// configuration for the system to check if the api paths and front end routes
// here VS the ones fetched from the database match.

export const REGISTRATION_CUSTOM_MATCH = /^\/reg\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/\w{24}\/?$/
// eslint-disable-next-line max-len
export const EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE =
/^\/log\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/\w{24}\/[A-Za-z0-9+/=]+\/?$/
// eslint-disable-next-line max-len
export const EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE =
/^\/log\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/\w{24}\/?$/
export const CONTENT_CUSTOM_MATCH = /^\/content\/[a-fA-F0-9]{24}$/
export const LIBRARY_ARTICLE_CUSTOM_MATCH = /^\/library-article\/[a-fA-F0-9]{24}$/
export const CAREPLANS_CUSTOM_MATCH = /^\/my-careplan\/[a-fA-F0-9]{24}$/
export const CAREPLAN_STEP_CUSTOM_MATCH = /^\/my-careplan\/[a-zA-Z0-9]{24}\/[a-zA-Z0-9]{24}$/
export const CARE_FACILITY_CUSTOM_MATCH = /^\/carefacilities\/[a-fA-F0-9]{24}(\/[^/]+)*$/
export const DEPARTMENT_CUSTOM_MATCH = /^\/departments\/[a-fA-F0-9]{24}(\/[^/]+)*$/
export const CAREPROFESSIONAL_CUSTOM_MATCH = /^\/careprofessionals\/[a-fA-F0-9]{24}(\/[^/]+)*$/
export const TREATMENT_CUSTOM_MATCH = /^\/treatments\/[a-fA-F0-9]{24}(\/[^/]+)*$/
export const CAREPATH_CUSTOM_MATCH = /^\/carepaths\/[a-fA-F0-9]{24}(\/[^/]+)*$/
export const PATIENT_CUSTOM_MATCH = /^\/patients\/[a-fA-F0-9]{24}(\/[^/]+)*$/

export const MODULE_TABLE = {
    reasonWithMe: {
        moduleName: 'reasonWithMe',
        moduleShortName: 'rwm',
        apiPaths: {
            reasonWithMe: {
                version: 1,
                path: '/api/v1/reasonWithMe'
            },
            getReasoningId: {
                version: 1,
                path: '/api/v1/reasonWithMe/:theReasoningId'
            },
            finishPersonalCareplan: {
                version: 1,
                path: '/api/v1/finishPersonalCareplan/<personalCarePlanId:path>'
            },
            checkCareplanStatus: {
                version: 1,
                path: '/api/v1/checkCareplanStatus/<personalCarePlanId:path>'
            },
            getWorkflow: {
                version: 1.0,
                path: '/api/v1/getWorkflow'
            },
            updateWorkflowProgress: {
                version: 1.0,
                path: '/api/v1/updateWorkflowProgress'
            }
        },
        routes: {
            home: '/rwm',
            questionInterface: '/rwm/question',
            results: '/rwm/results'
        }

    },
    eTherapeutics: {

        moduleName: 'eTherapeutics',
        moduleShortName: 'eth',
        apiPaths: {
            getTherapeutics: {
                version: 1,
                path: '/api/v1/getTherapeutics'
            },
            getTherapeutic: {
                version: 1,
                path: '/api/v1/getTherapeutic'
            },
            getLessons: {
                version: 1,
                path: '/api/v1/getLessons'
            },
            getLesson: {
                version: 1,
                path: '/api/v1/getLesson'
            },
            updateLessonProgress: {
                version: 1,
                path: '/api/v1/updateLessonProgress'
            }
        },
        routes: {}

    },
    articlesPatients: {

        moduleName: 'Articles - Patients',
        moduleShortName: 'art',
        apiPaths: {
            getCarepathContent: {
                version: 1,
                path: '/api/v1/getCarepathContent'
            },
            getInstructionSetContent: {
                version: 1,
                path: '/api/v1/getInstructionSetContent'
            }
        },
        routes: {}

    },
    activitiesPatients: {

        moduleName: 'Activities - Patients',
        moduleShortName: 'act',
        apiPaths: {
            getActivities: {
                version: 1,
                path: '/api/v1/getActivities'
            },
            getActivityId: {
                version: 1,
                path: '/api/v1/getActivityId'
            }
        },
        routes: {}

    },
    careplanPatient: {

        moduleName: 'Careplan - Patient',
        moduleShortName: 'cpn',
        apiPaths: {
            makePersonalCareplan: {
                version: 1,
                path: '/api/v1/makePersonalCareplan'
            },
            getPersonalCareplan: {
                version: 1,
                path: '/api/v1/getPersonalCareplan/<personalCareplanId:path>'
            },
            finishPersonalCareplan: {
                version: 1,
                path: '/api/v1/finishPersonalCareplan/<personalCarePlanId:path>'
            },
            getToday: {
                version: 1,
                path: '/api/v1/getToday'
            },
            getOverallProgress: {
                version: 1,
                path: '/api/v1/getOverallProgress'
            },
            getUserTreatments: {
                version: 1,
                path: '/api/v1/getUserTreatments'
            },
            updateCareplanProgress: {
                version: 1,
                path: '/api/v1/updateCareplanProgress'
            },
            completeCareplanProgress: {
                version: 1,
                path: '/api/v1/completeCareplanProgress'
            },
            completeCareplanProgressReasoning: {
                version: 1,
                path: '/api/v1/completeCareplanProgressReasoning'
            },
            makeCareplan: {
                version: 1,
                path: '/api/v1/makeCareplan'
            },
            getMotivationalMessage: {
                version: 1,
                path: '/api/v1/getMotivationalMessage'
            },
            getTreatmentCareplanId: {
                version: 1,
                path: '/api/v1/getTreatmentCareplanId'
            },
            getCareplanId: {
                version: 1,
                path: '/api/v1/getCareplanId'
            },
            getCareplanSteps: {
                version: 1,
                path: '/api/v1/getCareplanSteps'
            },
            getCareplanStepId: {
                version: 1,
                path: '/api/v1/getCareplanStepId'
            },
            checkCareplanStatus: {
                version: 1,
                path: '/api/v1/checkCareplanStatus/<personalCarePlanId:path>'
            },
            reasonWithMe: {
                version: 1,
                path: '/api/v1/reasonWithMe'
            },
            getReasoningId: {
                version: 1,
                path: '/api/v1/reasonWithMe/:theReasoningId'
            },
            saveToLibrary: {
                version: 1.0,
                path: '/api/v1/saveToLibrary'
            },
            removeFromLibrary: {
                version: 1.0,
                path: '/api/v1/removeFromLibrary'
            }
        },
        routes: {
            careplans: '/my-careplans',
            today: '/today',
            careplan: '/my-careplan/:personalCareplanId',
            careplanStep: '/my-careplan/:personalCareplanId/:personalCareplanStepId'
        }

    },
    login: {
        moduleName: 'Login',
        moduleShortName: 'log',
        apiPaths: {
            doLogin: {
                version: 1.0,
                path: '/api/v1/doLogin'
            },
            tokenValid: {
                version: 1.0,
                path: '/api/v1/tokenValid'
            },
            doGetToken: {
                version: 1.0,
                path: '/api/v1/doGetToken'
            },
            getModules: {
                version: 1.0,
                path: '/api/v1/getModules'
            },
            getMessages: {
                version: 1.0,
                path: '/api/v1/getMessages'
            },
            getWorkflow: {
                version: 1.0,
                path: '/api/v1/getWorkflow'
            },
            updateWorkflowProgress: {
                version: 1.0,
                path: '/api/v1/updateWorkflowProgress'
            },
            validateMFA: {
                version: 1.0,
                path: '/api/v1/validateMFA'
            },
            getMenu: {
                version: 1.0,
                path: '/api/v1/getMenu'
            },
            resetPassword: {
                version: 1.0,
                path: '/api/v1/resetPassword'
            },
            updateSubscriberId: {
                version: 1.0,
                path: '/api/v1/updateSubscriberId'
            },
            changePassword: {
                version: 1.0,
                path: '/api/v1/changePassword'
            }
        },
        routes: {
            login: '/log/login',
            logout: '/log/logout',
            forcePassword: '/log/force-password',
            resetPassword: '/log/reset-password',
            mfaPass: '/log/mfaPass',
            loginByEmail: '/log/:uuid/:id/:route',
            home: '/'
        }

    },
    userManagementAdmin: {

        moduleName: 'User Management - Admin',
        moduleShortName: 'umt',
        apiPaths: {
            addUser: {
                version: 1.0,
                path: '/api/v1/addUser'
            },
            editUser: {
                version: 1.0,
                path: '/api/v1/editUser'
            },
            deleteUser: {
                version: 1.0,
                path: '/api/v1/deleteUser'
            },
            getUsers: {
                version: 1.0,
                path: '/api/v1/getUsers'
            },
            getUserId: {
                version: 1.0,
                path: '/api/v1/getUserId'
            }
        },
        routes: {}

    },
    onboarding: {

        moduleName: 'Onboarding',
        moduleShortName: 'onb',
        apiPaths: {
            getOnboarding: {
                version: 1.0,
                path: '/api/v1/getOnboarding'
            }
        },
        routes: {
            gettingStarted: '/onb/getting-started'
        }

    },
    registration: {

        moduleName: 'Registration',
        moduleShortName: 'reg',
        apiPaths: {
            getOnboarding: {
                version: 1.0,
                path: '/api/v1/getOnboarding'
            },
            getTreatment: {
                version: 1.0,
                path: '/api/v1/getTreatment'
            },
            updateTreatment: {
                version: 1.0,
                path: '/api/v1/updateTreatment'
            },
            getUserTreatments: {
                version: 1.0,
                path: '/api/v1/getUserTreatments'
            },
            getPersonalDetails: {
                version: 1.0,
                path: '/api/v1/getPersonalDetails'
            },
            updatePersonalDetails: {
                version: 1.0,
                path: '/api/v1/updatePersonalDetails'
            },
            setAccount: {
                version: 1.0,
                path: '/api/v1/setAccount'
            },
            setMFA: {
                version: 1.0,
                path: '/api/v1/setMFA'
            },
            validateMFA: {
                version: 1.0,
                path: '/api/v1/validateMFA'
            },
            setSubscriberId: {
                version: 1.0,
                path: '/api/v1/setSubscriberId'
            },
            updateWorkflowProgress: {
                version: 1.0,
                path: '/api/v1/updateWorkflowProgress'
            }
        },
        routes: {
            registration: '/reg',
            registrationByEmail: '/reg/:uuid/:id'
        }

    },
    profile: {

        moduleName: 'Profile',
        moduleShortName: 'pro',
        apiPaths: {
            updateProfilePicture: {
                version: 1,
                path: '/api/v1/updateProfilePicture'
            },
            getUserCareProfessionals: {
                version: 1,
                path: '/api/v1/getUserCareProfessionals'
            },
            getMyTherapeutics: {
                version: 1,
                path: '/api/v1/getMyTherapeutics'
            },
            deleteCareplan: {
                version: 1,
                path: '/api/v1/deleteCareplan'
            },
            setMFA: {
                version: 1.0,
                path: '/api/v1/setMFA'
            },
            validateMFA: {
                version: 1.0,
                path: '/api/v1/validateMFA'
            },
            changePassword: {
                version: 1.0,
                path: '/api/v1/changePassword'
            },
            getSettingsList: {
                version: 1.0,
                path: '/api/v1/getSettingsList'
            },
            getSettingsArticle: {
                version: 1.0,
                path: '/api/v1/getSettingsArticle'
            },
            getContact: {
                version: 1.0,
                path: '/api/v1/getContact'
            },
            doContact: {
                version: 1.0,
                path: '/api/v1/doContact'
            },
            getOverallProgress: {
                version: 1,
                path: '/api/v1/getOverallProgress'
            },
            setSubscriberId: {
                version: 1.0,
                path: '/api/v1/setSubscriberId'
            },
            togglePushNotifications: {
                version: 1.0,
                path: '/api/v1/togglePushNotifications'
            },
            toggleEmailNotifications: {
                version: 1.0,
                path: '/api/v1/toggleEmailNotifications'
            },
            getPersonalDetails: {
                version: 1.0,
                path: '/api/v1/getPersonalDetails'
            },
            updatePersonalDetails: {
                version: 1.0,
                path: '/api/v1/updatePersonalDetails'
            },
            getMenu: {
                version: 1.0,
                path: '/api/v1/getMenu'
            },
            updateTreatment: {
                version: 1.0,
                path: '/api/v1/updateTreatment'
            },
            getTreatment: {
                version: 1.0,
                path: '/api/v1/getTreatment'
            },
            getUserTreatments: {
                version: 1.0,
                path: '/api/v1/getUserTreatments'
            }
        },
        routes: {
            myProfile: '/my-profile',
            myTherapeutics: '/my-therapeutics',
            myCareteam: '/my-careteam',
            myProgress: '/my-progress',
            accountSettings: '/account-settings',
            settings: '/settings',
            content: '/content/:listId',
            contact: '/contact'
        }

    },
    library: {

        moduleName: 'Library',
        moduleShortName: 'lib',
        apiPaths: {
            getLibrary: {
                version: 1.0,
                path: '/api/v1/getLibrary'
            },
            getLibraryArticle: {
                version: 1.0,
                path: '/api/v1/getLibraryArticle'
            },
            saveToLibrary: {
                version: 1.0,
                path: '/api/v1/saveToLibrary'
            },
            removeFromLibrary: {
                version: 1.0,
                path: '/api/v1/removeFromLibrary'
            }
        },
        routes: {
            library: '/library',
            article: '/library-article/:articleId'
        }

    },
    fmt: {

        moduleName: 'Facility Management - Admin',
        moduleShortName: 'fmt',
        apiPaths: {
            // FACILITY
            addCareFacility: {
                version: 1.0,
                path: '/api/v1/addCareFacility'
            },
            editCareFacility: {
                version: 1.0,
                path: '/api/v1/editCareFacility'
            },
            deleteCareFacility: {
                version: 1.0,
                path: '/api/v1/deleteCareFacility'
            },
            getCareFacilities: {
                version: 1.0,
                path: '/api/v1/getCareFacilities'
            },
            getCareFacility: {
                version: 1.0,
                path: '/api/v1/getCareFacility'
            },
            updateProfilePictureFacility: {
                version: 1.0,
                path: '/api/v1/updateProfilePictureFacility'
            },
            addDepartment: {
                version: 1.0,
                path: '/api/v1/addDepartment'
            },
            getDepartments: {
                version: 1.0,
                path: '/api/v1/getDepartments'
            },
            getDepartment: {
                version: 1.0,
                path: '/api/v1/getDepartment'
            },
            editDepartment: {
                version: 1.0,
                path: '/api/v1/editDepartment'
            },
            deleteDepartment: {
                version: 1.0,
                path: '/api/v1/deleteDepartment'
            },
            updateProfilePictureDepartment: {
                version: 1.0,
                path: '/api/v1/updateProfilePictureDepartment'
            },
            getCareProfessionals: {
                version: 1.0,
                path: '/api/v1/getCareProfessionals'
            },
            getDepartmentCareProfessionals: {
                version: 1.0,
                path: '/api/v1/getDepartmentCareProfessionals'
            },
            getCareProfessional: {
                version: 1.0,
                path: '/api/v1/getCareProfessional'
            },
            addCareProfessional: {
                version: 1.0,
                path: '/api/v1/addCareProfessional'
            },
            editCareProfessional: {
                version: 1.0,
                path: '/api/v1/editCareProfessional'
            },
            deleteCareProfessional: {
                version: 1.0,
                path: '/api/v1/deleteCareProfessional'
            },
            updateProfilePictureCareProfessional: {
                version: 1.0,
                path: '/api/v1/updateProfilePictureCareProfessional'
            },
            getUsers: {
                version: 1.0,
                path: '/api/v1/getUsers'
            },
            getUserData: {
                version: 1.0,
                path: '/api/v1/getUserData'
            },
            getUserProfile: {
                version: 1.0,
                path: '/api/v1/getUserProfile'
            },
            updateProfilePicture: {
                version: 1.0,
                path: '/api/v1/updateProfilePicture'
            },
            addDepartmentCareFacility: {
                version: 1.0,
                path: '/api/v1/addDepartmentCareFacility'
            },
            deleteDepartmentCareFacility: {
                version: 1.0,
                path: '/api/v1/deleteDepartmentCareFacility'
            },
            addDepartmentCareProfessional: {
                version: 1.0,
                path: '/api/v1/addDepartmentCareProfessional'
            },
            deleteDepartmentCareProfessional: {
                version: 1.0,
                path: '/api/v1/deleteDepartmentCareProfessional'
            },
            addDepartmentTreatment: {
                version: 1.0,
                path: '/api/v1/addDepartmentTreatment'
            },
            getDepartmentTreatments: {
                version: 1.0,
                path: '/api/v1/getDepartmentTreatments'
            },
            deleteDepartmentTreatment: {
                version: 1.0,
                path: '/api/v1/deleteDepartmentTreatment'
            },
            addTreatmentCarepath: {
                version: 1.0,
                path: '/api/v1/addTreatmentCarepath'
            },
            deleteTreatmentCarepath: {
                version: 1.0,
                path: '/api/v1/deleteTreatmentCarepath'
            },
            getTreatmentCarepaths: {
                version: 1.0,
                path: '/api/v1/getTreatmentCarepaths'
            },
            getTreatments: {
                version: 1.0,
                path: '/api/v1/getTreatments'
            },
            getTreatmentId: {
                version: 1.0,
                path: '/api/v1/getTreatmentId'
            },
            addTreatment: {
                version: 1.0,
                path: '/api/v1/addTreatment'
            },
            editTreatment: {
                version: 1.0,
                path: '/api/v1/editTreatment'
            },
            deleteTreatment: {
                version: 1.0,
                path: '/api/v1/deleteTreatment'
            },
            getCarepaths: {
                version: 1.0,
                path: '/api/v1/getCarepaths'
            },
            getCarepathId: {
                version: 1.0,
                path: '/api/v1/getCarepathId'
            },
            addCarepath: {
                version: 1.0,
                path: '/api/v1/addCarepath'
            },
            editCarepath: {
                version: 1.0,
                path: '/api/v1/editCarepath'
            },
            deleteCarepath: {
                version: 1.0,
                path: '/api/v1/deleteCarepath'
            },
            // ADD THIS TO API CALL.
            updateProfilePictureCarepath: {
                version: 1.0,
                path: '/api/v1/updateProfilePictureCarepath'
            },
            updateProfilePictureTreatment: {
                version: 1.0,
                path: '/api/v1/updateProfilePictureTreatment'
            }
        },
        routes: {
            careFacilities: '/carefacilities',
            createCareFacility: '/carefacilities/add',
            viewCareFacility: '/carefacilities/:careFacilityId',
            editCareFacility: '/carefacilities/:careFacilityId/edit',
            departments: '/departments',
            createDepartment: '/departments/add',
            viewDepartment: '/departments/:departmentId',
            editDepartment: '/departments/:departmentId/edit',
            careprofessionals: '/careprofessionals',
            createCareprofessional: '/careprofessionals/add',
            viewCareprofessional: '/careprofessionals/:careprofessionalId',
            editCareprofessional: '/careprofessionals/:careprofessionalId/edit',
            treatments: '/treatments',
            createTreatment: '/treatments/add',
            viewTreatment: '/treatments/:treatmentId',
            editTreatment: '/treatments/:treatmentId/edit',
            carepaths: '/carepaths',
            createCarepath: '/carepaths/add',
            viewCarepath: '/carepaths/:carepathId',
            editCarepath: '/carepaths/:carepathId/edit'
        }

    },
    doc: {

        moduleName: 'Doctor',
        moduleShortName: 'doc',
        apiPaths: {
            getPatients: {
                version: 1.0,
                path: '/api/v1/getPatients'
            },
            getPatientId: {
                version: 1.0,
                path: '/api/v1/getPatient'
            },
            getPatientActivity: {
                version: 1.0,
                path: '/api/v1/getPatientActivity'
            },
            getPatientActivityReasoning: {
                version: 1.0,
                path: '/api/v1/getPatientActivityReasoning'
            },
            gPACareplanStepId: {
                version: 1.0,
                path: '/api/v1/getPatientActivity/<careplanStepId:path>'
            },
            gPACareplanStepIdDetails: {
                version: 1.0,
                path: '/api/v1/getPatientActivity/details/<careplanStepId:path>'
            },
            getPatientCharts: {
                version: 1.0,
                path: '/api/v1/getPatientCharts'
            },
            getPatientChartData: {
                version: 1.0,
                path: '/api/v1/getPatientChartData'
            },
            getPatientProgress: {
                version: 1.0,
                path: '/api/v1/getPatientProgress'
            },
            getPatientDeviations: {
                version: 1.0,
                path: '/api/v1/getPatientDeviations'
            },
            getPatientAdvisory: {
                version: 1.0,
                path: '/api/v1/getPatientAdvisory'
            },
            addPatient: {
                version: 1.0,
                path: '/api/v1/addPatient'
            },
            editPatient: {
                version: 1.0,
                path: '/api/v1/editPatient'
            },
            deletePatient: {
                version: 1.0,
                path: '/api/v1/deletePatient'
            },
            // ADD THIS TO API CALL.
            updateProfilePicturePatient: {
                version: 1.0,
                path: '/api/v1/updateProfilePicturePatient'
            },
            getDepartmentCareFacilities: {
                version: 1.0,
                path: '/api/v1/getDepartmentCareFacilities'
            },
            getUserTreatments: {
                version: 1.0,
                path: '/api/v1/getUserTreatments'
            },
            makeCareplan: {
                version: 1,
                path: '/api/v1/makeCareplan'
            },
            getOverallAbnormalities: {
                version: 1.0,
                path: '/api/v1/getOverallAbnormalities'
            },
            getPatientsUpcomingSurgery: {
                version: 1.0,
                path: '/api/v1/getPatientsUpcomingSurgery'
            },
            getTreatedPatients: {
                version: 1.0,
                path: '/api/v1/getTreatedPatients'
            },
            getPatientTherapeutics: {
                version: 1,
                path: '/api/v1/getPatientTherapeutics'
            },
            updatePatientTreatment: {
                version: 1,
                path: '/api/v1/updatePatientTreatment'
            },
            resendInvite: {
                version: 1,
                path: '/api/v1/resendInvite'
            },
            getHCPCards: {
                version: 1,
                path: '/api/v1/getHCPCards'
            },
            deleteCareplan: {
                version: 1,
                path: '/api/v1/deleteCareplan'
            },
            getTreatment: {
                version: 1.0,
                path: '/api/v1/getTreatment'
            },
            updateTreatment: {
                version: 1.0,
                path: '/api/v1/updateTreatment'
            }
        },
        routes: {
            patients: '/patients',
            createPatient: '/patients/add',
            viewPatient: '/patients/:userId/view',
            editPatient: '/patients/:userId/edit',
            careprofessionalDashboard: '/careprofessionals/dashboard'
        }

    }
}
