import { RootState } from '@app/app.store'
import { FacilitiesState } from '@fmt/type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: FacilitiesState = {
    facilityMenu: {
        addInterfaceProps: {
            // facilityId: '66334b7afd16414d176ce55a'
            facilityId: ''
        },
        currentStep: 1,
        // currentStep: 2,
        showAddModal: false,
        steps: []
    },
    departmentMenu: {
        showAddModal: false,
        steps: []
    },
    careprofessionalMenu: {
        showAddModal: false,
        steps: []
    },
    treatmentMenu: {
        showAddModal: false,
        steps: []
    },
    carepathMenu: {
        showAddModal: false,
        steps: []
    }
}

// [
//     "64ecaa7ef52b24d955367c1d",
//     "62ab0250cd3cb31767b8d7bb",
//     "64ddeba096161ea7a69e2c47",
//     "6499580a61dc57280d4f867f",
//     "64d5ecbbdd49fe5055c1e157"
// ]

export const slice = createSlice({
    name: 'fmtv10',
    initialState,
    reducers: {
        setFacilityMenu: (state: FacilitiesState, action: PayloadAction<
            FacilitiesState['facilityMenu']
        >) => {
            state.facilityMenu = action.payload
        },
        setDepartmentMenu: (state: FacilitiesState, action: PayloadAction<
            FacilitiesState['departmentMenu']
        >) => {
            state.departmentMenu = action.payload
        },
        setCareprofessionalMenu: (state: FacilitiesState, action: PayloadAction<
            FacilitiesState['careprofessionalMenu']
        >) => {
            state.careprofessionalMenu = action.payload
        },
        setTreatmentMenu: (state: FacilitiesState, action: PayloadAction<
            FacilitiesState['treatmentMenu']
        >) => {
            state.treatmentMenu = action.payload
        },
        setCarepathMenu: (state: FacilitiesState, action: PayloadAction<
            FacilitiesState['carepathMenu']
        >) => {
            state.carepathMenu = action.payload
        },
        resetFacilities: (state: FacilitiesState) => {
            state = initialState
        }
    }
})

export const {
    resetFacilities,
    setFacilityMenu,
    setDepartmentMenu,
    setCareprofessionalMenu,
    setTreatmentMenu,
    setCarepathMenu
} = slice.actions

export const selectFacilityMenu = (state: RootState) => state.fmtV10.facilityMenu
export const selectDepartmentMenu = (state: RootState) => state.fmtV10.departmentMenu
export const selectCareprofessionalMenu = (state: RootState) => state.fmtV10.careprofessionalMenu
export const selectTreatmentMenu = (state: RootState) => state.fmtV10.treatmentMenu
export const selectCarepathMenu = (state: RootState) => state.fmtV10.carepathMenu

export default slice.reducer
