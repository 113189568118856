import { RootState } from '@app/app.store'
import { CurrentReasoning, ReasonWithMeRequest, ReasonWithMeState } from '@reasonWithMe/type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: ReasonWithMeState = {
    questionInterface: {
        /** retrieved from selecting a choice from the bodymap interface */
        // firstReasoningSetId: '642d47c671bf1038c72dfb81',
        firstReasoningSetId: '',
        /** only used once in ReasonWithMe. */
        // reasoningSetVersion: 0.1,
        reasoningSetVersion: 0,
        currentReasonWithMeResponse: {
            answerValue: undefined,
            reasoningData: {
                stop_value: false,
                reasoningSessionId: ''
            },
            results: {
                reasoningSessionId: '',
                stop_value: true
            }
        }
    }
}

export const slice = createSlice({
    name: 'reasonWithMe',
    initialState,
    reducers: {
        setReasoningSet: (state: ReasonWithMeState, action: PayloadAction<ReasonWithMeRequest>) => {
            state.questionInterface.firstReasoningSetId = action.payload.data.reasoningSetId
            state.questionInterface.reasoningSetVersion = action.payload.data.reasoningSetVersion
        },
        setCurrentReasoningData: (state: ReasonWithMeState, action: PayloadAction<
            CurrentReasoning['reasoningData']
        >) => {
            state.questionInterface.currentReasonWithMeResponse.reasoningData = action.payload
        },
        setCurrentReasoningResults: (state: ReasonWithMeState, action: PayloadAction<
            CurrentReasoning['results']
        >) => {
            state.questionInterface.currentReasonWithMeResponse.results = action.payload
        },
        setAnswerValue: (state: ReasonWithMeState, action: PayloadAction<any>) => {
            state.questionInterface.currentReasonWithMeResponse.answerValue = action.payload
        },
        resetReasonWithMe: (state: ReasonWithMeState) => {
            state.questionInterface = initialState.questionInterface
        }
    }
})

// eslint-disable-next-line no-empty-pattern
export const {
    setCurrentReasoningData,
    setReasoningSet,
    setAnswerValue,
    setCurrentReasoningResults,
    resetReasonWithMe
} = slice.actions

export const selectQuestionInterface = (state: RootState) => state.reasonWithMeV10.questionInterface

export default slice.reducer
