import { ReasonWithMeState } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkDefinitionList from 'remark-definition-list'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'
import remarkEmoji from 'remark-emoji'
import remarkBreaks from 'remark-breaks'

const MarkdownedText = (props: {text?: string}) => {
    const forMarkdownContent = props.text || ''
        .replace(/==([^=]+)==/g, '<mark>$1</mark>')
        .replace(/~(\d+)~/g, '<sub>$1</sub>')
        .replace(/~~([^~]+)~~/g, '<s>$1</s>')

    return <div className={'markdown'}>
        <ReactMarkdown
            components={{
            // supersub replaces markdown with del tags
            // for somereason.
                del: (props) => <sub {...props} />,
                ul: (props) => {
                    const modifiedProps = { ...props }
                    // eslint-disable-next-line react/prop-types
                    modifiedProps.ordered = props.ordered.toString() as any

                    if (modifiedProps.className && modifiedProps.className
                        .includes('contains-task-list')) {
                        return <ul
                            {...modifiedProps}
                            className={[
                                'contains-task-list list-unstyled ps-4'
                            ].join(' ')}
                        />
                    } else {
                        return <ul
                            {...modifiedProps}

                        />
                    }
                }
            }}
            linkTarget={'_blank'}
            remarkPlugins={[
                remarkBreaks,
                remarkGfm,
                supersub,
                remarkEmoji,
                remarkDefinitionList,
                remarkHeadingId
            ]} rehypePlugins={[
                rehypeRaw
            ]}
        >
            {`${ forMarkdownContent }`}
        </ReactMarkdown>
    </div>
}

function ResultsPage (props: ReasonWithMeState['questionInterface'] & {
    personalCareplanId: string
}) {
    const MainRecommendationReasoningOutcome = useMemo(
        () => {
            const results = props.currentReasonWithMeResponse
                .results?.reasoningRecommendation?.reasoningOutcome || []

            return _.maxBy(results, (o) => o.confidenceLevel)
        },
        [props]
    )

    const defaultContext = <div>
        <h2>{MainRecommendationReasoningOutcome
            ?.reasoningOutcomeDetailedText?.baseHeading}</h2>
        <span className={'d-inline-block mt-2'}>
            <MarkdownedText
                text={MainRecommendationReasoningOutcome
                    ?.reasoningOutcomeDetailedText?.baseOutcome}
            />
        </span>
    </div>

    const allContexts = useMemo(() => {
        // map through each reasoningOutcome.
        const outcomes = props.currentReasonWithMeResponse.results
            ?.reasoningRecommendation?.reasoningOutcome || []

        let isEmpty = false

        // check if outcomes array doesn't have a showContext property or
        // if said property exists BUT the array is an empty.
        if (_.every(outcomes, outcome => !outcome.showContext ||
            _.isEmpty(outcome.showContext)) ||
            _.isEmpty(outcomes)
        ) {
            // Your logic here
            isEmpty = true
        }

        const outerShell = _.map(outcomes, (o, index) => {
            const key = `reasoning-outcome-${ index }`

            // now iterate the showContexts
            const contexts = _.map(o.showContext, (p, index2) => {
                const key = `show-context-${ index }-${ index2 }`

                return <div key={key}
                    className={[
                        index ? 'mt-3' : ''
                    ].join(' ')}>
                    <h2>{p.subHeading}</h2>
                    <span className={'d-inline-block mt-2'}>
                        <MarkdownedText
                            text={p.subHeadingText}
                        />
                    </span>
                </div>
            })

            return <div key={key}>
                {contexts}
            </div>
        })

        return isEmpty ? defaultContext : outerShell
    }, [props.currentReasonWithMeResponse.results?.reasoningRecommendation?.reasoningOutcome])

    return (<div className={'container px-5 px-sm-3'}>

        {allContexts}

    </div>
    )
}

export default ResultsPage
