import { END_USER_COLORS } from '@stylesheet/brands/ikh/enduser/Colors'
import { RegistrationConfig } from '@stylesheet/config/endUser/Registration'

export const REGISTRATION_CONFIG: RegistrationConfig = {
    headerGradientColor: END_USER_COLORS.headerGradientColor,
    mainContent: {
        statusCard: {
            color: END_USER_COLORS.white,
            background: END_USER_COLORS.darkBlue
        },
        dropdownList: {
            searchBox: {
                backgroundColor: END_USER_COLORS.gray_900
            }
        }
    },
    pagination: {
        color: 'var(--bs-link-color)',
        bg: END_USER_COLORS.white_100,
        borderColor: END_USER_COLORS.white_100,
        hoverColor: END_USER_COLORS.white_100,
        hoverBg: END_USER_COLORS.white_100,
        hoverBorderColor: END_USER_COLORS.white_100,
        focusColor: END_USER_COLORS.white_100,
        focusBg: END_USER_COLORS.white_100,
        activeColor: END_USER_COLORS.white_100,
        activeBg: END_USER_COLORS.yellow1,
        activeBorderColor: END_USER_COLORS.yellow1,
        disabledColor: END_USER_COLORS.gray_200,
        disabledBg: END_USER_COLORS.white_100,
        disabledBorderColor: END_USER_COLORS.gray_100
    }
}
