
import { useAppDispatch } from '@app/app.hook'
import { setAnswerValue } from '@reasonWithMe/slice'
import { QuestionInterfaceActions, ReasonWithMeState } from '@reasonWithMe/type'
import _ from 'lodash'
import { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import remarkDefinitionList from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
}

const Text = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const dispatch = useAppDispatch()

    // onmount, dispatch the answer value immediately to the first value of the
    // question answers allowed array.
    useEffect(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData

        if (componentDispatch !== undefined) {
            componentDispatch({
                type: 'SET_ANSWER_VALUE',
                value: data.question?.questionAnswers[0]?.acqValue
            })
        } else {
            dispatch(setAnswerValue(
                data.question?.questionAnswers[0]?.acqValue
            ))
        }
    }, [questionInterface])

    const forMarkdownContent = _.isString(
        questionInterface.currentReasonWithMeResponse.reasoningData
            ?.question?.questionDescription)
        ? questionInterface.currentReasonWithMeResponse.reasoningData
            ?.question?.questionDescription
            .replace(/==([^=]+)==/g, '<mark>$1</mark>')
            .replace(/~(\d+)~/g, '<sub>$1</sub>')
            .replace(/~~([^~]+)~~/g, '<s>$1</s>')
        : ''

    return <div className={'question-text markdown'}>
        <ReactMarkdown
            components={{
                // supersub replaces markdown with del tags
                // for somereason.
                del: (props) => <sub {...props} />,
                ul: (props) => {
                    const modifiedProps = { ...props }
                    // eslint-disable-next-line react/prop-types
                    modifiedProps.ordered = props.ordered.toString() as any

                    if (modifiedProps.className && modifiedProps.className
                        .includes('contains-task-list')) {
                        return <ul
                            {...modifiedProps}
                            className={[
                                'contains-task-list list-unstyled ps-4'
                            ].join(' ')}
                        />
                    } else {
                        return <ul
                            {...modifiedProps}

                        />
                    }
                }
            }}
            linkTarget={'_blank'}
            remarkPlugins={[
                remarkBreaks,
                remarkGfm,
                supersub,
                remarkEmoji,
                remarkDefinitionList,
                remarkHeadingId
            ]} rehypePlugins={[
                rehypeRaw
            ]}
        >
            {`${ forMarkdownContent }`}
        </ReactMarkdown>
    </div>
}

export default Text
