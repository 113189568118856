import React from 'react'

interface OverlayProps {
  text: string;
}

const FullscreenOverlay: React.FC<OverlayProps> = ({ text }) => {
    return (
        <div className={'fullscreen-overlay'}>
            <div className={'fullscreen-overlay-text'}>{
                <div className={'spinner-container row g-0 align-items-center'}>
                    <div className={'col-auto'}>
                        <span className={'spinner-border'}></span>
                    </div>
                    <div className={'col-auto'}>
                        <span className={'ms-5 spinner-text'}>{
                            text
                        }</span>
                    </div>

                </div>
            }</div>
        </div>
    )
}

export default FullscreenOverlay
