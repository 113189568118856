import { END_USER_COLORS } from '@stylesheet/brands/ikh/enduser/Colors'
import { CareplanStepConfig } from '@stylesheet/config/endUser/CareplanStep'

export const CAREPLAN_STEP_CONFIG: CareplanStepConfig = {
    headerGradientColor: END_USER_COLORS.headerGradientColor,
    footerButtons: {
        boxShadow: END_USER_COLORS.shadowColor3,
        background: END_USER_COLORS.darkGrey_1
    },
    mainContent: {
        background: END_USER_COLORS.darkGrey_1,
        metaInfo: {
            activityColor: END_USER_COLORS.cardTypes.activity,
            contentColor: END_USER_COLORS.cardTypes.content,
            lessonColor: END_USER_COLORS.cardTypes.lesson,
            reasoningColor: END_USER_COLORS.cardTypes.reasoning
        },
        questionnaire: {
            card: {
                color: END_USER_COLORS.white,
                backgroundColor: END_USER_COLORS.darkBlue
            }
        },
        mediaWrapper: {
            player: {
                playButtonColor: END_USER_COLORS.black,
                playButtonBackground: END_USER_COLORS.yellow1,
                fullscreenButtonBoxShadow: `0px 2px 4px 0px ${ END_USER_COLORS.shadowColor1 }`,
                rangeBarColor: END_USER_COLORS.gray_300,
                rangeProgressColor: END_USER_COLORS.darkBlue,
                rangeThumbColor: END_USER_COLORS.darkBlue,
                rangeTrackColor: END_USER_COLORS.gray_200
            }
        }
    }
}
