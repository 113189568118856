import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import TreatmentsInterface from '@fmt/components/facilities/add/steps/StepFour/TreatmentsInterface'
import { IDS } from '@fmt/constants'
import { Department, GetTreatmentsResponse } from '@fmt/type'
import { FormikProps } from 'formik'
import _ from 'lodash'

interface ComponentProps {
    facilityStepFourAddFormik: FormikProps<{
        linkedTreatments: {
        department: Department,
        collapsible: boolean,
        arr: GetTreatmentsResponse['data']['treatments']
    }[]}>
}

const StepFour = ({ facilityStepFourAddFormik }:ComponentProps) => {
    // what you need from top to bottom,
    // one, a dropdown that displays a list of listed departments by the facilityId
    // and a list of AVAILABLE departments.
    const strings = useAppSelector(selectStrings)
    // const validateRoute = useValidateRoute()
    // const dispatch = useAppDispatch()

    const result = _.map(facilityStepFourAddFormik.values.linkedTreatments, (o, index, arr) => {
        const key = o.department.departmentId

        // tim wouldn't like it if the component is not rendered if collapsed.
        const result = <div className={[o.collapsible ? 'd-block' : 'd-none'].join(' ')}>
            <TreatmentsInterface
                department={o.department}
                isFromEditPage={false}
                facilityStepFourAddFormik={facilityStepFourAddFormik}
            />
        </div>

        return <div key={key} className={'col-12 card p-4'}>
            <div className={'row align-items-center justify-content-between'}>
                <div className={'col-auto'}>
                    <label htmlFor={IDS.FACILITY_ADD_MODAL.SELECT.ADD_TREATMENTS}
                        className={'fw-semibold'}>{
                            o.department.departmentName
                        }</label>
                </div>
                <div className={'col-auto'}>
                    <button
                        type={'button'}
                        className={[
                            'btn btn-round btn-rounded me-3'
                        ].join('')}
                        onClick={() => {
                            const temp = _.cloneDeep(arr)
                            _.forEach(temp, (o, innerIndex) => {
                                o.collapsible = index === innerIndex
                                    ? !temp[index].collapsible
                                    : false
                            })
                            facilityStepFourAddFormik.setFieldValue(
                                'linkedTreatments', temp
                            )
                        }}
                    >
                        <i
                            className={[
                                'fa-light',
                                o.collapsible ? 'fa-chevron-up' : ' fa-chevron-down'
                            ].join(' ')}
                            aria-hidden={'true'}>
                        </i>
                    </button>
                </div>
            </div>

            {result}

        </div>
    })

    return <div id={'step-four-content'}>
        <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text.facility.add.treatment}</h2>
        <div className={'row g-3'}>
            {
                result
            }
        </div>
    </div>
}

export default StepFour
