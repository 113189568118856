import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createBrowserHistory } from 'history'
import {
    createRouterMiddleware,
    createRouterReducerMapObject
} from '@lagunovsky/redux-react-router'

/**
 * load reducers that retrieve data from localStorage first.
 */
import appReducer from '@app/slices/slice.app'
import tokenReducer from '@app/slices/slice.token'
import loginV10Reducer from '@login/slices/slice.login'
import onboardingV10Reducer from '@onboarding/slice'
import reasonWithMeV10Reducer from '@reasonWithMe/slice'
import workflowv10Reducer from '@login/slices/slice.workflow'
import careplanV10Reducer from '@careplan/slice'
import sidebarV10Reducer from '@sidebar/slice'
import fmtV10Reducer from '@fmt/slice'
import docV10Reducer from '@doc/slice'
import stylesheetV10Reducer from '@stylesheet/slice'

import { appApi } from '@app/api.app'
import { loginV10Api } from '@login/api'
import { onboardingV10API } from '@onboarding/api'
import { reasonWithMeV10API } from '@reasonWithMe/api'
import { registrationV10API } from '@registration/api'
import { careplanV10Api } from '@careplan/api'
import { profileV10Api } from '@profile/api'
import { sidebarV10Api } from '@sidebar/api'
import { libraryV10Api } from '@library/api'
import { fmtV10Api } from '@fmt/api'
import { docV10Api } from '@doc/api'

export const history = createBrowserHistory()
const routerMiddleware = createRouterMiddleware(history)
const historyReducer = createRouterReducerMapObject(history)

/** dashboard slices */
// global state is initialized here. Store is what is called here in redux terminology.
export const store = configureStore({
    reducer: {
        app: appReducer,
        token: tokenReducer,
        loginV10: loginV10Reducer,
        onboardingV10: onboardingV10Reducer,
        reasonWithMeV10: reasonWithMeV10Reducer,
        workflowV10: workflowv10Reducer,
        careplanV10: careplanV10Reducer,
        sidebarV10: sidebarV10Reducer,
        fmtV10: fmtV10Reducer,
        docV10: docV10Reducer,
        stylesheetV10: stylesheetV10Reducer,
        router: historyReducer.router,
        [appApi.reducerPath]: appApi.reducer,
        [loginV10Api.reducerPath]: loginV10Api.reducer,
        [onboardingV10API.reducerPath]: onboardingV10API.reducer,
        [reasonWithMeV10API.reducerPath]: reasonWithMeV10API.reducer,
        [registrationV10API.reducerPath]: registrationV10API.reducer,
        [careplanV10Api.reducerPath]: careplanV10Api.reducer,
        [profileV10Api.reducerPath]: profileV10Api.reducer,
        [sidebarV10Api.reducerPath]: sidebarV10Api.reducer,
        [libraryV10Api.reducerPath]: libraryV10Api.reducer,
        [fmtV10Api.reducerPath]: fmtV10Api.reducer,
        [docV10Api.reducerPath]: docV10Api.reducer

    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(
            routerMiddleware,
            appApi.middleware,
            loginV10Api.middleware,
            onboardingV10API.middleware,
            reasonWithMeV10API.middleware,
            registrationV10API.middleware,
            careplanV10Api.middleware,
            profileV10Api.middleware,
            sidebarV10Api.middleware,
            libraryV10Api.middleware,
            fmtV10Api.middleware,
            docV10Api.middleware
        )
    }
})

setupListeners(store.dispatch)
// Infer the `GlobalState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {history: historyState, token: tokenState, login: loginState}
export type AppDispatch = typeof store.dispatch
export const selectRouter = (state: RootState) => state.router
