import { ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { FacilityManagementConfig } from '@stylesheet/config/admin/FacilityManagement'

export const FACILITY_MANAGEMENT_CONFIG: FacilityManagementConfig = {
    profileImgPlaceholder: {
        background: ADMIN_COLORS.gray_200
    },
    facilityHeader: {
        backgroundColor: [
            ADMIN_COLORS.facilityHeaderColor[1],
            ADMIN_COLORS.facilityHeaderColor[2],
            ADMIN_COLORS.facilityHeaderColor[3]
        ],
        iconContainerColor: ADMIN_COLORS.white,
        iconContainerBorderColor: ADMIN_COLORS.white,
        searchBoxFocusBorderColor: ADMIN_COLORS.primary,
        searchBoxFocusBoxShadowColor: ADMIN_COLORS.yellowShadow,
        searchBoxBackgroundColor: ADMIN_COLORS.gray_100,
        searchBoxInputColor: ADMIN_COLORS.dark,
        searchBoxIconColor: ADMIN_COLORS.dark
    },
    chartContainer: {
        overlayBackgroundColor: ADMIN_COLORS.white
    },
    answerSummary: {
        iconBackgroundColor: ADMIN_COLORS.gray_300,
        answerContainerBorderColor: ADMIN_COLORS.gray_200,
        answerContainerBackgroundColor: ADMIN_COLORS.grayish_blue,
        sliderRailBackgroundColor: ADMIN_COLORS.dark,
        floatingUiProgress: {
            background: ADMIN_COLORS.white,
            arrowColor: ADMIN_COLORS.white,
            shadowColor: ADMIN_COLORS.shadowColor2,
            textColor: ADMIN_COLORS.dark
        },
        informationFloatingUi: {
            background: ADMIN_COLORS.gray_700,
            arrowColor: ADMIN_COLORS.gray_700,
            shadowColor: ADMIN_COLORS.shadowColor2,
            textColor: ADMIN_COLORS.white
        },
        bodymapFloatingUi: {
            background: ADMIN_COLORS.gray_700,
            arrowColor: ADMIN_COLORS.gray_700,
            textColor: ADMIN_COLORS.white
        }
    },
    patientPage: {
        navigation: {
            contentBoxShadow: ADMIN_COLORS.navigationShadow,
            tab: {
                shadowColor: ADMIN_COLORS.navigationShadow,
                background: ADMIN_COLORS.gray_300,
                activeBackground: ADMIN_COLORS.gray_100
            }
        },
        cells: {
            defaultBorderColor: ADMIN_COLORS.dark,
            green: {
                borderColor: ADMIN_COLORS.green,
                color: ADMIN_COLORS.green
            },
            yellow: {
                borderColor: ADMIN_COLORS.yellow,
                color: ADMIN_COLORS.yellow
            },
            orange: {
                borderColor: ADMIN_COLORS.question,
                color: ADMIN_COLORS.question
            },
            red: {
                borderColor: ADMIN_COLORS.red,
                color: ADMIN_COLORS.red
            },
            grey: {
                borderColor: ADMIN_COLORS.gray_400,
                color: ADMIN_COLORS.gray_400
            }
        },
        progressBar: {
            backgroundColor: ADMIN_COLORS.yellow
        },
        adviceContainer: {
            borderColor: ADMIN_COLORS.secondary
        },
        customIcon: {
            circleColor: ADMIN_COLORS.secondary,
            minusColor: ADMIN_COLORS.red
        },
        taskCellContainer: {
            background: [
                ADMIN_COLORS.grayish_blue,
                ADMIN_COLORS.white
            ],
            current: {
                background: [
                    ADMIN_COLORS.taskCellContainerShadows[1],
                    ADMIN_COLORS.taskCellContainerShadows[2],
                    ADMIN_COLORS.taskCellContainerShadows[3],
                    ADMIN_COLORS.taskCellContainerShadows[4]
                ]
            },
            taskHeader: {
                background: ADMIN_COLORS.white
            },
            taskCell: {
                background: ADMIN_COLORS.white,
                boxShadow: ADMIN_COLORS.shadowColor,
                icon: {
                    background: ADMIN_COLORS.gray_300,
                    color: ADMIN_COLORS.white,
                    complete: {
                        background: ADMIN_COLORS.completion.complete
                    },
                    locked: {
                        background: ADMIN_COLORS.completion.locked,
                        color: ADMIN_COLORS.gray_dark
                    },
                    incomplete: {
                        background: ADMIN_COLORS.completion.incomplete
                    }
                }
            },
            progressBar: {
                background: ADMIN_COLORS.yellow
            }
        }
    },
    fmtPages: {
        mainContent: {
            color: ADMIN_COLORS.dark,
            background: {
                start: ADMIN_COLORS.gray_100,
                end: ADMIN_COLORS.grayish_blue
            }
        },
        body: {
            interface: {
                borderColor: ADMIN_COLORS.gray_100,
                background: ADMIN_COLORS.white
            },
            recordMenu: {
                borderColor: ADMIN_COLORS.gray_100,
                table: {
                    input: {
                        checkbox: {
                            borderColor: ADMIN_COLORS.gray_400
                        }
                    }
                },
                th: {
                    background: ADMIN_COLORS.gray_100,
                    borderBottomColor: ADMIN_COLORS.dark
                },
                td: {
                    borderBottomColor: ADMIN_COLORS.grayish_blue
                },
                tr: {
                    odd: {
                        background: ADMIN_COLORS.white
                    },
                    even: {
                        background: ADMIN_COLORS.white
                    }
                }

            }
        }
    },
    btnDark: {
        btnOutlineWhite: {
            color: ADMIN_COLORS.white,
            bg: ADMIN_COLORS.facilityHeaderColor[3],
            borderColor: ADMIN_COLORS.white,
            hoverColor: ADMIN_COLORS.white,
            hoverBg: ADMIN_COLORS.facilityHeaderColor[3],
            hoverBorderColor: ADMIN_COLORS.white,
            focusShadowRgb: '49, 132, 253',
            activeColor: ADMIN_COLORS.white,
            activeBg: ADMIN_COLORS.facilityHeaderColor[3],
            activeBorderColor: ADMIN_COLORS.white,
            activeShadow: `inset 0 3px 5px ${ ADMIN_COLORS.shadowColor }`,
            disabledColor: ADMIN_COLORS.gray_500,
            disabledBg: ADMIN_COLORS.facilityHeaderColor[3],
            disabledBorderColor: ADMIN_COLORS.white
        }
    },
    directionButtons: {
        color: ADMIN_COLORS.dark,
        disabled: {
            color: ADMIN_COLORS.gray_400
        }
    },
    pageNumbers: {
        background: ADMIN_COLORS.white,
        color: ADMIN_COLORS.dark,
        active: {
            background: ADMIN_COLORS.gray_300,
            color: ADMIN_COLORS.dark
        },
        hover: {
            background: ADMIN_COLORS.gray_200
        }
    },
    stepIndicator: {
        active: {
            background: ADMIN_COLORS.facilityHeaderColor,
            color: ADMIN_COLORS.white
        },
        border: ADMIN_COLORS.dark
    },
    stepDivider: {
        background: ADMIN_COLORS.gray_200
    },
    reviewTag: {
        background: ADMIN_COLORS.gray_500
    },
    dropdownList: {
        borderColor: ADMIN_COLORS.gray_200,
        option: {
            boxShadow: `0px 5px 15px 0px ${ ADMIN_COLORS.shadowColor2 }`,
            selected: {
                background: ADMIN_COLORS.gray_200
            }
        },
        backgroundColor: ADMIN_COLORS.white,
        searchBox: {
            backgroundColor: ADMIN_COLORS.gray_50
        }
    },
    innerDepartmentInteface: {
        borderColor: ADMIN_COLORS.gray_200,
        background: {
            1: ADMIN_COLORS.gray_200,
            2: ADMIN_COLORS.gray_200,
            3: ADMIN_COLORS.gray_100,
            4: ADMIN_COLORS.gray_100
        }
    },
    modal: {
        addForm: {
            color: ADMIN_COLORS.dark,
            footer: {
                background: 'var(--bs-light)'
            },
            body: {
                background: 'var(--bs-light)'
            },
            header: {
                background: 'var(--bs-light)'
            },
            webkitScrollbar: {
                track: {
                    background: ADMIN_COLORS.gray_200
                },
                thumb: {
                    background: 'var(--bs-article)'
                }
            }
        }
    },
    roundedSquare: {
        yellow: ADMIN_COLORS.yellow,
        dark: ADMIN_COLORS.dark,
        white: ADMIN_COLORS.white
    },
    searchBox: {
        focus: {
            borderColor: ADMIN_COLORS.primary,
            boxShadow: ADMIN_COLORS.yellowShadow
        },
        background: ADMIN_COLORS.white,
        icon: {
            textColor: ADMIN_COLORS.dark // Assuming 'dark' color is defined in your color constants
        }
    },
    formSelect: {
        color: ADMIN_COLORS.dark,
        background: ADMIN_COLORS.white,
        border: ADMIN_COLORS.gray_200, // eslint-disable-next-line max-len
        backgroundImage: 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3e%3cpath fill=\'none\' stroke=\'%23003b70\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'m2 5 6 6 6-6\'/%3e%3c/svg%3e")',
        focus: {
            border: ADMIN_COLORS.yellow,
            boxShadow: ADMIN_COLORS.yellowShadow
        },
        disabled: {
            background: ADMIN_COLORS.gray_200
        },
        mozFocusring: {
            textShadow: ADMIN_COLORS.dark
        }
    }
}
