import { Department, GetTreatmentsResponse, Treatment } from '@fmt/type'
import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { IDS } from '@fmt/constants'

import { smartSearch } from '@app/app.method'

import Pagination from '@app/components/Pagination'
import { FormikProps } from 'formik'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

interface ComponentProps {
    department: Department,
    isFromEditPage: boolean,
    unlinkedTreatments: GetTreatmentsResponse | undefined
    linkedTreatments:GetTreatmentsResponse | undefined
    LinkedTreatmentsFormik: FormikProps<{
        linkedTreatments: {
        department: Department,
        collapsible: boolean,
        arr: GetTreatmentsResponse['data']['treatments']
    }[]
    }>
    changeTreatmentStatus: (departmentId: string, obj: Treatment, linked: boolean) => Promise<void>
    search: string
    setSearch: React.Dispatch<React.SetStateAction<string>>
    currentPage: number
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const TreatmentsInterface = ({
    department,
    isFromEditPage,
    unlinkedTreatments,
    linkedTreatments,
    LinkedTreatmentsFormik,
    changeTreatmentStatus,
    search,
    setSearch,
    currentPage,
    setCurrentPage
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const containerRef = useRef<HTMLDivElement | null>(null)

    const debounceSetSearch = useDebouncedCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value)
        },
        1000
    )

    useEffect(() => {
        setCurrentPage(0)
    }, [department, search])
    // start off with dropdown already open.
    const [isOutsideClick, setIsOutsideClick] = useState(!isFromEditPage)

    // a workaround to prevent tables from flickering. simple but it works.
    const [
        getUnlinkedTreatmentsResponse,
        setGetUnlinkedTreatmentsResponse
    ] = useState<GetTreatmentsResponse>()

    const [
        getLinkedTreatmentsResponse,
        setGetLinkedTreatmentsResponse
    ] = useState<GetTreatmentsResponse>()

    useEffect(() => {
        if (unlinkedTreatments) {
            setGetUnlinkedTreatmentsResponse(
                unlinkedTreatments
            )
        }
    }, [unlinkedTreatments])

    useEffect(() => {
        if (linkedTreatments) {
            setGetLinkedTreatmentsResponse(
                linkedTreatments
            )
        }
    }, [linkedTreatments])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current) {
                // weird behavior but works anyways
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsOutsideClick(false)
                } else {
                    setIsOutsideClick(true)
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [containerRef])

    const renderOption = (obj: Treatment, defaultChecked: boolean) => {
        const key = obj.treatmentId

        return <div
            className={'col'}
            key={key}
        >
            <input type={'checkbox'}
                className={'btn-check'}
                id={key}
                autoComplete={'off'}
                // checked prop is removed.
                // when checked, link it, otherwise, unlink.
                defaultChecked={defaultChecked}
                onChange={(e) => {
                    if (e.target.checked) {
                    // link
                        changeTreatmentStatus(department.departmentId, obj, true)
                    } else {
                    // unlink.
                        changeTreatmentStatus(department.departmentId, obj, false)
                    }
                }}
            />
            <label
                className={'btn btn-dummy'}
                htmlFor={key}
            >

                <div className={'question-checkbox'}>
                    <div className={'card justify-content-center px-3 py-2'}>

                        <div className={'d-flex flex-column'}>
                            <div className={'d-flex align-items-center'}>
                                <div className={'p text-start'}>
                                    <span>{obj.treatmentName}</span>
                                </div>
                                <div className={'question-checkmark ms-auto'}>
                                    <i className={'fa-light fa-check mt-1 mx-auto'}>
                                    </i>
                                </div>
                                <div className={'question-plussign ms-auto'}>
                                    <i className={'fa-light fa-plus mx-auto'}>
                                    </i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </label>
        </div>
    }

    const LinkedTreatmentOptions = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    getLinkedTreatmentsResponse?.data.treatments || [],
                    [], search
                ) as Treatment[]),
                (obj) => {
                    return renderOption(obj, true)
                })
        }
    </div>

    const UnlinkedTreatmentOptions = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    getUnlinkedTreatmentsResponse?.data.treatments || [],
                    [], search
                ) as Treatment[]),
                (obj) => {
                    return renderOption(obj, false)
                })
        }
    </div>

    const result = <div className={[
        'align-items-baseline mt-4 row'
    ].join(' ')}>
        <div className={isFromEditPage
            ? 'col-xl-3 d-none d-sm-block'
            : 'col-3 d-none d-sm-block'}>
            <label htmlFor={IDS.FACILITY_ADD_MODAL.SELECT.ADD_TREATMENTS}
                className={'form-label'}>{
                    strings.fmt?.text.facility.add.treatment
                }</label>
        </div>
        <div className={isFromEditPage
            ? 'col-xl-9 col-12 position-relative'
            : 'col-sm-9 col-12 position-relative'}>

            <div
                id={IDS.FACILITY_ADD_MODAL.SELECT.ADD_TREATMENTS}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOutsideClick(!isOutsideClick)
                }}
                className={[
                    'form-select py-3'
                ].join(' ')}
            >
                {
                    <span className={'opacity-50'}>
                        {strings.app?.text.select || ''}
                    </span>
                }

            </div>
            {/* popup window to get a list of carepath with checkboxes */}

            {isOutsideClick && <div
                ref={(e) => {
                    containerRef.current = e
                }}
                className={[
                    'dropdown-list mt-4 px-5 py-6 w-100',
                    isFromEditPage ? '' : 'position-absolute'
                ].join(' ')}>
                {/* search tab and filter icon */}
                <div className={['row justify-content-between mb-4 ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box '}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text}
                                defaultValue={search}
                                onChange={(e) => {
                                    debounceSetSearch(e)
                                }}
                            />
                        </div>
                    </div>
                </div>

                {
                    department
                        ? <div className={'btn-group-checkbox-list row row-cols-1'}>
                            {/* linked first, then linked but not you, then unlinked */}
                            {getLinkedTreatmentsResponse?.data.treatments.length
                                ? <h6 className={'mb-3'}>{strings.fmt
                                    ?.text.facility.add.steps['2'].linked}</h6>
                                : ''}
                            {LinkedTreatmentOptions}
                            {getUnlinkedTreatmentsResponse
                                ?.data.treatments.length
                                ? <h6 className={'mb-3'}>{strings.fmt
                                    ?.text.facility.add.steps['2'].unlinked}</h6>
                                : ''}
                            {UnlinkedTreatmentOptions}
                            <div>
                                {(unlinkedTreatments?.data.totalRecords || 1) >
                                     (unlinkedTreatments?.data.limit || 1)
                                    ? <Pagination
                                        currentPage={currentPage}
                                        setCurrentPageState={setCurrentPage}
                                        limit={unlinkedTreatments
                                            ?.data.limit || unlinkedTreatments
                                            ?.data.totalRecords || 1}
                                        skip={unlinkedTreatments
                                            ?.data.skip || 1}
                                        totalRecords={unlinkedTreatments
                                            ?.data.totalRecords || 1}
                                    />
                                    : '' }
                            </div>
                        </div>
                        : <></>
                }
            </div>}

        </div>
    </div>

    return department ? result : <></>
}

export default TreatmentsInterface
