
import { useAppDispatch } from '@app/app.hook'
import { TokenData } from '@app/types/type.token'
import { setAnswerValue } from '@reasonWithMe/slice'
import { QuestionInterfaceActions, ReasonWithMeState } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo } from 'react'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>,
    goToNextQuestion: (token: TokenData) => Promise<void>
}

const Open = ({
    questionInterface,
    componentDispatch,
    goToNextQuestion
}: ComponentProps) => {
    const dispatch = useAppDispatch()

    // const token = useAppSelector(selectToken)

    // const handleKeyDown = (
    //     e: React.KeyboardEvent<HTMLTextAreaElement | HTMLSelectElement>
    // ) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault()
    //         // now make the next call.
    //         goToNextQuestion(token)
    //     }
    // }

    const input = useMemo(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        // const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        return <div className={'question-open'}>
            <textarea
                className={'form-control form-control-sm'}
                placeholder={_.has(data.question?.questionAnswers[0], 'answerName')
                    ? data.question?.questionAnswers[0]?.answerName
                    : ''}
                // errors={_.inRange(answerValue, lower, upper) === false}
                // value={answerValue}
                onChange={(e) => {
                    if (componentDispatch !== undefined) {
                        componentDispatch({
                            type: 'SET_ANSWER_VALUE',
                            value: e.target.value
                        })
                    } else {
                        dispatch(setAnswerValue(e.target.value))
                    }
                }}
                onBlur={(e) => {
                    if (componentDispatch !== undefined) {
                        componentDispatch({
                            type: 'SET_ANSWER_VALUE',
                            value: e.target.value.trim()
                        })
                    } else {
                        dispatch(setAnswerValue(e.target.value.trim()))
                    }
                }}
                // onKeyDown={handleKeyDown}
            />
        </div>
    }, [questionInterface])

    return input
}

export default Open
