import { MODULE_TABLE } from '@app/app.config'

/** This is the name of the module. */
export const MODULE_NAME = {
    LONG: MODULE_TABLE.library.moduleName,
    SHORT: MODULE_TABLE.library.moduleShortName
}

/** This is the major version number of the module. */
export const MODULE_MAJOR_VERSION = 1

/** This is the minor version number of the module. */
export const MODULE_MINOR_VERSION = 0

/** This is the hotfix version number of the module. */
export const MODULE_HOTFIX_VERSION = 6

/** This is the full version string of the module,
 * based on the major, minor, and hotfix versions. */
export const MODULE_VERSION = [
    MODULE_NAME.SHORT, ' ',
    `v.${ MODULE_MAJOR_VERSION }.${ MODULE_MINOR_VERSION }.${ MODULE_HOTFIX_VERSION }`
].join('')
// should be 10 seconds. this should also be the same increment with updating
// the active timers in all elements.
export const UPDATE_PROGRESS_INTERVAL = 10000
// export const UPDATE_PROGRESS_INTERVAL = 60000

// check library status interval
export const CHECK_LIBRARY_STATUS_INTERVAL = 3000

export const SEEKER = {
    MIN: 0,
    MAX: 0.999999,
    VALUE: 5
}

// should be a range of 100
export const VOLUME = {
    MIN: 0,
    MAX: 1,
    VALUE: 0.001
}

export const IDS = {
    LIBRARY: {
        SEARCH: 'LIBRARY__SEARCH'
    }
}
