import { OpenListInputs } from '@reasonWithMe/type'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
}

const Checkbox = ({
    answerType, outerIndex, innerIndex,
    formInput
}: ComponentProps) => {
    return <input
        type={'checkbox'}
        name={`${ answerType }-${ outerIndex }-${ innerIndex }`}
        disabled={!formInput.answerEditable}
        checked={formInput.userTypedAnswer as boolean}
        onChange={(e) => {
        }}
    />
}

export default Checkbox
