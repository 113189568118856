import { useAppDispatch, useAppSelector } from '@app/app.hook'

import { setIsRevalidating, setToken } from '@app/slices/slice.token'

import { selectStrings } from '@app/slices/slice.app'
import { useGetTokenMutation, useTokenValidMutation } from '@login/api'
import { setIsFullscreenOverlay } from '@login/slices/slice.login'
import { useCallback, useEffect } from 'react'
export const useRevalidateToken = () => {
    const strings = useAppSelector(selectStrings)

    const dispatch = useAppDispatch()

    const [doGetToken] = useGetTokenMutation({
        fixedCacheKey: 'shared-getToken-key'
    })
    /** trying out fixedCacheKey and I didn't like it at all. I really want to use originalArgs
     * and having one source of truth but having common logic while reducing code redundancy
     * also constrains on flexibility.
     *
     * I've decided to call the mutations on the components that really need it.
     * that way, we can modify the logic of one component on handling workflows
     * without harming the rest.
     */

    const [tokenValid, tokenValidMutation] = useTokenValidMutation()

    useEffect(() => {
        dispatch(setIsRevalidating(tokenValidMutation.isLoading))
    }, [tokenValidMutation.isLoading])

    // decided not to use a fixedCacheKey because each call should be a separate instance.
    // There have been cases where simulatneous API calls require a token validation in each one
    const callback = useCallback(async (token: {
        value: string;
        id: string;
    }, mode: '' | 'guest' | 'auth') => {
        let revalidatedToken = {
            id: '',
            value: ''
        }

        // console.log('double check on token before revalidation: ', token)
        await tokenValid({ token: token.value })
            .unwrap()
            .then((data) => {
                if (data.status === 'OK') {
                    dispatch(setToken({
                        id: token.id,
                        value: data.token,
                        valid: true,
                        mode
                    }))
                    revalidatedToken = {
                        id: token.id,
                        value: data.token
                    }

                    // no need to set to false because getWorkflow will be the last
                    // call to remove the overlay.
                    // dispatch(setIsFullscreenOverlay({
                    //     show: false,
                    //     text: ''
                    // }))
                } else if (data.status === 'NOT_OK') {
                    console.error(data.message)
                    // toast.error(
                    //     data.message,
                    //     { ...TOASTIFY_DEFAULT_OPTIONS }
                    // )
                    // instead of resetting the token, get a guest token
                    // no point in setting a false token dynamically.
                    // you want to get a guest token as long as the token
                    // is invalid. be it from reg or not.
                    doGetToken({
                        data: { locale: navigator.language }
                    })
                    // enable fullscreen
                    dispatch(setIsFullscreenOverlay({
                        show: true,
                        text: strings.app?.text.loading || ''
                    }))
                }
            })
        // prioritizes the FetchBaseQueryError type
            .catch((e) => {
                if (e && e instanceof Error) {
                    console.error(e)
                    // display toast  but i don't know what the default error response is.
                    // toast.error(
                    //     e.message,
                    //     { ...TOASTIFY_DEFAULT_OPTIONS }
                    // )
                    // reset the token data regardless.
                    // dispatch(setToken({
                    //     id: token.id,
                    //     value: token.value,
                    //     valid: false,
                    //     mode
                    // }))
                    // you want to get a guest token as long as the token
                    // is invalid. be it from reg or not.
                    doGetToken({
                        data: { locale: navigator.language }
                    })
                    // enable fullscreen
                    dispatch(setIsFullscreenOverlay({
                        show: true,
                        text: strings.app?.text.loading || ''
                    }))
                }
            })

        return revalidatedToken
    }, [strings])

    return callback
}
