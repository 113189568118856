// import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch } from '@app/app.hook'
import { setCurrentWorkflowStepProgress } from '@login/slices/slice.workflow'
import { TokenAuth } from '@app/types/type.app'
import { useEffect } from 'react'
// import { toast } from 'react-toastify'
import { UpdateWorkflowProgressResponse, UpdateWorkflowProgressRequest } from '@login/type'

const UpdateWorkflowProgressConsumer = ({ data, originalArgs }: {
    data?: UpdateWorkflowProgressResponse | undefined,
    originalArgs?: (UpdateWorkflowProgressRequest & TokenAuth) | undefined
}) => {
    const dispatch = useAppDispatch()
    /** unfortunately, it's best to put updateWorkflowProgress in the components
     * that need it.
     */

    useEffect(() => {
        // CANNOT USE FIXED CACHE KEY HERE.
        if (data && originalArgs) {
            if (data.status === 'OK') {
                // toast success message. DON'T display on empty successes
                // data.message && toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // now get the next workflow step the sameway through the
                // useEffect in the parent component. To do this, update
                // the current workflow step's progress.
                dispatch(setCurrentWorkflowStepProgress(
                    // by the time the mutation.data is triggered, this would
                    // have the updated value we want.
                    originalArgs.data.percentComplete
                ))
            } else {
                // toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [data, originalArgs])

    return <></> // Render nothing
}

export default UpdateWorkflowProgressConsumer
