import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { CareFacilityKeys, CareFacilityValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    facilityEditFormik: FormikProps<CareFacilityValues>
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>
        , fieldName?: CareFacilityKeys) => void
}

const Email = ({ readOnly, facilityEditFormik, handleKeyDown }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: CareFacilityKeys = 'facilityEmailAddress'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.FACILITY.EDIT.EMAIL} className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].email_address
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly={readOnly}
                    type={'text'}
                    name={fieldName}
                    className={[
                        'form-control-sm',
                        readOnly ? 'form-control-plaintext' : 'form-control',
                        facilityEditFormik.errors.facilityEmailAddress && 'border-danger'
                    ].join(' ')}
                    placeholder={!readOnly
                        ? strings.fmt?.text.facility.add.steps['1']
                            .email_address
                        : ''}
                    value={facilityEditFormik.values.facilityEmailAddress}
                    id={IDS.FACILITY.EDIT.EMAIL}
                    onBlur={facilityEditFormik.handleBlur}
                    onChange={!readOnly ? facilityEditFormik.handleChange : () => {}}
                    onKeyDown={handleKeyDown}
                    required
                />
                <div className={'form-text error'}>{
                    facilityEditFormik.errors.facilityEmailAddress
                        ? facilityEditFormik.errors.facilityEmailAddress
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default Email
