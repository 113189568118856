// eslint-disable-next-line max-len
import Bodymap from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/Bodymap'
import Bool from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/Bool'
// eslint-disable-next-line max-len
import FloatInput from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/FloatInput'
import List from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/List'
// eslint-disable-next-line max-len
import MultiList from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/MultiList'
// eslint-disable-next-line max-len
import MultiTile from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/MultiTile'
// eslint-disable-next-line max-len
import NumberInput from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/NumberInput'
import Open from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/Open'
// eslint-disable-next-line max-len
import Scale from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/Scale'
// eslint-disable-next-line max-len
import SingleDate from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/SingleDate'
import Text from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/Text'
// eslint-disable-next-line max-len
import Toggle from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/Toggle'
import { GPACareplanStepIdDetails } from '@doc/type'
import _ from 'lodash'
import { useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import { remarkDefinitionList } from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'
// eslint-disable-next-line max-len
import OpenList from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/open-list/OpenList'
// eslint-disable-next-line max-len
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
// eslint-disable-next-line max-len
import { useAppSelector } from '@app/app.hook'
import Pagination from '@app/components/Pagination'
import { selectStrings } from '@app/slices/slice.app'
// eslint-disable-next-line max-len
import MultiQuestion from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/multi-question/MultiQuestion'
import { useMediaQuery } from 'react-responsive'
// i need the element of reasoning data
const ReasoningAnswer = (props: {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject'],
    reasoningModal: {
        reasoningSessionId: string;
        reasoningSetId: string;
        addedOn: number;
    } | undefined,
    userId: string | undefined
    careplanStepId: string | undefined
}) => {
    // display the question as if you were gonna answer it but you can't.
    // don't use disabled but replace with readOnly AND removal of handlers.
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const data = props.reasoningObj
    const Question = useMemo(() => {
        /** how should the input be rendered */
        let input = <div></div>

        switch (data.questionData.questionType) {
            case 'multi-tile': input = <MultiTile reasoningObj={data} />
                break
            case 'multi-list': input = <MultiList reasoningObj={data}
                reasoningModal={props.reasoningModal}
                userId={props.userId}
                careplanStepId={props.careplanStepId}
            />
                break
            case 'scale': input = <Scale reasoningObj={data} />
                break
            case 'list': input = <List reasoningObj={data} />
                break
            case 'int32':
                input = <NumberInput reasoningObj={data}/>
                break
            case 'bodymap': input = <Bodymap reasoningObj={data} />
                break
            case 'bool': input = <Bool reasoningObj={data} />
                break
            case 'toggle': input = <Toggle reasoningObj={data} />
                break
            case 'open':
                input = <Open reasoningObj={data}/>
                break
            case 'float':
                input = <FloatInput reasoningObj={data}/>
                break
            case 'text': input = <Text reasoningObj={data} />
                break
            case 'date': input = <SingleDate reasoningObj={data} />
                break
            case 'open-list': input = <OpenList reasoningObj={data}
                reasoningModal={props.reasoningModal}
                userId={props.userId}
                careplanStepId={props.careplanStepId}/>
                break
            case 'multi-question': input = <MultiQuestion
                reasoningObj={data}
            />
                break
            case 'view': input = <span>{data.data.data.answerValue}</span>
                break
            default:
                break
        }

        const forMarkdownContent = _.isString(data?.questionData?.questionDescription)
            ? data?.questionData?.questionDescription
                .replace(/==([^=]+)==/g, '<mark>$1</mark>')
                .replace(/~(\d+)~/g, '<sub>$1</sub>')
                .replace(/~~([^~]+)~~/g, '<s>$1</s>')
            : ''

        // mobile header segment.
        const mobileHeaderSegment = <h5 className={data?.questionData?.questionType === 'text'
            ? 'fw-semibold mb-3 mt-4'
            : 'text-center fw-semibold mb-3 mt-4'}>
            {data?.questionData?.questionHeader}
        </h5>
        // desktop header segment
        const desktopHeaderSegment = <h5 className={data?.questionData?.questionType === 'text'
            ? 'mt-5 mb-3'
            : 'text-center mt-5 mb-3'}>
            {data?.questionData?.questionHeader}
        </h5>

        const content = <div>
            {/* question header */}
            {
                isMobile ? mobileHeaderSegment : desktopHeaderSegment
            }
            {
                data?.questionData?.questionType !== 'text'
                    ? <span className={'my-4 fw-light text-center'}>
                        <div className={'markdown'}>
                            <ReactMarkdown
                                components={{
                                    // supersub replaces markdown with del tags
                                    // for somereason.
                                    del: (props) => <sub {...props} />,
                                    ul: (props) => {
                                        const modifiedProps = { ...props }
                                        // eslint-disable-next-line react/prop-types
                                        modifiedProps.ordered = props.ordered.toString() as any

                                        if (modifiedProps.className && modifiedProps.className
                                            .includes('contains-task-list')) {
                                            return <ul
                                                {...modifiedProps}
                                                className={[
                                                    'contains-task-list list-unstyled ps-4'
                                                ].join(' ')}
                                            />
                                        } else {
                                            return <ul
                                                {...modifiedProps}

                                            />
                                        }
                                    }
                                }}
                                linkTarget={'_blank'}
                                remarkPlugins={[
                                    remarkBreaks,
                                    remarkGfm,
                                    supersub,
                                    remarkEmoji,
                                    remarkDefinitionList,
                                    remarkHeadingId
                                ]} rehypePlugins={[
                                    rehypeRaw
                                ]}
                            >
                                {`${ forMarkdownContent }`}
                            </ReactMarkdown>
                        </div>
                    </span>
                    : ''
            }

            {/* try to use the key prop so the component is recognized as a new one */}
            <div>
                {input}
            </div>
        </div>

        return (
            content
        )
        // on second thought, not adding inputKey as a dependency.
    }, [props.reasoningObj])

    return <div className={'reasoning-answer-card'}>
        {Question}
    </div>
}

interface ComponentProps {
    reasoningData:GPACareplanStepIdDetails['response']['reasoningData'] | undefined
    currentPage: number;
    setCurrentPageState?: (value: number) => void;
    reasoningModal: {
        reasoningSessionId: string;
        reasoningSetId: string;
        addedOn: number;
    } | undefined,
    userId: string | undefined
    careplanStepId: string | undefined
}
const ReadonlyReasonWithMe = ({
    reasoningData, currentPage, setCurrentPageState, reasoningModal, userId, careplanStepId
}: ComponentProps) => {
    // ok so you want to display the careplan step the way it is BUT readonly version.
    // best to do it from scratch. start with how to render the data.
    // we can import types from careplan to make less time.
    const data = reasoningData?.data
    const strings = useAppSelector(selectStrings)

    // shouldn't be responsive.
    const reasoningDataResults = <div>
        {
            data?.reasoningData?.length
                ? _.map(data?.reasoningData, (o) => {
                    const key = `reasoning-answer-${ o.id }`

                    return <ReasoningAnswer key={key} reasoningObj={o}
                        reasoningModal={reasoningModal}
                        userId={userId}
                        careplanStepId={careplanStepId}
                    />
                })
                : strings.app?.message.error.empty_table
        }
    </div>

    const result = <div className={'row gx-0'}>
        <div className={'col-12'}>
            {reasoningDataResults}
        </div>
        <div className={'col-12 pb-6'}>
            {
                (data?.limit || 0) > (data?.totalCount?.[0]?.totalRecords || 0)
                    ? <span>{
                        strings.app?.message.error.empty_records || ''
                    }</span>
                    : <Pagination
                        currentPage={currentPage}
                        setCurrentPageState={setCurrentPageState}
                        limit={data?.limit || data?.totalCount[0]?.totalRecords || 1}
                        skip={data?.skip || 1}
                        totalRecords={data?.totalCount[0]?.totalRecords || 0}
                    />
            }

        </div>
        {/* <div className={'col-auto border-start'}>
        </div> */}
    </div>

    return <>
        <h4 className={'mb-5 mt-3 text-center'}>{
            strings.doc
                ?.text.patient.actions_in_app.answer_summary.second_step.given_answers}
        </h4>
        {
            data !== undefined ? result : ''
        }
    </>
}
export default ReadonlyReasonWithMe
