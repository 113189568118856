import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import DepartmentData from '@fmt/components/facilities/add/steps/StepFive/DepartmentData'
import { CareFacilityValues, Department } from '@fmt/type'
import { FormikProps } from 'formik'
import _ from 'lodash'

/** summary is to iterate the list of selected treatements
 * and then select its carepath paths
 */
interface ComponentProps {
    facilityStepOneAddFormik: FormikProps<CareFacilityValues>,
    facilityStepTwoAddFormik: FormikProps<{
        linkedDepartments: Department[]
    }>,
}

const Main = ({
    facilityStepOneAddFormik,
    facilityStepTwoAddFormik
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const FacilityNameInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].facility_name
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={facilityStepOneAddFormik.values.facilityName}
                />
            </div>

        </div>

    </div>

    const AddressLine1Input = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['5'].address_line_1
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        facilityStepOneAddFormik.values.facilityAddressStreet,
                        facilityStepOneAddFormik.values.facilityAddressNumber,
                        facilityStepOneAddFormik.values.facilityAddressSuffix
                    ].join(' ')}
                />
            </div>

        </div>

    </div>

    const AddressLine2Input = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['5'].address_line_2
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        facilityStepOneAddFormik.values.facilityPostalcode,
                        facilityStepOneAddFormik.values.facilityCity
                    ].join(' ')}
                />
            </div>

        </div>

    </div>

    const GeneralPhoneNumberInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].general_telephone_number
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        facilityStepOneAddFormik.values.facilityPhonenumber
                    ].join(' ')}
                />
            </div>

        </div>

    </div>

    const EmailInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].email_address
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        facilityStepOneAddFormik.values.facilityEmailAddress
                    ].join(' ')}
                />
            </div>

        </div>

    </div>

    const WebsiteInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].website
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        facilityStepOneAddFormik.values.facilityWebsite
                    ].join(' ')}
                />
            </div>

        </div>

    </div>

    return <>
        <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text.facility.add.overview}</h2>

        {/* facility data BUT readonly and no change events. */}

        {/* you could copy the input from step one BUT there are some fields
        that are displayed different */}
        <h4 className={'mb-0'}>{strings.fmt?.text.facility.menu.facility_data}</h4>
        {FacilityNameInput}
        {AddressLine1Input}
        {AddressLine2Input}
        {GeneralPhoneNumberInput}
        {EmailInput}
        {WebsiteInput}

        <h4 className={'mb-6 mt-6'}>{strings.fmt?.text.facility.add.departments}</h4>

        {
            _.map(facilityStepTwoAddFormik.values.linkedDepartments, (dept, linkedDeptIndex) => {
                return <div className={'mb-4'} key={linkedDeptIndex}>
                    <DepartmentData department={dept}
                    />
                </div>
            })
        }

    </>
}

export default Main
