import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { CarepathKeys, CarepathValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    carepathEditFormik: FormikProps<CarepathValues>
    handleKeyDown: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CarepathKeys
    ) => void
}

const CarepathName = ({ readOnly, carepathEditFormik, handleKeyDown }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: CarepathKeys = 'carepathName'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.CAREPATH.EDIT.NAME}
                    className={'form-label'}>{
                        strings.fmt?.text.department.menu.name
                    }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly={readOnly}
                    type={'text'}
                    name={fieldName}
                    className={[
                        'form-control-sm',
                        readOnly ? 'form-control-plaintext' : 'form-control',
                        carepathEditFormik.errors.carepathName &&
                         'border-danger'
                    ].join(' ')}
                    placeholder={!readOnly ? strings.fmt?.text.department.menu.name : ''}
                    value={carepathEditFormik.values.carepathName}
                    id={IDS.CAREPATH.EDIT.NAME}
                    onBlur={carepathEditFormik.handleBlur}
                    onChange={!readOnly ? carepathEditFormik.handleChange : () => {}}
                    onKeyDown={handleKeyDown}
                    required
                />
                <div className={'form-text error'}>{
                    carepathEditFormik.errors.carepathName
                        ? carepathEditFormik.errors.carepathName
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default CarepathName
