import styled from '@emotion/styled'

export const ImageDisplay = styled.div<{url:string}>`

    &.header{
        height: 40px;
        width: 40px;
    }

    &.profile{
        height: 136px;
        width: 136px;
    }

    background-image: url(${ (props) => '"' + props.url + '"' });
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
`
