import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import TooltipSlider from '@app/components/TooltipSlider'
import { OpenMultiQuestionActions, QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'
import { SliderProps } from 'rc-slider'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    ids:{
        activeOptionId: string,
        questionId: string,
        answerIndex: number
    }
    obj:{
        question: QuestionAnswer & {
            id: string;
        };
    }
    answer: {
        hasAnswer: boolean;
        actualValue: any;
    }
    formsDispatch: React.Dispatch<OpenMultiQuestionActions>
}

const checkTypeForSliderValue: (value: any) => number | number[] | undefined = (value) => {
    return typeof value === 'number'
        ? value
        : Array.isArray(value) && value.every(item => typeof item === 'number')
            ? value
            : value === null || value === undefined
                ? value
                : undefined
}

const checkTypeForCheckboxValue: (value: any) => (number | string)[] | undefined = (value) => {
    return Array.isArray(value) && value.every(item => typeof item === 'number' ||
        typeof item === 'string')
        ? value
        : value === null || value === undefined
            ? value
            : undefined
}

const Scale = ({
    ids,
    obj,
    answer,
    formsDispatch
}: ComponentProps) => {
    // start of as undefined but the moment you switch boxes, sometimes, the checkboxAnsweers
    // will be set with a number instead.... and that's not good.
    const [
        sliderValue,
        setSliderValue
    ] = useState<number | number[] | undefined>(checkTypeForSliderValue(
        answer.actualValue
    ))

    const [
        checkboxAnswers,
        setCheckboxAnswers
    ] = useState<(number | string)[] | undefined>(
        checkTypeForCheckboxValue(answer.actualValue)
    )

    const containerRef = useRef<HTMLDivElement | null>()
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        // checkbox answers hold more priority than silder value
        formsDispatch({
            type: 'UPDATE_ANSWER_VALUE',
            ids,
            answer: {
                hasAnswer: checkboxAnswers !== undefined || sliderValue !== undefined,
                actualValue: checkboxAnswers || sliderValue
            }
        })
    }, [checkboxAnswers, sliderValue])

    // TEMPORARY FIX but would approve to be a permanent fix.
    // by default, once you got the lowest and biggest values
    // set the answerValue right away. The answerValue is the slider value to the very left.
    // however, only do this if the answer is not defined.
    useEffect(() => {
        const choices = obj.question?.answerQuestions?.[ids.answerIndex].questionAnswers

        let marks: SliderProps['marks'] = {}
        const foundCustomMarker = _.find(choices, o =>
            typeof o.answerValue !== 'number' ||
            o.answerValue === null ||
           (o.answerValue === undefined &&
            typeof o.rangeUpper === 'number' && typeof o.rangeLower === 'number')
        )

        if (foundCustomMarker) {
            if (typeof foundCustomMarker.rangeUpper === 'number' &&
                typeof foundCustomMarker.rangeLower === 'number') {
                marks = {
                    [foundCustomMarker.rangeLower]: foundCustomMarker.rangeLower,
                    [foundCustomMarker.rangeUpper]: foundCustomMarker.rangeUpper
                }
            }
        } else {
            marks = _.reduce(choices, (storage: typeof marks, obj, index) => {
                if (!storage) {
                    storage = {}
                }

                if (obj.answerValue !== undefined) {
                    let element = <span>
                        {obj.answerValue}
                    </span>

                    if (index === 0 || (choices && index === choices.length - 1)) {
                        element = <b>
                            {obj.answerValue}
                        </b>
                    }
                    storage[obj.answerValue] = {
                        label: element
                    }
                }

                return storage
            }, {})
        }

        const values = _.toPairs(marks)
            .map((arr) => {
                return arr[0]
            })

        const middleIndex = Math.floor(values.length / 2)

        // do this if undefined. if you don't, you'll keep getting this answer.
        !sliderValue && setSliderValue(Number(values[middleIndex]))
    }, [obj.question?.answerQuestions?.[ids.answerIndex].questionAnswers])

    const validateData = (data: QuestionAnswer[]) => {
        let nullAnswerValueRange = false
        let otherAnswerValuesRange = 0

        for (let i = 0; i < data.length; i++) {
            const answerValue = data[i].answerValue
            const rangeUpper = data[i].rangeUpper
            const rangeLower = data[i].rangeLower

            if (answerValue === null &&
                typeof rangeUpper === 'number' &&
                typeof rangeLower === 'number') {
                nullAnswerValueRange = true
            } else if (
                typeof answerValue === 'number' &&
                (
                    !_.isNumber(rangeUpper) ||
                    !_.isNumber(rangeLower)
                )
            ) {
                otherAnswerValuesRange++
            }
        }

        return nullAnswerValueRange && otherAnswerValuesRange > 0
    }

    const input = useMemo(() => {
        /** how should the input be rendered */

        // get the lowValue and highValue of the element that contains numbers.
        const choices = obj.question?.answerQuestions?.[ids.answerIndex].questionAnswers

        // NOTE: if the scaleObjects array is just one, use the rangeLower AND rangeUpper
        // as our marks.

        // the marks are now dynamic. in one of the question segments, there should be 6 marks.
        let marks: SliderProps['marks'] = {}

        const withRanges = _.filter(choices, (obj) => {
            return (_.isNumber(obj.rangeUpper) && _.isNumber(obj.rangeLower))
        })
        // assign labels from the smallest and larget values.
        // but exclude choices that have rangeUpper and/or rangeLower values as null.
        let smallestLabel = ''
        let centerLabel = ''
        let largestLabel = ''

        if (validateData(choices || [])) {
            centerLabel = _.find(choices, (o) => {
                return o.answerValue === null &&
                typeof o.rangeUpper === 'number' &&
                typeof o.rangeLower === 'number'
            })?.answerName || ''
        } else {
            smallestLabel = _.minBy(withRanges, 'answerValue')?.answerName || ''
            largestLabel = _.maxBy(withRanges, 'answerValue')?.answerName || ''
        }

        // check for an object that looks like this:
        /**
         {
            "answerName": "Aantal dagen",
            "answerValue": null,
            "rangeUpper": 365,
            "rangeLower": -1
         }
         If found, set the rangeUpper and rangeLower values to the marks.
         */

        const foundCustomMarker = _.find(choices, o =>
            typeof o.answerValue !== 'number' ||
            o.answerValue === null ||
           (o.answerValue === undefined &&
            typeof o.rangeUpper === 'number' && typeof o.rangeLower === 'number')
        )

        if (foundCustomMarker) {
            if (typeof foundCustomMarker.rangeUpper === 'number' &&
                typeof foundCustomMarker.rangeLower === 'number') {
                marks = {
                    [foundCustomMarker.rangeLower]: foundCustomMarker.rangeLower,
                    [foundCustomMarker.rangeUpper]: foundCustomMarker.rangeUpper
                }
            }
        } else {
            // checked from console log
            // const elementWidth = 50
            // // Width of one element in pixels
            // const containerWidth = containerRef.current?.clientWidth || 1
            // // Width of the container in pixels

            // const preferredLimit = Math.floor(containerWidth / elementWidth)

            // console.log(elementWidth, containerWidth, preferredLimit)

            marks = _.reduce(withRanges, (storage: typeof marks, obj, index) => {
                if (!storage) {
                    storage = {}
                }

                if (obj.answerValue !== undefined) {
                    let element = <span>
                        {obj.answerValue}
                    </span>

                    if (index === 0 || (choices && index === choices.length - 1)) {
                        element = <b>
                            {obj.answerValue}
                        </b>
                    }
                    storage[obj.answerValue] = {
                        label: element
                    }
                }

                return storage
            }, {})
        }

        const smallestKeyValue = _.toPairs(marks)
            .reduce((smallest, current) => {
                const currentKey = parseInt(current[0])
                const smallestKey = parseInt(smallest[0])
                return (currentKey < smallestKey) ? current : smallest
            })

        const largestKeyValue = _.toPairs(marks)
            .reduce((largest, current) => {
                const currentKey = parseInt(current[0])
                const largestKey = parseInt(largest[0])
                return (currentKey > largestKey) ? current : largest
            })

        // const values = _.toPairs(marks)
        //     .map((arr) => {
        //         return arr[0]
        //     })

        // const middleIndex = Math.floor(values.length / 2)

        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', ids.questionId
            ].join('')

            const isChecked = _.includes(
                answer.actualValue,
                obj.answerValue
            )

            // const isDisabled = _.isArray(answerValue)
            //     ? answerValue.length >= (data.question?.questionAnswersAllowed || 0)
            //     : false

            const isDisabled = false

            const selectionCellOnclick = () => {
                if (_.isArray(answer.actualValue)) {
                    const found = _.find(answer.actualValue, o => {
                        return (
                            o === obj.answerValue
                        )
                    })

                    if (found !== undefined) {
                        /** remove from list. */
                        const arr = _.filter(answer.actualValue, o => {
                            return (
                                o !== found
                            )
                        })

                        /** if the arr has no elements, turn to undefined */
                        setCheckboxAnswers(arr.length <= 0 ? undefined : arr)
                    } else {
                        setCheckboxAnswers(
                            _.concat(answer.actualValue, obj.answerValue)
                        )
                    }
                } else {
                    if (obj.answerValue === answer.actualValue) {
                        setCheckboxAnswers(undefined)
                    } else {
                        if (obj.answerValue !== undefined) {
                            setCheckboxAnswers([obj.answerValue])
                        }
                    }
                }
            }

            return <div className={'col'} key={key}>
                <input type={'checkbox'}
                    className={'btn-check'}
                    autoComplete={'off'}
                    id={key}
                    checked={isChecked}
                    disabled={isDisabled && !isChecked}
                    onChange={() => {
                        if (!(isDisabled && !isChecked)) {
                            selectionCellOnclick()
                        }
                    }}
                />
                <label className={'btn btn-dummy'} htmlFor={key}>

                    <div className={'question-checkbox'}>
                        <div className={'card justify-content-center px-3 py-2'}>

                            <div className={'d-flex'}>
                                <div className={'d-flex align-items-center'}>
                                    <div className={'p text-start'}>
                                        <span style={{ verticalAlign: 'inherit' }}>
                                            <span style={{ verticalAlign: 'inherit' }}>
                                                {obj.answerName}
                                            </span>
                                        </span></div>
                                    {
                                        isChecked
                                            ? <div className={'question-checkmark ms-auto'}>
                                                <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                            </div>
                                            : <div className={'question-plussign ms-auto'}>
                                                <i className={'fa-light fa-plus mx-auto'}></i>
                                            </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>

                </label>
            </div>
        }

        // if answerValue is undefined, then assign it the value in the middle.
        // answerValue would be only undefined in the beginning anyway.
        // NOTE: tried on useEffect but I ended up copying a lot of the code
        // which is not good. Doing this on useMemo will result in this error:
        // Warning: Cannot update a component (`Unknown`) while rendering a different component
        // no choice but to properly make a useEffect for it

        return <div><div className={'card scale-card'}>
            <div className={'card-body'}>
                <div className={'container'}>
                    {
                        !isMobile && <div className={'row'}>
                            {
                                centerLabel
                                    ? <span className={'col text-center'}>
                                        {centerLabel}
                                    </span>
                                    : <>
                                        <span className={'col'}>
                                            {smallestLabel}
                                        </span>
                                        <span className={'col text-end'}>
                                            {largestLabel}
                                        </span></>
                            }

                        </div>
                    }
                    <TooltipSlider
                        disabled={checkboxAnswers && checkboxAnswers.length > 0}
                        marks={marks}
                        // step={data.question?.valueType === 'int' ? 1 : 0.1}
                        step={1}
                        min={Number(smallestKeyValue[0])}
                        max={Number(largestKeyValue[0])}
                        tipFormatter={(value) => {
                            const found = _.find((choices || []), o => {
                                return o.answerValue === value
                            })
                            return `${ found?.answerName || value }`
                        }}
                        tipProps={null}
                        // do not assign a middle value anymore. should be undefined
                        // defaultValue={Number(values[middleIndex])}
                        defaultValue={undefined}
                        // if also disabled, find a way to retain the value
                        // of this slider when checkboxes
                        // are checked.
                        // value={answerValue}
                        // just show sliderValue instead.
                        value={sliderValue}
                        handleStyle={{
                        // display: checkboxAnswers === undefined ? 'none' : 'block'
                        // if checkboxAnswers is undefined, check the sliderValue
                        // if it has a value.
                        // if not, keep it hidden
                            display: checkboxAnswers === undefined &&
                            sliderValue !== undefined
                                ? 'block'
                                : 'none'
                        }}
                        onChange={(e) => {
                            setSliderValue(e)
                        }}
                    />
                </div>

            </div>

        </div>
        {/* A new feature has been added that involves rendering the other
         questionAnswer elements as checkboxes with a label next to them
          if their rangeLower and rangeUpper values are null.
         */}
        <div
            className={'row row-cols-1 btn-group-checkbox-list mt-3'}
            role={'group'}
        >
            {
            // only select those that have a rangeUpper and rangeLower
                _.map(_.filter(choices, (obj) => {
                    return !(_.isNumber(obj.rangeUpper) && _.isNumber(obj.rangeLower))
                }), renderInputs)
            }
        </div>
        </div>
    }, [obj, checkboxAnswers, isMobile])

    const AnswerDescriptions = useMemo(() => {
        // select which data to use (if you are from detailed lessons
        // OR from the reason with me interface)
        const data = obj.question?.answerQuestions?.[ids.answerIndex].questionAnswers

        // this is supposedly a number so we'll have to find it in the questionAnswers

        const foundChoice = _.find(data, (o) => {
            return o.answerValue === answer.actualValue
        })

        return foundChoice
            ? <div className={'text-center'} >
                {foundChoice.answerIcon
                    ? <img
                        className={[
                            'icon icon-xxl text-primary my-3',
                            checkboxAnswers ? 'opacity-50' : ''
                        ].join(' ')}
                        src={foundChoice.answerIcon}></img>
                    : '' }
                {foundChoice.answerName
                    ? <p className={'fw-bold mb-2'}>
                        {foundChoice.answerName}
                    </p>
                    : '' }

                {foundChoice.answerText
                    ? <p>
                        {foundChoice.answerText}
                    </p>
                    : '' }

            </div>
            : ''
    }, [obj])

    return <div className={['col question-scale', isMobile
        ? 'px-4'
        : 'px-4'].join(' ')} ref={(e) => {
        containerRef.current = e
    }}>

        <div className={'card multi-question-card'}>
            <div className={'card-body'}>
                {/* answerIcon, name and text goes here. Answer will modify the content here. */}
                {
                    obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                        ? <h5>
                            {
                                obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                            }
                        </h5>
                        : ''
                }

                {
                    obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                        ? <span className={'mb-2'}>
                            {
                                obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                            }
                        </span>
                        : ''
                }

                {AnswerDescriptions}
                {/* <p className={'text-center'}>{
                    `${ strings.reason_with_me?.text.list.allowed || '' }:
                    ${ questionInterface.currentReasonWithMeResponse
                        .reasoningData.question
                        ?.questionAnswersAllowed
                    }`.trim()
                }</p> */}
                {input}
            </div>
        </div>

    </div>
}

export default Scale
