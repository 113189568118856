/* eslint-disable no-unused-vars */
import { RootState } from '@app/app.store'
import { WorkflowStep } from '@login/type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RegistrationState } from '@registration/type'

const getFromLocalStorage : () => RegistrationState = () => {
    const workflowId = localStorage.getItem('workflowId') ||
        sessionStorage.getItem('workflowId') || ''

    const moduleId = localStorage.getItem('moduleId') ||
        sessionStorage.getItem('moduleId') || ''
    return {
        workflowId,
        moduleId,
        currentWorkflowStep: undefined,
        workflowCount: 0,
        renderRoutesInitial: false
    }
}

const initialState: RegistrationState = getFromLocalStorage()
export const slice = createSlice({
    name: 'workflowV10',
    initialState,
    reducers: {
        setWorkflowId: (state: RegistrationState, action: PayloadAction<string>) => {
            state.workflowId = action.payload
            localStorage.setItem('workflowId', action.payload)
        },
        setModuleId: (state: RegistrationState, action: PayloadAction<string>) => {
            state.moduleId = action.payload
            localStorage.setItem('moduleId', action.payload)
        },
        setCurrentWorkflowStep: (
            state: RegistrationState,
            action: PayloadAction<WorkflowStep | undefined
             >) => {
            state.currentWorkflowStep = action.payload
        },
        setWorkflowCount: (state: RegistrationState, action: PayloadAction<number>) => {
            state.workflowCount = action.payload
        },
        setCurrentWorkflowStepProgress: (
            state: RegistrationState, action: PayloadAction<number>
        ) => {
            if (state.currentWorkflowStep) {
                state.currentWorkflowStep.stepProgress.percentComplete = action.payload
            }
        },
        setRenderRoutesInitial: (state: RegistrationState, action: PayloadAction<boolean>) => {
            state.renderRoutesInitial = action.payload
        },
        resetWorkflow: (state: RegistrationState) => {
            state.moduleId = ''
            state.workflowId = ''
            state.currentWorkflowStep = undefined
            state.workflowCount = 0
            // you could change the renderWorkflow value right here but risk on never
            // seeing the login pages because there's no workflow needed there.
            // instead, you can split the routes and choose which to render
            // based on external conditions.
            state.renderRoutesInitial = false
        }
    }
})

// eslint-disable-next-line no-empty-pattern
export const {
    resetWorkflow,
    setModuleId,
    setWorkflowId,
    setCurrentWorkflowStep,
    setWorkflowCount,
    setCurrentWorkflowStepProgress,
    setRenderRoutesInitial
} = slice.actions

export const selectWorkflowId = (state: RootState) => state.workflowV10.workflowId
export const selectModuleId = (state: RootState) => state.workflowV10.moduleId
export const selectWorkflowCount = (state: RootState) => state.workflowV10.workflowCount
export const selectRenderRoutesInitial = (state: RootState) => state.workflowV10.renderRoutesInitial
export const selectCurrentWorkflowStep = (state: RootState) =>
    state.workflowV10.currentWorkflowStep

export default slice.reducer
