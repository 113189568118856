import { OpenListInputs } from '@reasonWithMe/type'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
}

const Radio = ({
    answerType, outerIndex, innerIndex,
    formInput
}: ComponentProps) => {
    return <label className={'custom-radio'}>
        <input
            type={'radio'}
            name={`${ answerType }-${ outerIndex }-${ innerIndex }`}
            disabled={!formInput.answerEditable}
            checked={formInput.userTypedAnswer as boolean}
            onChange={(e) => {}}
        />
        <span className={'checkmark'}>
            <i className={'fa-solid fa-check mx-auto'}></i>
        </span>
    </label>
}

export default Radio
