import { useEffect, useMemo, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useAddDepartmentCareFacilityMutation, useAddDepartmentMutation } from '@fmt/api'
import Address from '@fmt/components/departments/details/inputs/Address'
import City from '@fmt/components/departments/details/inputs/City'
import DepartmentName from '@fmt/components/departments/details/inputs/DepartmentName'
import Email from '@fmt/components/departments/details/inputs/Email'
import GeneralPhoneNumber from '@fmt/components/departments/details/inputs/GeneralPhoneNumber'
import PostalCode from '@fmt/components/departments/details/inputs/PostalCode'
import StreetName from '@fmt/components/departments/details/inputs/StreetName'
import Website from '@fmt/components/departments/details/inputs/Website'
import { DEPARTMENT_FORMIK_INITIAL_VALUES, DEPARTMENT_VALIDATION_SCHEMA } from '@fmt/constants'
import { selectFacilityMenu } from '@fmt/slice'
import { DepartmentKeys } from '@fmt/type'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

interface ComponentProps {
    fetchData: (token: TokenData) => () => void
    showAddDepartmentInterface: boolean
    setShowAddDepartmentInterface: React.Dispatch<React.SetStateAction<boolean>>
}

const QuickAddDepartment = ({
    fetchData, showAddDepartmentInterface,
    setShowAddDepartmentInterface
}:ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const facilityMenu = useAppSelector(selectFacilityMenu)

    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false)
    const [submissionFlag, setSubmissionFlag] = useState<
    'SAVE_AND_CLOSE' | 'SAVE_AND_ADD_ANOTHER' | ''
    >('')
    const [addDepartment, addDepartmentMutation] = useAddDepartmentMutation()
    const [
        addDepartmentCareFacility
    ] = useAddDepartmentCareFacilityMutation({})

    const departmentAddFormik = useFormik({
        initialValues: DEPARTMENT_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DEPARTMENT_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.addDepartment.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        addDepartment({
                            authToken: newToken.value,
                            data: {
                                departmentName: values.departmentName || '',
                                departmentAddressStreet: values.departmentAddressStreet,
                                departmentAddressNumber: values.departmentAddressNumber,
                                departmentAddressSuffix: values.departmentAddressSuffix || '',
                                departmentPostalcode: values.departmentPostalcode,
                                departmentCity: values.departmentCity,
                                departmentPhonenumber: values.departmentPhonenumber || '',
                                departmentEmailAddress: values.departmentEmailAddress || '',
                                departmentWebsite: values.departmentWebsite || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = addDepartmentMutation.data

        if (data) {
            if (data.status === 'OK') {
                const foundApiPath = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.apiPaths.addDepartmentCareFacility.path,
                    true
                )
                if (foundApiPath) {
                    addDepartmentCareFacility({
                        authToken: token.value,
                        data: {
                            departmentId: data.data.departmentId || '',
                            facilityId: facilityMenu.addInterfaceProps.facilityId
                        }
                    }).unwrap().then((data) => {
                        if (data.status === 'OK') {
                            //
                            fetchData(token)

                            // then check submission flag and change back to ''
                            if (submissionFlag === 'SAVE_AND_CLOSE') {
                                // departmentAddFormik.resetForm()
                                departmentAddFormik.setFieldValue('departmentName', '')
                                // then hide the form.
                                setShowAddDepartmentInterface(false)
                                setSubmissionFlag('')
                            } else if (submissionFlag === 'SAVE_AND_ADD_ANOTHER') {
                                // departmentAddFormik.resetForm()
                                departmentAddFormik.setFieldValue('departmentName', '')
                                setSubmissionFlag('')
                            }
                        } else {
                            toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        }
                    })
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [addDepartmentMutation.data])

    useEffect(() => {
        if (addDepartmentMutation.error) {
            const message = getErrorText(addDepartmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addDepartmentMutation.error])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: DepartmentKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                departmentAddFormik.handleSubmit()
            }
        }
    }

    const DepartmentNameInput = useMemo(() => {
        return <DepartmentName
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentPostalcode')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentName,
        departmentAddFormik.errors.departmentName
    ])

    const PostalCodeInput = useMemo(() => {
        return <PostalCode
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentAddressNumber')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentPostalcode,
        departmentAddFormik.errors.departmentPostalcode
    ])

    const AddressInput = useMemo(() => {
        return <Address
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={handleKeyDown}

        />
    }, [

        strings,
        departmentAddFormik.values.departmentAddressNumber,
        departmentAddFormik.errors.departmentAddressNumber,
        departmentAddFormik.values.departmentAddressSuffix,
        departmentAddFormik.errors.departmentAddressSuffix
    ])

    const StreetNameInput = useMemo(() => {
        return <StreetName
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentCity')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentAddressStreet,
        departmentAddFormik.errors.departmentAddressStreet
    ])

    const CityInput = useMemo(() => {
        return <City
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentPhonenumber')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentCity,
        departmentAddFormik.errors.departmentCity
    ])

    /** GROUP 2 */

    const GeneralPhoneNumberInput = useMemo(() => {
        return <GeneralPhoneNumber
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentEmailAddress')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentPhonenumber,
        departmentAddFormik.errors.departmentPhonenumber
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentWebsite')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentEmailAddress,
        departmentAddFormik.errors.departmentEmailAddress
    ])

    const WebsiteInput = useMemo(() => {
        return <Website
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { departmentAddFormik.handleSubmit() }
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentWebsite,
        departmentAddFormik.errors.departmentWebsite
    ])

    const CancelButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            // if you made changes to the formik, show a warning first via popup
            const obj = departmentAddFormik.touched

            interface FormikTouched {
                [key: string]: boolean;
              }

            function hasTrueValue (obj: FormikTouched) {
                for (const key in obj) {
                    if (obj[key] === true) {
                        return true
                    }
                }
                return false
            }

            const hasTrue = hasTrueValue(obj)
            if (hasTrue) {
                // show warning pls
                setShowCancelWarning(true)
            } else {
                // reset formik.
                departmentAddFormik.resetForm()
                setShowAddDepartmentInterface(false)
            }
        }}>
        {strings.app?.text.cancel}
    </button>

    const SaveAndCloseButton = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        )

        return <button type={'button'}
            disabled={
            // step 1 shouldn't be disabled here because of formik.
                false
            // step 2 can be skipped entirely.
            }
            className={[
                'btn btn-primary w-100 fw-semibold py-2'
            ].join(' ')}
            onClick={() => {
                departmentAddFormik.handleSubmit()
                setSubmissionFlag('SAVE_AND_CLOSE')
            }}>
            { addDepartmentMutation.isLoading
                ? LoadingContent
                : strings.app?.text.save_and_close}
        </button>
    }, undefined)

    const SaveAndAddAnotherButton = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        )

        return <button type={'button'}
            disabled={
            // step 1 shouldn't be disabled here because of formik.
                false
            // step 2 can be skipped entirely.
            }
            className={[
                'btn btn-primary w-100 fw-semibold py-2'
            ].join(' ')}
            onClick={() => {
                departmentAddFormik.handleSubmit()
                setSubmissionFlag('SAVE_AND_ADD_ANOTHER')
            }}>
            { addDepartmentMutation.isLoading
                ? LoadingContent
                : strings.fmt?.text.facility.add.steps['2'].save_and_add_more}
        </button>
    }, undefined)

    const result = <div className={'card mt-3 p-5'}>
        <h2 className={'mb-5 fw-semibold '} >{strings.fmt
            ?.text.facility.add.steps['2'].department_data}</h2>
        {/* group one */}
        <div className={'mb-6'}>
            <h4 className={'mb-6'}>{strings.fmt
                ?.text.facility.menu.name_and_address_details}</h4>
            {DepartmentNameInput}
            {PostalCodeInput}
            {AddressInput}
            {StreetNameInput}
            {CityInput}
        </div>
        {/* group two */}

        <div>
            <h4 className={'mb-6'}>{strings.fmt
                ?.text.facility.menu.contact_details}</h4>
            {GeneralPhoneNumberInput}
            {EmailInput}
            {WebsiteInput}
        </div>

        <div className={'container'}>
            <div className={'row align-items-center mt-6'}>
                <div className={'col-4 col-lg-3 col-md-4 offset-lg-3 offset-md-0'}>
                    {CancelButton}
                </div>
                <div className={'col-4 col-lg-3 col-md-4 offset-md-0'}>
                    {SaveAndCloseButton}
                </div>
                <div className={'col-4 col-lg-3 col-md-4'}>
                    {SaveAndAddAnotherButton}
                </div>
            </div>
        </div>

        <Modal centered={true} isOpen={showCancelWarning} toggle={() => {
            setShowCancelWarning(!showCancelWarning)
        }}>
            <ModalHeader className={'justify-content-between'} toggle={() => {
                setShowCancelWarning(!showCancelWarning)
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    setShowCancelWarning(false)
                }}>
                <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                </i>
            </a>}
            >
                <span className={'h5'}>{
                    strings.fmt?.message?.data_edit_warning?.title || ''
                }</span>
            </ModalHeader>
            <ModalBody>
                {/* content changes if idle / expire sessions expired. */}
                <small className={'d-block my-4'}>
                    {strings.fmt?.message?.data_edit_warning?.content || ''}
                </small>
                <div className={'row justify-content-end'}>
                    <div className={'col-auto mb-2 mb-md-0'}>
                        <button
                            type={'button'}
                            className={'btn btn-primary w-100'}
                            onClick={ () => {
                                setShowCancelWarning(false)
                            }}>
                            {strings.app?.text?.no || ''}
                        </button>
                    </div>
                    <div className={'col-auto'}>
                        <button type={'button'} onClick={() => {
                        // reset formik.
                            departmentAddFormik.resetForm()
                            setShowCancelWarning(false)
                            // hide after.
                            setShowAddDepartmentInterface(false)
                        }} className={'btn btn-primary w-100'}>
                            {strings.app?.text.yes}
                        </button>

                    </div>
                </div>
            </ModalBody>
        </Modal>
    </div>

    return <>
        {showAddDepartmentInterface && result}
    </>
}

export default QuickAddDepartment
