
import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { IDS } from '@registration/constants'

import { smartSearch } from '@app/app.method'
import {
    GetUserTreatmentsResponse,
    UpdateTreatmentKeys,
    UpdateTreatmentValues
} from '@registration/type'
import { FormikProps } from 'formik'
import _ from 'lodash'

interface ComponentProps {
    treatmentFormik: FormikProps<UpdateTreatmentValues>,
    isOutsideClick: boolean,
    setIsOutsideClick: React.Dispatch<React.SetStateAction<boolean>>,
    getUserTreatmentsResponse: GetUserTreatmentsResponse | undefined,
}

const TreatmentsInterface = ({
    treatmentFormik,
    isOutsideClick,
    setIsOutsideClick,
    getUserTreatmentsResponse
}: ComponentProps) => {
    // const token = useAppSelector(selectToken)
    //
    // const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    // const revalidateToken = useRevalidateToken()
    // const validateAPIPath = useValidateAPIPath()

    const containerRef = useRef<HTMLDivElement | null>(null)
    const [search, setSearch] = useState('')
    // start off with dropdown already open.

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current) {
                // weird behavior but works anyways
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsOutsideClick(false)
                } else {
                    setIsOutsideClick(true)
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [containerRef])

    const renderOption = (obj: GetUserTreatmentsResponse['data']['carepaths'][0]) => {
        const key = obj.treatmentId + obj.carepathId

        const fieldName: UpdateTreatmentKeys = 'treatmentId'
        const extraFieldName: UpdateTreatmentKeys = 'careplanId'

        const result = <div
            onClick={() => {
                console.log('what was selected: ', obj)
                if (
                    obj.treatmentId === treatmentFormik.values.treatmentId &&
                        obj.carepathId === treatmentFormik.values.careplanId
                ) {
                    // set as unselected
                    treatmentFormik.setFieldValue(fieldName, undefined)
                    treatmentFormik.setFieldValue(extraFieldName, undefined)
                } else {
                // set as selected
                    treatmentFormik.setFieldValue(fieldName, obj.treatmentId)
                    treatmentFormik.setFieldValue(extraFieldName, obj.carepathId)
                }
            }}
            className={['option',
                (obj.treatmentId === treatmentFormik.values.treatmentId &&
                obj.carepathId === treatmentFormik.values.careplanId)
                    ? 'selected'
                    : ''].join(' ')}
            key={key}
        >
            <span className={'fw-semibold'}>{obj.treatmentName}</span>
            <span className={'fw-light'}>{` (${ obj.carepathName }) `}</span>
        </div>

        return result
    }

    const FilteredTreatments = (smartSearch(
        (getUserTreatmentsResponse?.data.carepaths || []),
        [], search
    ) as GetUserTreatmentsResponse['data']['carepaths'])

    const AvailableTreatmentOptions = <div className={'mb-3'}>
        {
            _.map(FilteredTreatments,
                (obj) => {
                    return renderOption(obj)
                })
        }
    </div>

    const found = _.find(getUserTreatmentsResponse?.data.carepaths || [], (obj) => {
        return obj.treatmentId === treatmentFormik.values.treatmentId &&
        obj.carepathId === treatmentFormik.values.careplanId
    })

    return <div className={[
        'align-items-baseline mt-4 row'
    ].join(' ')}>

        <div className={'form-group  mb-4 position-relative'}>
            <label htmlFor={IDS.UPDATE_TREATMENT.TREATMENT_ID}
                className={'form-label'}>{
                    strings.registration?.text.treatment.therapy
                }</label>
            <div
                id={IDS.UPDATE_TREATMENT.TREATMENT_ID}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOutsideClick(!isOutsideClick)
                }}
                className={[
                    'form-control form-select py-4',
                    treatmentFormik.errors.treatmentId && 'border-danger'
                ].join(' ')}
            >
                {
                    !found?.treatmentName
                        ? <span className={'opacity-50'}>
                            {strings.registration?.text?.treatment.therapy || ''}
                        </span>
                        : <>
                            <span className={'fw-semibold'}>{found?.treatmentName}</span>
                            <span className={'fw-light'}>{` ${ found?.carepathName
                            ? ([
                                '(', found?.carepathName, ')'
                            ].join(''))
                            : '' } `}</span>
                        </>
                }

            </div>

            {/* //chaged from input to div */}

            {/* popup window to get a list of carepath with checkboxes */}

            {isOutsideClick && <div
                ref={(e) => {
                    containerRef.current = e
                }}
                className={[
                    'dropdown-list mt-4 px-5 py-5 w-100',
                    'position-absolute'
                ].join(' ')}>
                {/* search tab and filter icon */}
                <div className={['row justify-content-between mb-4 ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box '}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={'btn-group-checkbox-list '}>
                    {AvailableTreatmentOptions}
                </div>

            </div>}
            <div className={'form-text error'}>{
                treatmentFormik.errors.treatmentId
                    ? treatmentFormik.errors.treatmentId
                    : null
            }</div>
        </div>
    </div>
}

export default TreatmentsInterface
