import { useAppSelector } from '@app/app.hook'
import { endOfMonth, getMonth, getYear, isAfter, isBefore, startOfMonth } from 'date-fns'
import _ from 'lodash'
import { HTMLProps, InputHTMLAttributes, forwardRef, useState } from 'react'
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
// for react-datepicker locale. requires date-fns.
import enUS from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'

import { selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { AppState } from '@app/types/type.app'
import * as Popper from '@popperjs/core'
import InputMask from 'react-input-mask'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    popperPlacement?: Popper.Placement
    id: string | undefined;
    errors?: string;
    isDate?: (date:Date) => void,
    isRange?: (date: [Date | null, Date | null]) => void
    singleDate: Date | undefined | null,
    dateType?: string | number | undefined
    timeFormat?: string | undefined
    disabled?: boolean
    removeIcon?: boolean
    startDate?: Date | null | undefined
    endDate?: Date | null | undefined
    selectsRange?: boolean
}

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
    // Define any additional props here
    customObj: {
        dateFormats: AppState['dateFormats']
        removeIcon: boolean | undefined
        popperPlacement?: Popper.Placement
        datepickerOpen: boolean
        setDatepickerOpen: React.Dispatch<React.SetStateAction<boolean>>
        dateType: string | number | undefined
        setActivePopperPlacement: React.Dispatch<React.SetStateAction<Popper.Placement | undefined>>
        className?: string;
        id?: string;
        disabled?: boolean
    }
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>((props, ref) => {
    const {
        customObj,
        ...rest
    } = props

    const {
        dateType, dateFormats, id, disabled, removeIcon, setDatepickerOpen,
        datepickerOpen,
        popperPlacement, className,
        setActivePopperPlacement
    } = customObj

    console.log(customObj)

    const formatUsed = dateType === 'date_time'
        ? dateFormats.format4
        : dateFormats.format1

    return (<div className={'form-control-wrapper icon-start-and-end'}
        suppressHydrationWarning={true}>
        <InputMask
            // this should go first. notes of prop placement is important too.
            {...rest}
            id={id}
            disabled={Boolean(disabled)}
            mask={formatUsed === dateFormats.format5
                ? '99-99-9999 99:99:99 aa'
                : formatUsed === dateFormats.format1 ? '99-99-9999' : ''}
            maskPlaceholder={formatUsed}
            className={className}
            inputRef={ref}
        />
        {removeIcon !== true
        // now onClick on opening it goes here.
            ? <>
                <label className={'form-floaticon'} htmlFor={id}>
                    <div className={'icon clickable '} onClick={() => {
                        if (disabled !== true) {
                            // console.log('toggle datepicker')
                            setDatepickerOpen(!datepickerOpen)
                        }
                        if (!popperPlacement) {
                            setActivePopperPlacement('bottom-start')
                        }
                    }}>
                        <i className={'fa-light fa-calendar'} aria-hidden={'true'}></i>
                    </div>
                </label>
                <label className={'form-floaticon'} htmlFor={id}>
                    <div className={'icon clickable '} onClick={() => {
                        if (disabled !== true) {
                            // console.log('toggle datepicker')
                            setDatepickerOpen(!datepickerOpen)
                        }
                        if (!popperPlacement) {
                            setActivePopperPlacement('bottom-end')
                        }
                    }}>
                        <i className={'fa-chevron-down fa-solid'} aria-hidden={'true'}></i>
                    </div>
                </label>
            </>
            : ''}
    </div>)
})

CustomInput.displayName = 'CustomInput'

interface CustomRangeProps extends InputHTMLAttributes<HTMLElement> {
    // Define any additional props here
    customObj: {
        disabled?: boolean
        datepickerOpen: boolean
        setDatepickerOpen: React.Dispatch<React.SetStateAction<boolean>>
    }
}

const CustomRangeInput = forwardRef<HTMLElement, CustomRangeProps>((props, ref) => {
    const { customObj, ...rest } = props

    const {
        setDatepickerOpen, datepickerOpen, disabled
    } = customObj

    return <i {...rest} ref={ref} onClick={() => {
        if (disabled !== true) {
            setDatepickerOpen(!datepickerOpen)
        }
    }}
    className={'fa-light fa-calendar clickable'} aria-hidden={'true'}></i>
})

CustomRangeInput.displayName = 'CustomRangeInput'

const SingleDate = (componentProps:ComponentProps) => {
    const isSmall = useMediaQuery({
        query: '(max-width: 575.98px)'
    })

    const dateFormats = useAppSelector(selectDateFormats)

    // const isMobile = useMediaQuery({
    //     query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    // })

    /**
     * IHD-61 - unmount the date picker when you click outside of it AND
     * when you finally click on the day number.
     */

    const [showMonthYearPicker, setShowMonthYearPicker] = useState(false)
    const [showFullMonthYearPicker, setShowFullMonthYearPicker] = useState(false)
    const [showYearPicker, setShowYearPicker] = useState(false)
    const [datepickerOpen, setDatepickerOpen] = useState(false)

    // popper placement for react-datepicker. if props has popper placement defined,
    // take priority.
    const [activePopperPlacement,
        setActivePopperPlacement] = useState<Popper.Placement | undefined>(
            componentProps.popperPlacement
        )

    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)

    const onChange = (date: Date | [Date | null, Date | null] | null) => {
        /** expected value should be a date */

        console.log('Date is: ', date)
        if (_.isArray(date) && componentProps.isRange) {
            componentProps.isRange(date)
        } else {
            if (componentProps.isDate) {
                if (_.isDate(date)) {
                    // if isDate function goes here
                    // want to avoid 01/01/1970.

                    const dateToModify = componentProps.singleDate || new Date()

                    const oldDateRef = componentProps.singleDate || new Date()

                    // console.log('year picker: ', showYearPicker)
                    // console.log('month year picker: ', showMonthYearPicker)
                    // console.log('full month year picker: ', showFullMonthYearPicker)

                    // will only occur if the yearPicker is active.
                    if (showYearPicker) {
                        if (date.getFullYear() !== oldDateRef.getFullYear()) {
                            // if unequal, it means that the year from "date",
                            // is an indicator of change. Set it.
                            dateToModify.setFullYear(date.getFullYear())
                        }

                        console.log('closing year picker and going to month year picker')
                        setShowYearPicker(false)
                        setShowMonthYearPicker(true)
                        setShowFullMonthYearPicker(true)
                    } else if (showMonthYearPicker && showFullMonthYearPicker) {
                        if (date.getMonth() !== oldDateRef.getMonth()) {
                            dateToModify.setMonth(date.getMonth())
                        }
                        console.log('closing month year picker')
                        setShowMonthYearPicker(false)
                    } else {
                        // console.log(date)
                        if (
                            date.getDate() !== oldDateRef.getDate() ||
                            date.getTime() !== oldDateRef.getTime()
                        ) {
                            // newDate.setDate(date.getDate())
                            dateToModify.setFullYear(date.getFullYear())
                            dateToModify.setMonth(date.getMonth())
                            dateToModify.setDate(date.getDate())
                            // forgot to set time.
                            dateToModify.setTime(date.getTime())

                            setDatepickerOpen(false)
                        }
                    }

                    // now for days.

                    componentProps.isDate(dateToModify)
                }
            } else {
                console.log('isDate and isNotDate is missing')
            }
        }

        // setShowMonthYearPicker(false)
        // setShowFullMonthYearPicker(false)
        // setShowYearPicker(false)

        // adhere to ihd-61 change
        // setDatepickerOpen(false)
    }

    // react date picker should have date range limitations.
    // move custom inputs outside to keep focus.

    const renderDayContents = (dayOfMonth: number, date?: Date | undefined) => {
        if (date) {
            const isFirstDayOfMonth = isBefore(dayOfMonth, startOfMonth(date))
            const isLastDayOfMonth = isAfter(dayOfMonth, endOfMonth(date))

            if (isFirstDayOfMonth || isLastDayOfMonth) {
                return <div className={'disabled'}>{dayOfMonth}</div>
            }
        }

        return <div>{dayOfMonth}</div>
    }

    // console.log(componentProps)

    // const DatePicker = useMemo(() => {
    //     console.log('why is component rendering continuously')
    // }, [componentProps, strings, token, showMonthYearPicker, showYearPicker,
    //     showFullMonthYearPicker, datepickerOpen, isMobile])

    // console.log('testing render cycles after code adjustments ')
    return <ReactDatePicker
    // withPortal={isMobile}
        placeholderText={componentProps.dateType === 'date_time'
            ? dateFormats.format5
            : dateFormats.format1}
        popperPlacement={activePopperPlacement}
        disabled={componentProps.disabled}
        renderDayContents={renderDayContents}
        // this shouldn't happen anymore. put this in the custom input instead
        // onInputClick={() => {
        //     setDatepickerOpen(true)
        // }}
        locale={token.details.locale === 'nl-NL'
            ? nl
            : token.details.locale === 'en-US'
                ? enUS
                : enUS}
        open={datepickerOpen}
        // minDate={
        //     rangeLower !== undefined && typeof rangeLower === 'number'
        //         ? fromUnixTime(rangeLower)
        //         : undefined
        // }
        // maxDate={
        //     rangeUpper !== undefined && typeof rangeUpper === 'number'
        //         ? fromUnixTime(rangeUpper)
        //         : undefined
        // }
        selectsRange={componentProps.selectsRange}
        startDate={componentProps.startDate}
        endDate={componentProps.endDate}
        selected={componentProps.singleDate}
        calendarContainer={(props: HTMLProps<Element>) => {
            return <div className={props.className}>
                <div className={'position-relative'}>
                    {props.children}
                </div>
            </div>
        }}
        onClickOutside={() => {
            setShowMonthYearPicker(false)
            setShowFullMonthYearPicker(false)
            setShowYearPicker(false)
            setDatepickerOpen(false)
        }}
        renderCustomHeader={({
            date,
            decreaseMonth,
            decreaseYear,
            increaseYear,
            increaseMonth,
            prevYearButtonDisabled,
            nextYearButtonDisabled,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
        }: ReactDatePickerCustomHeaderProps) => {
            const monthLabels = _.map(strings.app?.text.months, (o) => o.label)
            return (
                <div
                    className={'align-items-center justify-content-betweem row g-0 flex-nowrap'}
                >

                    {/* double arrow shows up on  showFullMonthYearPicker and month picker */}
                    {
                        !showYearPicker && (
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'bg-transparent btn',
                                        'react-datepicker__gridbox_icons px-1'
                                    ].join(' ')}
                                    onClick={decreaseYear}
                                    disabled={prevYearButtonDisabled}
                                    aria-label={strings.app?.text.previous_year}>
                                    <i
                                        className={[
                                            'fa-solid fa-chevrons-left'
                                        ].join(' ')}
                                    >
                                    </i>
                                </button>
                            </div>
                        )
                    }

                    {
                        showYearPicker
                            ? <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'bg-transparent btn',
                                        'react-datepicker__gridbox_icons px-1'
                                    ].join(' ')}
                                    onClick={decreaseYear}
                                    disabled={prevYearButtonDisabled}
                                    aria-label={strings.app?.text.previous_year}>
                                    <i
                                        className={[
                                            'fa-solid fa-chevron-left'
                                        ].join(' ')}
                                    >
                                    </i>
                                </button>
                            </div>
                            : <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'bg-transparent btn',
                                        'react-datepicker__gridbox_icons px-1'
                                    ].join(' ')}
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                    aria-label={strings.app?.text.previous_year}>
                                    <i
                                        className={[
                                            'fa-solid fa-chevron-left'
                                        ].join(' ')}
                                    >
                                    </i>
                                </button>
                            </div>
                    }

                    <div className={'col'}>
                        <div className={'flex-nowrap g-0 justify-content-center row'}>
                            <div className={'col-auto'}>
                                <h5
                                    className={'d-inline-block mb-0'}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setShowMonthYearPicker(true)
                                        setShowFullMonthYearPicker(true)
                                        setShowYearPicker(false)
                                    }}>
                                    {`${ monthLabels[getMonth(date)] }`}
                                </h5>
                            </div>
                            <div className={'col-auto'}>

                                <h5
                                    className={'d-inline-block ps-1 mb-0'}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setShowMonthYearPicker(false)
                                        setShowFullMonthYearPicker(false)
                                        setShowYearPicker(true)
                                    }}>
                                    {`${ getYear(date) }`}
                                </h5>
                            </div>
                        </div>
                    </div>

                    {showYearPicker
                        ? <div className={'col-auto'}>
                            <button
                                type={'button'}
                                className={[
                                    'bg-transparent btn',
                                    'react-datepicker__gridbox_icons px-1'
                                ].join(' ')}
                                onClick={increaseYear}
                                disabled={nextYearButtonDisabled}
                                aria-label={strings.app?.text.next_year}>
                                <i
                                    className={[
                                        'fa-solid fa-chevron-right'
                                    ].join(' ')}
                                >
                                </i>
                            </button>
                        </div>
                        : <div className={'col-auto'}>
                            <button
                                type={'button'}
                                className={[
                                    'bg-transparent btn',
                                    'react-datepicker__gridbox_icons px-1'
                                ].join(' ')}
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                aria-label={strings.app?.text.next_month}>
                                <i
                                    className={[
                                        'fa-solid fa-chevron-right'
                                    ].join(' ')}
                                >
                                </i>
                            </button>
                        </div>
                    }

                    {
                        !showYearPicker && (
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'bg-transparent btn',
                                        'react-datepicker__gridbox_icons px-1'
                                    ].join(' ')}
                                    onClick={increaseYear}
                                    disabled={nextYearButtonDisabled}
                                    aria-label={strings.app?.text.next_year}>
                                    <i
                                        className={[
                                            'fa-solid fa-chevrons-right'
                                        ].join(' ')}
                                    >
                                    </i>
                                </button>
                            </div>
                        )
                    }

                </div>
            )
        }}
        customInput={componentProps.isRange
            ? <CustomRangeInput
                customObj={
                    {
                        datepickerOpen,
                        setDatepickerOpen
                    }
                }
            />
            : <CustomInput customObj= {{
                disabled: componentProps.disabled,
                className: [
                    'form-control',
                    componentProps.errors && 'border-danger',
                    componentProps.removeIcon === true && 'ps-3'
                ].join(' '),
                dateFormats,
                popperPlacement: componentProps.popperPlacement,
                id: componentProps.id,
                removeIcon: componentProps.removeIcon,
                dateType: componentProps.dateType,
                datepickerOpen,
                setDatepickerOpen,
                setActivePopperPlacement
            }}
            />
        }
        popperModifiers={isSmall
            ? [
                {
                    name: 'sameWidth',
                    enabled: true,
                    fn: ({ state }) => {
                        console.log('length to use on smaller devices: ', state.rects.reference)
                        state.styles.popper.width = `${ state.rects.reference.width }px`
                        // however if the width is 0, use 350 instead.
                        if (state.rects.reference.width < 0) {
                            console.log('you must be on an ios device. use a default width instead')
                            state.styles.popper.width = '350px'
                        }
                    },
                    phase: 'beforeWrite',
                    requires: ['computeStyles']
                }
            ]
            : []}
        formatWeekDay={nameOfDay => {
            return token.details.locale === 'nl-NL'
                ? (nameOfDay as unknown as string).substring(0, 2)
                : token.details.locale === 'en-US'
                    ? (nameOfDay as unknown as string).substring(0, 3)
                    : (nameOfDay as unknown as string).substring(0, 3)
        }}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showFullMonthYearPicker}
        showYearPicker={showYearPicker}
        dropdownMode={'select'}
        showTimeSelect={componentProps.dateType === 'date_time'}
        timeFormat={componentProps.dateType === 'date_time' ? componentProps.timeFormat : undefined}
        timeIntervals={1}
        onChange={onChange}
        dateFormat={componentProps.dateType === 'date_time'
            ? dateFormats.format5
            : dateFormats.format1}
    />
}

export default SingleDate
