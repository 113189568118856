import { OpenListInputs, OpenListValues, Question } from '@reasonWithMe/type'
import _ from 'lodash'

interface ComponentProps {
    questionData: Omit<Question, 'questionId'>
    form: OpenListValues
}

const MultiList = ({
    questionData, form
}: ComponentProps) => {
    const renderInputs = (obj: OpenListInputs) => {
        const key = [
            obj.answerName
        ].join('')

        const isChecked = obj.userTypedAnswer === true

        const isDisabled = form.inputs.filter((o) => {
            return o.userTypedAnswer === true
        }).length >= (questionData?.questionAnswersAllowed || 0)

        // if there are too many choices, use col-6
        return <div className={'col'} key={key}>
            <input type={'checkbox'}
                className={'btn-check'}
                autoComplete={'off'}
                id={key}
                checked={isChecked}
                disabled={isDisabled && !isChecked}
                onChange={() => {
                }}
            />
            <label className={'btn btn-dummy'} htmlFor={key}>

                <div className={'question-checkbox'}>
                    <div className={'card justify-content-center px-3 py-2'}>

                        <div className={'d-flex flex-column'}>
                            <div className={'d-flex align-items-center'}>
                                <div className={'p text-start'}>
                                    {obj.answerName}
                                </div>
                                {
                                    isChecked
                                        ? <div className={'question-checkmark ms-auto'}>
                                            <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                        </div>
                                        : <div className={'question-plussign ms-auto'}>
                                            <i className={'fa-light fa-plus mx-auto'}></i>
                                        </div>
                                }

                            </div>
                        </div>

                    </div>
                </div>

            </label>
        </div>
    }

    return <>
        <div className={'container-fluid'}>
            <div className={'row row-cols-1 row-cols-md-2 btn-group-checkbox-list mt-3'}
                role={'group'}>
                {
                    _.map(form.inputs, renderInputs)
                }
            </div>
        </div>
    </>
}

export default MultiList
