import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { CareProfessionalKeys, CareProfessionalValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    careprofessionalEditFormik: FormikProps<CareProfessionalValues>
    handleKeyDown: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareProfessionalKeys
    ) => void
}

const CareProfessionalName = ({
    readOnly, careprofessionalEditFormik
    , handleKeyDown
}:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: CareProfessionalKeys = 'careProfessionalName'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.CAREPROFESSIONAL.EDIT.NAME}
                    className={'form-label'}>{
                        strings.fmt?.text.department.menu.name
                    }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly={readOnly}
                    type={'text'}
                    name={fieldName}
                    className={[
                        'form-control-sm',
                        readOnly ? 'form-control-plaintext' : 'form-control',
                        careprofessionalEditFormik.errors.careProfessionalName &&
                         'border-danger'
                    ].join(' ')}
                    placeholder={!readOnly ? strings.fmt?.text.department.menu.name : ''}
                    value={careprofessionalEditFormik.values.careProfessionalName}
                    id={IDS.CAREPROFESSIONAL.EDIT.NAME}
                    onBlur={careprofessionalEditFormik.handleBlur}
                    onChange={!readOnly ? careprofessionalEditFormik.handleChange : () => {}}
                    onKeyDown={(e) => handleKeyDown(e, 'careProfessionalProfession')}
                    required
                />
                <div className={'form-text error'}>{
                    careprofessionalEditFormik.errors.careProfessionalName
                        ? careprofessionalEditFormik.errors.careProfessionalName
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default CareProfessionalName
