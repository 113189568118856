/* eslint-disable max-len */
import Color from 'color'
import { css, useTheme } from '@emotion/react'

// import _ from 'lodash'
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'

const GlobalStyle = () => {
    const theme = useTheme()

    return css`

        .floating-ui {
            border: 0.1em solid transparent;
            border-radius: 0.5em;
            background-color: ${ theme.config.endUser?.recoveryPlan.floatingUiProgress.background };
            padding-block: 0.2rem;
            padding-inline: 0.5em;
            z-index: 1;
            //focus visible shows on divs and that gets annoying.
            &:focus-visible {
                outline: 0;
            }

            > svg {
                fill: ${ theme.config.endUser?.recoveryPlan.floatingUiProgress.arrowColor };
            }

            .progress-label {
                color: ${ theme.config.endUser?.recoveryPlan.floatingUiProgress.textColor };
            }

            &.bodymap {
                z-index: 10;
                background-color: ${ theme.config.endUser?.recoveryPlan.bodymapFloatingUi.background };
                > svg {
                    fill: ${ theme.config.endUser?.recoveryPlan.bodymapFloatingUi.background };
                }
                color: ${ theme.config.endUser?.recoveryPlan.bodymapFloatingUi.textColor };
                border-radius: 0.3em;
            }

        }


        .recovery-plan-page, .today-page, .library-page {

            .selected {
                background: ${ theme.config.endUser?.recoveryPlan.selected.background };
                border-style: hidden;
            }

            .offcanvas {
                color: ${ theme.config.endUser?.recoveryPlan.offcanvas.color };

                & .form-control {
                    border-radius: 18px;
                    border: 2px solid ${ theme.config.endUser?.recoveryPlan.offcanvas.formControl.borderColor };
                }

                & .form-check {
                    & .form-check-input {
                        border: 2px solid ${ theme.config.endUser?.recoveryPlan.offcanvas.formCheck.input.borderColor };
                        border-radius: 3px;
                    }
                }

                & .react-datepicker-wrapper {
                    & input {
                        color: ${ theme.config.endUser?.recoveryPlan.offcanvas.reactDatepickerWrapper.input.color };
                    }
                }

                & .btn-primary {
                    --bs-btn-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnColor };
                    --bs-btn-bg: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnBg };
                    --bs-btn-border-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnBorderColor };
                    --bs-btn-hover-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnHoverColor };
                    --bs-btn-hover-bg: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnHoverBg };
                    --bs-btn-hover-border-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnHoverBorderColor };
                    --bs-btn-focus-shadow-rgb: 49, 132, 253;
                    --bs-btn-active-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnActiveColor };
                    --bs-btn-active-bg: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnActiveBg };
                    --bs-btn-active-border-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnActiveBorderColor };
                    --bs-btn-active-shadow: inset 0 3px 5px ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnActiveShadow };
                    --bs-btn-disabled-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnDisabledColor };
                    --bs-btn-disabled-bg: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnDisabledBg };
                    --bs-btn-disabled-border-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnPrimary.btnDisabledBorderColor };
                }

                & .btn-secondary {
                    --bs-btn-border-color: ${ theme.config.endUser?.recoveryPlan.offcanvas.btnSecondary.btnBorderColor };
                    border: 2px solid ${ theme.config.endUser?.recoveryPlan.offcanvas.btnSecondary.mainBorderColor };
                }
            }

            .main-content {
                .grey-text {
                    color: ${ theme.config.endUser?.recoveryPlan.mainContent.greyText.color };
                }

                & .careplan-cards-container {
                    width: 638px;
                }

                & .progress-bars-container {
                    /* fixedwidth by tim at all times */
                    width: 400px;
                }

                .group-count {
                    border-radius: 100%;
                    background: ${ theme.config.endUser?.recoveryPlan.mainContent.groupCount.background };
                    padding-inline: 0.8rem;
                    padding-block: 0.2rem;
                }

                .careplan-group-container {
                    & .title {
                        font-weight: 700;
                    }
                }

                .card {
                    border-radius: 10px;
                    --bs-card-bg: ${ theme.config.endUser?.recoveryPlan.mainContent.card.bsCardBg };

                    &.careplan-card {
                        cursor: pointer;
                    }

                    &.careplan-card .card-body {

                        & .progress {
                            --bs-progress-height: 0.5rem;
                            --bs-progress-bar-bg: ${ theme.config.endUser?.recoveryPlan.mainContent.card.careplanCard.cardBody.progressBarBg };
                        }

                        & .completion-status-flag {
                            width: 96px;
                            height: 26px;
                            border-radius: 0 5px 5px 0;
                            color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.careplanCard.completionStatusFlag.color };
                            position: absolute;
                            /* top: 40px; */
                            top: 14%;
                            left: 0;
                            font-size: 0.8rem;
                            background: ${ theme.config.endUser?.recoveryPlan.mainContent.card.careplanCard.completionStatusFlag.background };
                            
                            & > * {
                                line-height: 26px;
                            }

                            &.complete {
                                background: ${ theme.config.endUser?.recoveryPlan.mainContent.card.careplanCard.completionStatusFlag.complete.background };
                            }
                            &.locked {
                                background: ${ theme.config.endUser?.recoveryPlan.mainContent.card.careplanCard.completionStatusFlag.locked.background };
                                color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.careplanCard.completionStatusFlag.locked.color };
                            }
                            &.incomplete {
                                background: ${ theme.config.endUser?.recoveryPlan.mainContent.card.careplanCard.completionStatusFlag.incomplete.background };
                            }

                        }

                        & .title {
                            font-size: 18px;
                            font-weight: bold;

                            &.tile-complete {
                            font-size: 16px;

                            }
                        }

                        & .content-type {
                            font-size: 12px;
                        }

                    } 

                    &.status-card {
                        color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.statusCard.color };
                        background-color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.statusCard.background };
                        padding: 40px;
                        position: relative;
                        top: -55px;
                        margin-bottom: -25px;
                        z-index: 1;
                        
                    }

                    &.progress-card {
                        padding: 10px;

                        & .actual-recovery {
                            height: 2rem;
                            border-radius: 1em;
                            background-color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.progressCard.recoveryBarBg };
                            
                            & .actual-recovery-bar {

                                background-color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.progressCard.actualRecoveryBarBg };


                            }

                        }

                        & .expected-recovery {
                            position: relative;
                            top: -25px;
                            height: 1.2rem;
                            margin-inline: 0vw;
                            background: transparent !important;
                            color: transparent;
                            mix-blend-mode: multiply;

                            & .expected-recovery-bar {

                                background-color: ${
                                    (() => {
                                        const color = Color(theme.config.endUser?.recoveryPlan.mainContent.card.progressCard.expectedRecoveryBarBg)

                                        return 'rgba(' + [color.rgb().array()[0],
                                            color.rgb().array()[1],
                                            color.rgb().array()[2],
                                            '0.5'
                                        ].join(', ') + ')'
                                    })()
                                };

                            }

                        }
                    }

                    &.today-progress-card {
                        padding: 10px;

                        
                        & .main-recovery {
                            height: 1.5rem;
                            border-radius: 1em;
                            background-color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.progressCard.recoveryBarBg };
                            
                            & .main-recovery-bar {

                                background-color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.todayCard.mainRecoveryBarBg };


                            }

                        }

                        & .progress-divider {
                            position: relative;
                            top: -18px;
                            height: 12px;
                            mix-blend-mode: multiply;

                            & .row {
                                height: 100%;

                                & div {
                                    border-right-color: ${ theme.config.endUser
                                    ?.recoveryPlan?.mainContent?.card?.todayCard?.progressDivider?.borderRightColor };
                                    
                                    &.border-lg-end {
                                        @media (min-width: 992px) {
                                            border-right: 1px solid ${ theme.config.endUser?.recoveryPlan?.mainContent?.card?.todayCard?.progressDivider?.borderLgEnd?.borderRightColor };
                                        }
                                    }
                                }
                            }

                            
                        }

                        & .step-type-label {
                            & div {
                                border-radius: 5px;
                                background-color: ${ theme.config.endUser?.recoveryPlan?.mainContent?.card?.todayCard?.stepTypeLabel?.background };
                                padding: 7px;
                            }
                        }

                        & .step-type-recovery {
                            height: 1rem;
                            border-radius: 1em;
                            background-color: ${ theme.config.endUser?.recoveryPlan.mainContent.card.progressCard.recoveryBarBg };
                            
                            & .step-type-recovery-bar {

                                background-color: ${ theme.config.endUser?.recoveryPlan?.mainContent?.card?.todayCard?.stepTypeRecoveryBarBg };


                            }

                        }
                    }

                    small.content-type {
                        &.activity {
                            color: ${ theme.config.endUser?.recoveryPlan?.mainContent?.contentType?.activity?.color };
                        }
                        &.content {
                            color: ${ theme.config.endUser?.recoveryPlan?.mainContent?.contentType?.content?.color };
                        }
                        &.lesson {
                            color: ${ theme.config.endUser?.recoveryPlan?.mainContent?.contentType?.lesson?.color };
                        }
                        &.reasoning {
                            color: ${ theme.config.endUser?.recoveryPlan?.mainContent?.contentType?.reasoning?.color };
                        }
                    }
                }
            }


            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    //padding-top is better than margin-top. if you do margin top.
                    //any components above will also go down.
                    padding-top: 260px;

                        
                    .careplan-group-container {
                        & .title {
                            font-size: 23px;
                        }
                    }

                    .card-grid {
                        margin-bottom: 5rem;
                        .card {
                            &.careplan-card .card-body {
                                & .title {
                                    font-size: 18px;
                                }

                                & .subtitle {
                                    font-size: 16px;
                                }
                            }
                        }
                    }



                

                }
            }

            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                & .header {
                    height: 58px;

                    & img {
                        width: 98px;
                        margin-block-start: -5px;
                    }
                }
                
                & .main-content {

                    padding-top: 113px;
                    position: absolute;
                    //100% minus the height of the previous divs. 100% means height of viewport
                    //in this case
                                    /* height: calc(100% - 113px - 58px); */
                    height: calc(100% - 113px);

                    .careplan-group-container {
                        & .title {
                            font-size: 18px;
                        }
                    }

                    & .form-group {
                        & div.form-control-wrapper {
                            & .form-control.form-search {  
                                padding: 0.55rem 1rem 0.55em 2.75rem;
                            }

                            & .form-floaticon {
                                top: calc(calc(1.3125em + 2.125rem) / 2.3);
                            }
                        }
                    }

                    .card-grid {
                        margin-bottom: 3rem;
                        padding-bottom: 1rem;

                        .card {
                            &.careplan-card .card-body {
                                & .title {
                                    font-size: 14px;
                                }

                                & .subtitle {
                                    font-size: 12px;
                                }
                            }
                        }

                        //also applied to incomplete on smaller devices
                        &.incomplete {
                            flex-wrap: nowrap;
                            overflow-x: scroll;

                            .card {
                                &.careplan-card {
                                    //make sure the col width is col-auto.
                                    //then assign this.
                                    width: 260px;
                                }
                            }
                        }

                        &.complete {
                            flex-wrap: nowrap;
                            overflow-x: scroll;
                            
                            .card {
                                &.careplan-card {
                                    //make sure the col width is col-auto.
                                    //then assign this.
                                    width: 260px;
                                }
                            }
                        }

                    }

                }


            }



            }

            .recovery-plan-page {
            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {

                & .main-content {

                    .card-grid {
                        .card {
                            &.careplan-card {
                                .card-body {
                                    & .completion-status-flag {
                                        width: 70px;
                                        height: 23px;
                                        /* top: 40px; */
                                        top: 25%;
                                        font-size: 0.7rem; 
                                        & > * {
                                            line-height: 26px;
                                        }
                                    }
                                } 
                            }
                        }

                    }
                }
            }
            }

            .today-page {
            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                & .main-content {
                    .card-grid {
                        margin-bottom: 3rem;


                        //also applied to incomplete on smaller devices
                        &.incomplete {
                            flex-wrap: nowrap;
                            overflow-x: scroll;

                            .card {
                                &.careplan-card {
                                    //make sure the col width is col-auto.
                                    //then assign this.
                                    width: 260px;
                                }
                            }
                        }

                        &.complete {
                            flex-wrap: nowrap;
                            overflow-x: scroll;

                            
                            .card {
                                &.careplan-card {
                                    //make sure the col width is col-auto.
                                    //then assign this.
                                    width: 260px;
                                }
                            }
                        }

                    }

                }


            }
            }

            .library-page {

            & .main-content {

                .grey-text {
                    color: ${ theme.config.endUser?.recoveryPlan.libraryPage.mainContent.greyText };
                }

                & .careplan-cards-container {
                    width: 57.68vw;
                }

            }

        }
    `
}

export default GlobalStyle
