import { MODULE_TABLE } from '@app/app.config'
import { CareplanKeys, CareplanValues, Patient, PatientValues } from '@doc/type'
import { UpdateTreatmentValues } from '@registration/type'
import * as Yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'

/** This is the name of the module. */
export const MODULE_NAME = {
    LONG: MODULE_TABLE.doc.moduleName,
    SHORT: MODULE_TABLE.doc.moduleShortName
}

/** This is the major version number of the module. */
export const MODULE_MAJOR_VERSION = 1

/** This is the minor version number of the module. */
export const MODULE_MINOR_VERSION = 4

/** This is the hotfix version number of the module. */
export const MODULE_HOTFIX_VERSION = 2

/** This is the full version string of the module,
 * based on the major, minor, and hotfix versions. */
export const MODULE_VERSION = [
    MODULE_NAME.SHORT, ' ',
    `v.${ MODULE_MAJOR_VERSION }.${ MODULE_MINOR_VERSION }.${ MODULE_HOTFIX_VERSION }`
].join('')

export const IDS = {
    PATIENT: {
        ADD: {
            FORM: 'PATIENT_ADD__FORM',
            FIRST_NAME: 'PATIENT_ADD__FIRST_NAME',
            LAST_NAME: 'PATIENT_ADD__LAST_NAME',
            EMAIL: 'PATIENT_ADD__EMAIL',
            PATIENT_NUMBER: 'PATIENT_ADD__PATIENT_NUMBER'
        },
        EDIT: {
            FORM: 'PATIENT_EDIT__FORM',
            FIRST_NAME: 'PATIENT_EDIT__FIRST_NAME',
            LAST_NAME: 'PATIENT_EDIT__LAST_NAME',
            EMAIL: 'PATIENT_EDIT__EMAIL',
            PATIENT_NUMBER: 'PATIENT_EDIT__PATIENT_NUMBER',
            ACTIVE: 'PATIENT_EDIT__ACTIVE'
        }
    },
    PATIENT_ADD_MODAL: {
        /** from the add modal interface for adding care facilities. don't remove */
        SELECT: {
            ADD_DEPARTMENT: 'DEPARTMENTS_ADD__SELECT',
            SEARCH_DEPARTMENT: 'DEPARTMENTS_ADD__SEARCH',
            ADD_FACILITY: 'FACILITY_ADD__SELECT',
            SEARCH_FACILITY: 'FACILITY_ADD__SEARCH',
            ADD_CAREPATHS: 'CAREPATHS_ADD__SELECT',
            SEARCH_CAREPATHS: 'CAREPATHS_ADD__SEARCH',
            SELECT_OPERATION_DATE: 'CAREPATHS_ADD__SELECT_OPERATION_DATE',
            SELECT_OPERATION_DATE_CHECKED_1: 'CAREPATHS_ADD__SELECT__CHECKED_1',
            SELECT_OPERATION_DATE_CHECKED_2: 'CAREPATHS_ADD__SELECT__CHECKED_2'
        }

    },
    CHART: {
        START: 'CHART__START_DATE',
        END: 'CHART__END_DATE',
        RANGE: 'CHART__RANGE_DATE',
        PROGRESS_RANGE: 'CHART__PROGRESS_RANGE_DATE'
    },
    CAREPROF_DASHBOARD: {
        ABNORMALITIES: {
            START: 'CAREPROF_DASHBOARD__ABNORMALITIES__START',
            END: 'CAREPROF_DASHBOARD__ABNORMALITIES__END'
        }

    }

}

/**
 * patient formik but i decided to separate the formik beacuse asynchronous
 * validation is easier to navigate around because you are in a React app.
 */

export const PATIENT_FORMIK_STEP_ONE_INITIAL_VALUES: Pick<
PatientValues, 'firstName' | 'lastName' | 'email' | 'patientNumber'> = {
    firstName: '',
    lastName: '',
    email: '',
    patientNumber: ''
}

export const PATIENT_FORMIK_STEP_TWO_INITIAL_VALUES:{
    careFacilityId: string;
    departmentId: string;
} = {
    // careFacilities: [{
    //     careFacilityId: '',
    //     departmentId: ''
    // }]
    careFacilityId: '',
    departmentId: ''

}

export const CAREPLAN_FORMIK_INITIAL_VALUES: CareplanValues = {
    userId: '',
    treatmentId: '',
    carePlanId: '',
    startDate: 0
}

export const PATIENT_STEP_ONE_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
Pick<PatientValues, 'firstName' | 'lastName' | 'email' | 'patientNumber'>,
AnyObject, Record<keyof Pick<
PatientValues, 'firstName' | 'lastName' | 'email' | 'patientNumber'>, any>
> = (emptyMessage) => Yup.object({
    firstName: Yup.string().required(emptyMessage),
    lastName: Yup.string().required(emptyMessage),
    email: Yup.string().required(emptyMessage),
    patientNumber: Yup.string().required(emptyMessage)
})

export const PATIENT_EDIT_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
(Pick<PatientValues, 'firstName' | 'lastName' | 'email' | 'patientNumber'> &
Pick<Patient['userData'][0], 'active'>),
AnyObject, Record<
(keyof Pick<PatientValues, 'firstName' | 'lastName' | 'email' | 'patientNumber'>
& keyof Pick<Patient['userData'][0], 'active'>),
 any>
> = (emptyMessage) => Yup.object({
    firstName: Yup.string().required(emptyMessage),
    lastName: Yup.string().required(emptyMessage),
    email: Yup.string().required(emptyMessage),
    patientNumber: Yup.string().required(emptyMessage),
    active: Yup.boolean().required(emptyMessage)
})

export const PATIENT_STEP_TWO_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
{
    careFacilityId: string;
    departmentId: string;
},
AnyObject, Record<'careFacilityId' | 'departmentId', any>
> = (emptyMessage) => Yup.object({
    // careFacilities: Yup.array().required().of(
    //     Yup.object({
    //         careFacilityId: Yup.string().required(),
    //         departmentId: Yup.string().required()
    //     })
    // ).min(1, emptyMessage)
    careFacilityId: Yup.string().required(emptyMessage),
    departmentId: Yup.string().required(emptyMessage)
})

export const CAREPLAN_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
CareplanValues,
AnyObject,
Record<CareplanKeys, any>
> = (emptyMessage) => Yup.object({
    userId: Yup.string().required(emptyMessage),
    treatmentId: Yup.string().required(emptyMessage),
    carePlanId: Yup.string().required(emptyMessage),
    startDate: Yup.number().required(emptyMessage)
})

// constant for how many records per page in the careprofessional dashboard page
// set to zero by tim so we'll use his limit.
export const CAREPROF_TABLE_LIMIT = 0

export const MAKE_CAREPLAN_FORMIK_INITIAL_VALUES: UpdateTreatmentValues & {userId:string} = {
    userId: '',
    treatmentId: '',
    careplanId: '',
    startDate: undefined,
    isUnknownChecked: false
}

export const MAKE_CAREPLAN_VALIDATION_SCHEMA: (
    emptyMessage: string,
    invalidMessage: string,
) => ObjectSchema<
UpdateTreatmentValues,
AnyObject,
Record<keyof (UpdateTreatmentValues & {userId:string}), undefined>
> = (emptyMessage, invalidMessage) => Yup.object({
    userId: Yup.string().required(emptyMessage),
    treatmentId: Yup.string().required(emptyMessage),
    careplanId: Yup.string().required(emptyMessage),
    startDate: Yup.number()
        .test('isValidDate', invalidMessage, (value) => {
            //  we have to allow undefined and numerical values.
            if (value === undefined || typeof value === 'number') {
                return true
            }

            return false
        }),
    isUnknownChecked: Yup.boolean().required()
})
