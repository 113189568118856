import { MODULE_TABLE } from '@app/app.config'

import _ from 'lodash'

import { SystemModule } from '@login/type'
import RegistrationWorkflow from '@registration/components/RegistrationWorkflow'
import React from 'react'

interface ComponentProps {
    config: SystemModule[]
}
const selectedModule = MODULE_TABLE.registration
/** The final array of route configurations with their corresponding components */
const Routes = ({ config }: ComponentProps) => {
    const arr: {
            moduleName: string,
            path: string,
            element: React.ReactNode
        }[] = []

    const found = _.find(config, (o) => {
        return o.moduleName === selectedModule.moduleName
    })

    // routes that don't need validation such as the registration url.
    const staticRoutes: {
        moduleName: string,
        path: string,
        element: React.ReactNode
    }[] = []

    // // we'll need to include
    // // a route called /registration/uuid/id. You still will go
    // // through token revalidation and the client doesn't want that.
    // staticRoutes.push({
    //     moduleName: MODULE_TABLE.registration.moduleName,
    //     path: MODULE_TABLE.registration.routes.registrationByEmail,
    //     element: <RegistrationWorkflow />
    // })

    if (found) {
        _.forEach(_.cloneDeep(found.feRoutes), (o) => {
            let element: React.ReactNode | undefined

            // a new variable named route to pass in so we can cater to routes
            // with parameters on them.
            let routeName = ''
            switch (o.route) {
                case selectedModule.routes.registration: {
                    element = <RegistrationWorkflow/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.registrationByEmail: {
                    element = <RegistrationWorkflow/>
                    routeName = o.route
                    break
                }

                default:
                    break
            }

            element && arr.push({
                moduleName: found.moduleName,
                path: routeName,
                element
            })
        })
    }

    return _.concat(staticRoutes, arr)
}

export default Routes
