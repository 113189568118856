import { useEffect } from 'react'

import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { toast } from 'react-toastify'

import { MODULE_TABLE } from '@app/app.config'
import { selectRouter } from '@app/app.store'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { TokenData } from '@app/types/type.token'
import { useAddCareFacilityMutation } from '@fmt/api'
import PopupModal from '@fmt/components/facilities/add/PopupModal'
import {
    FACILITY_STEP_FOUR_FORMIK_INITIAL_VALUES,
    FACILITY_STEP_ONE_FORMIK_INITIAL_VALUES,
    FACILITY_STEP_ONE_VALIDATION_SCHEMA,
    FACILITY_STEP_THREE_FORMIK_INITIAL_VALUES,
    FACILITY_STEP_TWO_FORMIK_INITIAL_VALUES,
    FACILITY_STEP_TWO_VALIDATION_SCHEMA
} from '@fmt/constants'
import { selectFacilityMenu, setFacilityMenu } from '@fmt/slice'
import { FacilitiesState } from '@fmt/type'
import { useFormik } from 'formik'

import _ from 'lodash'

interface ComponentProps {
    fetchData: (token: TokenData) => () => void
}

const AddInterface = ({ fetchData } : ComponentProps) => {
    const dispatch = useAppDispatch()

    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const router = useAppSelector(selectRouter)
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const facilityMenu = useAppSelector(selectFacilityMenu)

    const [addCareFacility, addCareFacilityMutation] = useAddCareFacilityMutation({
    })

    // FOR STEP 1: OK
    const facilityStepOneAddFormik = useFormik({
        initialValues: FACILITY_STEP_ONE_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: FACILITY_STEP_ONE_VALIDATION_SCHEMA(
            strings.app?.message.error.email || '',
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.addCareFacility.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        const data = {
                            facilityName: values.facilityName || '',
                            facilityAddressStreet: values.facilityAddressStreet,
                            facilityAddressNumber: values.facilityAddressNumber,
                            facilityAddressSuffix: values.facilityAddressSuffix || '',
                            facilityPostalcode: values.facilityPostalcode,
                            facilityCity: values.facilityCity,
                            facilityPhonenumber: values.facilityPhonenumber || '',
                            facilityEmailAddress: values.facilityEmailAddress || '',
                            facilityWebsite: values.facilityWebsite || ''
                        }

                        addCareFacility({
                            authToken: newToken.value,
                            data
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    // FOR STEP 2: OK
    const facilityStepTwoAddFormik = useFormik({
        initialValues: FACILITY_STEP_TWO_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: FACILITY_STEP_TWO_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: () => {
            // proceed to step 3.
            dispatch(setFacilityMenu({
                ...facilityMenu,
                currentStep: facilityMenu.currentStep + 1
            }))
        }
    })

    // just to clarify: i know that you aren't supposed to validate if each department
    // has a list of careprofessionals or not as we have a skip button for this very reason
    // there won't be a schema given that it has no use here even if it technically is a form.
    const facilityStepThreeAddFormik = useFormik({
        initialValues: FACILITY_STEP_THREE_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: () => {
            // proceed to step 3.
            dispatch(setFacilityMenu({
                ...facilityMenu,
                currentStep: facilityMenu.currentStep + 1
            }))
        }
    })

    // same as above
    const facilityStepFourAddFormik = useFormik({
        initialValues: FACILITY_STEP_FOUR_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: () => {
            // proceed to step 3.
            dispatch(setFacilityMenu({
                ...facilityMenu,
                currentStep: facilityMenu.currentStep + 1
            }))
        }
    })

    useEffect(() => {
        const steps: FacilitiesState['facilityMenu']['steps'] = [
            {
                number: 1,
                name: strings.fmt?.text.facility.menu.facility_data || ''
            },
            {
                number: 2,
                name: strings.fmt?.text.facility.add.departments || ''
            },
            {
                number: 3,
                name: strings.fmt?.text.facility.add.careprofessionals || ''
            }, {
                number: 4,
                name: strings.fmt?.text.facility.add.treatment || ''
            },
            {
                number: 5,
                name: strings.fmt?.text.facility.add.overview || ''
            }
        ]

        dispatch(setFacilityMenu({
            ...facilityMenu,
            steps
        }))
    }, [router.location.pathname])

    useEffect(() => {
        const data = addCareFacilityMutation.data

        if (data) {
            if (data.status === 'OK') {
                // toast success message.
                // toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // go to next step
                if (facilityMenu.currentStep < facilityMenu.steps.length) {
                    facilityStepTwoAddFormik.resetForm()
                    facilityStepThreeAddFormik.resetForm()
                    facilityStepFourAddFormik.resetForm()

                    dispatch(setFacilityMenu({
                        ...facilityMenu,
                        currentStep: facilityMenu.currentStep + 1,
                        addInterfaceProps: {
                            facilityId: data.data.facilityId
                        }
                    }))
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [addCareFacilityMutation.data])

    useEffect(() => {
        if (addCareFacilityMutation.error) {
            const message = getErrorText(addCareFacilityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addCareFacilityMutation.error])

    // converting reducer to a formik.

    // when facilityStepTwoAddFormik is updated, update step 3 and 4 formiks to
    // include those ids.
    useEffect(() => {
        // console.log(facilityStepTwoAddFormik.values)

        const linkedDepartments = facilityStepTwoAddFormik.values.linkedDepartments
        const linkedCareprofessionals = _.cloneDeep(
            facilityStepThreeAddFormik.values.linkedCareprofessionals
        )
        const linkedTreatments = _.cloneDeep(facilityStepFourAddFormik.values.linkedTreatments)

        _.forEach(linkedDepartments, (o, index) => {
            const found1 = facilityStepThreeAddFormik.values
                .linkedCareprofessionals.find((p) => p.department.departmentId === o.departmentId)
            const found2 = facilityStepFourAddFormik.values
                .linkedTreatments.find((p) => p.department.departmentId === o.departmentId)

            if (!found1) {
                linkedCareprofessionals.push({
                    department: o,
                    // collapsible is true on the first and the rest is false.
                    collapsible: !index,
                    arr: []
                })
            }

            if (!found2) {
                linkedTreatments.push({
                    department: o,
                    // collapsible is true on the first and the rest is false.
                    collapsible: !index,
                    arr: []
                })
            }
        })

        facilityStepThreeAddFormik.setFieldValue('linkedCareprofessionals',
            linkedCareprofessionals
        )
        facilityStepFourAddFormik.setFieldValue('linkedTreatments',
            linkedTreatments
        )
    }, [facilityStepTwoAddFormik.values])

    // useEffect(() => {
    //     console.log(facilityStepThreeAddFormik.values)
    // }, [facilityStepThreeAddFormik.values])

    // useEffect(() => {
    //     console.log(facilityStepFourAddFormik.values)
    // }, [facilityStepFourAddFormik.values])

    return <>
        <PopupModal
            fetchData={fetchData}
            facilityStepOneAddFormik={facilityStepOneAddFormik}
            facilityStepTwoAddFormik={facilityStepTwoAddFormik}
            facilityStepThreeAddFormik={facilityStepThreeAddFormik}
            facilityStepFourAddFormik={facilityStepFourAddFormik}
            addCareFacilityData={{
                data: addCareFacilityMutation.data,
                isLoading: addCareFacilityMutation.isLoading
            }}
        />
    </>
}

export default AddInterface
