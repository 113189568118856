import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import { GetLanguageFileRequest, GetLanguageFileResponse } from '@app/types/type.app.language'
import { } from '@app/types/type.token'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        // placing strings api call here
        getLanguageFile: builder
            .mutation<GetLanguageFileResponse, GetLanguageFileRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetLanguageFileRequest = {
                        data: {
                            locale: body.data.locale
                        }
                    }
                    return {
                        url: 'api/v1/getLanguageFile',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })

    })
})

export const {
    useGetLanguageFileMutation
} = appApi
