import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { css, useTheme } from '@emotion/react'

const GlobalStyle = () => {
    const theme = useTheme()

    return css`
        
        .registration-page {

            & .main-content {

                & .status-card {
                    color: ${ theme.config.endUser?.registration.mainContent.statusCard.color };
                    background-color: ${ theme.config.endUser
        ?.registration.mainContent.statusCard.background };
                }

                .dropdown-list {
                    .search-box {
                        background-color: ${ theme.config.endUser?.registration.mainContent
            .dropdownList.searchBox.backgroundColor };
                    }
                }

            }

            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    //padding-top is better than margin-top. if you do margin top.
                    //any components above will also go down.
                    padding-top: 240px;

                    & .form-container {
                        padding-block-start: 7vh;
                    }

                    & > .container, & > .container-fluid  {
                        padding-block-end: 11vh;
                    }

                    & .status-card {
                        padding: 40px 12%;

                        position: relative;
                        top: -27%;
                        margin-bottom: -25px;
                    }
                }
            }



            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {

                & .header {
                    height: 58px;

                    & img {
                        width: 98px;
                    }
                }


                
                & .main-content {

                    padding-top: 113px;
                    position: absolute;
                    //100% minus the height of the previous divs. 100% means height of viewport
                    //in this case
                                    /* height: calc(100% - 113px - 58px); */
                    height: calc(100% - 113px);


                    & .form-container {
                        display: flex;
                        padding-block-start: 5vh;
                        flex-direction: column;
                        height: 100%;

                        & > div:nth-of-type(1) {
                            flex: 1;
                        }

                        & > div:nth-of-type(2) {
                            flex: 0;
                        }

                    }

                    & .status-card {
                        padding: 12px 4%;
                        
                        position: relative;
                        top: -27%;
                        margin-bottom: -25px;
                    }

                }


            }   
            .pagination {
                --bs-pagination-padding-x: 0.125rem;
                --bs-pagination-padding-y: 0.125rem;
                --bs-pagination-font-size: 1rem;
                --bs-pagination-color: ${ theme.config.endUser?.registration.pagination.color };
                --bs-pagination-bg: ${ theme.config.endUser?.registration.pagination.bg };
                --bs-pagination-border-width: 1px;
                --bs-pagination-border-color: ${ theme.config.endUser?.registration.pagination.borderColor };
                --bs-pagination-border-radius: 50rem;
                --bs-pagination-hover-color: ${ theme.config.endUser?.registration.pagination.hoverColor };
                --bs-pagination-hover-bg: ${ theme.config.endUser?.registration.pagination.hoverBg };
                --bs-pagination-hover-border-color: ${ theme.config.endUser?.registration.pagination.hoverBorderColor };
                --bs-pagination-focus-color: $${ theme.config.endUser?.registration.pagination.focusColor };
                --bs-pagination-focus-bg: ${ theme.config.endUser?.registration.pagination.focusBg };
                --bs-pagination-focus-box-shadow: none;
                --bs-pagination-active-color: ${ theme.config.endUser?.registration.pagination.activeColor };
                --bs-pagination-active-bg: ${ theme.config.endUser?.registration.pagination.activeBg };
                --bs-pagination-active-border-color: ${ theme.config.endUser?.registration.pagination.activeBorderColor };
                --bs-pagination-disabled-color: ${ theme.config.endUser?.registration.pagination.disabledColor };
                --bs-pagination-disabled-bg: ${ theme.config.endUser?.registration.pagination.disabledBg };
                --bs-pagination-disabled-border-color: ${ theme.config.endUser?.registration.pagination.disabledBorderColor };
                display: flex;
                padding-left: 0;
                list-style: none;
            }

            .page-link {
                z-index: 1 !important;
                position: relative;
                display: block;
                padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
                font-size: var(--bs-pagination-font-size);
                color: var(--bs-pagination-color);
                text-decoration: none;
                background-color: var(--bs-pagination-bg);
                border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
                transition: color 0.15s ease-in-out, 
                background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, 
                box-shadow 0.15s ease-in-out;
            }

            @media (prefers-reduced-motion: reduce) {
                .page-link {
                    transition: none;
                }
            }

            .page-link:hover {
                z-index: 2;
                color: var(--bs-pagination-hover-color);
                background-color: var(--bs-pagination-hover-bg);
                border-color: var(--bs-pagination-hover-border-color);
            }

            .page-link:focus {
                color: var(--bs-pagination-focus-color);
                background-color: var(--bs-pagination-focus-bg);
                outline: 0;
                box-shadow: var(--bs-pagination-focus-box-shadow);
            }

            .page-link.active,
            .active>.page-link {
                color: var(--bs-pagination-active-color);
                background-color: var(--bs-pagination-active-bg);
                border-color: var(--bs-pagination-active-border-color);
            }

            .page-link.disabled,
            .disabled>.page-link {
                color: var(--bs-pagination-disabled-color);
                pointer-events: none;
                background-color: var(--bs-pagination-disabled-bg);
                border-color: var(--bs-pagination-disabled-border-color);
            }

            .page-item:not(:first-of-type) .page-link {
                margin-left: 0.25rem;
            }

            .page-item .page-link {
                border-radius: var(--bs-pagination-border-radius);
            }

            .pagination-lg {
                --bs-pagination-padding-x: 1.5rem;
                --bs-pagination-padding-y: 0.75rem;
                --bs-pagination-font-size: 1.25rem;
                --bs-pagination-border-radius: 50rem;
            }

            .pagination-sm {
                --bs-pagination-padding-x: 0.5rem;
                --bs-pagination-padding-y: 0.25rem;
                --bs-pagination-font-size: 0.875rem;
                --bs-pagination-border-radius: 50rem;
            }
            }

    `
}

export default GlobalStyle
