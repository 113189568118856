import { useAppDispatch } from '@app/app.hook'
import { ReasonWithMeState, QuestionInterfaceActions } from '@reasonWithMe/type'
import { setAnswerValue } from '@reasonWithMe/slice'
import _ from 'lodash'
import { useMemo } from 'react'
interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
}

const Bool = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const dispatch = useAppDispatch()

    const input = useMemo(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        const questionsAnswers = data.question?.questionAnswers

        const acq = {
            acqName: answerValue?.acqName === undefined ? '' : answerValue?.acqName,
            acqValue: answerValue?.acqValue === undefined ? '' : answerValue?.acqValue
        }

        return <div className={'question-bool row row-cols-1 row-cols-md-2 btn-group-checkbox-list'}
            role={'group'}>
            {
                _.map(questionsAnswers, (obj, i) => {
                    const key = [
                        'question-choice', '-', i
                    ].join('')

                    return <div className={'col'} key={key}>
                        <input
                            type={'checkbox'}
                            className={'btn-check'}
                            id={key}
                            autoComplete={'off'}
                            checked={ obj.acqValue === acq.acqValue &&
                                obj.acqName === acq.acqName}
                            onChange={() => {
                                if (componentDispatch !== undefined) {
                                    componentDispatch({
                                        type: 'SET_ANSWER_VALUE',
                                        value: obj
                                    })
                                } else {
                                    dispatch(setAnswerValue(obj))
                                }
                            }}
                        />
                        <label className={'btn btn-dummy'} htmlFor={key} >

                            <div className={'question-checkbox'}>
                                <div className={'card justify-content-center px-3 py-2'}>
                                    <div className={'d-flex flex-column'}>
                                        <div className={'d-flex align-items-center'}>
                                            <div className={'p text-start'}><span style={{
                                                verticalAlign: 'inherit'
                                            }}>
                                                <span style={{ verticalAlign: 'inherit' }}>
                                                    {obj.acqName}
                                                </span>
                                            </span></div>
                                            <div
                                                className={'question-checkmark ms-auto'}>
                                                <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                            </div>
                                            <div
                                                className={'question-plussign ms-auto'}>
                                                <i className={'fa-light fa-plus mx-auto'}></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </label>
                    </div>
                })
            }
        </div>
    }, [questionInterface])

    return input
}

export default Bool
