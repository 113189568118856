import { useEffect } from 'react'

import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { TokenData } from '@app/types/type.token'
import { useGetDepartmentCareFacilitiesMutation } from '@doc/api'

import { MODULE_TABLE } from '@app/app.config'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import _ from 'lodash'
import { toast } from 'react-toastify'

import SelectCareInstitutionDropdown from '@doc/components/patients/add/steps/StepTwo/SelectCareInstitutionDropdown'
import SelectDepartmentDropdown from '@doc/components/patients/add/steps/StepTwo/SelectDepartmentDropdown'
import {
    DepartmentCareFacilities,
    DepartmentCareFacilitiesAction,
    DepartmentCareFacilitiesState
} from '@doc/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    patientAddStepTwoFormik: FormikProps<{
        careFacilityId: string;
        departmentId: string;
    }>,
    departmentCareFacilitiesState: DepartmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction: React.Dispatch<DepartmentCareFacilitiesAction>
}
const StepTwo = ({
    patientAddStepTwoFormik,
    departmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction
}: ComponentProps) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const [
        getDepartmentCareFacilities,
        getDepartmentCareFacilitiesMutation
    ] = useGetDepartmentCareFacilitiesMutation()
    // Use useReducer

    const extractCareInstitutionsAndDepartments: (data: DepartmentCareFacilities[]) => [
        {
            careInstitutionId: string;
            facilityName: string;
        }[], {
            departmentId: string;
            departmentName: string;
        }[]
    ] = (data) => {
        const careInstitutions: {
            careInstitutionId: string;
            facilityName: string;
        }[] = []
        const departments: {
            departmentId: string;
            departmentName: string;
        }[] = []

        data.forEach(item => {
            const careInstitution = {
                careInstitutionId: item.careInstitutionId,
                facilityName: item.careInstitutionData[0]?.facilityName || ''
            }

            const department = {
                departmentId: item.departmentId,
                departmentName: item.departmentData[0]?.departmentName || ''
            }

            // Check if the id already exists in the arrays
            const existingCareInstitution = careInstitutions
                .find(c => c.careInstitutionId === careInstitution.careInstitutionId)
            const existingDepartment = departments
                .find(d => d.departmentId === department.departmentId)

            // If it doesn't exist, push the new data
            if (!existingCareInstitution) {
                careInstitutions.push(careInstitution)
            }

            if (!existingDepartment) {
                if (item.careInstitutionId === departmentCareFacilitiesState.main.selectedOptions
                    ?.careInstitution.careInstitutionId) {
                    departments.push(department)
                }
            }
        })

        return [careInstitutions, departments]
    }

    const [careInstitutions, departments] = extractCareInstitutionsAndDepartments(
        getDepartmentCareFacilitiesMutation.data?.data || []
    )

    const unsubscribeGetData = () => {
        const unsubscribeMutation = getDepartmentCareFacilities({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        unsubscribeGetData()

        let getDepartmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getDepartmentCareFacilities.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getDepartmentsPromise = getDepartmentCareFacilities({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getDepartmentsPromise && getDepartmentsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        if (getDepartmentCareFacilitiesMutation.error) {
            const message = getErrorText(getDepartmentCareFacilitiesMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDepartmentCareFacilitiesMutation.error])

    const StepTwoContent = <>
        <h2 className={'mb-5 fw-semibold '} >{strings.doc?.text
            .patient.add_interface.stepIndicators['2']}</h2>
        <SelectCareInstitutionDropdown
            patientAddStepTwoFormik={patientAddStepTwoFormik}
            data={careInstitutions}
            departmentCareFacilitiesState={departmentCareFacilitiesState}
            dispatchDepartmentCareFacilitiesAction={dispatchDepartmentCareFacilitiesAction}
        />
        <SelectDepartmentDropdown
            patientAddStepTwoFormik={patientAddStepTwoFormik}
            data={departments}
            departmentCareFacilitiesState={departmentCareFacilitiesState}
            dispatchDepartmentCareFacilitiesAction={dispatchDepartmentCareFacilitiesAction}
        />
    </>

    const result = <div id={'step-two-content'}>
        {StepTwoContent}
    </div>
    return <>{result}</>
}

export default StepTwo
