import { OpenListInputs } from '@reasonWithMe/type'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
}

const Open = ({
    answerType, outerIndex, innerIndex,
    formInput
}: ComponentProps) => {
    return <input
        className={'form-control form-control-sm'}
        type={'text'}
        name={`${ answerType }-${ outerIndex }-${ innerIndex }`}
        value={formInput.userTypedAnswer as string}
        onChange={(e) => {
        }}
        onBlur={(e) => {
        }}
    />
}

export default Open
