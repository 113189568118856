import styled from '@emotion/styled'
import { useState } from 'react'

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ImageAuto = styled.img`
    @media only screen and (
        max-width: 767px
        ) {
            max-height: 80%;
            max-width: 80%;
    }
    @media only screen and (
        min-width: 767px
        ) {
            max-height: 90%;
            max-width: 90%;
    }
    cursor: auto;
`

export const ImagePointer = styled.img`
    margin: auto;
    max-height: 90%;
    max-width: 90%;
    cursor: pointer;

    display: block;
    width: auto;
    height: auto;
`

/**
* Renders a lightbox that displays an image with a black background overlay.
* @prop {string} imageUrl - The URL of the image to display.
* @prop {function} onClose - The function to call when the lightbox is closed.
*/
export function Lightbox ({ onModal, imageUrl, onClose }: {
     onModal?: boolean, imageUrl: string, onClose: () => void }) {
    const [, setIsOpen] = useState(true)

    /**
    * Closes the lightbox by setting the isOpen state variable to false.
    */
    function handleClose () {
        setIsOpen(false)
        onClose()
    }

    /**
    * Renders the lightbox using the createPortal method to render
    * content outside of the current component's parent element.
    */
    return <Overlay
        style={{
            zIndex: onModal ? 1 : 'initial'
        }}
        onClick={handleClose}
        data-testid={'lightbox-overlay'}
    >
        <ImageAuto
            src={imageUrl}
            onClick={e => e.stopPropagation()}
            data-testid={'lightbox-image'}
        />
    </Overlay>
}

/**
* Renders an image that displays a lightbox when clicked.
* @prop {string} imageUrl - The URL of the image to display.
*/
export function ImageLightbox ({ onModal, imageUrl, onLoad }:
    { onModal?: boolean, imageUrl: string, onLoad: () => void }) {
    const [isLightboxOpen, setIsLightboxOpen] = useState(false)

    /**
    * Sets the isLightboxOpen state variable to true, which causes the lightbox to render.
    */
    function handleImageClick () {
        setIsLightboxOpen(true)
    }

    /**
    * Sets the isLightboxOpen state variable to false, which hides the lightbox.
    */
    function handleLightboxClose () {
        setIsLightboxOpen(false)
    }

    /**
    * Renders the image and the lightbox component.
    * The lightbox component is only rendered when isLightboxOpen is true.
    */
    return (
        <>
            <ImagePointer
                src={imageUrl}
                onClick={handleImageClick}
                onLoad={onLoad}
                data-testid={'pointer-image'}
            />
            {isLightboxOpen && <Lightbox onModal={onModal}
                imageUrl={imageUrl} onClose={handleLightboxClose} />}
        </>
    )
}
