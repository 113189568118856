import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { CarepathKeys, CarepathValues } from '@fmt/type'
import { FormikProps } from 'formik'
import _ from 'lodash'

interface ComponentProps {
    readOnly: boolean
    carepathEditFormik: FormikProps<CarepathValues>
    handleKeyDown: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CarepathKeys
    ) => void
}

const Datename = ({ readOnly, carepathEditFormik, handleKeyDown }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: CarepathKeys = 'dateName'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.CAREPATH.EDIT.NAME}
                    className={'form-label'}>{
                        strings.fmt?.text.carepath.details.datename
                    }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <select
                    disabled={readOnly}
                    value={carepathEditFormik.values.dateName}
                    className={[
                        'form-control-sm',
                        readOnly ? 'form-control-plaintext' : 'form-control  form-select',
                        carepathEditFormik.errors.dateName &&
                         'border-danger'
                    ].join(' ')}
                    name={fieldName}
                    onBlur={carepathEditFormik.handleBlur}
                    placeholder={!readOnly ? strings.fmt?.text.carepath.details.datename : ''}
                    id={IDS.CAREPATH.EDIT.DATENAME}
                    onChange={!readOnly ? carepathEditFormik.handleChange : () => {}}
                    onKeyDown={handleKeyDown}
                    required >
                    {/* show options */}

                    {
                        _.map([
                            {
                                label: '<select a value>',
                                value: ''
                            },
                            {
                                label: 'Operation Date Name',
                                value: 'operation_date_name'
                            },
                            {
                                label: 'Therapeutic Date Name',
                                value: 'therapeutic_date_name'
                            },
                            {
                                label: 'Discharge Date Name',
                                value: 'discharge_date_name'
                            }
                        ], (o, i) => {
                            const key = [
                                'treatment-choice', '-', i
                            ].join('')
                            return <option key={key}
                                value={o.value}
                            >{o.label}</option>
                        })
                    }
                </select>
                <div className={'form-text error'}>{
                    carepathEditFormik.errors.dateName
                        ? carepathEditFormik.errors.dateName
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default Datename
