
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { smartSearch } from '@app/app.method'
import { selectStrings } from '@app/slices/slice.app'
import { setAnswerValue } from '@reasonWithMe/slice'
import { QuestionAnswer, QuestionInterfaceActions, ReasonWithMeState } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
}

const List = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const dispatch = useAppDispatch()

    const [search] = useState<string>('')
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const input = useMemo(() => {
        /** how should the input be rendered */
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        /**
         * render a list of checkboxes BUT disable the unchecked boxes
         * once the value of questionAnswersAllowed has reached.
         * expected answers are the following:
         * - if questionAnswersAllowed === 1, answerValue should be a string.
         * - if questionAnswersAllowed > 1, should be [string1, string2]
         */

        // console.log(`answers so far: ${ answerValue }`)

        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', data.question?.questionId
            ].join('')

            const isChecked = _.includes(
                answerValue,
                obj.acqValue
            )

            /** check if it is an array AND if the number of values
             * exceed the number of answers allowed.
             * Make sure you exclude checked boxes
             */
            const isDisabled = _.isArray(answerValue)
                // this will only be the case if the questionAnswersAllowed is truthy
                //  AND greater than one now. You don't want to deselect the cell every time.
                ? data.question?.questionAnswersAllowed
                    ? answerValue.length >= (data.question?.questionAnswersAllowed || 0)
                        ? data.question.questionAnswersAllowed >= 1
                        : false
                    : false
                : false

            const selectionCellOnclick = () => {
                if (_.isArray(answerValue)) {
                    const found = _.find(answerValue, o => {
                        return (
                            o === obj.acqValue
                        )
                    })

                    if (found !== undefined) {
                        /** remove from list. */
                        const arr = _.filter(answerValue, o => {
                            return (
                                o !== found
                            )
                        })

                        /** if the arr has no elements, turn to undefined */
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: arr.length <= 0 ? undefined : arr
                            })
                        } else {
                            dispatch(setAnswerValue(arr.length <= 0 ? undefined : arr))
                        }
                    } else {
                        // pushes the value to the list.

                        // this logic would apply if the questionAnswersAllowed
                        // is greater than 1. so what we will do is rely on the else
                        // condition by adding an and condition
                        if (data.question?.questionAnswersAllowed
                            ? data.question.questionAnswersAllowed === 1
                            : false
                        ) {
                            if (componentDispatch !== undefined) {
                                componentDispatch({
                                    type: 'SET_ANSWER_VALUE',
                                    value: [obj.acqValue]
                                })
                            } else {
                                dispatch(
                                    setAnswerValue(
                                        [obj.acqValue]
                                    )
                                )
                            }
                        } else {
                            if (componentDispatch !== undefined) {
                                componentDispatch({
                                    type: 'SET_ANSWER_VALUE',
                                    value: _.concat(answerValue, obj.acqValue)
                                })
                            } else {
                                dispatch(
                                    setAnswerValue(
                                        _.concat(answerValue, obj.acqValue)
                                    )
                                )
                            }
                        }
                    }
                } else {
                    if (obj.acqValue === answerValue) {
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: undefined
                            })
                        } else {
                            dispatch(setAnswerValue(undefined))
                        }
                    } else {
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: [obj.acqValue]
                            })
                        } else {
                            dispatch(setAnswerValue([obj.acqValue]))
                        }
                    }
                }
            }

            // if there are too many choices, use col-6
            return <div className={'col'} key={key}>
                <input type={'checkbox'}
                    className={[
                        'btn-check',
                        isDisabled && !isChecked ? 'disabled' : ''
                    ].join(' ')}
                    autoComplete={'off'}
                    id={key}
                    checked={isChecked}
                    // use class name instead.
                    // disabled={isDisabled && !isChecked}
                    onChange={() => {
                        // if (!(isDisabled && !isChecked)) {
                        //     selectionCellOnclick()
                        // }
                        // use on any checkbox
                        selectionCellOnclick()
                    }}
                />
                <label className={'btn btn-dummy'} htmlFor={key}>

                    <div className={'question-checkbox'}>
                        <div className={'card justify-content-center px-3 py-2'}>

                            <div className={'d-flex flex-column'}>
                                <div className={'d-flex align-items-center'}>
                                    <div className={'p text-start'}>
                                        {obj.acqName}
                                    </div>
                                    {
                                        isChecked
                                            ? <div className={'question-checkmark ms-auto'}>
                                                <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                            </div>
                                            : <div className={'question-plussign ms-auto'}>
                                                <i className={'fa-light fa-plus mx-auto'}></i>
                                            </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>

                </label>
            </div>
        }

        // filter by acqName.
        const filteredData = smartSearch(data.question?.questionAnswers || [], [], search)

        return <div className={'container-fluid'}>
            <div className={'row row-cols-1 btn-group-checkbox-list mt-3'}
                role={'group'}>
                {
                    _.map(filteredData, renderInputs)
                }
            </div>
        </div>
    }, [questionInterface, search])

    // entire text change message:
    let textAllowedMessage = [
        [
            strings.reason_with_me?.text.list.allowed,
            ':'
        ].join(''),
        questionInterface.currentReasonWithMeResponse
            .reasoningData.question
            ?.questionAnswersAllowed
    ].join(' ')

    if ((questionInterface.currentReasonWithMeResponse
        .reasoningData.question
        ?.questionAnswersAllowed || 0) <= 0) {
        textAllowedMessage = strings.reason_with_me?.text.list.no_restrictions || ''
    }

    return <div className={['question-list', isMobile ? 'px-0' : 'px-4'].join(' ')}>
        {/* place search text box here */}
        {/* <div className={'form-group mb-4'}>
            <div className={'form-control-wrapper'}>
                <input type={'search'}
                    className={'form-control form-search'}
                    placeholder={strings.app?.text.search.text}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    id={IDS.LIST.SEARCH} />
                <label
                    className={'form-floaticon'}
                    htmlFor={IDS.LIST.SEARCH}
                >
                    <div
                        className={'icon '}>
                        <i className={'fa-light fa-search'} aria-hidden={'true'}></i>
                    </div>
                </label>
            </div>
        </div> */}
        <p className={'text-center'}>{textAllowedMessage}</p>
        {input}
    </div>
}

export default List
