
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectDateFormats } from '@app/slices/slice.app'
import { setAnswerValue } from '@reasonWithMe/slice'
import { QuestionInterfaceActions, ReasonWithMeState } from '@reasonWithMe/type'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>,
}

const Resume = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const dispatch = useAppDispatch()

    const dateFormats = useAppSelector(selectDateFormats)

    const questionAnswers = questionInterface.currentReasonWithMeResponse
        .reasoningData.question?.questionAnswers

    const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

    return <div className={'question-resume'}>

        <div className={'row g-2'}>

            {
                _.map(questionAnswers, (o, index) => {
                    const key = `reasoning-button-${ o.reasoningId }-${ index }`

                    return <div key={key} className={' col-auto'}>
                        <button type={'button'}
                            className={[
                                'btn popup-button w-100 py-2',
                                _.isEqual(answerValue, o) ? 'btn-secondary' : 'btn-primary'
                            ].join(' ')}
                            onClick={() => {
                            // set the answerValue but will use another property on the get
                            // reasoning call.
                                if (componentDispatch !== undefined) {
                                    componentDispatch({
                                        type: 'SET_ANSWER_VALUE',
                                        value: {
                                            reasoningId: o.reasoningId,
                                            addedOn: o.addedOn
                                        }
                                    })
                                } else {
                                    dispatch(setAnswerValue({
                                        reasoningId: o.reasoningId,
                                        addedOn: o.addedOn
                                    }))
                                }
                            }}>

                            <span>
                                {format(fromUnixTime(
                                    o.addedOn || 0
                                ), dateFormats.format9) || ''}
                            </span>
                        </button>
                    </div>
                })
            }
        </div>

    </div>
}

export default Resume
