
import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@doc/constants'
import { Patient, PatientKeys, PatientValues } from '@doc/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    patientEditFormik: FormikProps<Pick<
    PatientValues, 'firstName' | 'lastName' | 'email' | 'patientNumber'> & {active: boolean}>
    handleKeyDown: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: PatientKeys
    ) => void
}

const Active = ({ patientEditFormik }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: keyof Patient['userData'][0] = 'active'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.PATIENT.EDIT.ACTIVE}
                    className={'form-label'}>{
                        strings.doc?.text.patient.patient_menu.active
                    }</label>
            </div>
            <div className={'col-auto'}>
                <div className={'form-check form-switch settings-switch col-auto'}>
                    <input className={'form-check-input'}
                        type={'checkbox'} role={'switch'}
                        checked={patientEditFormik.values.active}
                        onChange={() => {
                            patientEditFormik.setFieldValue(
                                fieldName, !patientEditFormik.values.active
                            )
                        }}
                    />
                </div>
                <div className={'form-text error'}>{
                    patientEditFormik.errors.active
                        ? patientEditFormik.errors.active
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default Active
