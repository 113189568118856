
import { useAppSelector } from '@app/app.hook'
import { fromUnixTime } from 'date-fns'
import { useMemo } from 'react'
// for react-datepicker locale. requires date-fns.

import NewDatePicker from '@app/components/NewDatePicker'
import { selectStrings } from '@app/slices/slice.app'
import { GPACareplanStepIdDetails } from '@doc/type'
import { IDS } from '@reasonWithMe/constants'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const SingleDate = ({
    reasoningObj
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue

    const DatePicker = useMemo(() => {
        const dateType = questionData?.questionAnswers[0]?.answerValue
        const timeFormat = questionData?.questionAnswers[0]?.timeFormat

        const singleDate = answerValue !== undefined && typeof answerValue === 'number'
            ? fromUnixTime(answerValue)
            : undefined

        return (<>
            <NewDatePicker
                disabled
                popperPlacement={'bottom'}
                id={IDS.SINGLE_DATE.INPUT}
                isDate={(date) => {
                }}
                singleDate={singleDate}
                dateType={dateType}
                timeFormat={timeFormat}
            />

        </>

        )
    }, [strings])

    return <div className={'question-single-date'}>
        {DatePicker}
    </div>
}

export default SingleDate
