
import {
    EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE,
    EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE,
    MODULE_TABLE,
    REGISTRATION_CUSTOM_MATCH
} from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectRouter } from '@app/app.store'
import {
    selectActiveModules,
    selectReturnToLink,
    selectStrings,
    selectUserLoginStatus,
    setUserLoginStatus
} from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import {
    useChangePasswordMutation,
    useDoLoginMutation,
    useGetWorkflowMutation,
    useUpdateWorkflowProgressMutation,
    useValidateMFAMutation
} from '@login/api'
import {
    selectCurrentWorkflowStep,
    selectModuleId,
    selectRenderRoutesInitial,
    selectWorkflowId,
    setRenderRoutesInitial
} from '@login/slices/slice.workflow'
import GetWorkflowConsumer from '@login/workflow/GetWorkflowConsumer'
import UpdateWorkflowProgressConsumer from '@login/workflow/UpdateWorkflowConsumer'
import { useEffect } from 'react'

import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { toast } from 'react-toastify'

import { useValidateRouteAll } from '@login/MutationProvider/validateRouteAll'
import { setIsFullscreenOverlay } from '@login/slices/slice.login'
import { fromBase64 } from 'js-base64'
import _ from 'lodash'

const CommonWorkflow = () => {
    // NOTE: useParams will never work here.
    const validateRouteAll = useValidateRouteAll()
    const renderRoutesInitial = useAppSelector(selectRenderRoutesInitial)

    // priority is to getWorkflow on every page navigation.
    const [getWorkflow, getWorkflowMutation] = useGetWorkflowMutation()

    const [
        updateWorkflowProgress,
        updateWorkflowProgressMutation
    ] = useUpdateWorkflowProgressMutation()

    const [, doLoginMutation] = useDoLoginMutation({
        fixedCacheKey: 'shared-login-key'
    })

    const [, validateMFAMutation] = useValidateMFAMutation({
        fixedCacheKey: 'shared-validate-mfa-key'
    })

    const [, changePasswordMutation] = useChangePasswordMutation({
        fixedCacheKey: 'shared-force-password-change-key'
    })

    useEffect(() => {
        console.log('common workflow mounted after new auth user')
    }, [])

    /** unfortunately, it's best to put updateWorkflowProgress in the components
     * that need it.
     */

    // created so we can directly get the workflow of the user
    // without checking the route this time.
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const token = useAppSelector(selectToken)
    const moduleId = useAppSelector(selectModuleId)
    const workflowId = useAppSelector(selectWorkflowId)
    const router = useAppSelector(selectRouter)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const activeModules = useAppSelector(selectActiveModules)
    const returnToLink = useAppSelector(selectReturnToLink)
    const userLoginStatus = useAppSelector(selectUserLoginStatus)

    // NOTE: you cannot get a workflow if you don't have getModules.

    // this function cannot easily have a unit test on its own.
    // its the pure function that you want to test.for example, did you
    // make sure that validateRouteAll being a hook and then checking
    // if any pure functions in it work? do that.
    const routeRedirection = () => {
        console.log('return link in question: ', returnToLink)

        if (returnToLink) {
            let targetRoute = returnToLink
            const errorMessage = `${ returnToLink } doesn't exist. Going to default route.`
            const foundRoute = validateRouteAll(activeModules.arr, returnToLink, false)
            console.log('route: ', foundRoute)
            if (!foundRoute.route) {
                toast.error(errorMessage, { ...TOASTIFY_DEFAULT_OPTIONS })
                console.error(errorMessage)
                targetRoute = token.details.roid
            }
            return targetRoute
        }

        return token.details.roid
    }

    const settingUserLoginStatus = () => {
        // record last logged in person.
        console.log('recording sub in login')
        dispatch(
            setUserLoginStatus({
                sub: token.details.sub,
                key: 'login'
            })
        )
    }

    useEffect(() => {
        // a check if you just finished doLogin AND once that user has its activeModules updated.
        // only dependency i can think of is just activeModules.id
        const loginCheck = doLoginMutation.data?.status === 'OK'

        if (loginCheck && token.mode === 'auth') {
            console.log('getting workflow after authentication ONLY and getting its activeModules.')

            let routeToUse = token.details.roid

            if (doLoginMutation.data?.status === 'OK') {
                // check if the user login and logout strings are the same.
                // make sure they are not empty strings
                if (
                    (userLoginStatus.login?.length || 0) > 0 &&
                    (userLoginStatus.logout?.length || 0) > 0
                ) {
                    if (userLoginStatus.login === userLoginStatus.logout) {
                        console.log('redirecting user to last saved path')
                        routeToUse = routeRedirection()
                        settingUserLoginStatus()
                    }
                } else {
                    console.log('the user login status values are empty')
                }
            } else if (validateMFAMutation.data?.status === 'OK') {
                if (
                    (userLoginStatus.login?.length || 0) > 0 &&
                    (userLoginStatus.logout?.length || 0) > 0
                ) {
                    if (userLoginStatus.login === userLoginStatus.logout) {
                        console.log('mfa: redirecting user to last saved path')
                        routeToUse = routeRedirection()
                        settingUserLoginStatus()
                    }
                } else {
                    console.log('the user login status values are empty')
                }
            } else if (changePasswordMutation.data?.status === 'OK') {
                if (
                    (userLoginStatus.login?.length || 0) > 0 &&
                    (userLoginStatus.logout?.length || 0) > 0
                ) {
                    if (userLoginStatus.login === userLoginStatus.logout) {
                        console.log('pass change: redirecting user to last saved path')
                        routeToUse = routeRedirection()
                        settingUserLoginStatus()
                    }
                } else {
                    console.log('the user login status values are empty')
                }
            }

            getWorkflow({
                authToken: token.value,
                data: {
                    route: routeToUse
                }
            })

            // enable fullscreen
            dispatch(setIsFullscreenOverlay({
                show: true,
                text: strings.app?.text.getting_workflow_data || ''
            }))
        }
    }, [activeModules.id])

    // regardless of what type. if the percentComplete is -1, proceed to the next workflow.
    useEffect(() => {
        if (currentWorkflowStep?.stepProgress.percentComplete === -1 && token.mode === 'auth') {
            console.log('getting workflow after a bugged step')
            getWorkflow({
                authToken: token.value,
                data: {}
            })

            // enable fullscreen
            dispatch(setIsFullscreenOverlay({
                show: true,
                text: strings.app?.text.getting_workflow_data || ''
            }))
        }
    }, [currentWorkflowStep])

    /** a useEffect specifically for the currentWorkflowStep. if the progress is 100,
     * then execute the call.
     */
    useEffect(() => {
        if (currentWorkflowStep && currentWorkflowStep.stepProgress
            .percentComplete >= 100 && moduleId && token.mode === 'auth') {
            console.log('getting workflow with a moduleId after a step is complete ')
            // note, if you do this call on the last workflow step, with a moduleId, you'd end
            // up with an empty data object. by doing this, you'd be redirected to the
            // defaultRoute of the logged in user. We don't want you to do that yet.
            // You have to do the call WITHOUT the moduleId THIS TIME.

            if (currentWorkflowStep.stepId === _.last(
                _.sortBy(getWorkflowMutation.data?.data?.workflowSteps || [], 'stepSequence')
            )?.stepId) {
                console.log("you are at the last step. don't call moduleId and workflowId anymore")
                getWorkflow({
                    authToken: token.value,
                    data: {}
                })

                // enable fullscreen
                dispatch(setIsFullscreenOverlay({
                    show: true,
                    text: strings.app?.text.getting_workflow_data || ''
                }))
            } else {
                console.log('proceed to next step via getWorkflow')
                getWorkflow({
                    authToken: token.value,
                    data: {
                        moduleId,
                        workflowId
                    }
                })

                // enable fullscreen
                dispatch(setIsFullscreenOverlay({
                    show: true,
                    text: strings.app?.text.getting_workflow_data || ''
                }))
            }
        }
    }, [currentWorkflowStep, moduleId])

    useEffect(() => {
        // checking if the current link you are in is accessible via getModules.
        if (activeModules.arr.length && token.mode === 'auth') {
            const foundRoute = validateRouteAll(activeModules.arr, router.location.pathname, false)
            // console.log('check route before getWorkflow call: ', foundRoute)
            if (!foundRoute.route) {
                console.log('getting workflow from an inaccessible route')
                getWorkflow({
                    authToken: token.value,
                    data: {}
                })

                // enable fullscreen
                dispatch(setIsFullscreenOverlay({
                    show: true,
                    text: strings.app?.text.getting_workflow_data || ''
                }))
            }
        }
    }, [activeModules.id])

    /** requested by tim: on every route change, call getWorkflow BUT
     *  make sure token is valid first AND make sure to save the url
     *  especially if the localStorage is empty. make sure activeModules
     * is truthy. and make sure the user is auth.
    */

    useEffect(() => {
        // make sure the user has modules, an auth, and is valid.
        if (token.valid === true && token.mode === 'auth') {
            if (
                REGISTRATION_CUSTOM_MATCH.test(router.location.pathname)
            ) {
                console.log('ignoring reg module routes on workflow route logic')
            } else if (
                EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE.test(router.location.pathname) ||
                EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE.test(router.location.pathname)
            ) {
                console.log('ignoring quick log email route on workflow route logic')
            } else if (
                Object.values(MODULE_TABLE.reasonWithMe.routes)
                    .includes(router.location.pathname)
            ) {
                console.log('ignoring rwm module routes on workflow route logic')
            } else if (
                Object.values(MODULE_TABLE.login.routes)
                    .includes(router.location.pathname)
            ) {
                console.log('ignoring log module routes on workflow route logic')
            } else {
                // then getWorkflow
                console.log('getting workflow after every route change')
                getWorkflow({
                    authToken: token.value,
                    data: {}
                })

                // NOT ENABLING FULLSCREEN HERE because of how annoying it is.
            }
        }
    }, [router.location.pathname, activeModules.id])

    // if the token.id in the storage is the same as the one in the uuid, just do a getWorkflow.
    useEffect(() => {
        if (REGISTRATION_CUSTOM_MATCH.test(router.location.pathname) && activeModules.arr.length) {
            const [, , uuid, id] = _.split(router.location.pathname, '/')

            if ((uuid && id) && (id === token.details.sub && doLoginMutation.isSuccess === false)) {
                console.log('REG onmount: if the token.id in the storage is the same ' +
                ' as the one in the uuid, just do a getWorkflow. also, doLogin should be unset')
                const routeToUse = token.details.roid

                getWorkflow({
                    authToken: token.value,
                    data: {
                        route: routeToUse
                    }
                })

                // enable fullscreen
                dispatch(setIsFullscreenOverlay({
                    show: true,
                    text: strings.app?.text.getting_workflow_data || ''
                }))
            }
        }
    }, [activeModules.id])

    useEffect(() => {
        if (
            (EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE.test(router.location.pathname) ||
            EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE.test(router.location.pathname)) &&
            activeModules.arr.length
        ) {
            const [, , uuid, id, route] = _.split(router.location.pathname, '/')

            if ((uuid && id) && (id === token.details.sub && doLoginMutation.isSuccess === false)) {
                console.log('LOG onmount: if the token.id in the storage is the same ' +
                ' as the one in the uuid, just do a getWorkflow. also, doLogin should be unset')
                const routeToUse = route ? fromBase64(route) : token.details.roid

                //

                getWorkflow({
                    authToken: token.value,
                    data: {
                        route: routeToUse
                    }
                })

                // enable fullscreen
                dispatch(setIsFullscreenOverlay({
                    show: true,
                    text: strings.app?.text.getting_workflow_data || ''
                }))
            }
        }
    }, [activeModules.id])

    useEffect(() => {
        if (
            Object.values(MODULE_TABLE.reasonWithMe.routes)
                .includes(router.location.pathname) && activeModules.arr.length
        ) {
            console.log('onmount: getting workflow from rwm path being refreshed')
            getWorkflow({
                authToken: token.value,
                data: {}
            })

            // enable fullscreen
            dispatch(setIsFullscreenOverlay({
                show: true,
                text: strings.app?.text.getting_workflow_data || ''
            }))
        }
    }, [activeModules.id])

    // resets the workflow if the token mode is a guest BUT
    // the other useEffects from getWorkflowConsumer will still trigger
    // because this change will happen in the next lifecycle.
    // thus, we need to make sure that the workflow consumer logic is
    // accessible to auth.
    useEffect(() => {
        if (token.mode === 'guest') {
            getWorkflowMutation.reset()
        }
    }, [token.mode])

    useEffect(() => {
        if (getWorkflowMutation.isSuccess === true &&
            renderRoutesInitial === false
        ) {
            dispatch(
                setRenderRoutesInitial(true)
            )
        }

        if (getWorkflowMutation.isSuccess === true) {
            // enable fullscreen
            dispatch(setIsFullscreenOverlay({
                show: false,
                text: ''
            }))
        }
    }, [getWorkflowMutation.isSuccess])
    return <>
        {
            token.mode === 'auth'
                ? <>
                    <GetWorkflowConsumer
                        updateWorkflowProgress={updateWorkflowProgress}
                        getWorkflow={getWorkflow}
                        originalArgs={getWorkflowMutation.originalArgs}
                        data={getWorkflowMutation.data}
                    />
                    <UpdateWorkflowProgressConsumer
                        data={updateWorkflowProgressMutation.data}
                        originalArgs={updateWorkflowProgressMutation.originalArgs}
                    />
                </>
                : ''
        }

    </>
}

export default CommonWorkflow
