import { ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { CareprofessionalDashboardConfig } from '@stylesheet/config/admin/CareprofessionalDashboard'

export const CAREPROFESSIONAL_DASHBOARD_CONFIG: CareprofessionalDashboardConfig = {
    profileImgPlaceholder: {
        background: ADMIN_COLORS.gray_700
    },
    searchBox: {
        focus: {
            borderColor: ADMIN_COLORS.primary,
            boxShadow: ADMIN_COLORS.yellowShadow
        },
        background: ADMIN_COLORS.gray_700,
        icon: {
            textColor: ADMIN_COLORS.white
        }
    },
    dropdownList: {
        searchBox: {
            backgroundColor: ADMIN_COLORS.gray_700
        }
    },
    careprofessionalHeader: {
        color: ADMIN_COLORS.white,
        gradientColors: [
            ADMIN_COLORS.facilityHeaderColor[1],
            ADMIN_COLORS.facilityHeaderColor[2],
            ADMIN_COLORS.facilityHeaderColor[3]
        ],
        iconContainer: {
            color: ADMIN_COLORS.white,
            borderColor: ADMIN_COLORS.white
        },
        greetings: {
            title: {
                color: ADMIN_COLORS.white
            },
            subtitle: {
                color: ADMIN_COLORS.white,
                urgentColor: ADMIN_COLORS.yellow
            }
        }
    },
    navigationContainer: {
        borderRadius: '18px 18px 0 0',
        tab: {
            color: ADMIN_COLORS.white,
            boxShadow: ADMIN_COLORS.navigationShadow + ' 0px -17px 15px -16px',
            background: ADMIN_COLORS.midnightAzure,
            borderRadius: '18px 18px 0px 0px',
            activeBackground: ADMIN_COLORS.gray_800,
            activeColor: ADMIN_COLORS.dark
        }
    },
    mainContent: {
        textColor: ADMIN_COLORS.white,
        gradientColors: [
            ADMIN_COLORS.gray_800,
            ADMIN_COLORS.gray_900
        ],
        interfaces: {
            borderColor: ADMIN_COLORS.gray_100,
            background: ADMIN_COLORS.gray_800,
            boxShadow: ADMIN_COLORS.navigationShadow,
            hcCards: {
                borderColor: ADMIN_COLORS.gray_100,
                ltColor: ADMIN_COLORS.red,
                gtColor: ADMIN_COLORS.green
            }
        },
        recordMenu: {
            borderColor: ADMIN_COLORS.gray_100,
            background: ADMIN_COLORS.gray_800,
            tableBorderColor: ADMIN_COLORS.gray_100,
            tableEvenRowBackground: ADMIN_COLORS.gray_800,
            tableOddRowBackground: ADMIN_COLORS.gray_800
        },
        cells: {
            borderColor: ADMIN_COLORS.white
        },
        riskBorder: {
            borderColor: ADMIN_COLORS.white,
            greenColor: ADMIN_COLORS.green,
            yellowColor: ADMIN_COLORS.yellow,
            orangeColor: ADMIN_COLORS.question,
            redColor: ADMIN_COLORS.red,
            greyColor: ADMIN_COLORS.gray_400
        },
        progressBar: {
            background: ADMIN_COLORS.dark
        },
        riskContainer: {
            borderColor: ADMIN_COLORS.black,
            greenColor: ADMIN_COLORS.green,
            yellowColor: ADMIN_COLORS.yellow,
            orangeColor: ADMIN_COLORS.orange,
            redColor: ADMIN_COLORS.red,
            greyColor: ADMIN_COLORS.gray_400
        }
    }
}
