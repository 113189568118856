import { useAppSelector } from '@app/app.hook'
import { findRoute } from '@app/app.method'
import { selectStrings } from '@app/slices/slice.app'
import { SystemModule } from '@login/type'
import { useCallback } from 'react'

export const useValidateRoute = () => {
    const strings = useAppSelector(selectStrings)
    const callback = useCallback((arr: SystemModule[],
        module: string, route: string, showToast: boolean) => {
        if (arr.length) {
            const foundRoute = findRoute(
                arr, module, route
            )

            // NOTE: not all need to show a toast error.
            // only do this error toast method AFTER authentication.
            if (foundRoute?.route) {
                return foundRoute
            } else {
                if (strings.login?.message.error.route_denied && showToast) {
                    const message = [
                        route,
                        ':',
                        strings.login?.message.error.route_denied
                    ].join(' ')
                    // toast.error(
                    //     message,
                    //     { ...TOASTIFY_DEFAULT_OPTIONS }
                    // )
                    console.error(message)
                }
            }
        }

        return {
            route: '',
            showMenu: false
        }
    }, [strings])

    return callback
}
