
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { ImageLightbox } from '@careplan/components/ImageLightbox'
import ListRoutine from '@careplan/components/ListRoutine'
import Player from '@careplan/components/Player'
import { selectMediaPlayers, setMediaPlayer } from '@careplan/slice'
import { BuildingBlock, BuildingBlockHeader } from '@careplan/type'
import { back } from '@lagunovsky/redux-react-router'
import { MODULE_VERSION } from '@profile/constants'
import _ from 'lodash'
import { ReactElement, useEffect, useMemo, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player/lazy'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import { remarkDefinitionList } from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'

import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

// import ActivityDetails from '@careplan/components/careplanStep/ActivityssDetails'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { TokenData } from '@app/types/type.token'
import { VOLUME } from '@careplan/constants'
import { useGetSettingsArticleMutation } from '@profile/api'
import { AboutParams } from '@profile/type'
import {
    HeaderContent,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/careplanStep/Components'

import { useMediaQuery } from 'react-responsive'
const ContentPage = () => {
    /** THIS MODULE IS NOT FINISHED. YOU NEED AN API CALL TO GET ABOUT
     *  PAGE DATA LIKE A CAREPLAN STEP */
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const rootRef = useRef<HTMLDivElement | null>(null)
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const { listId } = useParams<AboutParams>()

    const [getSettingsArticle, getSettingsArticleMutation] = useGetSettingsArticleMutation()

    const data = getSettingsArticleMutation.data?.data
    const isLoading = getSettingsArticleMutation.isLoading
    const isSuccess = getSettingsArticleMutation.isSuccess
    const error = getSettingsArticleMutation.error

    const unsubscribegetSettingsArticle = () => {
        const unsubscribeMutation = getSettingsArticle({
            data: {
                articleId: undefined
            }
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    // to get the react-player's ref. Will try to use map now.
    const mediaPlayersRef = useRef<{
        key: string,
        player: ReactPlayer | null
    }[]>([])
    const mediaPlayerState = useAppSelector(selectMediaPlayers)

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribegetSettingsArticle()

        let getSettingsArticlePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getSettingsArticle.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getSettingsArticlePromise = getSettingsArticle({
                            authToken: newToken.value,
                            data: {
                                listId: listId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getSettingsArticlePromise && getSettingsArticlePromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        if (getSettingsArticleMutation.error) {
            const message = getErrorText(getSettingsArticleMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSettingsArticleMutation.error])

    /** create fetch data function */

    /** don't render the players unless a mediaPlayerState is set */
    useEffect(() => {
        const filtered = _.filter(data?.content || [],
            (buildingBlock) => {
                return buildingBlock.buildingBlockType === 'audio/content' ||
                buildingBlock.buildingBlockType === 'video/content'
            }
        )

        _.forEach(filtered, (buildingBlock) => {
            dispatch(setMediaPlayer({
                key: buildingBlock.buildingBlockId,
                item: {
                    seeking: false,
                    played: 0,
                    looped: false,
                    playing: false,
                    volume: VOLUME.MAX,
                    muted: false
                }
            }))
        })
    }, [data])

    const BuildingBlocks = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.careplanPatient?.message.loading.detailed_step || ''
                }</span>
            </div>
        )

        const renderContent = (buildingBlock: BuildingBlock, i: number, arr: BuildingBlock[]) => {
            const key = buildingBlock.buildingBlockId
            let result: ReactElement<any, any> = <></>
            let printHr = true

            const contentType = buildingBlock.buildingBlockType

            const inViewEvent: (inView: boolean,
                entry: IntersectionObserverEntry) => void = (inView) => {
                }

            switch (buildingBlock.buildingBlockType) {
                case 'text/plain':
                    result = <span>
                        {_.isString(buildingBlock.buildingBlockValue)
                            ? buildingBlock.buildingBlockValue
                            : ''}
                    </span>
                    break
                case 'text/markdown':{
                    const forMarkdownContent = _.isString(buildingBlock.buildingBlockValue)
                        ? buildingBlock.buildingBlockValue
                            .replace(/==([^=]+)==/g, '<mark>$1</mark>')
                            .replace(/~(\d+)~/g, '<sub>$1</sub>')
                            .replace(/~~([^~]+)~~/g, '<s>$1</s>')
                        : ''

                    const MonitoringProgress = <InView
                        root={rootRef.current}
                        as={'div'}
                        onChange={inViewEvent}
                    >
                        <div className={'markdown'}>
                            {/* use workaround for  == to <mark></mark> */}
                            <ReactMarkdown
                                components={{
                                    // supersub replaces markdown with del tags
                                    // for somereason.
                                    del: (props) => <sub {...props} />,
                                    ul: (props) => {
                                        const modifiedProps = { ...props }
                                        // eslint-disable-next-line react/prop-types
                                        modifiedProps.ordered = props.ordered.toString() as any

                                        if (modifiedProps.className && modifiedProps.className
                                            .includes('contains-task-list')) {
                                            return <ul
                                                {...modifiedProps}
                                                className={[
                                                    'contains-task-list list-unstyled ps-4'
                                                ].join(' ')}
                                            />
                                        } else {
                                            return <ul
                                                {...modifiedProps}

                                            />
                                        }
                                    }
                                }}
                                linkTarget={'_blank'}
                                remarkPlugins={[
                                    remarkBreaks,
                                    remarkGfm,
                                    supersub,
                                    remarkEmoji,
                                    remarkDefinitionList,
                                    remarkHeadingId
                                ]} rehypePlugins={[
                                    rehypeRaw
                                ]}
                            >
                                {`${ forMarkdownContent }`}
                            </ReactMarkdown>
                        </div>
                    </InView>

                    result = MonitoringProgress
                    break
                }
                case 'audio/content':
                case 'video/content': {
                    // Display the media player.

                    // get mediaPlayer state.
                    const playerState = _.find(mediaPlayerState, (obj) => {
                        return obj.key === key
                    })

                    // get mediaPlayer ref.
                    const playerRef = _.find(mediaPlayersRef.current, (obj) => {
                        return obj.key === key
                    })

                    if (playerState) {
                        result = <Player
                            playerState={playerState}
                            type={contentType === 'audio/content'
                                ? 'audio'
                                : 'video'}
                            buildingBlock={buildingBlock}
                            playerRef={playerRef}
                            mapKey={key}
                        />
                    }

                    break
                }

                case 'image/content': {
                    result = <div className={'text-center'}>
                        <ImageLightbox imageUrl={
                            _.isString(buildingBlock.buildingBlockValue)
                                ? buildingBlock.buildingBlockValue
                                : ''
                        } onLoad={() => {

                        }} />
                    </div>
                    break
                }

                // do schedule.
                case 'list/routine': {
                    result = <ListRoutine
                        buildingBlock={buildingBlock}
                    />
                    break
                }

                default: printHr = false
            }

            return <div key={key} className={'mt-3'}>
                {result}
                {/* {printHr && <hr/>} */}
                {printHr && <></>}
            </div>
        }

        const content = _.map(
            _.sortBy(
                data?.content || [], 'buildingBlockOrder'
            ), renderContent
        )

        return (
            isLoading
                ? LoadingContent
                : isSuccess
                    ? content
                    : JSON.stringify(error)
        )
    }, [
        strings,
        data,
        mediaPlayerState
    ])

    /** if image/header is found, replace the image at the back. */
    const HeaderImageMain = useMemo(() => {
        const found = _.find(data?.content, (buildingBlock) => {
            return buildingBlock.buildingBlockType === 'image/header'
        })

        const buildingBlockHeader = (found?.buildingBlockValue
            ? found.buildingBlockValue
            : {
                imageUrl: '',
                xCoord: 0,
                yCoord: 0
            }) as BuildingBlockHeader

        const img = buildingBlockHeader.imageUrl
            ? buildingBlockHeader.imageUrl
            : ''

        return <>
            <HeaderImage
                url={img}
                backgroundPositionX={buildingBlockHeader.xCoord}
                backgroundPositionY={buildingBlockHeader.yCoord}
            >
            </HeaderImage>
            <HeaderGradient />
            <HeaderContent
                className={'p-4'}
            >
                <button
                    type={'button'}
                    className={[
                        'btn btn-round btn-rounded'
                    ].join('')}
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch(back())
                    }}
                >
                    <i
                        className={[
                            'fa-light',
                            'fa-arrow-left'
                        ].join(' ')}
                        aria-hidden={'true'}>
                    </i>
                </button>
            </HeaderContent>
        </>
    }, [data])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        {HeaderImageMain}

        <div className={'main-content'} >
            {/* container with a col width and then set to center */}

            <div className={'content-container'}>
                <div className={'align-items-center justify-content-between meta-info row'}>
                    <div className={'col-auto'}>
                    </div>
                    <div className={'col-auto'}>
                    </div>
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-auto'}>
                        <h1>
                            {data?.title}
                        </h1>
                    </div>
                </div>
                {BuildingBlocks}

            </div>

        </div>
        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        {HeaderImageMain}

        <div className={'main-content'}>

            <div className={'content-container'}>
                <div className={'align-items-center justify-content-between meta-info row'}>
                    <div className={'col-auto'}>
                    </div>
                    <div className={'col-auto'}>
                    </div>
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-auto'}>
                        <h1>
                            {data?.title}
                        </h1>
                    </div>
                </div>
                {BuildingBlocks}

            </div>
        </div>
    </>

    return <div className={'careplan-step-page position-relative'}>
        {/* the container */}

        {
            isMobile ? mobileResult : desktopResult
        }
    </div>
}

export default ContentPage
