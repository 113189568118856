
import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { IDS } from '@profile/constants'

import { smartSearch } from '@app/app.method'
import { DoContactKeys, DoContactValues, GetContactResponse } from '@profile/type'
import { FormikProps } from 'formik'
import _ from 'lodash'

interface ComponentProps {
    doContactFormik: FormikProps<DoContactValues>,
    isOutsideClick: boolean,
    setIsOutsideClick: React.Dispatch<React.SetStateAction<boolean>>,
    getContactResponse: GetContactResponse | undefined,
}

const DoContactInterface = ({
    doContactFormik,
    isOutsideClick,
    setIsOutsideClick,
    getContactResponse
}: ComponentProps) => {
    // const token = useAppSelector(selectToken)
    //
    // const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    // const revalidateToken = useRevalidateToken()
    // const validateAPIPath = useValidateAPIPath()

    const containerRef = useRef<HTMLDivElement | null>(null)
    const [search, setSearch] = useState('')
    // start off with dropdown already open.

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current) {
                // weird behavior but works anyways
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsOutsideClick(false)
                } else {
                    setIsOutsideClick(true)
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [containerRef])

    const renderOption = (obj: GetContactResponse['data']['categories'][0]) => {
        const key = obj.categoryId

        const fieldName: DoContactKeys = 'categoryId'

        const result = <div
            onClick={() => {
                console.log('what was selected: ', obj)
                if (
                    obj.categoryId === doContactFormik.values.categoryId
                ) {
                    // set as unselected
                    doContactFormik.setFieldValue(fieldName, undefined)
                } else {
                // set as selected
                    doContactFormik.setFieldValue(fieldName, obj.categoryId)
                }
            }}
            className={['col-12 option',
                obj.categoryId === doContactFormik.values.categoryId
                    ? 'selected'
                    : ''].join(' ')}
            key={key}
        >
            <span className={'fw-semibold'}>{obj.categoryName}</span>
        </div>

        return result
    }

    const FilteredCategories = (smartSearch(
        (getContactResponse?.data.categories || []),
        [], search
    ) as GetContactResponse['data']['categories'])

    const AvailableCategoryOptions = <div className={'mb-3'}>
        {
            _.map(FilteredCategories,
                (obj) => {
                    return renderOption(obj)
                })
        }
    </div>

    const found = _.find(getContactResponse?.data.categories || [], (obj) => {
        return obj.categoryId === doContactFormik.values.categoryId
    })

    return <div className={[
        'align-items-baseline mt-4 row'
    ].join(' ')}>

        <div className={'form-group  mb-4 position-relative'}>
            <label htmlFor={IDS.DO_CONTACT.CATEGORY_ID}
                className={'form-label'}>{
                    strings.profile?.text.contact_form.category
                }</label>
            <div
                id={IDS.DO_CONTACT.CATEGORY_ID}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOutsideClick(!isOutsideClick)
                }}
                className={[
                    'form-control form-select py-4',
                    doContactFormik.errors.categoryId && 'border-danger'
                ].join(' ')}
            >
                {
                    !found?.categoryName
                        ? <span className={'opacity-50'}>
                            {strings.profile?.text.contact_form.category || ''}
                        </span>
                        : <>
                            <span className={'fw-semibold'}>{found?.categoryName}</span>
                        </>
                }

            </div>

            {/* //chaged from input to div */}

            {/* popup window to get a list of carepath with checkboxes */}

            {isOutsideClick && <div
                ref={(e) => {
                    containerRef.current = e
                }}
                className={[
                    'dropdown-list mt-4 px-5 py-5 w-100',
                    'position-absolute'
                ].join(' ')}>
                {/* search tab and filter icon */}
                <div className={['row justify-content-between mb-4 ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box '}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>

                </div>

                <div className={'btn-group-checkbox-list row row-cols-1'}>
                    {AvailableCategoryOptions}
                </div>

            </div>}
            <div className={'form-text error'}>{
                doContactFormik.errors.categoryId
                    ? doContactFormik.errors.categoryId
                    : null
            }</div>
        </div>
    </div>
}

export default DoContactInterface
