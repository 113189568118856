
import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { GPACareplanStepIdDetails } from '@doc/type'
import { useMemo } from 'react'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const FloatInput = ({
    reasoningObj
}: ComponentProps) => {
    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue
    const strings = useAppSelector(selectStrings)

    const input = useMemo(() => {
        const ranges = questionData?.questionAnswers[0]
        const lower = ranges?.rangeLower || 0
        const upper = ranges?.rangeUpper || 0

        return <div className={'question-float-input'}>
            <input
                className={'form-control form-control-sm'}
                type={'number'}
                value={answerValue}
                onChange={(e) => {
                }}
            />
            <small>
                {`${ strings.reason_with_me
                    ?.text.float_input.range || '' } ${ lower } - ${ upper }`}
            </small>
            <small className={'ps-2'}>{strings.reason_with_me
                ?.text.float_input.disclaimer || ''}</small>
        </div>
    }, [strings])

    return input
}

export default FloatInput
