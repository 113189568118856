import { MODULE_TABLE } from '@app/app.config'
import { MOBILE_RESPONSIVE_LIMIT, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { replace } from '@lagunovsky/redux-react-router'
import { useValidateMFAMutation } from '@login/api'
import {
    IDS,
    MFA_FORMIK_INITIAL_VALUES,
    MFA_VALIDATION_SCHEMA,
    MODULE_VERSION
} from '@login/constants'
import { selectTokenForMFA } from '@login/slices/slice.login'
import { MFAKeys } from '@login/type'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/login/Components'
import { useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import FixedImage from '@app/components/FixedImage'
import { useMediaQuery } from 'react-responsive'

const MFAPage = () => {
    const [validateMFA, validateMFAMutation] = useValidateMFAMutation({
        fixedCacheKey: 'shared-validate-mfa-key'
    })

    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const tokenForMFA = useAppSelector(selectTokenForMFA)

    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: MFA_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MFA_VALIDATION_SCHEMA(
            strings.app?.message?.error.empty || ''
        ),
        onSubmit: async (values) => {
            if (tokenForMFA) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                // proceed with login and no need to setToken
                const isValid = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.login.moduleName,
                    MODULE_TABLE.login.apiPaths.validateMFA.path,
                    true
                )

                if (isValid && newToken.value) {
                    validateMFA({
                        authToken: tokenForMFA,
                        data: {
                            token: values.mfaCode
                        }
                    })
                }
            }
        }
    })

    // const handleKeyDown = (
    //     e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
    //     fieldName?: MFAKeys
    // ) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault()
    //         const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
    //         if (nextInput) {
    //             nextInput.focus()
    //             nextInput.select()
    //         } else {
    //             handleSubmit()
    //         }
    //     }
    // }

    const MFACodeInput = useMemo(() => {
        const fieldName: MFAKeys = 'mfaCode'

        return <div className={'form-group  mb-4'}>
            <label htmlFor={IDS.MFA.MFA_CODE} className={'form-label'}>{
            `${ strings.login?.text?.form.mfaCode || '' } `
            }</label>
            <input type={'text'}
                value={values.mfaCode}
                className={`form-control ${ errors.mfaCode && 'border-danger' }`}
                name={fieldName}
                placeholder={strings.login?.text?.form.mfaCode || ''}
                id={IDS.MFA.MFA_CODE}
                onChange={handleChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') { handleSubmit() }
                }}

                required />
            <div className={'form-text error'}>{
                errors.mfaCode ? errors.mfaCode : null
            }</div>
        </div>
    }, [strings, values.mfaCode, errors.mfaCode])

    useEffect(() => {
        if (validateMFAMutation.data) {
            const data = validateMFAMutation.data
            if (data.status === 'OK') {

                // you're going to make the workflow go to the route you need to go to.
                // that includes the defaultRoute.
            } else if (data.status === 'NOT_OK') {
                if (data.message === 'Signature has expired') {
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.routes.login,
                        true
                    )
                    if (isValid) {
                        dispatch(replace(isValid.route))
                    }
                } else {
                    toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                }
            }
        }
    }, [validateMFAMutation.data, activeModules.id])

    useEffect(() => {
        if (validateMFAMutation.error) {
            const message = getErrorText(validateMFAMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [validateMFAMutation.error])

    const SubmitButton = useMemo(() => {
        const buttonContent = validateMFAMutation.isLoading
            ? (
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{strings.app?.text?.submitting}</span>
                </div>
            )
            : strings.app?.text?.submit

        return (<button type={'submit'}
            disabled={validateMFAMutation.isLoading}
            form={IDS.LOGIN.FORM} className={'btn btn-primary btn-lg w-100'}>
            {buttonContent}
        </button>)
    }, [
        strings.login?.text,
        validateMFAMutation
    ])

    const CancelButton = useMemo(() => {
        return (<button type={'button'}
            onClick={(e) => {
                e.preventDefault()

                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.login.moduleName,
                    MODULE_TABLE.login.routes.logout,
                    true
                )

                if (isValid) {
                    dispatch(replace(isValid.route))
                }
            }}
            className={'btn btn-primary btn-lg w-100'}>
            {strings.app?.text.cancel}
        </button>)
    }, [
        activeModules.arr,
        strings.login?.text
    ])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        {/* put header image here */}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-5'}>

                <div className={'justify-content-center row'}>
                    <div className={'col-10 px-0'}>
                        {/* status card */}
                        <div className={'status-card card border-0'}>
                            <h4>
                                {
                                    validateMFAMutation.data?.status === 'OK' &&
                                        token.details.name
                                        ? <p className={'mt-2'}>{`
                                        ${ strings.login?.message?.hello } ${ token.details.name }
                                    `.trim()}</p>
                                        : ''
                                }
                            </h4>
                        </div>
                    </div>
                </div>
                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                        <p className={'mt-2'}>{
                            strings.login?.message?.mfa?.[0]
                        }</p>

                        <p className={'mt-2'}>{
                            strings.login?.message?.mfa?.[1]
                        }</p>

                        <h1 className={'mb-10'}>{`${ strings?.login?.text
                            ?.title.mfaCode } `}</h1>

                        <form id={IDS.LOGIN.FORM} onSubmit={handleSubmit}>
                            {MFACodeInput}
                        </form>

                    </div>
                </div>
                <div className={'row align-items-center justify-content-center'}>
                    <div className={'col-6 col-md-5 col-lg-5'}>
                        {CancelButton}
                    </div>
                    <div className={'col-6 col-md-5 col-lg-5'}>
                        {SubmitButton}
                    </div>
                </div>

            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        {/* put header image here */}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>
            <div className={'justify-content-center row'}>
                <div className={'col-12'}>
                    {/* status card */}
                    <div className={'status-card card border-0'}>
                        {
                            validateMFAMutation.data?.status === 'OK' &&
                                        token.details.name
                                ? <span className={'fw-semibold mb-2'}>{`
                                        ${ strings.login?.message?.hello } ${ token.details.name }
                                    `.trim()}</span>
                                : ''
                        }
                    </div>
                </div>
            </div>

            <div className={'form-container'}>
                <div >
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                            <p className={'mt-2'}>{
                                strings.login?.message?.mfa?.[0]
                            }</p>

                            <p className={'mt-2'}>{
                                strings.login?.message?.mfa?.[1]
                            }</p>

                            <h1 className={'mb-10'}>{`${ strings?.login?.text
                                ?.title.mfaCode } `}</h1>

                            <form id={IDS.LOGIN.FORM} onSubmit={handleSubmit}>
                                {MFACodeInput}
                            </form>

                        </div>
                    </div>
                </div>
                <div >
                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-12 col-sm-6 col-md-5 col-lg-5'}>
                            {CancelButton}
                        </div>
                        <div className={'col-12 col-sm-6 col-md-5 col-lg-5'}>
                            {SubmitButton}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>

    return <div>
        <div className={'login-page'}>

            {
                isMobile ? mobileResult : desktopResult
            }

        </div>

    </div>
}

export default MFAPage
