import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings, setFirebaseToken } from '@app/slices/slice.app'
import { MODULE_VERSION } from '@registration/constants'
import { useEffect, useMemo } from 'react'
// for react-datepicker locale. requires date-fns.

import { FIREBASE_CONFIG, MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useSetSubscriberIdMutation, useUpdateWorkflowProgressMutation } from '@registration/api'

import {
    selectCurrentWorkflowStep,
    selectWorkflowCount,
    selectWorkflowId
} from '@login/slices/slice.workflow'
import UpdateWorkflowProgressConsumer from '@login/workflow/UpdateWorkflowConsumer'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/registration/Components'

import { useMediaQuery } from 'react-responsive'

import FixedImage from '@app/components/FixedImage'
import _ from 'lodash'

import { MODULE_TABLE } from '@app/app.config'
import { getTokenForReal } from '@app/components/FirebaseController/method'
import { selectToken } from '@app/slices/slice.token'
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import remarkDefinitionList from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'
// Initialize Firebase
// console.log('this is called.')
const app = initializeApp(FIREBASE_CONFIG)

// Initialize Firebase Cloud Messaging and get a reference to the service
// this is asynchronous i think.
const firebaseMessaging = getMessaging(app)

function EnableNotifications () {
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const dispatch = useAppDispatch()
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const workflowId = useAppSelector(selectWorkflowId)
    const workflowCount = useAppSelector(selectWorkflowCount)

    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const [
        updateWorkflowProgress,
        updateWorkflowProgressMutation
    ] = useUpdateWorkflowProgressMutation()

    const [
        // updateWorkflowProgress,
        setSubscriber,
        setSubscriberMutation
    ] = useSetSubscriberIdMutation()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const SubscribeButton = useMemo(() => {
        const buttonContent = setSubscriberMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>

                    </div>
                </div>
            )
        // if it's not the last step, use "next", else "complete"
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            currentWorkflowStep && currentWorkflowStep?.stepData.stepButtonText
                        }
                    </div>

                </div>
            </div>

        return (<button onClick={async () => {
            if (setSubscriberMutation.data?.status !== 'OK') {
                const subscriberId = await getTokenForReal(firebaseMessaging, navigator.userAgent)

                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)

                if (token.valid) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.setSubscriberId.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        if (_.isString(subscriberId)) {
                            setSubscriber({
                                authToken: newToken.value,
                                data: {
                                    subscriberId
                                }
                            }).unwrap().then((o) => {
                                // if ok. set the value in dispatch
                                if (o.status === 'OK') {
                                    dispatch(setFirebaseToken(subscriberId))
                                }
                            })
                        } else {
                            console.error('setting value to -1')

                            setSubscriber({
                                authToken: newToken.value,
                                data: {
                                    subscriberId: '-1'
                                }
                            }).unwrap().then((o) => {
                                // if ok. set the value in dispatch
                                if (o.status === 'OK') {
                                    dispatch(setFirebaseToken('-1'))
                                }
                            })
                        }
                    }
                }
            }
        }}
        className={'btn btn-secondary btn-lg w-100'}
        disabled={setSubscriberMutation.data?.status === 'OK'}
        >
            {buttonContent}
        </button>)
    }, [strings, currentWorkflowStep, workflowCount,
        setSubscriberMutation.isLoading, setSubscriberMutation.data])

    const SubmitButton = useMemo(() => {
        const buttonContent = updateWorkflowProgressMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
        // if it's not the last step, use "next", else "complete"
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            currentWorkflowStep && currentWorkflowStep.stepSequence < workflowCount
                                ? strings.app?.text.next
                                : strings.app?.text.complete
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        return (<button onClick={() => {
            if (setSubscriberMutation.data?.status === 'OK') {
                const call = async () => {
                    if (currentWorkflowStep) {
                        if (token.valid) {
                            const newToken = await revalidateToken({
                                value: token.value,
                                id: token.id
                            }, token.mode)
                            const percentComplete = 100
                            const isValid = validateAPIPath(
                                activeModules.arr,
                                MODULE_TABLE.registration.moduleName,
                                MODULE_TABLE.registration.apiPaths.updateWorkflowProgress.path,
                                true
                            )

                            if (isValid && newToken.value) {
                                updateWorkflowProgress({
                                    authToken: newToken.value,
                                    data: {
                                        stepId: currentWorkflowStep.stepId,
                                        workflowId,
                                        percentComplete
                                    }
                                })
                            }
                        }
                    }
                }
                call()
            }
        }}
        disabled={updateWorkflowProgressMutation
            .isLoading || setSubscriberMutation.data?.status !== 'OK'}
        className={'btn btn-primary btn-lg w-100'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    {buttonContent}
                </div>
            </div>
        </button>)
    }, [strings, currentWorkflowStep, workflowCount,
        updateWorkflowProgressMutation.isLoading, setSubscriberMutation.data])

    const stepSequenceCells = useMemo(() => {
        const items = []

        for (let i = 0; i < workflowCount; i++) {
            items.push(
                <li key={i} className={'page-item'}>
                    <a className={`page-link clickable ${
                    i === ((currentWorkflowStep?.stepSequence || 0) - 1) ? 'active' : ''
                }`} onClick={(e) => {
                        e.preventDefault()
                    }}></a>
                </li>
            )
        }

        return (
            <ul className={'pagination justify-content-center'}>
                {items}
            </ul>
        )
    }, [currentWorkflowStep, workflowCount])

    const forMarkdownContent = _.isString(currentWorkflowStep?.stepData.stepContents)
        ? currentWorkflowStep?.stepData.stepContents
            .replace(/==([^=]+)==/g, '<mark>$1</mark>')
            .replace(/~(\d+)~/g, '<sub>$1</sub>')
            .replace(/~~([^~]+)~~/g, '<s>$1</s>')
        : ''

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        {/* put header image here */}
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-20'}>

                <div className={'justify-content-center row'}>
                    <div className={'col-10 px-0'}>
                        {/* status card */}
                        <div className={'status-card card border-0'}>
                            <h4>
                                <p className={'mt-2'}>
                                    {strings.login?.message.hello}
                                </p>
                            </h4>
                            <span>
                                {strings.registration?.text.title}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                        <h6>{currentWorkflowStep?.stepData.stepTitle}</h6>
                        <div className={'markdown'}>
                            <ReactMarkdown
                                components={{
                                    // supersub replaces markdown with del tags
                                    // for somereason.
                                    del: (props) => <sub {...props} />,
                                    ul: (props) => {
                                        const modifiedProps = { ...props }
                                        // eslint-disable-next-line react/prop-types
                                        modifiedProps.ordered = props.ordered.toString() as any

                                        if (modifiedProps.className && modifiedProps.className
                                            .includes('contains-task-list')) {
                                            return <ul
                                                {...modifiedProps}
                                                className={[
                                                    'contains-task-list list-unstyled ps-4'
                                                ].join(' ')}
                                            />
                                        } else {
                                            return <ul
                                                {...modifiedProps}

                                            />
                                        }
                                    }
                                }}
                                linkTarget={'_blank'}
                                remarkPlugins={[
                                    remarkBreaks,
                                    remarkGfm,
                                    supersub,
                                    remarkEmoji,
                                    remarkDefinitionList,
                                    remarkHeadingId
                                ]} rehypePlugins={[
                                    rehypeRaw
                                ]}
                            >
                                {`${ forMarkdownContent }`}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center mb-5'}>
                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                        {SubscribeButton}
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center mb-5'}>
                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                        {SubmitButton}
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center'}>
                    <div className={'col-auto'}>
                        {stepSequenceCells}
                    </div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>
    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>
            <div className={'justify-content-center row'}>
                <div className={'col-12'}>
                    {/* status card */}
                    <div className={'status-card card border-0'}>
                        <span className={'fw-semibold mb-2'}>
                            {strings.login?.message.hello}
                        </span>
                        <span>
                            {strings.registration?.text.title}
                        </span>
                    </div>
                </div>
            </div>

            <div className={'form-container'}>
                <div >
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                            <h6>{currentWorkflowStep?.stepData.stepTitle}</h6>
                            <div className={'markdown'}>
                                <ReactMarkdown
                                    components={{
                                    // supersub replaces markdown with del tags
                                    // for somereason.
                                        del: (props) => <sub {...props} />,
                                        ul: (props) => {
                                            const modifiedProps = { ...props }
                                            // eslint-disable-next-line react/prop-types
                                            modifiedProps.ordered = props.ordered.toString() as any

                                            if (modifiedProps.className && modifiedProps.className
                                                .includes('contains-task-list')) {
                                                return <ul
                                                    {...modifiedProps}
                                                    className={[
                                                        'contains-task-list list-unstyled ps-4'
                                                    ].join(' ')}
                                                />
                                            } else {
                                                return <ul
                                                    {...modifiedProps}

                                                />
                                            }
                                        }
                                    }}
                                    linkTarget={'_blank'}
                                    remarkPlugins={[
                                        remarkBreaks,
                                        remarkGfm,
                                        supersub,
                                        remarkEmoji,
                                        remarkDefinitionList,
                                        remarkHeadingId
                                    ]} rehypePlugins={[
                                        rehypeRaw
                                    ]}
                                >
                                    {`${ forMarkdownContent }`}
                                </ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div >

                    <div className={'row align-items-center justify-content-center mb-5'}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                            {SubscribeButton}
                        </div>
                    </div>
                    <div className={'row align-items-center justify-content-center mb-5'}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                            {SubmitButton}
                        </div>
                    </div>
                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-auto'}>
                            {stepSequenceCells}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>

    return (<div>
        <div className={'registration-page'}>

            <UpdateWorkflowProgressConsumer
                data={updateWorkflowProgressMutation.data}
                originalArgs={updateWorkflowProgressMutation.originalArgs}
            />

            {
                isMobile ? mobileResult : desktopResult
            }

        </div>
    </div>
    )
}

export default EnableNotifications
