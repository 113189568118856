import { useAppSelector } from '@app/app.hook'
import { selectStylesheets } from '@stylesheet/slice'
import {
    Chart,
    ChartData,
    ChartDataset,
    ChartOptions,
    LegendOptions,
    TitleOptions,
    TooltipModel,
    TooltipOptions
} from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { Context } from 'chartjs-plugin-datalabels'
import { Options } from 'chartjs-plugin-datalabels/types/options'
import { useEffect, useRef } from 'react'

import { selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { ComparisonChartActions, PatientProgressChartDataResponse } from '@doc/type'
import { fromUnixTime } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'
import _ from 'lodash'
import { ADMIN_COLORS as IKH_ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { ADMIN_COLORS as RTW_ADMIN_COLORS } from '@stylesheet/brands/rtw/admin/Colors'

/** two bugs to fix. change the font styles of the ticks.
 * when you hover over the edge data points, the tooltip either gets cropped out
 * or the tooltip keeps line breaking.
 */

/**
 * A simple component that creates and renders a Chart.js bar chart. IS IT?!
 */

type ActiveDatasetIndexType = {
    x: Date;
    y: number;
    extraData?: {
        dataId?: string
    };
  };
type ActiveChartType = Chart<'line', ActiveDatasetIndexType[]>;

interface ComponentProps {
    patientProgressChartData: PatientProgressChartDataResponse[] | undefined
    weekDates: PatientProgressChartDataResponse[]
    comparisonChartDispatch: React.Dispatch<ComparisonChartActions>
}

const RecoveryComparisonChart = ({
    weekDates, comparisonChartDispatch
}:ComponentProps) => {
    // Reference to the canvas element where the chart will be rendered
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    // Store the chart instance in a ref for later access and manipulation
    const chartInstanceRef = useRef<ActiveChartType | null>(null)

    const stylesheets = useAppSelector(selectStylesheets)

    const token = useAppSelector(selectToken)
    const strings = useAppSelector(selectStrings)

    const getOrCreateTooltip: (chart: ActiveChartType) => HTMLDivElement = (
        chart
    ) => {
        let tooltipEl = chart.canvas.parentNode?.querySelector('div')

        let backgroundColor = ''
        let fontColor = ''
        let shadowColor = ''

        // sheet to use.
        let sheetToUse = IKH_ADMIN_COLORS

        if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
            sheetToUse = IKH_ADMIN_COLORS
        } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
            sheetToUse = RTW_ADMIN_COLORS
        }

        backgroundColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.white)
            : (sheetToUse.gray_800)
        fontColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.dark)
            : (sheetToUse.white)
        shadowColor = stylesheets.admin?.theme === 'light'
            ? sheetToUse.shadowColor
            : (sheetToUse.gray_900)

        if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.style.background = backgroundColor
            tooltipEl.style.borderRadius = '18px'
            tooltipEl.style.color = fontColor
            tooltipEl.style.opacity = '1'
            tooltipEl.style.pointerEvents = 'auto'
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.transform = 'translate(-50%, 0)'
            tooltipEl.style.transition = 'all .1s ease'
            tooltipEl.style.zIndex = '2'
            tooltipEl.style.boxShadow = `0px 5px 15px 0px ${ shadowColor }`
            tooltipEl.style.whiteSpace = 'nowrap'

            const table = document.createElement('table')
            table.style.margin = '0px'

            tooltipEl.appendChild(table)

            // console.log('make tooltips listeners')
            // Prevent the tooltip from hiding when mouse is over it
            tooltipEl.addEventListener('mouseenter', () => {
                if (tooltipEl) tooltipEl.style.opacity = '1' // Keep the tooltip visible
            })

            // Hide the tooltip when mouse leaves the tooltip area
            tooltipEl.addEventListener('mouseleave', () => {
                if (tooltipEl) tooltipEl.style.opacity = '0' // Hide the tooltip
            })

            // Hide the tooltip when you click outside the canvas
            document.addEventListener('click', (event) => {
                const clickedElement = event.target as HTMLElement

                // Check if the clicked element is the Chart.js tooltip or its descendant
                const isChartTooltip = clickedElement.id === 'chartjs-tooltip' ||
                clickedElement.closest('#chartjs-tooltip')

                // Check if the click occurred outside the canvas and not on the Chart.js tooltip
                if (!isChartTooltip && !chart?.canvas?.contains(clickedElement)) {
                    // console.log('tooltip hidden by click,')
                    if (tooltipEl) tooltipEl.style.opacity = '0' // Hide the tooltip
                }
            })

            chart.canvas.parentNode?.appendChild(tooltipEl)
        }

        return tooltipEl
    }

    const externalTooltipHandler = (context: {
        chart: ActiveChartType;
        tooltip: TooltipModel<'line'>;
      }) => {
        // Tooltip Element
        const { chart, tooltip } = context
        const tooltipEl = getOrCreateTooltip(chart)

        tooltipEl.id = 'chartjs-tooltip'

        // Hide if no tooltip
        // if (tooltip.opacity === 0) {
        //   tooltipEl.style.opacity = "0";
        //   return;
        // }

        if (tooltip.body) {
            const titleLines = tooltip.title || []
            const bodyLines = tooltip.body.map((b) => b.lines)

            const tableHead = document.createElement('thead')

            titleLines.forEach((title) => {
                const tr = document.createElement('tr')
                tr.style.borderWidth = '0'

                const th = document.createElement('th')
                th.style.borderWidth = '0'
                const text = document.createTextNode(title)

                th.appendChild(text)
                tr.appendChild(th)
                tableHead.appendChild(tr)
            })

            const tableBody = document.createElement('tbody')
            bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i]

                const span = document.createElement('span')
                span.style.background = colors.backgroundColor.toString()
                span.style.borderColor = colors.borderColor.toString()
                span.style.borderWidth = '2px'
                span.style.marginRight = '10px'
                span.style.height = '10px'
                span.style.width = '10px'
                span.style.display = 'inline-block'

                const tr = document.createElement('tr')
                tr.style.backgroundColor = 'inherit'
                tr.style.borderWidth = '0'

                const td = document.createElement('td')
                td.style.borderWidth = '0'

                const text = document.createTextNode(body.join(' '))

                td.appendChild(span)
                td.appendChild(text)
                tr.appendChild(td)
                tableBody.appendChild(tr)
            })

            const tableRoot = tooltipEl?.querySelector('table')

            // Remove old children
            while (tableRoot?.firstChild) {
                tableRoot?.firstChild.remove()
            }

            // Add new children
            tableRoot?.appendChild(tableHead)
            tableRoot?.appendChild(tableBody)
        }

        // now append a text outside the table body called a link
        const existingLink = document.querySelector('a.viewDetails')
        if (existingLink) {
            existingLink.parentNode?.removeChild(existingLink)
        }

        const link = document.createElement('a')
        link.setAttribute('href', '#')
        link.textContent = strings.doc?.text.patient
            .recovery_comparison_chart.tooltip.get_details || ''
        link.className = 'viewDetails'
        link.onclick = function (e) {
            e.preventDefault()

            // console.log(tooltip)

            const activeDatapoint = tooltip.dataPoints[0]

            const data = activeDatapoint?.dataset
                .data[activeDatapoint.dataIndex] as unknown as ActiveDatasetIndexType

            comparisonChartDispatch({
                type: 'SET_DATA_ID',
                value: data.extraData?.dataId || ''
            })
        }

        // Append the link to the body or any other desired element
        tooltipEl.appendChild(link)
        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1'
        tooltipEl.style.left = positionX + tooltip.caretX + 'px'
        // it's blocking the tooltip so -30
        tooltipEl.style.top = positionY + tooltip.caretY - 30 + 'px'
        tooltipEl.style.fontSize = '12px'
        tooltipEl.style.padding =
          tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
    }

    useEffect(() => {
        // Data and options for the chart

        let borderColor = ''
        let pointBorderColor = ''
        let fontColor = ''
        let pointBackgroundColor = {
            done: '',
            notDone: ''
        }
        // const predictionBgColor = ''
        let dataLabelColors = {
            bgColor: {
                default: '',
                important: ''
            },
            fontColor: ''
        }

        // sheet to use.
        let sheetToUse = IKH_ADMIN_COLORS

        if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
            sheetToUse = IKH_ADMIN_COLORS
        } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
            sheetToUse = RTW_ADMIN_COLORS
        }

        borderColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.dark)
            : (sheetToUse.white)
        pointBorderColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.dark)
            : (sheetToUse.white)
        pointBackgroundColor = {
            done: stylesheets.admin?.theme === 'light'
                ? (sheetToUse.dark)
                : (sheetToUse.white),
            notDone: stylesheets.admin?.theme === 'light'
                ? (sheetToUse.white)
                : (sheetToUse.dark)
        }
        fontColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.dark)
            : (sheetToUse.white)
        dataLabelColors = {
            bgColor: {
                important: sheetToUse.red,
                default: sheetToUse.gray_100
            },
            fontColor: sheetToUse.white
        }

        // create two datasets.
        const expectedProgressDataset: ChartDataset<'line', ActiveDatasetIndexType[]> = {
            label: strings.doc?.text.patient.recovery_comparison_chart.dataset.labels.expected,
            data: _.map(weekDates, (o) => {
                return {
                    x: fromUnixTime(_.floor(o.timestamp)),
                    y: o.expectedProgress,
                    extraData: {
                        dataId: o.dataId
                    }
                }
            }),
            borderColor,
            tension: 0.4,
            pointStyle: false,
            borderDash: [6, 6],
            datalabels: {
                display: false
            }
        }

        const actualProgressDataset: ChartDataset<'line', ActiveDatasetIndexType[]> = {
            label: strings.doc?.text.patient.recovery_comparison_chart.dataset.labels.actual || '',
            data: _.map(weekDates, (o) => {
                return {
                    x: fromUnixTime(_.floor(o.timestamp)),
                    y: o.actualProgress,
                    extraData: {
                        dataId: o.dataId
                    }
                }
            }),
            borderColor,
            tension: 0.4,
            pointBackgroundColor: function (context, options) {
                const index = context.dataIndex
                return index > 4 ? pointBackgroundColor.notDone : pointBackgroundColor.done
            },
            pointRadius: 5,
            pointBorderColor,
            pointBorderWidth: 2,
            datalabels: {
                listeners: {
                    click: function (context, event) {
                    }
                },
                display (context) {
                    return false
                },
                formatter (value: ActiveDatasetIndexType, context) {
                    return '!'
                },
                backgroundColor (context) {
                    return dataLabelColors.bgColor.important
                }
            }
        }

        // console.log(expectedProgressDataset, actualProgressDataset)

        const data: ChartData<'line', ActiveDatasetIndexType[], Date> = {
            datasets: [
                expectedProgressDataset,
                actualProgressDataset
            ]
        }

        let locale: Locale = enUS

        if (_.lowerCase(token.details.locale) === 'nl-nl') {
            locale = nl
        } else if (_.lowerCase(token.details.locale) === 'en-us') {
            locale = enUS
        } else {
            locale = enUS
        }

        const legendPlugin: | _DeepPartialObject<LegendOptions<'line'>> | undefined = {
            labels: {
                boxHeight: 5,
                boxWidth: 10,
                color: fontColor,
                padding: 15
            },
            display: false
        }

        const titlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            color: fontColor,
            font: {
                size: 16,
                weight: 'normal' as number | 'normal' | 'bold' | 'lighter' | 'bolder'
            },
            padding: 10,
            display: true

        }

        const subtitlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            font: {
                size: 32
            }
        }

        const tooltipPlugin: | _DeepPartialObject<TooltipOptions<'line'>>| undefined = {
            // ...DEFAULT_CHART_OPTIONS.tooltip,
            // filter: function (tooltipItem) {
            //   return tooltipItem.datasetIndex === 0;
            // },
            // these two must be set like this for custom tooltips to work
            enabled: false,
            external: (context) => {
                return externalTooltipHandler({
                    chart: context.chart as unknown as ActiveChartType,
                    tooltip: context.tooltip
                })
            }
            // callbacks: {
            //   title: function (context) {
            //     return "some title";
            //   },
            //   label: function (context) {
            //     let label = context.dataset.label || "";

            //     if (label) {
            //       label += " some label: ";
            //     }
            //     if (context.parsed.y !== null) {
            //       label += context.parsed.y + "%";
            //     }
            //     return label;
            //   },
            //   labelTextColor: function (context) {
            //     return CHART_COLORS.$ih_app_white;
            //   },
            // },
        }

        const dataLabelsPlugin: _DeepPartialObject<Options> | undefined = {
            backgroundColor: function (ctx: Context) {
                return (ctx.dataset.backgroundColor || dataLabelColors.bgColor.default) as string
            },
            borderRadius: 100,
            color: dataLabelColors.fontColor,
            font: {
                weight: 'bold'
            },
            padding: {
                top: 4,
                right: 8,
                left: 8,
                bottom: 2
            },
            formatter (value: ActiveDatasetIndexType, context) {
                return value.y
            },
            offset: 8,
            align: 'top',
            anchor: 'end'
        }

        const options: ChartOptions<'line'> = {
            responsive: true,
            maintainAspectRatio: false,
            datasets: {
                line: {
                    borderWidth: 2
                }
            },
            plugins: {
                legend: legendPlugin,
                title: titlePlugin,
                subtitle: subtitlePlugin,
                tooltip: tooltipPlugin,
                datalabels: dataLabelsPlugin
            },
            interaction: {
                intersect: false,
                mode: 'index'
            },
            onClick (event, elements, chart) {
                if (elements.length > 0) {
                    const clickedDataPoint = elements[0]

                    const data = chart.data
                        .datasets[clickedDataPoint.datasetIndex]
                        .data[clickedDataPoint.index] as unknown as ActiveDatasetIndexType

                    comparisonChartDispatch({
                        type: 'SET_DATA_ID',
                        value: data.extraData?.dataId || ''
                    })
                }
            },
            scales: {
                x: {
                    adapters: {
                        date: {
                            locale
                        }
                    },
                    display: true,
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            month: 'MMM yyyy'
                        }
                    },
                    grid: {
                        display: false
                    },
                    ticks: {
                        color: fontColor,
                        font: {
                            family: 'Ikh-Admin',
                            weight: 'lighter'

                        }
                    }
                },
                y: {
                    grid: {
                        display: true
                    },
                    ticks: {
                        color: fontColor,
                        font: {
                            family: 'Ikh-Admin',
                            weight: 'lighter'

                        }
                    }
                }
            },
            font: {
                family: 'Ikh-Admin',
                size: 12
            }
        }

        // Create the chart when the component mounts
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')

            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'line',
                    data,
                    options
                })
            }
        }

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy()
            }
        }
    }, [stylesheets, weekDates, token.details.locale])

    return <canvas ref={canvasRef} />
}

export default RecoveryComparisonChart
