/* eslint-disable max-len */
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import Color from 'color'
import styled from '@emotion/styled'
// import _ from 'lodash'

interface HeaderImageProps {
    url:string, backgroundPositionX: number, backgroundPositionY: number
}

export const HeaderImage = styled.div<HeaderImageProps>`
    background-image: ${ (props) => {
    const urlToUse = props.url || '/images_new/header/header_blue.svg'
    return `url("${ urlToUse }")`
} };
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        background-position: ${ (props) => [
    props.backgroundPositionX + 'px',
    props.backgroundPositionY + 'px'
].join(' ') };
    }


    height: 260px;
    width: 100%;
    position: absolute;
    z-index: -1;
`

export const HeaderGradient = styled.div`
    background-image: ${ (props) => {
    let colorStopOne = ''
    let colorStopTwo = ''

    colorStopOne = Color(props.theme.config.endUser?.careplanStep.headerGradientColor).alpha(0.5).rgb().string()
    colorStopTwo = Color(props.theme.config.endUser?.careplanStep.headerGradientColor).alpha(0.4).rgb().string()

    // eslint-disable-next-line max-len
    const gradientPart = `linear-gradient(90deg, ${ colorStopOne } 0%, ${ colorStopTwo } 22.63%, rgba(255, 255, 255, 0) 57.06%)`

    return `${ gradientPart }`
} };
    height: 260px;
    width: 100%;
    position: absolute;
    z-index: -1;
`

export const HeaderContent = styled.div`
    background: transparent;
    height: 260px;
    width: 100%;
    position: absolute;
    z-index: 1;
`

export const FooterButtons = styled.div<{
    width:number,
}>`


    width: ${ (props) => props.width + 'px' };

    box-shadow: 0px 0px 0px 0, 0px -7px 15px ${ (props) => {
    let shadowColor = ''

    shadowColor = props.theme.config.endUser?.careplanStep.footerButtons.boxShadow || ''

    return shadowColor
} };

    background-color: ${ (props) => {
    let bgColor = ''

    bgColor = props.theme.config.endUser?.careplanStep.footerButtons.background || ''

    return bgColor
} };

    @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        padding-block: 0.9rem;
    }

    @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        padding-block: 2rem;

    }

    .complete-buttons {
        .btn {
            width: 200px;
        }
    }

    z-index: 1;

    bottom: 0px;

    @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
        bottom: 60px;
    }
`
