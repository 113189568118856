import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useGPACareplanStepIdDetailsMutation } from '@doc/api'
import { GPACareplanStepIdDetails } from '@doc/type'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { Modal, ModalBody, ModalHeader } from 'reactstrap'
// import _ from 'lodash'
// eslint-disable-next-line max-len
import ReadonlyReasonWithMe from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/Main'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
interface ComponentProps {
    reasoningModal: {
        reasoningSessionId: string;
        reasoningSetId: string;
        addedOn: number;
    } | undefined,
    toggleReasoningModal: React.Dispatch<React.SetStateAction<{
        reasoningSessionId: string;
        reasoningSetId: string;
        addedOn: number;
    } | undefined>>
    userId: string | undefined
    careplanStepId: string | undefined
}
const YesAndNoReasoningDetails = (props: ComponentProps) => {
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const strings = useAppSelector(selectStrings)

    const [currentPage, setCurrentPage] = useState(0)

    const [
        gPACareplanStepIdDetails,
        gPACareplanStepIdDetailsMutation
    ] = useGPACareplanStepIdDetailsMutation()

    const reasoningData = gPACareplanStepIdDetailsMutation.data as
     GPACareplanStepIdDetails['response']['reasoningData']

    const unsubscribeGPACareplanStepIdDetails = () => {
        const unsubscribeMutation = gPACareplanStepIdDetails({
            urlParams: {},
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGPACareplanStepIdDetails()

        const promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let gPACareplanStepIdDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.gPACareplanStepIdDetails.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        const skip = currentPage * (reasoningData
                            ?.data.limit || 1)

                        const totalRecords = (reasoningData
                            ?.data.totalCount[0]?.totalRecords || 0) - 1

                        gPACareplanStepIdDetailsPromise = gPACareplanStepIdDetails({
                            authToken: newToken.value,
                            data: {
                                type: 'reasoningData',
                                userId: props.userId || '',
                                reasoningSessionId: props.reasoningModal?.reasoningSessionId,
                                skip: skip > (reasoningData?.data
                                    .totalCount[0]?.totalRecords || 0)
                                    ? totalRecords
                                    : skip,
                                limit: reasoningData?.data.limit
                            },
                            urlParams: {
                                careplanStepId: props.careplanStepId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            gPACareplanStepIdDetailsPromise && gPACareplanStepIdDetailsPromise.abort()
        }
    }

    useEffect(() => {
        // there can be a case where it can be called more than once.
        // console.log(props.reasoningModal)
        if (props.reasoningModal) {
            return fetchData(token)
        }

        return () => {}
    }, [props.reasoningModal, currentPage])

    useEffect(() => {
        // console.log(reasoningData)
    }, [reasoningData])

    useEffect(() => {
        if (gPACareplanStepIdDetailsMutation.error) {
            const message = getErrorText(gPACareplanStepIdDetailsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [gPACareplanStepIdDetailsMutation.error])

    // ok so you want to display the careplan step the way it is BUT readonly version.
    // best to do it from scratch. start with how to render the data.

    const LoadingReasoning = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    return <Modal
        unmountOnClose={false}
        // overflow-auto necessary for smaller screen sizes. this modal should not be responsive.
        className={'answer-summary-modal overflow-auto'}
        isOpen={props.reasoningModal !== undefined} toggle={() => {
            props.toggleReasoningModal(undefined)
        }}>
        <ModalHeader className={'justify-content-start'} toggle={() => {
            props.toggleReasoningModal(undefined)
        // btn-close me-auto ms-0
        }} close={ <a className={'btn btn-round '}
            onClick={(e) => {
                e.preventDefault()
                props.toggleReasoningModal(undefined)
            }}>
            <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
            </i>
        </a>}
        >{}</ModalHeader>
        <ModalBody className={'p-0'}>
            {
                gPACareplanStepIdDetailsMutation.isLoading
                    ? LoadingReasoning
                    : gPACareplanStepIdDetailsMutation.isSuccess
                        ? <ReadonlyReasonWithMe reasoningData={reasoningData}
                            currentPage={currentPage}
                            setCurrentPageState={setCurrentPage}
                            reasoningModal={props.reasoningModal}
                            userId={props.userId}
                            careplanStepId={props.careplanStepId}
                        />
                        : reasoningData?.message
            }
        </ModalBody>
    </Modal>
}

export default YesAndNoReasoningDetails
