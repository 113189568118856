import { OnboardingState } from '@onboarding/type'
import { createSlice } from '@reduxjs/toolkit'

const initialState: OnboardingState = {}

export const slice = createSlice({
    name: 'onboardingV10',
    initialState,
    reducers: {
    }
})

// eslint-disable-next-line no-empty-pattern
export const {} = slice.actions

export default slice.reducer
