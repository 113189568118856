import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { IDS } from '@doc/constants'

import { smartSearch } from '@app/app.method'
import _ from 'lodash'

import { DepartmentCareFacilitiesAction, DepartmentCareFacilitiesState } from '@doc/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    data: {
        careInstitutionId: string;
        facilityName: string;
    }[]
    patientAddStepTwoFormik: FormikProps<{
        careFacilityId: string;
        departmentId: string;
    }>,
    departmentCareFacilitiesState: DepartmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction: React.Dispatch<DepartmentCareFacilitiesAction>
}

const SelectCareInstitutionDropdown = ({
    data, patientAddStepTwoFormik,
    departmentCareFacilitiesState, dispatchDepartmentCareFacilitiesAction
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const filteredData = smartSearch(
        data || [], [], departmentCareFacilitiesState.main.search
    ) as {
        careInstitutionId: string;
        facilityName: string;
    }[]

    // only happens on MOUNT but will check if if you don't have a selected value.
    useEffect(() => {
        // checks.
        if (
            !(departmentCareFacilitiesState.main.selectedOptions
                .careInstitution.careInstitutionId) &&
            filteredData.length === 1
        ) {
            // dispatch this. wait for the call to finish. and then set.
            dispatchDepartmentCareFacilitiesAction({
                type: 'UPDATE_MAIN',
                payload: {
                    ...departmentCareFacilitiesState.main,
                    selectedOptions: {
                        ...departmentCareFacilitiesState
                            .main.selectedOptions,
                        careInstitution: filteredData[0],
                        // department should be unselected.
                        department: {
                            departmentId: '',
                            departmentName: ''
                        }
                    }
                }
            })
        }
        // departmentId will not be a dependency.
    }, [data])

    const containerRef = useRef<HTMLDivElement | null>(null)
    const [isOutsideClick, setIsOutsideClick] = useState(false)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current) {
                // weird behavior but works anyways
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsOutsideClick(false)
                } else {
                    setIsOutsideClick(true)
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [containerRef])

    return <div className={'form-group'}>
        <div className={'align-items-baseline mt-3 row'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.PATIENT_ADD_MODAL.SELECT.ADD_FACILITY}
                    className={'form-label'}>{
                        strings.doc?.text.patient
                            .add_interface.steps['2'].fields.add_facility
                    }</label>
            </div>
            <div className={'col-sm-9 col-12 position-relative'}>

                <div
                    id={IDS.PATIENT_ADD_MODAL.SELECT.ADD_FACILITY}
                    onClick={(e) => {
                        e.preventDefault()
                        setIsOutsideClick(!isOutsideClick)
                    }}
                    className={[
                        'form-select py-3',
                        patientAddStepTwoFormik.errors.careFacilityId &&
                         'border-danger'
                    ].join(' ')}
                >
                    {
                        !departmentCareFacilitiesState.main.selectedOptions
                            ?.careInstitution.facilityName
                            ? <span className={'opacity-50'}>
                                {strings.app?.text.select}
                            </span>
                            : <>
                                <span>{ departmentCareFacilitiesState.main.selectedOptions
                                    ?.careInstitution.facilityName
                                }</span>
                            </>
                    }

                </div>

                <div className={'form-text error'}>{
                    patientAddStepTwoFormik.errors.careFacilityId
                        ? patientAddStepTwoFormik.errors.careFacilityId
                        : null
                }</div>

                {/* then custom dropdown list */}
                {isOutsideClick && <div
                    ref={(e) => {
                        containerRef.current = e
                    }}
                    style={{ zIndex: 2 }}
                    className={'dropdown-list mt-3 px-5 py-6 position-absolute w-100'}>
                    {/* search tab and filter icon */}
                    <div className={['row justify-content-between mb-4 ',
                        'align-items-center mt-3'].join(' ')}>
                        <div className={'col'}>
                            <div className={'search-box '}>
                                <i className={'fa-light fa-search'}></i>
                                <input type={'text'}
                                    className={'form-control'}
                                    placeholder={strings.app?.text.select}
                                    value={departmentCareFacilitiesState.main.search}
                                    onChange={(e) => {
                                        dispatchDepartmentCareFacilitiesAction({
                                            type: 'UPDATE_MAIN',
                                            payload: {
                                                ...departmentCareFacilitiesState.main,
                                                search: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className={'col-auto pe-0'}>
                            <button
                                className={['btn ',
                                    'rounded-square  me-2'].join(' ')}
                            >
                                <i className={'fa-light fa-filter'}></i>
                            </button>
                        </div>
                    </div>

                    <div className={''}>

                        {
                            _.map(filteredData, (obj) => {
                                const key = obj.careInstitutionId
                                return <div
                                    className={['option',
                                        obj.careInstitutionId === departmentCareFacilitiesState
                                            .main.selectedOptions
                                            ?.careInstitution.careInstitutionId
                                            ? 'selected'
                                            : ''].join(' ')}
                                    key={key}
                                    onClick={() => {
                                        dispatchDepartmentCareFacilitiesAction({
                                            type: 'UPDATE_MAIN',
                                            payload: {
                                                ...departmentCareFacilitiesState.main,
                                                selectedOptions: {
                                                    ...departmentCareFacilitiesState
                                                        .main.selectedOptions,
                                                    careInstitution: obj,
                                                    // department should be unselected.
                                                    department: {
                                                        departmentId: '',
                                                        departmentName: ''
                                                    }
                                                }
                                            }
                                        })

                                        // also, if you selected a value,
                                        // you need to close the dropdown like a normal
                                        // select dropdown would.
                                        setIsOutsideClick(false)
                                    }}
                                >
                                    <span>{obj.facilityName}</span>
                                </div>
                            })
                        }
                    </div>

                </div>}

            </div>

        </div>
    </div>
}

export default SelectCareInstitutionDropdown
