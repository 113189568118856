import { useMemo } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@doc/constants'
import { selectPatientMenu, setPatientMenu } from '@doc/slice'
import { PatientKeys, PatientValues } from '@doc/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    patientAddStepOneFormik: FormikProps<Pick<PatientValues,
    'firstName' | 'lastName' | 'email' | 'patientNumber'>>,
}
// won't put formik here because users can unclick and the data would be
// deinitialized.

const StepOne = ({
    patientAddStepOneFormik
}:ComponentProps) => {
    const dispatch = useAppDispatch()
    const patientMenu = useAppSelector(selectPatientMenu)

    const strings = useAppSelector(selectStrings)

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: PatientKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                // patientAddStepOneFormik.handleSubmit()
                dispatch(setPatientMenu({
                    ...patientMenu,
                    currentStep: patientMenu.currentStep + 1
                }))
            }
        }
    }

    const FirstNameInput = useMemo(() => {
        const fieldName: PatientKeys = 'firstName'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.PATIENT.ADD.FIRST_NAME} className={'form-label'}>{
                        strings.doc?.text.patient.add_interface.steps['1'].fields.first_name
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            patientAddStepOneFormik.errors.firstName && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.doc?.text.patient
                            .add_interface.steps['1'].fields.first_name}
                        value={patientAddStepOneFormik.values.firstName}
                        id={IDS.PATIENT.ADD.FIRST_NAME}
                        onChange={patientAddStepOneFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'lastName')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        patientAddStepOneFormik.errors.firstName
                            ? patientAddStepOneFormik.errors.firstName
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        patientAddStepOneFormik.values.firstName,
        patientAddStepOneFormik.errors.firstName
    ])

    const LastNameInput = useMemo(() => {
        const fieldName: PatientKeys = 'lastName'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.PATIENT.ADD.LAST_NAME} className={'form-label'}>{
                        strings.doc?.text
                            .patient.add_interface.steps['1'].fields.last_name
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            patientAddStepOneFormik.errors.lastName && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.doc?.text
                            .patient.add_interface.steps['1'].fields.last_name}
                        value={patientAddStepOneFormik.values.lastName}
                        id={IDS.PATIENT.ADD.LAST_NAME}
                        onChange={patientAddStepOneFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'email')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        patientAddStepOneFormik.errors.lastName
                            ? patientAddStepOneFormik.errors.lastName
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        patientAddStepOneFormik.values.lastName,
        patientAddStepOneFormik.errors.lastName
    ])

    const EmailInput = useMemo(() => {
        const fieldName: PatientKeys = 'email'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.PATIENT.ADD.EMAIL} className={'form-label'}>{
                        strings.doc?.text.patient.add_interface
                            .steps['1'].fields.email_address
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            patientAddStepOneFormik.errors.email && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.doc?.text.patient
                            .add_interface.steps['1'].fields.email_address}
                        value={patientAddStepOneFormik.values.email}
                        id={IDS.PATIENT.ADD.EMAIL}
                        onChange={patientAddStepOneFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'patientNumber')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        patientAddStepOneFormik.errors.email
                            ? patientAddStepOneFormik.errors.email
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        patientAddStepOneFormik.values.email,
        patientAddStepOneFormik.errors.email
    ])

    const PatientNumberInput = useMemo(() => {
        const fieldName: PatientKeys = 'patientNumber'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.PATIENT.ADD.PATIENT_NUMBER} className={'form-label'}>{
                        strings.doc?.text
                            .patient.add_interface.steps['1'].fields.patient_number
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            patientAddStepOneFormik.errors.patientNumber && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.doc?.text
                            .patient.add_interface.steps['1'].fields.patient_number}
                        value={patientAddStepOneFormik.values.patientNumber}
                        id={IDS.PATIENT.ADD.PATIENT_NUMBER}
                        onChange={patientAddStepOneFormik.handleChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                dispatch(setPatientMenu({
                                    ...patientMenu,
                                    currentStep: patientMenu.currentStep + 1
                                }))
                            }
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        patientAddStepOneFormik.errors.patientNumber
                            ? patientAddStepOneFormik.errors.patientNumber
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        patientAddStepOneFormik.values.patientNumber,
        patientAddStepOneFormik.errors.patientNumber
    ])

    const StepOneContent = <div id={'step-one-content'}>
        <h2 className={'mb-5 fw-semibold '} >{strings.doc?.text
            .patient.add_interface.steps['4'].patient_data}</h2>
        {/* group one */}
        <div className={'mb-6'}>
            {FirstNameInput}
            {LastNameInput}
            {EmailInput}
            {PatientNumberInput}
        </div>
    </div>

    const result = <div>
        {StepOneContent}
    </div>
    return <>{result}</>
}

export default StepOne
