import { MODULE_TABLE } from '@app/app.config'

import _ from 'lodash'

import { SystemModule } from '@login/type'
import React from 'react'
import GettingStarted from '@onboarding/components/GettingStarted'

interface ComponentProps {
    config: SystemModule[]
}
const selectedModule = MODULE_TABLE.onboarding
/** The final array of route configurations with their corresponding components */
const Routes = ({ config }: ComponentProps) => {
    const arr: {
            moduleName: string,
            path: string,
            element: React.ReactNode
        }[] = []

    const found = _.find(config, (o) => {
        return o.moduleName === selectedModule.moduleName
    })

    if (found) {
        _.forEach(_.cloneDeep(found.feRoutes), (o) => {
            let element: React.ReactNode | undefined
            // a new variable named route to pass in so we can cater to routes
            // with parameters on them.
            let routeName = ''
            switch (o.route) {
                case selectedModule.routes.gettingStarted: {
                    element = <GettingStarted />
                    routeName = o.route
                    break
                }

                default:
                    break
            }

            element && arr.push({
                moduleName: found.moduleName,
                path: routeName,
                element
            })
        })
    }

    return arr
}

export default Routes
