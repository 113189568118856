import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import NewDatePicker from '@app/components/NewDatePicker'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useGetPatientChartDataMutation, useGetPatientChartsMutation } from '@doc/api'
import LineChart from '@doc/components/patients/details/charts/LineChart/LineChart'
import HealthRelatedModal from '@doc/components/patients/details/modals/HealthRelatedModal'
import { IDS } from '@doc/constants'
import {
    GraphInfo,
    HealthRelatedReducerActions,
    HealthRelatedReducerState,
    PatientParams
} from '@doc/type'
import {
    FloatingArrow,
    FloatingFocusManager,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
    useHover,
    useId,
    useInteractions,
    useTransitionStyles
} from '@floating-ui/react'
import { getUnixTime, subDays } from 'date-fns'
import produce from 'immer'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const InformationPopover = ({ boundary, text }:
    {boundary: HTMLDivElement | null, text:string | undefined}) => {
    const arrowRef = useRef(null)
    const [visible, setVisible] = useState(false)

    const { refs, floatingStyles, context } = useFloating({
        open: visible,
        placement: 'top',
        onOpenChange: (isOpen, event, reason) => {
            setVisible(isOpen)
            // event && console.log(event)
            // reason && console.log(reason)
        },
        middleware: [
            offset(10),
            flip({
                fallbackAxisSideDirection: 'end',
                boundary: boundary || 'clippingAncestors'
            }),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate

    })
    const { styles } = useTransitionStyles(context)

    const hover = useHover(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover
    ])

    const headingId = useId()

    return <>
        <div className={'icon d-inline-block ps-2'}
            ref={refs.setReference} {...getReferenceProps()}>
            <i className={'fa-light fa-circle-info'}
                aria-hidden={'true'}></i>
        </div>
        { visible && <FloatingFocusManager context={context} modal={false}>
            <div
                className={'floating-ui information'}
                ref={refs.setFloating}
                style={{
                    ...floatingStyles,
                    ...styles
                }}
                aria-labelledby={headingId}
                {...getFloatingProps()}
            >
                <FloatingArrow ref={arrowRef} context={context}
                />
                {

                    <div><span>{
                        text
                    }</span></div>
                }
            </div>

        </FloatingFocusManager>}

    </>
}
interface ComponentProps {
    floatingUiBoundary: HTMLDivElement | null
    level: 'main' | 'primary'
    generalChartInfo: GraphInfo
}

const HealthRelatedChartInterface = (
    { floatingUiBoundary, level, generalChartInfo } : ComponentProps) => {
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)
    const token = useAppSelector(selectToken)

    const { userId } = useParams<PatientParams>()

    const [getPatientChartData, getPatientChartDataMutation] = useGetPatientChartDataMutation()
    const [getPatientChartsPrimary, getPatientChartsPrimaryMutation] = useGetPatientChartsMutation()

    const patientChartData = getPatientChartDataMutation.data
        ?.data

    const [startDate, setStartDate] = useState<Date | null>(subDays(new Date(), 14))
    const [endDate, setEndDate] = useState<Date | null>(new Date())

    const [detailsReducerState, detailsReducerDispatch] =
        useReducer((state: HealthRelatedReducerState, action: HealthRelatedReducerActions) => {
            switch (action.type) {
                case 'SET_PRIMARY_MODAL':
                    return produce(state, draft => {
                        draft.primaryModal = action.value
                    })
                case 'SET_SECONDARY_CONTENT':
                    return produce(state, draft => {
                        draft.secondaryContent = action.value
                    })
                default:
                    return state
            }
        }, {
            primaryModal: undefined,
            secondaryContent: undefined
        })

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                generalChartInfo.graphId &&
                userId && startDate && endDate
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientChartData.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getPatientChartData({
                            authToken: newToken.value,
                            data: {
                                dateFrom: getUnixTime(startDate),
                                dateTo: getUnixTime(endDate),
                                userId,
                                graphId: generalChartInfo.graphId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        if (userId) {
            return fetchData(token)
        } else {
            return () => {}
        }
    }, [token.id, token.valid])

    /** and then a separate call for fetching chart data below. */
    const fetchPrimaryChartData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                detailsReducerState.primaryModal?.extraInfo?.graphId &&
                userId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientCharts.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getPatientChartsPrimary({
                            authToken: newToken.value,
                            data: {
                                userId,
                                parentId: detailsReducerState.primaryModal?.extraInfo?.graphId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        if (getPatientChartsPrimaryMutation.data) {
            const arr = getPatientChartsPrimaryMutation.data.data

            if (!arr.length) {
                toast.error(
                    strings.doc?.message.error?.no_charts || '',
                    { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [getPatientChartsPrimaryMutation.data])

    useEffect(() => {
        if (detailsReducerState.primaryModal) {
            fetchPrimaryChartData(token)
        }
    }, [detailsReducerState.primaryModal])

    useEffect(() => {
        if (getPatientChartDataMutation.error) {
            const message = getErrorText(getPatientChartDataMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientChartDataMutation.error])

    useEffect(() => {
        if (getPatientChartsPrimaryMutation.error) {
            const message = getErrorText(getPatientChartsPrimaryMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientChartsPrimaryMutation.error])

    const chartRangeId = [
        IDS.CHART.RANGE,
        '-',
        generalChartInfo.graphId
    ].join(' ')

    const IconPicker = <NewDatePicker
        id={chartRangeId}
        singleDate={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange={true}
        isRange={(dates) => {
            const [start, end] = dates
            setStartDate(start)
            setEndDate(end)
        }}

    />

    const header = <div className={'row justify-content-between mb-2 align-items-start g-2'}>
        <div className={'col-auto'}>
            <h5 className={'mb-0 fw-semibold d-inline-block'} >{
                patientChartData?.graphName
            }</h5>
            <InformationPopover
                boundary={floatingUiBoundary}
                text={patientChartData?.graphDescription}
            />
        </div>
        <div className={'col-auto'}>
            <div className={'row g-3'}>
                <div className={'col-auto'}>
                    {/* refresh button */}
                    <div className={'icon d-inline-block clickable'} onClick={() => {
                        if (getPatientChartDataMutation.isLoading === false) {
                            fetchData(token)
                        }
                    }}>
                        <i className={'fa-light fa-rotate-right'} aria-hidden={'true'}></i>
                    </div>
                </div>
                <div className={'col-auto'}>
                    {/* date range picker */}
                    {IconPicker}
                </div>
            </div>

        </div>
    </div>

    const chart = <div className={'chart-container'}>
        {
            getPatientChartsPrimaryMutation.isLoading && <div className={'overlay rounded-5'}>
                <div className={'card px-2 py-2'}>{
                    strings.doc?.message.loading?.getting_detailed_chart_info || ''
                }</div>
            </div>
        }
        {
            patientChartData?.graphType === 'line'
                ? <LineChart
                    level={level}
                    detailsReducerState={detailsReducerState}
                    detailsReducerDispatch={detailsReducerDispatch}
                    patientChartData={patientChartData}
                    parentChartInfo={generalChartInfo}
                />
                : ''
        }

    </div>

    const isLoadingContent = <small className={'d-block text-center py-2'}>
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    </small>

    const unmountPrimaryData = () => {
        detailsReducerDispatch({
            type: 'SET_PRIMARY_MODAL',
            value: undefined
        })
        getPatientChartsPrimaryMutation.reset()
        // if primary modal is closed, so should secondary.
        detailsReducerDispatch({
            type: 'SET_SECONDARY_CONTENT',
            value: undefined
        })
    }

    const unmountSecondaryData = () => {
        detailsReducerDispatch({
            type: 'SET_SECONDARY_CONTENT',
            value: undefined
        })
    }

    // if there are no charts to render, don't show this modal.
    const detailedModal = useMemo(() => {
        return detailsReducerState.primaryModal &&
        getPatientChartsPrimaryMutation.data?.data.length && <Modal
            size={'lg'}
            unmountOnClose={false}
            isOpen={detailsReducerState.primaryModal !== null} toggle={() => {
                unmountPrimaryData()
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                unmountPrimaryData()
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    // instead of closing the modal right away, you want to check
                    // if you are NOT at the primary section of the modal.
                    // if so, set the showDetailedInterfaceModal to the main point
                    // otherwise, close.
                    if (detailsReducerState.secondaryContent) {
                        console.log('unmounting secondary data')
                        unmountSecondaryData()
                    } else {
                        console.log('unmounting primary data')
                        unmountPrimaryData()
                    }
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <HealthRelatedModal
                    level={'primary'}
                    detailsReducerState={detailsReducerState}
                    detailsReducerDispatch={detailsReducerDispatch}
                    primaryChartData={getPatientChartsPrimaryMutation.data?.data}
                    dateStates={{
                        startDate,
                        endDate
                    }}
                />
            </ModalBody>
        </Modal>
    }, undefined)

    const content = <>
        <div className={'flex-grow-1'}>{header}</div>
        <div className={'flex-grow-0'}>{chart}</div>
        {detailedModal}
    </>

    return <>
        <div className={'course-interface h-100 d-flex flex-column justify-content-center'}>
            {
                // including loading the primary data. this is when you clicked on a datapoint.
                getPatientChartDataMutation.isLoading
                    ? isLoadingContent
                    : patientChartData
                        ? content
                        : ''
            }
        </div>
    </>
}

export default HealthRelatedChartInterface
