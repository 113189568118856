import { QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'

interface ComponentProps {
    center?: boolean
    answerValue: {
        answers?: any[] | undefined;
    } | undefined
    questionQuestion: {
        questionHeader: string;
        questionAnswers: QuestionAnswer[];
    } | undefined
}

const Scale = ({
    center,
    answerValue,
    questionQuestion
}:ComponentProps) => {
    const arr = _.isArray(answerValue)
        ? answerValue
        : [
            answerValue
        ]

    // map it into acqNames.
    const actualResult: {
        name: string | undefined,
        text: string | undefined
    }[] = _.map(arr, (str) => {
        const found = questionQuestion?.questionAnswers.find((o) => {
            return o.answerValue === str
        })

        if (found) {
            return {
                name: found.answerName,
                // can be empty.
                text: found.answerText
            }
        } else {
            return {
                name: str,
                text: ''
            }
        }
    })

    return <>{_.map(actualResult, (o, index) => {
        const key = `list-li-${ index }`

        const result1 = o.text
            ? <p key={key} className={center ? 'text-center' : ''}>
                {o.name ? <span className={'fw-semibold'}>{o.name}</span> : ''}
                {o.text ? <span className={'fw-light'}>{` (${ o.text }) `}</span> : ''}
            </p>
            : <p key={key} className={center ? 'text-center' : ''}>
                {o.name ? <span className={'fw-light'}>{o.name}</span> : ''}
            </p>

        return result1
    })}
    </>
}

export default Scale
