
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { smartSearch } from '@app/app.method'
import { selectStrings } from '@app/slices/slice.app'
import { OpenListPreviousQuestions } from '@reasonWithMe/components/types/open-list/OpenList'
import { setAnswerValue } from '@reasonWithMe/slice'
import { QuestionAnswer, QuestionInterfaceActions, ReasonWithMeState } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
}

type Label = string | string[]; // Define a union type for the labels

function combineNestedArrays (arr: Label[]): string[][] {
    // Use reduce and flatMap to calculate the cartesian product
    const result = _.reduce(arr, (acc: string[][], curr: Label) => {
        const nextArr = Array.isArray(curr) ? curr : [curr] // Wrap non-array elements
        return _.flatMap(acc, (combination) =>
            nextArr.map((item) => [...combination, item])
        )
    }, [[]]) // Start with an empty array of arrays as the initial value

    return result
}

function processAcqObject (acq: {
    acqName: (string | string[])[];
    acqValue: (string | string[])[];
  }) {
    // Combine acqName and acqValue using the same logic
    const combinedNames = combineNestedArrays(acq.acqName)
    const combinedValues = combineNestedArrays(acq.acqValue)

    // Now you have both combined names and values, you can pair them or process them as needed
    const combinedResult = combinedNames.map((nameCombo, index) => ({
        acqName: nameCombo,
        acqValue: combinedValues[index] || []
    }))

    return combinedResult
}

const MultiList = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const dispatch = useAppDispatch()

    const [search] = useState<string>('')
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const input = useMemo(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', data.question?.questionId
            ].join('')

            const isChecked = _.includes(
                answerValue,
                obj.acqValue
            )

            const isDisabled = _.isArray(answerValue)
                ? data.question?.questionAnswersAllowed
                    ? answerValue.length >= (data.question?.questionAnswersAllowed || 0)
                        ? data.question.questionAnswersAllowed >= 1
                        : false
                    : false
                : false

            const selectionCellOnclick = () => {
                if (_.isArray(answerValue)) {
                    const found = _.find(answerValue, o => {
                        return (
                            o === obj.acqValue
                        )
                    })

                    if (found !== undefined) {
                        /** remove from list. */
                        const arr = _.filter(answerValue, o => {
                            return (
                                o !== found
                            )
                        })

                        /** if the arr has no elements, turn to undefined */
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: arr.length <= 0 ? undefined : arr
                            })
                        } else {
                            dispatch(setAnswerValue(arr.length <= 0 ? undefined : arr))
                        }
                    } else {
                        if (data.question?.questionAnswersAllowed
                            ? data.question.questionAnswersAllowed === 1
                            : false
                        ) {
                            if (componentDispatch !== undefined) {
                                componentDispatch({
                                    type: 'SET_ANSWER_VALUE',
                                    value: [obj.acqValue]
                                })
                            } else {
                                dispatch(
                                    setAnswerValue(
                                        [obj.acqValue]
                                    )
                                )
                            }
                        } else {
                            if (componentDispatch !== undefined) {
                                componentDispatch({
                                    type: 'SET_ANSWER_VALUE',
                                    value: _.concat(answerValue, obj.acqValue)
                                })
                            } else {
                                dispatch(
                                    setAnswerValue(
                                        _.concat(answerValue, obj.acqValue)
                                    )
                                )
                            }
                        }
                    }
                } else {
                    if (obj.acqValue === answerValue) {
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: undefined
                            })
                        } else {
                            dispatch(setAnswerValue(undefined))
                        }
                    } else {
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: [obj.acqValue]
                            })
                        } else {
                            dispatch(setAnswerValue([obj.acqValue]))
                        }
                    }
                }
            }

            // if there are too many choices, use col-6
            return <div className={'col'} key={key}>
                <input type={'checkbox'}
                    className={[
                        'btn-check',
                        isDisabled && !isChecked ? 'disabled' : ''
                    ].join(' ')}
                    autoComplete={'off'}
                    id={key}
                    checked={isChecked}
                    // use class name instead.
                    // disabled={isDisabled && !isChecked}
                    onChange={() => {
                        if (data.question?.questionAnswersAllowed === 1) {
                            selectionCellOnclick()
                        } else {
                            if (!(isDisabled && !isChecked)) {
                                selectionCellOnclick()
                            }
                        }
                    }}
                />
                <label className={'btn btn-dummy'} htmlFor={key}>

                    <div className={'question-checkbox'}>
                        <div className={'card justify-content-center px-3 py-2'}>

                            <div className={'d-flex flex-column'}>
                                <div className={'d-flex align-items-center'}>
                                    <div className={'p text-start'}>
                                        {obj.acqName}
                                    </div>
                                    {
                                        isChecked
                                            ? <div className={'question-checkmark ms-auto'}>
                                                <i className={'fa-light fa-check'}></i>
                                            </div>
                                            : <div className={'question-plussign ms-auto'}>
                                                <i className={'fa-light fa-plus'}></i>
                                            </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>

                </label>
            </div>
        }

        let filteredData: QuestionAnswer[] = []

        // added in 4/12/2024. if there is previous question data AND it's from open list.
        // use this instead.
        const previousQuestionData1 = data.previousQuestionData
        const previousQuestionData2 = data.question?.previousQuestionData

        // console.log(previousQuestionData1,
        //     previousQuestionData2)

        if (previousQuestionData2?.questionType === 'open-list') {
            const useField = previousQuestionData2.useField
            const onlyIf = previousQuestionData2.onlyIf

            // console.log('only if', onlyIf)
            // console.log('found indicator: ', foundIndicator)

            // console.log('useField: ', useField)

            // time to create the arr by the questionAnswer structure.
            const arr: QuestionAnswer[] = []
            let filteredItems : OpenListPreviousQuestions[] = previousQuestionData1
                ?.questionAnswers?.answers || []

            const firstElement: OpenListPreviousQuestions = previousQuestionData1
                ?.questionAnswers
                ?.answers?.[0]

            const foundIndicator = _.find(
                firstElement.inputs,
                (o) => {
                    const options = o.options
                    return options.answerValue === onlyIf?.answerValue
                }
            )

            if (foundIndicator) {
                filteredItems = filteredItems.filter((outer: OpenListPreviousQuestions) => {
                    // get inputs field.
                    const foundAgain = _.find(
                        outer.inputs,
                        (inner) => {
                            const options = inner.options
                            return options.answerValue === onlyIf?.answerValue
                        }
                    )

                    // console.log('id: ', outer.id)
                    console.log('foundAgain: ', foundAgain)

                    if (foundAgain) {
                        // check answertype through if statments.
                        if (foundAgain.options.answerType === 'radio') {
                            return foundAgain.answerValue === onlyIf?.answerIs
                        } else if (foundAgain.options.answerType === 'checkbox') {
                            return foundAgain.answerValue === onlyIf?.answerIs
                        }
                    }

                    return true
                })
            }

            // and then loop over the previous questionData.
            _.forEach(filteredItems, (answer: OpenListPreviousQuestions) => {
                // find input.
                // applicable if useField is defined.
                if (useField) {
                    const foundInput = answer.inputs.find((input) => {
                        return input.options.answerValue === useField?.answerValue
                    })

                    // console.log('foundInput: ', foundInput)

                    if (foundInput) {
                        if (_.isArray(foundInput.answerValue)) {
                            // console.log('pushing array')

                            _.forEach(foundInput.answerValue, value => {
                                arr.push({
                                    acqName: value,
                                    acqValue: value
                                })
                            })
                        } else if (_.isString(foundInput.answerValue)) {
                            // console.log('pushing string')
                            arr.push({
                                acqName: foundInput.answerValue,
                                acqValue: foundInput.answerValue
                            })
                        } else if (_.isBoolean(foundInput.answerValue)) {
                            console.log('cannot add boolean value because of type.')
                        }
                    }
                } else {
                    // if useField is not defined, use all inputs and then inputList in that order.
                    const restInputs = answer.inputs.filter(input => {
                        return input.options.answerType === 'input'
                    })

                    const restInputList = answer.inputs.filter(input => {
                        return input.options.answerType === 'input-list'
                    })

                    // creating empty obj BUT make it an array of strings.
                    // just use join in the end,
                    const acq: {
                        acqName: (string | string[])[]
                        acqValue: (string | string[])[]
                    } = {
                        acqName: [],
                        acqValue: []
                    }

                    // use join instead of concat please.

                    // go over the input boxes first.
                    _.forEach(restInputs, (foundInput) => {
                        if (_.isArray(foundInput.answerValue)) {
                            // console.log('pushing array')

                            // changed here now.
                            // _.forEach(foundInput.answerValue, value => {
                            //     acq.acqName.push(value)
                            //     acq.acqValue.push(value)
                            // })
                            acq.acqName.push(foundInput.answerValue)
                            acq.acqValue.push(foundInput.answerValue)
                        } else if (_.isString(foundInput.answerValue)) {
                            acq.acqName.push(foundInput.answerValue)
                            acq.acqValue.push(foundInput.answerValue)
                        } else if (_.isBoolean(foundInput.answerValue)) {
                            console.log('cannot add boolean value because of type.')
                        }
                    })

                    // then go over the input-lists.
                    _.forEach(restInputList, (foundInput) => {
                        if (_.isArray(foundInput.answerValue)) {
                            // console.log('pushing array')

                            // changed here now.
                            // _.forEach(foundInput.answerValue, value => {
                            //     acq.acqName.push(value)
                            //     acq.acqValue.push(value)
                            // })
                            acq.acqName.push(foundInput.answerValue)
                            acq.acqValue.push(foundInput.answerValue)
                        }
                    })

                    // now we will need to create all possible button combinations.
                    const result = processAcqObject(acq)

                    result.forEach((acq) => {
                        arr.push({
                            acqName: acq.acqName.join(' '),
                            acqValue: acq.acqValue.join(' ')
                        })
                    })
                }
            })

            filteredData = smartSearch(arr, [], search) as QuestionAnswer[]
        } else {
        // filter by acqName.
            filteredData = smartSearch(data.question?.questionAnswers || [],
                [], search) as QuestionAnswer[]
        }

        return <div className={'container-fluid'}>
            <div className={'row row-cols-1 btn-group-checkbox-list mt-3'}
                role={'group'}>
                {
                    _.map(filteredData, renderInputs)
                }
            </div>
        </div>
    }, [questionInterface, search])

    const filters = useMemo(() => {
        const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        const selectionCellOnclick = (str: string) => {
            if (_.isArray(answerValue)) {
                const found = _.find(answerValue, o => {
                    return (
                        o === str
                    )
                })

                if (found !== undefined) {
                    /** remove from list. */
                    const arr = _.filter(answerValue, o => {
                        return (
                            o !== found
                        )
                    })

                    /** if the arr has no elements, turn to undefined */
                    if (componentDispatch !== undefined) {
                        componentDispatch({
                            type: 'SET_ANSWER_VALUE',
                            value: arr.length <= 0 ? undefined : arr
                        })
                    } else {
                        dispatch(setAnswerValue(arr.length <= 0 ? undefined : arr))
                    }
                }
            }
        }

        return _.isArray(answerValue)
            ? <ul className={'tag-list pb-0'}>{
                _.map(answerValue, (o, i) => {
                    const data = questionInterface.currentReasonWithMeResponse.reasoningData
                    const key = [
                        'answer-choice-tag', '-', i, '-', data.question?.questionId
                    ].join('')
                    return <li className={''} key={key} >
                        <div className={'tag'}>
                            <span style={{ verticalAlign: 'inherit' }}>
                                <span style={{ verticalAlign: 'inherit' }}>
                                    {o}
                                </span>
                            </span>
                            <div className={'icon'}onClick={() => {
                                selectionCellOnclick(o)
                            }}>
                                <i className={'fa-light fa-xmark'} aria-hidden={'true'}>

                                </i>
                            </div>
                        </div>
                    </li>
                })}
            </ul>
            : ''
    }, [questionInterface])

    // entire text change message:
    let textAllowedMessage = [
        [
            strings.reason_with_me?.text.list.allowed,
            ':'
        ].join(''),
        questionInterface.currentReasonWithMeResponse
            .reasoningData.question
            ?.questionAnswersAllowed
    ].join(' ')

    if ((questionInterface.currentReasonWithMeResponse
        .reasoningData.question
        ?.questionAnswersAllowed || 0) <= 0) {
        textAllowedMessage = strings.reason_with_me?.text.list.no_restrictions || ''
    }

    return <div className={['question-multi-list', isMobile ? 'px-0' : 'px-4'].join(' ')}>
        {/* place search text box here */}
        {/* <div className={'form-group'}>
            <div className={'form-control-wrapper'}>
                <input type={'search'}
                    className={'form-control form-search'}
                    placeholder={strings.app?.text.search.text}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    id={IDS.LIST.SEARCH} />
                <label
                    className={'form-floaticon'}
                    htmlFor={IDS.LIST.SEARCH}
                >
                    <div
                        className={'icon '}>
                        <i className={'fa-light fa-search'} aria-hidden={'true'}></i>
                    </div>
                </label>
            </div>
        </div> */}
        {/* filters */}
        {filters}
        <p className={'text-center mt-2'}>{textAllowedMessage}</p>
        {input}
    </div>
}

export default MultiList
