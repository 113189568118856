import { Brands, ModuleName, StylesheetState, ThemeName } from '@stylesheet/type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@app/app.store'

const initialState: StylesheetState = {}

export const slice = createSlice({
    name: 'stylesheetV10',
    initialState,
    reducers: {
        setStylesheetModule: (state: StylesheetState, action: PayloadAction<{
            module: ModuleName,
            brand: Brands,
            theme: ThemeName
        }>) => {
            state[action.payload.module] = {
                brand: action.payload.brand,
                theme: action.payload.theme
            }
        }
    }
})

/**
 * actions to export from slice
 */
export const {
    setStylesheetModule
} = slice.actions
export default slice.reducer

export const selectStylesheets = (state: RootState) => state.stylesheetV10
