/* eslint-disable no-unused-vars */

import { selectToken } from '@app/slices/slice.token'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules } from '@app/slices/slice.app'
// import GlobalStyle from '@app/styles/globalStyle'
// import Overlay from '@app/styles/styledOverlay'
import { replace } from '@lagunovsky/redux-react-router'
import { useValidateRouteAll } from '@login/MutationProvider/validateRouteAll'
import React, { useEffect } from 'react'

import { MODULE_TABLE } from '@app/app.config'

/** Decmber 5, 2023 - IHD-125. Have an entirely separate component in charge
 * of CSS with regards to what stylesheets to use and how dependencies like
 * bootstrap are imported.
 */

/** show component when obj.component is undefined. */
export const MissingComponent: React.FC<{}> = () => {
    // go to default route of user. no need to validate.
    const activeModules = useAppSelector(selectActiveModules)
    const token = useAppSelector(selectToken)
    const dispatch = useAppDispatch()

    const validateRouteAll = useValidateRouteAll()

    const goToDefaultRoute = () => {
        // applied to auth users only.
        if (token.mode === 'auth' && activeModules.arr.length) {
            console.log('unknown route detected/ redirecting user to default route')

            let routeToUse = token.details.roid

            if (['/', ''].includes(token.details.roid)) {
                console.warn('Default route changed to login.')
                routeToUse = MODULE_TABLE.login.routes.login
            }

            const defaultRoute = validateRouteAll(activeModules.arr,
                routeToUse, false)
            console.log('route: ', defaultRoute.route)
            if (defaultRoute.route) {
                console.log('Replacing the current entry in the history stack')
                dispatch(replace(routeToUse))
            }
        }
    }

    // redirect to default route.
    useEffect(() => {
        goToDefaultRoute()
    }, [activeModules.id])

    return (
        <div>
            <p>
                {/* {"'Unknown route.'\r"} */}
            </p>
        </div>
    )
}
