import { useEffect, useReducer, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE } from '@app/app.constants'
import { selectActiveModules } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import {
    useGetHCPCardsMutation,
    useGetOverallAbnormalitiesMutation,
    useGetPatientsUpcomingSurgeryMutation,
    useGetTreatedPatientsMutation
} from '@doc/api'
import DashboardResponsiveHeader from '@doc/components/careprofessionals/DashboardResponsiveHeader'
import PatientsDirectory from '@doc/components/careprofessionals/PatientsDirectory'
import {
    AbnormalitiesActions,
    AbnormalitiesState,
    GetHCPCardsResponse,
    GetOverallAbnormalitiesResponse,
    GetPatientsUpcomingSurgeryResponse,
    GetTreatedPatientsResponse,
    TreatedPatientsActions,
    TreatedPatientsState,
    UpcomingSurgeryActions,
    UpcomingSurgeryState
} from '@doc/type'
import { getUnixTime, subDays } from 'date-fns'
import produce from 'immer'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

const CareProfessionalsDashboard = () => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)

    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const [getOverallAbnormalities,
        getOverallAbnormalitiesMutation] = useGetOverallAbnormalitiesMutation()
    const [getPatientsUpcomingSurgery,
        getPatientsUpcomingSurgeryMutation] = useGetPatientsUpcomingSurgeryMutation()
    const [getTreatedPatients,
        getTreatedPatientsMutation] = useGetTreatedPatientsMutation()
    const [getHCPCardsTotal,
        getHCPCardsTotalMutation] = useGetHCPCardsMutation()
    const [getHCPCardsCareplan,
        getHCPCardsCareplanMutation] = useGetHCPCardsMutation()
    const [getHCPCardsProgress,
        getHCPCardsProgressMutation] = useGetHCPCardsMutation()

    const [
        getOverallAbnormalitiesResponse,
        setGetOverallAbnormalitiesResponse
    ] = useState<GetOverallAbnormalitiesResponse>()
    const [abnormalitiesState, abnormalitiesDispatch] = useReducer(
        (state: AbnormalitiesState, action: AbnormalitiesActions) => {
            switch (action.type) {
                case 'SET_SEARCH': {
                    return produce(state, draft => {
                        draft.search = action.value
                    })
                }
                case 'SET_SKIP': {
                    return produce(state, draft => {
                        draft.skip = action.value
                    })
                } case 'SET_DATE_RANGE': {
                    return produce(state, draft => {
                        draft.dateRange[action.value.key] = action.value.dateValue
                    })
                }
                default:
                    return state // Ensure to return the state unchanged by default
            }
        }, {
            search: '',
            skip: 0,
            dateRange: {
                start: getUnixTime(subDays(new Date(), 7)),
                end: getUnixTime(new Date())
            }
        }
    )

    const [
        getPatientsUpcomingSurgeryResponse,
        setGetPatientsUpcomingSurgeryResponse
    ] = useState<GetPatientsUpcomingSurgeryResponse>()
    const [upcomingSurgeryState, upcomingSurgeryDispatch] = useReducer(
        (state: UpcomingSurgeryState, action: UpcomingSurgeryActions) => {
            switch (action.type) {
                case 'SET_SEARCH': {
                    return produce(state, draft => {
                        draft.search = action.value
                    })
                }
                case 'SET_SKIP': {
                    return produce(state, draft => {
                        draft.skip = action.value
                    })
                }
                default:
                    return state // Ensure to return the state unchanged by default
            }
        }, {
            search: '',
            skip: 0
        }
    )

    const [
        getTreatedPatientsResponse,
        setGetTreatedPatientsResponse
    ] = useState<GetTreatedPatientsResponse>()
    const [treatedPatientsState, treatedPatientsDispatch] = useReducer(
        (state: TreatedPatientsState, action: TreatedPatientsActions) => {
            switch (action.type) {
                case 'SET_SEARCH': {
                    return produce(state, draft => {
                        draft.search = action.value
                    })
                }
                case 'SET_SKIP': {
                    return produce(state, draft => {
                        draft.skip = action.value
                    })
                }
                default:
                    return state // Ensure to return the state unchanged by default
            }
        }, {
            search: '',
            skip: 0
        }
    )

    const [
        getHCPCardsTotalResponse,
        setHCPCardsTotalResponse
    ] = useState<GetHCPCardsResponse>()

    const [
        getHCPCardsCareplanResponse,
        setHCPCardsCareplanResponse
    ] = useState<GetHCPCardsResponse>()

    const [
        getHCPCardsProgressResponse,
        setHCPCardsProgressResponse
    ] = useState<GetHCPCardsResponse>()

    useEffect(() => {
        if (getOverallAbnormalitiesMutation.data) {
            setGetOverallAbnormalitiesResponse(getOverallAbnormalitiesMutation.data)
        }
    }, [getOverallAbnormalitiesMutation.data])

    useEffect(() => {
        if (getPatientsUpcomingSurgeryMutation.data) {
            setGetPatientsUpcomingSurgeryResponse(getPatientsUpcomingSurgeryMutation.data)
        }
    }, [getPatientsUpcomingSurgeryMutation.data])

    useEffect(() => {
        if (getTreatedPatientsMutation.data) {
            setGetTreatedPatientsResponse(getTreatedPatientsMutation.data)
        }
    }, [getTreatedPatientsMutation.data])

    useEffect(() => {
        if (getHCPCardsTotalMutation.data) {
            setHCPCardsTotalResponse(getHCPCardsTotalMutation.data)
        }
    }, [getHCPCardsTotalMutation.data])

    useEffect(() => {
        if (getHCPCardsCareplanMutation.data) {
            setHCPCardsCareplanResponse(getHCPCardsCareplanMutation.data)
        }
    }, [getHCPCardsCareplanMutation.data])

    useEffect(() => {
        if (getHCPCardsProgressMutation.data) {
            setHCPCardsProgressResponse(getHCPCardsProgressMutation.data)
        }
    }, [getHCPCardsProgressMutation.data])

    const unsubscribeGetOverallAbnormalities = () => {
        const unsubscribeMutation = getOverallAbnormalities({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPatientsUpcomingSurgery = () => {
        const unsubscribeMutation = getPatientsUpcomingSurgery({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetTreatedPatients = () => {
        const unsubscribeMutation = getTreatedPatients({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeHCPCardsTotal = () => {
        const unsubscribeMutation = getHCPCardsTotal({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeHCPCardsCareplan = () => {
        const unsubscribeMutation = getHCPCardsCareplan({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeHCPCardsProgress = () => {
        const unsubscribeMutation = getHCPCardsProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeHCPCardsTotal()
        unsubscribeHCPCardsCareplan()
        unsubscribeHCPCardsProgress()

        let getHCPCardsTotalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getHCPCardsCareplanPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getHCPCardsProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid4 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getHCPCards.path,
                        true
                    )

                    if (isValid4 && newToken.value) {
                        getHCPCardsTotalPromise = getHCPCardsTotal({
                            authToken: newToken.value,
                            data: {
                                type: 'total'
                            }
                        })

                        getHCPCardsCareplanPromise = getHCPCardsCareplan({
                            authToken: newToken.value,
                            data: {
                                type: 'careplan'
                            }
                        })

                        getHCPCardsProgressPromise = getHCPCardsProgress({
                            authToken: newToken.value,
                            data: {
                                type: 'progress'
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getHCPCardsTotalPromise && getHCPCardsTotalPromise.abort()
            getHCPCardsCareplanPromise && getHCPCardsCareplanPromise.abort()
            getHCPCardsProgressPromise && getHCPCardsProgressPromise.abort()
        }
    }

    const fetchDataAbnormalities = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetOverallAbnormalities()

        let getOverallAbnormalitiesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid1 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getOverallAbnormalities.path,
                        true
                    )

                    const skip = abnormalitiesState.skip * (getOverallAbnormalitiesResponse
                        ?.data.limit || 1)

                    const totalRecords = (getOverallAbnormalitiesResponse
                        ?.data.totalRecords || 0) - 1

                    if (isValid1 && newToken.value) {
                        getOverallAbnormalitiesPromise = getOverallAbnormalities({
                            authToken: newToken.value,
                            data: {
                                search: abnormalitiesState.search,
                                limit: getOverallAbnormalitiesResponse?.data.limit,
                                skip: skip > (getOverallAbnormalitiesResponse?.data
                                    .totalRecords || 0)
                                    ? totalRecords
                                    : skip,
                                startDate: abnormalitiesState.dateRange.start,
                                endDate: abnormalitiesState.dateRange.end
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getOverallAbnormalitiesPromise && getOverallAbnormalitiesPromise.abort()
        }
    }

    // make debounced call to api.
    const debouncedFetchAbnormalities = useDebouncedCallback(() => {
        fetchDataAbnormalities(token)
    }, 1500)

    const fetchDataUpcomingSurgery = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPatientsUpcomingSurgery()

        let getPatientsUpcomingSurgeryPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid1 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientsUpcomingSurgery.path,
                        true
                    )

                    if (isValid1 && newToken.value) {
                        const skip = upcomingSurgeryState.skip * (
                            getPatientsUpcomingSurgeryResponse
                                ?.data.limit || 1)

                        const totalRecords = (getPatientsUpcomingSurgeryResponse
                            ?.data.totalRecords || 0) - 1

                        getPatientsUpcomingSurgeryPromise = getPatientsUpcomingSurgery({
                            authToken: newToken.value,
                            data: {
                                search: upcomingSurgeryState.search,
                                limit: getPatientsUpcomingSurgeryResponse?.data.limit,
                                skip: skip > (getPatientsUpcomingSurgeryResponse?.data
                                    .totalRecords || 0)
                                    ? totalRecords
                                    : skip
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPatientsUpcomingSurgeryPromise && getPatientsUpcomingSurgeryPromise.abort()
        }
    }

    // make debounced call to api.
    const debouncedFetchUpcomingSurgery = useDebouncedCallback(() => {
        fetchDataUpcomingSurgery(token)
    }, 1500)

    const fetchDataTreatedPatients = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetTreatedPatients()

        let getTreatedPatientsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid1 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getTreatedPatients.path,
                        true
                    )

                    if (isValid1 && newToken.value) {
                        const skip = treatedPatientsState.skip * (getTreatedPatientsResponse
                            ?.data.limit || 1)

                        const totalRecords = (getTreatedPatientsResponse
                            ?.data.totalRecords || 0) - 1

                        getTreatedPatientsPromise = getTreatedPatients({
                            authToken: newToken.value,
                            data: {
                                search: treatedPatientsState.search,
                                limit: getTreatedPatientsResponse?.data.limit,
                                skip: skip > (getTreatedPatientsResponse?.data
                                    .totalRecords || 0)
                                    ? totalRecords
                                    : skip
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getTreatedPatientsPromise && getTreatedPatientsPromise.abort()
        }
    }

    // make debounced call to api.
    const debouncedFetchTreatedPatients = useDebouncedCallback(() => {
        fetchDataTreatedPatients(token)
    }, 1500)

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        return debouncedFetchAbnormalities()
    }, [token.id, token.valid, abnormalitiesState.search, abnormalitiesState.skip])

    useEffect(() => {
        return debouncedFetchUpcomingSurgery()
    }, [token.id, token.valid, upcomingSurgeryState.search, upcomingSurgeryState.skip])

    useEffect(() => {
        return debouncedFetchTreatedPatients()
    }, [token.id, token.valid, treatedPatientsState.search, treatedPatientsState.skip])

    const [activeTab, setActiveTab] = useState<string | undefined>('careprofessionals tab')

    const [floatingUiBoundary, setBoundary] = useState<HTMLDivElement | null>(null)

    const navigationContent = <div className={'container-fluid navigation-content pt-12 px-16'} >
        <div className={'h-100 row'}>
            <div className={' col-12 mx-auto'}>
                {
                    activeTab === 'careprofessionals tab' && <PatientsDirectory
                        floatingUiBoundary={floatingUiBoundary}
                        fetchGetPatientData={fetchData}
                        debouncedFetchAbnormalities={debouncedFetchAbnormalities}
                        debouncedFetchUpcomingSurgery={debouncedFetchUpcomingSurgery}
                        debouncedFetchTreatedPatients={debouncedFetchTreatedPatients}
                        getOverallAbnormalitiesResponse={{
                            isLoading: getOverallAbnormalitiesMutation.isLoading,
                            isSuccess: getOverallAbnormalitiesMutation.isSuccess,
                            response: getOverallAbnormalitiesResponse,
                            abnormalitiesState,
                            abnormalitiesDispatch
                        }}
                        getPatientsUpcomingSurgeryResponse={{
                            isLoading: getPatientsUpcomingSurgeryMutation.isLoading,
                            isSuccess: getPatientsUpcomingSurgeryMutation.isSuccess,
                            response: getPatientsUpcomingSurgeryResponse,
                            upcomingSurgeryState,
                            upcomingSurgeryDispatch

                        }}
                        getTreatedPatientsResponse={{
                            isLoading: getTreatedPatientsMutation.isLoading,
                            isSuccess: getTreatedPatientsMutation.isSuccess,
                            response: getTreatedPatientsResponse,
                            treatedPatientsState,
                            treatedPatientsDispatch
                        }}
                        getHCPCardsResponse={{
                            total: {
                                isLoading: getHCPCardsTotalMutation.isLoading,
                                isSuccess: getHCPCardsTotalMutation.isSuccess,
                                response: getHCPCardsTotalResponse
                            },
                            careplan: {
                                isLoading: getHCPCardsCareplanMutation.isLoading,
                                isSuccess: getHCPCardsCareplanMutation.isSuccess,
                                response: getHCPCardsCareplanResponse
                            },
                            progress: {
                                isLoading: getHCPCardsProgressMutation.isLoading,
                                isSuccess: getHCPCardsProgressMutation.isSuccess,
                                response: getHCPCardsProgressResponse
                            }

                        }}
                    />
                }
            </div>
        </div>
    </div>

    return <div className={'careprofessional-dashboard-page'}>
        {/* dashboard header */}
        <DashboardResponsiveHeader
            activeTab={activeTab || ''}
            setActiveTab={setActiveTab}
        />
        {/* use switch case to render what you want */}
        <div className={'main-content'} ref={(e) => {
            setBoundary(e)
        }}>
            {navigationContent}
        </div>
    </div>
}

export default CareProfessionalsDashboard
