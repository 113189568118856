import { useEffect, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { Department, GetCareProfessionalsResponse } from '@fmt/type'
import { FormikProps } from 'formik'
import _ from 'lodash'

import CareProfessionalsInterface from '@fmt/components/facilities/add/steps/StepThree/CareprofessionalsInterface'
import { IDS } from '@fmt/constants'

interface ComponentProps {
    facilityStepThreeAddFormik: FormikProps<{
        linkedCareprofessionals: {
        department: Department,
        collapsible: boolean,
        arr: GetCareProfessionalsResponse['data']['careprofessionals']
    }[]
    }>
}

const StepThree = ({ facilityStepThreeAddFormik }:ComponentProps) => {
    // what you need from top to bottom,
    // one, a dropdown that displays a list of listed departments by the facilityId
    // and a list of UNLINKED departments.
    const strings = useAppSelector(selectStrings)

    // this useState is responsible for updating all dropdowns after a submssion of
    // quickly adding careprofessionals because there was a bug where one of the dropdowns
    // was updated and the rest were not.
    const [
        dropdownsUpdateTrigger,
        setDropdownsUpdateTrigger
    ] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        if (dropdownsUpdateTrigger === true) {
            // set it to false immediately because we don't want to wait
            // for the api calls to finish. we just want the flag to change
            // the fetch part is already dealt with. the trigger to false
            // should happen at this HOC.
            setDropdownsUpdateTrigger(false)
        }
    }, [dropdownsUpdateTrigger])

    const result = _.map(facilityStepThreeAddFormik.values
        .linkedCareprofessionals, (o, index, arr) => {
        const key = o.department.departmentId

        // tim wouldn't like it if the component is not rendered if collapsed.
        const result = <div className={[o.collapsible ? 'd-block' : 'd-none'].join(' ')}>
            <CareProfessionalsInterface
                department={o.department}
                isFromEditPage={false}
                facilityStepThreeAddFormik={facilityStepThreeAddFormik}
                dropdownsUpdateTrigger={dropdownsUpdateTrigger}
                setDropdownsUpdateTrigger={setDropdownsUpdateTrigger}
            />
        </div>

        return <div key={key} className={'col-12 card p-4'}>
            <div className={'row align-items-center justify-content-between'}>
                <div className={'col-auto'}>
                    <label htmlFor={IDS.FACILITY_ADD_MODAL.SELECT.ADD_CAREPROFESSIONAL}
                        className={'fw-semibold'}>{
                            o.department.departmentName
                        }</label>
                </div>
                <div className={'col-auto'}>
                    <button
                        type={'button'}
                        className={[
                            'btn btn-round btn-rounded me-3'
                        ].join('')}
                        onClick={() => {
                            const temp = _.cloneDeep(arr)
                            _.forEach(temp, (o, innerIndex) => {
                                o.collapsible = index === innerIndex
                                    ? !temp[index].collapsible
                                    : false
                            })

                            facilityStepThreeAddFormik.setFieldValue(
                                'linkedCareprofessionals', temp
                            )
                        }}
                    >
                        <i
                            className={[
                                'fa-light',
                                o.collapsible ? 'fa-chevron-up' : ' fa-chevron-down'
                            ].join(' ')}
                            aria-hidden={'true'}>
                        </i>
                    </button>
                </div>
            </div>

            {result}

        </div>
    })

    return <div id={'step-three-content'}>
        <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text.facility.add.careprofessionals}</h2>

        <div className={'row g-3'}>
            {result}
        </div>
    </div>
}

export default StepThree
