import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import {
    selectActiveModules,
    selectShowMessages,
    selectStrings,
    setShowMessages
} from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { getErrorText } from '@app/app.method'
import { useDoLoginMutation, useGetMessagesMutation } from '@login/api'
import { toast } from 'react-toastify'

import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { Message } from '@login/type'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import { remarkDefinitionList } from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'

const MessagesModal = () => {
    const dispatch = useAppDispatch()
    const revalidateToken = useRevalidateToken()
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()

    const [, doLoginMutation] = useDoLoginMutation({
        fixedCacheKey: 'shared-login-key'
    })

    const showMessages = useAppSelector(selectShowMessages)

    const [getMessages, getMessagesMutation] = useGetMessagesMutation()

    const [activeMessage, setActiveMessage] = useState<Message | undefined>({
        messageTitle: '',
        messageContents: ''
    })

    const unsubscribeGetMessages = () => {
        const unsubscribeMutation = getMessages({ } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    useEffect(() => {
        if (getMessagesMutation.error) {
            const message = getErrorText(getMessagesMutation.error)
            if (message.includes('Aborted') === false) {
                console.error(message)
                toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [getMessagesMutation.error])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetMessages()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid && token.mode === 'auth') {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.apiPaths.getMessages.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (foundApiPath && newToken.value) {
                        promise = getMessages({
                            authToken: newToken.value
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            // toast.error(
                            //     `${ MODULE_TABLE.login
                            //         .apiPaths.getMessages.path }:
                            //     ${ strings.login?.message.error.api_path }`.trim(),
                            //     { ...TOASTIFY_DEFAULT_OPTIONS }
                            // )
                        }
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    // make the call the same way we do getMenu
    useEffect(() => {
        if (doLoginMutation.isSuccess === true) {
            return fetchData(token)
        }
        return () => {}
    }, [activeModules.id, doLoginMutation.data])

    useEffect(() => {
        if (getMessagesMutation.data?.data) {
            const arr = getMessagesMutation.data?.data

            if (arr.length) {
                setActiveMessage(arr[0])
                dispatch(setShowMessages(true))
            }
        }
    }, [getMessagesMutation.data?.data])

    const forMarkdownContent = _.isString(activeMessage?.messageContents)
        ? activeMessage?.messageContents
            .replace(/==([^=]+)==/g, '<mark>$1</mark>')
            .replace(/~(\d+)~/g, '<sub>$1</sub>')
            .replace(/~~([^~]+)~~/g, '<s>$1</s>')
        : ''

    const isLast = activeMessage?.messageTitle === getMessagesMutation
        .data?.data[getMessagesMutation
            .data?.data.length - 1]?.messageTitle

    const getNextElement = () => {
        const foundIndex = _.findIndex(getMessagesMutation.data?.data, (o) => {
            return o.messageTitle === activeMessage?.messageTitle
        })

        if (foundIndex > -1) {
            const nextVal = getMessagesMutation.data?.data[foundIndex + 1]

            if (nextVal) { setActiveMessage(nextVal) }
        }
    }

    return <>
        <Modal size={'lg'} unmountOnClose={false} backdrop={'static'}
            onClosed={() => {
            }} centered={true} isOpen={showMessages} >
            <ModalHeader className={'justify-content-between'}>
                <span className={'h5'}>{activeMessage?.messageTitle}</span>
            </ModalHeader>
            <ModalBody style={{
                minHeight: 400
            }}>
                <div className={'markdown'}>
                    {/* use workaround for  == to <mark></mark> */}
                    <ReactMarkdown
                        components={{
                            // supersub replaces markdown with del tags
                            // for somereason.
                            del: (props) => <sub {...props} />,
                            ul: (props) => {
                                const modifiedProps = { ...props }
                                // eslint-disable-next-line react/prop-types
                                modifiedProps.ordered = props.ordered.toString() as any

                                if (modifiedProps.className && modifiedProps.className
                                    .includes('contains-task-list')) {
                                    return <ul
                                        {...modifiedProps}
                                        className={[
                                            'contains-task-list list-unstyled ps-4'
                                        ].join(' ')}
                                    />
                                } else {
                                    return <ul
                                        {...modifiedProps}

                                    />
                                }
                            }
                        }}
                        linkTarget={'_blank'}
                        remarkPlugins={[
                            remarkBreaks,
                            remarkGfm,
                            supersub,
                            remarkEmoji,
                            remarkDefinitionList,
                            remarkHeadingId
                        ]} rehypePlugins={[
                            rehypeRaw
                        ]}
                    >
                        {`${ forMarkdownContent }`}
                    </ReactMarkdown>
                </div>

            </ModalBody>
            <ModalFooter>
                {/* next button on the middle */}
                <div className={'w-100'}>
                    <div className={'row justify-content-end'}>
                        <div className={'col-3'}>
                            <button type={'button'}
                                className={'btn btn-primary w-100'}
                                onClick={() => {
                                    if (isLast) {
                                        dispatch(setShowMessages(false))
                                    } else {
                                        getNextElement()
                                    }
                                }}>{
                                    isLast
                                        ? strings.app?.text.close
                                        : strings.app?.text.next || ''
                                }</button>
                        </div>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    </>
}

export default MessagesModal
