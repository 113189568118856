import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { CheckCareplanStatusResponse } from '@careplan/type'
import { END_USER_COLORS as IKH_ENDUSER_COLORS } from '@stylesheet/brands/ikh/enduser/Colors'
import { selectStylesheets } from '@stylesheet/slice'
import {
    CircularProgressBar,
    CircularProgressProps
} from '@tomickigrzegorz/react-circular-progress-bar'
import { useEffect, useState } from 'react'

interface VideoPlayerProps {
    propPercent: number;
    stageType?: CheckCareplanStatusResponse['data']['stageType']
}

const config: CircularProgressProps = {
    id: 0, // important
    percent: 0
}

const AnimatedLoader = ({ propPercent, stageType }: VideoPlayerProps) => {
    const [update, setUpdate] = useState(config)
    const strings = useAppSelector(selectStrings)
    const stylesheets = useAppSelector(selectStylesheets)
    const [status, setStatus] = useState(strings.reason_with_me
        ?.text.careplan_creation.ready_for_tailor)

    const updateStatus = (currentStatus: VideoPlayerProps['stageType']) => {
        switch (currentStatus) {
            case 'ready_for_tailor':
                setStatus(strings.reason_with_me
                    ?.text.careplan_creation.ready_for_tailor)
                break
            case 'building':
                setStatus(strings.reason_with_me
                    ?.text.careplan_creation.building)
                break
            case 'ready':
                setStatus(strings.reason_with_me
                    ?.text.careplan_creation.success)
                break
            default:
                setStatus(strings.reason_with_me
                    ?.text.careplan_creation.ready_for_tailor)
        }
    }

    useEffect(() => {
        updateStatus(stageType)
    }, [stageType])

    useEffect(() => {
        let backgroundColor = ''
        let fontColor = ''

        const sheetToUse = IKH_ENDUSER_COLORS

        backgroundColor = stylesheets.enduser?.theme === 'light'
            ? (sheetToUse.yellow1)
            : (sheetToUse.yellow1)
        fontColor = stylesheets.enduser?.theme === 'light'
            ? (sheetToUse.darkBlue)
            : (sheetToUse.white)

        setUpdate({
            ...config,
            id: 0, // we indicate which component we want to change
            percent: Math.trunc(propPercent),
            colorSlice: backgroundColor,
            fontColor,
            fontSize: '1.2rem',
            fontWeight: 700
            // animationOff: false
        })
    }, [stylesheets, propPercent])

    const newObject = {
        ...config,
        ...update
    }

    return (
        <div>
            <div className={'justify-content-center row mt-32'}>
                <div className={'col-auto'}>
                    <CircularProgressBar {...newObject} />
                </div>
            </div>
            <h5 className={'text-center mb-32 mt-4'}>{
                status
            }</h5>
        </div>
    )
}

export default AnimatedLoader
