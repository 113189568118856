import { END_USER_COLORS } from '@stylesheet/brands/rtw/enduser/Colors'
import { ProfileConfig } from '@stylesheet/config/endUser/Profile'

export const PROFILE_CONFIG: ProfileConfig = {
    headerGradientColor: END_USER_COLORS.headerGradientColor,
    cardImageWrapper: {
        background: END_USER_COLORS.gray_100
    },
    mainContent: {
        successMessage: END_USER_COLORS.green,
        // Assuming END_USER_COLORS.green is a valid CSS color value
        card: {
            boxShadowColor: END_USER_COLORS.black // Default value, adjust as needed
        },
        dropdownList: {
            searchBox: {
                backgroundColor: END_USER_COLORS.gray_50
            }
        }
    }

}
