import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useCompleteCareplanProgressMutation, useGetPersonalCareplanMutation } from '@careplan/api'
import ActivityDetails from '@careplan/components/careplanStep/ActivityDetails'
// import ActivityDetails from '@careplan/components/careplanStep/ActivityDetails'
import { selectSelectedCareplan, setSelectedCareplan } from '@careplan/slice'
import { CareplanParams, CareplanStepIds } from '@careplan/type'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { TokenData } from '@app/types/type.token'
import ContentDetails from '@careplan/components/careplanStep/ContentDetails'
import LessonDetails from '@careplan/components/careplanStep/LessonDetails'
import ReasoningDetails from '@careplan/components/careplanStep/ReasoningDetails'
// import { back } from '@lagunovsky/redux-react-router'

const Controller = () => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const revalidateToken = useRevalidateToken()
    const selectedCareplan = useAppSelector(selectSelectedCareplan)
    const validateAPIPath = useValidateAPIPath()

    const [careplanStepIds, setCareplanStepIds] = useState<CareplanStepIds>()

    const { personalCareplanId, personalCareplanStepId } = useParams<CareplanParams>()

    const [getSingularCareplan, getSingularCareplanMutation] = useGetPersonalCareplanMutation()
    const [getActualCareplan, getActualCareplanMutation] = useGetPersonalCareplanMutation()

    const [getReasoningId, getReasoningIdMutation] =
    useCompleteCareplanProgressMutation()

    const unsubscribeGetPersonalCareplans = () => {
        const unsubscribeMutation = getActualCareplan({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPersonalCareplan = () => {
        const unsubscribeMutation = getActualCareplan({
            data: {
                personalCarePlanId: undefined,
                personalCareplanStepId: undefined,
                contentId: undefined,
                reasoningSetId: undefined,
                uniqueLessonId: undefined,
                uniqueTherapeuticId: undefined
            }
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const fetchData1 = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPersonalCareplans()

        let getPersonalCareplansPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getPersonalCareplan.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getPersonalCareplansPromise = getSingularCareplan({
                            authToken: newToken.value,
                            data: {
                                personalCarePlanId: personalCareplanId,
                                personalCareplanStepId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPersonalCareplansPromise && getPersonalCareplansPromise.abort()
        }
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPersonalCareplan()

        let getPersonalCareplanPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getReasoningIdPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid && careplanStepIds) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getPersonalCareplan.path,
                        true
                    )

                    if (isValid && newToken.value && newToken.value) {
                        getPersonalCareplanPromise = getActualCareplan({
                            authToken: newToken.value,
                            data: {
                                personalCarePlanId: personalCareplanId,
                                personalCareplanStepId,
                                activityId: careplanStepIds.activityId,
                                contentId: careplanStepIds.contentId,
                                reasoningSetId: careplanStepIds.reasoningSetId,
                                uniqueLessonId: careplanStepIds.uniqueLessonId,
                                uniqueTherapeuticId: careplanStepIds.uniqueTherapeuticId
                            }
                        })
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.completeCareplanProgress
                            .path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getReasoningIdPromise = getReasoningId({
                            authToken: newToken.value,
                            personalCareplanStepId: personalCareplanStepId || '',
                            data: {
                                completeType: 'reasoning'
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPersonalCareplanPromise && getPersonalCareplanPromise.abort()
            getReasoningIdPromise && getReasoningIdPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData1(token)
    }, [token.id, token.valid])

    useEffect(() => {
        const arr = _.isArray(getSingularCareplanMutation.data?.data)
            ? getSingularCareplanMutation.data?.data || []
            : []

        if (arr.length) {
            // find id record using url ids.
            const found = arr.find((o) => {
                return o.carePlanStep?.personalCareplanStepId === personalCareplanStepId
            })

            if (found) {
                setCareplanStepIds(
                    {
                        ...found.carePlanStep?.tailorGroups[0]?.tailorGroup,
                        uniqueLessonId: found.carePlanStep?.lessonStep
                            ?.uniqueLessonId,
                        uniqueTherapeuticId: found.carePlanStep?.lessonStep
                            ?.uniqueTherapeuticId
                    }
                )
                dispatch(setSelectedCareplan(found))
            }
        } else {
            if (getSingularCareplanMutation.data?.status === 'NOT_OK') {
                console.error(getSingularCareplanMutation.data?.message)
                toast.error(getSingularCareplanMutation.data?.message, {
                    ...TOASTIFY_DEFAULT_OPTIONS,
                    autoClose: false
                })
                // dispatch(back())
            }
        }

        return () => {}
    }, [getSingularCareplanMutation.data])

    useEffect(() => {
        if (careplanStepIds) {
            return fetchData(token)
        }
        return () => {}
    }, [careplanStepIds])

    useEffect(() => {
        if (getActualCareplanMutation.error) {
            const message = getErrorText(getActualCareplanMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getActualCareplanMutation.error])

    const content = useMemo(() => {
        // if careplanStepIds.activityId is defined.
        const isActivityDetail = careplanStepIds?.activityId
        const isContentDetail = careplanStepIds?.contentId
        // get it from lessonStep
        const isLessonDetail = careplanStepIds?.uniqueLessonId
        const isReasoningDetail = careplanStepIds?.reasoningSetId

        let content = <div>
            {''}
        </div>

        // console.log(personalCareplanId)
        // console.log(selectedCareplan)

        if (personalCareplanId && selectedCareplan && getReasoningIdMutation.data?.data.setStatus) {
            if (isActivityDetail) {
                content = <ActivityDetails
                    careplan={selectedCareplan}
                    personalCareplanId={personalCareplanId}
                    personalCareplanStepId={personalCareplanStepId}
                    setStatus={getReasoningIdMutation.data?.data.setStatus}
                    reasoningSetId={getReasoningIdMutation.data?.data.reasoningSetId}
                    data={!_.isArray(getActualCareplanMutation.data?.data)
                        ? getActualCareplanMutation.data?.data
                        : {}}
                    isLoading={getActualCareplanMutation.isLoading}
                    isSuccess={getActualCareplanMutation.isSuccess}
                    error={getActualCareplanMutation.error}
                />
            } else if (isContentDetail) {
                content = <ContentDetails
                    careplan={selectedCareplan}
                    personalCareplanId={personalCareplanId}
                    personalCareplanStepId={personalCareplanStepId}
                    setStatus={getReasoningIdMutation.data?.data.setStatus}
                    reasoningSetId={getReasoningIdMutation.data?.data.reasoningSetId}
                    data={!_.isArray(getActualCareplanMutation.data?.data)
                        ? getActualCareplanMutation.data?.data
                        : {}}
                    isLoading={getActualCareplanMutation.isLoading}
                    isSuccess={getActualCareplanMutation.isSuccess}
                    error={getActualCareplanMutation.error}
                />
            } else if (isReasoningDetail) {
                content = <ReasoningDetails
                    careplan={selectedCareplan}
                    personalCareplanId={personalCareplanId}
                    personalCareplanStepId={personalCareplanStepId}
                    setStatus={getReasoningIdMutation.data?.data.setStatus}
                    reasoningSetId={getReasoningIdMutation.data?.data.reasoningSetId}
                    data={!_.isArray(getActualCareplanMutation.data?.data)
                        ? getActualCareplanMutation.data?.data
                        : {}}
                    isLoading={getActualCareplanMutation.isLoading}
                    isSuccess={getActualCareplanMutation.isSuccess}
                    error={getActualCareplanMutation.error}
                />
            } else if (isLessonDetail) {
                content = <LessonDetails
                    careplan={selectedCareplan}
                    personalCareplanId={personalCareplanId}
                    personalCareplanStepId={personalCareplanStepId}
                    setStatus={getReasoningIdMutation.data?.data.setStatus}
                    reasoningSetId={getReasoningIdMutation.data?.data.reasoningSetId}
                    data={!_.isArray(getActualCareplanMutation.data?.data)
                        ? getActualCareplanMutation.data?.data
                        : {}}
                    isLoading={getActualCareplanMutation.isLoading}
                    isSuccess={getActualCareplanMutation.isSuccess}
                    error={getActualCareplanMutation.error}
                />
            }
        }

        return content
    }, [careplanStepIds,
        getActualCareplanMutation.data,
        selectedCareplan,
        personalCareplanId,
        getReasoningIdMutation
    ])

    return <>

        {content}

    </>
}

export default Controller
