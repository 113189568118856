
import { useGetLanguageFileMutation } from '@app/api.app'
import { selectToken } from '@app/slices/slice.token'

import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectStrings, setStrings } from '@app/slices/slice.app'
import { setIsFullscreenOverlay } from '@login/slices/slice.login'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

/** Moving naivgation page to root because the header has to show up everywhere. */

export const TokenHandler = () => {
    const token = useAppSelector(selectToken)
    const strings = useAppSelector(selectStrings)

    const dispatch = useAppDispatch()

    const [getLanguageFile, getLanguageFileMutation] = useGetLanguageFileMutation({
        fixedCacheKey: 'shared-language-key'
    })

    useEffect(() => {
        console.warn(token.id, token.valid)
        /** you want to make the getLanguage api call on the following scenario
         * 1.) when token.details.sub changes
         * You can use the isUninitialized prop and the reset functions on every
         * dependency trigger.
         */
        if (
            token.valid && token.id &&
            token.details.roid !== 'mfa'
        ) {
            getLanguageFile({
                authToken: token.value,
                data: {
                    locale: (token.mode === 'auth' && token.details.locale)
                        ? token.details.locale
                        : navigator.language
                }
            })

            // enable fullscreen
            dispatch(setIsFullscreenOverlay({
                show: true,
                text: strings.app?.text.getting_language_data || ''
            }))
        }
    }, [token.id, token.valid])

    useEffect(() => {
        if (getLanguageFileMutation.data?.data) {
            dispatch(setStrings(getLanguageFileMutation.data.data))
            // no need to set to false because getWorkflow will be the last
            // call to remove the overlay.
            // dispatch(setIsFullscreenOverlay({
            //     show: false,
            //     text: ''
            // }))
        }
    }, [getLanguageFileMutation.data])

    useEffect(() => {
        if (getLanguageFileMutation.error) {
            const message = getErrorText(getLanguageFileMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getLanguageFileMutation.error])

    return <></>
}

export default TokenHandler
