import { CSSProperties } from 'react'

const SentImage = ({ imageUrl, position }: {
    imageUrl: string, position: 'top-left' | 'bottom-middle'
}) => {
    const style: CSSProperties = {
        padding: '1em',
        width: '18em',
        position: 'fixed',
        zIndex: 0,
        // zIndex: 9999,
        ...(position === 'top-left' && {
            top: 0,
            left: 0
        }),
        ...(position === 'bottom-middle' && {
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)'
        })
    }

    return (
        <img src={imageUrl} alt={'Sent'} style={style} />
    )
}

export default SentImage
