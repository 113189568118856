import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { CareProfessionalKeys, CareProfessionalValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    careprofessionalEditFormik: FormikProps<CareProfessionalValues>
    handleKeyDown: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareProfessionalKeys
    ) => void
}

const DeptLocation = ({ readOnly, careprofessionalEditFormik, handleKeyDown }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: CareProfessionalKeys = 'careProfessionalLocation'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.CAREPROFESSIONAL.EDIT.LOCATION} className={'form-label'}>{
                    strings.fmt?.text.department.details.table.location
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly={readOnly}
                    type={'text'}
                    name={fieldName}
                    className={[
                        'form-control-sm',
                        readOnly ? 'form-control-plaintext' : 'form-control',
                        careprofessionalEditFormik.errors
                            .careProfessionalLocation && 'border-danger'
                    ].join(' ')}
                    placeholder={!readOnly
                        ? strings.fmt?.text.department
                            .details.table.location
                        : ''}
                    value={careprofessionalEditFormik.values.careProfessionalLocation}
                    id={IDS.CAREPROFESSIONAL.EDIT.LOCATION}
                    onBlur={careprofessionalEditFormik.handleBlur}
                    onChange={!readOnly ? careprofessionalEditFormik.handleChange : () => {}}
                    onKeyDown={(e) => handleKeyDown(e, 'careProfessionalPhoneNumber')}
                    required
                />
                <div className={'form-text error'}>{
                    careprofessionalEditFormik.errors.careProfessionalLocation
                        ? careprofessionalEditFormik.errors.careProfessionalLocation
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default DeptLocation
