import { GlobalConfig } from '@stylesheet/config/Global'
import Color from 'color'
import { ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'

export const GLOBAL_CONFIG: GlobalConfig = {
    profileImgPlaceholder: {
        background: ADMIN_COLORS.gray_900
    },
    sidebar: {
        main: {
            background: ADMIN_COLORS.darkGrey_1,
            separator: ADMIN_COLORS.gray_200,
            text: ADMIN_COLORS.gray_300,
            primary: ADMIN_COLORS.white,
            primarySelected: ADMIN_COLORS.white,
            navLink: {
                active: {
                    background: ADMIN_COLORS.gray_600
                }
            },
            shadow: ADMIN_COLORS.shadowColor3
        },
        top: {
            background: ADMIN_COLORS.gray_900,
            separator: ADMIN_COLORS.gray_200,
            text: ADMIN_COLORS.gray_300,
            primary: ADMIN_COLORS.dark,
            primarySelected: ADMIN_COLORS.darkSelected,
            shadow: ADMIN_COLORS.shadowColor3
        },
        bottom: {
            background: ADMIN_COLORS.gray_900,
            separator: ADMIN_COLORS.gray_200,
            text: ADMIN_COLORS.white,
            primary: ADMIN_COLORS.dark,
            primarySelected: ADMIN_COLORS.darkSelected,
            shadow: ADMIN_COLORS.shadowColor3
        }
    },
    body: {
        background: ADMIN_COLORS.gray_900,
        fontFamily: 'Ikh-EndUser'
    },
    versionControl: {
        color: ADMIN_COLORS.white
    },
    overlayText: {
        background: `${ Color(ADMIN_COLORS.gray_900).alpha(0.5).rgb().toString() }`,
        color: ADMIN_COLORS.white
    },
    fullscreenOverlay: {
        background: `${ Color(ADMIN_COLORS.black).alpha(0.5).rgb().toString() }`,
        color: ADMIN_COLORS.white
    },
    toastifyToastDefault: {
        background: ADMIN_COLORS.black
    },
    toastifyToastInfo: {
        background: ADMIN_COLORS.gray_200,
        color: ADMIN_COLORS.white
    },
    toastifyToastSuccess: {
        background: ADMIN_COLORS.green,
        color: ADMIN_COLORS.white
    },
    toastifyToastWarning: {
        background: ADMIN_COLORS.yellow1,
        color: ADMIN_COLORS.white
    },
    toastifyToastError: {
        background: ADMIN_COLORS.red,
        color: ADMIN_COLORS.white
    },
    webkitScrollbarThumb: {
        backgroundColor: ADMIN_COLORS.gray_300
    },
    reactDatepicker: {
        borderColor: ADMIN_COLORS.gray_600,
        backgroundColor: ADMIN_COLORS.gray_900,
        textColor: ADMIN_COLORS.white
    },
    formFloaticon: {
        hoverColor: ADMIN_COLORS.blue_200
    },
    datePickerArrows: {
        borderColor: ADMIN_COLORS.blue_200,
        color: ADMIN_COLORS.blue_200
    },
    datePickerText: {
        color: ADMIN_COLORS.white
    },
    datePickerDay: {
        color: ADMIN_COLORS.white
    },
    datePickerSelected: {
        backgroundColor: ADMIN_COLORS.yellow1,
        color: ADMIN_COLORS.white
    },
    datePickerSelectingRange: {
        backgroundColor: `${ Color(ADMIN_COLORS.yellow1).alpha(0.5).rgb().toString() }`,
        color: ADMIN_COLORS.black
    },
    datePickerRange: {
        backgroundColor: ADMIN_COLORS.gray_300,
        color: ADMIN_COLORS.black
    },
    datePickerHover: {
        backgroundColor: ADMIN_COLORS.gray_300
    },
    datePickerSelect: {
        borderRadius: '0.3em',
        backgroundColor: 'transparent',
        color: ADMIN_COLORS.white
    },
    datePickerSelectOption: {
        backgroundColor: ADMIN_COLORS.gray_900,
        color: ADMIN_COLORS.white
    },
    timeListItemHover: {
        backgroundColor: ADMIN_COLORS.gray_300
    },
    dayName: {
        color: ADMIN_COLORS.gray_400
    },
    timeInput: {
        color: ADMIN_COLORS.white
    },
    sliderHandleDragging: {
        borderColor: ADMIN_COLORS.gray_300,
        boxShadow: `0 0 0 0 ${ ADMIN_COLORS.gray_300 }`
    },
    sliderHandleHover: {
        borderColor: ADMIN_COLORS.gray_300
    },
    sliderTrack: {
        backgroundColor: ADMIN_COLORS.yellow1
    },
    sliderHandle: {
        borderColor: `solid 1.5px ${ ADMIN_COLORS.gray_300 }`
    },
    sliderMarkText: {
        color: ADMIN_COLORS.white
    },
    sliderDot: {
        borderColor: ADMIN_COLORS.yellow1
    },
    sliderDisabled: {
        backgroundColor: 'transparent'
    },
    tag: {
        color: ADMIN_COLORS.white,
        backgroundColor: ADMIN_COLORS.gray_900,
        boxShadow: `0px 2px 10px 0px ${ ADMIN_COLORS.shadowColor3 }`
    },
    formSwitch: {
        backgroundColor: ADMIN_COLORS.gray_400,
        borderColor: 'transparent'
    },
    pageLinkSettingsSwitchWrapper: {
        borderTopColor: ADMIN_COLORS.gray_200,
        borderBottomColor: ADMIN_COLORS.gray_200
    },
    passwordStrengthMeter: {
        backgroundColor: ADMIN_COLORS.gray_200,
        activeBackgroundColor: ADMIN_COLORS.yellow1
    },
    dropdownList: {
        borderColor: ADMIN_COLORS.gray_600,
        option: {
            boxShadow: `0px 5px 15px 0px ${ ADMIN_COLORS.shadowColor2 }`,
            selected: {
                background: ADMIN_COLORS.gray_200
            }
        },
        backgroundColor: ADMIN_COLORS.gray_900,
        searchBox: {
            borderColor: ADMIN_COLORS.yellow2,
            boxShadow: `0 0 0 0.25rem ${ ADMIN_COLORS.yellowShadow }`,
            backgroundColor: ADMIN_COLORS.gray_700,
            input: {
                background: 'inherit'
            },
            icon: {
                color: ADMIN_COLORS.white
            }
        }
    },
    modal: {
        bg: ADMIN_COLORS.gray_900,
        color: ADMIN_COLORS.white
    }
}
