import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import _ from 'lodash'
import { ReactNode, useMemo } from 'react'

interface NavigationContainerProps {
  children: ReactNode;
}

const NavigationContainer = ({ children }: NavigationContainerProps) => {
    return <div className={'navigation-container'}>{children}</div>
}

interface NavigationProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Navigation = ({ activeTab, setActiveTab }: NavigationProps) => {
    const strings = useAppSelector(selectStrings)

    const tabs = [
        strings.doc?.text.careprofessionals.dashboard_navigation.careprofessionals
        // 'management tab',
        // 'director tab'
    ]

    const NavigationTabs = useMemo(() => {
        return _.map(tabs, (o, key) => {
            const isActive = activeTab === o

            return (
                <div key={key} className={`navigation-tab ${ isActive ? 'active-tab' : '' }`}
                    onClick={() => setActiveTab(o)}>
                    {o}
                </div>
            )
        })
    }, undefined)

    return (
        <NavigationContainer>
            {NavigationTabs}
        </NavigationContainer>
    )
}

export default Navigation
