import { GlobalConfig } from '@stylesheet/config/Global'
import Color from 'color'
import { END_USER_COLORS } from '@stylesheet/brands/rtw/enduser/Colors'

export const GLOBAL_CONFIG: GlobalConfig = {
    profileImgPlaceholder: {
        background: END_USER_COLORS.gray_200
    },
    sidebar: {
        main: {
            background: END_USER_COLORS.white,
            separator: END_USER_COLORS.gray_200,
            text: END_USER_COLORS.gray_300,
            primary: END_USER_COLORS.darkBlue,
            primarySelected: END_USER_COLORS.darkBlueSelected,
            navLink: {
                active: {
                    background: END_USER_COLORS.gray_200
                }
            },
            shadow: END_USER_COLORS.shadowColor3
        },
        top: {
            background: END_USER_COLORS.white,
            separator: END_USER_COLORS.gray_200,
            text: END_USER_COLORS.gray_300,
            primary: END_USER_COLORS.darkBlue,
            primarySelected: END_USER_COLORS.darkBlueSelected,
            shadow: END_USER_COLORS.shadowColor3
        },
        bottom: {
            background: END_USER_COLORS.white,
            separator: END_USER_COLORS.gray_200,
            text: END_USER_COLORS.gray_300,
            primary: END_USER_COLORS.darkBlue,
            primarySelected: END_USER_COLORS.darkBlueSelected,
            shadow: END_USER_COLORS.shadowColor3
        }
    },
    body: {
        background: END_USER_COLORS.white,
        fontFamily: 'Ikh-EndUser'
    },
    versionControl: {
        color: END_USER_COLORS.darkBlue
    },
    overlayText: {
        background: `${ Color(END_USER_COLORS.gray_500).alpha(0.5).rgb().toString() }`,
        color: END_USER_COLORS.darkBlue
    },
    fullscreenOverlay: {
        background: `${ Color(END_USER_COLORS.black).alpha(0.3).rgb().toString() }`,
        color: END_USER_COLORS.white
    },
    toastifyToastDefault: {
        background: END_USER_COLORS.black
    },
    toastifyToastInfo: {
        background: END_USER_COLORS.gray_200,
        color: END_USER_COLORS.white
    },
    toastifyToastSuccess: {
        background: END_USER_COLORS.green,
        color: END_USER_COLORS.white
    },
    toastifyToastWarning: {
        background: END_USER_COLORS.yellow1,
        color: END_USER_COLORS.white
    },
    toastifyToastError: {
        background: END_USER_COLORS.red,
        color: END_USER_COLORS.white
    },
    webkitScrollbarThumb: {
        backgroundColor: END_USER_COLORS.gray_300
    },
    reactDatepicker: {
        borderColor: END_USER_COLORS.gray_200,
        backgroundColor: END_USER_COLORS.white,
        textColor: END_USER_COLORS.black
    },
    formFloaticon: {
        hoverColor: END_USER_COLORS.blue_200
    },
    datePickerArrows: {
        borderColor: END_USER_COLORS.blue_200,
        color: END_USER_COLORS.blue_200
    },
    datePickerText: {
        color: END_USER_COLORS.black
    },
    datePickerDay: {
        color: END_USER_COLORS.black
    },
    datePickerSelected: {
        backgroundColor: END_USER_COLORS.yellow1,
        color: END_USER_COLORS.white
    },
    datePickerSelectingRange: {
        backgroundColor: `${ Color(END_USER_COLORS.yellow1).alpha(0.5).rgb().toString() }`,
        color: END_USER_COLORS.black
    },
    datePickerRange: {
        backgroundColor: END_USER_COLORS.gray_300,
        color: END_USER_COLORS.black
    },
    datePickerHover: {
        backgroundColor: END_USER_COLORS.gray_300
    },
    datePickerSelect: {
        borderRadius: '0.3em',
        backgroundColor: 'transparent',
        color: END_USER_COLORS.black
    },
    datePickerSelectOption: {
        backgroundColor: END_USER_COLORS.gray_300,
        color: END_USER_COLORS.black
    },
    timeListItemHover: {
        backgroundColor: END_USER_COLORS.gray_300
    },
    dayName: {
        color: END_USER_COLORS.gray_400
    },
    timeInput: {
        color: END_USER_COLORS.black
    },
    sliderHandleDragging: {
        borderColor: END_USER_COLORS.gray_300,
        boxShadow: `0 0 0 0 ${ END_USER_COLORS.gray_300 }`
    },
    sliderHandleHover: {
        borderColor: END_USER_COLORS.gray_300
    },
    sliderTrack: {
        backgroundColor: END_USER_COLORS.yellow1
    },
    sliderHandle: {
        borderColor: `solid 1.5px ${ END_USER_COLORS.gray_300 }`
    },
    sliderMarkText: {
        color: END_USER_COLORS.black
    },
    sliderDot: {
        borderColor: END_USER_COLORS.yellow1
    },
    sliderDisabled: {
        backgroundColor: 'transparent'
    },
    tag: {
        color: END_USER_COLORS.black,
        backgroundColor: END_USER_COLORS.white,
        boxShadow: `0px 2px 10px 0px ${ END_USER_COLORS.shadowColor3 }`
    },
    formSwitch: {
        backgroundColor: END_USER_COLORS.gray_400,
        borderColor: 'transparent'
    },
    pageLinkSettingsSwitchWrapper: {
        borderTopColor: END_USER_COLORS.gray_200,
        borderBottomColor: END_USER_COLORS.gray_200
    },
    passwordStrengthMeter: {
        backgroundColor: END_USER_COLORS.gray_200,
        activeBackgroundColor: END_USER_COLORS.yellow1
    },
    dropdownList: {
        borderColor: END_USER_COLORS.gray_200,
        option: {
            boxShadow: `0px 5px 15px 0px ${ END_USER_COLORS.shadowColor2 }`,
            selected: {
                background: END_USER_COLORS.gray_200
            }
        },
        backgroundColor: END_USER_COLORS.white,
        searchBox: {
            borderColor: END_USER_COLORS.yellow2,
            boxShadow: `0 0 0 0.25rem ${ END_USER_COLORS.yellowShadow }`,
            backgroundColor: END_USER_COLORS.white,
            input: {
                background: 'inherit'
            },
            icon: {
                color: END_USER_COLORS.black
            }
        }
    },
    modal: {
        bg: END_USER_COLORS.white,
        color: END_USER_COLORS.black
    }
}
