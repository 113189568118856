import { MODULE_TABLE } from '@app/app.config'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
    PublicClientApplication
} from '@azure/msal-browser'
import { useDoLoginMutation, useGetTokenMutation, useTokenValidMutation } from '@login/api'
import { MSAL_CONFIG } from '@login/constants'

import {
    AuthenticatedTemplate,
    MsalProvider,
    UnauthenticatedTemplate,
    useMsal
} from '@azure/msal-react'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useEffect } from 'react'

const pca = new PublicClientApplication(MSAL_CONFIG)

const AlreadyLoggedInButton = () => {
    const { instance, accounts, inProgress } = useMsal()
    const [doLogin] = useDoLoginMutation({
        fixedCacheKey: 'shared-login-key'
    })
    const [tokenValid] = useTokenValidMutation()
    const [doGetToken] = useGetTokenMutation()
    const token = useAppSelector(selectToken)
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const validateAPIPath = useValidateAPIPath()

    useEffect(() => {
        if (inProgress === InteractionStatus.None && accounts.length > 0) {
            const tokenRequest = {
                account: accounts[0],
                // This is an example - Select account based on your app's requirements
                scopes: ['User.Read']
            }

            // Acquire an access token
            instance.acquireTokenSilent(tokenRequest).then(async (response) => {
                // Call your API with the
                // access token and return the data you need to save in state
                console.log('doing token validation before doLogin on form')
                const revalidation = await tokenValid({ token: token.value })
                    .unwrap()

                let activeToken = token.value
                if (revalidation.status === 'OK') {
                    activeToken = revalidation.token
                } else if (revalidation.status === 'NOT_OK') {
                    // get a new token again from guest and proceed.
                    const guestRetrieval = await doGetToken({
                        data: { locale: navigator.language }
                    }).unwrap()

                    if (guestRetrieval.status === 'OK') {
                        activeToken = guestRetrieval.token
                    }
                }

                // console.log('token string after simple revalidation is: ', activeToken)
                if (activeToken) {
                    // proceed with login and no need to setToken
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.apiPaths.doLogin.path,
                        true
                    )

                    if (isValid) {
                        doLogin({
                            authToken: token.value,
                            id_token: response.idToken
                        })
                    }
                }
            }).catch(async (e) => {
                // Catch interaction_required errors and call interactive method to resolve
                if (e instanceof InteractionRequiredAuthError) {
                    await instance.acquireTokenRedirect(tokenRequest)
                }

                throw e
            })
        }
    }, [inProgress, accounts, instance])

    return <button className={'btn btn-primary sso-microsoft btn-lg w-100 disabled'}>
        <div className={'align-items-center justify-content-center gy-2 gx-1 row'}>
            <div className={'col-auto'}>
                <i className={'fa-2xl fa-brands fa-windows'}
                    aria-hidden={'true'}>
                </i>
            </div>
            <div className={'col-auto'}>
                <span className={'ms-2'}>{strings.login?.message.logged_in_already}</span>
            </div>
        </div>
    </button>
}

const MicrosoftButton = () => {
    const { instance } = useMsal()
    const handleLogin = () => {
        instance.loginPopup()
    }
    const strings = useAppSelector(selectStrings)

    return <button className={'btn btn-primary sso-microsoft btn-lg w-100'}
        onClick={handleLogin}>
        <div className={'align-items-center justify-content-center gy-2 gx-1 row'}>
            <div className={'col-auto'}>
                <i className={'fa-2xl fa-brands fa-windows'}
                    aria-hidden={'true'}>
                </i>
            </div>
            <div className={'col-auto'}>
                <span className={'ms-2'}>{strings.login?.text.form.sso?.microsoft}</span>
            </div>
        </div>
    </button>
}

const SSOLayout = () => {
    return (<MsalProvider instance={pca}>
        <AuthenticatedTemplate>
            {/* split into 2 buttons per row */}
            <div className={'row row-cols-2 g-3'}>
                <div className={'col'}><AlreadyLoggedInButton/></div>
            </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            {/* split into 2 buttons per row */}
            <div className={'row row-cols-2 g-3'}>
                <div className={'col'}><MicrosoftButton/></div>
            </div>
        </UnauthenticatedTemplate>
    </MsalProvider>)
}

export default SSOLayout
