
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppDispatch } from '@app/app.hook'
import { setAnswerValue } from '@reasonWithMe/slice'
import { QuestionAnswer, QuestionInterfaceActions, ReasonWithMeState } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
}

const Toggle = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const dispatch = useAppDispatch()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const input = useMemo(() => {
        /** how should the input be rendered */
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        /**
         * render a list of checkboxes BUT disable the unchecked boxes
         * once the value of questionAnswersAllowed has reached.
         * expected answers are the following:
         * - if questionAnswersAllowed === 1, answerValue should be a string.
         * - if questionAnswersAllowed > 1, should be [string1, string2]
         */

        // console.log(`answers so far: ${ answerValue }`)

        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', data.question?.questionId
            ].join('')

            const selectionCellOnclick = (i: number, checked: boolean) => {
                // get answerValue
                const toModify = _.cloneDeep(answerValue)
                toModify[i] = checked

                // then update answerValue
                if (componentDispatch !== undefined) {
                    componentDispatch({
                        type: 'SET_ANSWER_VALUE',
                        value: toModify
                    })
                } else {
                    dispatch(setAnswerValue(toModify))
                }
            }

            // if there are too many choices, use col-6
            return <div className={'col'} key={key}>
                <div className={'align-items-center row'}>
                    <div className={'form-check form-switch settings-switch col-auto'}>
                        <input className={'form-check-input'}
                            type={'checkbox'} role={'switch'}
                            checked={answerValue?.[i] || false}
                            onChange={() => {
                                if (answerValue?.[i] !== undefined) {
                                    selectionCellOnclick(i, !answerValue[i])
                                }
                            }}
                        />
                    </div>
                    <div className={'title col'}>{
                        obj.acqName
                    }</div>

                </div>
            </div>
        }

        return <div className={'container-fluid'}>
            <div className={'g-3 my-3 row row-cols-1'}
                role={'group'}>
                {
                    _.map(data.question?.questionAnswers, renderInputs)
                }
            </div>
        </div>
    }, [questionInterface])

    return <div className={['question-toggle', isMobile ? 'px-0' : 'px-4'].join(' ')}>
        {input}
    </div>
}

export default Toggle
