import { useAppDispatch, useAppSelector } from '@app/app.hook'

import { selectStrings } from '@app/slices/slice.app'
import {
    toggleMenuBottomMobileBar,
    toggleMenuDesktopBar,
    toggleMenuTopMainMobileBar
} from '@sidebar/slice'
import { useCallback } from 'react'

export const useInitializeSidebarVisibility = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const callback = useCallback((visibility: boolean) => {
        dispatch(toggleMenuDesktopBar(visibility))
        dispatch(toggleMenuBottomMobileBar(visibility))
        dispatch(toggleMenuTopMainMobileBar(visibility))
        // dispatch(toggleMenuTopSidebarMobileBar(visibility))
    }, [strings])

    return callback
}
