import React, { useReducer, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import _ from 'lodash'

import AccordionComponent from '@fmt/components/carepaths/AccordionComponent'

import { Carepath, GetCarepathsResponse } from '@fmt/type'

import { smartSearch } from '@app/app.method'

import { MODULE_TABLE } from '@app/app.config'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import PageCountDropdown from '@app/components/PageCountDropdown'
import Pagination from '@app/components/Pagination'
import { push } from '@lagunovsky/redux-react-router'

import produce from 'immer'

interface ComponentProps {
    mode: 'EDIT' | 'VIEW',
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    fixedCacheKey: 'shared-getCarepaths-key' | 'details-getTreatmentCarepaths-key'
    responseData?: GetCarepathsResponse
    pageCount: number
    setPageCount: React.Dispatch<React.SetStateAction<number>>
}

const DataTable = ({
    mode, currentPage, setCurrentPage, fixedCacheKey, responseData,
    pageCount, setPageCount
}: ComponentProps) => {
    const dispatch = useAppDispatch()

    const validateRoute = useValidateRoute()

    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)
    const [search, setSearch] = useState<string>('')

    /** for data table */
    // Initialize state using useReducer
    const [accordions, accordionsDispatch] = useReducer((state: {
        id: string;
        isOpen: boolean;
      }[], action: { type: 'PUSH_ID'; payload: { id: string } }
      | { type: 'TOGGLE_ISOPEN'; payload: { id: string, isOpen: boolean } }) => {
        switch (action.type) {
            case 'PUSH_ID':
                return produce(state, draft => {
                    const existingAccordion = draft.find((item) => item.id === action.payload.id)
                    if (!existingAccordion) {
                        draft.push({
                            id: action.payload.id,
                            isOpen: true
                        })
                    }
                })

            case 'TOGGLE_ISOPEN':
                return produce(state, draft => {
                    const existingAccordion = draft.find((item) => item.id === action.payload.id)
                    if (existingAccordion) {
                        existingAccordion.isOpen = action.payload.isOpen
                    }
                })

            default:
                return state
        }
    },
    [])

    const filteredData = smartSearch(responseData?.data.carepaths || []
        , [], search) as Carepath[]

    const isTableEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const table = <div className={'col-12 record-menu mt-5'}>
        <table>
            <thead>
                <tr>
                    <th>
                        <input type={'checkbox'} className={'form-check-input'}/>
                    </th>
                    {/* <i class="fa-solid fa-angles-up-down fa-rotate-180"></i> */}
                    <th>
                        <span className={'me-2'}>{strings.fmt?.text.department.menu.name}</span>
                        <i className={'fa-solid fa-angles-up-down'}></i>
                    </th>
                    <th colSpan={3}>{strings.fmt?.text.facility.menu.table.action}</th>
                </tr>
            </thead>
            <tbody>
                {
                    filteredData.length
                        ? _.map(filteredData, (obj) => {
                            const key = obj.carepathId

                            const found = _.find(accordions, (o) => {
                                return o.id === obj.carepathId
                            })

                            return <React.Fragment key={key}>
                                <tr onClick={() => {
                                    if (found) {
                                        accordionsDispatch({
                                            type: 'TOGGLE_ISOPEN',
                                            payload: {
                                                id: obj.carepathId,
                                                isOpen: !found.isOpen
                                            }
                                        })
                                    }

                                    accordionsDispatch({
                                        type: 'PUSH_ID',
                                        payload: {
                                            id: obj.carepathId
                                        }
                                    })
                                }}>
                                    <td>
                                        <input type={'checkbox'} className={'form-check-input'}/>
                                    </td>
                                    <td className={'accordion'}>
                                        <u className={'me-2'}>{obj.carepathName}</u>
                                        <i className={
                                            [
                                                'fa-light',
                                                found?.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                                            ].join(' ')
                                        }></i>
                                    </td>
                                    <td className={'cursor-pointer'} onClick={(e) => {
                                        e.stopPropagation()

                                        const isValid = validateRoute(
                                            activeModules.arr,
                                            MODULE_TABLE.fmt.moduleName,
                                            MODULE_TABLE.fmt.routes.viewCarepath,
                                            true
                                        )

                                        dispatch(push(
                                            _.replace(isValid.route,
                                                ':carepathId',
                                                obj.carepathId
                                            )
                                        ))
                                    }}><i className={'fa-light fa-eye'}></i></td>
                                    <td className={'cursor-pointer'} onClick={(e) => {
                                        e.stopPropagation()

                                        const isValid = validateRoute(
                                            activeModules.arr,
                                            MODULE_TABLE.fmt.moduleName,
                                            MODULE_TABLE.fmt.routes.editCarepath,
                                            true
                                        )

                                        dispatch(push(
                                            _.replace(isValid.route,
                                                ':carepathId',
                                                obj.carepathId
                                            )
                                        ))
                                    }}><i className={'fa-light fa-edit'}></i></td>
                                    <td className={'cursor-pointer'} >
                                        <i className={'fa-light fa-trash'}></i>
                                    </td>
                                </tr>
                                {<tr className={`accordion ${ !found?.isOpen ? 'd-none' : '' }`}>
                                    <td colSpan={8}>
                                        <AccordionComponent
                                            search={search}
                                            currentPage={currentPage}
                                            responseData={responseData}
                                            mode={mode}
                                            isOpen={found?.isOpen}
                                            obj={obj}
                                            fixedCacheKey={fixedCacheKey}
                                        />
                                    </td>
                                </tr>}
                            </React.Fragment>
                        })
                        : <tr><td colSpan={4}>{isTableEmpty}</td></tr>
                }
            </tbody>
        </table>
    </div>

    return <>
        <div className={'row'}>
            {/* <div className={'row h-100 flex-column'}> */}
            <div className={
                'col-12 d-flex justify-content-end align-items-center'
            }>
                <h6 className={'mb-0 fw-semibold'}>{
                    strings.fmt?.text.carepath.add_carepath
                }</h6>
                <div className={
                    'rounded-square yellow d-flex ms-3 ' +
                                            'justify-content-center align-items-center'
                } onClick={() => {
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.routes.createCarepath,
                        true
                    )

                    if (isValid) {
                        dispatch(push(isValid.route))
                    }
                }}>
                    <i className={'fa-light fa-plus'}></i>
                </div>
            </div>
            <div className={'col-12'}>
                <div className={['row justify-content-between ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box'}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text
                                }
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className={'col-auto pe-0'}>
                        <PageCountDropdown pageCount={pageCount}
                            setPageCount={setPageCount}
                        />
                    </div>
                    <div className={'col-auto pe-0'}>
                        <button
                            className={['btn ',
                                'rounded-square me-2'].join(' ')}
                        >
                            <i className={'fa-light fa-filter'}></i>
                        </button>
                        <button className={['btn ',
                            'rounded-square'].join(' ')}>
                            <i className={'fa-light fa-download'}></i>
                        </button>
                    </div>
                </div>
            </div>
            {table}
            <div className={'container-fluid pb-4 pt-6 px-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-auto'}>
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPageState={setCurrentPage}
                            limit={responseData?.data.limit || responseData
                                ?.data.totalRecords || 1}
                            skip={responseData?.data.skip || 1}
                            totalRecords={responseData
                                ?.data.totalRecords || 1}
                        />
                    </div>
                </div>
            </div>
        </div>

    </>
}

export default DataTable
