import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import { GetOnboardingRequest, GetOnboardingResponse } from '@onboarding/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'

export const onboardingV10API = createApi({
    reducerPath: 'onboardingV10API',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getOnboarding: builder
            .mutation<GetOnboardingResponse, GetOnboardingRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetOnboardingRequest = {}

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.onboarding.apiPaths
                                .getOnboarding.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetOnboardingMutation
} = onboardingV10API
