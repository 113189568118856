import { Department, GetDepartmentsResponse } from '@fmt/type'
import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { IDS } from '@fmt/constants'

import { smartSearch } from '@app/app.method'
import _ from 'lodash'

interface ComponentProps {
    isFromEditPage: boolean,
    isOutsideClick: boolean,
    setIsOutsideClick: React.Dispatch<React.SetStateAction<boolean>>,
    getLinkedDepartmentsResponse: GetDepartmentsResponse | undefined
    getUnlinkedDepartmentsResponse: GetDepartmentsResponse | undefined,
    changeDepartmentStatus: (obj: Department, linked: boolean) => Promise<void>
}

const DepartmentsInterface = ({
    isFromEditPage,
    isOutsideClick,
    setIsOutsideClick, getLinkedDepartmentsResponse,
    getUnlinkedDepartmentsResponse,
    changeDepartmentStatus
}: ComponentProps) => {
    // const token = useAppSelector(selectToken)
    //
    // const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    // const revalidateToken = useRevalidateToken()
    // const validateAPIPath = useValidateAPIPath()

    const containerRef = useRef<HTMLDivElement | null>(null)
    const [search, setSearch] = useState('')
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current) {
                // weird behavior but works anyways
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsOutsideClick(false)
                } else {
                    setIsOutsideClick(true)
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [containerRef])

    const renderOption = (obj: Department, defaultChecked: boolean) => {
        const key = obj.departmentId

        const addressTruthy = _.some([
            'departmentAddressStreet',
            'departmentAddressNumber',
            'departmentAddressSuffix'
        ], prop => _.get(obj, prop))

        const addressStreet = obj.departmentAddressStreet
        const addressNumber = obj.departmentAddressNumber
        const addressSuffix = obj.departmentAddressSuffix
        const city = obj.departmentCity

        const nonEmptyValues = _.compact([
            addressStreet, ' ', addressNumber, addressSuffix
        ])
        const formattedString =
        ' (' + nonEmptyValues.join('') + ', ' + (
            city || '') + ')'

        return <div
            className={'col'}
            key={key}
        >
            <input type={'checkbox'}
                className={'btn-check'}
                id={key}
                autoComplete={'off'}
                // checked prop is removed.
                // when checked, link it, otherwise, unlink.
                defaultChecked={defaultChecked}
                onChange={(e) => {
                    if (e.target.checked) {
                    // link
                        changeDepartmentStatus(obj, true)
                    } else {
                    // unlink.
                        changeDepartmentStatus(obj, false)
                    }
                }}
            />
            <label
                className={'btn btn-dummy'}
                htmlFor={key}
            >

                <div className={'question-checkbox'}>
                    <div className={'card justify-content-center px-3 py-2'}>

                        <div className={'d-flex flex-column'}>
                            <div className={'d-flex align-items-center'}>
                                <div className={'p text-start'}>
                                    <span>{obj.departmentName}</span>
                                    {

                                        addressTruthy
                                            ? <span className={'fw-light'}>
                                                {formattedString}
                                            </span>
                                            : ''
                                    }
                                </div>
                                <div className={'question-checkmark ms-auto'}>
                                    <i className={'fa-light fa-check mt-1 mx-auto'}>
                                    </i>
                                </div>
                                <div className={'question-plussign ms-auto'}>
                                    <i className={'fa-light fa-plus mx-auto'}>
                                    </i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </label>
        </div>
    }

    const LinkedDepartmentOptions = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    getLinkedDepartmentsResponse?.data.departments || [],
                    [], search
                ) as Department[]),
                (obj) => {
                    return renderOption(obj, true)
                })
        }
    </div>

    const UnlinkedDepartmentOptions = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    getUnlinkedDepartmentsResponse?.data.departments || [],
                    [], search
                ) as Department[]),
                (obj) => {
                    return renderOption(obj, false)
                })
        }
    </div>

    return <div className={[
        'align-items-baseline mt-3 row'
    ].join(' ')}>
        <div className={isFromEditPage
            ? 'col-xl-3 d-none d-sm-block'
            : 'col-3 d-none d-sm-block'}>
            <label htmlFor={IDS.FACILITY_ADD_MODAL.SELECT.ADD_DEPARTMENT}
                className={'form-label'}>{
                    strings.fmt?.text.facility.add.departments
                }</label>
        </div>
        <div className={isFromEditPage
            ? 'col-xl-9 col-12 position-relative'
            : 'col-sm-9 col-12 position-relative'}>

            <div
                id={IDS.FACILITY_ADD_MODAL.SELECT.ADD_DEPARTMENT}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOutsideClick(!isOutsideClick)
                }}
                className={[
                    'form-select py-3'
                ].join(' ')}
            >
                {
                    <span className={'opacity-50'}>
                        {strings.app?.text.select || ''}
                    </span>
                }

            </div>
            {/* popup window to get a list of carepath with checkboxes */}

            {isOutsideClick && <div
                ref={(e) => {
                    containerRef.current = e
                }}
                className={[
                    'dropdown-list mt-4 px-5 py-6 w-100',
                    isFromEditPage ? '' : 'position-absolute'
                ].join(' ')}>
                {/* search tab and filter icon */}
                <div className={['row justify-content-between mb-4 ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box '}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={'btn-group-checkbox-list row row-cols-1'}>
                    {getLinkedDepartmentsResponse?.data.departments.length
                        ? <h6 className={'mb-3'}>{strings.fmt
                            ?.text.facility.add.steps['2'].linked}</h6>
                        : ''}
                    {LinkedDepartmentOptions}
                    {getUnlinkedDepartmentsResponse?.data.departments.length
                        ? <h6 className={'mb-3'}>{strings.fmt
                            ?.text.facility.add.steps['2'].unlinked}</h6>
                        : ''}
                    {UnlinkedDepartmentOptions}
                </div>

            </div>}

        </div>
    </div>
}

export default DepartmentsInterface
