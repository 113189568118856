import { OpenListActions, OpenListInputs, OpenListValues } from '@reasonWithMe/type'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
    findPreviousInput: any,
    formsDispatch: (value: OpenListActions) => void
    form: OpenListValues
}

const Open = ({
    answerType, outerIndex, innerIndex,
    formInput, findPreviousInput, formsDispatch, form
}: ComponentProps) => {
    return <input
        className={'form-control form-control-sm'}
        type={'text'}
        name={`${ answerType }-${ outerIndex }-${ innerIndex }`}
        // refer to ihd-349 bullet 5.
        // change value to defaultValue instead
        defaultValue={formInput.userTypedAnswer as string}
        // if answerEditable is false OR if the findPreviousInput is truthy
        disabled={(findPreviousInput && !findPreviousInput.answerEditable) ||
                        !formInput.answerEditable}
        onChange={(e) => {
            if (formInput.answerEditable) {
                formsDispatch({
                    type: 'UPDATE_FORM',
                    id: form.id,
                    columnOrder: Number(formInput
                        .answerValue || 0),
                    value: e.target.value
                })
            }
        }}
        onBlur={(e) => {
            if (formInput.answerEditable) {
                formsDispatch({
                    type: 'UPDATE_FORM',
                    id: form.id,
                    columnOrder: Number(formInput
                        .answerValue || 0),
                    value: e.target.value
                })
            }
        }}
    />
}

export default Open
