// useLinkInterceptor.ts

import { useLayoutEffect } from 'react'

const useLinkInterceptor = () => {
    useLayoutEffect(() => {
        const handleAnchorClick = (event: MouseEvent) => {
            const target = event.target as HTMLAnchorElement
            if (!event.defaultPrevented && !target.getAttribute('target')) {
                event.preventDefault()
                const href = target.getAttribute('href')
                if (href && href !== '#') {
                    window.open(href, '_blank')
                }
            }
        }

        const anchors = document.querySelectorAll('a')
        anchors.forEach((anchor) => {
            const onClick = anchor.getAttribute('onclick')
            if (onClick === null) {
                anchor.addEventListener('click', handleAnchorClick)
            }
        })

        return () => {
            anchors.forEach((anchor) => {
                anchor.removeEventListener('click', handleAnchorClick)
            })
        }
    }, undefined)
}

export default useLinkInterceptor
