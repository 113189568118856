import { MODULE_TABLE } from '@app/app.config'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import {
    IDS,
    UPDATE_TREATMENT_FORMIK_INITIAL_VALUES,
    UPDATE_TREATMENT_VALIDATION_SCHEMA
} from '@registration/constants'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
// for react-datepicker locale. requires date-fns.

import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import {
    useGetTreatmentMutation,
    useGetUserTreatmentsMutation,
    useUpdateTreatmentMutation
} from '@registration/api'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { fromUnixTime, getUnixTime } from 'date-fns'
// for react-datepicker locale. requires date-fns.
import NewDatePicker from '@app/components/NewDatePicker'
import { TokenData } from '@app/types/type.token'
import { MyTherapeutic } from '@profile/type'
import { UpdateTreatmentKeys } from '@registration/type'
import { useMediaQuery } from 'react-responsive'
// import TreatmentsInterface from '@registration/components/TreatmentsInterface'

interface ComponentProps {
    treatment: MyTherapeutic,
    toggleShowEditModal: React.Dispatch<React.SetStateAction<boolean>>
    fetchData: (token: TokenData) => () => void
}

function EditTreatment (props: ComponentProps) {
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)

    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const [isOutsideClick, setIsOutsideClick] = useState(false)
    const dateFormats = useAppSelector(selectDateFormats)

    const [getTreatment, getTreatmentMutation] = useGetTreatmentMutation()
    const [getUserTreatments, getUserTreatmentsMutation] = useGetUserTreatmentsMutation()
    const [
        updateTreatment,
        updateTreatmentMutation
    ] = useUpdateTreatmentMutation()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const treatmentFormik = useFormik({
        initialValues: UPDATE_TREATMENT_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: UPDATE_TREATMENT_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.invalid_date || ''

        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken({
                        value: token.value,
                        id: token.id
                    }, token.mode)
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.updateTreatment.path,
                        true
                    )

                    if (foundApiPath && getTreatmentMutation.data?.data.myCareplanId) {
                        updateTreatment({
                            authToken: newToken.value,
                            data: {
                                treatmentId: values.treatmentId,
                                carepathId: values.careplanId,
                                myCareplanId: getTreatmentMutation.data?.data.myCareplanId,
                                startDate: values.isUnknownChecked ? 0 : values.startDate || 0
                            }
                        })
                    } else {
                        console.log('condition check: ', foundApiPath,
                            getTreatmentMutation.data?.data.myCareplanId)
                    }
                }
            }
            call()
        }
    })

    const unsubscribeGetTreatment = () => {
        const unsubscribeMutation = getTreatment({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const unsubscribeGetUserTreatments = () => {
        const unsubscribeMutation = getUserTreatments({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    useEffect(() => {
        if (getTreatmentMutation.error) {
            const message = getErrorText(getTreatmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getTreatmentMutation.error])

    useEffect(() => {
        if (getUserTreatmentsMutation.error) {
            const message = getErrorText(getUserTreatmentsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getUserTreatmentsMutation.error])

    useEffect(() => {
        const data = getTreatmentMutation.data
        if (data) {
            // get treatment id.
            const id = _.find(
                getUserTreatmentsMutation.data?.data.carepaths,
                (o) => o.carepathId === data.data.careplanId
            )

            const defaultId = getUserTreatmentsMutation.data?.data.carepaths[0]

            treatmentFormik.setValues({
                treatmentId: id?.treatmentId || defaultId?.treatmentId || '',
                careplanId: id?.carepathId || defaultId?.carepathId || '',
                startDate: data.data.startDate ? data.data.startDate : undefined,
                isUnknownChecked: data.data.startDate === 0 || data.data.startDate === undefined
            })
        }
    }, [getTreatmentMutation.data, getUserTreatmentsMutation.data])

    useEffect(() => {
        if (updateTreatmentMutation.error) {
            const message = getErrorText(updateTreatmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updateTreatmentMutation.error])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetTreatment()
        unsubscribeGetUserTreatments()

        let getTreatmentPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getUserTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const apiPath1 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getTreatment.path,
                        true
                    )

                    const apiPath2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getUserTreatments.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (apiPath1 && newToken.value) {
                        getTreatmentPromise = getTreatment({
                            authToken: newToken.value,
                            data: {
                                personalCareplanId: props.treatment.myCareplanId || ''
                            }
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.profile
                                    .apiPaths.getTreatment.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }

                    if (apiPath2 && newToken.value) {
                        getUserTreatmentsPromise = getUserTreatments({
                            authToken: newToken.value
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.profile
                                    .apiPaths.getUserTreatments.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getTreatmentPromise && getTreatmentPromise.abort()
            getUserTreatmentsPromise && getUserTreatmentsPromise.abort()
        }
    }

    useEffect(() => {
        const data = updateTreatmentMutation.data

        const call = async () => {
            if (data?.status === 'OK') {
                // close the modal and fetch the list of therapeutics again.
                props.fetchData(token)
                props.toggleShowEditModal(false)
            }
        }
        call()
    }, [activeModules.id, updateTreatmentMutation.data])

    useEffect(() => {
        // empty formik via reset.
        treatmentFormik.resetForm()
        return fetchData(token)
    }, [activeModules.id, props.treatment])

    /** components from ReviewAgainForm */
    const ReviewAgainSubmitButton = useMemo(() => {
        const buttonContent = updateTreatmentMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            strings.app?.text.next
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        return (<button type={'submit'}
            disabled={(getTreatmentMutation.isSuccess === false ||
                getUserTreatmentsMutation.isSuccess === false) ||
                (updateTreatmentMutation.isLoading)
            }
            form={IDS.UPDATE_TREATMENT.FORM} className={'btn btn-primary btn-lg w-100'}>
            {buttonContent}
        </button>)
    }, [strings, updateTreatmentMutation, getTreatmentMutation, getUserTreatmentsMutation])

    // this shoudln't be editable by any means.
    const TreatmentIdSelection = useMemo(() => {
        const found = _.find(getUserTreatmentsMutation?.data?.data.carepaths || [], (obj) => {
            return obj.treatmentId === treatmentFormik.values.treatmentId &&
            obj.carepathId === treatmentFormik.values.careplanId
        })

        const result = <div className={'form-group  mb-4 position-relative'}>
            <label htmlFor={IDS.UPDATE_TREATMENT.TREATMENT_ID}
                className={'form-label'}>{
                    strings.profile?.text.therapeutics.treatment.therapy
                }</label>
            <div
                id={IDS.UPDATE_TREATMENT.TREATMENT_ID}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOutsideClick(!isOutsideClick)
                }}
                className={[
                    'form-control py-4',
                    treatmentFormik.errors.treatmentId && 'border-danger'
                ].join(' ')}
            >
                {
                    !found?.treatmentName
                        ? <span className={'opacity-50'}>
                            {strings.profile?.text.therapeutics.treatment.therapy || ''}
                        </span>
                        : <>
                            <span className={'fw-semibold'}>{found?.treatmentName}</span>
                            <span className={'fw-light'}>{` ${ found?.carepathName
                        ? ([
                            '(', found?.carepathName, ')'
                        ].join(''))
                        : '' } `}</span>
                        </>
                }

            </div>
        </div>

        return result
    }, [strings,
        isOutsideClick,
        setIsOutsideClick,
        treatmentFormik.values.treatmentId,
        treatmentFormik.errors.treatmentId,
        treatmentFormik.values.careplanId,
        treatmentFormik.errors.careplanId,
        getUserTreatmentsMutation.data])

    const DatePickerInput = useMemo(() => {
        const fieldName: UpdateTreatmentKeys = 'startDate'

        // operation_date_name for value Operation Date
        // therapeutic_date_name for value Start Date
        const dateName = getTreatmentMutation.data?.data.dateName || ''
        const label = dateName === 'operation_date_name'
            ? strings.profile?.text.therapeutics.treatment.operation_date
            : dateName === 'therapeutic_date_name'
                ? strings.profile?.text.therapeutics.treatment.therapeutic_date_name
                : ''

        return (<div className={'form-group  mb-4'}>
            <label htmlFor={IDS.UPDATE_TREATMENT.OK_DATE}
                className={'form-label'}>{`${ label } `}</label>
            <NewDatePicker
                disabled={treatmentFormik.values.isUnknownChecked}
                id={IDS.UPDATE_TREATMENT.OK_DATE}
                errors={treatmentFormik.errors.startDate}
                isDate={(date) => {
                    treatmentFormik.setFieldValue(fieldName, getUnixTime(date))
                }}
                singleDate={treatmentFormik.values.startDate !== undefined
                    ? fromUnixTime(treatmentFormik.values.startDate)
                    : undefined
                }
                dateType={''}
                timeFormat={dateFormats.format5}
            />
            <div className={'form-text error'}>{
                treatmentFormik.errors.startDate
                    ? treatmentFormik.errors.startDate
                    : null
            }</div>
            <div>
                <input type={'checkbox'}
                    id={`${ IDS.UPDATE_TREATMENT.UNKNOWN_DATE }`}
                    checked={treatmentFormik.values.isUnknownChecked}
                    className={'form-check-input'}
                    onChange={(e) => {
                        treatmentFormik.setFieldValue('isUnknownChecked', e.target.checked)
                    }}/>
                <label className={'form-check-label ps-3'}
                    htmlFor={`${ IDS.UPDATE_TREATMENT.UNKNOWN_DATE }`}>{
                        strings.profile?.text.therapeutics.unknown}</label>
            </div>
        </div>)
    }, [strings, treatmentFormik.values.startDate,
        treatmentFormik.errors.startDate, treatmentFormik.values.isUnknownChecked,
        treatmentFormik.errors.isUnknownChecked, getTreatmentMutation.data])

    return (<>
        <div className={isMobile ? 'container' : 'container px-5' }>
            <div className={'row'}>
                <div className={'col-12 mx-auto'}>
                    <form id={IDS.UPDATE_TREATMENT.FORM} onSubmit={treatmentFormik.handleSubmit}>
                        {TreatmentIdSelection}
                        {DatePickerInput}
                        <div className={'justify-content-center row'}>
                            <div className={'col-auto'}>
                                {ReviewAgainSubmitButton}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}

export default EditTreatment
