import { MenuList, MenuBarState } from '@sidebar/type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@app/app.store'
import _ from 'lodash'

/*
    abortSignals when called to abort the first time will stay
    that way until a new abortController instance has been created.
*/

const initialState: MenuBarState = {
    toggle: {
        desktop: false,
        mobile: {
            top: {
                sidebar: false,
                menu: false
            },
            bottom: false,
            dashboard: {
                top: false
            }
        }
    },
    activeTab: {
        primaryFlag: false,
        sectionNumber: 1,
        pageIcon: '',
        pageName: '',
        pageLink: '',
        categoryName: '',
        roleTypes: [],
        locale: '',
        subPages: [],
        isActive: false,
        pageOrder: 1.0,
        id: ''
    },
    menuList: []
}

const findMenuListById = (menuList: MenuList[], id: string): MenuList | null => {
    const foundItem = _.find(menuList, { id })

    if (foundItem) {
        return foundItem
    }

    for (const item of menuList) {
        if (item.subPages && item.subPages.length > 0) {
            const nestedFoundItem = findMenuListById(item.subPages, id)
            if (nestedFoundItem) {
                return nestedFoundItem
            }
        }
    }

    return null
}

export const slice = createSlice({
    name: 'sidebarV10',
    initialState,
    /** jsdoc annotations are done in the reducers themselves since the state,action parameters
     *  have been defined by redux) */
    /** adding payloadAction helps actions with data types */
    reducers: {
        toggleMenuDesktopBar: (state: MenuBarState, action: PayloadAction<boolean>) => {
            state.toggle.desktop = action.payload
        },
        toggleMenuBottomMobileBar: (state: MenuBarState, action: PayloadAction<boolean>) => {
            state.toggle.mobile.bottom = action.payload
        },
        toggleMenuTopMainMobileBar: (state: MenuBarState, action: PayloadAction<boolean>) => {
            state.toggle.mobile.top.menu = action.payload
        },
        toggleMenuTopSidebarMobileBar: (state: MenuBarState, action: PayloadAction<boolean>) => {
            state.toggle.mobile.top.sidebar = action.payload
        },
        toggleDashboardTopMobileBar: (state: MenuBarState, action: PayloadAction<boolean>) => {
            state.toggle.mobile.dashboard.top = action.payload
        },
        setActiveTab: (state: MenuBarState, action: PayloadAction<MenuList>) => {
            state.activeTab = action.payload
        },
        setMenuList: (state: MenuBarState, action: PayloadAction<MenuList[]>) => {
            state.menuList = action.payload
        },
        toggleExpand: (state: MenuBarState, action: PayloadAction<{
            id: string, expand: boolean
        }>) => {
            const foundItem = findMenuListById(state.menuList, action.payload.id)
            if (foundItem) {
                foundItem.expand = action.payload.expand
            }
        },
        resetMenuBar: (state: MenuBarState) => {
            state.activeTab = initialState.activeTab
            state.toggle = initialState.toggle
            state.menuList = initialState.menuList
        }
    }
})

export const {
    toggleMenuDesktopBar, toggleMenuBottomMobileBar,
    toggleMenuTopMainMobileBar, toggleMenuTopSidebarMobileBar,
    setActiveTab, toggleDashboardTopMobileBar,
    resetMenuBar, setMenuList, toggleExpand
} = slice.actions

export const selectShowMenuBar = (state: RootState) => state.sidebarV10.toggle
export const selectActiveTab = (state: RootState) => state.sidebarV10.activeTab
export const selectMenuList = (state: RootState) => state.sidebarV10.menuList

export default slice.reducer
