import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import {
    FloatingArrow,
    FloatingFocusManager,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useClick,
    useClientPoint,
    useFloating,
    useHover,
    useId,
    useInteractions,
    useTransitionStyles
} from '@floating-ui/react'
import { IDS } from '@reasonWithMe/constants'
import { setAnswerValue } from '@reasonWithMe/slice'
import {
    BodyMapActions,
    BodyMapState,
    QuestionAnswer,
    QuestionInterfaceActions,
    ReasonWithMeState
} from '@reasonWithMe/type'
import produce from 'immer'
import _ from 'lodash'
import { ReactElement, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

const InformationPopover = ({ isTouch, text, element }:
    { isTouch: boolean, text:string | undefined, element: ReactElement}) => {
    const arrowRef = useRef(null)
    const [visible, setVisible] = useState(false)

    const { refs, floatingStyles, context } = useFloating({
        open: visible,
        placement: 'top',
        onOpenChange: (isOpen, event, reason) => {
            if (isTouch) {
                if (reason === 'click') {
                    setVisible(isOpen)
                }
            } else {
                setVisible(isOpen)
            }
            // event && console.log(event)
            // reason && console.log(reason)
        },
        middleware: [
            offset(10),
            flip({
                fallbackAxisSideDirection: 'end'
            }),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate

    })
    const { styles } = useTransitionStyles(context)

    const clientPoint = useClientPoint(context)

    const hover = useHover(context)
    const click = useClick(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        click,
        clientPoint
    ])

    const headingId = useId()

    return <>
        <div ref={refs.setReference} {...getReferenceProps()}>
            {element}
        </div>
        { visible && text && <FloatingFocusManager context={context} modal={false}>
            <div
                className={'floating-ui bodymap'}
                ref={refs.setFloating}
                style={{
                    ...floatingStyles,
                    ...styles
                }}
                aria-labelledby={headingId}
                {...getFloatingProps()}
            >
                <FloatingArrow ref={arrowRef} context={context}
                />
                {

                    <div><span>{
                        text
                    }</span></div>
                }
            </div>

        </FloatingFocusManager>}

    </>
}

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
}

const Bodymap = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const dispatch = useAppDispatch()

    const strings = useAppSelector(selectStrings)

    const bodymapRef = useRef<HTMLDivElement | null>(null)

    // new: adding a toggle for filtering from physical to non body part choices.
    const [mode] = useState<'mental'|'physical'>('physical')

    const [isTouch, setIsTouch] = useState(false)

    const windowWidth = useWindowSize()

    const [bodymapState, bodymapDispatch] = useReducer(
        (state: BodyMapState, action: BodyMapActions) => {
            switch (action.type) {
                case 'SET_BODY_POSITION': {
                    return produce(state, draft => {
                        draft.bodyPosition = action.value
                    })
                } case 'SET_MOUSE_IN': {
                    return produce(state, draft => {
                        draft.mouseIn = action.value
                    })
                } case 'SET_COORDINATES': {
                    return produce(state, draft => {
                        draft.coordinates = action.value
                    })
                } case 'ADD_SELECTED': {
                    return produce(state, draft => {
                        const found = _.find(draft.selected, o => {
                            return (
                                o.acqValue === action.value.acqValue &&
                                o.acqName === action.value.acqName &&
                                o.acqPosition === action.value.acqPosition

                            )
                        })

                        if (!found) {
                            draft.selected.push(action.value)
                        }
                    })
                } case 'REMOVE_SELECTED': {
                    return produce(state, draft => {
                        _.remove(draft.selected, o => {
                            return (
                                o.acqValue === action.value.acqValue &&
                                o.acqName === action.value.acqName &&
                                o.acqPosition === action.value.acqPosition
                            )
                        })
                    })
                }
                // } case 'SET_SELECTED': {
                //     return produce(state, draft => {
                //         // how do you unclick at this point.
                //         if (_.isEqual(draft.selected, action.value)) {
                //             draft.selected = {}
                //         } else {
                //             draft.selected = action.value
                //         }
                //     })
                // }
            }
        }
        , {
            bodyPosition: 'front',
            mouseIn: false,
            coordinates: [0, 0],
            selected: []
        }
    )

    const setSelected = (item: QuestionAnswer) => {
        const found = _.find(bodymapState.selected, o => {
            return (
                o.acqValue === item.acqValue &&
                o.acqName === item.acqName &&
                o.acqPosition === item.acqPosition

            )
        })

        const questionAnswerAllowed = questionInterface.currentReasonWithMeResponse
            .reasoningData.question?.questionAnswersAllowed

        // if 0, no limits.
        if (questionAnswerAllowed !== undefined && questionAnswerAllowed > 0) {
            if (!found) {
                // console.log('pushing body part')
                if (bodymapState.selected.length < (questionAnswerAllowed)) {
                    bodymapDispatch({
                        type: 'ADD_SELECTED',
                        value: item
                    })
                }
            } else {
                // console.log('pulling body part')
                bodymapDispatch({
                    type: 'REMOVE_SELECTED',
                    value: item
                })
            }
        } else {
            if (!found) {
                // console.log('pushing body part')
                bodymapDispatch({
                    type: 'ADD_SELECTED',
                    value: item
                })
            } else {
                // console.log('pulling body part')
                bodymapDispatch({
                    type: 'REMOVE_SELECTED',
                    value: item
                })
            }
        }
    }

    // useEffect(() => {
    //     console.log(bodymapState.selected)
    // }, [bodymapState.selected])

    const input = useMemo(() => {
        /** how should the input be rendered */
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        // const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        const questionsAnswers = data.question?.questionAnswers

        // the goal is to change the image to a certain kind.

        // step 1: filter out by type.
        const filteredByPosition = _.filter(questionsAnswers, (obj) => {
            return obj.acqPosition === bodymapState.bodyPosition
        })

        // iterate through each item and return matching coordinates.
        // then getting the body part that has the smallest sum.
        // if there is more than one match found.
        const filteredByCoordinates = _.filter(filteredByPosition, (obj) => {
            if (obj.acqCoords?.length) {
                return _.inRange(
                    bodymapState.coordinates[0],
                    obj.acqCoords[0]?.x,
                    obj.acqCoords[1].x
                ) &&
                _.inRange(
                    bodymapState.coordinates[1],
                    obj.acqCoords[0]?.y,
                    obj.acqCoords[1].y
                )
            } else {
                return false
            }
        })

        /** now we map the values.  */

        // console.log(filteredByCoordinates)

        const findClosestCoordinates = _.minBy(
            _.cloneDeep(filteredByCoordinates), (obj) => {
                // create new property.
                let sumX = 0; let sumY = 0

                if (obj.acqCoords?.length) {
                    sumX = obj.acqCoords[0]?.x + obj.acqCoords[1].x
                    sumY = obj.acqCoords[0]?.y + obj.acqCoords[1].y
                }

                return sumX + sumY
            }
        )

        // if the results are empty, you want to highlight the skin.
        // if (!findClosestCoordinates) {
        //     findClosestCoordinates = _.find(questionsAnswers, (obj) => {
        //         return obj.acqValue === 'skin'
        //     })
        // }

        const url = [
            '/images_new/herstelplan/bodymap/',
            bodymapState.bodyPosition === 'front' ? 'front' : 'back', '/',
            findClosestCoordinates?.acqValue || 'bodymap',
            '.png'
        ].join('')

        const result = <img className={'image-stack-hover'} src={url} onContextMenu={(event) => {
            if (isTouch) { event.preventDefault() }
        }}
        // do only on desktop
        onClick={() => {
            // console.log('image clicked: ', findClosestCoordinates)
            if (findClosestCoordinates) {
                setSelected(findClosestCoordinates)
            }
        }}
        alt={strings.reason_with_me?.text.body_selection_diagram || ''} role={'button'} />

        return <InformationPopover
            isTouch={isTouch}
            text={findClosestCoordinates?.acqName}
            element={result}
        />
    }, [strings, questionInterface, bodymapState, isTouch])

    useEffect(() => {
        // to save self from trouble with making a duplicate copy of this.
        // just pass in the dispatch from the reducer and use it IF truthy.
        if (componentDispatch !== undefined) {
            componentDispatch({
                type: 'SET_ANSWER_VALUE',
                value: bodymapState.selected
            })
        } else {
            dispatch(setAnswerValue(bodymapState.selected))
        }
    }, [bodymapState.selected])

    const nonBodyPartChoices = useMemo(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        const questionsAnswers = data.question?.questionAnswers

        // acqCoords that don't have elements belong here.
        const arr = _.filter(questionsAnswers, (obj) => {
            return (obj.acqCoords?.length || 0) <= 0
        })

        return <div
            className={['my-5 row row-cols-1 ',
            // { !componentDispatch ? 'row-cols-md-2' : '' }
                'btn-group-checkbox-list'].join(' ')}
            role={'group'}
        >
            {
                _.map(arr, (obj, i) => {
                    const key = [
                        'question-choice', '-', i
                    ].join('')

                    const isChecked = _.find(bodymapState.selected, o => {
                        return (
                            o.acqValue === obj.acqValue &&
                            o.acqName === obj.acqName &&
                            o.acqPosition === obj.acqPosition

                        )
                    })

                    return <div className={'col'} key={key}>
                        <input
                            type={'checkbox'}
                            className={'btn-check'}
                            id={key}
                            autoComplete={'off'}
                            checked={isChecked !== undefined}
                            onChange={() => {
                                setSelected(obj)
                            }}
                        />
                        <label className={'btn btn-dummy'} htmlFor={key} >

                            <div className={'question-checkbox'}>
                                <div className={'card justify-content-center px-3 py-2'}>
                                    <div className={'d-flex flex-column'}>
                                        <div className={'d-flex align-items-center'}>
                                            <div className={'p text-start'}><span style={{
                                                verticalAlign: 'inherit'
                                            }}>
                                                <span style={{ verticalAlign: 'inherit' }}>
                                                    {obj.acqName}
                                                </span>
                                            </span></div>
                                            <div
                                                className={'question-checkmark ms-auto'}>
                                                <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                            </div>
                                            <div
                                                className={'question-plussign ms-auto'}>
                                                <i className={'fa-light fa-plus mx-auto'}></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </label>
                    </div>
                })
            }

        </div>
    }, [questionInterface, bodymapState])

    const showActiveBodyMap = useMemo(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        const questionsAnswers = data.question?.questionAnswers

        // show questionAnswers that are visible in the bodymap.
        const arr = _.filter(questionsAnswers, (obj) => {
            return (obj.acqCoords?.length || 0) > 0
        })

        // first filter answers of current bodyposition.
        const currentBodyPosition = _.filter(bodymapState.selected, (o) => {
            return o.acqPosition === bodymapState.bodyPosition
        })

        // it will now be a list of filtered resutls
        const founds = _.filter(arr, (outer) => {
            return _.find(currentBodyPosition, (inner) => {
                return outer.acqValue === inner.acqValue &&
                outer.acqName === inner.acqName &&
                outer.acqPosition === inner.acqPosition
            }) !== undefined
        })

        // list of urls to render on top of each other
        const urls = _.map(founds, o => {
            return [
                '/images_new/herstelplan/bodymap/',
                bodymapState.bodyPosition, '/',
                o?.acqValue,
                '.png'
            ].join('')
        })

        // then push the bodymap last.
        urls.push([
            '/images_new/herstelplan/bodymap/',
            bodymapState.bodyPosition, '/',
            'bodymap',
            '.png'
        ].join(''))

        // console.log('urls: ', urls)

        return <div className={'image-stack'}>
            {bodymapState.mouseIn
                ? input
                : ''
            }

            {/* so that we don't have to keep rendering this all over again. */}

            {
                _.map(urls, (o, index) => {
                    const key = `bodymap-key-${ index }`
                    return <img key={key} className={'image'} src={o} onContextMenu={(event) => {
                        if (isTouch) { event.preventDefault() }
                    }}
                    alt={strings.reason_with_me?.text.body_selection_diagram || ''}
                    role={'button'}
                    />
                })
            }
        </div>
    }, [questionInterface, strings, bodymapState, input, isTouch])

    const exceedMessage = useMemo(() => {
        // entire text change message:
        let textAllowedMessage = [
            [
                strings.reason_with_me?.text.list.allowed,
                ':'
            ].join(''),
            questionInterface.currentReasonWithMeResponse
                .reasoningData.question
                ?.questionAnswersAllowed
        ].join(' ')

        if ((questionInterface.currentReasonWithMeResponse
            .reasoningData.question
            ?.questionAnswersAllowed || 0) <= 0) {
            textAllowedMessage = strings.reason_with_me?.text.list.no_restrictions || ''
        }

        return questionInterface.currentReasonWithMeResponse
            .reasoningData.question
            ?.questionAnswersAllowed
            ? <div className={'col-12'}>
                <p className={'text-center'}>{textAllowedMessage}</p>
            </div>
            : ''
    }, undefined)

    const onMouseLeaveEvent = () => {
        console.log('on mouse leave triggered')
        bodymapDispatch({
            type: 'SET_MOUSE_IN',
            value: false
        })
    }

    const onMouseMoveEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (bodymapRef.current) {
            const rect = bodymapRef.current.getBoundingClientRect()
            // console.log('on mouse move triggered')

            const x = (e.clientX - rect.left) / rect.width * 100
            const y = (e.clientY - rect.top) / rect.height * 100

            // logging purposes.

            // console.clear()
            // console.log(`Coordinates RELATIVE to element:
            // ${ x } and ${ y }`)

            bodymapDispatch({
                type: 'SET_COORDINATES',
                value: [x, y]
            })

            bodymapDispatch({
                type: 'SET_MOUSE_IN',
                value: true
            })
        }
    }

    useEffect(() => {
        const detectTouch = () => {
            setIsTouch(
                'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            window.matchMedia('(pointer: coarse)').matches
            )
        }

        detectTouch()
        window.addEventListener('resize', detectTouch) // Recheck on resize if needed
        return () => window.removeEventListener('resize', detectTouch)
    }, [windowWidth])

    const touchScreenActiveBodyMap = <div
        className={'d-inline-block my-4'}
        ref={(e) => {
            bodymapRef.current = e
        }}
        onClick={(e) => {
            if (bodymapRef.current) {
                const rect = bodymapRef.current.getBoundingClientRect()
                // console.log('on mouse move triggered')

                const x = (e.clientX - rect.left) / rect.width * 100
                const y = (e.clientY - rect.top) / rect.height * 100

                const coordinates = [x, y]

                const data = questionInterface.currentReasonWithMeResponse.reasoningData
                const questionsAnswers = data.question?.questionAnswers
                // step 1: filter out by type.
                const filteredByPosition = _.filter(questionsAnswers, (obj) => {
                    return obj.acqPosition === bodymapState.bodyPosition
                })

                const filteredByCoordinates = _.filter(filteredByPosition, (obj) => {
                    if (obj.acqCoords?.length) {
                        return _.inRange(
                            coordinates[0],
                            obj.acqCoords[0]?.x,
                            obj.acqCoords[1].x
                        ) &&
                        _.inRange(
                            coordinates[1],
                            obj.acqCoords[0]?.y,
                            obj.acqCoords[1].y
                        )
                    } else {
                        return false
                    }
                })

                const findClosestCoordinates = _.minBy(
                    _.cloneDeep(filteredByCoordinates), (obj) => {
                        // create new property.
                        let sumX = 0; let sumY = 0

                        if (obj.acqCoords?.length) {
                            sumX = obj.acqCoords[0]?.x + obj.acqCoords[1].x
                            sumY = obj.acqCoords[0]?.y + obj.acqCoords[1].y
                        }

                        return sumX + sumY
                    }
                )

                if (findClosestCoordinates) {
                    setSelected(findClosestCoordinates)
                }
            }
        }}
    >
        {showActiveBodyMap}
    </div>

    const desktopScreenActiveBodyMap = <div
        className={'d-inline-block my-4'}
        ref={(e) => {
            bodymapRef.current = e
        }}
        onMouseLeave={onMouseLeaveEvent} onMouseMove={onMouseMoveEvent}
    >
        {showActiveBodyMap}
    </div>

    return <div className={'question-bodymap'}>

        {/* tab selection */}
        {/* <div className={'row'}>
            <div className={`col-8 ${ !componentDispatch ? 'col-lg-5' : '' } mx-auto`}>
                <nav>
                    <div className={'nav nav-pills nav-fill'} role={'tablist'}>
                        <button className={`nav-link ${ mode === 'physical' ? 'active' : '' }`}
                            type={'button'} role={'tab'}
                            aria-controls={'pane-1'}
                            aria-selected={mode === 'physical' ? 'true' : 'false'}
                            tabIndex={mode === 'physical' ? 0 : -1}
                            onClick={() => setMode('physical')}
                        >
                            {strings.reason_with_me?.text.bodymap.physical}
                        </button>
                        <button className={`nav-link ${ mode === 'mental' ? 'active' : '' }`}
                            type={'button'} role={'tab'}
                            aria-controls={'pane-2'}
                            aria-selected={mode === 'mental' ? 'true' : 'false'}
                            tabIndex={mode === 'mental' ? 0 : -1}
                            onClick={() => setMode('mental')}
                        >
                            {strings.reason_with_me?.text.bodymap.mental}
                        </button>
                    </div>
                </nav>
            </div>
        </div> */}

        {
            mode === 'physical'
                ? <div className={'text-center pb-5'}>
                    <div className={'align-items-center justify-content-center row'}>
                        {exceedMessage}
                        <div className={'col-auto'}>
                            <h6 className={'mb-0'}>
                                {bodymapState.bodyPosition === 'front'
                                    ? strings.app?.text.right
                                    : strings.app?.text.left}
                            </h6>
                        </div>
                        <div className={'col-auto px-0'}>
                            {
                                isTouch ? touchScreenActiveBodyMap : desktopScreenActiveBodyMap
                            }
                        </div>
                        <div className={'col-auto'}>
                            <h6 className={'mb-0'}>
                                {bodymapState.bodyPosition === 'front'
                                    ? strings.app?.text.left
                                    : strings.app?.text.right}
                            </h6>
                        </div>
                    </div>
                    <div className={'d-flex align-items-center justify-content-center mb-4'}>
                        <label className={'form-label my-0 me-2'} htmlFor={IDS.BODYMAP.SWITCH}>
                            {
                                strings.reason_with_me?.text.bodymap.front
                            }
                        </label>
                        <div className={'form-check form-switch'}>
                            <input className={'form-check-input'}
                                type={'checkbox'} id={IDS.BODYMAP.SWITCH} checked={
                                    bodymapState.bodyPosition === 'back'
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        bodymapDispatch({
                                            type: 'SET_BODY_POSITION',
                                            value: 'back'
                                        })
                                    } else {
                                        bodymapDispatch({
                                            type: 'SET_BODY_POSITION',
                                            value: 'front'
                                        })
                                    }
                                }}
                            />
                            <label className={'form-check-label'} htmlFor={IDS.BODYMAP.SWITCH}>
                                <span style={{ verticalAlign: 'inherit' }}>
                                    <span style={{ verticalAlign: 'inherit' }}>{
                                        strings.reason_with_me?.text.bodymap.back
                                    }</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                : nonBodyPartChoices
        }

    </div>
}

export default Bodymap
