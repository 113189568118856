import { MODULE_TABLE } from '@app/app.config'
import { MOBILE_RESPONSIVE_LIMIT, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { replace } from '@lagunovsky/redux-react-router'
import { useResetPasswordMutation } from '@login/api'
import {
    IDS,
    MODULE_VERSION,
    RESET_PASSWORD_FORMIK_INITIAL_VALUES,
    RESET_PASSWORD_VALIDATION_SCHEMA
} from '@login/constants'
import { ResetPasswordKeys } from '@login/type'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/login/Components'
import { useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import FixedImage from '@app/components/FixedImage'
import { useMediaQuery } from 'react-responsive'

const ResetPasswordPage = () => {
    const [resetPassword, resetPasswordMutation] = useResetPasswordMutation()

    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)
    const token = useAppSelector(selectToken)
    const dispatch = useAppDispatch()

    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const revalidateToken = useRevalidateToken()

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: RESET_PASSWORD_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: RESET_PASSWORD_VALIDATION_SCHEMA(
            strings.app?.message?.error.empty || ''
        ),
        onSubmit: async (values) => {
            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            // proceed with login and no need to setToken
            const isValid = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.login.moduleName,
                MODULE_TABLE.login.apiPaths.resetPassword.path,
                true
            )

            if (isValid && newToken.value) {
                resetPassword({
                    authToken: token.value,
                    data: {
                        username: values.username
                    }
                })
            }
        }
    })

    // const handleKeyDown = (
    //     e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
    //     fieldName?: MFAKeys
    // ) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault()
    //         const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
    //         if (nextInput) {
    //             nextInput.focus()
    //             nextInput.select()
    //         } else {
    //             handleSubmit()
    //         }
    //     }
    // }

    const UsernameInput = useMemo(() => {
        const fieldName: ResetPasswordKeys = 'username'

        return <div className={'form-group  mb-4'}>
            <label htmlFor={IDS.RESET_PASSWORD.USERNAME} className={'form-label'}>{
            `${ strings.login?.text?.form.username || '' } `
            }</label>
            <input type={'text'}
                value={values.username}
                className={`form-control ${ errors.username && 'border-danger' }`}
                name={fieldName}
                placeholder={strings.login?.text?.form.username || ''}
                id={IDS.RESET_PASSWORD.USERNAME}
                onChange={handleChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') { handleSubmit() }
                }}
                required />
            <div className={'form-text error'}>{
                errors.username ? errors.username : null
            }</div>
        </div>
    }, [strings, values.username, errors.username])

    useEffect(() => {
        if (resetPasswordMutation.data) {
            const data = resetPasswordMutation.data
            if (data.status === 'OK') {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })

                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.login.moduleName,
                    MODULE_TABLE.login.routes.login,
                    true
                )

                if (isValid) {
                    dispatch(replace(isValid.route))
                }
            } else if (data.status === 'NOT_OK') {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [resetPasswordMutation.data, activeModules.id])

    useEffect(() => {
        if (resetPasswordMutation.error) {
            const message = getErrorText(resetPasswordMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [resetPasswordMutation.error])

    const SubmitButton = useMemo(() => {
        const buttonContent = resetPasswordMutation.isLoading
            ? (
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{strings.app?.text?.submitting}</span>
                </div>
            )
            : strings.app?.text?.submit

        return (<button type={'submit'}
            disabled={resetPasswordMutation.isLoading}
            form={IDS.RESET_PASSWORD.FORM} className={'btn btn-primary btn-lg w-100'}>
            {buttonContent}
        </button>)
    }, [
        strings.login?.text,
        resetPasswordMutation
    ])

    const CancelButton = useMemo(() => {
        return (<button type={'button'}
            onClick={(e) => {
                e.preventDefault()

                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.login.moduleName,
                    MODULE_TABLE.login.routes.logout,
                    true
                )

                if (isValid) {
                    dispatch(replace(isValid.route))
                }
            }}
            className={'btn btn-primary btn-lg w-100'}>
            {strings.app?.text.cancel}
        </button>)
    }, [
        activeModules.arr,
        strings.login?.text
    ])

    const desktopResult = <>
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        {/* put header image here */}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-5'}>

                <div className={'justify-content-center row'}>
                    <div className={'col-10 px-0'}>
                        {/* status card */}
                        <div className={'status-card card border-0'}>
                            <h4 className={'mb-0'}>
                                {strings?.login?.text
                                    ?.title.reset_password}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                        <form id={IDS.RESET_PASSWORD.FORM} onSubmit={handleSubmit}>
                            {UsernameInput}
                        </form>
                    </div>
                </div>
                <div
                    className={[
                        'row align-items-center justify-content-center'
                    ].join(' ')}
                >
                    <div className={'col-6 col-md-4 col-lg-3'}>
                        {CancelButton}
                    </div>
                    <div className={'col-6 col-md-4 col-lg-3'}>
                        {SubmitButton}
                    </div>
                </div>

            </div>
        </div>

        <div className={'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'}>
            {MODULE_VERSION}
        </div>
    </>

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>
            <div className={'justify-content-center row'}>
                <div className={'col-12'}>
                    {/* status card */}
                    <div className={'status-card card border-0'}>
                        <span className={'fw-semibold'}>
                            {strings?.login?.text
                                ?.title.reset_password}
                        </span>
                    </div>
                </div>
            </div>

            <div className={'form-container'}>
                <div>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                            <form id={IDS.RESET_PASSWORD.FORM} onSubmit={handleSubmit}>
                                {UsernameInput}
                            </form>

                        </div>
                    </div>
                </div>
                <div>

                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-6 col-md-4 col-lg-3'}>
                            {CancelButton}
                        </div>
                        <div className={'col-6 col-md-4 col-lg-3'}>
                            {SubmitButton}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>

    return <div>
        <div className={'forget-password-page'}>
            {
                isMobile ? mobileResult : desktopResult
            }

        </div>
    </div>
}

export default ResetPasswordPage
