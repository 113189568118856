import { MODULE_TABLE } from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings, setUserLoginStatus } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { replace } from '@lagunovsky/redux-react-router'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { useDoLoginMutation, useGetTokenMutation } from '@login/api'
import { setIsFullscreenOverlay } from '@login/slices/slice.login'
import { useEffect } from 'react'

const LogoutPage = () => {
    const dispatch = useAppDispatch()

    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const token = useAppSelector(selectToken)
    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)

    const [, doLoginMutation] = useDoLoginMutation({
        fixedCacheKey: 'shared-login-key'
    })

    const [doGetToken] = useGetTokenMutation({
        fixedCacheKey: 'shared-getToken-key'
    })

    useEffect(() => {
        // THE SIDEBAR should stay hidden at all times here.
        initializeSidebarVisibility(false)
    }, [])

    useEffect(() => {
        doLoginMutation.reset()
        // by tim's request, empty the localStorage on logout.
        // i decided against it.
        // localStorage.clear()

        // record the recent value of sub.
        console.log('recording sub in logout')
        dispatch(
            setUserLoginStatus({
                sub: token.details.sub,
                key: 'logout'
            })
        )

        // make the doGetToken call right away.
        // BUT if you are a token auth. otherwise,
        // use the same token from guest.
        if (token.mode === 'auth') {
            doGetToken({
                data: { locale: navigator.language }
            })
            // set fullscreen overlay as logging out.
            // enable fullscreen
            dispatch(setIsFullscreenOverlay({
                show: true,
                text: strings.app?.text.authenticate.log_out || ''
            }))
        } else {
            // go to login page immediately
            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.login.moduleName,
                MODULE_TABLE.login.routes.login,
                true
            )

            if (isValid) {
                dispatch(replace(isValid.route))
            }
        }
    }, [activeModules])

    return <div></div>
}
export default LogoutPage
