function iosPWASplash (icon: string, color: string = 'white'): void {
    if (typeof icon !== 'string' || icon.length === 0) {
        throw new Error('Invalid icon URL provided')
    }

    const deviceWidth: number = screen.width
    const deviceHeight: number = screen.height
    const pixelRatio: number = window.devicePixelRatio || 1
    const canvas: HTMLCanvasElement = document.createElement('canvas')
    const canvas2: HTMLCanvasElement = document.createElement('canvas')
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')
    const ctx2: CanvasRenderingContext2D | null = canvas2.getContext('2d')

    if (!ctx || !ctx2) {
        throw new Error('Failed to get 2D rendering context')
    }

    const iconImage: HTMLImageElement = new Image()

    iconImage.onerror = () => {
        throw new Error('Failed to load icon image')
    }

    iconImage.src = icon
    iconImage.onload = () => {
        const iconSizew: number = iconImage.width / (3 / pixelRatio)
        const iconSizeh: number = iconImage.height / (3 / pixelRatio)

        canvas.width = deviceWidth * pixelRatio
        canvas2.height = canvas.width
        canvas.height = deviceHeight * pixelRatio
        canvas2.width = canvas.height
        ctx.fillStyle = color
        ctx2.fillStyle = color
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx2.fillRect(0, 0, canvas2.width, canvas2.height)

        const x: number = (canvas.width - iconSizew) / 2
        const y: number = (canvas.height - iconSizeh) / 2
        const x2: number = (canvas2.width - iconSizew) / 2
        const y2: number = (canvas2.height - iconSizeh) / 2

        ctx.drawImage(iconImage, x, y, iconSizew, iconSizeh)
        ctx2.drawImage(iconImage, x2, y2, iconSizew, iconSizeh)

        const imageDataURL: string = canvas.toDataURL('image/png')
        const imageDataURL2: string = canvas2.toDataURL('image/png')

        const appleTouchStartupImageLink: HTMLLinkElement = document.createElement('link')
        appleTouchStartupImageLink.rel = 'apple-touch-startup-image'
        appleTouchStartupImageLink.media = 'screen and (orientation: portrait)'
        appleTouchStartupImageLink.href = imageDataURL
        document.head.appendChild(appleTouchStartupImageLink)

        const appleTouchStartupImageLink2: HTMLLinkElement = document.createElement('link')
        appleTouchStartupImageLink2.rel = 'apple-touch-startup-image'
        appleTouchStartupImageLink2.media = 'screen and (orientation: landscape)'
        appleTouchStartupImageLink2.href = imageDataURL2
        document.head.appendChild(appleTouchStartupImageLink2)
    }
}

export default iosPWASplash
