import { selectActiveModules, selectStrings, setConfig } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'

import { Route, Routes } from 'react-router-dom'

import {
    MOBILE_RESPONSIVE_LIMIT,
    SIDEBAR_WIDTH,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'

import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import careplanV1Routes from '@careplan/routes'
import docV1Routes from '@doc/routes'
import fmtV1Routes from '@fmt/routes'
import libraryV1Routes from '@library/routes'
import { useGetModulesMutation } from '@login/api'
import loginV1Routes from '@login/routes'
import onboardingV1Routes from '@onboarding/routes'
import profileV1Routes from '@profile/routes'
import reasonWithMeV1Routes from '@reasonWithMe/routes'
import registrationV1Routes from '@registration/routes'

import { useGetLanguageFileMutation } from '@app/api.app'
import { MissingComponent } from '@app/components/MissingComponent'
import { setIsFullscreenOverlay } from '@login/slices/slice.login'
import { selectRenderRoutesInitial } from '@login/slices/slice.workflow'
import { SystemModule } from '@login/type'
import { selectShowMenuBar } from '@sidebar/slice'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'

/** The final array of route configurations with their corresponding components */
const RoutesGenerator = () => {
    /** codebase to get guest tokens and for both "guest" and "auth"
     * types, get the module configuration */
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)
    const showMenuBar = useAppSelector(selectShowMenuBar)
    const token = useAppSelector(selectToken)
    const renderRoutesInitial = useAppSelector(selectRenderRoutesInitial)

    // no validation necessary.
    const [getModules, getModulesMutation] = useGetModulesMutation()
    const [, getLanguageFileMutation] = useGetLanguageFileMutation({
        fixedCacheKey: 'shared-language-key'
    })

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        if (
            token.valid && token.id
        ) {
            getModules({
                authToken: token.value
            })
            // enable fullscreen
            dispatch(setIsFullscreenOverlay({
                show: true,
                text: strings.app?.text.getting_modules_data || ''
            }))
        }
    }, [token.id, token.valid])

    useEffect(() => {
        if (getModulesMutation.data?.status === 'OK' &&
            getLanguageFileMutation.data?.status === 'OK') {
            const filteredModules: SystemModule[] = _.chain(getModulesMutation.data.data)
                .groupBy('moduleShortName')
                .map(moduleGroup => _.maxBy(moduleGroup, 'moduleVersion'))
                .compact()
                .value()

            // update this now where you get the latest version of each module
            console.log('filtered values: ', filteredModules)
            dispatch(setConfig(filteredModules))
            // no need to set to false because getWorkflow will be the last
            // call to remove the overlay.
            // dispatch(setIsFullscreenOverlay({
            //     show: false,
            //     text: ''
            // }))
        }
    }, [getModulesMutation.data, getLanguageFileMutation.data])

    useEffect(() => {
        if (getModulesMutation.error) {
            const message = getErrorText(getModulesMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModulesMutation.error])

    const loginRoutes = useMemo(() =>
        loginV1Routes({ config: activeModules.arr }), [activeModules.id])
    const onboardingRoutes = useMemo(() =>
        onboardingV1Routes({ config: activeModules.arr }), [activeModules.id])
    const reasonWithMeRoutes = useMemo(() =>
        reasonWithMeV1Routes({ config: activeModules.arr }), [activeModules.id])
    const registrationRoutes = useMemo(() =>
        registrationV1Routes({ config: activeModules.arr }), [activeModules.id])
    const careplanRoutes = useMemo(() =>
        careplanV1Routes({ config: activeModules.arr }), [activeModules.id])
    const profileRoutes = useMemo(() =>
        profileV1Routes({ config: activeModules.arr }), [activeModules.id])
    const libraryRoutes = useMemo(() =>
        libraryV1Routes({ config: activeModules.arr }), [activeModules.id])
    const fmtRoutes = useMemo(() =>
        fmtV1Routes({ config: activeModules.arr }), [activeModules.id])
    const docRoutes = useMemo(() =>
        docV1Routes({ config: activeModules.arr }), [activeModules.id])

    const combinedRoutes = useMemo(() => {
        console.log('activeModules for rendering routes: ', activeModules)

        /* placing sidebar here is the beginning scope of useParams.
        it should not be deinitialized at all.rather, based on the
        value of router.location.pathname's showMenu property,
        you decide what should the defaultValue be. */
        /* <Sidebar/> */
        /* moved to index.tsx because you can use this: */
        /* import {  selectRouter } from '@app/app.store' */

        /* if the sidebar is set to shown, add margin */

        return <>
            {_.map(loginRoutes, (route, index) => (
                <Route
                    key={`loginRoute-${ index }`}
                    path={route.path}
                    element={
                        <div className={'route-content'} style={{
                            marginInlineStart: (showMenuBar.desktop && !isMobile)
                                ? SIDEBAR_WIDTH
                                : 0
                        }}>
                            {route.element}
                        </div>
                    }
                />
            ))}

            {_.map(registrationRoutes, (route, index) => (
                <Route
                    key={`registrationRoute-${ index }`}
                    path={route.path}
                    element={
                        <div className={'route-content'} style={{
                            marginInlineStart: (showMenuBar.desktop && !isMobile)
                                ? SIDEBAR_WIDTH
                                : 0
                        }}>
                            {route.element}
                        </div>
                    }
                />
            ))}

            {/* render routes ONLY after getWorkflow call is done at least once. */}
            {
                renderRoutesInitial === true
                    ? <>
                        {_.map(onboardingRoutes, (route, index) => (
                            <Route
                                key={`onboardingRoute-${ index }`}
                                path={route.path}
                                element={
                                    <div className={'route-content'} style={{
                                        marginInlineStart: (showMenuBar.desktop && !isMobile)
                                            ? SIDEBAR_WIDTH
                                            : 0
                                    }}>
                                        {route.element}
                                    </div>
                                }
                            />
                        ))}

                        {_.map(reasonWithMeRoutes, (route, index) => (
                            <Route
                                key={`reasonWithMeRoute-${ index }`}
                                path={route.path}
                                element={
                                    <div className={'route-content'} style={{
                                        marginInlineStart: (showMenuBar.desktop && !isMobile)
                                            ? SIDEBAR_WIDTH
                                            : 0
                                    }}>
                                        {route.element}
                                    </div>
                                }
                            />
                        ))}

                        {_.map(careplanRoutes, (route, index) => (
                            <Route
                                key={`careplanRoute-${ index }`}
                                path={route.path}
                                element={
                                    <div className={'route-content'} style={{
                                        marginInlineStart: (showMenuBar.desktop && !isMobile)
                                            ? SIDEBAR_WIDTH
                                            : 0
                                    }}>
                                        {route.element}
                                    </div>
                                }
                            />
                        ))}

                        {_.map(profileRoutes, (route, index) => (
                            <Route
                                key={`profileRoute-${ index }`}
                                path={route.path}
                                element={
                                    <div className={'route-content'} style={{
                                        marginInlineStart: (showMenuBar.desktop && !isMobile)
                                            ? SIDEBAR_WIDTH
                                            : 0
                                    }}>
                                        {route.element}
                                    </div>
                                }
                            />
                        ))}

                        {_.map(libraryRoutes, (route, index) => (
                            <Route
                                key={`libraryRoute-${ index }`}
                                path={route.path}
                                element={
                                    <div className={'route-content'} style={{
                                        marginInlineStart: (showMenuBar.desktop && !isMobile)
                                            ? SIDEBAR_WIDTH
                                            : 0
                                    }}>
                                        {route.element}
                                    </div>
                                }
                            />
                        ))}

                        {_.map(fmtRoutes, (route, index) => (
                            <Route
                                key={`fmtRoute-${ index }`}
                                path={route.path}
                                element={
                                    <div className={'route-content'} style={{
                                        marginInlineStart: (showMenuBar.desktop && !isMobile)
                                            ? SIDEBAR_WIDTH
                                            : 0
                                    }}>
                                        {route.element}
                                    </div>
                                }
                            />
                        ))}

                        {_.map(docRoutes, (route, index) => (
                            <Route
                                key={`docRoute-${ index }`}
                                path={route.path}
                                element={
                                    <div className={'route-content'} style={{
                                        marginInlineStart: (showMenuBar.desktop && !isMobile)
                                            ? SIDEBAR_WIDTH
                                            : 0
                                    }}>
                                        {route.element}
                                    </div>
                                }
                            />
                        ))}</>
                    : <></>
            }

        </>
    }, [activeModules.id, showMenuBar, isMobile, renderRoutesInitial])

    return <Routes>
        {/* render the routes after getting the workflow on mount */}

        {combinedRoutes}
        {/* show this IF the user is on AUTH and has activeModules.id */}
        <Route path={'*'} element={renderRoutesInitial === true ? <MissingComponent/> : <></> } />
    </Routes>
}

export default RoutesGenerator
