import { END_USER_COLORS } from '@stylesheet/brands/ikh/enduser/Colors'
import { ReasonWithMeConfig } from '@stylesheet/config/endUser/ReasonWithMe'

export const REASON_WITH_ME_CONFIG: ReasonWithMeConfig = {
    boxShadowColor: END_USER_COLORS.black,
    formControlInputBackgroundColor: END_USER_COLORS.gray_50,
    openList: {
        plusButton: {
            background: END_USER_COLORS.gray_200
        }
    }
}
