import { DESKTOP_RESPONSIVE_LIMIT, MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { TokenData } from '@app/types/type.token'
import LineChart from '@doc/components/careprofessionals/LineChart'
import AddInterface from '@doc/components/patients/add/AddInterface'
import { selectPatientMenu, setPatientMenu } from '@doc/slice'
import { GetHCPCardsResponse } from '@doc/type'
import {
    FloatingArrow,
    FloatingFocusManager,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
    useHover,
    useId,
    useInteractions,
    useTransitionStyles
} from '@floating-ui/react'
import _ from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

const InformationPopover = ({ boundary }: {boundary: HTMLDivElement | null}) => {
    const arrowRef = useRef(null)
    const strings = useAppSelector(selectStrings)
    const [visible, setVisible] = useState(false)

    const { refs, floatingStyles, context } = useFloating({
        open: visible,
        placement: 'top',
        onOpenChange: (isOpen, event, reason) => {
            setVisible(isOpen)
            // event && console.log(event)
            // reason && console.log(reason)
        },
        middleware: [
            offset(10),
            flip({
                fallbackAxisSideDirection: 'end',
                boundary: boundary || 'clippingAncestors'
            }),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate

    })
    const { styles } = useTransitionStyles(context)

    const hover = useHover(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover
    ])

    const headingId = useId()

    return <>
        <div className={'icon d-inline-block ps-2'}
            ref={refs.setReference} {...getReferenceProps()}>
            <i className={'fa-light fa-circle-info'}
                aria-hidden={'true'}></i>
        </div>
        { visible && <FloatingFocusManager context={context} modal={false}>
            <div
                className={'floating-ui information'}
                ref={refs.setFloating}
                style={{
                    ...floatingStyles,
                    ...styles
                }}
                aria-labelledby={headingId}
                {...getFloatingProps()}
            >
                <FloatingArrow ref={arrowRef} context={context}
                />
                {

                    <div><span>{
                        strings.doc?.text.careprofessionals.hcp_cards.information
                    }</span><span>{
                        'Information about Add. Temporary'
                    }</span></div>
                }
            </div>

        </FloatingFocusManager>}

    </>
}
interface ComponentProps {
    floatingUiBoundary: HTMLDivElement | null,
    fetchGetPatientData: (token: TokenData) => () => void
    getHCPCardsResponse: {
        total:{
        isLoading: boolean;
        isSuccess: boolean;
        response: GetHCPCardsResponse | undefined;
    },
        careplan:{
        isLoading: boolean;
        isSuccess: boolean;
        response: GetHCPCardsResponse | undefined;
    },
        progress:{
        isLoading: boolean;
        isSuccess: boolean;
        response: GetHCPCardsResponse | undefined;
    }
    }
}

const HCPCards = ({
    floatingUiBoundary, fetchGetPatientData, getHCPCardsResponse
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const dispatch = useAppDispatch()
    const patientMenu = useAppSelector(selectPatientMenu)

    // 1,2,4
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const isMedium = useMediaQuery({
        minWidth: MOBILE_RESPONSIVE_LIMIT,
        maxWidth: DESKTOP_RESPONSIVE_LIMIT
    })

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    const HCPCardsSection = useMemo(() => {
        // console.log(getHCPCardsResponse)
        const data: {
            total:{
                currentNumber: number;
                previousNumber: number;
                lineChart: number[];
            }
            , careplan:{
                currentNumber: number;
                previousNumber: number;
                lineChart: number[];
            }, progress:{
                currentNumber: number;
                previousNumber: number;
                lineChart: number[];
            }
        } = {
            total: {
                currentNumber: getHCPCardsResponse.total.response?.data.currentNumber || 0,
                previousNumber: getHCPCardsResponse.total.response?.data.previousNumber || 0,
                lineChart: getHCPCardsResponse.total.response?.data.lineChart || []
            },
            careplan: {
                currentNumber: getHCPCardsResponse.careplan.response?.data.currentNumber || 0,
                previousNumber: getHCPCardsResponse.careplan.response?.data.previousNumber || 0,
                lineChart: getHCPCardsResponse.careplan.response?.data.lineChart || []
            },
            progress: {
                currentNumber: getHCPCardsResponse.progress.response?.data.currentNumber || 0,
                previousNumber: getHCPCardsResponse.progress.response?.data.previousNumber || 0,
                lineChart: getHCPCardsResponse.progress.response?.data.lineChart || []
            }
        }

        const changes: {
            total:number
            careplan:number
            progress:number
        } = {
            total: _.floor((data.total.currentNumber - data.total
                .previousNumber) / data
                .total.previousNumber * 100),
            careplan: _.floor((data.careplan.currentNumber - data.careplan
                .previousNumber) / data
                .careplan.previousNumber * 100),
            progress: _.floor((data.progress.currentNumber - data.progress
                .previousNumber) / data
                .progress.previousNumber * 100)
        }

        const color: {
            total:string
            careplan:string
            progress:string
        } = {
            total: _.gt(changes.total, 0)
                ? 'gt'
                : _.lt(changes.total, 0)
                    ? 'lt'
                    : '',
            careplan: _.gt(changes.careplan, 0)
                ? 'gt'
                : _.lt(changes.careplan, 0)
                    ? 'lt'
                    : '',
            progress: _.gt(changes.progress, 0)
                ? 'gt'
                : _.lt(changes.progress, 0)
                    ? 'lt'
                    : ''
        }

        const totalCard = <div className={'col'}>
            <div className={'abnormal-interface hcCards pt-4 px-0 pb-0 h-100'}>
                <h4 className={'mb-0 fw-semibold text-start title px-8 '} >
                    {strings.doc?.text.careprofessionals.hcp_cards.total_patients}
                </h4>
                <div className={'align-items-baseline row gx-3 mt-12 px-8'}>
                    <h1 className={'col-auto mb-0 fw-bold current-number'} >
                        { data.total.currentNumber}
                    </h1>
                    <h6 className={['col-auto mb-0', color.total].join(' ')}>{
                        [
                            changes.total,
                            '%'
                        ].join('')
                    }</h6>
                    <h6 className={['col-auto mb-0', color.total].join(' ')}>
                        <i className={[
                            'fa-solid ', (data.total.currentNumber || 0) > (data.total
                                .previousNumber || 0)
                                ? 'fa-arrow-up'
                                : 'fa-arrow-down'
                        ].join('')}></i>
                    </h6>
                </div>
                <LineChart arr={data.total.lineChart} color={color.total}/>
            </div>
        </div>
        const careplanCard = <div className={'col'}>
            <div className={'abnormal-interface hcCards pt-4 px-0 pb-0 h-100'}>
                <h4 className={'mb-0 fw-semibold text-start title px-8'} >
                    {strings.doc?.text.careprofessionals.hcp_cards.patients_with_careplan}
                </h4>
                <div className={'align-items-baseline row gx-3 mt-12 px-8'}>
                    <h1 className={'col-auto mb-0 fw-bold current-number'} >
                        { data.careplan.currentNumber}
                    </h1>
                    <h6 className={['col-auto mb-0', color.careplan].join(' ')}>{
                        [
                            changes.careplan,
                            '%'
                        ].join('')
                    }</h6>
                    <h6 className={['col-auto mb-0', color.careplan].join(' ')}>
                        <i className={[
                            'fa-solid ', (data.careplan.currentNumber || 0) > (data.careplan
                                .previousNumber || 0)
                                ? 'fa-arrow-up'
                                : 'fa-arrow-down'
                        ].join('')}></i>
                    </h6>
                </div>
                <LineChart arr={data.careplan.lineChart} color={color.careplan}/>
            </div>
        </div>
        const progressCard = <div className={'col'}>
            <div className={'abnormal-interface hcCards pt-4 px-0 pb-0 h-100'}>
                <h4 className={'mb-0 fw-semibold text-start title px-8'} >
                    {strings.doc?.text.careprofessionals.hcp_cards.patients_with_progress}
                </h4>
                <div className={'align-items-baseline row gx-3 mt-12 px-8'}>
                    <h1 className={'col-auto mb-0 fw-bold current-number'} >
                        { data.progress.currentNumber}
                    </h1>
                    <h6 className={['col-auto mb-0', color.progress].join(' ')}>{
                        [
                            changes.progress,
                            '%'
                        ].join('')
                    }</h6>
                    <h6 className={['col-auto mb-0', color.progress].join(' ')}>
                        <i className={[
                            'fa-solid ', (data.progress.currentNumber || 0) > (data.progress
                                .previousNumber || 0)
                                ? 'fa-arrow-up'
                                : 'fa-arrow-down'
                        ].join('')}></i>
                    </h6>
                </div>
                <LineChart arr={data.progress.lineChart} color={color.progress}/>
            </div>
        </div>

        const addPatientCard = <div className={'col'}>
            {/* add patient shortcut */}
            <div className={'abnormal-interface hcCards py-4 h-100 d-flex flex-column'}>
                <div className={'flex-grow-0 row align-items-center justify-content-between mb-4'}>
                    <div className={'col-auto'}>
                        <h6 className={'mb-0 fw-semibold '} >
                            {strings.doc?.text.careprofessionals.hcp_cards
                                .add_patient_card.title || ''}
                        </h6>
                    </div>
                    <div className={'col-auto'}>
                        <InformationPopover boundary={floatingUiBoundary}/>
                    </div>
                </div>
                <span className={'flex-grow-1 d-inline-block pb-4 fw-light'}>{
                    strings.doc?.text.careprofessionals.hcp_cards.add_patient_card.body || ''
                }</span>
                <button
                    type={'button'}
                    className={'align-self-center btn btn-primary flex-grow-0 py-3 rounded-5 w-75'}
                    onClick={ () => {
                        // open up popup modal.
                        dispatch(setPatientMenu({
                            ...patientMenu,
                            showAddModal: true
                        }))
                    }}>
                    {strings.app?.text.add || ''}
                </button>
                <AddInterface
                    fetchData={fetchGetPatientData}
                />
            </div>
        </div>

        return <div className={['g-3 mb-4 mt-3 row', isMobile
            ? 'rows-col-12'
            : isMedium ? 'rows-col-6' : 'row-cols-4'].join(' ')}>
            {addPatientCard}
            {
                getHCPCardsResponse.total.isLoading
                    ? LoadingContent
                    : getHCPCardsResponse.total.isSuccess ? totalCard : ''
            }
            {
                getHCPCardsResponse.careplan.isLoading
                    ? LoadingContent
                    : getHCPCardsResponse.careplan.isSuccess ? careplanCard : ''
            }
            {
                getHCPCardsResponse.progress.isLoading
                    ? LoadingContent
                    : getHCPCardsResponse.progress.isSuccess ? progressCard : ''
            }

        </div>
    }, undefined)

    return HCPCardsSection
}

export default HCPCards
