
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { GPACareplanStepIdDetails } from '@doc/type'
import { QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const List = ({
    reasoningObj
}: ComponentProps) => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue

    const input = useMemo(() => {
        /**
         * render a list of checkboxes BUT disable the unchecked boxes
         * once the value of questionAnswersAllowed has reached.
         * expected answers are the following:
         * - if questionAnswersAllowed === 1, answerValue should be a string.
         * - if questionAnswersAllowed > 1, should be [string1, string2]
         */

        // console.log(`answers so far: ${ answerValue }`)

        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', reasoningObj.id
            ].join('')

            const isChecked = _.includes(
                answerValue,
                obj.acqValue
            )

            /** check if it is an array AND if the number of values
             * exceed the number of answers allowed.
             * Make sure you exclude checked boxes
             */
            const isDisabled = _.isArray(answerValue)
                // this will only be the case if the questionAnswersAllowed is truthy
                //  AND greater than one now. You don't want to deselect the cell every time.
                ? questionData?.questionAnswersAllowed
                    ? answerValue.length >= (questionData?.questionAnswersAllowed || 0)
                        ? questionData.questionAnswersAllowed >= 1
                        : false
                    : false
                : false

            // if there are too many choices, use col-6
            return <div className={'col'} key={key}>
                <input type={'checkbox'}
                    className={[
                        'btn-check',
                        isDisabled && !isChecked ? 'disabled' : ''
                    ].join(' ')}
                    autoComplete={'off'}
                    id={key}
                    checked={isChecked}
                    // use class name instead.
                    // disabled={isDisabled && !isChecked}
                    onChange={() => {
                    }}
                />
                <label className={'btn btn-dummy'} htmlFor={key}>

                    <div className={'question-checkbox'}>
                        <div className={'card justify-content-center px-3 py-2'}>

                            <div className={'d-flex flex-column'}>
                                <div className={'d-flex align-items-center'}>
                                    <div className={'p text-start'}>
                                        {obj.acqName}
                                    </div>
                                    {
                                        isChecked
                                            ? <div className={'question-checkmark ms-auto'}>
                                                <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                            </div>
                                            : <div className={'question-plussign ms-auto'}>
                                                <i className={'fa-light fa-plus mx-auto'}></i>
                                            </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>

                </label>
            </div>
        }

        return <div className={'container-fluid'}>
            <div className={'row row-cols-1 btn-group-checkbox-list mt-3'}
                role={'group'}>
                {
                    _.map(questionData?.questionAnswers || [], renderInputs)
                }
            </div>
        </div>
    }, [])

    return <div className={['question-list', isMobile ? 'px-0' : 'px-4'].join(' ')}>
        {input}
    </div>
}

export default List
