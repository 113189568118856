import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import { GetOverallProgressRequest, GetOverallProgressResponse } from '@careplan/type'
import {
    ChangePasswordRequest,
    ChangePasswordResponse,
    DeleteCareplanRequest,
    DeleteCareplanResponse,
    DoContactRequest,
    DoContactResponse,
    GetCareprofessionalsRequest,
    GetCareprofessionalsResponse,
    GetContactRequest,
    GetContactResponse,
    GetMyTherapeuticsRequest,
    GetMyTherapeuticsResponse,
    GetSettingsArticleRequest,
    GetSettingsArticleResponse,
    GetSettingsListRequest,
    GetSettingsListResponse,
    SetMFARequest,
    SetMFAResponse,
    ToggleEmailNotificationsRequest,
    ToggleEmailNotificationsResponse,
    TogglePushNotificationsRequest,
    TogglePushNotificationsResponse,
    UpdateProfilePictureRequest,
    UpdateProfilePictureResponse,
    ValidateMFARequest,
    ValidateMFAResponse
} from '@profile/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
    GetPersonalDetailsRequest,
    GetPersonalDetailsResponse,
    UpdatePersonalDetailsRequest,
    UpdatePersonalDetailsResponse
} from '@registration/type'
import _ from 'lodash'
export const profileV10Api = createApi({
    reducerPath: 'profileV10Api',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        updateProfilePicture: builder
            .mutation<UpdateProfilePictureResponse, UpdateProfilePictureRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<UpdateProfilePictureRequest, 'data'> = {
                        data: body.data
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths.updateProfilePicture.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData.data
                    }
                }
            }),
        getCareprofessionals: builder
            .mutation<GetCareprofessionalsResponse, GetCareprofessionalsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetCareprofessionalsRequest = {}

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths
                                .getUserCareProfessionals.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getMyTherapeutics: builder
            .mutation<GetMyTherapeuticsResponse, GetMyTherapeuticsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetMyTherapeuticsRequest = {}

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths.getMyTherapeutics.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        deleteCareplan: builder
            .mutation<DeleteCareplanResponse, DeleteCareplanRequest & TokenAuth>({
                query: (body) => {
                    const formData: DeleteCareplanRequest = {
                        data: {
                            myCareplanId: body.data.myCareplanId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths.deleteCareplan.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        validateMFA: builder.mutation<ValidateMFAResponse,
        ValidateMFARequest & TokenAuth>({
            query: (body) => {
                const formData: ValidateMFARequest = {
                    data: {
                        token: body.data.token
                    }
                }

                return {
                    url: _.trimStart(
                        MODULE_TABLE.profile.apiPaths.validateMFA.path, '/'
                    ),
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`
                    },
                    body: formData
                }
            }
        }),
        setMFA: builder.mutation<SetMFAResponse, SetMFARequest & TokenAuth>({
            query: (body) => {
                const formData: SetMFARequest = {
                    data: {
                        mfaToggle: body.data.mfaToggle
                    }
                }

                return {
                    url: _.trimStart(
                        MODULE_TABLE.profile.apiPaths.setMFA.path, '/'
                    ),
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`
                    },
                    body: formData
                }
            }
        }),
        changePassword: builder.mutation<ChangePasswordResponse,
        ChangePasswordRequest & TokenAuth>({
            query: (body) => {
                const formData: ChangePasswordRequest = {
                    data: {
                        currentPassword: body.data.currentPassword,
                        newPassword: body.data.newPassword,
                        newPassword2: body.data.newPassword2
                    }
                }

                return {
                    url: _.trimStart(
                        MODULE_TABLE.profile.apiPaths.changePassword.path, '/'
                    ),
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`
                    },
                    body: formData
                }
            }
        }),
        getSettingsList: builder
            .mutation<GetSettingsListResponse, GetSettingsListRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetSettingsListRequest = {
                        data: {}
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths.getSettingsList.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getSettingsArticle: builder
            .mutation<GetSettingsArticleResponse, GetSettingsArticleRequest
            & TokenAuth>({
                query: (body) => {
                    const formData: GetSettingsArticleRequest = {
                        data: {
                            listId: body.data.listId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths
                                .getSettingsArticle.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getContact: builder
            .mutation<GetContactResponse, GetContactRequest
            & TokenAuth>({
                query: (body) => {
                    const formData: GetContactRequest = {
                        data: {}
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths
                                .getContact.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        doContact: builder
            .mutation<DoContactResponse, DoContactRequest
            & TokenAuth>({
                query: (body) => {
                    const formData: DoContactRequest = {
                        data: {
                            categoryId: body.data.categoryId,
                            subject: body.data.subject,
                            message: body.data.message
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths
                                .doContact.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        togglePushNotifications: builder
            .mutation<TogglePushNotificationsResponse, TogglePushNotificationsRequest
            & TokenAuth>({
                query: (body) => {
                    const formData: TogglePushNotificationsRequest = {
                        data: {
                            subscriberId: body.data.subscriberId,
                            pushNotifications: body.data.pushNotifications
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths
                                .togglePushNotifications.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        toggleEmailNotifications: builder
            .mutation<ToggleEmailNotificationsResponse, ToggleEmailNotificationsRequest
            & TokenAuth>({
                query: (body) => {
                    const formData: ToggleEmailNotificationsRequest = {
                        data: {
                            emailNotifications: body.data.emailNotifications
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths
                                .toggleEmailNotifications.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getOverallProgress: builder
            .mutation<GetOverallProgressResponse, GetOverallProgressRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetOverallProgressRequest = {
                        data: {}
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.profile
                                .apiPaths.getOverallProgress.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPersonalDetails: builder
            .mutation<GetPersonalDetailsResponse, GetPersonalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetPersonalDetailsRequest = {
                        data: {
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths
                                .getPersonalDetails.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updatePersonalDetails: builder
            .mutation<UpdatePersonalDetailsResponse, UpdatePersonalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdatePersonalDetailsRequest = {
                        data: {
                            firstName: body.data.firstName,
                            lastName: body.data.lastName,
                            email: body.data.email
                            // dob: body.data.dob
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.profile.apiPaths
                                .updatePersonalDetails.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useUpdateProfilePictureMutation,
    useGetCareprofessionalsMutation,
    useGetMyTherapeuticsMutation,
    useDeleteCareplanMutation,
    useSetMFAMutation,
    useValidateMFAMutation,
    useChangePasswordMutation,
    useGetSettingsListMutation,
    useGetSettingsArticleMutation,
    useGetContactMutation,
    useDoContactMutation,
    useToggleEmailNotificationsMutation,
    useTogglePushNotificationsMutation,
    useGetOverallProgressMutation,
    useGetPersonalDetailsMutation,
    useUpdatePersonalDetailsMutation
} = profileV10Api
