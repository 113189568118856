import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { BuildingBlock, BuildingBlockLinkArticle } from '@careplan/type'
import { CardImageWrapper } from '@stylesheet/globalStyles/group/endUser/recoveryPlan/Components'
import { useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    buildingBlock: BuildingBlock
}

const ReadOnlyLinkArticle = ({
    buildingBlock
}:ComponentProps) => {
    const ref = useRef<HTMLDivElement>(null)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const colImageClassName = 'col'
    const colContentClassName = 'col meta-info'
    const imageWrapperWidth = '100%'
    const imageWrapperHeight = 81

    const blockData: BuildingBlockLinkArticle = buildingBlock
        .buildingBlockValue as BuildingBlockLinkArticle

    const cardBody = <div className={'card-body position-relative'}>
        <div className={[
            'row row-cols-1', isMobile ? 'g-3' : ''
        ].join(' ')}>
            <div className={colImageClassName}>
                <CardImageWrapper className={''}
                    url={blockData?.articleThumbnail || ''}
                    style={{
                        width: imageWrapperWidth,
                        height: imageWrapperHeight
                    }}
                >
                </CardImageWrapper>
            </div>
            <div className={[
                colContentClassName,
                !isMobile ? 'mb-3 mt-3' : ''
            ].join(' ')}>
                <h6 className={'mb-0'}>
                    {blockData.articleTitle}
                </h6>
                <span>
                    {blockData.articleDescription}
                </span>
            </div>
        </div>
    </div>

    const result = (<div
        className={'align-items-center justify-content-start row'}
        ref={ref}
    >
        <div className={'col-6'} >
            {/* card-activity will be something else soon */}
            <div className={'card careplan-card'}>
                {cardBody}
            </div>

        </div>
    </div>
    )

    return result
}

export default ReadOnlyLinkArticle
