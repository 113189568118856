import { MODULE_TABLE } from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { replace } from '@lagunovsky/redux-react-router'
import { useValidateRouteAll } from '@login/MutationProvider/validateRouteAll'

import { selectCurrentWorkflowStep } from '@login/slices/slice.workflow'
import EnableNotifications from '@registration/components/EnableNotifications'
import Registration from '@registration/components/Registration'
import ReviewSection from '@registration/components/ReviewSection'
import SetMfa from '@registration/components/SetMfa'
import SuccessPage from '@registration/components/SuccessPage'
import TreatmentPrompt from '@registration/components/TreatmentPrompt'
import { useMemo } from 'react'

const RegistrationWorkflow = () => {
    // params we are waiting for is uuid and id respectively.
    // seems to only work on mount in components after setting up the router.
    // tried at CommonWorkflow.tsx and failed.
    const dispatch = useAppDispatch()
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)

    const validateRouteAll = useValidateRouteAll()
    const activeModules = useAppSelector(selectActiveModules)
    const token = useAppSelector(selectToken)

    /**
     * NEW: you can also go to this component through getWorkflow after logging
     * in for example.
     */

    /**
     * even if the localStorage was modified where they are expecting
     * a different workflow, because of the token of the user, they'll
     * get nothing.
     */

    const redirectProperly = () => {
        let routeToUse = token.details.roid

        if (['/', ''].includes(token.details.roid)) {
            console.warn('Default route changed to login.')
            routeToUse = MODULE_TABLE.login.routes.login
        }

        const defaultRoute = validateRouteAll(activeModules.arr,
            routeToUse, false)
        console.log('route: ', defaultRoute)
        if (defaultRoute.route) {
            dispatch(replace(routeToUse))
        }
    }

    // to deal with the ul in the footer, just add the number
    // of workflows in the state. could be an array of that data
    // sooner or later.

    // so basically you need a currentWorkflowStep before going here.
    // if in the event that you don't, then you will be redirected immediately.

    // now a component that renders what we need.
    const ActiveComponent = useMemo(() => {
        let result = <></>

        // do this if the stepType is route.

        if (currentWorkflowStep?.stepType === 'route') {
            switch (currentWorkflowStep?.stepContent) {
                case 'createAccount':
                    result = <Registration />
                    break
                case 'enableNotifications':
                    result = <EnableNotifications />
                    break
                case 'personalInformation':
                    result = <ReviewSection />
                    break
                case 'treatmentInformation':
                    result = <TreatmentPrompt />
                    break
                case 'succesPage':
                    result = <SuccessPage />
                    break
                case 'setMfa':
                    result = <SetMfa />
                    break
                default:{
                    console.warn('no step content met. going to default route.')
                    redirectProperly()
                    break
                }
            }
        } else if (currentWorkflowStep?.stepType === 'reasoningset') {
            // at this point, you shouldn't be longer in the registration
            // process. you should be in a useEffect where you will be
            // redirected to the reasoningWithMe interface.
        } else if (currentWorkflowStep?.stepType === undefined) {
            console.warn('no step type met. going to default route.')
            redirectProperly()
        }

        return result
    }, [currentWorkflowStep])

    return <>
        {ActiveComponent}
    </>
}

export default RegistrationWorkflow
