import { OpenListActions, OpenListInputs, OpenListValues } from '@reasonWithMe/type'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
    findPreviousInput: any,
    formsDispatch: (value: OpenListActions) => void
    form: OpenListValues
}

const Checkbox = ({
    answerType, outerIndex, innerIndex,
    formInput, findPreviousInput, formsDispatch, form
}: ComponentProps) => {
    return <input
        type={'checkbox'}
        name={`${ answerType }-${ outerIndex }-${ innerIndex }`}

        disabled={(findPreviousInput && !findPreviousInput?.answerEditable) ||
                         !formInput.answerEditable}
        checked={formInput.userTypedAnswer as boolean}
        onChange={(e) => {
            if (formInput.answerEditable) {
                formsDispatch({
                    type: 'UPDATE_FORM',
                    id: form.id,
                    columnOrder: Number(formInput
                        .answerValue || 0),
                    value: !formInput.userTypedAnswer
                })
            }
        }}
    />
}

export default Checkbox
