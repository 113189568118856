import React, { useEffect, useMemo, useReducer, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectDateFormats, selectStrings } from '@app/slices/slice.app'

import Pagination from '@app/components/Pagination'
import {
    Deviation,
    DeviationDataActions,
    DeviationDataState,
    GetPatientDeviationsResponse
} from '@doc/type'
import { format, fromUnixTime } from 'date-fns'
import produce from 'immer'
import _ from 'lodash'
import { v4 as uuidV4 } from 'uuid'

interface ComponentProps {
    patientDeviationsData: GetPatientDeviationsResponse['data'] | undefined,
    isLoading: boolean
    isSuccess: boolean
}
const Abnormalities = ({ patientDeviationsData, isLoading, isSuccess } : ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const dateFormats = useAppSelector(selectDateFormats)

    const [currentPage, setCurrentPage] = useState(0)

    const [deviationDataState, deviationDataDispatch] = useReducer((
        state: DeviationDataState, action: DeviationDataActions
    ) => {
        switch (action.type) {
            case 'SET_DATA':{
                return produce(state, draft => {
                    draft.data = action.value
                })
            }
            case 'SET_SKIP':{
                return produce(state, draft => {
                    draft.skip = action.value
                })
            }
            case 'SET_LIMIT':{
                return produce(state, draft => {
                    draft.limit = action.value
                })
            }
            case 'SET_SORT':{
                return produce(state, draft => {
                    draft.sort = action.value
                })
            }
            case 'SET_ARRANGE':{
                return produce(state, draft => {
                    draft.arrange = action.value
                })
            }
            case 'SET_CHECKED':{
                return produce(state, draft => {
                    const found = _.find(draft.data, o => {
                        return (
                            o.id === action.value.id
                        )
                    })

                    if (found) {
                        found.isChecked = action.value.value
                    }
                })
            }
        }
    }, {
        data: [],
        skip: 0,
        limit: 10,
        sort: 'isComplete',
        arrange: 'asc'
    })

    useEffect(() => {
        deviationDataDispatch({
            type: 'SET_DATA',
            value: _.map(patientDeviationsData?.deviationData, (o) => {
                return {
                    ...o,
                    isChecked: false,
                    id: uuidV4()
                }
            })
        })
    }, [patientDeviationsData])

    const sortDeviations = (
        deviations: (Deviation & {isChecked: boolean, id:string})[],
        field: keyof (Deviation & {isChecked: boolean, id:string}),
        order: 'asc' | 'desc' = 'asc'
    ) => {
        if (field === 'riskLevel') {
            return _.orderBy(deviations,
                [dev => _.orderBy(dev.riskLevel, ['dataPoint'], order)],
                order
            )
        } else if (field === 'dataLabels') {
            return _.orderBy(deviations,
                [dev => _.orderBy(dev.dataLabels, ['labelName'], order)[0]?.labelName],
                order
            )
        } else {
            return _.orderBy(deviations, field, order)
        }
    }

    const toggleSortAndArrange = (key: keyof (Deviation & {isChecked: boolean, id:string})) => {
        // toggle sort.
        if (deviationDataState.sort !== key) {
            deviationDataDispatch({
                type: 'SET_SORT',
                value: key
            })

            // but also set the arrange to asc.
            deviationDataDispatch({
                type: 'SET_ARRANGE',
                value: 'asc'
            })
        } else {
            // toggle the arrange
            deviationDataDispatch({
                type: 'SET_ARRANGE',
                value: deviationDataState.arrange === 'asc'
                    ? 'desc'
                    : 'asc'
            })
        }
    }

    const abnormalities = useMemo(() => {
        const sorted = sortDeviations(
            deviationDataState.data,
            deviationDataState.sort,
            deviationDataState.arrange
        ).slice(deviationDataState
            .skip, deviationDataState
            .skip + deviationDataState
            .limit)

        const sortArrows = <i
            className={[
                'fa-solid fa-angles-up-down',
                deviationDataState.arrange === 'asc'
                    ? ''
                    : 'fa-rotate-180'
            ].join(' ')
            }>
        </i>

        const tableHeaders = <tr>
            <th>
                <input type={'checkbox'}
                    className={'form-check-input'}
                    defaultChecked={false}
                    onChange={(e) => {
                        if (e.target.checked) {
                            _.forEach(deviationDataState.data, (o) => {
                                deviationDataDispatch({
                                    type: 'SET_CHECKED',
                                    value: {
                                        id: o.id,
                                        value: true
                                    }
                                })
                            })
                        } else {
                            _.forEach(deviationDataState.data, (o) => {
                                deviationDataDispatch({
                                    type: 'SET_CHECKED',
                                    value: {
                                        id: o.id,
                                        value: false
                                    }
                                })
                            })
                        }
                    }}
                />
            </th>
            <th onClick={() => {
                toggleSortAndArrange('isComplete')
            }}>
                <span className={'me-2 clickable'}>
                    {strings.app?.text.complete}</span>
                {/* only show arrows IF this is set */}
                {
                    deviationDataState.sort === 'isComplete' && sortArrows
                }
            </th>
            <th onClick={() => {
                toggleSortAndArrange('timestamp')
            }}>
                <span className={'me-2 clickable'}>
                    {strings.doc?.text.patient.patient_progress.table.day}</span>
                {/* only show arrows IF this is set */}
                {
                    deviationDataState.sort === 'timestamp' && sortArrows
                }
            </th>
            <th onClick={() => {
                toggleSortAndArrange('deviation')
            }}>
                <span className={'me-2 clickable'}>{strings.doc?.text.patient
                    .ikherstel_records.abnormalities.deviation}</span>
                {/* only show arrows IF this is set */}
                {
                    deviationDataState.sort === 'deviation' && sortArrows
                }
            </th>
            <th onClick={() => {
                // sort by datapoint.
                toggleSortAndArrange('riskLevel')
            }}>
                <span className={'me-2 clickable'}>{strings.doc?.text
                    .careprofessionals.abnormalities_table.risk}</span>
                {/* only show arrows IF this is set */}
                {
                    deviationDataState.sort === 'riskLevel' && sortArrows
                }
            </th>
            <th onClick={() => {
                // sort by datapoint.
                toggleSortAndArrange('dataLabels')
            }}>
                <span className={'me-2'}>{strings.doc?.text
                    .careprofessionals.abnormalities_table.symptoms}</span>
                {
                    deviationDataState.sort === 'dataLabels' && sortArrows
                }
            </th>
        </tr>

        const isTableEmpty = <small className={'d-block text-center py-2'}>
            <span >{
                strings.app?.message.error.empty_table || ''
            }</span>
        </small>

        const tableBody = sorted.length
            ? _.map(sorted, (obj) => {
                const key = obj.id

                const resolveButton = <button type={'button'} onClick={async () => {
                }} className={'btn btn-primary btn-sm w-100'}>
                    {strings.doc?.text.patient.ikherstel_records.abnormalities.resolve}
                </button>

                const formattedDate = format(fromUnixTime(obj.timestamp), dateFormats.format2)

                return <React.Fragment key={key}>
                    <tr onClick={() => {}}>
                        <td className={[
                            'risk-border',
                            (obj.riskLevel.dataColor)
                        ].join(' ')}>
                            <div className={'align-items-center flex-nowrap gx-3 row'}>
                                <div className={'col-auto'}>
                                    <input type={'checkbox'}
                                        className={'form-check-input'}
                                        checked={obj.isChecked}
                                        onChange={() => {
                                            deviationDataDispatch({
                                                type: 'SET_CHECKED',
                                                value: {
                                                    id: obj.id,
                                                    value: !obj.isChecked
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className={'col-auto'}>
                                    {/* if isChecked is true, render a small
                                    // button named resolve */}
                                    {
                                        obj.isChecked && resolveButton
                                    }
                                </div>
                            </div>

                        </td>
                        <td>
                            <span className={'me-2'}>{obj.isComplete
                                ? strings.app?.text.yes
                                : strings.app?.text.no}</span>
                        </td>
                        <td>
                            <span className={'me-2'}>{formattedDate}</span>
                        </td>
                        <td>
                            <span className={'me-2'}>{obj.deviation}</span>
                        </td>
                        <td>
                            <div className={[
                                'risk-container',
                                (obj.riskLevel.dataColor)
                            ].join(' ')}>
                                {
                                    obj.riskLevel.dataColor === 'red' && <i className={
                                        'fa-sharp fa-solid fa-circle-exclamation'
                                    }></i>
                                }

                                <div className={[
                                    'fraction',
                                    obj.riskLevel.dataColor === 'red' && 'ms-2'
                                ].join(' ')}>
                                    <span className={'numerator'}>{
                                        obj.riskLevel.dataPoint}</span>
                                    <span>{'/'}</span>
                                    <span className={'denominator'}>{
                                        obj.riskLevel.scaleUpper}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={'cells'}>
                                {
                                    _.map(obj.dataLabels, (o, index) => {
                                        return <div key={`cell-${ index }`} className={[
                                            (o.labelColor)
                                        ].join('')}>
                                            {o.labelName}
                                        </div>
                                    })
                                }
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            })
            : isSuccess && <tr><td colSpan={6}>{isTableEmpty}</td></tr>

        const pagination = <div className={'container-fluid pb-4 pt-6 px-3'}>
            <div className={'row justify-content-center'}>
                <div className={'col-auto'}>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPageState={setCurrentPage}
                        limit={deviationDataState.limit ||
                            patientDeviationsData?.deviationData.length || 1}
                        skip={deviationDataState.skip}
                        totalRecords={patientDeviationsData?.deviationData.length || 0}
                    />
                </div>
            </div>
        </div>

        const isLoadingContent = <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>

        return <div>
            <div className={'course-interface'}>
                <div className={'row align-items-center justify-content-between mb-4'}>
                    <div className={'col-auto'}>
                        <h5 className={'mb-0 fw-semibold '} >
                            {strings.doc?.text.patient
                                .ikherstel_records.abnormalities.abnormalities}
                        </h5>
                    </div>
                </div>
                {
                    isLoading
                        ? isLoadingContent
                        : <div className={'record-menu'}>
                            <table>
                                <thead>
                                    {tableHeaders}
                                </thead>
                                <tbody>
                                    {
                                        tableBody
                                    }
                                </tbody>
                            </table>
                            {/* show pagination when length is greater than limit */}
                            {
                                deviationDataState.data.length > deviationDataState.limit &&
                                 pagination
                            }
                        </div>
                }

            </div>
        </div>
    }, undefined)

    return <>
        {abnormalities}
    </>
}

export default Abnormalities
