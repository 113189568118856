import {
    ACTION_MUTATION_PROMISE,
    FIREBASE_CONFIG,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken, setToken } from '@app/slices/slice.token'

import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { back } from '@lagunovsky/redux-react-router'
import {
    useChangePasswordMutation,
    useGetOverallProgressMutation,
    useGetPersonalDetailsMutation,
    useSetMFAMutation,
    useToggleEmailNotificationsMutation,
    useTogglePushNotificationsMutation,
    useUpdatePersonalDetailsMutation,
    useUpdateProfilePictureMutation,
    useValidateMFAMutation
} from '@profile/api'
import {
    CHANGE_PASSWORD_FORMIK_INITIAL_VALUES,
    CHANGE_PASSWORD_VALIDATION_SCHEMA,
    IDS,
    MFA_FORMIK_INITIAL_VALUES,
    MFA_VALIDATION_SCHEMA,
    MODULE_VERSION,
    PERSONAL_INFO_FORMIK_INITIAL_VALUES,
    PERSONAL_INFO_VALIDATION_SCHEMA
} from '@profile/constants'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { MODULE_TABLE } from '@app/app.config'
import { TokenData } from '@app/types/type.token'

import { ImageDisplay } from '@profile/styles/ImageDisplay'
import { ChangePasswordKeys, MfaKeys, PersonalInfoKeys } from '@profile/type'

import {
    HeaderContent,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/profilePage/Components'

import { getTokenForReal } from '@app/components/FirebaseController/method'
import { PASSWORD_STRENGTH_LIMIT } from '@registration/constants'
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'

// Initialize Firebase
// console.log('this is called.')
const app = initializeApp(FIREBASE_CONFIG)

// Initialize Firebase Cloud Messaging and get a reference to the service
// this is asynchronous i think.
const firebaseMessaging = getMessaging(app)

const AccountSettings = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()

    const [weakPasswordMessage, setWeakPasswordMessage] = useState<string>('')
    const [setMFA, setMFAMutation] = useSetMFAMutation()
    const [validateMFA, validateMFAMutation] = useValidateMFAMutation()
    const [getPersonalDetails, getPersonalDetailsMutation] = useGetPersonalDetailsMutation()
    const [
        updatePersonalDetails,
        updatePersonalDetailsMutation
    ] = useUpdatePersonalDetailsMutation()

    const [changePassword, changePasswordMutation] = useChangePasswordMutation()
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()
    const [toggleEmailNotifications,
        toggleEmailNotificationsMutation] = useToggleEmailNotificationsMutation()
    const [togglePushNotifications,
        togglePushNotificationsMutation] = useTogglePushNotificationsMutation()
    const [strength, setStrength] = useState(0)

    const calculateStrength = (value: string) => {
        let score = 0
        if (value.length >= 8) {
            score += 1
        }
        if (/[a-z]/.test(value)) {
            score += 1
        }
        if (/[A-Z]/.test(value)) {
            score += 1
        }
        if (/[0-9]/.test(value)) {
            score += 1
        }
        if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(value)) {
            score += 1
        }

        setStrength(
            Math.min(
                Math.round(
                    score / (PASSWORD_STRENGTH_LIMIT / 10)),
                PASSWORD_STRENGTH_LIMIT
            )
        )
    }

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const [mfaSwitchToggle, setMfaSwitchToggle] = useState<boolean>(false)
    const [emailSwitchToggle, setEmailSwitchToggle] = useState<boolean>(false)
    const [pushSwitchToggle, setPushSwitchToggle] = useState<boolean>(false)
    const [showQRForm, setShowQRForm] = useState<boolean>(false)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const unsubscribeGetPersonalDetails = () => {
        const unsubscribeMutation = getPersonalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPersonalDetails()
        unsubscribeGetOverallProgress()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getPersonalDetails.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (foundApiPath && newToken.value) {
                        promise = getPersonalDetails({
                            authToken: newToken.value
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.profile
                                    .apiPaths.getPersonalDetails.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [activeModules.id])

    useEffect(() => {
        if (getPersonalDetailsMutation.error) {
            const message = getErrorText(getPersonalDetailsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPersonalDetailsMutation.error])

    // we need two formiks here.
    const personalInfoFormik = useFormik({
        initialValues: PERSONAL_INFO_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PERSONAL_INFO_VALIDATION_SCHEMA(
            strings.app?.message.error.email || '',
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken({
                        value: token.value,
                        id: token.id
                    }, token.mode)
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.updatePersonalDetails.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        updatePersonalDetails({
                            authToken: newToken.value,
                            data: {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    const changePasswordFormik = useFormik({
        initialValues: CHANGE_PASSWORD_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CHANGE_PASSWORD_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.password_match || ''
        ),
        onSubmit: async (values) => {
            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            const isValid = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.profile.moduleName,
                MODULE_TABLE.profile.apiPaths.changePassword.path,
                true
            )

            if (isValid && newToken.value) {
                changePassword({
                    authToken: token.value,
                    data: {
                        currentPassword: values.currentPassword,
                        newPassword: values.newPassword,
                        newPassword2: values.retypeNewPassword
                    }
                }).unwrap().then((data) => {
                    if (data) {
                        if (data.status === 'OK') {
                            toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                            fetchData(token)
                        // then empty form.
                        } else {
                            toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                            setWeakPasswordMessage(data.message)
                        }
                    }
                })
            }
        }
    })

    const mfaFormik = useFormik({
        initialValues: MFA_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MFA_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: async (values) => {
            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            const isValid = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.profile.moduleName,
                MODULE_TABLE.profile.apiPaths.validateMFA.path,
                true
            )

            if (isValid && newToken.value) {
                validateMFA({
                    authToken: token.value,
                    data: {
                        token: values.pinCode
                    }
                }).unwrap().then((data) => {
                    if (data) {
                        if (data.status === 'OK') {
                            console.log(data.message)
                            toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                            setMfaSwitchToggle(true)
                            setShowQRForm(false)
                            // don't do this anymore.
                            // fetchData(token)
                            mfaFormik.resetForm()
                        } else {
                            toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        }
                    }
                })
            }
        }
    })

    useEffect(() => {
        calculateStrength(changePasswordFormik.values.newPassword)
    }, [changePasswordFormik.values.newPassword])

    const passwordStrengthCells = useMemo(() => {
        const items = []

        for (let i = 0; i < PASSWORD_STRENGTH_LIMIT; i++) {
            items.push(
                <div key={i} className={`meter-item ${
                    i < strength ? 'active' : ''
                }`}></div>
            )
        }

        return (
            <div className={'password-strength-meter mb-4'}>
                {items}
            </div>
        )
    }, [strength])

    useEffect(() => {
        const data = getPersonalDetailsMutation.data
        if (data?.data) {
            personalInfoFormik.setValues({
                email: data.data.email || '',
                firstName: data.data.firstName || '',
                lastName: data.data.lastName || '',
                sex: personalInfoFormik.values.sex || ''
            })

            setEmailSwitchToggle(
                getPersonalDetailsMutation.data?.data.notifications.emailNotifications || false
            )
            setPushSwitchToggle(
                getPersonalDetailsMutation.data?.data.notifications.pushNotifications || false
            )
        }
    }, [getPersonalDetailsMutation.data])

    useEffect(() => {
        if (updatePersonalDetailsMutation.error) {
            const message = getErrorText(updatePersonalDetailsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updatePersonalDetailsMutation.error])

    // password text toggles.
    const [togglePassword, setTogglePassword] = useState({
        currentPassword: false,
        newPassword: false,
        retypeNewPassword: false
    })

    // upload profile picture logic.
    const [file, setFile] = useState<File | null>(null)
    const [updateProfilePicture, updateProfilePictureMutation] = useUpdateProfilePictureMutation()
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0]
        if (selectedFile) {
            setFile(selectedFile)
        }
    }

    const handleUpload = async () => {
        if (file) {
            const formData = new FormData()
            formData.append('profilePicture', file)

            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            const isValid = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.profile.moduleName,
                MODULE_TABLE.profile.apiPaths.updateProfilePicture.path,
                true
            )

            if (isValid && newToken.value) {
                updateProfilePicture({
                    data: formData,
                    authToken: newToken.value
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                    // toast success message.
                        toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        // we need to use this token now so set it.
                        dispatch(setToken({
                            id: token.id,
                            value: data.data.token,
                            valid: token.valid,
                            mode: token.mode
                        }))
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        }
    }

    useEffect(() => {
        handleUpload()
    }, [file])

    useEffect(() => {
        if (updateProfilePictureMutation.error) {
            const message = getErrorText(updateProfilePictureMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updateProfilePictureMutation.error])

    const ProfilePic = useMemo(() => {
        const imageThumbnail = token.details.pic
            ? <ImageDisplay
                url={token.details.pic}
                className={'rounded-pill profile'}
            />
            : <div
                className={[
                    'profile-img-placeholder profile rounded-pill'
                ].join(' ')}
            >
            </div>

        return imageThumbnail
    }, undefined)

    const ProfilePhotoEditing = useMemo(() => {
        return <div className={'container'}>
            <div className={!isMobile ? 'row my-5' : 'row mt-3 mb-5 text-center'}>
                <div className={!isMobile ? 'col-auto' : 'col-12'}>
                    <div className={'img-wrapper mx-auto mb-2'}>

                        {ProfilePic}

                        <input
                            type={'file'}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <button
                            type={'button'}
                            className={'btn  btn-sm'}
                            onClick={() => {
                                if (fileInputRef.current) {
                                    fileInputRef.current.click()
                                }
                            }}
                        >
                            <i
                                className={'fa-light fa-pen-to-square'}
                                aria-hidden={'true'}>

                            </i>
                        </button>

                    </div>
                </div>
                <div className={!isMobile ? 'col' : 'col-12'}>
                    <span className={[
                        'profile-title',
                        isMobile ? 'h5 mt-3' : 'h2'
                    ].join(' ')}>{[
                            getPersonalDetailsMutation.data?.data.firstName || '',
                            getPersonalDetailsMutation.data?.data.lastName || ''
                        ].join(' ')}</span>

                    <p className={isMobile ? 'mb-1' : 'mb-2'}>{
                        getPersonalDetailsMutation.data?.data.email || ''}</p>

                </div>
            </div>
        </div>
    }, undefined)

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: PersonalInfoKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                personalInfoFormik.handleSubmit()
            }
        }
    }

    const FirstNameInput = useMemo(() => {
        const fieldName: PersonalInfoKeys = 'firstName'

        return <div className={'form-group  mb-4'}>
            <label
                htmlFor={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.FIRST_NAME}
                className={'form-label'}
            >{strings.profile?.text.account_settings.personal_info.first_name}</label>
            <input
                type={'text'}
                name={fieldName}
                className={`form-control ${ personalInfoFormik.errors.firstName &&
                    'border-danger' }`}
                placeholder={strings.profile?.text.account_settings.personal_info.first_name}
                id={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.FIRST_NAME}
                value={personalInfoFormik.values.firstName}
                onChange={personalInfoFormik.handleChange}
                onKeyDown={(e) => {
                    handleKeyDown(e, 'lastName')
                }}
                required
            />
            <div className={'form-text error'}>{
                personalInfoFormik.errors.firstName ? personalInfoFormik.errors.firstName : null
            }</div>
        </div>
    }, [
        strings,
        personalInfoFormik.values.firstName,
        personalInfoFormik.errors.firstName
    ])

    const LastNameInput = useMemo(() => {
        const fieldName: PersonalInfoKeys = 'lastName'

        return <div className={'form-group  mb-4'}>
            <label htmlFor={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.LAST_NAME}
                className={'form-label'}>
                {strings.profile?.text.account_settings.personal_info.last_name}</label>
            <input
                type={'text'}
                name={fieldName}
                className={`form-control ${
                    personalInfoFormik.errors.lastName && 'border-danger' }`}
                placeholder={strings.profile?.text.account_settings.personal_info.last_name}
                id={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.LAST_NAME}
                value={personalInfoFormik.values.lastName}
                onChange={personalInfoFormik.handleChange}
                onKeyDown={(e) => {
                    handleKeyDown(e, 'email')
                }}
                required
            />
            <div className={'form-text error'}>{
                personalInfoFormik.errors.lastName ? personalInfoFormik.errors.lastName : null
            }</div>
        </div>
    }, [
        strings,
        personalInfoFormik.values.lastName,
        personalInfoFormik.errors.lastName
    ])

    const EmailInput = useMemo(() => {
        const fieldName: PersonalInfoKeys = 'email'

        return <div className={'form-group  mb-4'}>
            <label htmlFor={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.EMAIL} className={'form-label'}>{
                strings.profile?.text.account_settings.personal_info.email
            }</label>
            <input
                type={'email'}
                name={fieldName}
                className={`form-control ${ personalInfoFormik.errors.email && 'border-danger' }`}
                placeholder={strings.profile?.text.account_settings.personal_info.email}
                id={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.EMAIL}
                value={personalInfoFormik.values.email}
                onChange={personalInfoFormik.handleChange}
                required
            />
            <div className={'form-text error'}>{
                personalInfoFormik.errors.email ? personalInfoFormik.errors.email : null
            }</div>
        </div>
    }, [
        strings,
        personalInfoFormik.values.email,
        personalInfoFormik.errors.email
    ])

    const SexInput = useMemo(() => {
        const fieldName: PersonalInfoKeys = 'sex'

        return <div className={'form-group  mb-4'}>

            <label className={'form-label'}>{strings.profile?.text
                .account_settings.personal_info.sex.main}</label>

            <div className={'row row-cols-1 btn-group-checkbox-set'} role={'group'}>
                <div className={'col'}>
                    <input
                        type={'radio'} checked={personalInfoFormik.values.sex === 'male'}
                        value={'male'}
                        onChange={personalInfoFormik.handleChange}
                        name={fieldName}
                        className={'btn-check'}
                        id={`${ IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.SEX }-01`}
                    />

                    <label className={'btn btn-dummy'} htmlFor={
                        `${ IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.SEX }-01`
                    }>
                        <div className={'question-checkbox'}>
                            <div className={'card justify-content-center px-3'}>
                                <div className={['card-body', !isMobile ? ' px-4' : ''].join(' ')}>
                                    <div className={'d-flex align-items-center'}>
                                        <div className={'question-unchecked'}></div>
                                        <div className={'question-checkmark'}>
                                            <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                        </div>
                                        <div className={'p fw-bold mx-3'}>
                                            {strings.profile?.text
                                                .account_settings.personal_info.sex.male}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div className={'col'}>
                    <input
                        type={'radio'} checked={personalInfoFormik.values.sex === 'female'}
                        onChange={personalInfoFormik.handleChange}
                        value={'female'}
                        name={fieldName}
                        className={'btn-check'}
                        id={`${ IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.SEX }-02`}
                    />

                    <label className={'btn btn-dummy'} htmlFor={
                        `${ IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.SEX }-02`
                    }>
                        <div className={'question-checkbox'}>
                            <div className={'card justify-content-center px-3'}>
                                <div className={['card-body', !isMobile ? ' px-4' : ''].join(' ')}>
                                    <div className={'d-flex align-items-center'}>
                                        <div className={'question-unchecked'}></div>
                                        <div className={'question-checkmark'}>
                                            <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                        </div>
                                        <div className={'p fw-bold mx-3'}>
                                            {strings.profile?.text.account_settings
                                                .personal_info.sex.female}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>

                <div className={'col'}>
                    <input
                        type={'radio'} checked={personalInfoFormik.values.sex === 'unknown'}
                        onChange={personalInfoFormik.handleChange}
                        value={'unknown'}
                        name={fieldName}
                        className={'btn-check'}
                        id={`${ IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.SEX }-03`}
                    />

                    <label className={'btn btn-dummy'} htmlFor={
                        `${ IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.SEX }-03`
                    }>
                        <div className={'question-checkbox'}>
                            <div className={'card justify-content-center px-3'}>
                                <div className={['card-body', !isMobile ? ' px-4' : ''].join(' ')}>
                                    <div className={'d-flex align-items-center'}>
                                        <div className={'question-unchecked'}></div>
                                        <div className={'question-checkmark'}>
                                            <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                        </div>
                                        <div className={'p fw-bold mx-3'}>
                                            {strings.profile?.text.account_settings
                                                .personal_info.sex.other}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div className={'form-text error'}>{
                personalInfoFormik.errors.sex ? personalInfoFormik.errors.sex : null
            }</div>

        </div>
    }, [
        strings,
        personalInfoFormik.values.sex,
        personalInfoFormik.errors.sex
    ])

    const PersonalInfoForm = useMemo(() => {
        return <>
            {FirstNameInput}
            {LastNameInput}
            {EmailInput}
            {SexInput}
        </>
    }, undefined)

    const CurrentPasswordInput = useMemo(() => {
        const fieldName: ChangePasswordKeys = 'currentPassword'

        return <div className={'form-group mb-4'}>
            <label htmlFor={IDS.ACCOUNT_SETTINGS.PASSWORD.CURRENT_PASSWORD}
                className={'form-label'}>{
                    strings.profile?.text.account_settings.change_password.current_password
                }</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={togglePassword.currentPassword ? 'text' : 'password'}
                    value={changePasswordFormik.values.currentPassword}
                    className={`form-control ${
                        changePasswordFormik.errors.currentPassword && 'border-danger' }`}
                    placeholder={strings.profile?.text.account_settings
                        .change_password.current_password || ''}
                    id={IDS.ACCOUNT_SETTINGS.PASSWORD.CURRENT_PASSWORD}
                    name={fieldName}
                    onChange={changePasswordFormik.handleChange}
                    data-parsley-trigger={'blur'}
                    required />
                <label className={'form-floaticon'}
                    htmlFor={IDS.ACCOUNT_SETTINGS.PASSWORD.CURRENT_PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        setTogglePassword({
                            ...togglePassword,
                            currentPassword: !togglePassword.currentPassword
                        })
                    }}>
                        {!togglePassword.currentPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                changePasswordFormik.errors.currentPassword
                    ? changePasswordFormik.errors.currentPassword
                    : null
            }</div>
        </div>
    }, [
        strings,
        changePasswordFormik.values.currentPassword,
        changePasswordFormik.errors.currentPassword,
        togglePassword
    ])

    const NewPasswordInput = useMemo(() => {
        const fieldName: ChangePasswordKeys = 'newPassword'

        return <div className={'form-group mb-4'}>
            <label htmlFor={IDS.ACCOUNT_SETTINGS.PASSWORD.NEW_PASSWORD}
                className={'form-label'}>{
                    strings.profile?.text.account_settings.change_password.new_password
                }</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={togglePassword.newPassword ? 'text' : 'password'}
                    value={changePasswordFormik.values.newPassword}
                    className={`form-control ${
                        changePasswordFormik.errors.newPassword && 'border-danger' }`}
                    placeholder={strings.profile?.text.account_settings
                        .change_password.new_password || ''}
                    id={IDS.ACCOUNT_SETTINGS.PASSWORD.NEW_PASSWORD}
                    name={fieldName}
                    onChange={changePasswordFormik.handleChange}
                    data-parsley-trigger={'blur'}
                    required />
                <label className={'form-floaticon'}
                    htmlFor={IDS.ACCOUNT_SETTINGS.PASSWORD.NEW_PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        setTogglePassword({
                            ...togglePassword,
                            newPassword: !togglePassword.newPassword
                        })
                    }}>
                        {!togglePassword.newPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                changePasswordFormik.errors.newPassword
                    ? changePasswordFormik.errors.newPassword
                    : weakPasswordMessage
            }</div>
        </div>
    }, [
        weakPasswordMessage,
        strings,
        changePasswordFormik.values.newPassword,
        changePasswordFormik.errors.newPassword,
        togglePassword
    ])

    const RetypeNewPasswordInput = useMemo(() => {
        const fieldName: ChangePasswordKeys = 'retypeNewPassword'

        return <div className={'form-group mb-4'}>
            <label htmlFor={IDS.ACCOUNT_SETTINGS.PASSWORD.RETYPE_NEW_PASSWORD}
                className={'form-label'}>{
                    strings.profile?.text.account_settings.change_password.retype_new_password
                }</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={togglePassword.retypeNewPassword ? 'text' : 'password'}
                    value={changePasswordFormik.values.retypeNewPassword}
                    className={`form-control ${
                        changePasswordFormik.errors.retypeNewPassword && 'border-danger' }`}
                    placeholder={strings.profile?.text.account_settings
                        .change_password.retype_new_password || ''}
                    id={IDS.ACCOUNT_SETTINGS.PASSWORD.RETYPE_NEW_PASSWORD}
                    name={fieldName}
                    onChange={changePasswordFormik.handleChange}
                    data-parsley-trigger={'blur'}
                    required />
                <label className={'form-floaticon'}
                    htmlFor={IDS.ACCOUNT_SETTINGS.PASSWORD.RETYPE_NEW_PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        setTogglePassword({
                            ...togglePassword,
                            retypeNewPassword: !togglePassword.retypeNewPassword
                        })
                    }}>
                        {!togglePassword.retypeNewPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                changePasswordFormik.errors.retypeNewPassword
                    ? changePasswordFormik.errors.retypeNewPassword
                    : null
            }</div>
        </div>
    }, [
        strings,
        changePasswordFormik.values.retypeNewPassword,
        changePasswordFormik.errors.retypeNewPassword,
        togglePassword
    ])

    const ChangePasswordForm = useMemo(() => {
        return <>
            {CurrentPasswordInput}
            {NewPasswordInput}
            {passwordStrengthCells}
            {RetypeNewPasswordInput}
        </>
    }, undefined)

    const triggerSetMFACall = async (bool: 'True' | 'False') => {
        const newToken = await revalidateToken({
            value: token.value,
            id: token.id
        }, token.mode)

        const foundApiPath = validateAPIPath(
            activeModules.arr,
            MODULE_TABLE.profile.moduleName,
            MODULE_TABLE.profile.apiPaths.setMFA.path,
            true
        )

        // NOTE: not all need to show a toast error.
        // only do this error toast method AFTER authentication.
        if (foundApiPath && newToken.value) {
            setMFA({
                authToken: newToken.value,
                data: {
                    mfaToggle: bool
                }
            }).unwrap()
                .then((data) => {
                    if (data.mfa === 'True') {
                        setMfaSwitchToggle(true)
                        setShowQRForm(true)
                        // scroll to qr code.
                    } else if (data.mfa === 'False') {
                        setMfaSwitchToggle(false)
                        setShowQRForm(false)
                    }
                })
        } else {
            if (strings.login?.message.error.api_path) {
                toast.error(
                    `${ MODULE_TABLE.profile
                        .apiPaths.getPersonalDetails.path }:
                    ${ strings.login?.message.error.api_path }`.trim(),
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }

    useEffect(() => {
        if (showQRForm) {
            const scrollToBottom = () => {
                window.scrollTo(0, document.body.scrollHeight)
            }

            // Delay the scroll by one second (1000 milliseconds)
            const delay = 500
            const timeoutId = setTimeout(scrollToBottom, delay)

            // Clean up the timeout when the component unmounts
            return () => clearTimeout(timeoutId)
        }
        return () => {}
    }, [showQRForm])

    useEffect(() => {
        if (getPersonalDetailsMutation.data?.data.mfa === true) {
            setMfaSwitchToggle(true)
        }
    }, [
        getPersonalDetailsMutation.data?.data.mfa
    ])

    const SettingsSwitchForm = useMemo(() => {
        return <>
            <div className={'settings-switch-wrapper'}>

                <i className={['fa-light fa-shield-keyhole',
                    isMobile ? 'me-3' : 'me-5'].join(' ')} aria-hidden={'true'}></i>
                <div className={'title'}>{strings.profile?.text
                    .account_settings.settings_switch.mfa}</div>
                <div className={'form-check form-switch settings-switch ms-auto'}>
                    <input className={'form-check-input'} type={'checkbox'}
                        onChange={async (e) => {
                            const formString = e.target.checked === true
                                ? 'True'
                                : 'False'

                            triggerSetMFACall(formString)
                        }}
                        role={'switch'} id={IDS.ACCOUNT_SETTINGS.SWITCHES.MFA}
                        checked={mfaSwitchToggle === true}/>
                </div>

            </div>

            <div className={'settings-switch-wrapper'}>

                <i className={['fa-light fa-fingerprint',
                    isMobile ? 'me-3' : 'me-5'].join(' ')} aria-hidden={'true'}></i>
                <div className={'title'}>{strings.profile?.text
                    .account_settings.settings_switch.fingerprint}</div>
                <div className={'form-check form-switch settings-switch ms-auto'}>
                    <input className={'form-check-input'} type={'checkbox'}
                        role={'switch'} id={IDS.ACCOUNT_SETTINGS.SWITCHES.FINGERPRINT}/>
                </div>

            </div>
        </>
    }, undefined)

    // mfa form

    const QRCode = <img
        style={{ width: '10em' }}
        className={'mx-3'}
        src={`data:image/png;base64, ${ setMFAMutation.data?.image_data }`}
        alt={strings.app?.text?.qr_code_needed}
    />

    const PinCodeInput = useMemo(() => {
        const fieldName: MfaKeys = 'pinCode'

        return <div className={'form-group  mb-4'}>
            <label
                htmlFor={IDS.ACCOUNT_SETTINGS.MFA.PIN_CODE}
                className={'form-label'}
            >{strings.profile?.text.account_settings.settings_switch.pin_code}</label>
            <input
                type={'text'}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        mfaFormik.handleSubmit()
                    }
                }}
                name={fieldName}
                className={`form-control ${ mfaFormik.errors.pinCode &&
                    'border-danger' }`}
                placeholder={strings.profile?.text.account_settings.settings_switch.pin_code}
                id={IDS.ACCOUNT_SETTINGS.MFA.PIN_CODE}
                value={mfaFormik.values.pinCode}
                onChange={mfaFormik.handleChange}
                required
            />
            <div className={'form-text error'}>{
                mfaFormik.errors.pinCode
                    ? mfaFormik.errors.pinCode
                    : null
            }</div>
        </div>
    }, [
        strings,
        mfaFormik.values.pinCode,
        mfaFormik.errors.pinCode
    ])

    const MFASubmitButton = useMemo(() => {
        const buttonContent = validateMFAMutation.isLoading
            ? (
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.submitting || ''
                    }</span>
                </div>
            )
            : strings.app?.text.submit

        return (
            <button type={'button'}
                onClick={() => {
                    mfaFormik.handleSubmit()
                }}
                className={'submit-button btn btn-primary btn-lg'}
                disabled={validateMFAMutation.isLoading}
            >{buttonContent}</button>
        )
    }, [validateMFAMutation.isLoading])

    const ChangePasswordSubmitButton = useMemo(() => {
        const buttonContent = changePasswordMutation.isLoading
            ? (
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.submitting || ''
                    }</span>
                </div>
            )
            : strings.profile?.text.account_settings.change_password.change_password

        const buttonClassName = [
            'submit-button btn btn-primary btn-lg',
            isMobile ? 'px3 py-2' : ''
        ].join(' ')

        return (
            <button type={'button'}
                onClick={() => {
                    changePasswordFormik.handleSubmit()
                }}
                className={buttonClassName}
                disabled={changePasswordMutation.isLoading}
            >{buttonContent}</button>
        )
    }, [changePasswordMutation.isLoading, isMobile])

    const PersonalInfoSubmitButton = useMemo(() => {
        const buttonContent = updatePersonalDetailsMutation.isLoading
            ? (
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{strings.app?.text?.submitting}</span>
                </div>
            )
            : strings.app?.text.submit

        const buttonClassName = [
            'submit-button btn btn-primary btn-lg',
            isMobile ? 'px3 py-2' : ''
        ].join(' ')

        return (<button type={'submit'}
            disabled={updatePersonalDetailsMutation.isLoading}
            form={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.FORM}
            className={buttonClassName}>
            {buttonContent}
        </button>)
    }, [strings, updatePersonalDetailsMutation, isMobile])

    const showingQRFormCondition = showQRForm
        ? <div className={'card'}>

            <div className={['card-body', !isMobile ? ' px-4' : ''].join(' ')}>
                <p className={isMobile ? 'h6 mb-3 mt-2' : 'h4 mb-4 mt-3'}>
                    {strings.profile?.text.account_settings.settings_switch.private_key.title
                    }</p>
                <div className={'d-flex flex-column px-4'}>
                    <p>{strings.profile?.text.account_settings.settings_switch.private_key
                        ?.instructions?.[1]}</p>
                    <p>{strings.profile?.text.account_settings.settings_switch.private_key
                        ?.instructions?.[2]}</p>
                    <p>{
                        [
                            strings.profile?.text.account_settings.settings_switch.private_key
                                ?.instructions?.[3],
                            ':',
                            setMFAMutation.data?.code
                        ].join(' ')
                    }</p>
                    {/* then input box */}
                    {QRCode}
                    {PinCodeInput}
                    <div className={
                        'row justify-content-center'}>
                        <div className={'col text-center'}>
                            {MFASubmitButton}

                        </div>
                    </div>

                </div>
            </div>

        </div>
        : ''

    const triggerSwitchPushNotif = async (bool: boolean) => {
        const newToken = await revalidateToken({
            value: token.value,
            id: token.id
        }, token.mode)

        const foundApiPath = validateAPIPath(
            activeModules.arr,
            MODULE_TABLE.profile.moduleName,
            MODULE_TABLE.profile.apiPaths.togglePushNotifications.path,
            true
        )

        const subscriberId = bool
            ? await getTokenForReal(
                firebaseMessaging, navigator.userAgent
            )
            : ''
        // NOTE: not all need to show a toast error.
        // only do this error toast method AFTER authentication.
        if (foundApiPath && newToken.value) {
            togglePushNotifications({
                authToken: newToken.value,
                data: {
                    subscriberId: bool ? subscriberId || '' : '',
                    pushNotifications: bool
                }
            })
        } else {
            if (strings.login?.message.error.api_path) {
                toast.error(
                    `${ MODULE_TABLE.profile
                        .apiPaths.getPersonalDetails.path }:
                    ${ strings.login?.message.error.api_path }`.trim(),
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }

    useEffect(() => {
        if (togglePushNotificationsMutation.data?.status === 'OK') {
            fetchData(token)
        }
    }, [togglePushNotificationsMutation.data])

    const PushNotificationsSwitch = <div className={'settings-switch-wrapper'}>

        <i className={['fa-light fa-bell',
            isMobile ? 'me-3' : 'me-5'].join(' ')} aria-hidden={'true'}> </i>
        <div className={'title'}>{
            strings.profile?.text.account_settings.notifcations.push_notifcations
        }</div>
        <div className={'form-check form-switch settings-switch ms-auto'}>
            <input className={'form-check-input'}
                type={'checkbox'} role={'switch'}
                id={IDS.APP_SETTINGS.SWITCHES.PUSH_NOTIFICATIONS} checked={
                    pushSwitchToggle
                } onChange={(e) => {
                    // make toggle call.
                    triggerSwitchPushNotif(e.target.checked)
                }}/>
        </div>

    </div>

    const triggerSwitchEmailNotif = async (bool: boolean) => {
        const newToken = await revalidateToken({
            value: token.value,
            id: token.id
        }, token.mode)

        const foundApiPath = validateAPIPath(
            activeModules.arr,
            MODULE_TABLE.profile.moduleName,
            MODULE_TABLE.profile.apiPaths.toggleEmailNotifications.path,
            true
        )

        // NOTE: not all need to show a toast error.
        // only do this error toast method AFTER authentication.
        if (foundApiPath && newToken.value) {
            toggleEmailNotifications({
                authToken: newToken.value,
                data: {
                    emailNotifications: bool
                }
            })
        } else {
            if (strings.login?.message.error.api_path) {
                toast.error(
                `${ MODULE_TABLE.profile
                    .apiPaths.getPersonalDetails.path }:
                ${ strings.login?.message.error.api_path }`.trim(),
                { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }

    useEffect(() => {
        if (toggleEmailNotificationsMutation.data?.status === 'OK') {
            fetchData(token)
        }
    }, [toggleEmailNotificationsMutation.data])

    const EmailSwitch = <div className={'settings-switch-wrapper'}>

        <i className={['fa-light fa-bell',
            isMobile ? 'me-3' : 'me-5'].join(' ')} aria-hidden={'true'}> </i>
        <div className={'title'}>{
            strings.profile?.text.account_settings.personal_info.email
        }</div>
        <div className={'form-check form-switch settings-switch ms-auto'}>
            <input className={'form-check-input'}
                type={'checkbox'} role={'switch'}
                id={IDS.APP_SETTINGS.SWITCHES.EMAIL} checked={
                    emailSwitchToggle
                } onChange={(e) => {
                    // make toggle call.
                    triggerSwitchEmailNotif(e.target.checked)
                }}/>
        </div>

    </div>

    const NotificationSection = useMemo(() => {
        return <div className={'card'}>

            <div className={'card-body px-4'}>
                <p className={isMobile ? 'h6 mb-3 mt-2' : 'h4 mb-4 mt-3'}>
                    {strings.profile?.text.account_settings.notifcations.title }</p>
                <div className={!isMobile ? 'd-flex flex-column px-4' : 'd-flex flex-column'}>
                    {PushNotificationsSwitch}
                    {EmailSwitch}
                </div>
            </div>

        </div>
    }, undefined)

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        <HeaderImage
            className={'p-4'}
            url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} >
        </HeaderImage>
        <HeaderGradient />
        <HeaderContent
            className={'p-4'}
        >
            <button
                type={'button'}
                className={[
                    'btn btn-round btn-rounded'
                ].join('')}
                onClick={(e) => {
                    e.preventDefault()
                    dispatch(back())
                }}
            >
                <i
                    className={[
                        'fa-light',
                        'fa-arrow-left'
                    ].join(' ')}
                    aria-hidden={'true'}>
                </i>
            </button>
        </HeaderContent>
        <div className={'main-content'}>
            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-center mb-5'}>
                    <div className={'col-10 col-md-8'}>
                        <h1 className={'mt-4'}>{strings.profile?.text
                            .account_settings.my_account}</h1>

                        <div className={'my-5'}>

                            {ProfilePhotoEditing}

                        </div>

                        {/* two columns. */}

                        <div className={'row g-4'}>
                            <div className={'col-12 col-xl-6'}>

                                <div className={'card'}>
                                    <div className={'card-body px-4'}>

                                        <p className={
                                            isMobile ? 'h6 mb-3 mt-2' : 'h4 mb-4 mt-3'
                                        }>{`${ strings.profile
                                            ?.text.account_settings
                                            .personal_information }: `}</p>
                                        {/* show reaodnly user name here. */}
                                        <b className={'d-block'}>{strings.profile?.text
                                            .account_settings.username || ''}</b>
                                        <span>{
                                            getPersonalDetailsMutation.data?.data.username
                                        }</span>
                                        <form
                                            id={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.FORM}
                                            onSubmit={personalInfoFormik.handleSubmit}
                                        >
                                            {PersonalInfoForm}
                                            <div className={'row justify-content-center'}>
                                                <div className={'col text-center'}>
                                                    {PersonalInfoSubmitButton}

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className={'col-12 col-xl-6'}>
                                <div className={'row row-cols-1 g-3'}>
                                    <div className={'col'}>
                                        <div className={'card'}>
                                            <div className={'card-body px-4'}>
                                                <p className={isMobile
                                                    ? 'h6 mb-3 mt-2'
                                                    : 'h4 mb-4 mt-3'
                                                }>
                                                    {strings.profile?.text.account_settings
                                                        .change_password.password}
                                                </p>

                                                <form
                                                    id={IDS.ACCOUNT_SETTINGS.PASSWORD.FORM}
                                                    onSubmit={changePasswordFormik.handleSubmit}
                                                >
                                                    {ChangePasswordForm}
                                                    <div className={'row justify-content-center'}>
                                                        <div className={'col text-center'}>
                                                            {ChangePasswordSubmitButton}

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col'}>
                                        {NotificationSection}
                                    </div>
                                    <div className={'col'}>

                                        <div className={'card'}>

                                            <div className={'card-body px-4'}>
                                                <p className={isMobile
                                                    ? 'h6 mb-3 mt-2'
                                                    : 'h4 mb-4 mt-3'}>
                                                    { strings.profile
                                                        ?.text.account_settings
                                                        .settings_switch.security

                                                    }</p>

                                                <div className={'d-flex flex-column px-4'}>

                                                    {SettingsSwitchForm}

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className={'col'}>

                                        {showingQRFormCondition}

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <div className={'main-content'}>
            <div className={'container-fluid px-4'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-12 cards-container mb-5'}>
                        <div className={'row align-items-center g-3'}>
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'btn btn-round btn-rounded'
                                    ].join('')}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(back())
                                    }}
                                >
                                    <i
                                        className={[
                                            'fa-light',
                                            'fa-arrow-left'
                                        ].join(' ')}
                                        aria-hidden={'true'}>
                                    </i>
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <h2 className={'mb-0'}>{strings.profile?.text
                                    .account_settings.my_account}</h2>
                            </div>
                        </div>

                        <div className={'my-5'}>

                            {ProfilePhotoEditing}

                        </div>

                        {/* two columns. */}

                        <div className={'row g-4'}>
                            <div className={'col-12 col-xl-6'}>

                                <div className={'card'}>
                                    <div className={'card-body px-4'}>

                                        <p className={isMobile
                                            ? 'h6 mb-3 mt-2'
                                            : 'h4 mb-4 mt-3'}>{strings.profile?.text
                                                .account_settings.personal_information}</p>

                                        {/* show reaodnly user name here. */}
                                        <b>{strings.profile?.text
                                            .account_settings.username || ''}</b>
                                        <span>{
                                            getPersonalDetailsMutation.data?.data.username
                                        }</span>
                                        <form
                                            id={IDS.ACCOUNT_SETTINGS.PERSONAL_INFO.FORM}
                                            onSubmit={personalInfoFormik.handleSubmit}
                                        >
                                            {PersonalInfoForm}
                                            <div className={'row justify-content-center'}>
                                                <div className={'col text-center'}>
                                                    {PersonalInfoSubmitButton}

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className={'col-12 col-xl-6'}>
                                <div className={'row row-cols-1 g-3'}>
                                    <div className={'col'}>
                                        <div className={'card'}>
                                            <div className={'card-body px-4'}>
                                                <p
                                                    className={isMobile
                                                        ? 'h6 mb-3 mt-2'
                                                        : 'h4 mb-4 mt-3'}>
                                                    {strings.profile?.text.account_settings
                                                        .change_password.password}
                                                </p>

                                                <form
                                                    id={IDS.ACCOUNT_SETTINGS.PASSWORD.FORM}
                                                    onSubmit={changePasswordFormik.handleSubmit}
                                                >
                                                    {ChangePasswordForm}
                                                    <div className={'row justify-content-center'}>
                                                        <div className={'col text-center'}>
                                                            {ChangePasswordSubmitButton}

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col'}>
                                        {NotificationSection}
                                    </div>
                                    <div className={'col'}>

                                        <div className={'card'}>

                                            <div className={'card-body px-4'}>
                                                <p className={isMobile
                                                    ? 'h6 mb-3 mt-2'
                                                    : 'h4 mb-4 mt-3'}>
                                                    { strings.profile
                                                        ?.text.account_settings
                                                        .settings_switch.security

                                                    }</p>

                                                <div className={'d-flex flex-column'}>

                                                    {SettingsSwitchForm}

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className={'col'}>

                                        {showingQRFormCondition}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>

    return <div className={'account-settings-page position-relative'}>

        {/* the container */}

        {
            isMobile ? mobileResult : desktopResult
        }
    </div>
}

export default AccountSettings
