
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { MODULE_TABLE } from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules } from '@app/slices/slice.app'
import { push } from '@lagunovsky/redux-react-router'
import { MODULE_VERSION } from '@onboarding/constants'

const GettingStarted = () => {
    // const [getOnboarding, getOnboardingMutation] = useGetOnboardingMutation()
    // const token = useAppSelector(selectToken)
    // const rootContext = useContext(MutationContext)
    // const revalidateToken = rootContext.revalidateToken
    // const config = useAppSelector(selectConfig)
    // const strings = useAppSelector(selectStrings)
    const dispatch = useAppDispatch()

    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)

    // const [activeSlide, setActiveSlide] = useState<number>(0)

    // const unsubscribeGetOnboarding = () => {
    // const unsubscribeMutation = getOnboarding({} as any)
    // unsubscribeMutation.abort()
    // unsubscribeMutation.reset()
    // }

    // useEffect(() => {
    //     if (getOnboardingMutation.error) {
    //         const message = getErrorText(getOnboardingMutation.error)
    //         console.error(message)
    //         toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
    //     }
    // }, [getOnboardingMutation.error])

    // const fetchData = (mode: 'auth' | 'guest') => {
    //     /** this will reset the data to unInitialized AND prevent sending a request
    //      * to the server.
    //      */
    //     unsubscribeGetOnboarding()

    //     let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
    //     let isMounted = true

    //     const call = async () => {
    //         if (token.valid) {
    //             const newToken = await revalidateToken(mode)
    //             if (isMounted) {
    //                 const foundApiPath = findAPIPath(
    //                     config, modules.onboarding.moduleName,
    //                     modules.onboarding.apiPaths.getOnboarding.path
    //                 )

    //                 // NOTE: not all need to show a toast error.
    //                 // only do this error toast method AFTER authentication.
    //                 if (foundApiPath && newToken.value) {
    //                     promise = getOnboarding({
    //                         authToken: newToken.value
    //                     })
    //                 } else {
    //                     if (strings.login?.message.error.api_path) {
    //                         toast.error(
    //                             `${ modules.onboarding.apiPaths.getOnboarding.path }:
    //                             ${ strings.login?.message.error.api_path }`.trim(),
    //                             { ...TOASTIFY_DEFAULT_OPTIONS }
    //                         )
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     call()

    //     return () => {
    //         isMounted = false
    //         promise && promise.abort()
    //     }
    // }

    // useEffect(() => {
    //     return fetchData(token.mode)
    // }, [token.valid])

    // const onBoardingContent = useMemo(() => {
    //     const selectedSlide = getOnboardingMutation.data
    //         ?.data[activeSlide]

    //     const buildingBlocks = _.sortBy(selectedSlide?.onboardingContent, 'buidingBlockOrder')

    //     return <div className={'container px-5 px-sm-3'}>

    //         <div className={'row my-4'}>
    //             <div className={'col-12 col-md-8 col-lg-4 mx-auto text-center'}>

    //                 <img
    //                     src={selectedSlide?.onboardingImage || PLACEHOLDER_IMAGE}
    //                     className={'img-fluid'} alt={ selectedSlide?.onboardingTitle } />
    //             </div>
    //         </div>

    //         <div className={'row'}>
    //             <div className={'col-12 col-md-8 col-lg-6 mx-auto text-center'}>

    //                 <h1 className={'mb-4'}>{selectedSlide?.onboardingTitle}</h1>

    //                 {
    //                     _.map(buildingBlocks, (o, index) => {
    //                         const key = `building-block-${ index }`
    //                         let result = <></>
    //                         switch (o.buildingBlockType) {
    //                             case 'text/markdown':
    //                                 result = <div className={'text-center markdown'} >
    //                                     <ReactMarkdown
    //     remarkPlugins={[
    //         remarkGfm,
    //         supersub,
    //         remarkEmoji,
    //         remarkDefinitionList,
    //         remarkHeadingId
    //     ]} rehypePlugins={[
    //         rehypeRaw
    //     ]}
    // >
    //                                         {`${ o.buildingBlockValue }`}
    //                                     </ReactMarkdown>
    //                                 </div>
    //                                 break
    //                             default:
    //                                 result = <p>{
    //                                     strings.onboarding
    //                                         ?.text.building_block_type_undefined || ''
    //                                 }</p>
    //                                 break
    //                         }

    //                         return <div key={key}>{result}</div>
    //                     })
    //                 }

    //             </div>
    //         </div>

    //     </div>
    // }, [getOnboardingMutation.data, activeSlide, strings])

    // const nextButton = useMemo(() => {
    //     const isLast = activeSlide === (getOnboardingMutation.data?.data || []).length - 1

    //     return <a
    //         className={'btn btn-primary btn-lg w-100'}
    //         onClick={(e) => {
    //             e.preventDefault()
    //             if (isLast) {
    //                 // go to reasonWithMe.
    //                 const foundRoute = findRoute(
    //                     config, modules.reasonWithMe.moduleName,
    //                     modules.reasonWithMe.routes.questionInterface
    //                 )

    //                 if (foundRoute) {
    //                     dispatch(push(foundRoute.route))
    //                 } else {
    //                     if (strings.app?.message.error.route_denied) {
    //                         toast.error(
    //                             `${ modules.reasonWithMe.routes.questionInterface }:
    //                             ${ strings.app?.message.error.route_denied }`.trim(),
    //                             { ...TOASTIFY_DEFAULT_OPTIONS }
    //                         )
    //                     }
    //                 }
    //             } else {
    //                 setActiveSlide(activeSlide + 1)
    //             }
    //         }}
    //     >
    //         {!isLast ? strings.app?.text.next :
    // strings.reason_with_me?.text.go_to_reason_with_me}
    //         <i className={'fa-regular fa-arrow-right float-end'}
    //             aria-hidden={'true'} ></i>
    //     </a>
    // }, [config, getOnboardingMutation.data, activeSlide, strings])

    return <>

        <header className={''}>

            <nav className={'navbar navbar-expand-md navbar-light'}>
                <div className={'container px-5 px-sm-3'}>
                    <ul className={'navbar-nav'}>
                        <li className={'nav-item my-auto'}>
                            {/* navigate to login page. */}
                            <a className={'btn btn-round '}
                                onClick={(e) => {
                                    e.preventDefault()

                                    const isValid = validateRoute(
                                        activeModules.arr,
                                        MODULE_TABLE.login.moduleName,
                                        MODULE_TABLE.login.routes.login,
                                        true
                                    )

                                    if (isValid) {
                                        dispatch(push(isValid.route))
                                    }
                                }}>
                                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}></i>
                            </a>
                        </li>
                    </ul>

                </div>
            </nav>

        </header>

        <main className={'ih-app-main'}>

            <section>

                {/* {onBoardingContent} */}

            </section>

        </main>

        <footer className={'ih-app-footer d-flex align-items-end'}>

            <div className={'container-fluid px-5 px-sm-3 pb-5'}>

                <div className={'row align-items-center'}>

                    <div className={'col-12 col-md-6 col-lg-4 order-md-1'}>
                        {/* {getOnboardingMutation.data?.data && nextButton} */}
                    </div>

                    <div className={'col-12 col-md-6 col-lg-4 offset-lg-4 mt-5 mt-md-0 order-md-0'}>
                        <ul className={'pagination justify-content-center'}>
                            {
                                // _.map(getOnboardingMutation.data?.data || [], (obj, index) => {
                                //     const key = `page-${ index }`

                                //     return <li key={key} className={
                                //         `page-item ${ activeSlide === index ? 'active' : '' }`
                                //             .trim()
                                //     } aria-current={activeSlide === index ? 'page' : undefined}
                                //     onClick={() => setActiveSlide(index)}
                                //     >
                                //         <a className={'page-link'}
                                //             >
                                //         </a>
                                //     </li>
                                // })
                            }
                        </ul>
                    </div>

                </div>

            </div>

            <div className={'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'}
            >{MODULE_VERSION}</div>

        </footer>
    </>
}
export default GettingStarted
