import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { DepartmentKeys, DepartmentValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    departmentEditFormik: FormikProps<DepartmentValues>,
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: DepartmentKeys) => void
}

const Address = ({ readOnly, departmentEditFormik, handleKeyDown }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName1: DepartmentKeys = 'departmentAddressNumber'
    const fieldName2: DepartmentKeys = 'departmentAddressSuffix'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.DEPARTMENT.EDIT.HOUSE_NUMBER} className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].house_number
                }</label>
                <span className={'mx-2'}>{'+'}</span>
                <label htmlFor={IDS.DEPARTMENT.EDIT.SUFFIX} className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].suffix
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <div className={'row'}>
                    <div className={'col-12 col-lg-7 mb-4 mb-lg-0'}>
                        <input
                            readOnly={readOnly}
                            type={'text'}
                            name={fieldName1}
                            className={[
                                'form-control-sm',
                                readOnly ? 'form-control-plaintext' : 'form-control',
                                departmentEditFormik.errors.departmentAddressNumber &&
                                'border-danger'
                            ].join(' ')}
                            placeholder={!readOnly
                                ? strings.fmt?.text.facility.add.steps['1'].house_number
                                : ''}
                            value={departmentEditFormik.values.departmentAddressNumber}
                            id={IDS.DEPARTMENT.EDIT.HOUSE_NUMBER}
                            onBlur={departmentEditFormik.handleBlur}
                            onChange={!readOnly ? departmentEditFormik.handleChange : () => {}}
                            onKeyDown={(e) => {
                                handleKeyDown(e, 'departmentAddressSuffix')
                            }}
                            required
                        />
                        <div className={'form-text error'}>{
                            departmentEditFormik.errors.departmentAddressNumber
                                ? departmentEditFormik.errors.departmentAddressNumber
                                : null
                        }</div>
                    </div>
                    <div className={'col-lg-5 col-12'}>
                        <input
                            readOnly={readOnly}
                            type={'text'}
                            name={fieldName2}
                            className={[
                                'form-control-sm',
                                readOnly ? 'form-control-plaintext' : 'form-control',
                                departmentEditFormik.errors.departmentAddressSuffix &&
                                 'border-danger'
                            ].join(' ')}
                            placeholder={!readOnly
                                ? strings.fmt?.text.facility.add.steps['1'].suffix
                                : ''}
                            value={departmentEditFormik.values.departmentAddressSuffix}
                            id={IDS.DEPARTMENT.EDIT.SUFFIX}
                            onBlur={departmentEditFormik.handleBlur}
                            onChange={!readOnly ? departmentEditFormik.handleChange : () => {}}
                            onKeyDown={(e) => {
                                handleKeyDown(e, 'departmentAddressStreet')
                            }}
                            required
                        />
                        <div className={'form-text error'}>{
                            departmentEditFormik.errors.departmentAddressSuffix
                                ? departmentEditFormik.errors.departmentAddressSuffix
                                : null
                        }</div>
                    </div>
                </div>

            </div>

        </div>

    </div>

    return result
}

export default Address
