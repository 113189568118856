
import { QuestionHeader } from '@reasonWithMe/type'
import _ from 'lodash'

interface ComponentProps {
    data: {
        id: string,
        inputs: {
            answerValue: any,
            options: QuestionHeader
        }[]
    }[] | undefined
}

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

const OpenList = ({
    data
}: ComponentProps) => {
    // getting it from first index
    const questionHeaders = _.map(data?.[0]?.inputs, (form) => {
        return form.options
    })

    const rows = _.map(
        data, (form, outerIndex) => {
            const rowHasInputList = _.includes(
                _.map(form.inputs, (o) => {
                    return o.options.answerType
                }), 'input-list'
            )

            const inputs = _.map(form.inputs,
                (formInput, innerIndex) => {
                    const answerType = formInput.options.answerType as AnswerTypes

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'input') {
                        input = <span>
                            {formInput.answerValue}
                        </span>
                    } else if (answerType === 'radio') {
                        input = <label className={'custom-radio non-clickable'} >
                            <input
                                type={'radio'}
                                disabled={true}
                                checked={formInput.answerValue === true}
                            />
                            <span className={'checkmark'}>
                                <i className={'fa-solid fa-check mx-auto'}></i>
                            </span>
                        </label>
                    } else if (answerType === 'checkbox') {
                        input = <input
                            className={'non-clickable'}
                            type={'checkbox'}
                            disabled={true}
                            checked={formInput.answerValue === true}
                        />
                    } else if (answerType === 'input-list') {
                        input = <ol type={'a'} className={'mb-0'}>
                            {
                                _.map(formInput.answerValue as string[], (inputString,
                                    inputIndex) => {
                                    return <li key={[
                                        'input-cell', outerIndex, innerIndex, inputIndex
                                    ].join('-')}
                                    className={'align-middle text-center mb-4'}>
                                        <div className={'row align-items-center'}>
                                            <div className={'col'}>
                                                <span>
                                                    {inputString}
                                                </span>
                                            </div>
                                        </div>

                                    </li>
                                })
                            }
                        </ol>
                    }
                    return <td key={['table-cell', outerIndex, innerIndex].join('-')}
                        className={[
                            rowHasInputList ? '' : 'align-middle',
                            'text-center'
                        ].join(' ')}>
                        {input}
                    </td>
                })

            return <tr key={['table-row', outerIndex].join('-')}>
                {inputs}
            </tr>
        }
    )

    return <>
        {
            <table className={'table table-borderless'}>
                <thead>
                    <tr>
                        {
                            _.map(questionHeaders, (obj, i) => {
                                return <th className={'align-middle text-center'}
                                    key={['th', i].join('-')
                                    }>
                                    {obj.answerName}
                                </th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        }
    </>
}

export default OpenList
