import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { back } from '@lagunovsky/redux-react-router'
import {
    useDeleteCareplanMutation,
    useGetMyTherapeuticsMutation,
    useGetOverallProgressMutation
} from '@profile/api'
import EditTreatment from '@profile/components/therapeutics/EditTreatment'
import { MODULE_VERSION } from '@profile/constants'
import { MyTherapeutic } from '@profile/type'
import {
    HeaderContent,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/profilePage/Components'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { useMediaQuery } from 'react-responsive'

const MyTherapeutics = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const dateFormats = useAppSelector(selectDateFormats)

    const token = useAppSelector(selectToken)

    const [showEditModal, toggleShowEditModal] = useState<boolean>(false)
    const [showDeleteModal, toggleShowDeleteModal] = useState<boolean>(false)
    const [activeTreatment, toggleActiveTreatment] = useState<MyTherapeutic>({
        careplanName: '',
        myCareplanId: '',
        canEditTreatment: true,
        startDate: 0,
        status: 'active'
    })

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    /** add modal with api call will be put on hold only because
     * we have a proper way of adding a treatment to begin with.
     *
     *
     */

    const [getMyTherapeutics, getMyTherapeuticsMutation] = useGetMyTherapeuticsMutation()
    const [deleteCareplan, deleteCareplanMutation] = useDeleteCareplanMutation()

    const unsubscribeGetMyTherapeutics = () => {
        const unsubscribeMutation = getMyTherapeutics({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGetMyTherapeutics()
        unsubscribeGetOverallProgress()

        let getMyTherapeuticsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getMyTherapeutics.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getMyTherapeuticsPromise = getMyTherapeutics({
                            authToken: newToken.value
                        })
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getMyTherapeuticsPromise && getMyTherapeuticsPromise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        if (deleteCareplanMutation.data) {
            const data = deleteCareplanMutation.data
            if (data.status === 'OK') {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                toggleShowDeleteModal(false)
                fetchData(token)
            } else {
                toast.error(
                    data.message,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [deleteCareplanMutation.data])

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    const activeCards = useMemo(() => {
        const data = getMyTherapeuticsMutation.data?.data.myTherapeutics

        const filtered = _.filter(data, (o) => o.status !== 'complete')

        return <div className={'g-3 row row-cols-1 row-cols-lg-2 mb-4'}>
            {_.map(filtered, (o) => {
                const formattedDate = o.startDate
                    ? format(fromUnixTime(
                        o.startDate || 0
                    ), dateFormats.format3)
                    : strings.profile?.text.therapeutics.unknown

                return <div className={'col'}>
                    {/* can have the completed and inactive classes */}
                    <div className={['card h-100',
                        !isMobile ? ' py-3' : ''].join(' ')}>

                        <div className={'card-body'}>

                            <div className={'row justify-content-between align-items-center'}>
                                <div className={'col'}>
                                    <span className={isMobile
                                        ? 'd-block h6 mb-1'
                                        : 'd-block h5 mb-2'
                                    }>
                                        {o.careplanName}
                                    </span>
                                    <span className={'d-inline-block'}>
                                        {strings.profile?.text.therapeutics
                                            .treatment.operation_date}
                                        {':'}
                                    </span>
                                    <span className={'d-inline-block ps-1'}>
                                        {formattedDate}
                                    </span>

                                </div>
                                <div className={'col-auto'}>
                                    <button
                                        type={'button'}
                                        className={[
                                            'btn btn-round btn-rounded me-3'
                                        ].join('')}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            toggleActiveTreatment(o)
                                            toggleShowEditModal(true)
                                        }}
                                    >
                                        <i
                                            className={[
                                                'fa-light',
                                                'fa-pen-to-square'
                                            ].join(' ')}
                                            aria-hidden={'true'}>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </div>
    }, undefined)

    const CompleteCards = useMemo(() => {
        const data = getMyTherapeuticsMutation.data?.data.myTherapeutics

        const filtered = _.filter(data, (o) => o.status === 'complete')

        return filtered.length
            ? <div className={'row row-cols-1 row-cols-md-2 g-3 g-lg-5'}>
                {_.map(filtered, (o) => {
                    const formattedDate = o.startDate
                        ? format(fromUnixTime(
                            o.startDate || 0
                        ), dateFormats.format3)
                        : strings.profile?.text.therapeutics.unknown

                    return <div className={'col'}>
                        {/* can have the completed and inactive classes */}
                        <div className={['card h-100 completed inactive',
                            !isMobile ? ' py-3' : ''].join(' ')}>

                            <div className={'card-body'}>

                                <div className={'row justify-content-between align-items-center'}>
                                    <div className={'col'}>
                                        <span className={isMobile
                                            ? 'd-block h6 mb-1'
                                            : 'd-block h5 mb-2'
                                        }>
                                            {o.careplanName}
                                        </span>
                                        <span className={'d-inline-block'}>
                                            {strings.profile?.text.therapeutics
                                                .treatment.operation_date}
                                            {':'}
                                        </span>
                                        <span className={'d-inline-block'}>
                                            {formattedDate}
                                        </span>

                                    </div>
                                    <div className={'col-auto'}>
                                        <button
                                            type={'button'}
                                            className={[
                                                'btn btn-round btn-rounded me-3'
                                            ].join('')}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                toggleActiveTreatment(o)
                                                toggleShowEditModal(true)
                                            }}
                                        >
                                            <i
                                                className={[
                                                    'fa-light',
                                                    'fa-pen-to-square'
                                                ].join(' ')}
                                                aria-hidden={'true'}>
                                            </i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
            : strings.profile?.text.therapeutics.no_deleted_found
    }, undefined)

    const AddTreatment = useMemo(() => {
        const buttonContent = <div className={'container'}>
            <div className={'row justify-content-between align-items-center'}>
                <div className={'col text-center'}>
                    {strings.profile?.text.therapeutics.add_treatment}

                </div>
                <div className={'col-auto'}>
                    <i className={'fa-regular fa-plus float-end'}
                        aria-hidden={'true'} ></i>
                </div>
            </div>
        </div>

        const buttonClassName = 'btn btn-primary btn-lg submit-button disabled'.trim()

        return <a
            className={buttonClassName}
            onClick={(e) => {
                e.preventDefault()
            }}
        >
            {buttonContent}

        </a>
    }, [])

    // startIncompleteCareplan modal goes here.
    const editTreatmentForm = useMemo(() => {
        return activeTreatment.myCareplanId && <Modal
            unmountOnClose={false}
            size={'lg'}
            isOpen={showEditModal} toggle={() => {
                toggleShowEditModal(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                toggleShowEditModal(false)
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    toggleShowEditModal(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <EditTreatment
                    treatment={activeTreatment}
                    toggleShowEditModal={toggleShowEditModal}
                    fetchData={fetchData}
                />
            </ModalBody>
        </Modal>
    }, [
        showEditModal,
        activeTreatment
    ])

    const deleteTreatmentForm = useMemo(() => {
        return activeTreatment.myCareplanId && <Modal
            unmountOnClose={false}
            size={'lg'}
            isOpen={showDeleteModal} toggle={() => {
                toggleShowDeleteModal(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                toggleShowDeleteModal(false)
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    toggleShowDeleteModal(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <p className={'fw-500 mb-2'}>{
                    strings.profile?.text.therapeutics.delete_treatment?.confirmation_message?.[1]
                }</p>
            </ModalBody>
            <ModalFooter>
                <div className={'container px-0'}>

                    <div className={'row align-items-center'}>

                        <div className={'col-6 col-md-4 ms-auto'}>

                            <button type={'button'} onClick={async () => {
                                // do api call.
                                const newToken = await revalidateToken(token, token.mode)

                                const isValid = validateAPIPath(
                                    activeModules.arr,
                                    MODULE_TABLE.profile.moduleName,
                                    MODULE_TABLE.profile.apiPaths.deleteCareplan.path,
                                    true
                                )

                                if (isValid && newToken.value) {
                                    deleteCareplan({
                                        authToken: newToken.value,
                                        data: {
                                            myCareplanId: activeTreatment.myCareplanId
                                        }
                                    })
                                }
                            }} className={'btn btn-primary btn-sm w-100'}>
                                {strings.app?.text.yes}
                            </button>

                        </div>

                        <div className={'col-6 col-md-4 me-auto'}>

                            <button type={'button'} onClick={() => {
                                toggleShowDeleteModal(false)
                            }} className={'btn btn-secondary btn-sm w-100'}>
                                {strings.app?.text.no}
                            </button>

                        </div>

                    </div>

                </div>
            </ModalFooter>
        </Modal>
    }, [
        showDeleteModal,
        activeTreatment
    ])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        <HeaderImage
            className={'p-4'}
            url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} >
        </HeaderImage>
        <HeaderGradient />
        <HeaderContent
            className={'p-4'}
        >
            <button
                type={'button'}
                className={[
                    'btn btn-round btn-rounded'
                ].join('')}
                onClick={(e) => {
                    e.preventDefault()
                    dispatch(back())
                }}
            >
                <i
                    className={[
                        'fa-light',
                        'fa-arrow-left'
                    ].join(' ')}
                    aria-hidden={'true'}>
                </i>
            </button>
        </HeaderContent>
        <div className={'main-content'}>
            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-10 col-md-8 cards-container mb-5'}>
                        <h1 className={'mt-4 mb-5'}>{strings.profile?.text
                            .therapeutics.my_therapeutics}</h1>
                        <div>
                            <hr className={'d-lg-none'}/>
                            <div className={'mb-5'} role={'group'}>
                                <div className={'fw-bold mb-4'}>
                                    {strings.profile?.text.therapeutics.active}
                                </div>
                                {activeCards}
                                {AddTreatment}
                            </div>
                            <div className={'mt-lg-16'} role={'group'}>
                                <div className={'fw-bold mb-4'}>
                                    {strings.profile?.text.therapeutics.deleted}
                                </div>
                                {
                                    CompleteCards
                                }

                                {editTreatmentForm}
                                {deleteTreatmentForm}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <div className={'main-content'}>
            <div className={'container-fluid px-4'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-12 cards-container mb-5'}>
                        <div className={'row align-items-center g-3'}>
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'btn btn-round btn-rounded'
                                    ].join('')}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(back())
                                    }}
                                >
                                    <i
                                        className={[
                                            'fa-light',
                                            'fa-arrow-left'
                                        ].join(' ')}
                                        aria-hidden={'true'}>
                                    </i>
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <h2 className={'mb-0'}>{strings.profile
                                    ?.text.therapeutics.my_therapeutics}</h2>
                            </div>
                        </div>

                        <div>
                            <hr className={'d-lg-none'}/>
                            <div className={'mb-5'} role={'group'}>
                                <div className={'fw-bold mb-4'}>
                                    {strings.profile?.text.therapeutics.active}
                                </div>
                                {activeCards}
                                {AddTreatment}
                            </div>
                            <div className={'mt-lg-16'} role={'group'}>
                                <div className={'fw-bold mb-4'}>
                                    {strings.profile?.text.therapeutics.deleted}
                                </div>
                                {
                                    CompleteCards
                                }

                                {editTreatmentForm}
                                {deleteTreatmentForm}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>

    return <div className={'my-therapeutics-page position-relative'}>
        {/* the container */}

        {
            isMobile ? mobileResult : desktopResult
        }
    </div>
}

export default MyTherapeutics
