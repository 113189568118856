import { CareProfessional, Department, GetCareProfessionalsResponse } from '@fmt/type'
import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { IDS } from '@fmt/constants'

import { getErrorText, smartSearch } from '@app/app.method'
import { FormikProps } from 'formik'
import _ from 'lodash'

import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'

import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import {
    useAddDepartmentCareProfessionalMutation,
    useDeleteDepartmentCareProfessionalMutation,
    useGetCareProfessionalsMutation
} from '@fmt/api'

// eslint-disable-next-line max-len
import QuickAddCareprofessional from '@fmt/components/facilities/add/steps/StepThree/QuickAddCareprofessional'
import { toast } from 'react-toastify'

interface ComponentProps {
    department: Department,
    isFromEditPage: boolean,
    facilityStepThreeAddFormik: FormikProps<{
        linkedCareprofessionals: {
        department: Department,
        collapsible: boolean,
        arr: GetCareProfessionalsResponse['data']['careprofessionals']
    }[]
    }>
    dropdownsUpdateTrigger: boolean | undefined
    setDropdownsUpdateTrigger: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const CareProfessionalsInterface = ({
    department,
    isFromEditPage,
    facilityStepThreeAddFormik,
    dropdownsUpdateTrigger,
    setDropdownsUpdateTrigger
}: ComponentProps) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()

    const containerRef = useRef<HTMLDivElement | null>(null)
    const [search, setSearch] = useState('')

    // start off with dropdown already open.
    const [isOutsideClick, setIsOutsideClick] = useState(false)

    const [
        addDepartmentCareprofessional,
        addDepartmentCareprofessionalMutation
    ] = useAddDepartmentCareProfessionalMutation({})

    const [
        deleteDepartmentCareprofessional,
        deleteDepartmentCareprofessionalMutation
    ] = useDeleteDepartmentCareProfessionalMutation({})

    useEffect(() => {
        if (addDepartmentCareprofessionalMutation.error) {
            const message = getErrorText(addDepartmentCareprofessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addDepartmentCareprofessionalMutation.error])

    useEffect(() => {
        if (deleteDepartmentCareprofessionalMutation.error) {
            const message = getErrorText(deleteDepartmentCareprofessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [deleteDepartmentCareprofessionalMutation.error])

    // things have changed thanks to
    const [getUnlinkedCareprofessionals,
        getUnlinkedCareprofessionalsMutation
    ] = useGetCareProfessionalsMutation()
    const [getLinkedCareprofessionals,
        getLinkedCareprofessionalsMutation
    ] = useGetCareProfessionalsMutation()

    const unsubscribeGetUnlinkedCareprofessionals = () => {
        const unsubscribeMutation = getUnlinkedCareprofessionals({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetLinkedCareprofessionals = () => {
        const unsubscribeMutation = getLinkedCareprofessionals({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const fetchData = (token: TokenData) => {
        unsubscribeGetUnlinkedCareprofessionals()
        unsubscribeGetLinkedCareprofessionals()

        let getUnlinkedCareprofessionalsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getLinkedCareprofessionalsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCareProfessionals.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getUnlinkedCareprofessionalsPromise = getUnlinkedCareprofessionals({
                            authToken: newToken.value,
                            data: {
                                departmentId: department.departmentId,
                                isLinked: false,
                                limit: 0,
                                skip: 0
                            }
                        })

                        getLinkedCareprofessionalsPromise = getLinkedCareprofessionals({
                            authToken: newToken.value,
                            data: {
                                departmentId: department.departmentId,
                                isLinked: true,
                                limit: 0,
                                skip: 0
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getUnlinkedCareprofessionalsPromise && getUnlinkedCareprofessionalsPromise.abort()
            getLinkedCareprofessionalsPromise && getLinkedCareprofessionalsPromise.abort()
        }
    }

    useEffect(() => {
        // if the values of linkedDepartments is updated, then update the queries.
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        // if set to true, refresh the data.
        if (dropdownsUpdateTrigger === true) {
            fetchData(token)
        }
    }, [dropdownsUpdateTrigger])

    // a workaround to prevent tables from flickering. simple but it works.
    const [
        getUnlinkedCareprofessionalsResponse,
        setGetUnlinkedCareprofessionalsResponse
    ] = useState<GetCareProfessionalsResponse>()

    const [
        getLinkedCareprofessionalsResponse,
        setGetLinkedCareprofessionalsResponse
    ] = useState<GetCareProfessionalsResponse>()

    useEffect(() => {
        if (getUnlinkedCareprofessionalsMutation.data) {
            setGetUnlinkedCareprofessionalsResponse(
                getUnlinkedCareprofessionalsMutation.data
            )
        }
    }, [getUnlinkedCareprofessionalsMutation.data])

    useEffect(() => {
        if (getLinkedCareprofessionalsMutation.data) {
            setGetLinkedCareprofessionalsResponse(
                getLinkedCareprofessionalsMutation.data
            )
        }
    }, [getLinkedCareprofessionalsMutation.data])

    useEffect(() => {
        if (getLinkedCareprofessionalsResponse) {
            const temp = _.cloneDeep(facilityStepThreeAddFormik.values.linkedCareprofessionals)
            const foundIndex = temp.findIndex(
                (p) => p.department.departmentId === department.departmentId
            )

            if (foundIndex > -1) {
                temp[foundIndex].arr = getLinkedCareprofessionalsResponse.data.careprofessionals

                facilityStepThreeAddFormik.setFieldValue(
                    'linkedCareprofessionals', temp
                )
            }
        }
    }, [getLinkedCareprofessionalsResponse])

    const changeCareprofessionalStatus = async (
        departmentId: string,
        obj: CareProfessional, linked: boolean
    ) => {
        const newToken = await revalidateToken(
            {
                value: token.value,
                id: token.id
            },
            token.mode
        )

        if (linked) {
            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.apiPaths.addDepartmentCareProfessional.path,
                true
            )
            if (foundApiPath && newToken.value) {
                addDepartmentCareprofessional({
                    authToken: newToken.value,
                    data: {
                        departmentId: departmentId || '',
                        careProfessionalId: obj?.careProfessionalId || ''
                    }
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        //
                        fetchData(token)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        } else {
            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.apiPaths.deleteDepartmentCareProfessional.path,
                true
            )
            if (foundApiPath && newToken.value) {
                deleteDepartmentCareprofessional({
                    authToken: newToken.value,
                    data: {
                        departmentId: departmentId || '',
                        careProfessionalId: obj?.careProfessionalId || ''
                    }
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        //
                        fetchData(token)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current) {
                // weird behavior but works anyways
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsOutsideClick(false)
                } else {
                    setIsOutsideClick(true)
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [containerRef])

    const renderOption = (obj: CareProfessional, defaultChecked: boolean) => {
        const key = obj.careProfessionalId

        const addressTruthy = _.some([
            'careProfessionalProfession'
        ], prop => _.get(obj, prop))

        const profession = obj.careProfessionalProfession

        const nonEmptyValues = _.compact([
            profession
        ])
        const formattedString =
        ' (' + nonEmptyValues.join('') + ')'

        return <div
            className={'col'}
            key={key}
        >
            <input type={'checkbox'}
                className={'btn-check'}
                id={key}
                autoComplete={'off'}
                // checked prop is removed.
                // when checked, link it, otherwise, unlink.
                defaultChecked={defaultChecked}
                onChange={(e) => {
                    if (e.target.checked) {
                    // link
                        changeCareprofessionalStatus(department.departmentId, obj, true)
                    } else {
                    // unlink.
                        changeCareprofessionalStatus(department.departmentId, obj, false)
                    }
                }}
            />
            <label
                className={'btn btn-dummy'}
                htmlFor={key}
            >

                <div className={'question-checkbox'}>
                    <div className={'card justify-content-center px-3 py-2'}>

                        <div className={'d-flex flex-column'}>
                            <div className={'d-flex align-items-center'}>
                                <div className={'p text-start'}>
                                    <span>{obj.careProfessionalName}</span>
                                    {

                                        addressTruthy
                                            ? <span className={'fw-light'}>
                                                {formattedString}
                                            </span>
                                            : ''
                                    }
                                </div>
                                <div className={'question-checkmark ms-auto'}>
                                    <i className={'fa-light fa-check mt-1 mx-auto'}>
                                    </i>
                                </div>
                                <div className={'question-plussign ms-auto'}>
                                    <i className={'fa-light fa-plus mx-auto'}>
                                    </i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </label>
        </div>
    }

    const LinkedCareProfessionalOptions = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    getLinkedCareprofessionalsResponse?.data.careprofessionals || [],
                    [], search
                ) as CareProfessional[]),
                (obj) => {
                    return renderOption(obj, true)
                })
        }
    </div>

    const UnlinkedCareProfessionalOptions = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    getUnlinkedCareprofessionalsResponse?.data.careprofessionals || [],
                    [], search
                ) as CareProfessional[]),
                (obj) => {
                    return renderOption(obj, false)
                })
        }
    </div>

    const result = <div className={[
        'align-items-baseline mt-4 row'
    ].join(' ')}>
        <div className={isFromEditPage
            ? 'col-xl-3 d-none d-sm-block'
            : 'col-3 d-none d-sm-block'}>
            <label htmlFor={IDS.FACILITY_ADD_MODAL.SELECT.ADD_CAREPROFESSIONALS}
                className={'form-label'}>{
                    strings.fmt?.text.facility.add.careprofessionals
                }</label>
        </div>
        <div className={isFromEditPage
            ? 'col-xl-9 col-12 position-relative'
            : 'col-sm-9 col-12 position-relative'}>

            <div
                id={IDS.FACILITY_ADD_MODAL.SELECT.ADD_CAREPROFESSIONAL}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOutsideClick(!isOutsideClick)
                }}
                className={[
                    'form-select py-3'
                ].join(' ')}
            >
                {
                    <span className={'opacity-50'}>
                        {strings.app?.text.select || ''}
                    </span>
                }

            </div>
            {/* popup window to get a list of carepath with checkboxes */}

            {isOutsideClick && <div
                ref={(e) => {
                    containerRef.current = e
                }}
                className={[
                    'dropdown-list mt-4 px-5 py-6 w-100',
                    isFromEditPage ? '' : 'position-absolute'
                ].join(' ')}>
                {/* search tab and filter icon */}
                <div className={['row justify-content-between mb-4 ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box '}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* wouldn't make sense to link a careprofessional to a
                department that isn't selected. */}
                {
                    department
                        ? <div className={'btn-group-checkbox-list row row-cols-1'}>
                            {/* linked first, then linked but not you, then unlinked */}
                            {getLinkedCareprofessionalsResponse?.data.careprofessionals.length
                                ? <h6 className={'mb-3'}>{strings.fmt
                                    ?.text.facility.add.steps['2'].linked}</h6>
                                : ''}
                            {LinkedCareProfessionalOptions}
                            {getUnlinkedCareprofessionalsResponse
                                ?.data.careprofessionals.length
                                ? <h6 className={'mb-3'}>{strings.fmt
                                    ?.text.facility.add.steps['2'].unlinked}</h6>
                                : ''}
                            {UnlinkedCareProfessionalOptions}
                        </div>
                        : <></>
                }

            </div>}

        </div>
    </div>

    return department
        ? <>
            {result}
            <QuickAddCareprofessional
                setDropdownsUpdateTrigger={setDropdownsUpdateTrigger}
                department={department}
            /></>
        : <></>
}

export default CareProfessionalsInterface
