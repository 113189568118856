
import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    DESKTOP_RESPONSIVE_LIMIT,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    useGetMotivationalMessageMutation,
    useGetOverallProgressMutation,
    useGetTodayMutation
} from '@careplan/api'
import { MODULE_VERSION } from '@careplan/constants'
import { PersonalCareplanData, TodaySearchActions, TodaySearchState } from '@careplan/type'
import { push } from '@lagunovsky/redux-react-router'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { TokenData } from '@app/types/type.token'

import {
    CardImageWrapper,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/recoveryPlan/Components'
import { format, fromUnixTime } from 'date-fns'
import produce from 'immer'
import { Progress } from 'reactstrap'

import {
    FloatingArrow,
    FloatingFocusManager,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
    useId
} from '@floating-ui/react'
import { useMediaQuery } from 'react-responsive'

const ProgressBarPopover = ({ actualProgress, progressNumber }: {
    actualProgress: number | undefined
    progressNumber: number | undefined
}) => {
    const arrowRef = useRef(null)
    const strings = useAppSelector(selectStrings)

    const { refs, floatingStyles, context } = useFloating({
        open: true,
        placement: 'bottom',
        middleware: [
            offset(10),
            flip({ fallbackAxisSideDirection: 'end' }),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate

    })

    const headingId = useId()

    return <>
        <Progress
            className={'actual-recovery'}
            barClassName={'actual-recovery-bar'}
            value={actualProgress}
        >
        </Progress>

        <div className={'expected-recovery'} ref={refs.setReference}>
            <Progress
                barClassName={'expected-recovery-bar'}
                value={progressNumber}
            >
            </Progress>
        </div>
        { progressNumber !== undefined && <FloatingFocusManager context={context} modal={false}>
            <div
                className={'floating-ui'}
                ref={refs.setFloating}
                style={{
                    ...floatingStyles
                }}
                aria-labelledby={headingId}
            >
                <FloatingArrow ref={arrowRef} context={context}
                />
                {
                    <span className={'fw-light progress-label'}>{[
                        [
                            progressNumber,
                            '%'
                        ].join(''),
                        strings.careplanPatient?.text.careplanMenu.progress_bars.expected
                    ].join(' ')}</span>
                }
            </div>

        </FloatingFocusManager>}

    </>
}

const TodayPage = () => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const validateRoute = useValidateRoute()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const dateFormats = useAppSelector(selectDateFormats)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const [getToday, getTodayMutation] = useGetTodayMutation()
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()
    const [getMotivationalMessage, getMotivationalMessageMutation] =
     useGetMotivationalMessageMutation()

    const rootRef = useRef<HTMLDivElement | null>(null)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const isMedium = useMediaQuery({
        minWidth: MOBILE_RESPONSIVE_LIMIT,
        maxWidth: DESKTOP_RESPONSIVE_LIMIT
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const [todaySearchState, todaySearchDispatch] = useReducer(
        (state: TodaySearchState, action: TodaySearchActions) => {
            switch (action.type) {
                case 'UPDATE_VIEW_MODE': {
                    return produce(state, draft => {
                        draft.viewMode = action.value
                    })
                } case 'UPDATE_COLLAPSIBLE': {
                    return produce(state, draft => {
                        draft.collapsible[action.value.key] = action.value.value
                    })
                }
            }
        }, {
            viewMode: 'tile',
            collapsible: {
                incomplete: true,
                complete: true,
                daily_goal: false
            }
        }
    )

    useEffect(() => {
        if (isMobile) {
            todaySearchDispatch({
                type: 'UPDATE_VIEW_MODE',
                value: 'row'
            })
        }
    }, [isMobile])

    const unsubscribeGetToday = () => {
        const unsubscribeMutation = getToday({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMotivationalMessage = () => {
        const unsubscribeMutation = getMotivationalMessage({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetToday()
        unsubscribeGetOverallProgress()
        unsubscribeGetMotivationalMessage()

        let getTodayPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getMotivationalMessagePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getToday.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getTodayPromise = getToday({
                            authToken: newToken.value,
                            data: {
                                personalCarePlanId: '6496ef5feb57ee0227276c40'

                            }
                        })
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }

                    const isValid3 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths
                            .getMotivationalMessage.path,
                        true
                    )

                    if (isValid3 && newToken.value) {
                        getMotivationalMessagePromise = getMotivationalMessage({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getTodayPromise && getTodayPromise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
            getMotivationalMessagePromise && getMotivationalMessagePromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        if (getTodayMutation.error) {
            const message = getErrorText(getTodayMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getTodayMutation.error])

    // so that the cards will have 0 and then the value again
    const DebouncedProgressBarComponent = ({ valueToPass }: {valueToPass: number}) => {
        const [value, setValue] = useState(0)

        // Define a debounced function to update the state with the provided value
        const debouncedUpdateValue = _.debounce((newValue) => {
            setValue(newValue)
        }, 1000) // Debounce for 2 seconds

        useEffect(() => {
            // Trigger the debounced function with the provided value
            debouncedUpdateValue(valueToPass)

            // Cleanup the debounce function on component unmount
            return () => {
                debouncedUpdateValue.cancel()
            }
        }, [valueToPass]) // Re-run the effect when valueToPass changes

        return <Progress
            value={value}
        >
        </Progress>
    }

    const EmptyCard = <div className={todaySearchState.viewMode === 'row'
        ? 'col-12'
        : todaySearchState.viewMode === 'tile'
            ? 'col'
            : 'col'}>
        {/* card-activity will be something else soon */}
        <div className={'card careplan-card h-100'}>
            <div className={'card-body position-relative'}>
                <div className={'row'}>
                    <div className={[
                        todaySearchState.viewMode === 'row'
                            ? 'col'
                            : todaySearchState.viewMode === 'tile' ? 'col-12' : 'col',
                        isMobile ? 'mt-3' : 'mb-3 mt-3'
                    ].join(' ')}>
                        <div className={'justify-content-center row'}>
                            <div className={'col-auto'}>
                                <span className={
                                    [
                                        'title',
                                        (todaySearchState.viewMode === 'tile')
                                            ? 'tile-complete'
                                            : '',
                                        'text-truncate-2 w-100',
                                        'mb-2'
                                    ].join(' ')
                                }>
                                    {strings.careplanPatient?.text.today_page.no_tasks_to_do}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

    const menu = useMemo(() => {
        // console.log(groupArrayByTime(primarySort, 'week'))
        // console.log(groupArrayByTime(primarySort, 'day'))

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        )

        const arr = _.isArray(getTodayMutation.data?.data)
            ? getTodayMutation.data?.data || []
            : []

        // this data needs to be sorted by doneWhen.
        const sorted = _.orderBy(arr,
            ['carePlanStep.doneWhen',
                'carePlanStep.sequenceStep'], ['asc']) as PersonalCareplanData[]

        // step 2. extract into three groups. incomplete, complete.

        const incompleteRecords = _.filter(sorted, (o) => o.stepState !== 'complete')
        const completeRecords = _.filter(sorted, (o) => o.stepState === 'complete')

        const renderCard = (obj: PersonalCareplanData, index: number,
            stepState: 'complete' | 'incomplete') => {
            // Generate a unique key for each group.
            const innerKey = index

            /**
             * state 1 is checked aka you clicked on complete careplan step
             */
            const isComplete = (obj.progress?.percentComplete || 0) >= 100 &&
            obj.stepState === 'complete'

            /**
             * state 2 is locked
             */
            const locked = obj.stepState === 'locked'

            /** state 3 is identified type but no content  */
            const uncertain = obj.stepState === 'uncertain'

            /** as for state 3.1, rely on cardIcon and cardType */

            /** state 4 - is a full progress bar meaning you did all the
             * lessons but haven't marked as complete yet.
             * Just display the progress bar as is */

            /** state 5 - is a progress bar that's incomplete.
             * Just display the progress bar as is */

            /**
             * state 6 where someone marked the content as
             * 'incomplete' he "i can't do it" button
             */
            const isIncomplete = obj.stepState === 'incomplete'

            let colImageClassName = todaySearchState.viewMode === 'row'
                ? 'col-auto'
                : todaySearchState.viewMode === 'tile' ? 'col-12' : 'col'

            let colContentClassName = todaySearchState.viewMode === 'row'
                ? 'col'
                : todaySearchState.viewMode === 'tile' ? 'col-12' : 'col'

            let imageWrapperWidth = todaySearchState.viewMode === 'row'
                ? '150px'
                : todaySearchState.viewMode === 'tile' ? '100%' : '100%'

            let imageWrapperHeight = todaySearchState.viewMode === 'tile' &&
            stepState === 'complete'
                ? 70
                : 120

            if (isMobile && todaySearchState.viewMode === 'row') {
                colImageClassName = 'col-12'

                colContentClassName = 'col-12'

                imageWrapperWidth = '100%'

                imageWrapperHeight = 120
            }

            const progressBar = obj.progress
                ?.percentComplete
                ? <DebouncedProgressBarComponent
                    valueToPass={_.round(obj.progress
                        ?.percentComplete || 0)}
                />
                : ''

            const renderDate = <div className={'row'}>
                <div className={'col-auto'}>
                    <span className={'subtitle'}>
                        {strings.careplanPatient?.text.to_do_on}
                    </span>
                    <span className={'subtitle'}>
                        {format(fromUnixTime(
                            obj.carePlanStep?.doneWhen || 0
                        ), dateFormats.format1)}
                    </span>
                </div>
            </div>

            const cardTitle = <span className={
                [
                    'title',
                    (todaySearchState.viewMode === 'tile' && stepState === 'complete')
                        ? 'tile-complete'
                        : '',
                    'text-truncate-2 w-100',
                    'mb-2'
                ].join(' ')
            }>
                {obj?.stepName}
            </span>

            const completionStatusFlag = obj.stepState !== 'open'
                ? <div className={[
                    'completion-status-flag text-center',
                    isComplete
                        ? 'complete'
                        : locked
                            ? 'locked'
                            : uncertain
                                ? 'uncertain'
                                : isIncomplete ? 'incomplete' : ''
                ].join(' ')}>
                    <span className={'fw-semibold text-capitalize'}>
                        {
                            isComplete
                                ? strings.careplanPatient
                                    ?.text.careplanMenu.stepState.complete
                                : locked
                                    ? strings.careplanPatient
                                        ?.text.careplanMenu.stepState.locked
                                    : uncertain
                                        ? strings.careplanPatient
                                            ?.text.careplanMenu.stepState.uncertain
                                        : isIncomplete
                                            ? strings.careplanPatient
                                                ?.text.careplanMenu.stepState.incomplete
                                            : ''
                        }
                    </span>
                </div>
                : ''

            let stepTypeString = ''

            if (obj.stepType === 'activity') {
                stepTypeString = strings.careplanPatient
                    ?.text.careplanMenu.filters.activity || ''
            } else if (obj.stepType === 'content') {
                stepTypeString = strings.careplanPatient
                    ?.text.careplanMenu.filters.content || ''
            } else if (obj.stepType === 'lesson') {
                stepTypeString = strings.careplanPatient
                    ?.text.careplanMenu.filters.lesson || ''
            } else if (obj.stepType === 'uncertain') {
                stepTypeString = strings.careplanPatient
                    ?.text.careplanMenu.filters.uncertain || ''
            } else if (obj.stepType === 'reasoning') {
                stepTypeString = strings.careplanPatient
                    ?.text.careplanMenu.filters.reasoning || ''
            }

            const cardBody = <div className={'card-body position-relative'}>
                {/* put status flag here. if obj.stepState === open don't render */}
                {completionStatusFlag}
                <div className={'row'}>
                    {/* if on row and incomplete or complete, 1 row. */}
                    <div className={colImageClassName}>
                        <CardImageWrapper className={''}

                            url={obj.imageThumbnail || ''}
                            style={{
                                width: imageWrapperWidth,
                                height: imageWrapperHeight
                            }}
                        >
                        </CardImageWrapper>
                    </div>
                    <div className={[
                        colContentClassName,
                        isMobile ? 'mt-3' : 'mb-3 mt-3'
                    ].join(' ')}>
                        <div className={'row'}>
                            <div className={'col-auto card-title'}>
                                {cardTitle}
                            </div>
                        </div>
                        {/* if it's incomplete, show date */}
                        {/* only be the case on tile mode. */}
                        {
                            todaySearchState.viewMode === 'row'
                                ? renderDate
                                : stepState === 'incomplete'
                                    ? renderDate
                                    : ''
                        }

                        <div className={
                            'align-items-center justify-content-between row'
                        }>
                            <div className={'col-auto'}>
                                {/* change color based on type */}
                                <small className={[
                                    'fw-bold content-type',
                                    obj.stepType
                                ].join(' ')}>
                                    {stepTypeString}
                                </small>
                            </div>
                            {
                                todaySearchState.viewMode === 'row'
                                    ? <div className={'col-4'}>
                                        {progressBar}
                                    </div>
                                    : stepState === 'incomplete'
                                        ? index
                                            ? ''
                                            : <div className={'col-4'}>
                                                {progressBar}
                                            </div>
                                        : ''
                            }
                            {/* don't show the prgoress bar at all in complete */}
                            {
                                stepState === 'complete' ? '' : ''
                            }

                        </div>
                    </div>
                </div>
            </div>

            // row on both complete and incomplete.
            // then check for complete and incomplete
            let colArrangement = todaySearchState.viewMode === 'row'
                ? 'col-12'
                : todaySearchState.viewMode === 'tile'
                    ? stepState === 'incomplete'
                        ? index
                            ? 'col'
                            : 'col-12'
                        : stepState === 'complete'
                            ? 'col'
                            : 'col'
                    : 'col'

            if (isMobile && todaySearchState.viewMode === 'row') {
                // col works because col-auto would get the width 100% from the container
                // from a few parents high.
                colArrangement = 'col-auto'
            }

            return (<div className={colArrangement} key={innerKey}
                onClick={() => {
                // now dispatch to another page.

                    if (obj.stepState !== 'locked') {
                        if (obj.carePlanStep
                            ?.personalCareplanStepId) {
                            const foundRoute = validateRoute(
                                activeModules.arr,
                                MODULE_TABLE.careplanPatient.moduleName,
                                MODULE_TABLE.careplanPatient
                                    .routes.careplanStep,
                                true
                            )

                            console.log('going to careplanStepId path')
                            if (foundRoute) {
                            // now push but replace :personalCareplanId
                            // with something else
                            // AND you need to include the 4 ids you passed in.
                            // and that you are able to refresh that
                            // page at that time.

                                /** new: set the selected card so the
                         * controller has access to some items
                         * like stepState and progress.percentComplete
                         */

                                dispatch(push(
                                    _.replace(
                                        _.replace(
                                            foundRoute.route,
                                            ':personalCareplanStepId',
                                            obj.carePlanStep.personalCareplanStepId
                                        ),
                                        ':personalCareplanId',
                                        obj.personalCareplanId || ''
                                    )
                                ))
                            }
                        } else {
                        //
                        }
                    } else {
                        toast.error(
                            strings.careplanPatient?.message.error.content_locked || '',
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                }}>
                {/* card-activity will be something else soon */}
                <div className={'card careplan-card h-100'}>
                    {cardBody}
                </div>

            </div>
            )
        }

        // the way you render cards here are different in mobile versions.
        // so just using a isMobile useMediaQuery hook will do.
        const incompleteChildren = _.map(incompleteRecords, (o, index) => {
            return renderCard(o, index, 'incomplete')
        })
        const completeChildren = _.map(completeRecords, (o, index) => {
            return renderCard(o, index, 'complete')
        })

        const content = <>
            <div className={['row justify-content-between align-items-center g-3 ',
                'careplan-group-container', isMobile ? 'mb-3' : 'mb-5'].join(' ')}>
                <div className={'col-auto'}>
                    <div className={'row align-items-center'}>
                        <div className={'col-auto'}>
                            <span className={[
                                'd-inline-block mb-0 title'
                            ].join(' ')}>
                                {strings.careplanPatient?.text.today_page.tasks_to_do}
                            </span>
                        </div>
                        <div className={'col-auto ps-0'}>
                            {/* a circle div and then a text inside */}
                            <div className={'group-count'}>
                                <span className={[
                                    'd-inline-block mb-0 title'
                                ].join(' ')}>
                                    {incompleteRecords.length
                                        ? incompleteRecords.length
                                        : '0'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-auto'}>
                    {/* collapse all and expand all button */}
                    <button
                        type={'button'}
                        className={[
                            'btn btn-round btn-rounded',
                            !isMobile ? ' me-3' : ''
                        ].join('')}
                        onClick={() => {
                            todaySearchDispatch({
                                type: 'UPDATE_COLLAPSIBLE',
                                value: {
                                    value: !todaySearchState.collapsible.incomplete,
                                    key: 'incomplete'
                                }
                            })
                        }}
                    >
                        <i
                            className={[
                                'fa-light',
                                todaySearchState.collapsible.incomplete
                                    ? 'fa-chevron-up'
                                    : ' fa-chevron-down'
                            ].join(' ')}
                            aria-hidden={'true'}>
                        </i>
                    </button>

                    {
                        !isMobile
                            ? <>
                                <button
                                    type={'button'}
                                    className={[
                                        'btn btn-round btn-rounded me-3',
                                        todaySearchState.viewMode === 'tile' ? 'selected' : ''
                                    ].join(' ')}
                                    onClick={() => {
                                        todaySearchDispatch({
                                            type: 'UPDATE_VIEW_MODE',
                                            value: 'tile'
                                        })
                                    }}
                                >
                                    <i
                                        className={'fa-light fa-grid-2'}
                                        aria-hidden={'true'}>
                                    </i>
                                </button>
                                <button
                                    type={'button'}
                                    className={[
                                        'btn btn-round btn-rounded me-3',
                                        todaySearchState.viewMode === 'row' ? 'selected' : ''
                                    ].join(' ')}
                                    onClick={() => {
                                        todaySearchDispatch({
                                            type: 'UPDATE_VIEW_MODE',
                                            value: 'row'
                                        })
                                    }}
                                >
                                    <i
                                        className={[
                                            'fa-light', 'fa-list-ul'
                                        ].join(' ')}
                                        aria-hidden={'true'}>
                                    </i>
                                </button> </>
                            : ''
                    }

                </div>

            </div>
            {/* render content here */}
            {todaySearchState.collapsible.incomplete
                ? <div className={['card-grid row g-3 incomplete',
                    !isMobile
                        ? todaySearchState.viewMode === 'row'
                            ? 'row-cols-1'
                            : todaySearchState.viewMode === 'tile'
                                ? 'row-cols-2'
                                : ''
                        : todaySearchState.viewMode === 'row'
                            ? ''
                            : ''
                ].join(' ')}>
                    {incompleteChildren.length ? incompleteChildren : EmptyCard}
                </div>
                : ''}

            <div className={['row justify-content-between',
                'careplan-group-container', isMobile ? 'mb-3' : 'mb-5'].join(' ')}>
                <div className={'col-auto mb-md-0 mb-3 fw-bold'}>
                    <div className={'row align-items-center'}>
                        <div className={'col-auto'}>
                            <span className={[
                                'd-inline-block mb-0 title'
                            ].join(' ')}>
                                {strings.careplanPatient?.text.careplanMenu.stepState.complete}
                            </span>
                        </div>
                        <div className={'col-auto ps-0'}>
                            {/* a circle div and then a text inside */}
                            <div className={'group-count'}>
                                <span className={[
                                    'd-inline-block mb-0 title'
                                ].join(' ')}>
                                    {completeRecords.length
                                        ? completeRecords.length
                                        : '0'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-auto'}>
                    {/* collapse all and expand all button */}
                    <button
                        type={'button'}
                        className={[
                            'btn btn-round btn-rounded me-3'
                        ].join('')}
                        onClick={() => {
                            todaySearchDispatch({
                                type: 'UPDATE_COLLAPSIBLE',
                                value: {
                                    value: !todaySearchState.collapsible.complete,
                                    key: 'complete'
                                }
                            })
                        }}
                    >
                        <i
                            className={[
                                'fa-light',
                                todaySearchState.collapsible.complete
                                    ? 'fa-chevron-up'
                                    : ' fa-chevron-down'
                            ].join(' ')}
                            aria-hidden={'true'}>
                        </i>
                    </button>
                </div>

            </div>
            {/* render content here */}
            {todaySearchState.collapsible.complete
                ? <div className={['card-grid row g-3 complete',
                    !isMobile
                        ? todaySearchState.viewMode === 'row'
                            ? 'row-cols-1'
                            : todaySearchState.viewMode === 'tile'
                                ? 'row-cols-2'
                                : ''
                        : todaySearchState.viewMode === 'row'
                            ? ''
                            : ''
                ].join(' ')}>
                    {completeChildren}
                </div>
                : ''}
        </>

        // Render the grouped activities with their corresponding order number.
        return (
            getTodayMutation.isLoading
                ? LoadingContent
                : getTodayMutation.isSuccess
                    ? content
                    : JSON.stringify(getTodayMutation.error)
        )
    }, [getTodayMutation, strings, todaySearchState, isMobile])

    const columnOne = <div>

        {
            (!isMobile && !isMedium) && <span className={[
                'my-3 d-inline-block',
                isMobile ? 'h3' : 'h1'
            ].join(' ')}>
                {strings.careplanPatient?.text.navigation.today}
            </span>

        }

        <div className={'container-fluid'}>
            <div className={'row'}>
                <div className={[
                    'col-12 mx-auto',
                    isMobile ? 'px-0' : ''
                ].join(' ')}>
                    {(isMobile || isMedium) && <hr />}
                    {menu}
                </div>
            </div>
        </div>
    </div>

    const progressNumber: number | undefined = getOverallProgressMutation.data
        ?.data.progressData.expectedProgress

    const MainProgressBar = <div>
        <ProgressBarPopover
            actualProgress={getOverallProgressMutation.data?.data.progressData.actualProgress}
            progressNumber={progressNumber}
        />
    </div>

    const TodayProgressBar = useMemo(() => {
        const columnCount = 8
        const stepTypeCounts: Record<string, number> = {}
        const stepTypeCountsFinished: Record<string, number> = {}

        // Iterate through the array and count occurrences
        const arr = _.isArray(getTodayMutation.data?.data)
            ? getTodayMutation.data?.data || []
            : []

        arr.forEach((item) => {
            const { stepType, stepState } = item
            if (stepType) {
                stepTypeCounts[stepType] = (stepTypeCounts[stepType] || 0) + 1

                if (stepState === 'complete') {
                    stepTypeCountsFinished[stepType] = (stepTypeCountsFinished[stepType] || 0) + 1
                }
            }
        })

        // Include other stepTypes with values zero in stepTypeCountsFinished
        Object.keys(stepTypeCounts).forEach((stepType) => {
            if (!(stepType in stepTypeCountsFinished)) {
                stepTypeCountsFinished[stepType] = 0
            }
        })

        const uniqueStepTypes = Object.keys(stepTypeCounts)
        const allTotal = _.sum(Object.values(stepTypeCounts))
        const finishedTotal = _.sum(Object.values(stepTypeCountsFinished))

        // console.log(allTotal)
        // console.log(finishedTotal)
        return <div>
            <Progress
                className={'main-recovery'}
                barClassName={'main-recovery-bar'}
                value={(finishedTotal / allTotal) * 100 }
            >
            </Progress>
            <div className={'container progress-divider'}>
                <div className={'row'}>
                    {Array.from({ length: columnCount }).map((_, index) => {
                        const classes = ['col']

                        if (index !== columnCount - 1 && index !== 3) {
                            classes.push('border-end')
                        }

                        if (index >= 4) {
                            classes.push('d-none', 'd-lg-block')
                        }

                        if (index === 3) {
                            classes.push('border-lg-end')
                        }

                        return (
                            <div key={index} className={classes.join(' ')}>
                            </div>
                        )
                    })}
                </div>
            </div>
            {
                (isMobile && todaySearchState.collapsible.daily_goal) ||
                (!isMobile && todaySearchState.collapsible.daily_goal)
                    ? <div className={'my-3'}>
                        {
                            _.map(uniqueStepTypes, (stepType, index) => {
                                const width = stepTypeCounts[stepType] !== 0
                                    ? (stepTypeCountsFinished[stepType] /
                                    stepTypeCounts[stepType]) * 100
                                    : 0

                                // the step type should be what was translated.

                                let stepTypeString = ''

                                if (stepType === 'activity') {
                                    stepTypeString = strings.careplanPatient
                                        ?.text.careplanMenu.filters.activity || ''
                                } else if (stepType === 'content') {
                                    stepTypeString = strings.careplanPatient
                                        ?.text.careplanMenu.filters.content || ''
                                } else if (stepType === 'lesson') {
                                    stepTypeString = strings.careplanPatient
                                        ?.text.careplanMenu.filters.lesson || ''
                                } else if (stepType === 'uncertain') {
                                    stepTypeString = strings.careplanPatient
                                        ?.text.careplanMenu.filters.uncertain || ''
                                } else if (stepType === 'reasoning') {
                                    stepTypeString = strings.careplanPatient
                                        ?.text.careplanMenu.filters.reasoning || ''
                                }

                                return <div className={'align-items-center mt-1 row'} key={index}>
                                    <div className={'col-6 col-md step-type-label'}>
                                        <div className={'d-inline-block'}>
                                            <span className={'text-capitalize'}>{
                                                stepTypeString
                                            }</span>
                                            <span className={'ps-2'}>{'('}{
                                                stepTypeCounts[stepType]}{')'}</span>
                                        </div>
                                    </div>
                                    <div className={'col-6 col-md'}>
                                        <Progress
                                            className={'step-type-recovery'}
                                            barClassName={'step-type-recovery-bar'}
                                            value={width}
                                        >
                                        </Progress>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    : ''
            }

        </div>
    }, undefined)

    const columnTwo = useMemo(() => {
        const pg1 = <div className={[
            'card progress-card',
            getMotivationalMessageMutation.data?.data.show === false ? 'mt-4' : '',
            isMobile ? 'pb-0' : ''
        ].join(' ')}>
            <div className={[
                'card-body',
                isMobile ? 'pb-0 pt-1 px-2' : ''
            ].join(' ')}>
                <h4 className={''}>
                    {[
                        getOverallProgressMutation.data?.data.progressData.actualProgress,
                        '% '
                    ].join('') + strings.careplanPatient?.text.careplanMenu.progress_bars.recovered}
                </h4>
                {MainProgressBar}
            </div>
        </div>

        const pg2 = <div className={[
            'card today-progress-card mt-4',
            isMobile ? 'pb-0' : ''
        ].join(' ')}>
            <div className={[
                'card-body',
                isMobile ? 'pb-0 pt-1 px-2' : ''
            ].join(' ')}>
                <div className={'align-items-center justify-content-between row mb-3'}>
                    <div className={'col-auto'}>
                        <h4 className={' mb-0'}>
                            {strings.careplanPatient?.text.careplanMenu.progress_bars.daily_goal}
                        </h4>
                    </div>
                    <div className={'col-auto'}>
                        <button
                            type={'button'}
                            className={[
                                'btn btn-round btn-rounded me-3'
                            ].join('')}
                            onClick={() => {
                                todaySearchDispatch({
                                    type: 'UPDATE_COLLAPSIBLE',
                                    value: {
                                        value: !todaySearchState.collapsible.daily_goal,
                                        key: 'daily_goal'
                                    }
                                })
                            }}
                        >
                            <i
                                className={[
                                    'fa-light',
                                    todaySearchState.collapsible.daily_goal
                                        ? 'fa-chevron-up'
                                        : ' fa-chevron-down'
                                ].join(' ')}
                                aria-hidden={'true'}>
                            </i>
                        </button>
                    </div>
                </div>
                {TodayProgressBar}
            </div>
        </div>

        return <div>

            {
                (isMobile || isMedium) && <span className={[
                    'my-3 d-inline-block',
                    isMobile ? 'h3' : 'h1'
                ].join(' ')}>
                    {strings.careplanPatient?.text.navigation.today}
                </span>

            }

            {
                getMotivationalMessageMutation.data?.data.show === true
                    ? <div className={'status-card card border-0'}>
                        <h4 className={''}>
                            {getMotivationalMessageMutation.data?.data.heading}
                        </h4>
                        <span>
                            {getMotivationalMessageMutation.data?.data.message}
                        </span>
                    </div>
                    : ''
            }

            {
                isMedium
                    ? <div className={'row'}>
                        <div className={'col'}>{pg1}</div>
                        <div className={'col'}>{pg2}</div>
                    </div>
                    : <div>
                        {pg1}
                        {pg2}
                    </div>
            }

        </div>
    }, undefined)

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0'} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        {/* put header image here */}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'main-content'}>
            {/* container with a col width and then set to center */}
            <div className={'container mx-auto'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-auto careplan-cards-container mb-5'}>
                        {columnOne}
                    </div>
                    <div className={'col-auto progress-bars-container ms-5'}>
                        {columnTwo}
                    </div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mediumResult = <>
        {/* put header image here */}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'main-content'}>
            {/* container with a col width and then set to center */}
            <div className={'container mx-auto'}>
                <div className={'row justify-content-center'}>
                    {columnTwo}
                    <div className={'pb-5'}>{columnOne}</div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-6'}>
            {columnTwo}
            <div className={'pb-5'}>{columnOne}</div>
        </div>
    </>

    return (<div className={[
        'today-page', !isMobile ? 'position-relative' : ''
    ].join(' ')} ref={rootRef}>
        {
            isMobile ? mobileResult : isMedium ? mediumResult : desktopResult
        }

    </div>)
}

export default TodayPage
