
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { GPACareplanStepIdDetails } from '@doc/type'
import { QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const Toggle = ({
    reasoningObj
}: ComponentProps) => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue

    const input = useMemo(() => {
        /**
         * render a list of checkboxes BUT disable the unchecked boxes
         * once the value of questionAnswersAllowed has reached.
         * expected answers are the following:
         * - if questionAnswersAllowed === 1, answerValue should be a string.
         * - if questionAnswersAllowed > 1, should be [string1, string2]
         */

        // console.log(`answers so far: ${ answerValue }`)

        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', reasoningObj.id
            ].join('')

            // if there are too many choices, use col-6
            return <div className={'col'} key={key}>
                <div className={'align-items-center row'}>
                    <div className={'form-check form-switch settings-switch col-auto'}>
                        <input className={'form-check-input'}
                            type={'checkbox'} role={'switch'}
                            checked={answerValue?.[i] || false}
                            onChange={() => {

                            }}
                        />
                    </div>
                    <div className={'title col'}>{
                        obj.acqName
                    }</div>

                </div>
            </div>
        }

        return <div className={'container-fluid'}>
            <div className={'g-3 my-3 row row-cols-1'}
                role={'group'}>
                {
                    _.map(questionData?.questionAnswers, renderInputs)
                }
            </div>
        </div>
    }, [])

    return <div className={['question-toggle', isMobile ? 'px-0' : 'px-4'].join(' ')}>
        {input}
    </div>
}

export default Toggle
