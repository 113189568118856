/* eslint-disable max-len */
import { useGPACareplanStepIdDetailsMutation } from '@doc/api'
// eslint-disable-next-line max-len
import Checkbox from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/open-list/answerTypes/Checkbox'
// eslint-disable-next-line max-len
import InputList from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/open-list/answerTypes/InputList'
// eslint-disable-next-line max-len
import MultiList from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/open-list/answerTypes/MultiList'
// eslint-disable-next-line max-len
import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import Open from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/open-list/answerTypes/Open'
// eslint-disable-next-line max-len
import Radio from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/open-list/answerTypes/Radio'
import { GPACareplanStepIdDetails } from '@doc/type'
import { OpenListValues, OpenMultiQuestionOption, QuestionHeader } from '@reasonWithMe/type'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useState } from 'react'

import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

// import _ from 'lodash'
// eslint-disable-next-line max-len

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
    reasoningModal: {
        reasoningSessionId: string;
        reasoningSetId: string;
        addedOn: number;
    } | undefined,
    userId: string | undefined
    careplanStepId: string | undefined
}

// multi-list is also included here so it should be ok
export interface BodyMapPreviousQuestion {
    acqName?: string
    acqValue?: string
    acqCoords?: { x: number, y: number }[]
    acqPosition?: string,
    acqPositionString?: string,

}

export interface OpenListPreviousQuestions {
    id: string;
    inputs: {
        options: {
            answerValue: string | number;
            answerName: string;
            answerType: string;
            answerEditable: boolean;
        };
        answerValue: string | boolean | string[];
    }[];
}

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

const OpenList = ({
    reasoningObj, reasoningModal, userId, careplanStepId
}: ComponentProps) => {
    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue as {
        answers: OpenListPreviousQuestions[];
        userQuestionIdentifier?: string | undefined;
        questionUseAnswerFrom?: string | undefined;
    }

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()

    const [
        gPACareplanStepIdDetails,
        gPACareplanStepIdDetailsMutation
    ] = useGPACareplanStepIdDetailsMutation()

    const reasoningData = gPACareplanStepIdDetailsMutation.data as
     GPACareplanStepIdDetails['response']['reasoningPrevious']

    const unsubscribeGPACareplanStepIdDetails = () => {
        const unsubscribeMutation = gPACareplanStepIdDetails({
            urlParams: {},
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGPACareplanStepIdDetails()

        const promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let gPACareplanStepIdDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid && reasoningObj.questionData
                .previousQuestionData?.questionUseAnswerFrom) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.gPACareplanStepIdDetails.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        gPACareplanStepIdDetailsPromise = gPACareplanStepIdDetails({
                            authToken: newToken.value,
                            data: {
                                type: 'reasoningPrevious',
                                userId: userId || '',
                                reasoningSessionId: reasoningModal?.reasoningSessionId,
                                questionId: reasoningObj.questionData
                                    .previousQuestionData?.questionUseAnswerFrom
                            },
                            urlParams: {
                                careplanStepId: careplanStepId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            gPACareplanStepIdDetailsPromise && gPACareplanStepIdDetailsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [])

    const [formsState] = useReducer((
        state: OpenListValues[],
        action: | {type: ''}
    ) => {
        switch (action.type) {
            default:
                return state
        }
    }, _.map(answerValue.answers, (o) => {
        return {
            id: o.id,
            inputs: _.map(o.inputs, o => {
                return {
                    ...o.options,
                    userTypedAnswer: o.answerValue

                }
            })
        }
    }))

    // const [questionHeaders] = useState<QuestionHeader[]>(formsState[0]?.inputs)
    const [questionHeaders, setQuestionHeaders] = useState<QuestionHeader[]>([])

    useEffect(() => {
        const previousQuestionData1 = _.cloneDeep(reasoningData?.data.previousQuestionData)
        const previousQuestionData2 = _.cloneDeep(questionData.previousQuestionData)

        let combinedHeaders: QuestionHeader[] = []

        if (
            (previousQuestionData2?.questionType === 'open-list' ||
            // if there's nothing.
            !previousQuestionData2?.questionType)
        ) {
            const questionAnswers: OpenListPreviousQuestions[] = previousQuestionData1
                ?.questionAnswers?.answers || []

            combinedHeaders = [
                ..._.flatMap(questionAnswers?.[0]?.inputs || [], (p) => {
                    return p.options
                }),
                ..._.map(questionData?.questionAnswers, (o) => {
                    return {
                        answerValue: (Number(o.answerValue) +
                        ((questionAnswers?.[0]?.inputs?.length) || 0)) as string | number,
                        answerName: o.answerName || '',
                        answerType: o.answerType || '',
                        answerEditable: o.answerEditable || true
                    }
                })
            ]

            console.log('combined headers: ', combinedHeaders)

            const multiListIndex = combinedHeaders
                .findIndex(item => item.answerType === 'multi-list')

            if (multiListIndex !== -1) {
                // step 1. make sure that multi-list is the only column in combinedHeaders.
                combinedHeaders = [
                    combinedHeaders[multiListIndex]
                ]

                // OK THAT'S IT.
            }

            const inputListSplitIndex = combinedHeaders
                .findIndex(item => item.answerType === 'input-list-split')

            if (inputListSplitIndex !== -1) {
            // STEP 2: Find indices of input and input-list BEFORE input-list-split

                const inputListObj = combinedHeaders.find(o => o.answerType === 'input-list')

                combinedHeaders.slice(0, inputListSplitIndex)
                    .filter(item => item.answerType === 'input' || item.answerType === 'input-list')

                // STEP 3: Remove input-list-split and input-list
                combinedHeaders.splice(inputListSplitIndex, 1) // Remove input-list-split

                // Filter out input-list before split
                const inputListIndices = combinedHeaders.map((item, index) => ({
                    index,
                    answerType: item.answerType
                }))
                    .filter(item => item.answerType === 'input-list')

                inputListIndices
                    .forEach(item => combinedHeaders.splice(item.index, 1)) // Remove input-list

                // STEP 4: Add new input header AFTER the first input found
                const firstInputIndex = combinedHeaders
                    .findIndex(item => item.answerType === 'input')
                const firstInput = combinedHeaders.find(item => item.answerType === 'input')

                if (firstInputIndex !== -1 && inputListObj && firstInput) {
                    combinedHeaders.splice(firstInputIndex + 1, 0, {
                        answerValue: firstInputIndex + 1,
                        answerName: inputListObj.answerName,
                        answerType: firstInput.answerType,
                        answerEditable: firstInput.answerEditable
                    })
                }
            }

            combinedHeaders.sort((a, b) => {
                const valueA = typeof a.answerValue === 'string'
                    ? parseInt(a.answerValue, 10)
                    : a.answerValue
                const valueB = typeof b.answerValue === 'string'
                    ? parseInt(b.answerValue, 10)
                    : b.answerValue

                return valueA - valueB
            })

            // Step 2: Reassign sequential numbers to answerValue
            combinedHeaders.forEach((item, index) => {
                item.answerValue = index + 1
            })

            console.log(combinedHeaders)
        } else if (
            previousQuestionData2?.questionType === 'multi-question'
        ) {
            const filteredAnswers = (previousQuestionData1?.questionAnswers?.answers || []
            )
                .flatMap((item: OpenMultiQuestionOption) => {
                    const arr = item?.answers || []
                    return arr.flatMap(av =>
                        av.question.answerQuestions?.filter(avItem =>
                            avItem.questionIdentifier === previousQuestionData1
                                ?.userQuestionIdentifier
                        )
                    )
                })

            // applicable if the question identifier has the answer type input-list
            const getFirst = (filteredAnswers
                ?.[0]?.questionAnswers[0])

            const getPreviousHeader: QuestionHeader = {
                answerValue: getFirst?.answerValue as any,
                answerName: getFirst?.answerName || '',
                answerType: getFirst?.answerType || '',
                answerEditable: getFirst?.answerEditable || true
            }

            combinedHeaders = [
                getPreviousHeader,
                ..._.map(questionData?.questionAnswers, (o) => {
                    return {
                        answerValue: (Number(o.answerValue) +
                        ((previousQuestionData1?.questionAnswers?.answers
                            ?.[0]?.inputs?.length) || 0)) as string | number,
                        answerName: o.answerName || '',
                        answerType: o.answerType || '',
                        answerEditable: o.answerEditable || true
                    }
                })
            ]
        }

        combinedHeaders.sort((a, b) => {
            const valueA = typeof a.answerValue === 'string'
                ? parseInt(a.answerValue, 10)
                : a.answerValue
            const valueB = typeof b.answerValue === 'string'
                ? parseInt(b.answerValue, 10)
                : b.answerValue

            return valueA - valueB
        })

        // Step 2: Reassign sequential numbers to answerValue
        combinedHeaders.forEach((item, index) => {
            item.answerValue = index + 1
        })

        setQuestionHeaders(combinedHeaders)
    }, [reasoningData])

    const input = useMemo(() => {
        // header has multi-list.
        const multiListIndex = questionHeaders
            .findIndex(item => item.answerType === 'multi-list')

        const toRender = formsState.map((form, outerIndex) => {
            const sorted = [...form.inputs]
                .sort((a, b) => {
                    const valueA = Number(a.answerValue || 0)
                    const valueB = Number(b.answerValue || 0)
                    return valueA - valueB
                })

            const rowHasInputList = _.includes(
                _.map(form.inputs, (o) => {
                    return o.answerType
                }), 'input-list'
            )

            const inputs = _.map(sorted,
                (formInput, innerIndex) => {
                    const answerType = formInput.answerType as AnswerTypes

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'input') {
                        input = <Open
                            answerType={answerType} outerIndex={outerIndex} innerIndex={innerIndex}
                            formInput={formInput}
                        />
                    } else if (answerType === 'radio') {
                        input = <Radio
                            answerType={answerType} outerIndex={outerIndex} innerIndex={innerIndex}
                            formInput={formInput}
                        />
                    } else if (answerType === 'checkbox') {
                        input = <Checkbox
                            answerType={answerType} outerIndex={outerIndex} innerIndex={innerIndex}
                            formInput={formInput}
                        />
                    } else if (answerType === 'input-list') {
                        input = <InputList
                            answerType={answerType} outerIndex={outerIndex} innerIndex={innerIndex}
                            formInput={formInput}
                        />
                    }

                    return <td key={['table-cell', outerIndex, innerIndex].join('-')}
                        className={[
                            rowHasInputList ? '' : 'align-middle',
                            'text-center'
                        ].join(' ')}>
                        {input}
                    </td>
                })

            return <tr key={['table-row', outerIndex].join('-')}>
                {inputs}
            </tr>
        })

        return <div>

            {!(multiListIndex !== -1) && (
                <table className={'table table-borderless'}>
                    <thead>
                        <tr>
                            {
                                _.map(questionHeaders, (obj, i) => {
                                    return <th className={'align-middle text-center'} style={{
                                        minWidth: obj.answerType === 'input'
                                            ? 150
                                            : obj.answerType === 'input-list'
                                                ? 150
                                                : 'initial',
                                        whiteSpace: obj.answerType === 'input'
                                            ? 'nowrap'
                                            : obj.answerType === 'input-list'
                                                ? 'nowrap'
                                                : 'initial'
                                    }}
                                    key={['th', i].join('-')
                                    }>
                                        {obj.answerName}
                                    </th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {toRender}
                    </tbody>
                </table>
            )}

            {
                multiListIndex !== -1 && <MultiList
                    questionData={questionData} form={formsState[0]}
                />
            }

        </div>

        // turning this into undefined cause the removeChild node crash
    }, [questionHeaders])

    return <div>
        {input}
    </div>
}

export default OpenList
