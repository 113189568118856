import { DATE_FORMAT } from '@app/app.constants'
import { RootState } from '@app/app.store'
import { AppState } from '@app/types/type.app'
import { SystemModule } from '@login/type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export const INITIAL_STATE: AppState = {
    activeModules: {
        id: uuidv4(),
        arr: []
    },
    strings: {},
    returnToLink: localStorage.getItem('recentRoute') || '',
    userLoginStatus: {},
    // uses english by default
    dateFormats: {
        ...DATE_FORMAT['en-us']
    },
    firebase: {
        registrationToken: localStorage.getItem('firebaseToken')
    },
    moduleOwner: 'none',
    showMessages: false
}

export const slice = createSlice({
    name: 'app',
    initialState: INITIAL_STATE,
    reducers: {
        setConfig: (state: AppState, action: PayloadAction<SystemModule[]>) => {
            state.activeModules.arr = action.payload
            state.activeModules.id = uuidv4()
        },
        setStrings: (state: AppState, action: PayloadAction<AppState['strings']>) => {
            state.strings = action.payload
        },
        setReturnToLink: (state: AppState, action: PayloadAction<AppState['returnToLink']>) => {
            state.returnToLink = action.payload
        },
        setDateFormats: (state: AppState, action: PayloadAction<
            AppState['dateFormats']>) => {
            state.dateFormats = action.payload
        },
        setUserLoginStatus: (state: AppState, action: PayloadAction<{
            sub: string | undefined,
            key: keyof AppState['userLoginStatus']
        }>) => {
            state.userLoginStatus[action.payload.key] = action.payload.sub
        },
        setFirebaseToken: (state: AppState, action: PayloadAction<string>) => {
            state.firebase.registrationToken = action.payload
            localStorage.setItem('firebaseToken', action.payload)
        },
        setModuleOwner: (state: AppState, action:
            PayloadAction<AppState['moduleOwner']>) => {
            state.moduleOwner = action.payload
        },
        setShowMessages: (state: AppState, action: PayloadAction<AppState['showMessages']>) => {
            state.showMessages = action.payload
        }
    }
})

export const {
    setConfig,
    setStrings,
    setReturnToLink,
    setDateFormats,
    setUserLoginStatus,
    setFirebaseToken, setModuleOwner,
    setShowMessages
} = slice.actions

export const selectActiveModules = (state: RootState) => state.app.activeModules
export const selectStrings = (state: RootState) => state.app.strings
export const selectReturnToLink = (state: RootState) => state.app.returnToLink
export const selectDateFormats = (state: RootState) => state.app.dateFormats
export const selectUserLoginStatus = (state: RootState) => state.app.userLoginStatus
export const selectFirebaseToken = (state: RootState) => state.app.firebase.registrationToken
export const selectModuleOwner = (state: RootState) => state.app.moduleOwner
export const selectShowMessages = (state: RootState) => state.app.showMessages
export default slice.reducer
