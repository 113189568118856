import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import {
    CheckCareplanStatusRequest,
    CheckCareplanStatusResponse,
    FinishPersonalCareplanRequest,
    FinishPersonalCareplanResponse
} from '@careplan/type'
import {
    GetWorkflowRequest,
    GetWorkflowResponse,
    UpdateWorkflowProgressRequest,
    UpdateWorkflowProgressResponse
} from '@login/type'
import {
    GetReasoningRequest,
    GetReasoningResponse,
    ReasonWithMeRequest,
    ReasonWithMeResponse
} from '@reasonWithMe/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'

export const reasonWithMeV10API = createApi({
    reducerPath: 'reasonWithMeV10API',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        reasonWithMe: builder
            .mutation<ReasonWithMeResponse, ReasonWithMeRequest & TokenAuth>({
                query: (body) => {
                    const formData: ReasonWithMeRequest = {
                        data: {
                            reasoningSetId: body.data.reasoningSetId,
                            reasoningSetVersion: body.data.reasoningSetVersion
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.reasonWithMe.apiPaths.reasonWithMe.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getReasoning: builder
            .mutation<GetReasoningResponse, GetReasoningRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: Omit<GetReasoningRequest['data'], 'reasoningSessionId'>
                    } = {
                        data: {
                            answerValue: body.data.answerValue,
                            questionId: body.data.questionId,
                            reasoningSetId: body.data.reasoningSetId
                        }
                    }

                    return {
                        url: _.trimStart(
                             `${ MODULE_TABLE.reasonWithMe
                                 .apiPaths.reasonWithMe.path }/${
                                    body.data.reasoningSessionId }`, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        finishPersonalCareplan: builder
            .mutation<FinishPersonalCareplanResponse, FinishPersonalCareplanRequest & TokenAuth>({
                query: (body) => {
                    const formData = {
                        data: {
                            reasoningSessionId: body.data.reasoningSessionId
                        }
                    }

                    const removeBrackets = (str: string) => {
                        const pattern = /<[^>]+>/g
                        return _.replace(str, pattern, '')
                    }

                    const url = [
                        _.trim(
                            removeBrackets(
                                MODULE_TABLE.reasonWithMe
                                    .apiPaths.finishPersonalCareplan.path
                            ), '/'
                        ),
                        body.personalCareplanId ? '/' + body.personalCareplanId : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        checkCareplanStatus: builder
            .mutation<CheckCareplanStatusResponse, CheckCareplanStatusRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: Omit<CheckCareplanStatusRequest['data'], 'personalCarePlanId'>
                    } = {
                        data: {
                        }
                    }

                    const removeBrackets = (str: string) => {
                        const pattern = /<[^>]+>/g
                        return _.replace(str, pattern, '')
                    }

                    const url = [
                        _.trim(
                            removeBrackets(
                                MODULE_TABLE.reasonWithMe
                                    .apiPaths.checkCareplanStatus.path
                            ), '/'
                        ),
                        body.personalCarePlanId ? '/' + body.personalCarePlanId : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getWorkflow: builder
            .mutation<GetWorkflowResponse, GetWorkflowRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetWorkflowRequest = {
                        data: {
                            ...(body.data?.moduleId && { moduleId: body.data?.moduleId }),
                            ...(body.data?.workflowId && { workflowId: body.data?.workflowId }),
                            ...(body.data?.route && { route: body.data.route })
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.reasonWithMe.apiPaths.getWorkflow.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateWorkflowProgress: builder
            .mutation<UpdateWorkflowProgressResponse,
            UpdateWorkflowProgressRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdateWorkflowProgressRequest = {
                        data: {
                            stepId: body.data?.stepId,
                            workflowId: body.data?.workflowId,
                            percentComplete: body.data?.percentComplete
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.reasonWithMe.apiPaths
                                .updateWorkflowProgress.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetReasoningMutation,
    useReasonWithMeMutation,
    useCheckCareplanStatusMutation,
    useFinishPersonalCareplanMutation,
    useGetWorkflowMutation,
    useUpdateWorkflowProgressMutation

} = reasonWithMeV10API
