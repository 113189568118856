export const PAGINATE_CONFIG = {
    paginationEdgeCount: 5,
    paginationEdgeLimit: 3,
    middleRangeCount: 5,
    middleRangeLimit: 3
}

export function paginate (
    startingNumbers: number[],
    endingNumbers: number[],
    index: number,
    totalPages: number,
    startingFixedRange: number[],
    endingFixedRange: number[]
): (number | string)[] {
    const pagination: (number | string)[] = []

    const hasCommonValue: boolean =
    startingNumbers.some(element => endingNumbers.includes(element))

    // A subset of the five numbers from both sides where we start the [0, ...] in the middle
    const subsetStart: number[] = startingNumbers.slice(
        0, startingNumbers.length -
        (PAGINATE_CONFIG.paginationEdgeCount - PAGINATE_CONFIG.paginationEdgeLimit)
    )
    const subsetEnd: number[] = endingNumbers.slice(
        PAGINATE_CONFIG.paginationEdgeCount - PAGINATE_CONFIG.paginationEdgeLimit
    )

    // If you are at the start, print the first 5 indexes of the array
    for (let i: number = 0; i < totalPages; i++) {
        if (
            i < PAGINATE_CONFIG.paginationEdgeCount &&
        !endingNumbers.includes(index) &&
        startingNumbers.includes(index) &&
        !hasCommonValue &&
        index < PAGINATE_CONFIG.paginationEdgeLimit
        ) {
        // Push page number.
        // Condition added: make sure that this number is not in the lastFiveNumbers
            pagination.push(i)
        }

        // Now applicable to smaller counts. Check if
        // firstFiveNumbers has at least one element that is also in lastFiveNumbers
        // If this is the case, get the values in the
        // last five numbers and remove all negative values
        if (hasCommonValue) {
            const mergedArray: number[] = Array.from(
                new Set([...startingNumbers, ...endingNumbers].filter(num => num >= 0))
            )
            pagination.push(...mergedArray)
            break
        }

        // Now if the gap is still short, go ahead and do the first loop too
        // What should happen if your i is equal to the paginationEdgeCount count.
        if (i === PAGINATE_CONFIG.paginationEdgeCount) {
        // What it should do besides pushing the ellipsis and the last number.
        // Check if the index passed in is included in the last five numbers array.
            if (subsetEnd.includes(index)) {
                // In the last part.
                pagination.push(startingNumbers[0], '...')
                // Add the last part and then end the loop
                pagination.push(...endingNumbers)
                break
            }

            // If condition above is not true, check if you already passed the firstFiveNumbers
            // If so, add first number and then
            if (!subsetStart.includes(index)) {
                pagination.push(startingNumbers[0], '...')
                // You can continue after.
            }

            // If you want to print numbers in the middle, you want to check
            // if the index is greater than the pagination edge limit
            // and if the the index is less than
            // the length of the array minus the pagination edge limit.
            if (index >= PAGINATE_CONFIG.paginationEdgeLimit &&
                index < totalPages - PAGINATE_CONFIG.paginationEdgeLimit) {
                // Check if startingFixedRange and endingFixedRange's first indexes are the same.
                if (startingFixedRange[0] === endingFixedRange[0]) {
                    // Print the rest without limit anyways.
                    pagination.push(...startingFixedRange)
                } else {
                    // Print the ranges accordingly.
                    // Check if the index minus the
                    // middle range limit is less than the middle range limit
                    if (index - PAGINATE_CONFIG.middleRangeLimit <
                         PAGINATE_CONFIG.middleRangeLimit) {
                        pagination.push(...startingFixedRange)
                        // In the end, check if the
                        // index is greater than the first
                        // time you'll be printing the middle range limit.
                    } else if (index >= totalPages -
                         PAGINATE_CONFIG.paginationEdgeLimit -
                          PAGINATE_CONFIG.middleRangeLimit) {
                        pagination.push(...endingFixedRange)
                    } else {
                        // Push the starting
                        // fixed range + index - middleRangeLimit - paginationEdgeLimit
                        startingFixedRange.forEach((num: number) => {
                            pagination.push(
                                num + index -
                                PAGINATE_CONFIG.middleRangeLimit -
                                 PAGINATE_CONFIG.paginationEdgeLimit + 1
                            )
                        })
                    }
                }
            }

            // Apply ellipsis check behavior here.
            pagination.push('...')
            // Mark the last number
            pagination.push(totalPages - 1)
            break // Exit the loop
        }
    }

    return pagination
}
