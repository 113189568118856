import { paginate, PAGINATE_CONFIG } from '@app/components/paginate-array'
import { isString } from 'lodash'
import { useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

interface ComponentProps {
     currentPage: number;
     setCurrentPageState?: (value: number) => void;
     setCurrentPageDispatch?: (value: number) => void;
     limit: number, skip: number, totalRecords: number
    }

const Pagination = ({
    currentPage, setCurrentPageState, setCurrentPageDispatch, limit, skip, totalRecords
}:ComponentProps) => {
    const [paginationKey] = useState<string>(uuidV4())
    // Calculate the total number of pages based on totalRecords and limit
    const totalPages = limit ? Math.ceil(totalRecords / limit) : 0

    const startingNumbers = Array.from(
        {
            length: totalPages < PAGINATE_CONFIG.paginationEdgeCount
                ? totalPages
                : PAGINATE_CONFIG.paginationEdgeCount
        }, (_, index) => index
    )
    const endingNumbers = Array.from(
        {
            length: totalPages < PAGINATE_CONFIG.paginationEdgeCount
                ? totalPages
                : PAGINATE_CONFIG.paginationEdgeCount
        }, (_, index) => totalPages - PAGINATE_CONFIG.paginationEdgeCount + index
    )

    // create array for array from using the pagiantionEdgeLimit and the middleRangeCount
    const startingFixedRange = Array.from(
        { length: PAGINATE_CONFIG.middleRangeCount },
        (_, index) => index + PAGINATE_CONFIG.paginationEdgeLimit
    )
    // as for the end limit, use the middleRangeCount again.
    // starting value would be the first value of the endingNumbers array.
    const endingFixedRange = Array.from(
        { length: PAGINATE_CONFIG.middleRangeCount },
        (_, index) => {
            let idealNumber = endingNumbers[0] + index - PAGINATE_CONFIG.paginationEdgeLimit

            // if the number to push is less than the startingFixedRange's
            // index, get the difference and increment the amount
            if (idealNumber < startingFixedRange[index]) {
                idealNumber += (startingFixedRange[index] - idealNumber)
            }

            return idealNumber
        }
    )

    // Generate an array of page numbers to display in the pagination
    const pageNumbers = paginate(
        startingNumbers,
        endingNumbers,
        currentPage,
        totalPages,
        startingFixedRange,
        endingFixedRange
    )

    // Handle clicking on a page number
    const handlePageClick = (pageNumber:number) => {
        setCurrentPageState && setCurrentPageState(pageNumber)
        setCurrentPageDispatch && setCurrentPageDispatch(pageNumber)
        // Calculate the new skip value based on the clicked page number and limit
        const newSkip = (pageNumber) * limit
        // You can use the newSkip value in your API request or data fetching logic
        // For this example, we'll just log it.
        console.log('New Page Number:', pageNumber)
        console.log('New Skip:', newSkip)
    }

    // Previous Page
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            handlePageClick(currentPage - 1)
        }
    }

    // Next Page
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            handlePageClick(currentPage + 1)
        }
    }

    // useEffect(() => {
    // // You can perform any data fetching or API requests here based on the skip value.
    // // For this example, we'll just log it.
    //     console.log('Current Skip:', skip)
    // }, [skip])

    return (
        <div className={'pagination'}>
            <a href={'#'} className={[
                'previous',
                currentPage <= 0 ? 'disabled' : ''
            ].join(' ')} onClick={(e) => {
                e.preventDefault()

                if ((currentPage <= 0)) {
                    // nothing
                } else {
                    handlePreviousPage()
                }
            }}>
                <i className={'fas fa-arrow-left'}></i>
            </a>
            <span className={'page-numbers'}>
                {pageNumbers.map((pageNumber, index) => {
                    const key = `page-number-${ index }-${ paginationKey }`
                    return isString(pageNumber)
                        ? <span key={key}>{'...'}</span>
                        : (
                            <a
                                key={key}
                                href={'#'}
                                className={pageNumber === currentPage ? 'active' : ''}
                                onClick={(e) => {
                                    e.preventDefault()
                                    handlePageClick(pageNumber)
                                }}
                            >
                                {pageNumber + 1}
                            </a>
                        )
                })}
            </span>
            <a href={'#'} onClick={(e) => {
                e.preventDefault()

                if ((limit * (currentPage + 1)) >= totalRecords) {
                    // nothing
                } else {
                    handleNextPage()
                }
            }} className={[
                'next',
                (limit * (currentPage + 1)) >= totalRecords ? 'disabled' : ''
            ].join(' ')}>
                <i className={'fas fa-arrow-right'}></i>
            </a>
        </div>
    )
}

export default Pagination
