import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { CHECK_CAREPLAN_STATUS_INTERVAL } from '@careplan/constants'
import { CheckCareplanStatusResponse } from '@careplan/type'
import { selectCurrentWorkflowStep } from '@login/slices/slice.workflow'
import {
    useCheckCareplanStatusMutation,
    useFinishPersonalCareplanMutation
} from '@reasonWithMe/api'
import { selectQuestionInterface } from '@reasonWithMe/slice'
import {
    MakeTherapeuticsActions,
    MakeTherapeuticsOutcome,
    TherapeuticsCheck
} from '@reasonWithMe/type'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

const CreateCareplanConsumer = ({
    outcome,
    therapeuticsCheck,
    makeTherapeuticsDispatch
} : {
    outcome: MakeTherapeuticsOutcome,
    therapeuticsCheck: TherapeuticsCheck,
    makeTherapeuticsDispatch: React.Dispatch<MakeTherapeuticsActions>
}) => {
    const token = useAppSelector(selectToken)
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const questionInterface = useAppSelector(selectQuestionInterface)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)

    // so this component is rendered already once the makeTherapeutics.arr is populated.
    // now, you can't make the calls immediately. you have to rely on a boolean
    // so all of it will start.

    const [
        finishPersonalCareplan, finishPersonalCareplanMutation
    ] = useFinishPersonalCareplanMutation()
    const [
        checkCareplanStatus, checkCareplanStatusMutation
    ] = useCheckCareplanStatusMutation()

    const markAsFailed = () => {
        // basically reset all.
        makeTherapeuticsDispatch({
            type: 'SET_IS_INITIALIZING',
            value: {
                reasoningOutcome: outcome.reasoningOutcome,
                confidenceLevel: outcome.confidenceLevel,
                obj: {
                    therapeuticsId: therapeuticsCheck.therapeutic.therapeuticsId,
                    isInitialized: true
                }
            }
        })

        makeTherapeuticsDispatch({
            type: 'SET_STATUS_CHECK',
            value: {
                reasoningOutcome: outcome.reasoningOutcome,
                confidenceLevel: outcome.confidenceLevel,
                obj: {
                    therapeuticsId: therapeuticsCheck.therapeutic.therapeuticsId,
                    statusCheck: false
                }
            }
        })

        makeTherapeuticsDispatch({
            type: 'SET_CREATE_PROGRESS',
            value: {
                reasoningOutcome: outcome.reasoningOutcome,
                confidenceLevel: outcome.confidenceLevel,
                obj: {
                    therapeuticsId: therapeuticsCheck.therapeutic.therapeuticsId,
                    createProgress: 0
                }
            }
        })

        makeTherapeuticsDispatch({
            type: 'SET_IS_SUCCESS',
            value: {
                reasoningOutcome: outcome.reasoningOutcome,
                confidenceLevel: outcome.confidenceLevel,
                obj: {
                    therapeuticsId: therapeuticsCheck.therapeutic.therapeuticsId,
                    isSuccess: false
                }
            }
        })
    }

    // only run this when onSuccess
    useEffect(() => {
        const data = finishPersonalCareplanMutation.data

        if (finishPersonalCareplanMutation.isSuccess) {
            if (data?.status === 'OK') {
                data?.message && toast
                    .success(data.message,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )

                // now initiate check careplan status.
                makeTherapeuticsDispatch({
                    type: 'SET_STATUS_CHECK',
                    value: {
                        reasoningOutcome: outcome.reasoningOutcome,
                        confidenceLevel: outcome.confidenceLevel,
                        obj: {
                            therapeuticsId: therapeuticsCheck.therapeutic.therapeuticsId,
                            statusCheck: true
                        }
                    }
                })
            } else {
                console.log('marking as failed after finishPersonalCareplan')
                markAsFailed()
                data?.message && toast
                    .error(data.message,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
            }
        }
    }, [finishPersonalCareplanMutation.data])

    useEffect(() => {
        const error = finishPersonalCareplanMutation.error

        if (error) {
            console.log('marking as failed after throwing an error')
            const message = getErrorText(error)
            console.error(message)
            markAsFailed()
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [finishPersonalCareplanMutation.error])

    useEffect(() => {
        let createInterval: any

        if (therapeuticsCheck.creationProcess.statusCheck) {
            (() => {
                const careplanStatusUpdate = (data: CheckCareplanStatusResponse) => {
                    if (data?.status === 'OK') {
                        // mark status.
                        makeTherapeuticsDispatch({
                            type: 'SET_STAGE_TYPE',
                            value: {
                                reasoningOutcome: outcome.reasoningOutcome,
                                confidenceLevel: outcome.confidenceLevel,
                                obj: {
                                    therapeuticsId: therapeuticsCheck.therapeutic.therapeuticsId,
                                    stageType: data.data.stageType
                                }
                            }
                        })

                        if (data.data.stageType === 'ready') {
                            // end the interval
                            clearInterval(createInterval)

                            makeTherapeuticsDispatch({
                                type: 'SET_IS_SUCCESS',
                                value: {
                                    reasoningOutcome: outcome.reasoningOutcome,
                                    confidenceLevel: outcome.confidenceLevel,
                                    obj: {
                                        therapeuticsId: therapeuticsCheck.therapeutic
                                            .therapeuticsId,
                                        isSuccess: true
                                    }
                                }
                            })
                        } else {
                            // just continue as normal.
                        }
                        // don't forget to set the createProgress
                        // and make sure that it isn't a dependency.
                        makeTherapeuticsDispatch({
                            type: 'SET_CREATE_PROGRESS',
                            value: {
                                reasoningOutcome: outcome.reasoningOutcome,
                                confidenceLevel: outcome.confidenceLevel,
                                obj: {
                                    therapeuticsId: therapeuticsCheck.therapeutic.therapeuticsId,
                                    createProgress: data.data.createProgress
                                }
                            }
                        })
                    }
                }

                const isValid = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.reasonWithMe.moduleName,
                    MODULE_TABLE.reasonWithMe.apiPaths.checkCareplanStatus.path,
                    true
                )

                if (isValid) {
                    createInterval = setInterval(async () => {
                        const newToken = await revalidateToken({
                            value: token.value,
                            id: token.id
                        }, token.mode)

                        if (currentWorkflowStep?.stepData.personalCareplanId) {
                            checkCareplanStatus({
                                authToken: newToken.value,
                                personalCarePlanId: currentWorkflowStep.stepData.personalCareplanId,
                                data: {}
                            }).unwrap().then(careplanStatusUpdate)
                        }
                    }, CHECK_CAREPLAN_STATUS_INTERVAL)
                }
            })()
        }

        return () => {
            clearInterval(createInterval)
        }
    }, [therapeuticsCheck.creationProcess.statusCheck])

    useEffect(() => {
        const error = checkCareplanStatusMutation.error

        if (error) {
            const message = getErrorText(error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [checkCareplanStatusMutation.error])

    const initiatePlan = async (
        reasoningSessionId:string,
        myCareplanId:string
    ) => {
        const newToken = await revalidateToken({
            value: token.value,
            id: token.id
        }, token.mode)

        const isValid = validateAPIPath(
            activeModules.arr,
            MODULE_TABLE.reasonWithMe.moduleName,
            MODULE_TABLE.reasonWithMe
                .apiPaths.finishPersonalCareplan.path,
            true
        )

        if (isValid && newToken.value) {
            finishPersonalCareplan({
                authToken: newToken.value,
                personalCareplanId: myCareplanId,
                data: {
                    reasoningSessionId
                }
            })
        } else {
            toast.error(
                strings.careplanPatient
                    ?.message.error.make_careplan,
                { ...TOASTIFY_DEFAULT_OPTIONS }
            )

            console.log('marking as failed after route mismatch')
            markAsFailed()

            console.log('reasoning session id: ', reasoningSessionId)

            console.log('myCareplanId: ', myCareplanId)
        }
    }

    useEffect(() => {
        if (therapeuticsCheck.creationProcess.isInitialized) {
            if (token.valid && questionInterface
                .currentReasonWithMeResponse
                .results?.reasoningSessionId &&
                currentWorkflowStep?.stepData.personalCareplanId) {
                initiatePlan(
                    questionInterface
                        .currentReasonWithMeResponse
                        .results?.reasoningSessionId,
                    currentWorkflowStep?.stepData.personalCareplanId
                )
            }
        }
    }, [
        therapeuticsCheck.creationProcess.isInitialized,
        questionInterface,
        currentWorkflowStep?.stepData.personalCareplanId
    ])

    return <>
    </>
}

export default CreateCareplanConsumer
