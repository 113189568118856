import AnimatedLoader from '@reasonWithMe/components/AnimatedLoader'
import {
    MakeTherapeuticsActions,
    MakeTherapeuticsOutcome,
    ReasoningRecommendationOutcome
} from '@reasonWithMe/type'
import _ from 'lodash'
import React from 'react'

const RecommendationConditionalButtons = (
    { outcome, makeTherapeuticsDispatch, MainRecommendationReasoningOutcome }: {
    outcome: MakeTherapeuticsOutcome,
    makeTherapeuticsDispatch: React.Dispatch<MakeTherapeuticsActions>,
    MainRecommendationReasoningOutcome: ReasoningRecommendationOutcome | undefined
}) => {
    return <> {
        _.map(MainRecommendationReasoningOutcome?.recommendedTherapeutics, (o, index) => {
            const key = `button-${ index }`
            const foundChecker = _.find(outcome.arr, (a) => {
                return a.therapeutic.therapeuticsId === o.therapeuticsId
            })

            const isntDone = foundChecker
                ?.creationProcess.isSuccess === false

            const hasntStarted = foundChecker
                ?.creationProcess.isInitialized === false && isntDone

            const isDisabled = foundChecker
                ?.creationProcess.isInitialized === true && isntDone

            const isAlreadyDone = foundChecker?.creationProcess.isSuccess === true

            const onClick = () => {
                if ((o.includeTherapeutic && hasntStarted) || isAlreadyDone) {
                    makeTherapeuticsDispatch({
                        type: 'SET_IS_INITIALIZING',
                        value: {
                            reasoningOutcome: outcome.reasoningOutcome,
                            confidenceLevel: outcome.confidenceLevel,
                            obj: {
                                therapeuticsId: o.therapeuticsId,
                                isInitialized: true
                            }
                        }
                    })
                } else {
                    console.log('event trigger canceled due to conditions.')
                }
            }

            // display progress.
            const buttonText = isDisabled
            // ? `${ foundChecker.creationProcess.createProgress }%`
                ? <div className={'text-center'}>
                    <h1 className={'d-block mb-4 mt-3 p'}>{
                        MainRecommendationReasoningOutcome
                            ?.reasoningOutcomeDetailedText?.baseHeading
                    }</h1>
                    <span className={'p d-block'}>{
                        MainRecommendationReasoningOutcome
                            ?.reasoningOutcomeDetailedText?.baseOutcome
                    }</span>
                </div>
            // ? 'Temp Text: Creating careplan started'
                : <span>{o.buttonText}</span>

            // let's add a loader in each of these buttons.
            // we will need an interface for therapeutics where includeTherapeutic
            // is true AND includeOutcome is false.

            // includeTherapeutic === true, set this event.

            const percentToPass = foundChecker?.creationProcess.createProgress || 0

            const content = <div key={key}>
                {
                    o.includeOutcome === true
                        ? <button

                            type={'button'}
                            disabled={isDisabled || isAlreadyDone}
                            onClick={onClick}
                            className={'btn btn-primary w-100'}
                        >{buttonText}</button>
                        : <div className={'text-center'}>
                            {/* <span>{'Temp Text: Creating
                        specific careplan automatically'}</span> */}
                            <h1 className={'p fw-bold d-block'}>{
                                MainRecommendationReasoningOutcome
                                    ?.reasoningOutcomeDetailedText?.baseHeading
                            }</h1>
                            <span className={'p d-block'}>{
                                MainRecommendationReasoningOutcome
                                    ?.reasoningOutcomeDetailedText?.baseOutcome
                            }</span>
                        </div>
                }

                {/* we will just check if the percent prop is greater than 100. if true, the
                number passed will be just 100, otherwise, just stick to number */}
                <AnimatedLoader propPercent={percentToPass} stageType={
                    foundChecker?.creationProcess.stageType
                } />
            </div>

            return content
        })
    }</>
}

export default RecommendationConditionalButtons
