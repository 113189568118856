import { MODULE_TABLE } from '@app/app.config'
import {
    ChangePasswordKeys,
    ChangePasswordValues,
    DoContactKeys,
    DoContactValues,
    MfaKeys,
    MfaValues,
    PersonalInfoKeys,
    PersonalInfoValues
} from '@profile/type'
import { AnyObject, ObjectSchema } from 'yup'
import * as Yup from 'yup'

/** This is the name of the module. */
export const MODULE_NAME = {
    LONG: MODULE_TABLE.profile.moduleName,
    SHORT: MODULE_TABLE.profile.moduleShortName
}

/** This is the major version number of the module. */
export const MODULE_MAJOR_VERSION = 1

/** This is the minor version number of the module. */
export const MODULE_MINOR_VERSION = 2

/** This is the hotfix version number of the module. */
export const MODULE_HOTFIX_VERSION = 1

/** This is the full version string of the module,
 * based on the major, minor, and hotfix versions. */
export const MODULE_VERSION = [
    MODULE_NAME.SHORT, ' ',
    `v.${ MODULE_MAJOR_VERSION }.${ MODULE_MINOR_VERSION }.${ MODULE_HOTFIX_VERSION }`
].join('')
export const IDS = {
    ACCOUNT_SETTINGS: {
        PERSONAL_INFO: {
            FORM: 'PERSONAL_INFO__FORM',
            FIRST_NAME: 'PERSONAL_INFO__FIRST_NAME',
            LAST_NAME: 'PERSONAL_INFO__LAST_NAME',
            EMAIL: 'PERSONAL_INFO__EMAIL',
            DATE_OF_BIRTH: 'PERSONAL_INFO__DATE_OF_BIRTH',
            SEX: 'PERSONAL_INFO__SEX'
        },
        PASSWORD: {
            FORM: 'PASSWORD__FORM',
            CURRENT_PASSWORD: 'PASSWORD__CURRENT_PASSWORD',
            NEW_PASSWORD: 'PASSWORD__NEW_PASSWORD',
            RETYPE_NEW_PASSWORD: 'PASSWORD__RETYPE_NEW_PASSWORD'
        },
        SWITCHES: {
            MFA: 'SWITCHES__MFA',
            FINGERPRINT: 'SWITCHES__FINGERPRINT'
        },
        MFA: {
            FORM: 'MFA__FORM',
            PIN_CODE: 'MFA__PIN_CODE'
        }
    },
    APP_SETTINGS: {
        SWITCHES: {
            PUSH_NOTIFICATIONS: 'SWITCHES__PUSH_NOTIFICATIONS',
            EMAIL: 'SWITCHES__EMAIL',
            LARGE_TEXT: 'SWITCHES__LARGE_TEXT',
            DARK_MODE: 'SWITCHES__DARK_MODE',
            DOWNLOAD_DATA: 'BUTTON__DOWNLOAD_DATA',
            DELETE_DATA: 'BUTTON__DELETE_DATA'

        }
    },
    DO_CONTACT: {
        FORM: 'DO_CONTACT__FORM',
        CATEGORY_ID: 'DO_CONTACT__CATEGORY_ID',
        SUBJECT: 'DO_CONTACT__SUBJECT',
        MESSAGE: 'DO_CONTACT__MESSAGE'
    }
}

export const PERSONAL_INFO_FORMIK_INITIAL_VALUES: PersonalInfoValues = {
    firstName: '',
    lastName: '',
    email: '',
    sex: ''
}

export const MFA_FORMIK_INITIAL_VALUES: MfaValues = {
    pinCode: ''
}

export const PERSONAL_INFO_VALIDATION_SCHEMA: (
    emailMessage: string,
    emptyMessage: string,
) => ObjectSchema<
PersonalInfoValues,
AnyObject,
Record<PersonalInfoKeys, undefined>
> = (emailMessage, emptyMessage) => Yup.object({
    firstName: Yup.string().required(emptyMessage),
    lastName: Yup.string().required(emptyMessage),
    sex: Yup.string().required(emptyMessage),
    email: Yup.string()
        .email(emailMessage)
        .required(emptyMessage)
    // dateOfBirth: Yup.number()
    //     .required(emptyMessage)
})

export const MFA_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
MfaValues,
AnyObject,
Record<MfaKeys, undefined>
> = (emptyMessage) => Yup.object({
    pinCode: Yup.string().required(emptyMessage)
})

export const CHANGE_PASSWORD_FORMIK_INITIAL_VALUES: ChangePasswordValues = {
    currentPassword: '',
    newPassword: '',
    retypeNewPassword: ''
}

export const CHANGE_PASSWORD_VALIDATION_SCHEMA: (
    emptyMessage: string,
    passwordMatchMessage: string,
) => ObjectSchema<
ChangePasswordValues,
AnyObject,
Record<ChangePasswordKeys, undefined>
> = (emptyMessage, passwordMatchMessage) => Yup.object({
    currentPassword: Yup.string().required(emptyMessage),
    newPassword: Yup.string().required(emptyMessage),
    retypeNewPassword: Yup.string()
        .required(emptyMessage)
        .oneOf([Yup.ref('newPassword'), ''], passwordMatchMessage)
})

export const DO_CONTACT_FORMIK_INITIAL_VALUES: DoContactValues = {
    categoryId: '',
    subject: '',
    message: ''
}

export const DO_CONTACT_VALIDATION_SCHEMA: (
    emptyMessage: string
) => ObjectSchema<
DoContactValues,
AnyObject,
Record<DoContactKeys, undefined>
> = (emptyMessage) => Yup.object({
    categoryId: Yup.string().required(emptyMessage),
    subject: Yup.string().required(emptyMessage),
    message: Yup.string().required(emptyMessage)
})
