import { Department, GetTreatmentsResponse, Treatment } from '@fmt/type'
import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { IDS } from '@fmt/constants'

import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText, smartSearch } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import {
    useAddDepartmentTreatmentMutation,
    useDeleteDepartmentTreatmentMutation,
    useGetTreatmentsMutation
} from '@fmt/api'
import { FormikProps } from 'formik'
import _ from 'lodash'

import { toast } from 'react-toastify'
interface ComponentProps {
    department: Department,
    isFromEditPage: boolean,
    facilityStepFourAddFormik: FormikProps<{
        linkedTreatments: {
        department: Department,
        collapsible: boolean,
        arr: GetTreatmentsResponse['data']['treatments']
    }[]
    }>
}

const TreatmentsInterface = ({
    department,
    isFromEditPage,
    facilityStepFourAddFormik
}: ComponentProps) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()

    const containerRef = useRef<HTMLDivElement | null>(null)
    const [search, setSearch] = useState('')

    // start off with dropdown already open.
    const [isOutsideClick, setIsOutsideClick] = useState(false)

    const [
        addDepartmentTreatment,
        addDepartmentTreatmentMutation
    ] = useAddDepartmentTreatmentMutation({})

    const [
        deleteDepartmentTreatment,
        deleteDepartmentTreatmentMutation
    ] = useDeleteDepartmentTreatmentMutation({})

    useEffect(() => {
        if (addDepartmentTreatmentMutation.error) {
            const message = getErrorText(addDepartmentTreatmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addDepartmentTreatmentMutation.error])

    useEffect(() => {
        if (deleteDepartmentTreatmentMutation.error) {
            const message = getErrorText(deleteDepartmentTreatmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [deleteDepartmentTreatmentMutation.error])

    // things have changed thanks to
    const [getUnlinkedTreatments,
        getUnlinkedTreatmentsMutation
    ] = useGetTreatmentsMutation()
    const [getLinkedTreatments,
        getLinkedTreatmentsMutation
    ] = useGetTreatmentsMutation()

    const unsubscribeGetUnlinkedTreatments = () => {
        const unsubscribeMutation = getUnlinkedTreatments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetLinkedTreatments = () => {
        const unsubscribeMutation = getLinkedTreatments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const fetchData = (token: TokenData) => {
        unsubscribeGetUnlinkedTreatments()
        unsubscribeGetLinkedTreatments()

        let getUnlinkedTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getLinkedTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getTreatments.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getUnlinkedTreatmentsPromise = getUnlinkedTreatments({
                            authToken: newToken.value,
                            data: {
                                departmentId: department.departmentId,
                                isLinked: false,
                                limit: 0,
                                skip: 0
                            }
                        })

                        getLinkedTreatmentsPromise = getLinkedTreatments({
                            authToken: newToken.value,
                            data: {
                                departmentId: department.departmentId,
                                isLinked: true,
                                limit: 0,
                                skip: 0
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getUnlinkedTreatmentsPromise && getUnlinkedTreatmentsPromise.abort()
            getLinkedTreatmentsPromise && getLinkedTreatmentsPromise.abort()
        }
    }

    useEffect(() => {
        // if the values of linkedDepartments is updated, then update the queries.
        return fetchData(token)
    }, [token.id, token.valid])

    // a workaround to prevent tables from flickering. simple but it works.
    const [
        getUnlinkedTreatmentsResponse,
        setGetUnlinkedTreatmentsResponse
    ] = useState<GetTreatmentsResponse>()

    const [
        getLinkedTreatmentsResponse,
        setGetLinkedTreatmentsResponse
    ] = useState<GetTreatmentsResponse>()

    useEffect(() => {
        if (getUnlinkedTreatmentsMutation.data) {
            setGetUnlinkedTreatmentsResponse(
                getUnlinkedTreatmentsMutation.data
            )
        }
    }, [getUnlinkedTreatmentsMutation.data])

    useEffect(() => {
        if (getLinkedTreatmentsMutation.data) {
            setGetLinkedTreatmentsResponse(
                getLinkedTreatmentsMutation.data
            )
        }
    }, [getLinkedTreatmentsMutation.data])

    useEffect(() => {
        if (getLinkedTreatmentsResponse) {
            const temp = _.cloneDeep(facilityStepFourAddFormik.values.linkedTreatments)
            const foundIndex = temp.findIndex(
                (p) => p.department.departmentId === department.departmentId
            )

            if (foundIndex > -1) {
                temp[foundIndex].arr = getLinkedTreatmentsResponse.data.treatments

                facilityStepFourAddFormik.setFieldValue(
                    'linkedTreatments', temp
                )
            }
        }
    }, [getLinkedTreatmentsResponse])

    const changeTreatmentStatus = async (
        departmentId: string,
        obj: Treatment, linked: boolean
    ) => {
        const newToken = await revalidateToken(
            {
                value: token.value,
                id: token.id
            },
            token.mode
        )

        if (linked) {
            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.apiPaths.addDepartmentTreatment.path,
                true
            )
            if (foundApiPath && newToken.value) {
                addDepartmentTreatment({
                    authToken: newToken.value,
                    data: {
                        departmentId: departmentId || '',
                        treatmentId: obj?.treatmentId || ''
                    }
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        //
                        fetchData(token)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        } else {
            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.apiPaths.deleteDepartmentTreatment.path,
                true
            )
            if (foundApiPath && newToken.value) {
                deleteDepartmentTreatment({
                    authToken: newToken.value,
                    data: {
                        departmentId: departmentId || '',
                        treatmentId: obj?.treatmentId || ''
                    }
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        //
                        fetchData(token)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current) {
                // weird behavior but works anyways
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsOutsideClick(false)
                } else {
                    setIsOutsideClick(true)
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [containerRef])

    const renderOption = (obj: Treatment, defaultChecked: boolean) => {
        const key = obj.treatmentId

        return <div
            className={'col'}
            key={key}
        >
            <input type={'checkbox'}
                className={'btn-check'}
                id={key}
                autoComplete={'off'}
                // checked prop is removed.
                // when checked, link it, otherwise, unlink.
                defaultChecked={defaultChecked}
                onChange={(e) => {
                    if (e.target.checked) {
                    // link
                        changeTreatmentStatus(department.departmentId, obj, true)
                    } else {
                    // unlink.
                        changeTreatmentStatus(department.departmentId, obj, false)
                    }
                }}
            />
            <label
                className={'btn btn-dummy'}
                htmlFor={key}
            >

                <div className={'question-checkbox'}>
                    <div className={'card justify-content-center px-3 py-2'}>

                        <div className={'d-flex flex-column'}>
                            <div className={'d-flex align-items-center'}>
                                <div className={'p text-start'}>
                                    <span>{obj.treatmentName}</span>
                                </div>
                                <div className={'question-checkmark ms-auto'}>
                                    <i className={'fa-light fa-check mt-1 mx-auto'}>
                                    </i>
                                </div>
                                <div className={'question-plussign ms-auto'}>
                                    <i className={'fa-light fa-plus mx-auto'}>
                                    </i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </label>
        </div>
    }

    const LinkedTreatmentOptions = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    getLinkedTreatmentsResponse?.data.treatments || [],
                    [], search
                ) as Treatment[]),
                (obj) => {
                    return renderOption(obj, true)
                })
        }
    </div>

    const UnlinkedTreatmentOptions = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    getUnlinkedTreatmentsResponse?.data.treatments || [],
                    [], search
                ) as Treatment[]),
                (obj) => {
                    return renderOption(obj, false)
                })
        }
    </div>

    const result = <div className={[
        'align-items-baseline mt-4 row'
    ].join(' ')}>
        <div className={isFromEditPage
            ? 'col-xl-3 d-none d-sm-block'
            : 'col-3 d-none d-sm-block'}>
            <label htmlFor={IDS.FACILITY_ADD_MODAL.SELECT.ADD_TREATMENTS}
                className={'form-label'}>{
                    strings.fmt?.text.facility.add.treatment
                }</label>
        </div>
        <div className={isFromEditPage
            ? 'col-xl-9 col-12 position-relative'
            : 'col-sm-9 col-12 position-relative'}>

            <div
                id={IDS.FACILITY_ADD_MODAL.SELECT.ADD_TREATMENTS}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOutsideClick(!isOutsideClick)
                }}
                className={[
                    'form-select py-3'
                ].join(' ')}
            >
                {
                    <span className={'opacity-50'}>
                        {strings.app?.text.select || ''}
                    </span>
                }

            </div>
            {/* popup window to get a list of carepath with checkboxes */}

            {isOutsideClick && <div
                ref={(e) => {
                    containerRef.current = e
                }}
                className={[
                    'dropdown-list mt-4 px-5 py-6 w-100',
                    isFromEditPage ? '' : 'position-absolute'
                ].join(' ')}>
                {/* search tab and filter icon */}
                <div className={['row justify-content-between mb-4 ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box '}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>

                {
                    department
                        ? <div className={'btn-group-checkbox-list row row-cols-1'}>
                            {/* linked first, then linked but not you, then unlinked */}
                            {getLinkedTreatmentsResponse?.data.treatments.length
                                ? <h6 className={'mb-3'}>{strings.fmt
                                    ?.text.facility.add.steps['2'].linked}</h6>
                                : ''}
                            {LinkedTreatmentOptions}
                            {getUnlinkedTreatmentsResponse
                                ?.data.treatments.length
                                ? <h6 className={'mb-3'}>{strings.fmt
                                    ?.text.facility.add.steps['2'].unlinked}</h6>
                                : ''}
                            {UnlinkedTreatmentOptions}
                        </div>
                        : <></>
                }
            </div>}

        </div>
    </div>

    return department ? result : <></>
}

export default TreatmentsInterface
