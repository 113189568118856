import { MODULE_TABLE } from '@app/app.config'
import {
    LoginKeys,
    LoginValues,
    MFAKeys,
    MFAValues,
    ResetPasswordKeys,
    ResetPasswordValues
} from '@login/type'
import * as Yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'

/** This is the name of the module. */
export const MODULE_NAME = {
    LONG: MODULE_TABLE.login.moduleName,
    SHORT: MODULE_TABLE.login.moduleShortName
}

/** This is the major version number of the module. */
export const MODULE_MAJOR_VERSION = 1

/** This is the minor version number of the module. */
export const MODULE_MINOR_VERSION = 4

/** This is the hotfix version number of the module. */
export const MODULE_HOTFIX_VERSION = 1

/** This is the full version string of the module,
 * based on the major, minor, and hotfix versions. */
export const MODULE_VERSION = [
    MODULE_NAME.SHORT,
    [
        'v.',
        MODULE_MAJOR_VERSION,
        '.',
        MODULE_MINOR_VERSION,
        '.',
        MODULE_HOTFIX_VERSION
    ].join('')
].join(' ')

export const LOGIN_FORMIK_INITIAL_VALUES: LoginValues = {
    username: '',
    password: ''
}

export const LOGIN_VALIDATION_SCHEMA: (
    usernameValidationMessage: string,
    emptyMessage: string,
) => ObjectSchema<
  LoginValues,
  AnyObject,
  Record<LoginKeys, undefined>
> = (usernameValidationMessage, emptyMessage) => Yup.object({
    username: Yup.string()
        .required(emptyMessage)
        // .matches(/^[a-zA-Z0-9]+$/, usernameValidationMessage),
        .matches(/[^'"`]+/, usernameValidationMessage),
    password: Yup.string().required(emptyMessage)
})

export const IDS = {
    LOGIN: {
        FORM: 'LOGIN__FORM',
        USERNAME: 'LOGIN__USERNAME',
        PASSWORD: 'LOGIN__PASSWORD'
    },
    MFA: {
        FORM: 'MFA__FORM',
        MFA_CODE: 'MFA__MFA_CODE'
    },
    RESET_PASSWORD: {
        FORM: 'RESET_PASSWORD__FORM',
        USERNAME: 'RESET_PASSWORD__USERNAME'
    }
}

export const MFA_FORMIK_INITIAL_VALUES: MFAValues = {
    mfaCode: ''
}

export const MFA_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
MFAValues,
  AnyObject,
  Record<MFAKeys, undefined>
> = (emptyMessage) => Yup.object({
    mfaCode: Yup.string().required(emptyMessage)
})

export const RESET_PASSWORD_FORMIK_INITIAL_VALUES: ResetPasswordValues = {
    username: ''
}

export const RESET_PASSWORD_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
ResetPasswordValues,
  AnyObject,
  Record<ResetPasswordKeys, undefined>
> = (emptyMessage) => Yup.object({
    username: Yup.string().required(emptyMessage)
})

export const MSAL_CONFIG = {
    auth: {
        clientId: '480fcc90-0ca0-4c26-a14f-871588691d02',
        // authority: 'https://login.microsoftonline.com/cf85141a-3bb6-4b26-8d96-d926da6296c6',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin + '/log/login'
    }
}
