
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { OpenListActions, OpenListValues, QuestionAnswer } from '@reasonWithMe/type'
import produce from 'immer'
import _ from 'lodash'
import React, { useMemo, useReducer } from 'react'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidV4 } from 'uuid'

interface ComponentProps {
    ids:{
        activeOptionId: string,
        questionId: string,
        answerIndex: number
    }
    obj:{
        question: QuestionAnswer & {
            id: string;
        };
    }
    answer: {
        hasAnswer: boolean;
        actualValue: any;
    }
}

const OpenList = ({
    ids,
    obj,
    answer
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const [formsState] = useReducer((
        state: Omit<OpenListValues, 'generalInfo'>[],
        action: OpenListActions
    ) => {
        switch (action.type) {
            case 'SET_INITIAL_VALUES':
                return produce(state, draft => {
                    draft.splice(0, draft.length, ...(action.arr || []))
                })
            case 'ADD_FORM':{
                return produce(state, draft => {
                    draft.push({
                        id: uuidV4(),
                        inputs: _.map(obj.question?.answerQuestions
                            ?.[ids.answerIndex].questionAnswers, (o) => {
                            const answerType = o.answerType as 'input' | 'radio'
                            | 'checkbox' | 'input-list' | 'input-list-split'

                            let userTypedAnswer: string | string[] | boolean = ''

                            if (answerType === 'input') {
                                userTypedAnswer = ''
                            }

                            if (answerType === 'radio') {
                                userTypedAnswer = false
                            }

                            if (answerType === 'checkbox') {
                                userTypedAnswer = false
                            }

                            if (answerType === 'input-list') {
                                userTypedAnswer = ['']
                            }

                            const answerValue = o.answerValue as number

                            return {
                                answerValue,
                                answerName: o.answerName || '',
                                answerType,
                                answerEditable: o.answerEditable || true,
                                userTypedAnswer
                            }
                        })
                    })
                })
            }
            case 'DELETE_FORM':{
                return produce(state, draft => {
                    if (action.index !== undefined && action.index > -1) {
                        _.pullAt(draft, [action.index])
                    }
                })
            }
            case 'UPDATE_FORM':{
                return produce(state, draft => {
                    const found = _.find(draft, (o) => {
                        return o.id === action.id
                    })

                    if (found) {
                        const inputToUpdate = _.find(found.inputs, (o) => {
                            return o.answerValue === action.columnOrder
                        })

                        if (inputToUpdate) {
                            inputToUpdate.userTypedAnswer = action.value
                        }
                    }
                })
            }
            default:
                return state
        }
    }, answer.actualValue || [
        {
            id: uuidV4(),
            inputs: _.map(obj.question?.answerQuestions
                ?.[ids.answerIndex].questionAnswers, (o) => {
                const answerType = o.answerType as 'input' | 'radio'
                | 'checkbox' | 'input-list' | 'input-list-split'

                let userTypedAnswer: string | string[] | boolean = ''

                if (answerType === 'input') {
                    userTypedAnswer = ''
                }

                if (answerType === 'radio') {
                    userTypedAnswer = false
                }

                if (answerType === 'checkbox') {
                    userTypedAnswer = false
                }

                if (answerType === 'input-list') {
                    userTypedAnswer = ['']
                }

                const answerValue = o.answerValue as number
                return {
                    answerValue,
                    answerName: o.answerName || '',
                    answerType,
                    answerEditable: o.answerEditable || true,
                    userTypedAnswer
                }
            })
        }
    ])

    const input = useMemo(() => {
        const desktopRender = formsState.map((form, outerIndex) => {
            const sorted = [...form.inputs]
                .sort((a, b) => {
                    const valueA = Number(a.answerValue || 0)
                    const valueB = Number(b.answerValue || 0)
                    return valueA - valueB
                })

            const rowHasInputList = _.includes(
                _.map(form.inputs, (o) => {
                    return o.answerType
                }), 'input-list'
            )

            const inputs = _.map(sorted,
                (formInput, innerIndex) => {
                    const answerType = formInput.answerType as 'input' | 'radio'
                        | 'checkbox' | 'input-list' | 'input-list-split'

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'input') {
                        input = <input
                            className={'form-control form-control-sm'}
                            type={'text'}
                            name={`${ answerType }-${ outerIndex }`}
                            value={formInput.userTypedAnswer as string}
                            // if answerEditable is false OR if the findPreviousInput is truthy
                            disabled={ !formInput.answerEditable}
                            onChange={(e) => {
                            }}
                            onBlur={(e) => {
                            }}
                        />
                    }

                    if (answerType === 'radio') {
                        input = <label className={'custom-radio'}>
                            <input
                                type={'radio'}
                                name={`${ answerType }-${ outerIndex }`}
                                disabled={ !formInput.answerEditable}
                                checked={formInput.userTypedAnswer as boolean}
                                onChange={(e) => {
                                }}
                            />
                            <span className={'checkmark'}>
                                <i className={'fa-solid fa-check mx-auto'}></i>
                            </span>
                        </label>
                    }

                    if (answerType === 'checkbox') {
                        input = <input
                            type={'checkbox'}
                            name={`${ answerType }-${ outerIndex }`}
                            disabled={!formInput.answerEditable}
                            checked={formInput.userTypedAnswer as boolean}
                            onChange={(e) => {
                            }}
                        />
                    }

                    if (answerType === 'input-list') {
                        const inputArr = formInput
                            .userTypedAnswer as string[]

                        const inputList = _.map(inputArr, (inputString,
                            inputIndex) => {
                            return <li key={[
                                'input-cell', outerIndex, innerIndex, inputIndex
                            ].join('-')}
                            className={'align-middle text-center mb-4'}>
                                <div className={'row align-items-center'}>
                                    <div className={'col'}>
                                        <input
                                            className={'form-control form-control-sm'}
                                            type={'text'}
                                            value={inputString}
                                            onChange={(e) => {
                                            }}
                                            onBlur={(e) => {
                                            }}
                                        />

                                    </div>
                                </div>

                            </li>
                        })

                        input = <ol type={'a'} className={'mb-0'}>
                            {
                                inputList
                            }
                        </ol>
                    }

                    return <td key={['table-cell', outerIndex, innerIndex].join('-')}
                        className={[
                            rowHasInputList ? '' : 'align-middle',
                            'text-center'
                        ].join(' ')}>
                        {input}
                    </td>
                })

            return <tr key={['table-row', outerIndex].join('-')}>
                {inputs}
                <td className={[
                    rowHasInputList ? 'pt-4' : 'align-middle',
                    'text-center ',
                    'cursor-pointer'
                ].join(' ')
                }
                >
                </td>
            </tr>
        })

        const mobileRender = formsState.map((form, outerIndex) => {
            const sorted = [...form.inputs]
                .sort((a, b) => {
                    const valueA = Number(a.answerValue || 0)
                    const valueB = Number(b.answerValue || 0)
                    return valueA - valueB
                })

            const rowOneInputs = _.filter(sorted, (item) => {
                // Custom comparator to make 'John' appear first
                return item.answerType === 'input'
            })

            const rowTwoInputs = _.filter(sorted, (item) => {
                // Custom comparator to make 'John' appear first
                return item.answerType !== 'input'
            })

            const rowHasInputList = _.includes(
                _.map(rowTwoInputs, (o) => {
                    return o.answerType
                }), 'input-list'
            )

            // idea. split into two rows right away.
            // one row with inputs
            // one row with the rest.
            const rowOne = _.map(rowOneInputs,
                (formInput, innerIndex) => {
                    const answerType = formInput.answerType as 'input' | 'radio'
                        | 'checkbox' | 'input-list' | 'input-list-split'

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'input') {
                        input = <input
                            className={'form-control form-control-sm'}
                            type={'text'}
                            name={`${ answerType }-${ outerIndex }`}
                            value={formInput.userTypedAnswer as string}
                            // if answerEditable is false OR if the findPreviousInput is truthy
                            disabled={ !formInput.answerEditable}
                            onChange={(e) => {
                            }}
                            onBlur={(e) => {
                            }}
                        />
                    }

                    return <tr key={['table-row-cell', outerIndex, innerIndex].join('-')}>
                        <td key={['table-cell', outerIndex, innerIndex].join('-')}
                            className={[
                                rowHasInputList ? '' : 'align-middle',
                                'text-center'
                            ].join(' ')}
                            colSpan={(obj.question?.answerQuestions
                                ?.[ids.answerIndex].questionAnswers.length || 0) + 1}
                        >
                            {input}
                        </td>
                    </tr>
                })

            const rowTwo = _.map(rowTwoInputs,
                (formInput, innerIndex) => {
                    const answerType = formInput.answerType as 'input' | 'radio'
                        | 'checkbox' | 'input-list' | 'input-list-split'

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'radio') {
                        input = <label className={'custom-radio'}>
                            <input
                                type={'radio'}
                                name={`${ answerType }-${ outerIndex }`}
                                disabled={ !formInput.answerEditable}
                                checked={formInput.userTypedAnswer as boolean}
                                onChange={(e) => {
                                }}
                            />
                            <span className={'checkmark'}>
                                <i className={'fa-solid fa-check mx-auto'}></i>
                            </span>
                        </label>
                    }

                    if (answerType === 'checkbox') {
                        input = <input
                            type={'checkbox'}
                            name={`${ answerType }-${ outerIndex }`}
                            disabled={!formInput.answerEditable}
                            checked={formInput.userTypedAnswer as boolean}
                            onChange={(e) => {
                            }}
                        />
                    }

                    if (answerType === 'input-list') {
                        const inputArr = formInput
                            .userTypedAnswer as string[]

                        const inputList = _.map(inputArr, (inputString,
                            inputIndex) => {
                            const showButtons = (<div>
                                {
                                    inputArr.length > 1 && (
                                        <div className={'col-auto'}>
                                            <div className={'question-plussign ms-auto'}
                                                onClick={() => { }}>
                                                <i className={'fa-light fa-minus mx-auto'}></i>
                                            </div>

                                        </div>
                                    )
                                }

                                {
                                    inputIndex === inputArr.length - 1 && (
                                        <div className={'col-auto'}>
                                            <div className={'question-plussign ms-auto'}
                                                onClick={() => {
                                                }}>
                                                <i
                                                    className={'fa-light fa-plus mx-auto'}></i>
                                            </div>

                                        </div>
                                    )
                                }
                            </div>)
                            return <li key={[
                                'input-cell', outerIndex, innerIndex, inputIndex
                            ].join('-')}
                            className={'align-middle text-center mb-4'}>
                                <div className={'row align-items-center'}>
                                    <div className={'col'}>
                                        <input
                                            className={'form-control form-control-sm'}
                                            type={'text'}
                                            value={inputString}
                                            onChange={(e) => {
                                            }}
                                            onBlur={(e) => {
                                            }}
                                        />

                                    </div>

                                    {showButtons}

                                </div>

                            </li>
                        })

                        input = <ol type={'a'} className={'mb-0'}>
                            {
                                inputList
                            }
                        </ol>
                    }

                    return <td key={['table-cell', outerIndex, innerIndex].join('-')}
                        className={[
                            rowHasInputList ? '' : 'align-middle',
                            'text-center'
                        ].join(' ')}>
                        {input}
                    </td>
                })

            return <React.Fragment key={['table-row', outerIndex].join('-')}>
                {rowOne}
                <tr>
                    {/* then an extra td because the first one is taken */}
                    <td colSpan={rowOneInputs.length || 0}></td>
                    {rowTwo}
                    <td className={[
                        rowHasInputList ? 'pt-4' : 'align-middle',
                        'text-center ',
                        'cursor-pointer'
                    ].join(' ')
                    }
                    >
                        <div className={'question-plussign ms-auto'}
                            onClick={() => {
                            }}>
                            <i className={`fa-light
                        ${ 'fa-trash px-1' }`}></i>
                        </div>

                    </td>
                </tr>
            </React.Fragment>
        })

        return <div>

            <table className={'table table-borderless'}>
                <thead>
                    <tr>
                        {
                            _.map(obj.question?.answerQuestions
                                ?.[ids.answerIndex].questionAnswers, (obj, i) => {
                                return <th className={'align-middle text-center'}
                                    key={['th', i].join('-')
                                    }>
                                    {obj.answerName}
                                </th>
                            })
                        }
                        <th className={'align-middle text-center'}>{strings.reason_with_me
                            ?.text['open-list'].action}</th>
                    </tr>
                </thead>
                <tbody>
                    {isMobile ? mobileRender : desktopRender}
                </tbody>
            </table>
        </div>

        // turning this into undefined cause the removeChild node crash
    }, [strings, formsState, isMobile])

    return <div className={'col px-4'}>
        <div className={'card multi-question-card'}>
            <div className={'card-body'}>{
                obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                    ? <h5>
                        {
                            obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                        }
                    </h5>
                    : ''
            }

            {
                obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                    ? <span className={'mb-2'}>
                        {
                            obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                        }
                    </span>
                    : ''
            }
            {input}
            </div>
        </div>
    </div>
}

export default OpenList
