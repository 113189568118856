
import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { GPACareplanStepIdDetails } from '@doc/type'
import _ from 'lodash'
import { useMemo } from 'react'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const NumberInput = ({
    reasoningObj
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue

    const input = useMemo(() => {
        const ranges = questionData?.questionAnswers[0]
        const lower = ranges?.rangeLower || 0
        const upper = ranges?.rangeUpper || 0

        return <div className={'question-number-input'}>
            <input
                className={'form-control form-control-sm'}
                // errors={_.inRange(answerValue, lower, upper) === false}
                type={'number'}
                placeholder={_.has(ranges, 'answerInner')
                    ? ranges?.answerInner
                    : strings.reason_with_me?.text.number_input.placeholder}
                // defaultValue={0}
                value={answerValue}
                onChange={(e) => {

                }}
            />
            <small>
                {`${ strings.reason_with_me
                    ?.text.float_input.range || '' } ${ lower } - ${ upper }`}
            </small>
        </div>
    }, [strings])

    return input
}

export default NumberInput
