
import Scale from '@reasonWithMe/components/types/view/Scale'

import OpenList from '@reasonWithMe/components/types/view/OpenList'

import { OpenMultiQuestionOption, QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'

interface ComponentProps {
    previousAnswers: Pick<OpenMultiQuestionOption, 'id' | 'label' | 'answers'>[]
    questionQuestion: {
        questionHeader: string;
        questionAnswers: QuestionAnswer[];
    } | undefined
}

const MultiQuestion = ({
    previousAnswers, questionQuestion
}: ComponentProps) => {
    // the previous question will always be a multi-question so use that case.
    return <div>{
        _.map(previousAnswers, ({ id, label, answers }, index) => {
            const parentKey = `button-${ index }`

            const answersInEachGroup = _.map(answers, (outerObj, index) => {
                let outerArr: JSX.Element[] = []

                if (outerObj.question.answerType === 'scale') {
                // obj.answer and obj.question should be the same index ref.
                    outerArr = _.map(outerObj.answer, (inner, innerIndex) => {
                        const key2 = [
                            id,
                            outerObj.question.id,
                            inner.id
                        ].join('-')

                        const title = outerObj.question.answerQuestions?.[innerIndex].questionTitle

                        return <div key={key2}>
                            <p>{title}</p>
                            <Scale
                                center={false}
                                answerValue={inner.actualValue}
                                questionQuestion={{
                                    questionHeader: title || '',
                                    questionAnswers: outerObj.question.answerQuestions?.[innerIndex]
                                        .questionAnswers || []
                                }}
                            />
                        </div>
                    })
                } else if (outerObj.question.answerType === 'open') {
                    outerArr = _.map(outerObj.answer, (inner, innerIndex) => {
                        const key2 = [
                            id,
                            outerObj.question.id,
                            inner.id
                        ].join('-')

                        return <div key={key2}>
                            <p>{outerObj.question.answerQuestions?.[innerIndex].questionTitle}</p>
                            <p>{
                                inner.actualValue
                            }</p>
                        </div>
                    })
                } else if (outerObj.question.answerType === 'open-list') {
                    outerArr = _.map(outerObj.answer, (inner, innerIndex) => {
                        const key2 = [
                            id,
                            outerObj.question.id,
                            inner.id
                        ].join('-')

                        // console.log('key2: ', key2)

                        return <div key={key2}>
                            <p>{outerObj.question.answerQuestions?.[innerIndex].questionTitle}</p>
                            <OpenList
                                data={inner.actualValue}
                            />
                        </div>
                    })
                }

                return <ul key={outerObj.question.id} >
                    <li>
                        {outerArr}
                    </li>
                </ul >
            })

            return <div key={parentKey}>
                <p >
                    {label}
                </p>
                {
                    answersInEachGroup
                }
            </div>
        })
    }</div>
}

export default MultiQuestion
