import { ADMIN_COLORS } from '@stylesheet/brands/rtw/admin/Colors'
import { AnswerSummaryConfig } from '@stylesheet/config/admin/AnswerSummary'

export const ANSWER_SUMMARY_CONFIG: AnswerSummaryConfig = {
    careplanStep: {
        modalGeneralColor: ADMIN_COLORS.dark,
        progressBlock: {
            borderColor: ADMIN_COLORS.gray_300
        },
        headerGradientColor: ADMIN_COLORS.headerGradientColor,
        footerButtons: {
            boxShadow: ADMIN_COLORS.shadowColor3,
            background: ADMIN_COLORS.white
        },
        mainContent: {
            background: ADMIN_COLORS.white,
            metaInfo: {
                activityColor: ADMIN_COLORS.cardTypes.activity,
                contentColor: ADMIN_COLORS.cardTypes.content,
                lessonColor: ADMIN_COLORS.cardTypes.lesson,
                reasoningColor: ADMIN_COLORS.cardTypes.reasoning
            },
            questionnaire: {
                card: {
                    color: ADMIN_COLORS.white,
                    backgroundColor: ADMIN_COLORS.dark
                }
            },
            mediaWrapper: {
                player: {
                    playButtonColor: ADMIN_COLORS.black,
                    playButtonBackground: ADMIN_COLORS.yellow1,
                    fullscreenButtonBoxShadow: `0px 2px 4px 0px ${ ADMIN_COLORS.shadowColor }`,
                    rangeBarColor: ADMIN_COLORS.gray_300,
                    rangeProgressColor: ADMIN_COLORS.dark,
                    rangeThumbColor: ADMIN_COLORS.dark,
                    rangeTrackColor: ADMIN_COLORS.gray_200
                }
            }
        }
    },
    reasonWithMe: {
        boxShadowColor: ADMIN_COLORS.black,
        formControlInputBackgroundColor: ADMIN_COLORS.gray_50,
        openList: {
            plusButton: {
                background: ADMIN_COLORS.gray_200
            }
        }
    }
}
