import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import {
    selectShowMenuBar,
    toggleDashboardTopMobileBar,
    toggleMenuDesktopBar,
    toggleMenuTopSidebarMobileBar
} from '@sidebar/slice'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

const DashboardResponsiveHeader = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const showMenuBar = useAppSelector(selectShowMenuBar)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        dispatch(toggleDashboardTopMobileBar(true))
        return () => {
            dispatch(toggleDashboardTopMobileBar(false))
        }
    }, [])

    // useEffect(() => {
    //     console.log('display sidebar value in random component: ', showMenuBar)
    // })

    const result = <div className={'container-fluid facility-header py-2'}>
        <div className={'flex-nowrap justify-content-between overflow-scroll row'}>
            <div className={'col-auto'}>
                <div className={'icon-container'} onClick={() => {
                    if (isMobile) {
                        // console.log('toggling mobile sidebar')
                        // console.log('current value is: ', showMenuBar.mobile.top.sidebar)
                        dispatch(toggleMenuTopSidebarMobileBar(
                            !showMenuBar.mobile.top.sidebar
                        ))
                    } else {
                        // console.log('toggling desktop sidebar')
                        dispatch(toggleMenuDesktopBar(!showMenuBar.desktop))
                    }
                }}>
                    <i className={'fa-square-caret-left fa-light'}></i>
                </div>
            </div>
            <div className={'col-auto'}>
                <div className={'search-box'}>
                    <i className={'fa-light fa-search'}></i>
                    <input type={'text'} className={'form-control'}
                        placeholder={strings.app?.text.search.text}
                    />
                </div>
            </div>
            <div className={'col-auto'}>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <div className={'icon-container'}>
                            <i className={'fa-bell fa-light'}></i>
                        </div>
                    </div>
                    <div className={'col-6'}>
                        <div className={'icon-container'}>
                            <i className={'fa-gear fa-light'}></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    return result
}

export default DashboardResponsiveHeader
