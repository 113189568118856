
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import MultiQuestion from '@reasonWithMe/components/types/view/MultiQuestion'
import OpenList from '@reasonWithMe/components/types/view/OpenList'
import Scale from '@reasonWithMe/components/types/view/Scale'
import { setAnswerValue } from '@reasonWithMe/slice'
import {
    OpenMultiQuestionOption,
    QuestionAnswer,
    QuestionInterfaceActions,
    ReasonWithMeState
} from '@reasonWithMe/type'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>,
}

const View = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const strings = useAppSelector(selectStrings)
    const dateFormats = useAppSelector(selectDateFormats)

    // onmount, dispatch the answer value immediately to the first value of the
    // question answers allowed array.
    useEffect(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData

        if (componentDispatch !== undefined) {
            componentDispatch({
                type: 'SET_ANSWER_VALUE',
                value: data.question?.questionAnswers[0]?.acqValue
            })
        } else {
            dispatch(setAnswerValue(
                data.question?.questionAnswers[0]?.acqValue
            ))
        }
    }, [questionInterface])

    // a useMemo that renders the view version of a question.
    const result = useMemo(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData

        let renderedComponent = <></>

        switch (data.question?.previousQuestionData?.questionType) {
            case 'open-list':
                renderedComponent = <OpenList
                    data={data.previousQuestionData?.questionAnswers?.answers}
                />
                break
            case 'float':
            case 'int32':{
                const answerValue = data.previousQuestionData?.questionAnswers as number
                renderedComponent = <p className={'text-center'}>{
                    answerValue
                }</p>
            } break
            case 'open':{
                const answerValue = data.previousQuestionData?.questionAnswers as string
                renderedComponent = <p className={'text-center'}>{
                    answerValue
                }</p>
            } break

            case 'date':{
                // unix time.
                const answerValue = data.previousQuestionData?.questionAnswers as number
                const questionQuestion = data.previousQuestionData?.questionQuestion

                if (questionQuestion?.questionAnswers[0]?.answerValue === 'date') {
                    renderedComponent = <p className={'text-center'}>{
                        format(fromUnixTime(
                            answerValue
                        ), dateFormats.format6)
                    }</p>
                } else {
                    const preposition = _.lowerCase(token.details.locale)
                        .replace(' ', '-') === 'nl-nl'
                        ? 'om'
                        : 'at'

                    renderedComponent = <p className={'text-center'}>{
                        [
                            format(fromUnixTime(
                                answerValue
                            ), dateFormats.format6),
                            preposition,
                            format(fromUnixTime(
                                answerValue
                            ), dateFormats.format5)
                        ].join(' ')
                    }</p>
                }
            } break
            case 'scale':{
                // iterate over answerValue as an array of acqValue.
                // BUT note that it can be string or string[]
                const answerValue = data.previousQuestionData?.questionAnswers
                const questionQuestion = data.previousQuestionData?.questionQuestion

                renderedComponent = <Scale answerValue={answerValue}
                    questionQuestion={questionQuestion} />
            } break
            case 'multi-tile':
            case 'multi-list':
            case 'list':{
                // iterate over answerValue as an array of acqValue.
                // BUT note that it can be string or string[]
                const answerValue = data.previousQuestionData?.questionAnswers
                const questionQuestion = data.previousQuestionData?.questionQuestion

                const arr = _.isArray(answerValue)
                    ? data.previousQuestionData?.questionAnswers
                    : [
                        data.previousQuestionData?.questionAnswers
                    ]

                // map it into acqNames.
                const actualResult = _.map(arr, (str) => {
                    const found = questionQuestion?.questionAnswers.find((o) => {
                        return o.acqValue === str
                    })

                    if (found) {
                        return found.acqName
                    } else {
                        return str
                    }
                })

                renderedComponent = <ul>
                    {
                        _.map(actualResult, (o, index) => {
                            const key = `list-li-${ index }`
                            return <li key={key}>
                                {o}
                            </li>
                        })
                    }
                </ul>
            } break
            case 'bodymap':{
                // get questionAnswers.
                const answerValue = data.previousQuestionData?.questionAnswers as QuestionAnswer[]

                // sort by front first
                const sorted = _.sortBy(answerValue, ['acqPosition', 'acqName'])

                const front = _.filter(sorted, (o) => {
                    return o.acqPosition === 'front'
                })

                const back = _.filter(sorted, (o) => {
                    return o.acqPosition === 'back'
                })

                // then render.
                renderedComponent = <>
                    {
                        front.length
                            ? <>
                                <p className={''}>{
                                    strings.reason_with_me?.text.bodymap.front
                                }</p>
                                <ul>
                                    {
                                        _.map(
                                            _.filter(sorted, (o) => {
                                                return o.acqPosition === 'front'
                                            }), (o, index) => {
                                                const key = `list-li-front-${ index }`
                                                return <li key={key}>
                                                    {o.acqName}
                                                </li>
                                            }
                                        )
                                    }
                                </ul>
                            </>
                            : ''
                    }
                    {
                        back.length
                            ? <>
                                <p className={''}>{
                                    strings.reason_with_me?.text.bodymap.back
                                }</p>
                                <ul>
                                    {
                                        _.map(
                                            _.filter(sorted, (o) => {
                                                return o.acqPosition === 'back'
                                            }), (o, index) => {
                                                const key = `list-li-back-${ index }`
                                                return <li key={key}>
                                                    {o.acqName}
                                                </li>
                                            }
                                        )
                                    }
                                </ul></>
                            : ''
                    }

                </>
            } break
            case 'bool':{
                // iterate over answerValue as an array of acqValue.
                // BUT note that it can be string or string[]
                const answerValue = data.previousQuestionData?.questionAnswers as unknown as boolean
                const questionQuestion = data.previousQuestionData?.questionQuestion

                // console.log(answerValue)
                // console.log(questionQuestion?.questionAnswers)
                // map it into acqNames.
                const found = questionQuestion?.questionAnswers.find((o) => {
                    return o.acqValue === answerValue
                })

                // console.log(found)
                let actualResult = ''

                if (found) {
                    actualResult = found.acqName || ''
                } else {
                    actualResult = answerValue.toString()
                }

                renderedComponent = <p className={'text-center'}>{
                    actualResult
                }</p>
            } break
            case 'multi-question':{
                const answerValue = data.previousQuestionData
                    ?.questionAnswers as {
                        questionUseAnswerFrom: string,
                        answers: Pick<OpenMultiQuestionOption, 'id' | 'label' | 'answers'>[]
                    }

                const questionQuestion = data.previousQuestionData?.questionQuestion

                renderedComponent = <MultiQuestion
                    previousAnswers={answerValue.answers}
                    questionQuestion={questionQuestion}
                />
            } break
            default:
                break
        }

        return renderedComponent
    }, [questionInterface])

    return <div className={'question-view'}>
        {result}
    </div>
}

export default View
