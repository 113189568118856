import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetMenuRequest, GetMenuResponse } from '@sidebar/type'

export const sidebarV10Api = createApi({
    reducerPath: 'sidebarV10Api',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getMenu: builder.mutation<GetMenuResponse, GetMenuRequest & TokenAuth>({
            query: (body) => {
                const formData: GetMenuRequest = {
                    data: {
                        ...(body.data?.isProfile && {
                            isProfile: body.data?.isProfile
                        })
                    }
                }

                return {
                    url: 'api/v1/getMenu',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        })
    })
})

export const { useGetMenuMutation } = sidebarV10Api
