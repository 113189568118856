import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import styled from '@emotion/styled'

export const HeaderImage = styled.img<{url:string}>`
    background-image: url(${ (props) => '"' + props.url + '"' });
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        height: 113px;
    }

    @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        height: 260px;

    }
    width: 100%;
    position: absolute;
    z-index: -1;
`
