import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import RecoveryComparisonChart from '@doc/components/patients/details/charts/RecoveryComparisonChart'
import _ from 'lodash'
import { ReactElement, useEffect, useMemo, useReducer, useRef, useState } from 'react'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import NewDatePicker from '@app/components/NewDatePicker'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useGetPatientProgressMutation } from '@doc/api'
import PatientProgressTable from '@doc/components/patients/details/components/patientProgress/PatientProgressTable'
import { IDS } from '@doc/constants'
import {
    ComparisonChartActions,
    ComparisonChartState,
    PatientParams,
    PatientProgressChartDataResponse
} from '@doc/type'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { closestTo, format, fromUnixTime, getUnixTime, isSameDay, subDays } from 'date-fns'
import produce from 'immer'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
    FloatingArrow,
    FloatingFocusManager,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
    useHover,
    useId,
    useInteractions,
    useTransitionStyles
} from '@floating-ui/react'

const InformationPopover = ({ boundary, text, element }:
    {boundary: HTMLDivElement | null, text:string | undefined, element: ReactElement}) => {
    const arrowRef = useRef(null)
    const [visible, setVisible] = useState(false)

    const { refs, floatingStyles, context } = useFloating({
        open: visible,
        placement: 'top',
        onOpenChange: (isOpen, event, reason) => {
            setVisible(isOpen)
            // event && console.log(event)
            // reason && console.log(reason)
        },
        middleware: [
            offset(10),
            flip({
                fallbackAxisSideDirection: 'end',
                boundary: boundary || 'clippingAncestors'
            }),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate

    })
    const { styles } = useTransitionStyles(context)

    const hover = useHover(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover
    ])

    const headingId = useId()

    return <>
        <div ref={refs.setReference} {...getReferenceProps()}>
            {element}
        </div>
        { visible && <FloatingFocusManager context={context} modal={false}>
            <div
                className={'floating-ui information'}
                ref={refs.setFloating}
                style={{
                    ...floatingStyles,
                    ...styles
                }}
                aria-labelledby={headingId}
                {...getFloatingProps()}
            >
                <FloatingArrow ref={arrowRef} context={context}
                />
                {

                    <div><span>{
                        text
                    }</span></div>
                }
            </div>

        </FloatingFocusManager>}

    </>
}

interface ComponentProps {
    floatingUiBoundary: HTMLDivElement | null,
}

const ComparisonChartInterface = ({
    floatingUiBoundary
} : ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)

    const [getPatientProgressChart, getPatientProgressChartMutation] =
    useGetPatientProgressMutation()

    const [getPatientProgressTable, getPatientProgressTableMutation] =
    useGetPatientProgressMutation()

    const { userId } = useParams<PatientParams>()
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const activeModules = useAppSelector(selectActiveModules)

    const [comparisonChartState, comparisonChartDispatch] = useReducer(
        (state: ComparisonChartState, action: ComparisonChartActions) => {
            switch (action.type) {
                case 'SET_DATE_RANGE': {
                    return produce(state, draft => {
                        draft.dateRanges[action.value.key] = action.value.date
                    })
                }
                case 'SET_DATA_ID' : {
                    return produce(state, draft => {
                        draft.dataId = action.value
                    })
                }
                case 'SET_CURRENT_INDEX' : {
                    return produce(state, draft => {
                        draft.currentIndex = action.value
                    })
                }
            }
        }, {
            dateRanges: {
                start: subDays(new Date(), 30),
                end: new Date()
            },
            dataId: '',
            currentIndex: 0,
            filters: {}
        }
    )

    const patientProgressChartData = getPatientProgressChartMutation.data
        ?.data.chartData

    const patientProgressTableData = getPatientProgressTableMutation.data
        ?.data.progressData

    const [weekDates, setWeekDates] = useState<PatientProgressChartDataResponse[]>([])

    const elementsOnEachSide = 7

    const getSurroundingElements = (index: number, array: PatientProgressChartDataResponse[]) => {
        // the surrounding element should be elementsOnEachSide points before and after.
        // if the numbers before or after is less than elementsOnEachSide,
        // the other side should cater
        const start = Math.max(0, index - elementsOnEachSide)
        const end = Math.min(array.length - 1, index + elementsOnEachSide)
        return array.slice(start, end + 1)
    }

    useEffect(() => {
        setWeekDates(getSurroundingElements(
            comparisonChartState.currentIndex, patientProgressChartData || []
        ))
    }, [comparisonChartState.currentIndex, patientProgressChartData])

    const goToToday = () => {
        const today = new Date()

        const dateStrings = _.map(patientProgressChartData, (o) => {
            return fromUnixTime(_.floor(o.timestamp))
        })

        const closestDate = dateStrings.find(dateString => isSameDay(dateString, today)) ||
         closestTo(today, dateStrings)
        // console.log('Closest date to today is: ', closestDate)

        if (closestDate) {
            const closestIndex = dateStrings.findIndex(dateString =>
                isSameDay(dateString, closestDate))
            console.log('Index is: ', closestIndex)

            // before setting, make sure that the difference between
            // the lenght of the array and the closest index is elementsOnEachSide.

            if (((patientProgressChartData?.length || 0) - 1) - closestIndex < elementsOnEachSide) {
                const diff = elementsOnEachSide -
                ((patientProgressChartData?.length || 0) - 1 - closestIndex)

                console.log(
                    (patientProgressChartData?.length || 0),
                    closestIndex,
                    elementsOnEachSide,
                    diff
                )

                const newIndex = closestIndex - diff
                console.log('index now is: ', newIndex)

                comparisonChartDispatch({
                    type: 'SET_CURRENT_INDEX',
                    value: newIndex
                })
            } else {
                comparisonChartDispatch({
                    type: 'SET_CURRENT_INDEX',
                    value: closestIndex
                })
            }
        } else {
            // console.log('closest date is undefined.')
        }
    }

    useEffect(() => {
        goToToday()
    }, [patientProgressChartData])

    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid && userId &&
                 comparisonChartState.dateRanges.start &&
                 comparisonChartState.dateRanges.end
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid4 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientProgress.path,
                        true
                    )

                    // console.log('isValid: ', isValid4)

                    if (isValid4 && newToken.value) {
                        getPatientProgressChart({
                            authToken: newToken.value,
                            data: {
                                dateFrom: getUnixTime(comparisonChartState.dateRanges.start),
                                dateTo: getUnixTime(comparisonChartState.dateRanges.end),
                                userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    const fetchData2 = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid && userId &&
                comparisonChartState.dateRanges.start && comparisonChartState.dateRanges.end &&
                 comparisonChartState.dataId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid4 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientProgress.path,
                        true
                    )

                    if (isValid4 && newToken.value) {
                        getPatientProgressTable({
                            authToken: newToken.value,
                            data: {
                                dateFrom: getUnixTime(comparisonChartState.dateRanges.start),
                                dateTo: getUnixTime(comparisonChartState.dateRanges.end),
                                dataId: comparisonChartState.dataId,
                                userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        if (getPatientProgressChartMutation.error) {
            const message = getErrorText(getPatientProgressChartMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientProgressChartMutation.error])

    useEffect(() => {
        if (getPatientProgressTableMutation.error) {
            const message = getErrorText(getPatientProgressTableMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientProgressTableMutation.error])

    useEffect(() => {
        // console.log('useriD: ', userId)
        if (userId) {
            return fetchData(token)
        } else {
            return () => {}
        }
    }, [token.id, token.valid, userId, comparisonChartState.dateRanges])

    useEffect(() => {
        if (userId) {
            return fetchData2(token)
        } else {
            return () => {}
        }
    }, [comparisonChartState.dataId])

    const chartRangeId = [
        IDS.CHART.PROGRESS_RANGE
    ].join(' ')

    const IconPicker = <NewDatePicker
        id={chartRangeId}
        singleDate={comparisonChartState.dateRanges.start}
        startDate={comparisonChartState.dateRanges.start}
        endDate ={comparisonChartState.dateRanges.end }
        selectsRange={true}
        isRange={(dates) => {
            const [start, end] = dates

            comparisonChartDispatch({
                type: 'SET_DATE_RANGE',
                value: {
                    date: start,
                    key: 'start'
                }
            })

            comparisonChartDispatch({
                type: 'SET_DATE_RANGE',
                value: {
                    date: end,
                    key: 'end'
                }
            })

            // request to execute fetch data right after.
        }}

    />

    const titleDateRange = useMemo(() => {
        if (weekDates.length) {
            const start = fromUnixTime(weekDates[0]?.timestamp)
            const end = fromUnixTime(weekDates[weekDates.length - 1].timestamp)

            const formattedStartDate = format(start, 'd MMM yyyy')
            const formattedEndDate = format(end, 'd MMM yyyy')

            return weekDates.length
                ? <span className={'fw-semibold'}>{
                    [
                        formattedStartDate,
                        ' - ',
                        formattedEndDate
                    ].join(' ')
                }</span>
                : ''
        }

        return ''
    }, undefined)

    const header = <div className={'row justify-content-between mb-4 align-items-center g-2'}
    >
        <div className={'col-auto'}>
            <div className={'align-items-baseline gx-2 row'}>
                <div className={'col-auto'}>
                    <h5 className={'mb-0 fw-semibold '} >
                        {strings.doc?.text.patient.patient_progress.recovery_comparison}
                    </h5>
                </div>
                <div className={'col-auto'}>
                    {/* date range label is moved to here without the arrows */}
                    {weekDates.length
                        ? <span className={'fw-semibold'}>{
                            titleDateRange
                        }</span>
                        : ''}

                </div>
            </div>
        </div>
        <div className={'col-auto'}>
            <div className={'row justify-content-between align-items-center'}>
                <div className={'col-auto'}>
                    {/* refresh button */}
                    <div className={'icon d-inline-block clickable'} onClick={() => {
                        if (getPatientProgressChartMutation.isLoading === false) {
                            fetchData(token)
                        }
                    }}>
                        <i className={'fa-light fa-rotate-right'} aria-hidden={'true'}></i>
                    </div>
                </div>
                <div className={'col-auto'}>
                    {/* date range picker */}
                    {IconPicker}
                </div>
            </div>
        </div>
    </div>

    const filters = <div className={'row justify-content-between g-3'}>
        <div className={'col-auto'}>
            <div className={'align-items-center g-2 gap-3 row'}>
                <div className={'col-auto'}>
                    <div className={'row align-items-center g-2'}>
                        <div className={'col-auto'}>
                            <i className={'fa-light fa-calendar-arrow-up'}></i>
                        </div>
                        <div className={'col-auto'}>
                            <span className={'fw-light clickable'} onClick={() => {
                                goToToday()
                            }}>
                                {strings.doc?.text.patient.patient_progress.today}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'col-auto'}>
                    <div className={'row g-2'}>
                        <div className={'col-auto'}>
                            <InformationPopover
                                boundary={floatingUiBoundary}
                                text={strings.doc?.text
                                    .patient.patient_progress.move_left}
                                element={
                                    <i className={[
                                        'fa-solid fa-chevron-left clickable',
                                        comparisonChartState.currentIndex <=
                                        elementsOnEachSide
                                            ? 'text-black-50'
                                            : ''
                                    ].join(' ')}
                                    onClick={() => {
                                        if (!(comparisonChartState.currentIndex <=
                                            elementsOnEachSide)) {
                                            const newIndexLeft = comparisonChartState
                                                .currentIndex > 0
                                                ? comparisonChartState.currentIndex - 1
                                                : 0

                                            comparisonChartDispatch({
                                                type: 'SET_CURRENT_INDEX',
                                                value: newIndexLeft
                                            })
                                        }
                                    }}
                                    ></i>
                                }
                            />
                        </div>
                        <div className={'col-auto'}>
                            <InformationPopover
                                boundary={floatingUiBoundary}
                                text={strings.doc?.text
                                    .patient.patient_progress.move_right}
                                element={
                                    <i className={[
                                        'fa-solid fa-chevron-right clickable',
                                        patientProgressChartData?.length &&
                                comparisonChartState.currentIndex + elementsOnEachSide ===
                                patientProgressChartData.length - 1
                                            ? 'text-black-50'
                                            : ''
                                    ].join(' ')}
                                    onClick={() => {
                                        if (patientProgressChartData?.length) {
                                            if (!(
                                                comparisonChartState.currentIndex +
                                                 elementsOnEachSide === patientProgressChartData
                                                    ?.length - 1)) {
                                                const maximumIndex = patientProgressChartData
                                                    ?.length - 1

                                                const newIndexRight = comparisonChartState
                                                    .currentIndex < maximumIndex
                                                    ? comparisonChartState.currentIndex + 1
                                                    : maximumIndex

                                                comparisonChartDispatch({
                                                    type: 'SET_CURRENT_INDEX',
                                                    value: newIndexRight
                                                })
                                            }
                                        }
                                    }}
                                    ></i>
                                }
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    const isLoadingContent = <small className={'d-block text-center py-2'}>
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    </small>

    const isChartEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const chart = getPatientProgressChartMutation.isLoading
        ? isLoadingContent
        : patientProgressChartData?.length
            ? <div className={'chart-container mb-4'}>
                <RecoveryComparisonChart patientProgressChartData={patientProgressChartData}
                    weekDates={weekDates}
                    comparisonChartDispatch={comparisonChartDispatch}
                />
            </div>
            : getPatientProgressChartMutation.isSuccess && isChartEmpty

    return <>
        <div className={'course-interface'}>
            {header}
            {filters}
            {chart}
            <PatientProgressTable
                patientProgressTableData={patientProgressTableData}
                isLoading={getPatientProgressTableMutation.isLoading}
                isSuccess={getPatientProgressTableMutation.isSuccess}
                dataId={comparisonChartState.dataId}
            />
        </div>
    </>
}

export default ComparisonChartInterface
