import { MODULE_TABLE } from '@app/app.config'
import {
    CareFacilityKeys,
    CareFacilityValues,
    CareProfessionalKeys,
    CareProfessionalValues,
    CarepathKeys,
    CarepathValues,
    Department,
    DepartmentKeys,
    DepartmentValues,
    GetCareProfessionalsResponse,
    GetTreatmentsResponse,
    TreatmentKeys,
    TreatmentValues
} from '@fmt/type'
import * as Yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'

/** This is the name of the module. */
export const MODULE_NAME = {
    LONG: MODULE_TABLE.fmt.moduleName,
    SHORT: MODULE_TABLE.fmt.moduleShortName
}

/** This is the major version number of the module. */
export const MODULE_MAJOR_VERSION = 1

/** This is the minor version number of the module. */
export const MODULE_MINOR_VERSION = 0

/** This is the hotfix version number of the module. */
export const MODULE_HOTFIX_VERSION = 9

/** This is the full version string of the module,
 * based on the major, minor, and hotfix versions. */
export const MODULE_VERSION = [
    MODULE_NAME.SHORT, ' ',
    `v.${ MODULE_MAJOR_VERSION }.${ MODULE_MINOR_VERSION }.${ MODULE_HOTFIX_VERSION }`
].join('')
export const IDS = {
    FACILITY: {
        ADD: {
            FORM: 'FACILITY_ADD__FORM',
            FACILITY_NAME: 'FACILITY_ADD__FACILITY_NAME',
            POSTAL_CODE: 'FACILITY_ADD__POSTAL_CODE',
            HOUSE_NUMBER: 'FACILITY_ADD__HOUSE_NUMBER',
            SUFFIX: 'FACILITY_ADD__SUFFIX',
            STREET: 'FACILITY_ADD__STREET',
            CITY: 'FACILITY_ADD__CITY',
            /** group 2 */
            GENERAL_NUMBER: 'FACILITY_ADD__GENERAL_NUMBER',
            URGENT_NUMBER: 'FACILITY_ADD__URGENT_NUMBER',
            WEBSITE: 'FACILITY_ADD__WEBSITE',
            EMAIL: 'FACILITY_ADD__EMAIL',
            INFO: 'FACILITY_ADD__INFO'

        },
        EDIT: {
            FORM: 'FACILITY_EDIT__FORM',
            FACILITY_NAME: 'FACILITY_EDIT__FACILITY_NAME',
            POSTAL_CODE: 'FACILITY_EDIT__POSTAL_CODE',
            HOUSE_NUMBER: 'FACILITY_EDIT__HOUSE_NUMBER',
            SUFFIX: 'FACILITY_EDIT__SUFFIX',
            STREET: 'FACILITY_EDIT__STREET',
            CITY: 'FACILITY_EDIT__CITY',
            /** group 2 */
            GENERAL_NUMBER: 'FACILITY_EDIT__GENERAL_NUMBER',
            URGENT_NUMBER: 'FACILITY_EDIT__URGENT_NUMBER',
            WEBSITE: 'FACILITY_EDIT__WEBSITE',
            EMAIL: 'FACILITY_EDIT__EMAIL',
            INFO: 'FACILITY_EDIT__INFO'

        },
        VIEW: {
            // FORM: 'FACILITY_VIEW__FORM',
            FACILITY_NAME: 'FACILITY_VIEW__FACILITY_NAME',
            STREET_NAME_HOUSE_NUMBER: 'FACILITY_VIEW__STREET_NAME_HOUSE_NUMBER',
            ZIP_CODE_CITY: 'FACILITY_VIEW__ZIP_CODE_CITY',
            PHONE_NUMBER: 'FACILITY_VIEW__PHONE_NUMBER',
            EMAIL_ADDRESS: 'FACILITY_VIEW__EMAIL_ADDRESS',
            WEBSITE: 'FACILITY_VIEW__WEBSITE'
        },
        REVIEW: {
            ADDRESS_LINE_1: 'FACILITY_REVIEW__ADDRESS_LINE_1',
            ADDRESS_LINE_2: 'FACILITY_REVIEW__ADDRESS_LINE_2'
        }
    },
    DEPARTMENT: {
        EDIT: {
            FORM: 'DEPARTMENT_EDIT__FORM',
            DEPARTMENT_NAME: 'DEPARTMENT_EDIT__DEPARTMENT_NAME',
            POSTAL_CODE: 'DEPARTMENT_EDIT__POSTAL_CODE',
            HOUSE_NUMBER: 'DEPARTMENT_EDIT__HOUSE_NUMBER',
            SUFFIX: 'DEPARTMENT_EDIT__SUFFIX',
            STREET: 'DEPARTMENT_EDIT__STREET',
            CITY: 'DEPARTMENT_EDIT__CITY',
            /** group 2 */
            GENERAL_NUMBER: 'DEPARTMENT_EDIT__GENERAL_NUMBER',
            URGENT_NUMBER: 'DEPARTMENT_EDIT__URGENT_NUMBER',
            WEBSITE: 'DEPARTMENT_EDIT__WEBSITE',
            EMAIL: 'DEPARTMENT_EDIT__EMAIL',
            INFO: 'DEPARTMENT_EDIT__INFO'
        }
    },
    CAREPROFESSIONAL: {
        EDIT: {
            FORM: 'CAREPROFESSIONAL_EDIT__FORM',
            NAME: 'CAREPROFESSIONAL_EDIT__NAME',
            PROFESSION: 'CAREPROFESSIONAL_EDIT__PROFESSION',
            LOCATION: 'CAREPROFESSIONAL_EDIT__LOCATION',
            PHONENUMBER: 'CAREPROFESSIONAL_EDIT__PHONENUMBER',
            EMAIL: 'CAREPROFESSIONAL_EDIT__EMAIL',
            ADD_SYSTEM_USER: 'CAREPROFESSIONAL_EDIT__ADD_SYSTEM_USER'
        }
    },
    TREATMENT: {
        EDIT: {
            FORM: 'TREATMENTL_EDIT__FORM',
            NAME: 'TREATMENT_EDIT__TREATMENT_NAME'
        }
    },
    CAREPATH: {
        EDIT: {
            FORM: 'CAREPATH_EDIT__FORM',
            NAME: 'CAREPATH_EDIT__CAREPATH_NAME',
            DATENAME: 'CAREPATH_EDIT__DATENAME',
            LINK_TREATMENTS: 'CAREPATH_EDIT__TREATMENTS_LINK__SELECT'
        }
    },
    FACILITY_ADD_MODAL: {
        /** from the add modal interface for adding care facilities. don't remove */
        SELECT: {
            ADD_DEPARTMENT: 'DEPARTMENTS_ADD__SELECT',
            SEARCH_DEPARTMENT: 'DEPARTMENTS_ADD__SEARCH',
            ADD_CAREPROFESSIONALS: 'CAREPROFESSIONALS_ADD__SELECT',
            ADD_CAREPROFESSIONAL: 'CAREPROFESSIONAL_ADD__SELECT',
            SEARCH_CAREPROFESSIONALS: 'CAREPROFESSIONALS_ADD__SEARCH',
            ADD_TREATMENTS: 'TREATMENTS_ADD__SELECT',
            SEARCH_TREATMENTS: 'TREATMENTS_ADD__SEARCH',
            ADD_CAREPATHS: 'CAREPATHS_ADD__SELECT',
            SEARCH_CAREPATHS: 'CAREPATHS_ADD__SEARCH'
        }

    }

}

export const FACILITY_STEP_ONE_FORMIK_INITIAL_VALUES: CareFacilityValues = {
    facilityName: '',
    facilityAddressStreet: '',
    facilityAddressNumber: '',
    facilityAddressSuffix: '',
    facilityPostalcode: '',
    facilityCity: '',
    facilityPhonenumber: '',
    facilityEmailAddress: '',
    facilityWebsite: ''
}

export const FACILITY_STEP_TWO_FORMIK_INITIAL_VALUES: {
    linkedDepartments: Department[]
} = {
    linkedDepartments: []
}

export const FACILITY_STEP_THREE_FORMIK_INITIAL_VALUES: {
    linkedCareprofessionals: {
        department: Department,
        collapsible: boolean,
        arr: GetCareProfessionalsResponse['data']['careprofessionals']
    }[]
} = {
    linkedCareprofessionals: []
}

export const FACILITY_STEP_FOUR_FORMIK_INITIAL_VALUES: {
    linkedTreatments:{
        department: Department,
        collapsible: boolean,
        arr: GetTreatmentsResponse['data']['treatments']
    }[]
} = {
    linkedTreatments: []
}

export const FACILITY_STEP_ONE_VALIDATION_SCHEMA: (
    emailMessage: string,
    emptyMessage: string,
) => ObjectSchema<
CareFacilityValues,
AnyObject,
Record<CareFacilityKeys, any>
> = (emailMessage, emptyMessage) => Yup.object({
    facilityName: Yup.string().required(emptyMessage),
    facilityAddressStreet: Yup.string().required(emptyMessage),
    facilityAddressNumber: Yup.string().required(emptyMessage),
    facilityAddressSuffix: Yup.string(),
    facilityPostalcode: Yup.string().required(emptyMessage),
    facilityCity: Yup.string().required(emptyMessage),
    facilityPhonenumber: Yup.string(),
    facilityEmailAddress: Yup.string(),
    facilityWebsite: Yup.string()
})

// you can only proceed if you have at least one linked department.
export const FACILITY_STEP_TWO_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
{
    linkedDepartments: Department[]
},
AnyObject,
Record<'linkedDepartments', any>
> = (emptyMessage) => Yup.object({
    linkedDepartments: Yup.array().required(emptyMessage)
})

/**
 * departments
 */

export const DEPARTMENT_FORMIK_INITIAL_VALUES: DepartmentValues = {
    departmentName: '',
    departmentAddressStreet: '',
    departmentAddressNumber: '',
    departmentAddressSuffix: '',
    departmentPostalcode: '',
    departmentCity: '',
    departmentPhonenumber: '',
    departmentEmailAddress: '',
    departmentWebsite: ''
}

export const DEPARTMENT_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
DepartmentValues,
AnyObject,
Record<DepartmentKeys, undefined>
> = (emptyMessage) => Yup.object({
    departmentName: Yup.string().required(emptyMessage),
    departmentAddressStreet: Yup.string().required(emptyMessage),
    departmentAddressNumber: Yup.string().required(emptyMessage),
    departmentAddressSuffix: Yup.string(),
    departmentPostalcode: Yup.string().required(emptyMessage),
    departmentCity: Yup.string().required(emptyMessage),
    departmentPhonenumber: Yup.string(),
    departmentEmailAddress: Yup.string(),
    departmentWebsite: Yup.string()
})

/**
 * careprofessional
 */

export const CAREPROFESSIONAL_FORMIK_INITIAL_VALUES: CareProfessionalValues = {
    careProfessionalName: '',
    careProfessionalProfession: '',
    careProfessionalLocation: '',
    careProfessionalPhoneNumber: '',
    careProfessionalEmail: '',
    addSystemUser: false
}

export const CAREPROFESSIONAL_VALIDATION_SCHEMA: (
    emptyMessage: string,
    emailMessage: string,
) => ObjectSchema<
CareProfessionalValues,
AnyObject,
Record<CareProfessionalKeys, undefined>
> = (emptyMessage, emailMessage) => Yup.object({
    careProfessionalName: Yup.string(),
    careProfessionalProfession: Yup.string().required(emptyMessage),
    careProfessionalLocation: Yup.string().required(emptyMessage),
    careProfessionalPhoneNumber: Yup.string().required(emptyMessage),
    careProfessionalEmail: Yup.string()
        .email(emailMessage)
        .required(emptyMessage),
    addSystemUser: Yup.boolean().required(emptyMessage)
})

/**
 * carepath.
 */

export const CAREPATH_FORMIK_INITIAL_VALUES: CarepathValues = {
    carepathName: '',
    dateName: ''
}

export const CAREPATH_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
CarepathValues,
AnyObject,
Record<CarepathKeys, undefined>
> = (emptyMessage) => Yup.object({
    carepathName: Yup.string(),
    dateName: Yup.string().required(emptyMessage)
})

/**
 * treatment
 */

export const TREATMENT_FORMIK_INITIAL_VALUES: TreatmentValues = {
    treatmentName: ''
}

export const TREATMENT_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
TreatmentValues,
AnyObject,
Record<TreatmentKeys, undefined>
> = (emptyMessage) => Yup.object({
    treatmentName: Yup.string()
})

export const DETAILS_ROW_LIMIT = 10
