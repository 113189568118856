
import { GPACareplanStepIdDetails } from '@doc/type'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import remarkDefinitionList from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const Text = ({
    reasoningObj
}: ComponentProps) => {
    const questionData = reasoningObj.questionData

    const forMarkdownContent = _.isString(
        questionData?.questionDescription)
        ? questionData?.questionDescription
            .replace(/==([^=]+)==/g, '<mark>$1</mark>')
            .replace(/~(\d+)~/g, '<sub>$1</sub>')
            .replace(/~~([^~]+)~~/g, '<s>$1</s>')
        : ''

    return <div className={'question-text markdown'}>
        <ReactMarkdown
            components={{
                // supersub replaces markdown with del tags
                // for somereason.
                del: (props) => <sub {...props} />,
                ul: (props) => {
                    const modifiedProps = { ...props }
                    // eslint-disable-next-line react/prop-types
                    modifiedProps.ordered = props.ordered.toString() as any

                    if (modifiedProps.className && modifiedProps.className
                        .includes('contains-task-list')) {
                        return <ul
                            {...modifiedProps}
                            className={[
                                'contains-task-list list-unstyled ps-4'
                            ].join(' ')}
                        />
                    } else {
                        return <ul
                            {...modifiedProps}

                        />
                    }
                }
            }}
            linkTarget={'_blank'}
            remarkPlugins={[
                remarkBreaks,
                remarkGfm,
                supersub,
                remarkEmoji,
                remarkDefinitionList,
                remarkHeadingId
            ]} rehypePlugins={[
                rehypeRaw
            ]}
        >
            {`${ forMarkdownContent }`}
        </ReactMarkdown>
    </div>
}

export default Text
