import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import { UpdateWorkflowProgressRequest, UpdateWorkflowProgressResponse } from '@login/type'
import { ValidateMFARequest, ValidateMFAResponse } from '@profile/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
    GetPersonalDetailsRequest,
    GetPersonalDetailsResponse,
    GetTreatmentRequest,
    GetTreatmentResponse,
    GetUserTreatmentsRequest,
    GetUserTreatmentsResponse,
    SetAccountRequest,
    SetAccountResponse,
    SetMFARequest,
    SetMFAResponse,
    SetSubscriberIdRequest,
    SetSubscriberIdResponse,
    UpdatePersonalDetailsRequest,
    UpdatePersonalDetailsResponse,
    UpdateTreatmentRequest,
    UpdateTreatmentResponse
} from '@registration/type'
import _ from 'lodash'
export const registrationV10API = createApi({
    reducerPath: 'registrationV10API',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getTreatment: builder
            .mutation<GetTreatmentResponse, GetTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetTreatmentRequest = {
                        data: body.data
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.registration.apiPaths
                                .getTreatment.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getUserTreatments: builder
            .mutation<GetUserTreatmentsResponse, GetUserTreatmentsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetUserTreatmentsRequest = {
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.registration.apiPaths
                                .getUserTreatments.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateTreatment: builder
            .mutation<UpdateTreatmentResponse, UpdateTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdateTreatmentRequest = {
                        data: {
                            treatmentId: body.data.treatmentId,
                            startDate: body.data.startDate,
                            carepathId: body.data.carepathId,
                            myCareplanId: body.data.myCareplanId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.registration.apiPaths
                                .updateTreatment.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPersonalDetails: builder
            .mutation<GetPersonalDetailsResponse, GetPersonalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetPersonalDetailsRequest = {
                        data: {
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.registration.apiPaths
                                .getPersonalDetails.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updatePersonalDetails: builder
            .mutation<UpdatePersonalDetailsResponse, UpdatePersonalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdatePersonalDetailsRequest = {
                        data: {
                            firstName: body.data.firstName,
                            lastName: body.data.lastName,
                            email: body.data.email
                            // dob: body.data.dob
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.registration.apiPaths
                                .updatePersonalDetails.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        setAccount: builder
            .mutation<SetAccountResponse, SetAccountRequest & TokenAuth>({
                query: (body) => {
                    const formData: SetAccountRequest = {
                        data: {
                            username: body.data.username,
                            password: body.data.password
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.registration.apiPaths
                                .setAccount.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        validateMFA: builder.mutation<ValidateMFAResponse,
            ValidateMFARequest & TokenAuth>({
                query: (body) => {
                    const formData: ValidateMFARequest = {
                        data: {
                            token: body.data.token
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.registration.apiPaths.validateMFA.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        setMFA: builder.mutation<SetMFAResponse, SetMFARequest & TokenAuth>({
            query: (body) => {
                const formData: SetMFARequest = {
                    data: {
                        mfaToggle: body.data.mfaToggle
                    }
                }

                return {
                    url: _.trimStart(
                        MODULE_TABLE.registration.apiPaths.setMFA.path, '/'
                    ),
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`
                    },
                    body: formData
                }
            }
        }),
        setSubscriberId: builder
            .mutation<SetSubscriberIdResponse, SetSubscriberIdRequest & TokenAuth>({
                query: (body) => {
                    const formData: SetSubscriberIdRequest = {
                        data: {
                            subscriberId: body.data.subscriberId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.registration.apiPaths.setSubscriberId.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        updateWorkflowProgress: builder
            .mutation<UpdateWorkflowProgressResponse,
            UpdateWorkflowProgressRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdateWorkflowProgressRequest = {
                        data: {
                            stepId: body.data?.stepId,
                            workflowId: body.data?.workflowId,
                            percentComplete: body.data?.percentComplete
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.reasonWithMe.apiPaths
                                .updateWorkflowProgress.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useSetAccountMutation,
    useGetPersonalDetailsMutation,
    useGetTreatmentMutation,
    useGetUserTreatmentsMutation,
    useSetMFAMutation,
    useValidateMFAMutation,
    useUpdatePersonalDetailsMutation,
    useUpdateTreatmentMutation,
    useSetSubscriberIdMutation,
    useUpdateWorkflowProgressMutation
} = registrationV10API
