import { useAppDispatch, useAppSelector } from '@app/app.hook'
import {
    BuildingBlock,
    BuildingBlockProgress,
    BuildingBlockProgressActions,
    BuildingBlockProgressState,
    MediaPlayer
} from '@careplan/type'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { SEEKER, VOLUME } from '@careplan/constants'
import {
    selectMediaPlayers,
    setMuted,
    setPlayed,
    setPlaying,
    setSeeking,
    setVolume
} from '@careplan/slice'
import _ from 'lodash'
import { OnProgressProps } from 'react-player/base'
import ReactPlayer from 'react-player/lazy'
import screenfull from 'screenfull'

interface ComponentProps {
    playerState: MediaPlayer,
    type: 'audio' | 'video'
    buildingBlock: BuildingBlock
    playerRef: {
        key: string;
        player: ReactPlayer | null;
    } | undefined
    mapKey: string,
    progressState?: BuildingBlockProgressState
    progressDispatch?: React.Dispatch<BuildingBlockProgressActions>,
    personalCareplanStepId?: string | undefined,
    buildingBlockProgress?: BuildingBlockProgress,
    updateBuildingBlockProgress?: (requestData: {
        obj: BuildingBlockProgress, percentage: number, miscVar?: any
    }) => Promise<void>
}

const Player = ({
    playerState, type, buildingBlock,
    playerRef, mapKey, progressState, progressDispatch,
    personalCareplanStepId, buildingBlockProgress, updateBuildingBlockProgress
}: ComponentProps) => {
    const mediaPlayerState = useAppSelector(selectMediaPlayers)

    const dispatch = useAppDispatch()

    const seekerInputRef = useRef<HTMLInputElement | null>(null)
    const volumeInputRef = useRef<HTMLInputElement | null>(null)

    const [backgroundSize, setBackgroundSize] = useState<string | null>(null)

    const [isDone, setIsDone] = useState<boolean>(
        buildingBlockProgress?.initialProgress !== undefined &&
        buildingBlockProgress.initialProgress >= 100
    )

    const [isPlayedOnce, setIsPlayedOnce] = useState<boolean>(false)

    useLayoutEffect(() => {
        if (seekerInputRef.current) {
            const target = seekerInputRef.current
            if (target) {
                const min = parseFloat(target.min)
                const max = parseFloat(target.max)
                const val = parseFloat(target.value)
                setBackgroundSize((val - min) * 100 / (max - min) + '% 100%')
            }
        }
    })

    const updateVolumeProgress = () => {
        const target = volumeInputRef.current
        if (target) {
            const min = parseFloat(target.min)
            const max = parseFloat(target.max)

            const val = parseFloat(target.value)
            target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
        }
    }

    // const handleStop: MouseEventHandler<HTMLDivElement> = (e) => {
    //     dispatch(setPlayed({
    //         key: mapKey,
    //         played: 0
    //     }))

    //     dispatch(setPlaying({
    //         key: mapKey,
    //         playing: false
    //     }))

    //     if (playerRef) {
    //         playerRef.player?.seekTo(parseFloat('0'))
    //     }

    //     const target = seekerInputRef.current
    //     if (target) {
    //         target.style.backgroundSize = 0 + '% 100%'
    //     }
    // }

    const handleSeekMouseDown = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        dispatch(setSeeking({
            key: mapKey,
            seeking: true
        }))
    }

    const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const limit = (SEEKER.VALUE + SEEKER.MIN) / (100 + SEEKER.MAX)

        const playedSeconds = ((buildingBlockProgress?.activeProgress || 0) / 100)

        if (parseFloat(e.target.value) <= playedSeconds + limit) {
            dispatch(setPlayed({
                key: mapKey,
                played: parseFloat(e.target.value)
            }))

            const target = e.target
            const min = parseFloat(target.min)
            const max = parseFloat(target.max)
            const val = parseFloat(target.value)
            target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
        } else {
            console.error('Seek duration exceeded. Player seek method aborted.')
        }
    }

    const handleSeekMouseUp = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        dispatch(setSeeking({
            key: mapKey,
            seeking: false
        }))

        if (playerRef) {
            playerRef.player?.seekTo(parseFloat(e.currentTarget.value))
        }
    }

    const handlePlay = () => {
        // pause all media players except for the one selected.
        _.forEach(mediaPlayerState, (o) => {
            if (o.key !== mapKey) {
                dispatch(setPlaying({
                    key: o.key,
                    playing: false
                }))
            }
        })

        // and then play yours.
        dispatch(setPlaying({
            key: mapKey,
            playing: true
        }))
    }

    const handlePause = () => {
        dispatch(setPlaying({
            key: mapKey,
            playing: false
        }))
    }

    const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setVolume({
            key: mapKey,
            volume: parseFloat(e.target.value)
        }))
    }

    // glad this is being called once
    const handleDuration = (duration: number) => {
        if (buildingBlockProgress) {
            progressDispatch && progressDispatch({
                type: 'CREATE_TIME_NEEDED',
                value: {
                    buildingBlockId: buildingBlockProgress.buildingBlockId,
                    timeNeeded: duration
                }
            })
            // const playedSeconds = (buildingBlockProgress.initialProgress / 100)

            // refer to comment in IHD-349
            // if (playerRef) {
            //     playerRef.player?.seekTo(playedSeconds)
            // }
        } else {
            // if (playerRef) {
            //     playerRef.player?.seekTo(0)
            // }
        }
    }

    /** wonder why handleReady is being called infinitely. */
    // const handleReady = () => {

    // }

    // ihd-349. before playing the video player for the first time, set the seekTo Values.
    useEffect(() => {
        if (playerState?.data.playing === true) {
            setIsPlayedOnce(true)
        }

        // do nothing after that.
    }, [playerState?.data.playing])

    useEffect(() => {
        if (isPlayedOnce) {
            if (buildingBlockProgress) {
                const playedSeconds = (buildingBlockProgress.initialProgress / 100)
                if (playerRef) {
                    playerRef.player?.seekTo(playedSeconds)
                }
            } else {
                if (playerRef) {
                    playerRef.player?.seekTo(0)
                }
            }
        }
    }, [isPlayedOnce])

    const handleProgress = (e: OnProgressProps) => {
        if (playerState?.data.playing === true) {
            if (buildingBlockProgress) {
                let percentage = (e.playedSeconds / (buildingBlockProgress.timeNeeded || 1)) * 100
                percentage = Math.max(0, Math.min(percentage, 100))
                const divisor = 10

                if (percentage % divisor <= 1 || percentage >= 100) {
                    if (!isDone) {
                        if (percentage >= buildingBlockProgress.activeProgress) {
                            // console.log('updating progress slowly')
                            updateBuildingBlockProgress && updateBuildingBlockProgress({
                                obj: buildingBlockProgress,
                                percentage
                            })
                        }
                    } else {
                        // console.log('player already finished. no longer updating')
                    }
                }
            }

            if (e.played > 0) {
                dispatch(setPlayed({
                    key: mapKey,
                    played: e.played
                }))
            }

            if (!playerState?.data.seeking) {
                //
            }
        }
    }

    useLayoutEffect(() => {
        // default is always on 1 (100%).
        updateVolumeProgress()
    }, [playerState?.data.volume])

    const handleVideoEnd = () => {
        // Video has finished playing
        // console.log('player has finished')
        setIsDone(true)

        // don't forget to pause the player.
        dispatch(setPlaying({
            key: mapKey,
            playing: false
        }))

        // we are not updating the progress here. ever.
    }

    return <div>
        <div className={[
            'media-wrapper',
            type === 'audio' ? 'd-none' : 'react-player'
        ].join(' ')}>
            <ReactPlayer
                playsinline
                config={{
                    file: {
                        attributes: {
                            // not sure if camelcase or not so i did both
                            // disableremoteplayback: true,
                            disableRemotePlayback: true
                        }
                    }
                }}
                ref={(e) => {
                    // set if it doesn't exist.
                    if (!(playerRef)) {
                        // set react-player to useRef
                        // console.log('Setting new audio player ref.')

                        playerRef = {
                            key: mapKey,
                            player: e
                        }
                    }
                }}
                // hide the component only because a default width and height is assigned.
                className={type === 'audio' ? 'd-none' : 'react-player'}
                url={_.isString(buildingBlock.buildingBlockValue)
                    ? buildingBlock.buildingBlockValue
                    : ''}
                // no more controls for any media type.
                controls={false}
                playing={playerState?.data.playing}
                loop={playerState?.data.looped}
                volume={playerState?.data.volume}
                muted={playerState?.data.muted}
                width={'100%'}
                height={'100%'}
                // onReady={() => console.log('onReady')}
                // onStart={() => console.log('onStart')}
                onPlay={handlePlay}
                // onEnablePIP={this.handleEnablePIP}
                // onDisablePIP={this.handleDisablePIP}
                onPause={handlePause}
                // onBuffer={() => console.log('onBuffer')}
                // onPlaybackRateChange={this.handleOnPlaybackRateChange}
                // onSeek={e => console.log('onSeek', e)}
                onEnded={handleVideoEnd}
                // onError={e => console.log('onError', e)}
                onProgress={handleProgress}
                onDuration={handleDuration}
            />
        </div>
        <div className={`media-wrapper pt-0 ${ type } shadow`}>
            {
                <div className={'player'}>
                    <input
                        type={'range'}
                        min={SEEKER.MIN}
                        max={SEEKER.MAX}
                        step={'any'}
                        value={playerState?.data.played || 0.00}
                        onMouseDown={handleSeekMouseDown}
                        onChange={handleSeekChange}
                        onMouseUp={handleSeekMouseUp}
                        ref={(e) => {
                            seekerInputRef.current = e
                        }}
                        style={{
                            backgroundSize: backgroundSize as any
                        }}

                    />
                    <div className={'align-items-center row justify-content-between g-0'}>
                        <div className={
                            'col-auto play-button'
                        } onClick={() => {
                            dispatch(setPlaying({
                                key: mapKey,
                                playing: !playerState?.data.playing
                            }))
                        }}>
                            {playerState?.data.playing
                                ? <i className={'fa-light fa-pause'} aria-hidden={'true'} />
                                : <i className={'fa-light fa-play'} aria-hidden={'true'} />
                            }
                        </div>
                        <div className={`col d-flex gap-2
                        justify-content-end`}>
                            <div className={'align-items-center row g-1'}>
                                <div className={'col-auto volume-button'} onClick={() => {
                                    dispatch(setMuted({
                                        key: mapKey,
                                        muted: !playerState?.data.muted
                                    }))
                                }}>
                                    {/* if muted, change icon */}
                                    {
                                        playerState?.data.muted
                                            ? <i className={'fa-light fa-volume-xmark'}
                                                aria-hidden={'true'} />
                                            : <i className={'fa-light fa-volume-high'}
                                                aria-hidden={'true'} />
                                    }
                                </div>
                                <div className={'col-auto'}>
                                    <input
                                        className={'volume-control'}
                                        type={'range'}
                                        min={VOLUME.MIN}
                                        max={VOLUME.MAX}
                                        step={'any'}
                                        value={playerState?.data.volume || 0.00}
                                        onChange={handleVolumeChange}
                                        ref={(e) => {
                                            volumeInputRef.current = e
                                        }}
                                    />
                                </div>
                            </div>
                            {type === 'video' && (<div className={
                                'fullscreen-button'
                            } onClick={() => {
                                if (screenfull.isEnabled &&
                                    playerRef?.player
                                ) {
                                    const playerElement = playerRef.player.getInternalPlayer()
                                    // console.log('player element: ', playerElement)
                                    /** dom node is found there. */
                                    screenfull.toggle(playerElement.g)
                                }
                            }}>
                                <i className={'fa-light fa-expand-wide'}
                                    aria-hidden={'true'} />
                            </div>)}

                        </div>
                    </div>
                    {/* <div className={'align-items-center justify-content-center row'}> */}
                    {/* loop button. add box-shadow effect when true. */}
                    {/* <div className={
                            `icon col-auto ${ playerState?.data.looped ? 'active' : '' }`
                        } onClick={() => {
                            dispatch(setLooped({
                                key: mapKey,
                                looped: !playerState?.data.looped
                            }))
                        }}>
                            <ImLoop />
                        </div> */}
                    {/* stop button. reset to zero AND pauses the player. */}
                    {/* <div className={
                            'icon col-auto'
                        } onClick={handleStop}>
                            <ImStop2 />
                        </div> */}
                    {/* seek 10 seconds backwards on click only */}
                    {/* <div className={
                            'icon col-auto'
                        } onClick={(e) => {
                            if (playerRef) {
                                // updateSeekerProgress()
                                // this value is in 0 - 0.999999
                                const currentSeekValue = playerState?.data.played || 0
                                // new seek value reduce by 5 seconds.
                                const limit = (SEEKER.VALUE + SEEKER.MIN) / (100 + SEEKER.MAX)
                                const newSeekValue = Math.max(
                                    currentSeekValue - limit, 0
                                )
                                if (newSeekValue <= (
                                    buildingBlockProgress.activeProgress / 100
                                ) + limit) {
                                    playerRef.player?.seekTo(newSeekValue)
                                    dispatch(setPlayed({
                                        key: mapKey,
                                        played: newSeekValue
                                    }))
                                } else {
                                    console.error('back seek exceeded. button aborted.')
                                }
                            }
                        }}>
                            <AiOutlineBackward />
                        </div> */}
                    {/* play button */}

                    {/* seek 10 seconds forwards on click only */}
                    {/* <div className={
                            'icon col-auto'
                        } onClick={(e) => {
                            if (playerRef) {
                                // updateSeekerProgress()
                                // this value is in 0 - 0.999999
                                const currentSeekValue = playerState?.data.played || 0
                                const duration = playerRef.player?.getDuration() || 1

                                // new seek value increase by 5 seconds.
                                const limit = (SEEKER.VALUE + SEEKER.MIN) / (100 + SEEKER.MAX)
                                const newSeekValue = Math.min(
                                    currentSeekValue + limit, duration
                                )
                                if (newSeekValue <= (
                                    buildingBlockProgress.activeProgress / 100
                                ) + limit) {
                                    playerRef.player?.seekTo(newSeekValue)
                                    dispatch(setPlayed({
                                        key: mapKey,
                                        played: newSeekValue
                                    }))
                                } else {
                                    console.error('forward seek exceeded. button aborted.')
                                }
                            }
                        }}>
                            <AiOutlineForward />
                        </div> */}
                    {/* volume adjuster */}

                    {/* a fullscreen button */}
                </div>
                // </div>
            }
        </div>
    </div>
}

export default Player
