import { MODULE_TABLE } from '@app/app.config'

import _ from 'lodash'

import { SystemModule } from '@login/type'
import React from 'react'

import FacilitiesMain from '@fmt/components/facilities/FacilitiesMain'
import DepartmentsMain from '@fmt/components/departments/DepartmentsMain'
import CareprofessionalsMain from '@fmt/components/careprofessionals/CareprofessionalsMain'
import TreatmentsMain from '@fmt/components/treatments/TreatmentsMain'
import CarepathsMain from '@fmt/components/carepaths/CarepathsMain'
import FacilityDetailsInterface from '@fmt/components/facilities/details/FacilityDetailsInterface'
import DepartmentDetailsInterface from
    '@fmt/components/departments/details/DepartmentDetailsInterface'
import CareprofessionalDetailsInterface from
    '@fmt/components/careprofessionals/details/CareprofessionalDetailsInterface'
import TreatmentDetailsInterface from
    '@fmt/components/treatments/details/TreatmentDetailsInterface'
import CarepathDetailsInterface from '@fmt/components/carepaths/details/CarepathDetailsInterface'
import CarepathAddInterface from '@fmt/components/carepaths/add/CarepathAddInterface'
import CareprofessionalAddInterface from
    '@fmt/components/careprofessionals/add/CareprofessionalAddInterface'
import DepartmentAddInterface from '@fmt/components/departments/add/DepartmentAddInterface'
import TreatmentAddInterface from '@fmt/components/treatments/add/TreatmentAddInterface'
interface ComponentProps {
    config: SystemModule[]
}
const selectedModule = MODULE_TABLE.fmt
/** The final array of route configurations with their corresponding components */
const Routes = ({ config }: ComponentProps) => {
    const arr: { moduleName: string, path: string,
            element: React.ReactNode
        }[] = []

    const found = _.find(config, (o) => {
        return o.moduleName === selectedModule.moduleName
    })

    if (found) {
        _.forEach(_.cloneDeep(found.feRoutes), (o) => {
            let element: React.ReactNode | undefined

            // a new variable named route to pass in so we can cater to routes
            // with parameters on them.
            let routeName = ''
            switch (o.route) {
                // add is also included here. extra functionality
                // is included in the component itself.
                // NOTE: the add modal shouldn't be unmounted at all.
                case selectedModule.routes.careFacilities:
                case selectedModule.routes.createCareFacility: {
                    element = <FacilitiesMain/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.viewCareFacility:
                case selectedModule.routes.editCareFacility: {
                    element = <FacilityDetailsInterface />
                    routeName = o.route
                    break
                }

                case selectedModule.routes.departments: {
                    element = <DepartmentsMain/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.createDepartment: {
                    element = <DepartmentAddInterface/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.viewDepartment:
                case selectedModule.routes.editDepartment: {
                    element = <DepartmentDetailsInterface />
                    routeName = o.route
                    break
                }

                case selectedModule.routes.careprofessionals: {
                    element = <CareprofessionalsMain/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.createCareprofessional: {
                    element = <CareprofessionalAddInterface/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.viewCareprofessional:
                case selectedModule.routes.editCareprofessional: {
                    element = <CareprofessionalDetailsInterface />
                    routeName = o.route
                    break
                }

                case selectedModule.routes.treatments:{
                    element = <TreatmentsMain/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.createTreatment: {
                    element = <TreatmentAddInterface/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.viewTreatment:
                case selectedModule.routes.editTreatment: {
                    element = <TreatmentDetailsInterface />
                    routeName = o.route
                    break
                }

                case selectedModule.routes.carepaths: {
                    element = <CarepathsMain/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.createCarepath: {
                    element = <CarepathAddInterface/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.viewCarepath:
                case selectedModule.routes.editCarepath: {
                    element = <CarepathDetailsInterface />
                    routeName = o.route
                    break
                }

                default:
                    break
            }

            element && arr.push({
                moduleName: found.moduleName,
                path: routeName,
                element
            })
        })
    }

    return arr
}

export default Routes
