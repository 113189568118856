import { MODULE_TABLE } from '@app/app.config'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import {
    IDS,
    MODULE_VERSION,
    REVIEW_AGAIN_FORMIK_INITIAL_VALUES,
    REVIEW_AGAIN_VALIDATION_SCHEMA
} from '@registration/constants'
import { ReviewAgainKeys } from '@registration/type'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
// for react-datepicker locale. requires date-fns.

import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import {
    selectCurrentWorkflowStep,
    selectWorkflowCount,
    selectWorkflowId
} from '@login/slices/slice.workflow'
import {
    useGetPersonalDetailsMutation,
    useUpdatePersonalDetailsMutation,
    useUpdateWorkflowProgressMutation
} from '@registration/api'
import _ from 'lodash'
import { toast } from 'react-toastify'

// for react-datepicker locale. requires date-fns.
import FixedImage from '@app/components/FixedImage'
import { TokenData } from '@app/types/type.token'
import UpdateWorkflowProgressConsumer from '@login/workflow/UpdateWorkflowConsumer'

import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/registration/Components'

import { useMediaQuery } from 'react-responsive'
import { useDebouncedCallback } from 'use-debounce'

function ReviewSection () {
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const workflowId = useAppSelector(selectWorkflowId)
    const workflowCount = useAppSelector(selectWorkflowCount)

    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const [getPersonalDetails, getPersonalDetailsMutation] = useGetPersonalDetailsMutation()
    const [
        updatePersonalDetails,
        updatePersonalDetailsMutation
    ] = useUpdatePersonalDetailsMutation()

    const [
        updateWorkflowProgress,
        updateWorkflowProgressMutation
    ] = useUpdateWorkflowProgressMutation()

    const [reviewAgain, setReviewAgain] = useState(false)

    const {
        handleSubmit, handleChange, values, errors, setValues
    } = useFormik({
        initialValues: REVIEW_AGAIN_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: REVIEW_AGAIN_VALIDATION_SCHEMA(
            strings.app?.message.error.email || '',
            strings.app?.message.error.empty || ''
        ),
        onSubmit: useDebouncedCallback((values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken({
                        value: token.value,
                        id: token.id
                    }, token.mode)
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.updatePersonalDetails.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        updatePersonalDetails({
                            authToken: newToken.value,
                            data: {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                // dob: values.dateOfBirth,
                                email: values.email
                            }
                        })
                    }
                }
            }
            call()
        }, 1000)
    })

    const unsubscribeGetPersonalDetails = () => {
        const unsubscribeMutation = getPersonalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    useEffect(() => {
        if (getPersonalDetailsMutation.error) {
            const message = getErrorText(getPersonalDetailsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPersonalDetailsMutation.error])

    useEffect(() => {
        const data = getPersonalDetailsMutation.data
        if (data) {
            setValues({
                // dateOfBirth: data.data.dob || getUnixTime(new Date()),
                email: data.data.email,
                firstName: data.data.firstName,
                lastName: data.data.lastName
            })
        }
    }, [getPersonalDetailsMutation.data])

    useEffect(() => {
        if (updatePersonalDetailsMutation.error) {
            const message = getErrorText(updatePersonalDetailsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updatePersonalDetailsMutation.error])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPersonalDetails()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.getPersonalDetails.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (foundApiPath && newToken.value) {
                        promise = getPersonalDetails({
                            authToken: newToken.value
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.registration
                                    .apiPaths.getPersonalDetails.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    useEffect(() => {
        const data = updatePersonalDetailsMutation.data

        const call = async () => {
            if (data?.status === 'OK') {
                setReviewAgain(false)
                fetchData(token)
            }
        }
        call()
    }, [updatePersonalDetailsMutation.data, activeModules.id])

    useEffect(() => {
        return fetchData(token)
    }, [activeModules.id])

    const NoButton = useMemo(() => {
        return (<a
            onClick={(e) => {
                // if no, show ReviewAgain.tsx.
                e.preventDefault()
                setReviewAgain(true)
            }}
            className={'btn btn-secondary btn-lg w-100'}>
            <span style={{ verticalAlign: 'inherit' }}>
                <span style={{ verticalAlign: 'inherit' }}>
                    {strings.app?.text.no}
                </span>
            </span>
        </a>)
    }, [strings])

    const YesButton = useMemo(() => {
        const buttonContent = updateWorkflowProgressMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
        // if it's not the last step, use "next", else "complete"
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            currentWorkflowStep && currentWorkflowStep.stepSequence < workflowCount
                                ? strings.app?.text.yes
                                : strings.app?.text.complete
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        const buttonClassName = `btn btn-primary btn-lg w-100 
            ${ !currentWorkflowStep || updateWorkflowProgressMutation.isLoading
            ? 'disabled'
            : '' }`.trim()

        return <a
            className={buttonClassName}
            onClick={(e) => {
                e.preventDefault()
                const call = async () => {
                    if (currentWorkflowStep) {
                        if (token.valid) {
                            const newToken = await revalidateToken({
                                value: token.value,
                                id: token.id
                            }, token.mode)
                            const percentComplete = 100
                            const isValid = validateAPIPath(
                                activeModules.arr,
                                MODULE_TABLE.registration.moduleName,
                                MODULE_TABLE.registration.apiPaths.updateWorkflowProgress.path,
                                true
                            )

                            if (isValid && newToken.value) {
                                updateWorkflowProgress({
                                    authToken: newToken.value,
                                    data: {
                                        stepId: currentWorkflowStep.stepId,
                                        workflowId,
                                        percentComplete
                                    }
                                })
                            }
                        }
                    }
                }
                call()
            }}
        >
            {buttonContent}
        </a>
    }, [strings, currentWorkflowStep, updateWorkflowProgressMutation.isLoading, workflowCount])

    const stepSequenceCells = useMemo(() => {
        const items = []

        for (let i = 0; i < workflowCount; i++) {
            items.push(
                <li key={i} onClick={(e) => {
                    e.preventDefault()
                }} className={'page-item'}>
                    <a className={`page-link clickable ${
                    i === ((currentWorkflowStep?.stepSequence || 0) - 1) ? 'active' : ''
                }`}></a>
                </li>
            )
        }

        return (
            <ul className={'pagination justify-content-center'}>
                {items}
            </ul>
        )
    }, [currentWorkflowStep, workflowCount])

    /** components from ReviewAgainForm */
    const ReviewAgainSubmitButton = useMemo(() => {
        const buttonContent = updatePersonalDetailsMutation.isLoading ||
        updateWorkflowProgressMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            strings.app?.text.next
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        return (<button type={'submit'}
            disabled={updatePersonalDetailsMutation.isLoading ||
                updateWorkflowProgressMutation.isLoading}
            form={IDS.REVIEW_AGAIN.FORM} className={'btn btn-primary btn-lg w-100'}>
            {buttonContent}
        </button>)
    }, [strings, updatePersonalDetailsMutation])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: ReviewAgainKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                handleSubmit()
            }
        }
    }

    const FirstNameInput = useMemo(() => {
        const fieldName: ReviewAgainKeys = 'firstName'

        return <div className={'form-group  mb-4'}>
            <label htmlFor={IDS.REVIEW_AGAIN.FIRST_NAME} className={'form-label'}>{
            `${ strings.registration?.text.review.first_name || '' } `
            }</label>
            <input type={'text'}
                value={values.firstName}
                className={`form-control ${ errors.firstName && 'border-danger' }`}
                name={fieldName}
                placeholder={strings.registration?.text?.review.first_name || ''}
                id={IDS.REVIEW_AGAIN.FIRST_NAME}
                onChange={handleChange}
                onKeyDown={(e) => {
                    handleKeyDown(e, 'lastName')
                }}
                required />
            <div className={'form-text error'}>{
                errors.firstName
                    ? errors.firstName
                    : null
            }</div>
        </div>
    }, [strings, values.firstName, errors.firstName])

    const LastNameInput = useMemo(() => {
        const fieldName: ReviewAgainKeys = 'lastName'

        return <div className={'form-group  mb-4'}>
            <label htmlFor={IDS.REVIEW_AGAIN.LAST_NAME} className={'form-label'}>{
            `${ strings.registration?.text.review.last_name || '' } `
            }</label>
            <input type={'text'}
                value={values.lastName}
                className={`form-control ${ errors.lastName && 'border-danger' }`}
                name={fieldName}
                placeholder={strings.registration?.text?.review.last_name || ''}
                id={IDS.REVIEW_AGAIN.LAST_NAME}
                onChange={handleChange}
                onKeyDown={(e) => {
                    handleKeyDown(e, 'email')
                }}
                required />
            <div className={'form-text error'}>{
                errors.lastName
                    ? errors.lastName
                    : null
            }</div>
        </div>
    }, [strings, values.lastName, errors.lastName])

    const EmailInput = useMemo(() => {
        const fieldName: ReviewAgainKeys = 'email'

        return <div className={'form-group  mb-4'}>
            <label htmlFor={IDS.REVIEW_AGAIN.EMAIL} className={'form-label'}>{
            `${ strings.registration?.text.review.email || '' } `
            }</label>
            <input type={'email'}
                value={values.email}
                className={`form-control ${ errors.email && 'border-danger' }`}
                name={fieldName}
                placeholder={strings.registration?.text?.review.email || ''}
                id={IDS.REVIEW_AGAIN.EMAIL}
                onChange={handleChange}
                required />
            <div className={'form-text error'}>{
                errors.email
                    ? errors.email
                    : null
            }</div>
        </div>
    }, [strings, values.email, errors.email])

    // const DatePickerInput = useMemo(() => {
    //     const fieldName: ReviewAgainKeys = 'dateOfBirth'

    //     return (<div className={'form-group  mb-4'}>
    //         <label htmlFor={IDS.REVIEW_AGAIN.DATE_OF_BIRTH} className={'form-label'}>{
    //     `${ strings.registration?.text.review.date_of_birth || '' } `
    //         }</label>
    //         <NewDatePicker
    //             id={IDS.REVIEW_AGAIN.DATE_OF_BIRTH}
    //             errors={errors.dateOfBirth}
    //             isDate={(date) => {
    //                 setFieldValue(fieldName, getUnixTime(date))
    //             }}
    //             singleDate={fromUnixTime(values.dateOfBirth)}
    //             dateType={''}
    //             timeFormat={dateFormats.format5}
    //         />
    //         <div className={'form-text error'}>{
    //             errors.dateOfBirth
    //                 ? errors.dateOfBirth
    //                 : null
    //         }</div>
    //     </div>)
    // }, [strings, values.dateOfBirth, errors.dateOfBirth])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        {/* put header image here */}
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-20'}>

                {
                    reviewAgain
                        ? <>
                            <div className={'justify-content-center row'}>
                                <div className={'col-10 px-0'}>
                                    {/* status card */}
                                    <div className={'status-card card border-0'}>
                                        <h4>
                                            <p className={'mt-2'}>
                                                {strings.login?.message.hello}
                                            </p>
                                        </h4>
                                        <span>
                                            {strings.registration?.text.title}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={'row form-container'}>
                                <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                                    {/* content goes here */}

                                    <form id={IDS.REVIEW_AGAIN.FORM} onSubmit={handleSubmit}>
                                        {FirstNameInput}
                                        {LastNameInput}
                                        {EmailInput}
                                        {/* {DatePickerInput} */}
                                    </form>

                                    <div className={[
                                        'row align-items-center justify-content-center mb-5'
                                    ].join(' ')}>
                                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5 '}>
                                            {ReviewAgainSubmitButton}
                                        </div>
                                    </div>
                                    <div className={[
                                        'row align-items-center justify-content-center mb-5'
                                    ].join(' ')}>
                                        <div className={'col-auto'}>
                                            {stepSequenceCells}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <>
                            <div className={'justify-content-center row'}>
                                <div className={'col-10 px-0'}>
                                    {/* status card */}
                                    <div className={'status-card card border-0'}>
                                        <h4>
                                            <p className={'mt-2'}>
                                                {strings.login?.message.hello}
                                            </p>
                                        </h4>
                                        <span>
                                            {strings.registration?.text.review.title}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={'row form-container'}>
                                <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                                    {/* content goes here */}

                                    <div className={'mb-5'}>
                                        <b className={'form-label'}>
                                            {strings.registration?.text.review.first_name}
                                        </b>
                                        <p>
                                            {getPersonalDetailsMutation.data?.data.firstName || ''}
                                        </p>
                                        <b className={'form-label'}>
                                            {strings.registration?.text.review.last_name}
                                        </b>
                                        <p>
                                            {getPersonalDetailsMutation.data?.data.lastName || ''}
                                        </p>
                                        <b className={'form-label'}>
                                            {strings.registration?.text.review.email}
                                        </b>
                                        <p>
                                            {getPersonalDetailsMutation.data?.data.email || ''}
                                        </p>
                                    </div>

                                    <div
                                        className={[
                                            'row align-items-center justify-content-center mb-5'
                                        ].join(' ')}
                                    >
                                        <div className={'col-6 col-md-5 col-lg-5'}>
                                            {NoButton}
                                        </div>
                                        <div className={'col-6 col-md-5 col-lg-5'}>
                                            {YesButton}
                                        </div>
                                    </div>
                                    <div
                                        className={[
                                            'row align-items-center justify-content-center'
                                        ].join(' ')}
                                    >
                                        <div className={'col-auto'}>
                                            {stepSequenceCells}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }

            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>
    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>

            {
                reviewAgain
                    ? <>

                        <div className={'justify-content-center row'}>
                            <div className={'col-12'}>
                                {/* status card */}
                                <div className={'status-card card border-0'}>
                                    <span className={'fw-semibold mb-2'}>
                                        {strings.login?.message.hello}
                                    </span>
                                    <span>
                                        {strings.registration?.text.title}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={'form-container'}>
                            <div>
                                <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                                    <form id={IDS.REVIEW_AGAIN.FORM} onSubmit={handleSubmit}>
                                        {FirstNameInput}
                                        {LastNameInput}
                                        {EmailInput}
                                        {/* {DatePickerInput} */}
                                    </form>
                                </div>
                            </div>
                            <div >
                                <div className={[
                                    'row align-items-center justify-content-center mb-5'
                                ].join(' ')}>
                                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5 '}>
                                        {ReviewAgainSubmitButton}
                                    </div>
                                </div>
                                <div className={[
                                    'row align-items-center justify-content-center'
                                ].join(' ')}>
                                    <div className={'col-auto'}>
                                        {stepSequenceCells}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                    : <>
                        <div className={'justify-content-center row'}>
                            <div className={'col-12'}>
                                {/* status card */}
                                <div className={'status-card card border-0'}>
                                    <span className={'fw-semibold mb-2'}>
                                        {strings.login?.message.hello}
                                    </span>
                                    <span>
                                        {strings.registration?.text.review.title}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={'form-container'}>
                            <div >
                                <div className={'row'}>
                                    <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                                        <div className={'mb-5'}>
                                            <b className={'form-label'}>
                                                {strings.registration?.text.review.first_name}
                                            </b>
                                            <p>
                                                {getPersonalDetailsMutation.data
                                                    ?.data.firstName || ''}
                                            </p>
                                            <b className={'form-label'}>
                                                {strings.registration?.text.review.last_name}
                                            </b>
                                            <p>
                                                {getPersonalDetailsMutation.data
                                                    ?.data.lastName || ''}
                                            </p>
                                            <b className={'form-label'}>
                                                {strings.registration?.text.review.email}
                                            </b>
                                            <p>
                                                {getPersonalDetailsMutation.data?.data.email || ''}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <div
                                    className={[
                                        'row align-items-center justify-content-center mb-5 g-3'
                                    ].join(' ')}
                                >
                                    <div className={'col-12 col-sm-6 col-md-5 col-lg-5'}>
                                        {NoButton}
                                    </div>
                                    <div className={'col-12 col-sm-6 col-md-5 col-lg-5'}>
                                        {YesButton}
                                    </div>
                                </div>
                                <div
                                    className={[
                                        'row align-items-center justify-content-center'
                                    ].join(' ')}
                                >
                                    <div className={'col-auto'}>
                                        {stepSequenceCells}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </div>
    </>

    return (<div>
        <div className={'registration-page'}>

            <UpdateWorkflowProgressConsumer
                data={updateWorkflowProgressMutation.data}
                originalArgs={updateWorkflowProgressMutation.originalArgs}
            />

            {
                isMobile ? mobileResult : desktopResult
            }
        </div>
    </div>)
}

export default ReviewSection
