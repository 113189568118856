import { OpenListInputs } from '@reasonWithMe/type'
import _ from 'lodash'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
}

const InputList = ({
    answerType, outerIndex, innerIndex, formInput
}: ComponentProps) => {
    const inputArr = formInput
        .userTypedAnswer as string[]

    const inputList = _.map(inputArr, (inputString,
        inputIndex) => {
        const isDisabled = !formInput.answerEditable

        return <li key={[
            'input-cell', outerIndex, innerIndex, inputIndex
        ].join('-')}
        className={'align-middle text-center mb-4'}>
            <div className={'row align-items-center'}>
                <div className={'col'}>
                    <input
                        className={'form-control form-control-sm'}
                        type={'text'}
                        value={inputString}
                        disabled={isDisabled}
                        onChange={(e) => {
                        }}
                        onBlur={(e) => {
                        }}
                    />

                </div>
            </div>

        </li>
    })

    return <ol type={'a'} className={'mb-0'}>
        {
            inputList
        }
    </ol>
}

export default InputList
