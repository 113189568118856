/* eslint-disable max-len */
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { css, useTheme } from '@emotion/react'

const GlobalStyle = () => {
    const theme = useTheme()

    return css`
        .login-page, .forget-password-page {
            & .main-content {

                & .status-card {
                    color: ${ theme.config.endUser?.login.mainContent.statusCard.color };
                    background-color: ${
                    theme.config.endUser?.login.mainContent.statusCard.background };
                }

                & .btn-primary.sso-microsoft {
                    border-radius: 5px;
                    --bs-btn-color: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.color };
                    --bs-btn-bg: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.bg };
                    --bs-btn-border-color: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.borderColor };
                    --bs-btn-hover-color: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.hoverColor };
                    --bs-btn-hover-bg: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.hoverBg };
                    --bs-btn-hover-border-color: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.hoverBorderColor };
                    --bs-btn-active-color: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.activeColor };
                    --bs-btn-active-bg: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.activeBg };
                    --bs-btn-active-border-color: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.activeBorderColor };
                    --bs-btn-disabled-bg: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.disabledBg };
                    --bs-btn-disabled-border-color: ${ theme.config.endUser?.login.mainContent.btnPrimary.sso.microsoft.disabledBorderColor };
                }
            }

            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    //padding-top is better than margin-top. if you do margin top.
                    //any components above will also go down.
                    padding-top: 240px;

                    & .form-container {
                        padding-block-start: 7vh;
                    }

                    & > .container, & > .container-fluid  {
                        padding-block-end: 11vh;
                    }

                    & .status-card {
                        padding: 40px 12%;

                        position: relative;
                        top: -27%;
                        margin-bottom: -25px;
                    }
                }
            }



            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {

                & .header {
                    height: 58px;

                    & img {
                        width: 98px;
                    }
                }

                
                & .main-content {

                    padding-top: 113px;
                    position: absolute;
                    //100% minus the height of the previous divs. 100% means height of viewport
                    //in this case
                                    /* height: calc(100% - 113px - 58px); */
                    height: calc(100% - 113px);



                    & .form-container {
                        display: flex;
                        padding-block-start: 5vh;
                        flex-direction: column;
                        height: 100%;

                        & > div:nth-of-type(1) {
                            flex: 1;
                        }

                        & > div:nth-of-type(2) {
                            flex: 0;
                        }

                    }

                    & .status-card {
                        padding: 12px 4%;
                        
                        position: relative;
                        top: -27%;
                        margin-bottom: -25px;
                    }

                }


            }

        }
    `
}

export default GlobalStyle
