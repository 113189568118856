import { useCallback, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts'

interface Size {
  width: number;
  height: number;
}

interface Options {
  debounce?: number;
}

/**
 * A hook for tracking the size of a DOM element with optional debounce.
 * @template T - The type of the DOM element. Defaults to `HTMLDivElement`.
 * @param {Options} [options] - Options for debounce.
 * @param {number} [options.debounce=100] - Debounce time in milliseconds. Defaults to 100.
 * @returns {[ (node: T | null) => void, Size ]}
 *  A tuple containing a ref-setting function and the size of the element.
 */
export function useElementSize<T extends HTMLElement = HTMLDivElement> (
    options: Options = { debounce: 100 }
): [(node: T | null) => void, Size] {
    const { debounce: debounceTime } = options
    const [ref, setRef] = useState<T | null>(null)
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0
    })

    function handleSize () {
        setSize({
            width: ref?.offsetWidth || 0,
            height: ref?.offsetHeight || 0
        })
    }

    // Debounce the handleSize function
    const debouncedHandleSize = useDebouncedCallback(handleSize, debounceTime)

    // Prevent too many renderings using useCallback
    const handleSizeCallback = useCallback(() => {
        debouncedHandleSize()
    }, [debouncedHandleSize])

    useEventListener('resize', handleSizeCallback)

    useIsomorphicLayoutEffect(() => {
        handleSize()
    }, [ref?.offsetHeight, ref?.offsetWidth])

    return [setRef, size]
}
