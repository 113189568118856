import {
    ANSWER_SUMMARY_CONFIG
} from '@stylesheet/brands/rtw/admin/themes/light/AnswerSummaryConfig'
import { BOOTSTRAP_CONFIG } from '@stylesheet/brands/rtw/admin/themes/light/BootstrapConfig'
import {
    CAREPROFESSIONAL_DASHBOARD_CONFIG
} from '@stylesheet/brands/rtw/admin/themes/light/CareprofessionalDashboardConfig'
import {
    FACILITY_MANAGEMENT_CONFIG
} from '@stylesheet/brands/rtw/admin/themes/light/FacilityManagementConfig'
import { GLOBAL_CONFIG } from '@stylesheet/brands/rtw/admin/themes/light/GlobalConfig'
import { ThemeConfig } from '@stylesheet/config/ThemeConfig'

export const THEME_CONFIG: ThemeConfig = {
    global: GLOBAL_CONFIG,
    bootstrap: BOOTSTRAP_CONFIG,
    admin: {
        answerSummary: ANSWER_SUMMARY_CONFIG,
        careprofDashboard: CAREPROFESSIONAL_DASHBOARD_CONFIG,
        fmt: FACILITY_MANAGEMENT_CONFIG
    }
}
