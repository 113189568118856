import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import { CAREPROF_TABLE_LIMIT } from '@doc/constants'
import {
    AddPatientRequest,
    AddPatientResponse,
    EditPatientRequest,
    EditPatientResponse,
    GetDepartmentCareFacilitiesRequest,
    GetDepartmentCareFacilitiesResponse,
    GetHCPCardsRequest,
    GetHCPCardsResponse,
    GetOverallAbnormalitiesRequest,
    GetOverallAbnormalitiesResponse,
    GetPatientActivityReasoningRequest,
    GetPatientActivityReasoningResponse,
    GetPatientActivityRequest,
    GetPatientActivityResponse,
    GetPatientAdvisoryRequest,
    GetPatientAdvisoryResponse,
    GetPatientChartDataRequest,
    GetPatientChartDataResponse,
    GetPatientChartsRequest,
    GetPatientChartsResponse,
    GetPatientDeviationsRequest,
    GetPatientDeviationsResponse,
    GetPatientRequest,
    GetPatientResponse,
    GetPatientsRequest,
    GetPatientsResponse,
    GetPatientsUpcomingSurgeryRequest,
    GetPatientsUpcomingSurgeryResponse,
    GetPatientTherapeuticsRequest,
    GetPatientTherapeuticsResponse,
    GetTreatedPatientsRequest,
    GetTreatedPatientsResponse,
    GetTreatmentRequest,
    GetTreatmentResponse,
    GetUserTreatmentsRequest,
    GetUserTreatmentsResponse,
    GPACareplanStepId,
    GPACareplanStepIdDetails,
    MakeCareplanRequest,
    MakeCareplanResponse,
    PatientProgressDataRequest,
    PatientProgressResponseData,
    ResendInviteRequest,
    ResendInviteResponse,
    UpdatePatientTreatmentRequest,
    UpdatePatientTreatmentResponse,
    UpdateProfilePicturePatientRequest,
    UpdateProfilePicturePatientResponse
} from '@doc/type'
import { DeleteCareplanRequest, DeleteCareplanResponse } from '@profile/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'
export const docV10Api = createApi({
    reducerPath: 'docV10Api',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        resendInvite: builder
            .mutation<ResendInviteResponse, ResendInviteRequest & TokenAuth>({
                query: (body) => {
                    const formData: ResendInviteRequest = {
                        data: body.data
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.doc.apiPaths
                                .resendInvite.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getTreatment: builder
            .mutation<GetTreatmentResponse, GetTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetTreatmentRequest = {
                        data: body.data
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.doc.apiPaths
                                .getTreatment.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatients: builder
            .mutation<GetPatientsResponse, GetPatientsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetPatientsRequest['data']
                        } =
                        {
                            data: {
                                skip: body.data.skip,
                                limit: body.data.limit,
                                search: body.data.search
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatients.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatient: builder
            .mutation<GetPatientResponse, GetPatientRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetPatientRequest['data']
                        } =
                        {
                            data: {
                                userId: body.data.userId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientId.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatientActivity: builder
            .mutation<GetPatientActivityResponse, GetPatientActivityRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetPatientActivityRequest['data']
                        } =
                        {
                            data: {
                                userId: body.data.userId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientActivity.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        gPACareplanStepId: builder
            .mutation<GPACareplanStepId['response'],
            GPACareplanStepId['request'] & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GPACareplanStepId['request']['data']
                        } =
                        {
                            data: {
                                userId: body.data.userId
                            }
                        }

                    const removeBrackets = (str: string) => {
                        const pattern = /<[^>]+>/g
                        return _.replace(str, pattern, '')
                    }

                    const url = [
                        _.trim(
                            removeBrackets(
                                MODULE_TABLE.doc
                                    .apiPaths.gPACareplanStepId.path
                            ), '/'
                        ),
                        body.urlParams.careplanStepId ? '/' + body.urlParams.careplanStepId : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        gPACareplanStepIdDetails: builder
            .mutation<GPACareplanStepIdDetails['response']['content'] |
            GPACareplanStepIdDetails['response']['reasoningData'] |
            GPACareplanStepIdDetails['response']['reasoningSessions'],
            GPACareplanStepIdDetails['request'] & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GPACareplanStepIdDetails['request']['data']
                        } =
                        {
                            data: {
                                userId: body.data.userId,
                                type: body.data.type,
                                ...(body.data
                                    .questionId && {
                                    questionId: body.data
                                        .questionId
                                }),
                                ...(body.data
                                    .reasoningSessionId && {
                                    reasoningSessionId: body.data
                                        .reasoningSessionId
                                }),
                                ...(body.data
                                    .reasoningSetId && {
                                    reasoningSetId: body.data
                                        .reasoningSetId
                                }),
                                ...(body.data.skip && { skip: body.data.skip }),
                                ...(body.data.limit && { limit: body.data.limit })

                            }
                        }

                    const removeBrackets = (str: string) => {
                        const pattern = /<[^>]+>/g
                        return _.replace(str, pattern, '')
                    }

                    const url = [
                        _.trim(
                            removeBrackets(
                                MODULE_TABLE.doc
                                    .apiPaths.gPACareplanStepIdDetails.path
                            ), '/'
                        ),
                        body.urlParams.careplanStepId ? '/' + body.urlParams.careplanStepId : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatientActivityReasoning: builder
            .mutation<GetPatientActivityReasoningResponse,
             GetPatientActivityReasoningRequest & TokenAuth>({
                 query: (body) => {
                     const formData: {
                            data: GetPatientActivityReasoningRequest['data']
                        } =
                        {
                            data: {
                                userId: body.data.userId,
                                careplanStepId: body.data.careplanStepId
                            }
                        }

                     const url = [
                         _.trimStart(
                             MODULE_TABLE.doc
                                 .apiPaths.getPatientActivityReasoning.path, '/'
                         )
                     ].join('')

                     return {
                         url,
                         method: 'POST',
                         headers: {
                             Authorization: `Bearer ${ body.authToken }`,
                             'Content-Type': 'application/json'
                         },
                         body: formData
                     }
                 }
             }),
        getPatientCharts: builder
            .mutation<GetPatientChartsResponse, GetPatientChartsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetPatientChartsRequest['data']
                        } =
                        {
                            data: {
                                userId: body.data.userId,
                                parentId: body.data.parentId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientCharts.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatientChartData: builder
            .mutation<GetPatientChartDataResponse, GetPatientChartDataRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetPatientChartDataRequest['data']
                        } =
                        {
                            data: {
                                graphId: body.data.graphId,
                                userId: body.data.userId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientChartData.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatientProgress: builder
            .mutation<PatientProgressResponseData, PatientProgressDataRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: PatientProgressDataRequest['data']
                        } =
                        {
                            data: {
                                dateFrom: body.data.dateFrom,
                                dateTo: body.data.dateTo,
                                ...(body.data.dataId && { dataId: body.data.dataId }),
                                userId: body.data.userId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientProgress.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatientAdvisory: builder
            .mutation<GetPatientAdvisoryResponse, GetPatientAdvisoryRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetPatientAdvisoryRequest['data']
                        } =
                        {
                            data: {
                                userId: body.data.userId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientAdvisory.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatientDeviations: builder
            .mutation<GetPatientDeviationsResponse, GetPatientDeviationsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetPatientDeviationsRequest['data']
                        } =
                        {
                            data: {
                                userId: body.data.userId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientDeviations.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        addPatient: builder
            .mutation<AddPatientResponse, AddPatientRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: AddPatientRequest['data']
                    } =
                    {
                        data: {
                            firstName: body.data.firstName,
                            lastName: body.data.lastName,
                            email: body.data.email,
                            patientNumber: body.data.patientNumber,
                            careFacilities: body.data.careFacilities
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.addPatient.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        editPatient: builder
            .mutation<EditPatientResponse, EditPatientRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: EditPatientRequest['data']
                    } =
                    {
                        data: {
                            userId: body.data.userId,
                            firstName: body.data.firstName,
                            lastName: body.data.lastName,
                            email: body.data.email,
                            patientNumber: body.data.patientNumber,
                            active: body.data.active
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.editPatient.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateProfilePicturePatient: builder
            .mutation<
                UpdateProfilePicturePatientResponse,
                UpdateProfilePicturePatientRequest & TokenAuth>({
                    query: (body) => {
                        const formData: Pick<
                        UpdateProfilePicturePatientRequest, 'data'> = {
                            data: body.data
                        }

                        return {
                            url: [
                                _.trimStart(
                                    MODULE_TABLE.doc.apiPaths
                                        .updateProfilePicturePatient.path, '/'
                                ),
                                body.userId ? '/' + body.userId : ''
                            ].join(''),
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`
                            },
                            body: formData.data
                        }
                    }
                }),
        getDepartmentCareFacilities: builder
            .mutation<GetDepartmentCareFacilitiesResponse,
            GetDepartmentCareFacilitiesRequest & TokenAuth>({
                query: (body) => {
                    const formData: {} = {}

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getDepartmentCareFacilities.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getUserTreatments: builder
            .mutation<GetUserTreatmentsResponse, GetUserTreatmentsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetUserTreatmentsRequest = {
                        data: {
                            userId: body.data.userId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.doc.apiPaths
                                .getUserTreatments.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        makeCareplan: builder
            .mutation<MakeCareplanResponse, MakeCareplanRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: MakeCareplanRequest['data']
                    } =
                    {
                        data: {
                            userId: body.data.userId,
                            treatmentId: body.data.treatmentId,
                            carePlanId: body.data.carePlanId,
                            startDate: body.data.startDate
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.makeCareplan.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getOverallAbnormalities: builder
            .mutation<GetOverallAbnormalitiesResponse,
                GetOverallAbnormalitiesRequest & TokenAuth>({
                    query: (body) => {
                        const formData: {
                            data: GetOverallAbnormalitiesRequest['data']
                        } = {
                            data: {
                                skip: body.data.skip !== undefined ? body.data.skip : 0,
                                limit: body.data.limit !== undefined
                                    ? body.data.limit
                                    : CAREPROF_TABLE_LIMIT,
                                search: body.data.search !== undefined ? body.data.search : '',
                                startDate: body.data.startDate !== undefined
                                    ? body.data.startDate
                                    : 0,
                                endDate: body.data.endDate !== undefined
                                    ? body.data.endDate
                                    : 0
                            }
                        }

                        const url = [
                            _.trimStart(
                                MODULE_TABLE.doc
                                    .apiPaths.getOverallAbnormalities.path, '/'
                            )
                        ].join('')

                        return {
                            url,
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`,
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        }
                    }
                }),
        getPatientsUpcomingSurgery: builder
            .mutation<GetPatientsUpcomingSurgeryResponse,
            GetPatientsUpcomingSurgeryRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: GetOverallAbnormalitiesRequest['data']
                    } = {
                        data: {
                            skip: body.data.skip !== undefined ? body.data.skip : 0,
                            limit: body.data.limit !== undefined
                                ? body.data.limit
                                : CAREPROF_TABLE_LIMIT,
                            search: body.data.search !== undefined ? body.data.search : ''
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientsUpcomingSurgery.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getTreatedPatients: builder
            .mutation<GetTreatedPatientsResponse,
            GetTreatedPatientsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: GetOverallAbnormalitiesRequest['data']
                    } = {
                        data: {
                            skip: body.data.skip !== undefined ? body.data.skip : 0,
                            limit: body.data.limit !== undefined
                                ? body.data.limit
                                : CAREPROF_TABLE_LIMIT,
                            search: body.data.search !== undefined ? body.data.search : ''
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getTreatedPatients.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPatientTherapeutics: builder
            .mutation<GetPatientTherapeuticsResponse, GetPatientTherapeuticsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetPatientTherapeuticsRequest = {
                        data: {
                            userId: body.data.userId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getPatientTherapeutics.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updatePatientTreatment: builder
            .mutation<UpdatePatientTreatmentResponse, UpdatePatientTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdatePatientTreatmentRequest = {
                        data: {
                            userId: body.data.userId,
                            treatmentId: body.data.treatmentId,
                            startDate: body.data.startDate,
                            carepathId: body.data.carepathId,
                            myCareplanId: body.data.myCareplanId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.doc.apiPaths
                                .updatePatientTreatment.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getHCPCards: builder
            .mutation<GetHCPCardsResponse, GetHCPCardsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetHCPCardsRequest = {
                        data: {
                            type: body.data.type
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.doc
                                .apiPaths.getHCPCards.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        deleteCareplan: builder
            .mutation<DeleteCareplanResponse, DeleteCareplanRequest & TokenAuth>({
                query: (body) => {
                    const formData: DeleteCareplanRequest = {
                        data: {
                            myCareplanId: body.data.myCareplanId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.doc.apiPaths.deleteCareplan.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useResendInviteMutation,
    useGetTreatmentMutation,
    useAddPatientMutation,
    useEditPatientMutation,
    useGetPatientMutation,
    useGetPatientsMutation,
    useGetPatientActivityMutation,
    useGetPatientChartsMutation,
    useGetPatientChartDataMutation,
    useUpdateProfilePicturePatientMutation,
    useGetDepartmentCareFacilitiesMutation,
    useGetUserTreatmentsMutation,
    useMakeCareplanMutation,
    useGetPatientProgressMutation,
    useGetPatientActivityReasoningMutation,
    useGetPatientAdvisoryMutation,
    useGetPatientDeviationsMutation,
    useGetOverallAbnormalitiesMutation,
    useGetPatientsUpcomingSurgeryMutation,
    useGetTreatedPatientsMutation,
    useGetPatientTherapeuticsMutation,
    useUpdatePatientTreatmentMutation,
    useGetHCPCardsMutation,
    useDeleteCareplanMutation,
    useGPACareplanStepIdDetailsMutation,
    useGPACareplanStepIdMutation
} = docV10Api
