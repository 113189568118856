import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings, selectDateFormats } from '@app/slices/slice.app'
import { IDS } from '@doc/constants'

import NewDatePicker from '@app/components/NewDatePicker'
import { DepartmentCareFacilitiesAction, DepartmentCareFacilitiesState } from '@doc/type'
import { fromUnixTime, getUnixTime } from 'date-fns'

interface ComponentProps {
    departmentCareFacilitiesState: DepartmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction: React.Dispatch<DepartmentCareFacilitiesAction>
}

const OperationDateSelection = ({
    departmentCareFacilitiesState, dispatchDepartmentCareFacilitiesAction
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const dateFormats = useAppSelector(selectDateFormats)

    const toggleCheckbox = (checked: boolean) => {
        dispatchDepartmentCareFacilitiesAction({
            type: 'UPDATE_MAIN',
            payload: {
                ...departmentCareFacilitiesState.main,
                selectedOptions: {
                    ...departmentCareFacilitiesState
                        .main.selectedOptions,
                    operationDate: {
                        ...departmentCareFacilitiesState
                            .main.selectedOptions.operationDate,
                        isChecked: checked
                    }
                }
            }
        })
    }

    const onChangeDate: (date: number) => void = (date) => {
        dispatchDepartmentCareFacilitiesAction({
            type: 'UPDATE_MAIN',
            payload: {
                ...departmentCareFacilitiesState.main,
                selectedOptions: {
                    ...departmentCareFacilitiesState
                        .main.selectedOptions,
                    operationDate: {
                        ...departmentCareFacilitiesState
                            .main.selectedOptions.operationDate,
                        date
                    }
                }
            }
        })
    }

    return <div className={'form-group'}>
        <div className={'align-items-baseline mt-3 row'}>
            <div className={'col-3 d-none d-sm-block'}>
                <span className={'form-label'}>{
                    strings.doc?.text.careprofessionals.treated_patients.operation_date
                }</span>
            </div>
            <div className={'col-sm-9 col-12 position-relative'}>
                <div>
                    <input type={'checkbox'}
                        id={`${ IDS.PATIENT_ADD_MODAL.SELECT
                        .SELECT_OPERATION_DATE_CHECKED_1 }`}
                        checked={
                            departmentCareFacilitiesState.main
                                .selectedOptions.operationDate.isChecked === false
                        } className={'form-check-input'}
                        onChange={(e) => {
                            toggleCheckbox(!e.target.checked)
                        }}/>
                    <label className={'form-check-label ps-3'}
                        htmlFor={`${ IDS.PATIENT_ADD_MODAL.SELECT
                            .SELECT_OPERATION_DATE_CHECKED_1 }`}>{
                            strings.doc?.text.patient
                                .add_interface.steps['3'].fields.not_known}</label>
                </div>
                <div>
                    <div className={'mt-3 row justify-content-between align-items-center'}>
                        <div className={'col-auto'}>
                            <input type={'checkbox'}
                                id={`${ IDS.PATIENT_ADD_MODAL.SELECT
                                .SELECT_OPERATION_DATE_CHECKED_2 }`}
                                checked={
                                    departmentCareFacilitiesState.main
                                        .selectedOptions.operationDate.isChecked === true
                                } className={'form-check-input'}
                                onChange={(e) => {
                                    toggleCheckbox(e.target.checked)
                                }}/>
                            <label className={'form-check-label ps-3'}
                                htmlFor={`${ IDS.PATIENT_ADD_MODAL.SELECT
                            .SELECT_OPERATION_DATE_CHECKED_2 }`}>{
                                    strings.doc?.text.patient
                                        .add_interface.steps['3'].fields.known}</label>
                        </div>
                        <div className={'col'}>
                            <NewDatePicker
                                disabled={
                                    departmentCareFacilitiesState.main
                                        .selectedOptions.operationDate.isChecked === false
                                }
                                id={IDS.PATIENT_ADD_MODAL.SELECT.SELECT_OPERATION_DATE}
                                isDate={(date) => {
                                    onChangeDate(getUnixTime(date))
                                }}

                                singleDate={departmentCareFacilitiesState.main
                                    .selectedOptions.operationDate.date
                                    ? fromUnixTime(
                                        departmentCareFacilitiesState.main
                                            .selectedOptions.operationDate.date
                                    )
                                    : undefined}
                                dateType={''}
                                timeFormat={dateFormats.format5}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default OperationDateSelection
