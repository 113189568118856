import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import QuestionInterface from '@careplan/components/careplanStep/reasoningCard/QuestionInterface'
import {
    BuildingBlock,
    BuildingBlockProgress,
    BuildingBlockProgressActions,
    ReasoningCard,
    RwmCard,
    RwmCardActions
} from '@careplan/type'
import { QuestionType } from '@reasonWithMe/type'
import _ from 'lodash'
import { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

const RwmModal = ({
    personalCareplanId,
    progressDispatch,
    buildingBlock,
    buildingBlockProgress,
    rwmCard,
    rwmCardDispatch,
    updateProgress
}: {
    personalCareplanId: string
    progressDispatch: React.Dispatch<BuildingBlockProgressActions>,
    buildingBlock: BuildingBlock,
    buildingBlockProgress: BuildingBlockProgress,
    rwmCard: RwmCard,
    rwmCardDispatch: React.Dispatch<RwmCardActions>,
    updateProgress: (requestData: {
        obj: BuildingBlockProgress, percentage: number, miscVar?: any
    }) => Promise<void>
}) => {
    const [questionType, setQuestionType] = useState<QuestionType>()
    const toggle = () => {
        rwmCardDispatch({
            type: 'UPDATE_IS_OPEN',
            value: {
                buildingBlockId: rwmCard.buildingBlockId,
                isOpen: !rwmCard.isOpen
            }
        })
    }

    const contents = buildingBlock.buildingBlockValue !== null &&
    typeof buildingBlock.buildingBlockValue !== 'string' &&
    !_.isArray(buildingBlock.buildingBlockValue)
        ? buildingBlock.buildingBlockValue as ReasoningCard
        : {
            reasoningSetTitle: '',
            reasoningSetDescription: '',
            reasoningSetId: ''
        } as ReasoningCard

    const strings = useAppSelector(selectStrings)
    const questionnaire = <div className={'questionnaire'}>
        <div className={'card'}>
            <div className={'card-body'}>
                <div className={'d-flex align-items-center'}>
                    <h2 className={'mb-3 mt-4'}>
                        <i className={'fa-light fa-file-lines me-2'}>
                        </i>
                        {contents?.reasoningSetTitle || ''}
                    </h2>
                </div>
                <p>
                    {contents?.reasoningSetDescription || ''}
                </p>

                <div className={'row align-items-center'}>
                    <div className={'col-10 col-md-8 mx-auto'}>
                        <button type={'button'}
                            onClick={() => {
                            // show the modal right away.
                                toggle()
                            }}
                            className={'btn btn-primary w-100 btn-lg'}>
                            {strings.app?.text.search.start.label}
                        </button>
                    </div>
                </div>

            </div>

        </div>
    </div>
    return <div className={'col-12 col-lg-12 mx-auto'}>
        <div className={'row row-cols-1 mb-4'} role={'group'}>
            <div className={'col'}>
                {questionnaire}
            </div>
        </div>
        {/* place modal here. from '' to 'lg' on new design */}
        <Modal size={questionType === 'open-list' ? 'lg' : 'lg'}
            unmountOnClose={false} isOpen={rwmCard.isOpen} toggle={() => {
                toggle()
            }}>

            <ModalBody className={'modal-body px-2'}>
                <QuestionInterface
                    personalCareplanId={personalCareplanId}
                    progressDispatch={progressDispatch}
                    data={{
                        reasoningSetId: contents.reasoningSetId,
                        reasoningSetVersion: 0.1
                    }}
                    buildingBlockProgress={buildingBlockProgress}
                    updateProgress={updateProgress}
                    setQuestionType={setQuestionType}
                    isModal={true}
                />
            </ModalBody>
        </Modal>
    </div>
}

export default RwmModal

/* removed at 3/27/2024. implicates back when it doesn't */
/* <ModalHeader className={'justify-content-start'} toggle={() => {
                toggle()
                // btn-close me-auto ms-0
            }} */
/* close={ */
// <a className={'btn btn-round '}
//     onClick={(e) => {
//         e.preventDefault()
//         toggle()
//     }}>
//     {/* thought this was supposed to fix it */}
//     {/* <i className={'fa-light fa-arrow-left ps-3'} aria-hidden={'true'}> */}
//     <i className={'fa-light fa-arrow-left align-middle'} aria-hidden={'true'}>
//     </i>
// </a>
// }

// >{}</ModalHeader>}
