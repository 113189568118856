
interface Colors {
    blue: string;
    white_50: string;
    white_100: string;
    midnightAzure: string;
    indigo: string;
    purple: string;
    pink: string;
    red: string;
    orange: string;
    yellow: string;
    yellow1: string;
    yellow2: string;
    yellow3: string;
    yellow4: string;
    yellowDark: string,
    yellowShadow: string;
    shadowColor: string
    shadowColor2: string
    shadowColor3: string
    shadowColorYellow: string
    navigationShadow: string
    green: string;
    teal: string;
    cyan: string;
    black: string;
    white: string;
    gray: string;
    gray_dark: string;
    grayish_blue: string;
    gray_50: string;
    gray_100: string;
    gray_200: string;
    gray_300: string;
    gray_400: string;
    gray_500: string;
    gray_600: string;
    gray_700: string;
    gray_800: string;
    gray_900: string;
    darkGrey_1: string,
    primary: string;
    secondary: string;
    activity: string;
    article: string;
    module: string;
    question: string;
    light: string;
    dark: string;
    darkSelected: string;
    blue_100: string;
    blue_200: string;
    darkOpacityLow: string;
    whiteOpacityLow: string;
    facilityHeaderColor: {
        1: string,
        2: string,
        3: string
    }
    taskCellContainerShadows: {
        1: string,
        2: string,
        3: string
        4: string
    }
    completion: {
        complete: string,
        locked: string,
        uncertain: string,
        incomplete: string
    }
    headerGradientColor: string,
    cardTypes: {
        notification: string,
        activity: string,
        content: string,
        lesson: string,
        reasoning: string
    }
}

export const ADMIN_COLORS: Colors = {
    blue: '#2640c4',
    white_50: '#f4f4f4',
    white_100: '#e5e5e5',
    midnightAzure: '#0356A1',
    indigo: '#6610f2',
    purple: '#8300d3',
    pink: '#d63384',
    red: '#f2320c',
    orange: '#fd7e14',
    yellow: '#f6bf00',
    yellow1: '#F0B000',
    yellow2: '#fab700e1',
    yellow3: '#fab700c8',
    yellow4: '#F6BF00',
    yellowDark: '#c59900',
    yellowShadow: '#f6bf003f',
    green: '#198754',
    teal: '#47c0a3',
    cyan: '#0dcaf0',
    black: '#000',
    shadowColor: '#2626260D',
    shadowColor2: '#3b3b3b36',
    shadowColor3: '#2626260A',
    navigationShadow: '#00325e26',
    white: '#fff',
    gray: '#6c757d',
    gray_dark: '#343a40',
    grayish_blue: '#EBECF5',
    gray_50: '#f7f7fd',
    gray_100: '#f8f8fc',
    gray_200: '#e7e8ed',
    gray_300: '#dddddd',
    gray_400: '#c6ccd2',
    gray_500: '#aaaaaa',
    gray_600: '#6c757d',
    gray_700: '#495057',
    gray_800: '#343a40',
    gray_900: '#262626',
    darkGrey_1: '#474747',
    primary: '#f6bf00',
    secondary: '#2640c4',
    activity: '#8300d3',
    article: '#2640c4',
    module: '#47c0a3',
    question: '#c06f47',
    light: '#f8f8fc',
    dark: '#003b70',
    darkSelected: '#266299',
    blue_100: '#457ae5',
    shadowColorYellow: '#B2860014',
    blue_200: '#0A7AFF',
    darkOpacityLow: '#003c7035',
    whiteOpacityLow: '#dcdcdc94',
    facilityHeaderColor: {
        1: '#0672D3',
        2: '#024C8F',
        3: '#003B70'
    },
    taskCellContainerShadows: {
        1: 'rgba(6, 114, 211, 0)',
        2: 'rgba(6, 114, 211, 0.0549233)',
        3: 'rgba(6, 114, 211, 0.115817)',
        4: 'rgba(6, 114, 211, 0.054)'
    },
    completion: {
        complete: '#35B16E',
        locked: '#C6CCD2;',
        uncertain: '#0c0d0d',
        incomplete: '#E78123'
    },
    headerGradientColor: '#DF8600',
    cardTypes: {
        notification: '#003870',
        activity: '#457AE5',
        content: '#E5448D',
        lesson: '#6CD8B7',
        reasoning: '#9840AE'
    }
}
