import { useEffect, useRef, useState } from 'react'

import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { smartSearch } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useGetTreatmentCarepathsMutation, useGetTreatmentsMutation } from '@fmt/api'

import { IDS } from '@fmt/constants'
import {
    GetTreatmentCarepathsResponse,
    GetTreatmentsResponse,
    Treatment,
    TreatmentCarepathData
} from '@fmt/type'
import _ from 'lodash'

interface ComponentProps {
    selectedTreatment: TreatmentCarepathData | undefined
    setSelectedTreatment: React.Dispatch<React.SetStateAction<TreatmentCarepathData | undefined>>,
    isOutsideClick: boolean,
    setIsOutsideClick: React.Dispatch<React.SetStateAction<boolean>>,
}

const LinkTreatmentFromCarepathDropdown = (
    {
        selectedTreatment, setSelectedTreatment, isOutsideClick, setIsOutsideClick
    }:ComponentProps
) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const strings = useAppSelector(selectStrings)

    // both add and delete interfaces show up here.
    // when the object changes, and it depends on the current page you're in
    // will affect what happens after. IF you are on the add page,
    // nothing should happen. If you are on the edit page, unlink the previously
    // selected treatment, and link the new one.
    // I'd advise against using useEffect and rely on simple click handlers.

    // get a list of unlinked treatments and linked treatments.
    // EXPECTED RESULTS: 1 linked or 0 linked.
    // rest are unlinked because of treatmentId check.

    // this call gets the list of linked treatments if the treatmentId is opted
    const [
        getLinkedTreatments,
        getLinkedTreatmentsMutation
    ] = useGetTreatmentCarepathsMutation()

    // this call gets all available treatments linked or unlinked. You'll need
    // to filter this out to only unlinked records.
    const [getAvailableTreatments,
        getAvailableTreatmentsMutation
    ] = useGetTreatmentsMutation()

    // a workaround to prevent tables from flickering. simple but it works.
    const [
        getAvailableTreatmentsResponse,
        setGetAvailableTreatmentsResponse
    ] = useState<GetTreatmentsResponse>()

    const [
        getLinkedTreatmentsResponse,
        setGetLinkedTreatmentsResponse
    ] = useState<GetTreatmentCarepathsResponse>()

    // In add, you only want the unlinked treatments.
    // so basically, you can select all the option in the getTreatments
    // response data because the carepath and treatment link doesn't exist.

    // In, edit, you want to _.find, that one value in getLinkedTreatmets
    // and filter that one treatmentId out from the unlinked treatments.

    const linkedTreatment: TreatmentCarepathData | undefined = _.find(
        getLinkedTreatmentsResponse?.data, (o) => {
            return o.carepathId === (selectedTreatment?.carepathId)
        }
    )

    useEffect(() => {
        // if there's no record, don't set.
        if (getLinkedTreatmentsResponse) {
            setSelectedTreatment(linkedTreatment)
        }
    }, [getLinkedTreatmentsResponse])

    useEffect(() => {
        if (getAvailableTreatmentsMutation.data) {
            setGetAvailableTreatmentsResponse(getAvailableTreatmentsMutation
                .data)
        }
    }, [getAvailableTreatmentsMutation.data])

    useEffect(() => {
        if (getLinkedTreatmentsMutation.data) {
            setGetLinkedTreatmentsResponse(getLinkedTreatmentsMutation.data)
        }
    }, [getLinkedTreatmentsMutation.data])

    const unsubscribeGetAvailableTreatments = () => {
        const unsubscribeMutation = getAvailableTreatments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetLinkedTreatments = () => {
        const unsubscribeMutation = getLinkedTreatments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const fetchData = (token: TokenData) => {
        unsubscribeGetAvailableTreatments()
        unsubscribeGetLinkedTreatments()

        let getAvailableTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getLinkedTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getTreatments.path,
                        true
                    )

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getTreatmentCarepaths.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getAvailableTreatmentsPromise = getAvailableTreatments({
                            authToken: newToken.value,
                            // we want available records of all.
                            data: {
                                limit: 0,
                                skip: 0
                            }
                        })
                    }

                    if (isValid2 && newToken.value) {
                        getLinkedTreatmentsPromise = getLinkedTreatments({
                            authToken: newToken.value,
                            // no params because we'll filter them out after.
                            data: {}
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getAvailableTreatmentsPromise && getAvailableTreatmentsPromise.abort()
            getLinkedTreatmentsPromise && getLinkedTreatmentsPromise.abort()
        }
    }

    useEffect(() => {
        // if the values of linkedDepartments is updated, then update the queries.
        return fetchData(token)
    }, [token.id, token.valid])

    // WE'LL NEED TO DO THE TREATMENT STATUS CHANGE STRICTLY.

    const containerRef = useRef<HTMLDivElement | null>(null)
    const [search, setSearch] = useState('')
    // start off with dropdown already open.

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current) {
                // weird behavior but works anyways
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsOutsideClick(false)
                } else {
                    setIsOutsideClick(true)
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [containerRef])

    const changeTreatmentStatus = async (
        e: React.ChangeEvent<HTMLInputElement>, obj: TreatmentCarepathData
    ) => {
        if (e.target.checked) {
            setSelectedTreatment(obj)
        } else {
            setSelectedTreatment(undefined)
        }
    }

    const renderOption = (obj: TreatmentCarepathData) => {
        const key = obj.treatmentId

        return <div
            className={'col'}
            key={key}
        >
            <input type={'checkbox'}
                className={'btn-check'}
                id={key}
                autoComplete={'off'}
                checked={obj.treatmentId === selectedTreatment?.treatmentId}
                onChange={(e) => {
                    changeTreatmentStatus(e, obj)
                }}
            />
            <label
                className={'btn btn-dummy'}
                htmlFor={key}
            >

                <div className={'question-checkbox'}>
                    <div className={'card justify-content-center px-3 py-2'}>

                        <div className={'d-flex flex-column'}>
                            <div className={'d-flex align-items-center'}>
                                <div className={'p text-start'}>
                                    <span>{obj.treatmentData?.[0]?.treatmentName || ''}</span>
                                </div>
                                <div className={'question-checkmark ms-auto'}>
                                    <i className={'fa-light fa-check mt-1 mx-auto'}>
                                    </i>
                                </div>
                                <div className={'question-plussign ms-auto'}>
                                    <i className={'fa-light fa-plus mx-auto'}>
                                    </i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </label>
        </div>
    }

    // IF add.
    const AvailableTreatmentOptionsAdd = <div className={'mb-3'}>
        {
            _.map(
                (smartSearch(
                    (getAvailableTreatmentsResponse?.data.treatments || []),
                    [], search
                ) as Treatment[]),
                (obj) => {
                    return renderOption({
                        treatmentId: obj.treatmentId,
                        // carepathId is supposed to be empty here. you are
                        // at the carepath module wanting to link a treatment.
                        carepathId: '',
                        // one result.
                        treatmentData: [{
                            treatmentName: obj.treatmentName
                        }],
                        // one result.
                        carepathData: [{
                            careplanName: ''
                        }]
                    })
                })
        }
    </div>

    // creating our own treatments interface because it works differently from the other one.
    return <div className={[
        'align-items-baseline mt-4 row'
    ].join(' ')}>
        <div className={'col-xl-3 d-none d-sm-block'}>
            <label htmlFor={IDS.CAREPATH.EDIT.LINK_TREATMENTS}
                className={'form-label'}>{
                    strings.fmt?.text.facility.add.treatment
                }</label>
        </div>
        <div className={'col-xl-9 col-12 position-relative'}>

            <div
                id={IDS.CAREPATH.EDIT.LINK_TREATMENTS}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOutsideClick(!isOutsideClick)
                }}
                className={[
                    'form-select py-3'
                ].join(' ')}
            >
                {
                    !selectedTreatment?.treatmentData?.[0]?.treatmentName
                        ? <span className={'opacity-50'}>
                            {strings.app?.text.select || ''}
                        </span>
                        : <>
                            <span>{selectedTreatment?.treatmentData?.[0]?.treatmentName}</span>
                        </>
                }

            </div>

            {/* popup window to get a list of carepath with checkboxes */}

            {isOutsideClick && <div
                ref={(e) => {
                    containerRef.current = e
                }}
                className={[
                    'dropdown-list mt-4 px-5 py-6 w-100'
                ].join(' ')}>
                {/* search tab and filter icon */}
                <div className={['row justify-content-between mb-4 ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box '}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={'btn-group-checkbox-list row row-cols-1'}>
                    <>
                        {getAvailableTreatmentsResponse
                            ?.data.treatments.length
                            ? <h6 className={'mb-3'}>{strings.fmt
                                ?.text.carepath.details.available}</h6>
                            : ''}
                        {AvailableTreatmentOptionsAdd}</>
                </div>

            </div>}

        </div>
    </div>
}

export default LinkTreatmentFromCarepathDropdown
