
import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { CareProfessionalKeys, CareProfessionalValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    careprofessionalEditFormik: FormikProps<CareProfessionalValues>
}

const AddSystemUser = ({ careprofessionalEditFormik }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: CareProfessionalKeys = 'addSystemUser'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.CAREPROFESSIONAL.EDIT.ADD_SYSTEM_USER}
                    className={'form-label'}>{
                        strings.fmt?.text.careprofessional.details.add_system_user
                    }</label>
            </div>
            <div className={'col-auto'}>
                <div className={'form-check form-switch settings-switch col-auto'}>
                    <input className={'form-check-input'}
                        id={IDS.CAREPROFESSIONAL.EDIT.ADD_SYSTEM_USER}
                        type={'checkbox'} role={'switch'}
                        checked={careprofessionalEditFormik.values.addSystemUser}
                        onChange={() => {
                            careprofessionalEditFormik.setFieldValue(
                                fieldName, !careprofessionalEditFormik.values.addSystemUser
                            )
                        }}
                    />
                </div>
                <div className={'form-text error'}>{
                    careprofessionalEditFormik.errors.addSystemUser
                        ? careprofessionalEditFormik.errors.addSystemUser
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default AddSystemUser
