
import { GPACareplanStepIdDetails } from '@doc/type'
import { useMemo } from 'react'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const Open = ({
    reasoningObj
}: ComponentProps) => {
    const answerValue = reasoningObj.data.data.answerValue

    const input = useMemo(() => {
        return <div className={'question-open'}>
            <textarea
                className={'form-control form-control-sm'}
                value={answerValue}
                onChange={(e) => {
                }}
                onBlur={(e) => {
                }}
            />
        </div>
    }, [])

    return input
}

export default Open
