import { useAppSelector } from '@app/app.hook'
import { selectToken } from '@app/slices/slice.token'
import '@stylesheet/other-css-imports'
import { useEffect, useMemo, useState } from 'react'

import { selectModuleOwner } from '@app/slices/slice.app'
import iosPWASplash from '@app/stylesheetManager/iosPWASplash'
import { APP_ICON_AND_TITLE as IKH_APP_ICON_AND_TITLE } from '@stylesheet/brands/ikh/constants'
import { APP_ICON_AND_TITLE as RTW_APP_ICON_AND_TITLE } from '@stylesheet/brands/rtw/constants'
import { FavIcon } from '@stylesheet/config/ThemeConfig'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { DESKTOP_RESPONSIVE_LIMIT } from '@app/app.constants'
// import { selectRouter } from '@app/app.store'
const HeadContents = () => {
    // now you want the token data here.
    const token = useAppSelector(selectToken)
    const moduleOwner = useAppSelector(selectModuleOwner)

    const [favicon, setFavicon] = useState<FavIcon>({
        16: '',
        24: '',
        32: '',
        48: '',
        57: '',
        64: '',
        72: '',
        76: '',
        114: '',
        120: '',
        144: '',
        152: '',
        180: '',
        192: '',
        512: ''
    })
    const [title, setTitle] = useState('')

    const fontUrl = 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700'
    const fontAwesomeUrl = 'https://kit.fontawesome.com/a71b114ecc.js'

    const cssFiles = {
        fontFamily: '/css/FontFamily.css',
        spacingUtilities: '/css/spacing-utilities.css'
    }

    useEffect(() => {
        // a new function introduced where you determine based on the router.location.pathname
        // if you belong to the routes that belong to ADMIN or ENDUSER
        // but because it's out of scope from the route generator, we will use a global state
        // instead.
        // or just import from app store anyways.
        if (moduleOwner === 'enduser') {
            if (_.includes(token.details.ss?.endUser, 'ikh-enduser')) {
                const DATA = IKH_APP_ICON_AND_TITLE.END_USER
                setFavicon(DATA.FAVICON)
                setTitle(DATA.APP_NAME)
            } else if (_.includes(token.details.ss?.endUser, 'rtw-enduser')) {
                const DATA = RTW_APP_ICON_AND_TITLE.END_USER
                setFavicon(DATA.FAVICON)
                setTitle(DATA.APP_NAME)
            }
        } else if (moduleOwner === 'admin') {
            if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
                const DATA = IKH_APP_ICON_AND_TITLE.ADMIN
                setFavicon(DATA.FAVICON)
                setTitle(DATA.APP_NAME)
            } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
                const DATA = RTW_APP_ICON_AND_TITLE.ADMIN
                setFavicon(DATA.FAVICON)
                setTitle(DATA.APP_NAME)
            }
        }
    }, [token.details, moduleOwner])

    useEffect(() => {
        // console.log('is using title and favicon: ', favicon, title)
    }, [favicon, title])

    useEffect(() => {
        if (favicon[512]) iosPWASplash(favicon[512], '#000000')
    }, [favicon[512]])

    // load all favicons on mount.
    const conditionalFavIconRender = useMemo(() => {
        console.log('rendering favicons')
        return <Helmet>
            {/* for favicon sizes */}
            {/* <link rel={'icon'} href={favicon[32]} />
            <link rel={'apple-touch-icon'} sizes={'57x57'} href={favicon[57]} />
            <link rel={'apple-touch-icon'} sizes={'72x72'} href={favicon[72]} />
            <link rel={'apple-touch-icon'} sizes={'76x76'} href={favicon[76]} />
            <link rel={'apple-touch-icon'} sizes={'114x114'} href={favicon[114]} />
            <link rel={'apple-touch-icon'} sizes={'120x120'} href={favicon[120]} />
            <link rel={'apple-touch-icon'} sizes={'144x144'} href={favicon[144]} />
            <link rel={'apple-touch-icon'} sizes={'152x152'} href={favicon[152]} />
            <link rel={'apple-touch-icon'} sizes={'180x180'} href={favicon[180]} />
            <link rel={'icon'} sizes={'192x192'} href={favicon[192]} /> */}

            <link rel={'shortcut icon'} type={'image/png'}
                sizes={'16x16'} href={favicon[16]}/>
            <link rel={'shortcut icon'} type={'image/png'}
                sizes={'24x24'} href={favicon[24]}/>
            <link rel={'shortcut icon'} type={'image/png'}
                sizes={'32x32'} href={favicon[32]}/>
            <link rel={'shortcut icon'} type={'image/png'}
                sizes={'48x48'} href={favicon[48]}/>
            <link rel={'shortcut icon'} type={'image/png'}
                sizes={'64x64'} href={favicon[64]}/>
            <link rel={'shortcut icon'} type={'image/png'}
                sizes={'72x72'} href={favicon[72]}/>

            {/* Mobile (Android, iOS & others) */}
            <link rel={'apple-touch-icon'} sizes={'57x57'} href={favicon[57]}/>
            <link rel={'apple-touch-icon-precomposed'} href={favicon[180]}/>
            <link rel={'apple-touch-icon'} sizes={'72x72'} href={favicon[76]}/>
            <link rel={'apple-touch-icon-precomposed'} sizes={'76x76'} href={favicon[76]}/>
            <link rel={'apple-touch-icon-precomposed'} sizes={'114x114'} href={favicon[114]}/>
            <link rel={'apple-touch-icon-precomposed'} sizes={'120x120'} href={favicon[120]}/>
            <link rel={'apple-touch-icon-precomposed'} sizes={'144x144'} href={favicon[144]}/>
            <link rel={'apple-touch-icon-precomposed'} sizes={'152x152'} href={favicon[152]}/>
            <link rel={'apple-touch-icon'} sizes={'180x180'} href={favicon[180]}/>
            <link rel={'apple-touch-icon'} sizes={'192x192'} href={favicon[192]}/>
            <link rel={'apple-touch-icon'} sizes={'195x195'} href={favicon[192]}/>
            <link rel={'apple-touch-icon'} sizes={'196x196'} href={favicon[192]}/>

            {/* <link rel={'icon'} type={'image/png'} sizes={'48x48'} href={favicon[57]}/> */}
            <link rel={'icon'} type={'image/png'} sizes={'96x96'} href={favicon[114]}/>
            {/* <link rel={'icon'} type={'image/png'} sizes={'57x57'} href={favicon[57]}/> */}
            {/* <link rel={'icon'} type={'image/png'} href={favicon[180]}/> */}
            {/* <link rel={'icon'} type={'image/png'} sizes={'72x72'} href={favicon[76]}/>
            <link rel={'icon'} type={'image/png'} sizes={'76x76'} href={favicon[76]}/>
            <link rel={'icon'} type={'image/png'} sizes={'114x114'} href={favicon[114]}/>
            <link rel={'icon'} type={'image/png'} sizes={'120x120'} href={favicon[120]}/>
            <link rel={'icon'} type={'image/png'} sizes={'144x144'} href={favicon[144]}/>
            <link rel={'icon'} type={'image/png'} sizes={'152x152'} href={favicon[152]}/>
            <link rel={'icon'} type={'image/png'} sizes={'180x180'} href={favicon[180]}/> */}
            <link rel={'icon'} type={'image/png'} sizes={'192x192'} href={favicon[192]}/>
            {/* <link rel={'icon'} type={'image/png'} sizes={'195x195'} href={favicon[192]}/>
            <link rel={'icon'} type={'image/png'} sizes={'196x196'} href={favicon[192]}/> */}
        </Helmet>
    }, [favicon])

    // 710 won't do. you're just getting
    // the shortest of what the desktop responsive limit will allow.
    // use DESKTOP_RESPONSIVE_LIMIT instead
    const widthForMobile = Number(DESKTOP_RESPONSIVE_LIMIT.slice(0, -2)) + 10

    return <>
        <Helmet>

            <meta charSet={'utf-8'}/>

            {/* for mobile app experience especially borderless. */}
            {/* General Purpose Tags */}
            <meta name={'theme-color'} content={'#2A2A2A'}></meta>

            {/* applicable to styles in the endUser. admin pages aren't repsonsive */}
            {moduleOwner === 'enduser' &&
                <meta
                    name={'viewport'} content={'width=device-width, initial-scale=1'}/>
            }
            {moduleOwner === 'admin' &&
                <meta
                    name={'viewport'} content={
                        'width=' + widthForMobile + ', initial-scale=1'
                    }/>}
            {/* <meta name={'viewport'} content={'viewport-fit=cover'} /> */}
            <meta name={'mobile-web-app-capable'} content={'yes'} />
            {/* HTML TAG FOR IOS */}
            <meta name={'apple-mobile-web-app-capable'} content={'yes'} />
            <meta name={'apple-mobile-web-app-status-bar-style'} content={'black-translucent'}/>
            <meta name={'apple-mobile-web-app-title'} content={title}></meta>
            {/* <link rel={'apple-touch-startup-image'} href={favicon[512]}/> */}
            <meta name={'apple-touch-fullscreen'} content={'yes'} />

            <meta name={'robots'} content={'noindex,nofollow'} />
            <title>{title}</title>
            <link href={fontUrl} rel={'stylesheet'} />
            <script src={fontAwesomeUrl} crossOrigin={'anonymous'}></script>
            <meta name={'description'} content={'Amino'} />

            <link href={cssFiles.fontFamily} rel={'stylesheet'} />
            <link href={cssFiles.spacingUtilities} rel={'stylesheet'} />

            {/* necessary for android icons to show up on the device */}
            <link href={'/manifest.json'} rel={'manifest'} />

            {/* Windows 8 Tiles */}
            <meta name={'application-name'} content={title}/>
            <meta name={'msapplication-tooltip'} content={title}></meta>
            <meta name={'msapplication-TileImage'} content={favicon[144]}/>
            <meta name={'msapplication-TileColor'} content={'#2A2A2A'}/>
        </Helmet>
        {conditionalFavIconRender}
    </>
}
export default HeadContents
