import { BuildingBlock, MediaPlayer } from '@careplan/type'
import { useLayoutEffect, useRef, useState } from 'react'

import { SEEKER, VOLUME } from '@careplan/constants'
import _ from 'lodash'
import ReactPlayer from 'react-player/lazy'

interface ComponentProps {
    playerState: MediaPlayer,
    type: 'audio' | 'video'
    buildingBlock: BuildingBlock
    playerRef: {
        key: string;
        player: ReactPlayer | null;
    } | undefined
    mapKey: string,
}

const ReadOnlyPlayer = ({
    playerState, type, buildingBlock,
    playerRef, mapKey
}: ComponentProps) => {
    const seekerInputRef = useRef<HTMLInputElement | null>(null)
    const volumeInputRef = useRef<HTMLInputElement | null>(null)

    const [backgroundSize, setBackgroundSize] = useState<string | null>(null)

    useLayoutEffect(() => {
        if (seekerInputRef.current) {
            const target = seekerInputRef.current
            if (target) {
                const min = parseFloat(target.min)
                const max = parseFloat(target.max)
                const val = parseFloat(target.value)
                setBackgroundSize((val - min) * 100 / (max - min) + '% 100%')
            }
        }
    })

    return <div>
        <div className={[
            'media-wrapper',
            type === 'audio' ? 'd-none' : 'react-player'
        ].join(' ')}>
            <ReactPlayer
                playsinline
                config={{
                    file: {
                        attributes: {
                            // not sure if camelcase or not so i did both
                            // disableremoteplayback: true,
                            disableRemotePlayback: true
                        }
                    }
                }}
                ref={(e) => {
                    // set if it doesn't exist.
                    if (!(playerRef)) {
                        // set react-player to useRef
                        // console.log('Setting new audio player ref.')

                        playerRef = {
                            key: mapKey,
                            player: e
                        }
                    }
                }}
                // hide the component only because a default width and height is assigned.
                className={type === 'audio' ? 'd-none' : 'react-player'}
                url={_.isString(buildingBlock.buildingBlockValue)
                    ? buildingBlock.buildingBlockValue
                    : ''}
                // no more controls for any media type.
                controls={false}
                playing={playerState?.data.playing}
                loop={playerState?.data.looped}
                volume={playerState?.data.volume}
                muted={playerState?.data.muted}
                width={'100%'}
                height={'100%'}
            />
        </div>
        <div className={`media-wrapper pt-0 ${ type } shadow`}>
            {
                <div className={'player'}>
                    <input
                        type={'range'}
                        min={SEEKER.MIN}
                        max={SEEKER.MAX}
                        step={'any'}
                        value={playerState?.data.played || 0.00}
                        ref={(e) => {
                            seekerInputRef.current = e
                        }}
                        style={{
                            backgroundSize: backgroundSize as any
                        }}

                    />
                    <div className={'align-items-center row justify-content-between g-0'}>
                        <div className={
                            'col-auto play-button'
                        }>
                            {playerState?.data.playing
                                ? <i className={'fa-light fa-pause'} aria-hidden={'true'} />
                                : <i className={'fa-light fa-play'} aria-hidden={'true'} />
                            }
                        </div>
                        <div className={'col d-flex gap-2 justify-content-end'}>
                            <div className={'align-items-center row g-1'}>
                                <div className={'col-auto volume-button'}>
                                    {/* if muted, change icon */}
                                    {
                                        playerState?.data.muted
                                            ? <i className={'fa-light fa-volume-xmark'}
                                                aria-hidden={'true'} />
                                            : <i className={'fa-light fa-volume-high'}
                                                aria-hidden={'true'} />
                                    }
                                </div>
                                <div className={'col-auto'}>
                                    <input
                                        className={'volume-control'}
                                        type={'range'}
                                        min={VOLUME.MIN}
                                        max={VOLUME.MAX}
                                        step={'any'}
                                        defaultValue={playerState?.data.volume || 0.00}
                                        ref={(e) => {
                                            volumeInputRef.current = e
                                        }}
                                    />
                                </div>
                            </div>
                            {type === 'video' && (<div className={
                                'fullscreen-button'
                            } >
                                <i className={'fa-light fa-expand-wide'}
                                    aria-hidden={'true'} />
                            </div>)}

                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}

export default ReadOnlyPlayer
