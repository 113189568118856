import { LoginState } from '@login/type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@app/app.store'

const initialState: LoginState = {
    tokenForMFA: '',
    tokenForForcePasswordChange: '',
    isFullscreenOverlay: {
        show: false,
        text: ''
    }
}

export const slice = createSlice({
    name: 'loginV10',
    initialState,
    /** jsdoc annotations are done in the reducers themselves since the state,action parameters
     *  have been defined by redux) */
    /** adding payloadAction helps actions with data types */
    reducers: {
        setTokenForMFA: (state: LoginState, action: PayloadAction<
            LoginState['tokenForMFA']
        >) => {
            state.tokenForMFA = action.payload
        },
        setTokenForForcePasswordChange: (state: LoginState, action: PayloadAction<
            LoginState['tokenForForcePasswordChange']
        >) => {
            state.tokenForForcePasswordChange = action.payload
        },
        setIsFullscreenOverlay: (state: LoginState, action: PayloadAction<
            LoginState['isFullscreenOverlay']
        >) => {
            state.isFullscreenOverlay = action.payload
        }
    }
})

/**
 * actions to export from slice
 */
// eslint-disable-next-line no-empty-pattern
export const {
    setTokenForMFA,
    setTokenForForcePasswordChange,
    setIsFullscreenOverlay
} = slice.actions
export default slice.reducer

export const selectTokenForMFA = (state: RootState) => state.loginV10.tokenForMFA
export const selectTokenForForcePasswordChange = (state: RootState) => state
    .loginV10.tokenForForcePasswordChange
export const selectIsFullscreenOverlay = (state: RootState) => state.loginV10.isFullscreenOverlay
