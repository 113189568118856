import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { findModuleAll } from '@app/app.method'
import { selectStrings } from '@app/slices/slice.app'
import { SystemModule } from '@login/type'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

export const useValidateModuleAll = () => {
    const strings = useAppSelector(selectStrings)
    const callback = useCallback((arr: SystemModule[],
        module: string, showToast: boolean) => {
        if (arr.length) {
            const foundModule = findModuleAll(
                arr, module
            )

            // NOTE: not all need to show a toast error.
            // only do this error toast method AFTER authentication.
            if (foundModule) {
                return foundModule
            } else {
                if (strings.login?.message.error.module_denied && showToast) {
                    const message = [
                        module,
                        ':',
                        strings.login?.message.error.module_denied
                    ].join(' ')
                    toast.error(
                        message,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
                    console.error(message)
                }
            }
        }

        return undefined
    }, [strings])

    return callback
}
