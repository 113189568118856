import { RootState } from '@app/app.store'
import { ContentState, ReactPlayerControlState } from '@careplan/type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const getFromLocalStorage: () => ContentState = () => {
    return {
        mediaPlayers: []
    }
}

const initialState: ContentState = getFromLocalStorage()

export const slice = createSlice({
    name: 'careplanv10',
    initialState,
    reducers: {
        setSelectedCareplan: (state: ContentState, action: PayloadAction<
            ContentState['selectedCareplan']
        >) => {
            state.selectedCareplan = action.payload
        },
        setMediaPlayer: (state: ContentState, action: PayloadAction<{
            key: string,
            item: ReactPlayerControlState
        }>) => {
            let found = _.find(state.mediaPlayers, (obj) => {
                return obj.key === action.payload.key
            })

            if (!found) {
                // console.log('Data with specified key does not exists. Will push.')
                state.mediaPlayers.push({
                    key: action.payload.key,
                    data: action.payload.item
                })
            } else {
                // this case SHOULD only be met when invoked. not ideally done in a ref.
                // console.log('Data with specified key already exists. Will overwrite.')
                found = {
                    key: action.payload.key,
                    data: action.payload.item
                }
            }
        },
        removeMediaPlayer: (state: ContentState, action: PayloadAction<{
            key: string
        }>) => {
            _.remove(state.mediaPlayers, mediaPlayer => {
                return (
                    mediaPlayer.key === action.payload.key
                )
            })
        },
        // taken from react-player demo so there's going to be a lot of dispatches.
        setSeeking: (state: ContentState, action: PayloadAction<{
            key: string, seeking: boolean
        }>) => {
            const found = _.find(state.mediaPlayers, (obj) => {
                return obj.key === action.payload.key
            })

            if (found) {
                found.data.seeking = action.payload.seeking
            }
        },
        setPlaying: (state: ContentState, action: PayloadAction<{
            key: string, playing: boolean
        }>) => {
            const found = _.find(state.mediaPlayers, (obj) => {
                return obj.key === action.payload.key
            })

            if (found) {
                // console.log('playing media')
                found.data.playing = action.payload.playing
            }
        },
        setPlayed: (state: ContentState, action: PayloadAction<{
            key: string, played: number
        }>) => {
            const found = _.find(state.mediaPlayers, (obj) => {
                return obj.key === action.payload.key
            })

            if (found) {
                found.data.played = action.payload.played
            }
        },
        setLooped: (state: ContentState, action: PayloadAction<{
            key: string, looped: boolean
        }>) => {
            const found = _.find(state.mediaPlayers, (obj) => {
                return obj.key === action.payload.key
            })

            if (found) {
                found.data.looped = action.payload.looped
            }
        },
        setVolume: (state: ContentState, action: PayloadAction<{
            key: string, volume: number
        }>) => {
            const found = _.find(state.mediaPlayers, (obj) => {
                return obj.key === action.payload.key
            })

            if (found) {
                found.data.volume = action.payload.volume
            }
        },
        setMuted: (state: ContentState, action: PayloadAction<{
            key: string, muted: boolean
        }>) => {
            const found = _.find(state.mediaPlayers, (obj) => {
                return obj.key === action.payload.key
            })

            if (found) {
                found.data.muted = action.payload.muted
            }
        },
        removeAllMediaPlayers: (state: ContentState) => {
            state.mediaPlayers = []
        },
        resetCareplan: (state: ContentState) => {
            state.mediaPlayers = []
        }
    }
})

export const {
    setMediaPlayer,
    setPlayed,
    setSeeking,
    setPlaying,
    setLooped,
    setMuted,
    setVolume,
    removeAllMediaPlayers,
    resetCareplan,
    removeMediaPlayer,
    setSelectedCareplan
} = slice.actions

export default slice.reducer

export const selectMediaPlayers = (state: RootState) => state.careplanV10.mediaPlayers
export const selectSelectedCareplan = (state: RootState) => state.careplanV10
    . selectedCareplan
