import { useEffect } from 'react'

import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { TokenData } from '@app/types/type.token'
import { useGetUserTreatmentsMutation } from '@doc/api'

import { MODULE_TABLE } from '@app/app.config'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import _ from 'lodash'
import { toast } from 'react-toastify'

// eslint-disable-next-line max-len
import OperationDateSelection from '@doc/components/patients/add/steps/StepThree/OperationDateSelection'
// eslint-disable-next-line max-len
// eslint-disable-next-line max-len
import SelectCarepathDropdown from '@doc/components/patients/add/steps/StepThree/SelectCarepathDropdown'
import { selectPatientMenu } from '@doc/slice'
import { DepartmentCareFacilitiesAction, DepartmentCareFacilitiesState } from '@doc/type'

interface ComponentProps {
    departmentCareFacilitiesState: DepartmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction: React.Dispatch<DepartmentCareFacilitiesAction>
}
const StepThree = ({
    departmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction
}: ComponentProps) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const patientMenu = useAppSelector(selectPatientMenu)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const [
        getUserTreatments,
        getUserTreatmentsMutation
    ] = useGetUserTreatmentsMutation()
    // Use useReducer

    const carepaths = getUserTreatmentsMutation.data?.data.carepaths || []

    const unsubscribeGetData = () => {
        const unsubscribeMutation = getUserTreatments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        unsubscribeGetData()

        let getTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getUserTreatments.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getTreatmentsPromise = getUserTreatments({
                            authToken: newToken.value,
                            data: {
                                userId: patientMenu.addInterfaceProps.userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getTreatmentsPromise && getTreatmentsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        if (getUserTreatmentsMutation.error) {
            const message = getErrorText(getUserTreatmentsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getUserTreatmentsMutation.error])

    const StepThreeContent = <>
        <h2 className={'mb-5 fw-semibold '} >{strings.doc?.text.patient
            .add_interface.stepIndicators['3']}</h2>
        <SelectCarepathDropdown
            data={carepaths}
            departmentCareFacilitiesState={departmentCareFacilitiesState}
            dispatchDepartmentCareFacilitiesAction={dispatchDepartmentCareFacilitiesAction}
        />
        <OperationDateSelection
            departmentCareFacilitiesState={departmentCareFacilitiesState}
            dispatchDepartmentCareFacilitiesAction={dispatchDepartmentCareFacilitiesAction}
        />
    </>

    const result = <div id={'step-three-content'}>
        {StepThreeContent}
    </div>
    return <>{result}</>
}

export default StepThree
