import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import NewDatePicker from '@app/components/NewDatePicker'
import { selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { TokenData } from '@app/types/type.token'
import AbnormalitiesTable from '@doc/components/careprofessionals/AbnormalitiesTable'
import HCPCards from '@doc/components/careprofessionals/HCPCards'
import TreatedPatientsTable from '@doc/components/careprofessionals/TreatedPatientsTable'
import UpcomingSurgeryTable from '@doc/components/careprofessionals/UpcomingSurgeryTable'
import { IDS } from '@doc/constants'
import {
    AbnormalitiesActions,
    AbnormalitiesState,
    GetHCPCardsResponse,
    GetOverallAbnormalitiesResponse,
    GetPatientsUpcomingSurgeryResponse,
    GetTreatedPatientsResponse,
    TreatedPatientsActions,
    TreatedPatientsState,
    UpcomingSurgeryActions,
    UpcomingSurgeryState
} from '@doc/type'
import { fromUnixTime, getUnixTime } from 'date-fns'
import { useMemo, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import { DebouncedState } from 'use-debounce'

interface ComponentProps {
    floatingUiBoundary: HTMLDivElement | null
    fetchGetPatientData: (token: TokenData) => () => void
    debouncedFetchUpcomingSurgery: DebouncedState<() => void>
    debouncedFetchAbnormalities: DebouncedState<() => void>
    debouncedFetchTreatedPatients: DebouncedState<() => void>
    getOverallAbnormalitiesResponse: {
        isLoading: boolean;
        isSuccess: boolean;
        response: GetOverallAbnormalitiesResponse | undefined;
        abnormalitiesState: AbnormalitiesState
        abnormalitiesDispatch: React.Dispatch<AbnormalitiesActions>

    }
    getPatientsUpcomingSurgeryResponse: {
        isLoading: boolean;
        isSuccess: boolean;
        response: GetPatientsUpcomingSurgeryResponse | undefined;
        upcomingSurgeryState: UpcomingSurgeryState
        upcomingSurgeryDispatch: React.Dispatch<UpcomingSurgeryActions>
    }
    getTreatedPatientsResponse: {
        isLoading: boolean;
        isSuccess: boolean;
        response: GetTreatedPatientsResponse | undefined;
        treatedPatientsState: TreatedPatientsState
        treatedPatientsDispatch: React.Dispatch<TreatedPatientsActions>
    }
    getHCPCardsResponse: {
        total:{
        isLoading: boolean;
        isSuccess: boolean;
        response: GetHCPCardsResponse | undefined;
    },
        careplan:{
        isLoading: boolean;
        isSuccess: boolean;
        response: GetHCPCardsResponse | undefined;
    },
        progress:{
        isLoading: boolean;
        isSuccess: boolean;
        response: GetHCPCardsResponse | undefined;
    }
    }
}
const PatientsDirectory = ({
    floatingUiBoundary,
    fetchGetPatientData, debouncedFetchAbnormalities,
    debouncedFetchUpcomingSurgery,
    debouncedFetchTreatedPatients,
    getOverallAbnormalitiesResponse,
    getPatientsUpcomingSurgeryResponse,
    getTreatedPatientsResponse, getHCPCardsResponse
}: ComponentProps) => {
    const [showOffcanvas, setShowOffcanvas] = useState(false)
    const rootRef = useRef<HTMLDivElement | null>(null)
    const strings = useAppSelector(selectStrings)
    // 1,2,4
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })
    const dateFormats = useAppSelector(selectDateFormats)
    const offCanvas = useMemo(() => {
        const StartDate = <div className={'form-group'}>
            <label className={'form-label'} htmlFor={IDS.CAREPROF_DASHBOARD.ABNORMALITIES.START}>
                {strings.doc?.text.careprofessionals.patients_directory.date_range.from}
            </label>
            <NewDatePicker
                // also removed. users want both
                // removeIcon={true}
                id={IDS.CAREPROF_DASHBOARD.ABNORMALITIES.START}
                isDate={(date) => {
                    getOverallAbnormalitiesResponse.abnormalitiesDispatch({
                        type: 'SET_DATE_RANGE',
                        value: {
                            dateValue: getUnixTime(date),
                            key: 'start'
                        }
                    })
                }}
                singleDate={fromUnixTime(getOverallAbnormalitiesResponse.abnormalitiesState
                    .dateRange.start)}
                dateType={''}
                timeFormat={dateFormats.format5}
            />
        </div>

        const EndDate = <div className={'form-group'}>
            <label className={'form-label'} htmlFor={IDS.CAREPROF_DASHBOARD.ABNORMALITIES.END}>
                {strings.doc?.text.careprofessionals.patients_directory.date_range.to}
            </label>
            <div className={'form-control-wrapper'} id={'date-wrapper'}>
                <NewDatePicker
                    // removeIcon={true}
                    id={IDS.CAREPROF_DASHBOARD.ABNORMALITIES.END}
                    isDate={(date) => {
                        getOverallAbnormalitiesResponse.abnormalitiesDispatch({
                            type: 'SET_DATE_RANGE',
                            value: {
                                dateValue: getUnixTime(date),
                                key: 'end'
                            }
                        })
                    }}
                    singleDate={fromUnixTime(getOverallAbnormalitiesResponse.abnormalitiesState
                        .dateRange.end)}
                    dateType={''}
                    timeFormat={dateFormats.format5}
                />
            </div>
        </div>

        return <Offcanvas
            fade={true}
            container={rootRef}
            isOpen={showOffcanvas}
            direction={'end'}
            toggle={() => {
                setShowOffcanvas(!showOffcanvas)
            }}
        >
            <OffcanvasHeader
                className={'align-items-center offcanvas-header'}
                tag={'div'}
                close={ <button
                    type={'button'}
                    className={
                        'border btn btn-round  offcanvas-trigger shadow-none'
                    }
                    onClick={() => {
                        setShowOffcanvas(!showOffcanvas)
                    }}
                >
                    <i className={'fa-light fa-xmark'} aria-hidden={'true'}></i>
                </button>}
                toggle={() => {
                    setShowOffcanvas(!showOffcanvas)
                }}
            >

                <h2 id={'offcanvas-header-label mb-0'}>
                    {strings.doc?.text.careprofessionals.patients_directory.offcanvas.name}
                </h2>

            </OffcanvasHeader>
            <OffcanvasBody className={'d-flex flex-column'}>
                <div >
                    <div className={'mb-4'} role={'group'}>

                        <small className={'d-block fw-bold mb-2'}>
                            {strings.doc?.text.careprofessionals
                                .patients_directory.offcanvas.period}
                        </small>

                        <div className={['row', isMobile ? 'g-3' : ''].join(' ')}>
                            <div className={isMobile ? 'col-12' : 'col-12'}>
                                {StartDate}
                            </div>
                            <div className={isMobile ? 'col-12' : 'col-12'}>
                                {EndDate}
                            </div>
                        </div>

                        <div className={'mt-3'}>

                            <div className={isMobile ? 'row g-3' : 'row mx-2'}>
                                <div className={isMobile ? 'col-12' : 'col-12'}>
                                    <button type={'button'} className={[
                                        'btn btn-primary btn-lg  mx-auto d-block h-100'
                                    ].join(' ')}
                                    onClick={() => {
                                        setShowOffcanvas(!showOffcanvas)
                                        // then fetch the data again
                                        debouncedFetchAbnormalities()
                                    }}>
                                        {strings.doc?.text.careprofessionals
                                            .patients_directory.offcanvas.apply_filters}
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    }, undefined)

    return <div className={''}>
        <HCPCards fetchGetPatientData={fetchGetPatientData}
            floatingUiBoundary={floatingUiBoundary}
            getHCPCardsResponse={getHCPCardsResponse} />
        <div className={'row g-3'}>
            <div className={'col'}>
                {<AbnormalitiesTable
                    debouncedFetchAbnormalities={debouncedFetchAbnormalities}
                    showOffcanvas={showOffcanvas}
                    setShowOffcanvas={setShowOffcanvas}
                    rootRef={rootRef}
                    getOverallAbnormalitiesResponse={getOverallAbnormalitiesResponse}
                />}
                {offCanvas}
            </div>
        </div>
        <div className={'g-3 mt-3 mb-4 row row-cols-xl-2 rows-col-1'}>
            <div className={'col'}>
                {<TreatedPatientsTable
                    debouncedFetchTreatedPatients={debouncedFetchTreatedPatients}
                    rootRef={rootRef}
                    getTreatedPatientsResponse={getTreatedPatientsResponse}
                />}
            </div>
            <div className={'col'}>
                {<UpcomingSurgeryTable
                    debouncedFetchUpcomingSurgery={debouncedFetchUpcomingSurgery}
                    rootRef={rootRef}
                    getPatientsUpcomingSurgeryResponse={getPatientsUpcomingSurgeryResponse}
                />}
            </div>
        </div>

    </div>
}

export default PatientsDirectory
