
import { MOBILE_RESPONSIVE_LIMIT, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText, readTime } from '@app/app.method'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    useCompleteCareplanProgressMutation,
    useUpdateCareplanProgressMutation
} from '@careplan/api'
import { ImageLightbox } from '@careplan/components/ImageLightbox'
import ListRoutine from '@careplan/components/ListRoutine'
import Player from '@careplan/components/Player'
import QuestionInterface from '@careplan/components/careplanStep/reasoningCard/QuestionInterface'
import RwmModal from '@careplan/components/careplanStep/reasoningCard/RwmModal'
import { MODULE_VERSION, UPDATE_PROGRESS_INTERVAL, VOLUME } from '@careplan/constants'
import { selectMediaPlayers, setMediaPlayer } from '@careplan/slice'
import {
    BuildingBlock,
    BuildingBlockHeader,
    BuildingBlockProgress,
    BuildingBlockProgressActions,
    BuildingBlockProgressState,
    CompleteCareplanProgressResponse,
    PersonalCareplanData,
    RwmCardActions,
    RwmCardState
} from '@careplan/type'
import { back, replace } from '@lagunovsky/redux-react-router'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import {
    FooterButtons,
    HeaderContent,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/careplanStep/Components'

import { format, fromUnixTime } from 'date-fns'
import produce from 'immer'
import _ from 'lodash'
import { ReactElement, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { InView } from 'react-intersection-observer'
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player/lazy'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import { remarkDefinitionList } from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'

import { MODULE_TABLE } from '@app/app.config'
import { selectRouter } from '@app/app.store'
import { useElementSize } from '@app/hooks/useElementSize'
import LinkArticle from '@careplan/components/LinkArticle'
import { useMediaQuery } from 'react-responsive'

const LessonDetails = ({
    careplan,
    personalCareplanId,
    personalCareplanStepId,
    setStatus,
    reasoningSetId,
    data,
    isLoading,
    isSuccess,
    error
}: {
    careplan: PersonalCareplanData,
    personalCareplanId: string,
    personalCareplanStepId: string | undefined,
        reasoningSetId: {
        incomplete: string;
        complete: string;
      } | undefined,
    setStatus: CompleteCareplanProgressResponse['data']['setStatus']
    data:PersonalCareplanData | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}) => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const rootRef = useRef<HTMLDivElement | null>(null)

    const activeModules = useAppSelector(selectActiveModules)
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    // to get the react-player's ref. Will try to use map now.
    const mediaPlayersRef = useRef<{
        key: string,
        player: ReactPlayer | null
    }[]>([])
    const mediaPlayerState = useAppSelector(selectMediaPlayers)
    const [updateCareplanProgress] = useUpdateCareplanProgressMutation()
    const [completeCareplanProgress, completeCareplanProgressMutation] =
    useCompleteCareplanProgressMutation()
    const token = useAppSelector(selectToken)

    const dateFormats = useAppSelector(selectDateFormats)
    const router = useAppSelector(selectRouter)
    const validateRoute = useValidateRoute()
    const validateAPIPath = useValidateAPIPath()
    // variables for incomplete carestep plan operations.
    const [startIncompleteCareplan, setStartIncompleteCareplan] = useState<boolean>(false)
    const [startCompleteCareplan, setStartCompleteCareplan] = useState<boolean>(false)
    const [showSmallOverlay, setShowSmallOverlay] = useState<boolean>(false)

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const [mainContentRef, { width: mainContentWidth }] = useElementSize({
        debounce: 100
    })

    /** record all reasoning/card building blocks so when we render the modal
     * we won't be able to lose the instance because they were created from the very beginning
     */
    const [rwmCardState, rwmCardDispatch] = useReducer(
        (state: RwmCardState, action: RwmCardActions) => {
            switch (action.type) {
                case 'SET_DATA': {
                    return produce(state, draft => {
                        _.forEach(action.value, (obj) => {
                            const id = obj.buildingBlockId
                            if (!_.find(draft.arr,
                                (o) => o.buildingBlockId === id)
                            ) {
                                draft.arr.push(obj)
                            }
                        })
                    })
                }
                case 'UPDATE_IS_OPEN': {
                    return produce(state, draft => {
                        const found = _.find(draft.arr, o => {
                            return (
                                o.buildingBlockId === action.value.buildingBlockId
                            )
                        })

                        if (found) {
                            found.isOpen = action.value.isOpen
                        }
                    })
                }
            }
        }
        , {
            arr: []
        }
    )

    const [progressState, progressDispatch] = useReducer(
        (state: BuildingBlockProgressState, action: BuildingBlockProgressActions) => {
            switch (action.type) {
                case 'SET_DATA': {
                    return produce(state, draft => {
                        // yes you could do this but I prefer that you don't
                        // remove the address of the array that was initialized
                        // prior to mount. Instead, just push elements if the building block
                        // content id isn't found.

                        // you can add a dispatch to clear the contents of the array.

                        _.forEach(action.value, (obj) => {
                            const id = obj.buildingBlockId
                            if (!_.find(draft.progress,
                                (o) => o.buildingBlockId === id)
                            ) {
                                draft.progress.push(obj)
                            }
                        })
                    })
                } case 'UPDATE_ACTIVE_PROGRESS': {
                    return produce(state, draft => {
                        const found = _.find(draft.progress, o => {
                            return (
                                o.buildingBlockId === action.value.buildingBlockId
                            )
                        })

                        if (found) {
                            found.activeProgress = action.value.activeProgress
                        }
                    })
                } case 'UPDATE_PAUSE': {
                    return produce(state, draft => {
                        const found = _.find(draft.progress, o => {
                            return (
                                o.buildingBlockId === action.value.buildingBlockId
                            )
                        })

                        if (found) {
                            found.isPaused = action.value.isPaused
                        }
                    })
                } case 'CREATE_TIME_NEEDED': {
                    return produce(state, draft => {
                        const found = _.find(draft.progress, o => {
                            return (
                                o.buildingBlockId === action.value.buildingBlockId
                            )
                        })

                        if (found) {
                            found.timeNeeded = action.value.timeNeeded
                        }
                    })
                }
            }
        }, {
            progress: []
        }
    )

    /** UPDATE: if the progress is 100 AND/OR
     * if the stepState is complete, this function should do
     * nothing.
     *
     * To deal with intervals using this method, add a dependency
     * called: completeCareplanProgressMutation.isSuccess
     */
    const updateBuildingBlockProgress = async (
        requestData: {
            obj: BuildingBlockProgress, percentage: number, miscVar?: any
        }
    ) => {
        if (
            // note that this is initial data.
            careplan.stepState === 'complete' ||
            // careplan.stepState === 'incomplete' ||
            // (careplan.progress?.percentComplete || 0) >= 100 ||
            // now if completeCareplanProgressMutation.isSuccess is true.
            // or if you do not complete the call.
            completeCareplanProgressMutation.isSuccess === true
        ) {
            return
        }

        const found = _.find(data?.progress?.careplanProgress || [], (o) => {
            return o.buildingBlockId === requestData.obj.buildingBlockId
        })

        const isValid = validateAPIPath(
            activeModules.arr,
            MODULE_TABLE.careplanPatient.moduleName,
            MODULE_TABLE.careplanPatient.apiPaths.updateCareplanProgress.path,
            true
        )

        // don't make the call IF the progress is already 100.
        if (isValid && (found?.careplanStepProgress || 0) < 100) {
            updateCareplanProgress({
                authToken: token.value,
                personalCareplanStepId: personalCareplanStepId || '',
                data: {
                    buildingBlockId: requestData.obj.buildingBlockId,
                    percentComplete: requestData.percentage,
                    miscVar: requestData.miscVar
                }
            }).unwrap().then((data) => {
                if (data.status === 'OK') {
                    // toast success message.
                    // toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })

                    // update the timer because the api call succeeded.
                    progressDispatch({
                        type: 'UPDATE_ACTIVE_PROGRESS',
                        value: {
                            buildingBlockId: requestData.obj.buildingBlockId,
                            activeProgress: requestData.percentage
                        }
                    })

                    // idk if I should be updating the initialTimer.
                    // we'll leave it alone.

                    // the complete button will be enabled IF all lessons are complete.
                } else {
                    toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                }
            }).catch((error) => {
                if (error) {
                    const message = getErrorText(error)
                    console.error(message)
                    toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    // if the call fails, immediately clear the interval.
                    // console.log(`error thrown in failed call. pausing timer
                    // so that the intervals will not be called anymore.`.trim())
                    progressDispatch({
                        type: 'UPDATE_PAUSE',
                        value: {
                            buildingBlockId: requestData.obj.buildingBlockId,
                            isPaused: true
                        }
                    })
                }
            })
        } else {
            if ((found?.careplanStepProgress || 0) >= 100) {
                console.info(strings.careplanPatient?.message.error.building_block_already_complete)
            }
        }
    }

    /** create fetch data function */

    /** set lesson contents */
    useEffect(() => {
        const theContent = data?.theContent?.theContent || []

        if (data) {
            progressDispatch({
                type: 'SET_DATA',
                value: _.map(theContent, (a) => {
                    const foundProgress = _.find(data?.progress?.careplanProgress,
                        (o) => o.buildingBlockId === a.buildingBlockId
                    )

                    const automaticComplete = [
                        'image/content', 'image/header', 'image/thumbnail'
                    ]

                    // for the ff building block types, set the activeProgress to 100:
                    // 'image/content' 'image/header' 'image/thumbnail'
                    return {
                        buildingBlockId: a.buildingBlockId,
                        // a reference of what th current progress was before monitoring
                        // any of the activity contents. Useful for seeking the players
                        // to guide them on where they left off.
                        initialProgress: foundProgress?.careplanStepProgress || 0,
                        // the intervals or whatever can't proceed if these
                        // numbers are zero.
                        activeProgress: automaticComplete.includes(a.buildingBlockType)
                            ? 100
                            : foundProgress?.careplanStepProgress || 0,
                        timeNeeded: 0,
                        // default timer is set to true and will change based on render.
                        isPaused: true,
                        // you can only start AND clear an interval so don't make it
                        // just yet. When the isPausedTimer boolean is set to true,
                        // clear it, otherwise, create one again.
                        /** added because of list/routine. remains optional */
                        miscVar: foundProgress?.miscVar
                    }
                })
            })
        } else {
            console.log("getPersonalCareplan hasn't been initialized")
        }
    }, [data])

    // creating a cleanup function for the useEffect that has the
    // progressState.progress as a dependency.
    // on the cleanup function, clear all intervals before component unmounts.
    // another useEffect to deal with submitting interval data.
    useEffect(() => {
        // my concern is that if you play around with
        // the video player for example, the interval creation
        // is just gonna keep resetting and never reach the limit.
        // If you try to use setTimeout, the function will still run
        // even after unmount and cannot be cleared especially on multiple
        // lifecycle runs.
        const intervalStorage: any[] = []

        /** if you start updating the careplan progress to either complete
         * or incomplete, all intervals MUST be cleared until the call
         * is finished loading.
         */

        !completeCareplanProgressMutation.isLoading && _.forEach(progressState.progress, (obj) => {
            // let's get the building block. Btw, we don't need
            // to add it as a dependency since you can't have
            // progressState.progress without it.
            const lessonContent = data?.theContent?.theContent || []

            const found = _.find(lessonContent, (o) => {
                return o.buildingBlockId === obj.buildingBlockId
            })

            // if isPausedTimer === false AND if activeTimer < 100,
            // create new interval. This only applies to contentType: 1
            // which is text. Will add more if necessary.
            if (
                found?.buildingBlockType === 'text/plain' ||
                found?.buildingBlockType === 'text/markdown'
            ) {
                if (obj.isPaused === false) {
                    if (
                        // because we need this to be 100%
                        // but we're gonna need the timeNeeded property
                        obj.timeNeeded > 0 &&
                        obj.activeProgress < 100
                    ) {
                        // console.log("create delayed function.")
                        const createInterval = setInterval(() => {
                            // step 1: turn activeProgress into a number
                            const activeProgressInNumber = obj.timeNeeded *
                            (obj.activeProgress / 100)

                            const incrementedProgress = activeProgressInNumber +
                                UPDATE_PROGRESS_INTERVAL

                            let percentage = (incrementedProgress / (obj.timeNeeded || 1)) * 100
                            // eslint-disable-next-line no-unused-vars
                            percentage = Math.max(0, Math.min(percentage, 100))

                            updateBuildingBlockProgress({
                                obj,
                                percentage
                            })
                        }, UPDATE_PROGRESS_INTERVAL)

                        intervalStorage.push(createInterval)
                    } else if (
                        obj.activeProgress >= 100
                    ) {
                        console.log('This goal has been cleared.'.trim())
                    }
                }
                // if isPausedTimer is true, do nothing.
            }
        })

        // cleanup function to clear intervals.

        return () => {
            _.forEach(intervalStorage, (interval) => {
                clearInterval(interval)
            })
        }
    }, [
        progressState.progress,
        updateBuildingBlockProgress,
        completeCareplanProgressMutation.isLoading
    ])

    /** don't render the players unless a mediaPlayerState is set */
    useEffect(() => {
        // get all pieces that are audio or video.

        if (data?.theContent?.theContent) {
            const filtered = _.filter(data?.theContent?.theContent || [],
                (buildingBlock) => {
                    return buildingBlock.buildingBlockType === 'audio/content' ||
                    buildingBlock.buildingBlockType === 'video/content'
                }
            )

            _.forEach(filtered, (buildingBlock) => {
                const foundProgress = _.find(data?.progress?.careplanProgress,
                    (o) => o.buildingBlockId === buildingBlock.buildingBlockId
                )

                dispatch(setMediaPlayer({
                    key: buildingBlock.buildingBlockId,
                    item: {
                        seeking: false,
                        played: (foundProgress?.careplanStepProgress || 0) / 100,
                        looped: false,
                        playing: false,
                        volume: VOLUME.MAX,
                        muted: false
                    }
                }))
            })
        }
    }, [data])

    const hasDuplicateId = (data: BuildingBlock[]) => {
        const idSet = new Set()
        for (const item of data) {
            if (idSet.has(item.buildingBlockId)) {
                return true
            }
            idSet.add(item.buildingBlockId)
        }
        return false
    }

    /** get all reasoning/card pieces */
    useEffect(() => {
        // get all pieces that are audio or video.

        if (hasDuplicateId(data?.theContent?.theContent || [])) {
            toast.error('Duplicate IDs found', { ...TOASTIFY_DEFAULT_OPTIONS })
        }

        if (data?.theContent?.theContent) {
            const filtered = _.filter(data?.theContent?.theContent || [],
                (buildingBlock) => {
                    return buildingBlock.buildingBlockType === 'reasoning/card'
                }
            )

            const arr: RwmCardState['arr'] = _.map(filtered, (o) => {
                return {
                    buildingBlockId: o.buildingBlockId,
                    isOpen: false
                }
            })

            rwmCardDispatch({
                type: 'SET_DATA',
                value: arr
            })
        }
    }, [data])

    const BuildingBlocks = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.careplanPatient?.message.loading.detailed_step || ''
                }</span>
            </div>
        )

        const renderContent = (buildingBlock: BuildingBlock, i: number, arr: BuildingBlock[]) => {
            // backend flaw where buildingBlockId could be similar.

            const key = hasDuplicateId(arr) ? i.toString() : buildingBlock.buildingBlockId

            let result: ReactElement<any, any> = <></>
            let printHr = true

            const progress = _.find(progressState.progress, (obj) => {
                return obj.buildingBlockId === buildingBlock.buildingBlockId
            })

            const contentType = buildingBlock.buildingBlockType

            const inViewEvent: (inView: boolean,
                entry: IntersectionObserverEntry) => void = (inView) => {
                    // if this component was created this way and
                    // not through another function, the inView component
                    // will not be rerendered. Even if you have another
                    // div as a parent, it will still be safe.
                    // console.log('onChange should be called on mount and if out of view.')

                    progressDispatch({
                        type: 'UPDATE_PAUSE',
                        value: {
                            buildingBlockId: buildingBlock.buildingBlockId,
                            isPaused: !inView
                        }
                    })

                    progressDispatch({
                        type: 'CREATE_TIME_NEEDED',
                        value: {
                            buildingBlockId: buildingBlock.buildingBlockId,
                            timeNeeded: _.isString(buildingBlock.buildingBlockValue)
                                ? readTime(buildingBlock.buildingBlockValue)
                                : 0
                        }
                    })
                }

            if (progress) {
                switch (buildingBlock.buildingBlockType) {
                    case 'text/plain':
                        result = <span>
                            {_.isString(buildingBlock.buildingBlockValue)
                                ? buildingBlock.buildingBlockValue
                                : ''}
                        </span>
                        break
                    case 'text/markdown':{
                        const forMarkdownContent = _.isString(buildingBlock.buildingBlockValue)
                            ? buildingBlock.buildingBlockValue
                                .replace(/==([^=]+)==/g, '<mark>$1</mark>')
                                .replace(/~(\d+)~/g, '<sub>$1</sub>')
                                .replace(/~~([^~]+)~~/g, '<s>$1</s>')
                            : ''

                        const MonitoringProgress = <InView
                            root={rootRef.current}
                            as={'div'}
                            onChange={inViewEvent}
                        >
                            <div className={'markdown'}>
                                {/* use workaround for  == to <mark></mark> */}
                                <ReactMarkdown
                                    components={{
                                        // supersub replaces markdown with del tags
                                        // for somereason.
                                        del: (props) => <sub {...props} />,
                                        ul: (props) => {
                                            const modifiedProps = { ...props }
                                            // eslint-disable-next-line react/prop-types
                                            modifiedProps.ordered = props.ordered.toString() as any

                                            if (modifiedProps.className && modifiedProps.className
                                                .includes('contains-task-list')) {
                                                return <ul
                                                    {...modifiedProps}
                                                    className={[
                                                        'contains-task-list list-unstyled ps-4'
                                                    ].join(' ')}
                                                />
                                            } else {
                                                return <ul
                                                    {...modifiedProps}

                                                />
                                            }
                                        }
                                    }}
                                    linkTarget={'_blank'}
                                    remarkPlugins={[
                                        remarkBreaks,
                                        remarkGfm,
                                        supersub,
                                        remarkEmoji,
                                        remarkDefinitionList,
                                        remarkHeadingId
                                    ]} rehypePlugins={[
                                        rehypeRaw
                                    ]}
                                >
                                    {`${ forMarkdownContent }`}
                                </ReactMarkdown>
                            </div>
                        </InView>

                        result = MonitoringProgress
                        break
                    }
                    case 'audio/content':
                    case 'video/content': {
                    // Display the media player.

                        // get mediaPlayer state.
                        const playerState = _.find(mediaPlayerState, (obj) => {
                            return obj.key === key
                        })

                        // get mediaPlayer ref.
                        const playerRef = _.find(mediaPlayersRef.current, (obj) => {
                            return obj.key === key
                        })

                        if (playerState) {
                            result = <Player
                                playerState={playerState}
                                type={contentType === 'audio/content'
                                    ? 'audio'
                                    : 'video'}
                                buildingBlock={buildingBlock}
                                playerRef={playerRef}
                                mapKey={key}
                                progressState={progressState}
                                progressDispatch={progressDispatch}
                                personalCareplanStepId={personalCareplanStepId}
                                buildingBlockProgress={progress}
                                updateBuildingBlockProgress={updateBuildingBlockProgress}

                            />
                        }

                        break
                    }

                    case 'image/content': {
                        result = <div className={'text-center'}>
                            <ImageLightbox imageUrl={
                                _.isString(buildingBlock.buildingBlockValue)
                                    ? buildingBlock.buildingBlockValue
                                    : ''
                            } onLoad={() => {
                            // it loaded so time to mark as 100.
                                updateBuildingBlockProgress({
                                    obj: progress,
                                    percentage: 100
                                })
                            }} />
                        </div>
                        break
                    }

                    case 'reasoning/card': {
                        // so now it should be a card
                        // when you click on the card,
                        // an overlay shows up and the rwm starts.

                        // get mediaPlayer state.
                        const foundCard = _.find(rwmCardState.arr, (obj) => {
                            return obj.buildingBlockId === buildingBlock.buildingBlockId
                        })

                        result = foundCard
                            ? <RwmModal
                                personalCareplanId={personalCareplanId}
                                progressDispatch={progressDispatch}
                                buildingBlock={buildingBlock}
                                buildingBlockProgress={progress}
                                rwmCard={foundCard}
                                rwmCardDispatch={rwmCardDispatch}
                                updateProgress={updateBuildingBlockProgress}
                            />
                            : <></>
                        break
                    }

                    case 'reasoning/set': {
                        result = <QuestionInterface
                            personalCareplanId={personalCareplanId}
                            personalCareplanStepId={personalCareplanStepId || ''}
                            progressDispatch={progressDispatch}
                            data={{
                                reasoningSetId: _.isString(buildingBlock.buildingBlockValue)
                                    ? buildingBlock.buildingBlockValue
                                    : '',
                                reasoningSetVersion: 0.1
                            }}
                            buildingBlockProgress={progress}
                            updateProgress={updateBuildingBlockProgress}
                            isModal={false}
                        />
                        break
                    }

                    // do schedule.
                    case 'list/routine': {
                        result = <ListRoutine
                            buildingBlock={buildingBlock}
                            buildingBlockProgress={progress}
                            updateBuildingBlockProgress={updateBuildingBlockProgress}
                        />
                        break
                    }

                    case 'link/article': {
                        result = <LinkArticle
                            buildingBlock={buildingBlock}
                            progressState={progressState}
                            updateBuildingBlockProgress={updateBuildingBlockProgress}
                        />

                        break
                    }

                    default: printHr = false
                }
            }
            return <div key={key} className={[
                'mt-3', buildingBlock.buildingBlockType
            ].join(' ')}>
                {result}
                {/* {printHr && <hr/>} */}
                {printHr && <></>}
            </div>
        }

        const content = _.map(
            _.sortBy(
                data?.theContent?.theContent || [], 'buildingBlockOrder'
            ), renderContent
        )

        return (
            isLoading
                ? LoadingContent
                : isSuccess
                    ? content
                    : JSON.stringify(error)
        )
    }, [
        strings,
        data,
        mediaPlayerState,
        progressState.progress,
        rwmCardState,
        updateBuildingBlockProgress
    ])

    // when buttons are clicked in the incomplete/complete buttons,
    // show a modal saying that they can't do anything about it.

    const IncompleteButton = useMemo(() => {
        const buttonContent = isLoading
            ? (
                <small className={'d-block text-center py-2'}>
                    <div className={'spinner-container'}>
                        <span
                            className={'spinner-border spinner-border-sm'}
                        ></span>
                        <span className={'ms-2'}>{
                            strings.app?.text.submitting || ''
                        }</span>
                    </div>
                </small>
            )
            // if the rwm card is already incomplete OR if the operation
            // was already done AND if the originalArgs was incomplete
            // then use another string.
            : (completeCareplanProgressMutation.isSuccess === true &&
                completeCareplanProgressMutation.originalArgs
                    ?.data.completeType === 'incomplete') ||
                careplan.stepState === 'incomplete'
                ? strings.careplanPatient?.text.careplanStep.lesson.resume_incomplete_rwm
                : strings.careplanPatient?.text.careplanStep.lesson.no || ''

        const sum = _.reduce(progressState.progress, (sum, o) => {
            sum = sum + o.activeProgress
            return sum
        }, 0)
        // if the state is already complete or was complete after a call,
        // disable this button.
        let isDisabled = false

        switch (true) {
            case (
                completeCareplanProgressMutation.isSuccess === true &&
                completeCareplanProgressMutation.originalArgs?.data.completeType === 'complete'
            ):
            case (careplan.stepState === 'complete'):
            case (
                setStatus.incomplete.enableOn !== 0 &&
                (sum / progressState.progress.length) < setStatus.incomplete.enableOn
            ):
            case (
                setStatus.incomplete.disableOn !== null &&
                setStatus.incomplete.disableOn >= (sum / progressState.progress.length)
            ):
                isDisabled = true
                break
        }
        const buttonClassName = [
            'btn btn-secondary btn-lg w-100',
            isDisabled ? 'disabled' : 'clickable',
            isMobile ? 'px3 py-2' : ''
        ].join(' ')

        const notCompleteButton = <a className={buttonClassName}
            onClick={async () => {
                if (!isDisabled) {
                    // show rwm modal right away.
                    setStartIncompleteCareplan(true)
                }
            }}
        >
            {buttonContent}
        </a>
        return setStatus.incomplete.isVisible
            ? <div className={'col-auto'}>
                <div onClick={() => {
                    if (isDisabled === true) {
                        setShowSmallOverlay(true)
                    }
                }}>
                    {notCompleteButton}
                </div>
            </div>
            : <></>
    }, [
        data, progressState.progress, strings,
        completeCareplanProgressMutation, isMobile, setStatus
    ])

    const CompleteButton = useMemo(() => {
        const buttonContent = isLoading
            ? (
                <small className={'d-block text-center py-2'}>
                    <div className={'spinner-container'}>
                        <span
                            className={'spinner-border spinner-border-sm'}
                        ></span>
                        <span className={'ms-2'}>{
                            strings.app?.text.submitting || ''
                        }</span>
                    </div>
                </small>
            )
            : strings.careplanPatient?.text.careplanStep.lesson.yes || ''

        // disable the button if the progress isn't complete.
        const sum = _.reduce(progressState.progress, (sum, o) => {
            sum = sum + o.activeProgress
            return sum
        }, 0)

        const progressFromResponse = data?.progress?.careplanProgress || []

        const initialSum = _.reduce(progressFromResponse, (sum, o) => {
            sum = sum + o.careplanStepProgress
            return sum
        }, 0)

        // const isDisabled = (sum / progressState.progress.length) < 100
        // const isDisabled = (sum / progressState.progress.length) < 30
        // const isDisabled = (sum / progressState.progress.length) < 0
        let isDisabled = false

        if (
            setStatus.complete.enableOn !== 0 &&
            (sum / progressState.progress.length) < setStatus.complete.enableOn
        ) {
            isDisabled = true
        }

        if (
            !isDisabled &&
            setStatus.complete.disableOn !== null &&
            setStatus.complete.disableOn >= (sum / progressState.progress.length)
        ) {
            isDisabled = true
        }

        // we are checking the completeLessonMutation data and NOT the progressState.
        // but only render this button if the operation is also marked as complete.
        const alreadyComplete = (
            (initialSum / (
                progressFromResponse || ['']
            // ).length) >= 100
            ).length) >= 30
        ) && (
            // check either the card or the status of completeCareplanProgress operation.
            (completeCareplanProgressMutation.isSuccess === true &&
            completeCareplanProgressMutation.originalArgs?.data.completeType === 'complete') ||
            careplan.stepState === 'complete'
        )

        const buttonClassName = [
            'btn btn-primary btn-lg w-100',
            isDisabled ? 'disabled' : 'clickable',
            isMobile ? 'px3 py-2' : ''
        ].join(' ')

        const notCompleteButton = <a className={buttonClassName}
            onClick={async () => {
                if (personalCareplanStepId) {
                    setStartCompleteCareplan(true)
                }
            }}
        >
            {buttonContent}
        </a>

        const alreadyCompleteButton = <a className={`${ buttonClassName } my-3 disabled`}>
            {strings.careplanPatient?.text.careplanStep.lesson.already_complete}
        </a>

        return setStatus.complete.isVisible
            ? <div className={'col-auto'}>
                <div onClick={() => {
                    if (isDisabled === true) {
                        setShowSmallOverlay(true)
                    }
                }}>
                    {!alreadyComplete
                        ? notCompleteButton
                        : alreadyCompleteButton}
                </div>
            </div>
            : <></>
    }, [
        data, progressState.progress, strings,
        completeCareplanProgressMutation, isMobile, setStatus
    ])

    /** if image/header is found, replace the image at the back. */
    const HeaderImageMain = useMemo(() => {
        const found = _.find(data?.theContent?.theContent, (buildingBlock) => {
            return buildingBlock.buildingBlockType === 'image/header'
        })

        const buildingBlockHeader = (found?.buildingBlockValue
            ? found.buildingBlockValue
            : {
                imageUrl: '',
                xCoord: 0,
                yCoord: 0
            }) as BuildingBlockHeader

        const img = buildingBlockHeader.imageUrl
            ? buildingBlockHeader.imageUrl
            : data?.images?.header || ''

        return <>
            <HeaderImage
                url={img}
                backgroundPositionX={buildingBlockHeader.xCoord}
                backgroundPositionY={buildingBlockHeader.yCoord}
            >
            </HeaderImage>
            <HeaderGradient />
            <HeaderContent
                className={'p-4'}
            >
                <button
                    type={'button'}
                    className={[
                        'btn btn-round btn-rounded'
                    ].join('')}
                    onClick={(e) => {
                        e.preventDefault()

                        if (router.location.key === 'default') {
                            console.log('route key is default. going to user specified route')
                            // will use replace and then back to the route.
                            const isValid = validateRoute(
                                activeModules.arr,
                                MODULE_TABLE.careplanPatient.moduleName,
                                MODULE_TABLE.careplanPatient.routes.today,
                                true
                            )
                            if (isValid) {
                                // now push but replace :personalCareplanId with something else
                                dispatch(replace(MODULE_TABLE.careplanPatient.routes.today))
                            }
                        } else {
                            dispatch(back())
                        }
                    }}
                >
                    <i
                        className={[
                            'fa-light',
                            'fa-arrow-left'
                        ].join(' ')}
                        aria-hidden={'true'}>
                    </i>
                </button>
            </HeaderContent>
        </>
    }, [data])

    // startIncompleteCareplan modal goes here.
    const incompleteRWMForm = useMemo(() => {
        return reasoningSetId?.incomplete && <Modal
            size={'lg'}
            unmountOnClose={false}
            isOpen={startIncompleteCareplan} toggle={() => {
                setStartIncompleteCareplan(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                setStartIncompleteCareplan(false)
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    setStartIncompleteCareplan(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <QuestionInterface
                    personalCareplanId={personalCareplanId}
                    personalCareplanStepId={personalCareplanStepId || ''}
                    progressDispatch={progressDispatch}
                    data={{
                        reasoningSetId: reasoningSetId?.incomplete,
                        reasoningSetVersion: 0.1
                    }}
                    fromIncompleteOperation={{
                        setStartIncompleteCareplan,
                        personalCareplanId,
                        completeCareplanProgress
                    }}
                    isModal={true}
                />
            </ModalBody>
        </Modal>
    }, [
        startIncompleteCareplan,
        completeCareplanProgressMutation.data
    ])

    const completeRWMForm = useMemo(() => {
        return reasoningSetId?.complete && <Modal
            size={'lg'}
            unmountOnClose={false}
            isOpen={startCompleteCareplan} toggle={() => {
                setStartCompleteCareplan(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                setStartCompleteCareplan(false)
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    setStartCompleteCareplan(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <QuestionInterface
                    personalCareplanId={personalCareplanId}
                    personalCareplanStepId={personalCareplanStepId || ''}
                    progressDispatch={progressDispatch}
                    data={{
                        reasoningSetId: reasoningSetId?.complete,
                        reasoningSetVersion: 0.1
                    }}
                    fromCompleteOperation={{
                        setStartCompleteCareplan,
                        personalCareplanId,
                        completeCareplanProgress
                    }}
                    isModal={true}
                />
            </ModalBody>
        </Modal>
    }, [
        startCompleteCareplan,
        completeCareplanProgressMutation.data
    ])

    const smallModal = useMemo(() => {
        return <Modal
            unmountOnClose={false}
            isOpen={showSmallOverlay} toggle={() => {
                setShowSmallOverlay(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                setShowSmallOverlay(false)
            // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    setShowSmallOverlay(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                {strings.careplanPatient?.text.careplanStep.cant_click_on_disabled_button}
            </ModalBody>
        </Modal>
    }, [showSmallOverlay])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    let stepTypeString = ''

    if (data?.stepType === 'activity') {
        stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.activity || ''
    } else if (data?.stepType === 'content') {
        stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.content || ''
    } else if (data?.stepType === 'lesson') {
        stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.lesson || ''
    } else if (data?.stepType === 'uncertain') {
        stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.uncertain || ''
    } else if (data?.stepType === 'reasoning') {
        stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.reasoning || ''
    }

    const desktopResult = <>
        {HeaderImageMain}

        <div className={'main-content'} ref={(e) => {
            if (!isMobile) {
                return mainContentRef(e)
            }
        }}>
            {/* container with a col width and then set to center */}

            <div className={'content-container'}>
                <div className={'align-items-center justify-content-between meta-info row'}>
                    <div className={'col-auto'}>
                        {/* d-inline-block */}
                        <small className={[
                            'fw-bold content-type',
                            'd-inline-block',
                            data?.stepType
                        ].join(' ')}>
                            {stepTypeString}
                        </small>
                        <small className={[
                            'd-inline-block ms-3'
                        ].join(' ')}>
                            <i className={'fa-light fa-clock'} aria-hidden={'true'}>
                            </i>
                            <span className={'d-inline-block ms-2'}>
                                {strings.careplanPatient?.text.to_do_on}
                            </span>
                            <span className={'d-inline-block ms-2'}>
                                {format(fromUnixTime(
                                    data?.doneWhen || 0
                                ), dateFormats.format1) || ''}
                            </span>
                        </small>
                    </div>
                    <div className={'col-auto'}>
                        {/* bookmark button exists in article page. */}
                    </div>
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-auto'}>
                        <h1>
                            {data?.theContent?.title}
                        </h1>
                    </div>
                </div>
                {BuildingBlocks}

                {incompleteRWMForm}
                {completeRWMForm}
                {smallModal}

            </div>

            <FooterButtons

                width={mainContentWidth}
                className={' position-fixed container-fluid'}
            >
                <div className={'align-items-center justify-content-center ' +
                    'row complete-buttons'}>
                    {!data || _.keys(data).length <= 0 ? '' : IncompleteButton}
                    {!data || _.keys(data).length <= 0 ? '' : CompleteButton}
                </div>

            </FooterButtons>
        </div>
        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        {HeaderImageMain}

        <div className={'main-content'} ref={(e) => {
            if (isMobile) {
                return mainContentRef(e)
            }
        }}>
            <div className={'content-container'}>
                <div className={'align-items-center justify-content-between meta-info row'}>
                    <div className={'col-auto'}>
                        {/* d-inline-block */}
                        <small className={[
                            'fw-bold content-type',
                            'd-inline-block',
                            data?.stepType
                        ].join(' ')}>
                            {stepTypeString}
                        </small>
                        <small className={[
                            'd-inline-block ms-3'
                        ].join(' ')}>
                            <i className={'fa-light fa-clock'} aria-hidden={'true'}>
                            </i>
                            <span className={'d-inline-block ms-2'}>
                                {strings.careplanPatient?.text.to_do_on}
                            </span>
                            <span className={'d-inline-block ms-2'}>
                                {format(fromUnixTime(
                                    data?.doneWhen || 0
                                ), dateFormats.format1) || ''}
                            </span>
                        </small>
                    </div>
                    <div className={'col-auto'}>
                        {/* bookmark button exists in article page. */}
                    </div>
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-auto'}>
                        <h1>
                            {data?.theContent?.title}
                        </h1>
                    </div>
                </div>
                {BuildingBlocks}

                {incompleteRWMForm}
                {completeRWMForm}
                {smallModal}

            </div>

            <FooterButtons

                width={mainContentWidth}
                className={' position-fixed container-fluid'}
            >
                <div className={'align-items-center justify-content-center g-3 ' +
                    'row complete-buttons'}>
                    {!data || _.keys(data).length <= 0 ? '' : IncompleteButton}
                    {!data || _.keys(data).length <= 0 ? '' : CompleteButton}
                </div>

            </FooterButtons>
        </div>
    </>

    return <div className={'careplan-step-page position-relative'}>
        {/* the container */}

        {
            isMobile ? mobileResult : desktopResult
        }

    </div>
}

export default LessonDetails
