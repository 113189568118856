import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import Color from 'color'
import { css, useTheme } from '@emotion/react'
// import _ from 'lodash'

const GlobalStyle = () => {
    const theme = useTheme()

    return css`
        .profile-page {

            & .main-content {

                .success-message {
                    color: ${ theme.config.endUser?.profile?.mainContent?.successMessage };
                }

                & .img-wrapper {
                    position: relative;
                    width: 8.5rem;
                }
                & .img-wrapper .btn {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                & .card {
                    box-shadow: 0px 2px 10px 0px ${
                        (() => {
                            const color = Color(theme.config.endUser?.profile
                                ?.mainContent?.card?.boxShadowColor)
                            return 'rgba(' + [color.rgb().array()[0],
                                color.rgb().array()[1],
                                color.rgb().array()[2],
                                '0.05'
                            ].join(', ') + ')'
                        })()
                    };
                }

                .dropdown-list {
                    .search-box {
                        background-color: ${ theme.config.endUser?.profile.mainContent
                        .dropdownList.searchBox.backgroundColor };
                    }
                }

            }

            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    width: 100%;
                    padding-top: 260px;
                }
            }

            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                & .header {
                    height: 58px;
                    
                    & img {
                        width: 98px;
                        margin-block-start: -5px;
                    }
                }
                
                & .main-content {
                    width: 100%;
                    padding-top: 0px;
                    margin-block-end: 6em;

                }


            }

            }

            .my-therapeutics-page {

            & .main-content {
                & .card {
                    box-shadow: 0px 2px 10px 0px ${
                        (() => {
                            const color = Color(theme.config.endUser?.profile?.mainContent?.card?.boxShadowColor)

                            return 'rgba(' + [
                                color.rgb().array()[0],
                                color.rgb().array()[1],
                                color.rgb().array()[2],
                                '0.05'
                            ].join(', ') + ')'
                        })()
                    };

                    &.complete, &.inactive {
                        opacity: 0.5;
                    }
                }

                .cards-container {
                    max-width: 1280px;
                    /* width: 100%; */
                }
            }


            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    width: 100%;
                    padding-top: 260px;
                }
            }

            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                & .header {
                    height: 58px;
                    
                    & img {
                        width: 98px;
                        margin-block-start: -5px;
                    }
                }
                
                & .main-content {
                    width: 100%;
                    padding-top: 0px;
                    margin-block-end: 6em;

                }


            }

            }

            .my-careteam-page {
            & .main-content {

                & .img-wrapper {
                    position: relative;
                    width: 8.5rem;
                }
                & .img-wrapper .btn {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                & .card {

                    height: 100%;

                    box-shadow: 0px 2px 10px 0px ${
                       ((props) => {
                           const color = Color(theme.config.endUser?.profile?.mainContent?.card?.boxShadowColor)

                           return 'rgba(' + [
                               color.rgb().array()[0],
                               color.rgb().array()[1],
                               color.rgb().array()[2],
                               '0.05'
                           ].join(', ') + ')'
                       })()
                    };
                }

            }

                    
            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    width: 100%;
                    padding-top: 260px;

                }
            }

            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                & .header {
                    height: 58px;
                    
                    & img {
                        width: 98px;
                        margin-block-start: -5px;
                    }
                }
                
                & .main-content {
                    width: 100%;
                    padding-top: 0px;
                    margin-block-end: 6em;

                }


            }
            }

            .account-settings-page {

            & .main-content {

                & .submit-button {
                    max-width: 350px;
                    width: 100%;
                }

                padding-top: 260px;

                & .img-wrapper {
                    position: relative;
                    width: 8.5rem;
                }
                & .img-wrapper .btn {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                
                .question-checkmark,
                .question-plussign,
                .question-unchecked {
                    min-height: 1.5rem;
                    min-width: 1.5rem;
                    max-height: 1.5rem;
                    max-width: 1.5rem;
                }

                & .card {

                    box-shadow: 0px 2px 10px 0px ${
                        ((props) => {
                            const color = Color(theme.config.endUser?.profile?.mainContent?.card?.boxShadowColor)

                            return 'rgba(' + [
                                color.rgb().array()[0],
                                color.rgb().array()[1],
                                color.rgb().array()[2],
                                '0.05'
                            ].join(', ') + ')'
                        })()
                    };
                }

            }

                    
            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    width: 100%;
                    padding-top: 260px;

                }
            }

            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                & .header {
                    height: 58px;
                    
                    & img {
                        width: 98px;
                        margin-block-start: -5px;

                    }
                }
                
                & .main-content {
                    width: 100%;
                    padding-top: 0px;
                    margin-block-end: 6em;

                    .form-label {
                        font-size: 0.87em;
                    }

                    /* for all types even search */
                    & .form-group {
                        & .form-control {  
                            padding: 0.55rem 1rem 0.55em 1rem;
                        }
                    }

                    & .react-datepicker-wrapper {
                        & .form-control {  
                            padding: 0.55rem 1rem 0.55em 2.75rem;
                        }

                        & .form-floaticon {
                            top: calc(calc(1.3125em + 2.125rem) / 2.3);
                        }
                    }

                    & .form-group {
                        & div.form-control-wrapper.icon-end {
                            & .form-control {  
                                padding: 0.55rem 1rem 0.55em 1rem;
                            }

                            & .form-floaticon {
                                top: calc(calc(1.3125em + 2.125rem) / 2.3);
                            }
                        }
                    }

                }


            }

        }
    `
}

export default GlobalStyle
