
import { QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo } from 'react'

interface ComponentProps {
    ids:{
        activeOptionId: string,
        questionId: string,
        answerIndex: number
    }
    obj:{
        question: QuestionAnswer & {
            id: string;
        };
    }
    answer: {
        hasAnswer: boolean;
        actualValue: any;
    }
}

const Open = ({
    ids,
    obj,
    answer
}: ComponentProps) => {
    // useEffect(() => {
    //     console.log('input box newly rendered because of key change')
    // }, [])

    const input = useMemo(() => {
        const questionAnswers = obj.question?.answerQuestions
            ?.[ids.answerIndex].questionAnswers || []
        // const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        return <div>
            <input
                className={'form-control form-control-sm'}
                placeholder={_.has(questionAnswers[0], 'answerName')
                    ? questionAnswers[0]?.answerName
                    : ''}
                value={answer.actualValue}
                onChange={(e) => {
                }}
                onBlur={(e) => {
                }}
            />
        </div>
    }, [obj, answer])

    return <div className={'col px-4'}>
        <div className={'card multi-question-card'}>
            <div className={'card-body'}>
                {
                    obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                        ? <h5>
                            {
                                obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                            }
                        </h5>
                        : ''
                }

                {
                    obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                        ? <span className={'mb-2'}>
                            {
                                obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                            }
                        </span>
                        : ''
                }
                {input}
            </div>
        </div>
    </div>
}

export default Open
