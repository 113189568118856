import { OpenListActions, OpenListInputs, OpenListValues } from '@reasonWithMe/type'
import _ from 'lodash'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
    findPreviousInput: any,
    formsDispatch: (value: OpenListActions) => void
    form: OpenListValues
}

const InputList = ({
    answerType, outerIndex, innerIndex,
    formInput, findPreviousInput, formsDispatch, form
}: ComponentProps) => {
    // const prevInputStrings: string[] = (findPreviousInput
    //     ?.userTypedAnswer || []) as string[]

    const inputArr = formInput
        .userTypedAnswer as string[]

    const inputList = _.map(inputArr, (inputString,
        inputIndex) => {
        // const isDisabled = prevInputStrings
        //     .includes(inputString)

        const isDisabled = (findPreviousInput &&
                                 !findPreviousInput?.answerEditable) ||
                            !formInput.answerEditable

        const showButtons = !isDisabled && (<>
            {
                inputArr.length > 1 && (
                    <div className={'col-auto'}>
                        <div className={'question-plussign ms-auto'}
                            onClick={() => {
                                const newArray = [...inputArr]

                                newArray.splice(inputIndex, 1)

                                formsDispatch({
                                    type: 'UPDATE_FORM',
                                    id: form.id,
                                    columnOrder: Number(formInput
                                        .answerValue || 0),
                                    value: newArray
                                })
                            }}>
                            <i className={'fa-light fa-minus mx-auto'}></i>
                        </div>

                    </div>
                )
            }

            {
                inputIndex === inputArr.length - 1 && (
                    <div className={'col-auto'}>
                        <div className={'question-plussign ms-auto'}
                            onClick={() => {
                                formsDispatch({
                                    type: 'UPDATE_FORM',
                                    id: form.id,
                                    columnOrder: Number(formInput
                                        .answerValue || 0),
                                    value: [
                                        ...inputArr,
                                        ''
                                    ]
                                })
                            }}>
                            <i
                                className={'fa-light fa-plus mx-auto'}></i>
                        </div>

                    </div>
                )
            }
        </>)
        return <li key={[
            'input-cell', outerIndex, innerIndex, inputIndex
        ].join('-')}
        className={'align-middle text-center mb-4'}>
            <div className={'row align-items-center'}>
                <div className={'col'}>
                    <input
                        className={'form-control form-control-sm'}
                        type={'text'}
                        defaultValue={inputString}
                        disabled={isDisabled}
                        onChange={(e) => {
                            const newArray = [...inputArr]
                            newArray[inputIndex] = e.target.value
                            formsDispatch({
                                type: 'UPDATE_FORM',
                                id: form.id,
                                columnOrder: Number(formInput
                                    .answerValue || 0),
                                value: newArray
                            })
                        }}
                        onBlur={(e) => {
                            const newArray = [...inputArr]
                            newArray[inputIndex] = e.target.value
                            formsDispatch({
                                type: 'UPDATE_FORM',
                                id: form.id,
                                columnOrder: Number(formInput
                                    .answerValue || 0),
                                value: newArray
                            })
                        }}
                    />

                </div>

                {showButtons}

            </div>

        </li>
    })

    return <ol type={'a'} className={'mb-0'}>
        {
            inputList
        }
    </ol>
}

export default InputList
