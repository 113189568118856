import React, { useReducer } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import _ from 'lodash'

import AccordionComponent from '@fmt/components/treatments/AccordionComponent'

import { GetTreatmentsResponse, Treatment } from '@fmt/type'

import { smartSearch } from '@app/app.method'

import { MODULE_TABLE } from '@app/app.config'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import produce from 'immer'

import Pagination from '@app/components/Pagination'
import { TokenData } from '@app/types/type.token'
import { DETAILS_ROW_LIMIT } from '@fmt/constants'
import { push } from '@lagunovsky/redux-react-router'

interface ComponentProps {
    mode: 'EDIT' | 'VIEW',
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    fetchData: (token: TokenData) => () => void
    responseData?: GetTreatmentsResponse
}

const TreatmentsTable = ({
    mode, search, setSearch, currentPage, setCurrentPage, fetchData, responseData
}: ComponentProps) => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    /** for data table */
    // Initialize state using useReducer
    const [accordions, accordionsDispatch] = useReducer((state: {
        id: string;
        isOpen: boolean;
      }[], action: { type: 'PUSH_ID'; payload: { id: string } }
      | { type: 'TOGGLE_ISOPEN'; payload: { id: string, isOpen: boolean } }) => {
        switch (action.type) {
            case 'PUSH_ID':
                return produce(state, draft => {
                    const existingAccordion = draft.find((item) => item.id === action.payload.id)
                    if (!existingAccordion) {
                        draft.push({
                            id: action.payload.id,
                            isOpen: true
                        })
                    }
                })

            case 'TOGGLE_ISOPEN':
                return produce(state, draft => {
                    const existingAccordion = draft.find((item) => item.id === action.payload.id)
                    if (existingAccordion) {
                        existingAccordion.isOpen = action.payload.isOpen
                    }
                })

            default:
                return state
        }
    },
    [])

    const filteredData = smartSearch(responseData?.data.treatments || []
        , [], search) as Treatment[]

    const isTableEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const table = <div className={'col-12 record-menu mt-5'}>
        <table>
            <thead>
                <tr>
                    <th>
                        <input type={'checkbox'} className={'form-check-input'}/>
                    </th>
                    {/* <i class="fa-solid fa-angles-up-down fa-rotate-180"></i> */}
                    <th>
                        <span className={'me-2'}>{strings.fmt?.text.department.menu.name}</span>
                        <i className={'fa-solid fa-angles-up-down'}></i>
                    </th>
                    <th colSpan={2}>{strings.fmt?.text
                        .facility.menu.table.action}</th>
                </tr>
            </thead>
            <tbody>
                {
                    filteredData.length
                        ? _.map(filteredData, (obj) => {
                            const key = obj.treatmentId

                            const found = _.find(accordions, (o) => {
                                return o.id === obj.treatmentId
                            })

                            return <React.Fragment key={key}>
                                <tr onClick={() => {
                                    if (found) {
                                        accordionsDispatch({
                                            type: 'TOGGLE_ISOPEN',
                                            payload: {
                                                id: obj.treatmentId,
                                                isOpen: !found.isOpen
                                            }
                                        })
                                    }

                                    accordionsDispatch({
                                        type: 'PUSH_ID',
                                        payload: {
                                            id: obj.treatmentId
                                        }
                                    })
                                }}>
                                    <td>
                                        <input type={'checkbox'} className={'form-check-input'}/>
                                    </td>
                                    <td className={'accordion'}>
                                        <u className={'me-2'}>{obj.treatmentName}</u>
                                        <i className={
                                            [
                                                'fa-light',
                                                found?.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                                            ].join(' ')
                                        }></i>
                                    </td>
                                    <td className={'cursor-pointer'} onClick={(e) => {
                                        e.stopPropagation()

                                        const isValid = validateRoute(
                                            activeModules.arr,
                                            MODULE_TABLE.fmt.moduleName,
                                            MODULE_TABLE.fmt.routes.viewTreatment,
                                            true
                                        )

                                        dispatch(push(
                                            _.replace(isValid.route,
                                                ':treatmentId',
                                                obj.treatmentId
                                            )
                                        ))
                                    }}><i className={'fa-light fa-eye'}></i></td>
                                    <td className={'cursor-pointer'} onClick={(e) => {
                                        e.stopPropagation()

                                        const isValid = validateRoute(
                                            activeModules.arr,
                                            MODULE_TABLE.fmt.moduleName,
                                            MODULE_TABLE.fmt.routes.editTreatment,
                                            true
                                        )

                                        dispatch(push(
                                            _.replace(isValid.route,
                                                ':treatmentId',
                                                obj.treatmentId
                                            )
                                        ))
                                    }}><i className={'fa-light fa-edit'}></i></td>
                                </tr>
                                {<tr className={`accordion ${ !found?.isOpen ? 'd-none' : '' }`}>
                                    <td colSpan={8}>
                                        <AccordionComponent mode={mode}
                                            isOpen={found?.isOpen} obj={obj}
                                            fetchData={fetchData}/>
                                    </td>
                                </tr>}
                            </React.Fragment>
                        })
                        : <tr><td colSpan={7}>{isTableEmpty}</td></tr>
                }
            </tbody>
        </table>
    </div>

    return <>
        <div className={'row'}>
            {/* <div className={'row h-100 flex-column'}> */}
            <div className={'col-12'}>
                <div className={['row justify-content-between ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box'}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className={'col-auto pe-0'}>
                        <button
                            className={['btn ',
                                'rounded-square me-2'].join(' ')}
                        >
                            <i className={'fa-light fa-filter'}></i>
                        </button>
                        <button className={['btn ',
                            'rounded-square'].join(' ')}>
                            <i className={'fa-light fa-download'}></i>
                        </button>
                    </div>
                </div>
            </div>
            {table}
            {
                (responseData?.data.totalRecords || 1) > (responseData
                    ?.data.limit || DETAILS_ROW_LIMIT) && (
                    <div className={'container-fluid pb-4 pt-6 px-3'}>
                        <div className={'row justify-content-center'}>
                            <div className={'col-auto'}>
                                <Pagination
                                    currentPage={currentPage}
                                    setCurrentPageState={setCurrentPage}
                                    limit={responseData?.data.limit || responseData
                                        ?.data.totalRecords || 1}
                                    skip={responseData?.data.skip || 1}
                                    totalRecords={responseData
                                        ?.data.totalRecords || 1}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    </>
}

export default TreatmentsTable
