import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { BuildingBlock, ListRoutineState } from '@careplan/type'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useMemo, useReducer } from 'react'
// import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid'
const ReadOnlyListRoutine = ({
    buildingBlock,
    buildingBlockProgress
}: {
    buildingBlock: BuildingBlock,
    buildingBlockProgress?:{
        buildingBlockId: string;
        updatedOn: number;
        careplanStepProgress: number;
        miscVar: any;
    } | undefined
}) => {
    const strings = useAppSelector(selectStrings)

    const [routineState, routineDispatch] = useReducer(
        (state: ListRoutineState, action: { type: 'SET_DATA', value: ListRoutineState['arr'] }) => {
            switch (action.type) {
                case 'SET_DATA': {
                    return produce(state, draft => {
                        draft.arr = action.value
                    })
                }
            }
        }, {
            arr: []
        }
    )

    useEffect(() => {
        let arr: ListRoutineState['arr'] = []

        if (_.isArray(buildingBlock.buildingBlockValue)) {
            // check if the progress has miscVar.
            // if it does, get that instead of making a new one.

            // should be the ListRoutineState['arr']
            arr = _.map(buildingBlock.buildingBlockValue, (exercise) => {
                const repetitions = exercise.setRepetition

                const findSet = _.find(buildingBlockProgress
                    ?.miscVar, (o) => {
                    return o.setId === exercise.setId
                }) as ListRoutineState['arr'][0] | undefined

                if (findSet) {
                    return findSet
                } else {
                    return {
                        ...exercise,
                        repetitions: _.times(repetitions, (i) => {
                            return {
                                isChecked: false,
                                repetitionId: uuidv4()
                            }
                        })
                    }
                }
            })
        }

        console.log(arr)

        // should be a 2d array.
        routineDispatch({
            type: 'SET_DATA',
            value: arr
        })
    }, [buildingBlock])

    const choices = useMemo(() => {
        // should be 2d
        return _.map(routineState.arr, (set, outerIndex) => {
            return _.map(set.repetitions, (repetition, innerIndex) => {
                const key = `btncheckset-${ outerIndex }-${ innerIndex }`

                const checkbox = <input type={'checkbox'}
                    checked={repetition.isChecked} className={'btn-check'} id={key}
                    onChange={() => {}}
                />

                const cardBody = <div className={'card-body'}>
                    <div className={'d-flex align-items-center'}>
                        <div className={'question-unchecked'}></div>
                        <div className={'question-checkmark'}>
                            <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                        </div>
                        <div className={'p fw-bold mx-3'}>
                            {set.setName}
                        </div>
                        <small className={'set-value'}>{
                            [
                                set.setValue,
                                strings.careplanPatient?.text.careplanStep.routine.times
                            ].join(' ')
                        }
                        </small>

                    </div>
                </div>
                return <div className={'col'} key={key}>
                    {checkbox}
                    <label className={'btn btn-dummy'} htmlFor={key}>
                        <div className={'question-checkbox'}>
                            <div className={'card justify-content-center px-3'}>
                                {cardBody}
                            </div>
                        </div>
                    </label>
                </div>
            })
        })
    }, [routineState])

    return <div>
        <div className={'col-12 col-md-8 col-lg-6 me-auto mb-4'}>
            <div className={'row row-cols-1 btn-group-checkbox-set'} role={'group'}>
                {choices}
            </div>
        </div>
    </div>
}

export default ReadOnlyListRoutine
