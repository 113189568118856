import { useAppSelector } from '@app/app.hook'
import { ADMIN_COLORS as IKH_ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { ADMIN_COLORS as RTW_ADMIN_COLORS } from '@stylesheet/brands/rtw/admin/Colors'

import { selectStylesheets } from '@stylesheet/slice'
import { Chart, ChartOptions, LegendOptions, TitleOptions, TooltipOptions } from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { useEffect, useRef } from 'react'
import { Options } from 'chartjs-plugin-datalabels/types/options'

import { selectToken } from '@app/slices/slice.token'
import Color from 'color'
import _ from 'lodash'

/** two bugs to fix. change the font styles of the ticks.
 * when you hover over the edge data points, the tooltip either gets cropped out
 * or the tooltip keeps line breaking.
 */

/**
 * A simple component that creates and renders a Chart.js bar chart. IS IT?!
 */

type ActiveChartType = Chart<'line', number[]>;

interface ComponentProps {
    arr:number[],
    color: string
}

const LineChart = ({
    arr, color
}: ComponentProps) => {
    // Reference to the canvas element where the chart will be rendered
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    // Store the chart instance in a ref for later access and manipulation
    const chartInstanceRef = useRef<ActiveChartType | null>(null)

    const stylesheets = useAppSelector(selectStylesheets)
    const token = useAppSelector(selectToken)

    useEffect(() => {
        // Data and options for the chart

        let borderColor = ''
        let backgroundColor = ''
        let pointBorderColor = ''
        let pointBackgroundColor = ''

        // sheet to use.
        let sheetToUse = IKH_ADMIN_COLORS

        if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
            sheetToUse = IKH_ADMIN_COLORS
        } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
            sheetToUse = RTW_ADMIN_COLORS
        }

        let colorToUse = sheetToUse.green

        if (color === 'green') {
            colorToUse = sheetToUse.green
        } else if (color === 'red') {
            colorToUse = sheetToUse.red
        }

        backgroundColor = Color(colorToUse).alpha(0.2).rgb().toString()
        borderColor = Color(colorToUse).alpha(0.5).rgb().toString()
        pointBorderColor = colorToUse
        pointBackgroundColor = colorToUse

        console.log('arr is: ', arr)

        console.log('color is: ', colorToUse)

        const legendPlugin: | _DeepPartialObject<LegendOptions<'line'>> | undefined = {
            display: false
        }

        const titlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            display: false

        }

        const subtitlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            display: false
        }

        const tooltipPlugin: | _DeepPartialObject<TooltipOptions<'line'>>| undefined = {
            enabled: false
        }

        const dataLabelsPlugin: _DeepPartialObject<Options> | undefined = {
            display: false
        }

        const options: ChartOptions<'line'> = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: legendPlugin,
                title: titlePlugin,
                subtitle: subtitlePlugin,
                tooltip: tooltipPlugin,
                datalabels: dataLabelsPlugin

            },
            layout: {
                autoPadding: false,
                padding: -10
            },
            scales: {
                x: {
                    type: 'linear',
                    ticks: {
                        display: false
                    },
                    grid: {
                        display: false
                    },
                    border: {
                        display: false
                    },
                    min: 0,
                    max: arr.length - 1,
                    beginAtZero: true
                },
                y: {
                    type: 'linear',
                    ticks: {
                        display: false
                    },
                    grid: {
                        display: false
                    },
                    border: {
                        display: false
                    },
                    min: 0,
                    max: Math.max(...arr),
                    beginAtZero: true
                }
            }
        }

        const labels = arr.map((_, index) => index)
        // Create the chart when the component mounts
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')

            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        // labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        labels,
                        datasets: [
                            {
                                label: 'base line',
                                data: arr,
                                tension: 0.4,
                                borderWidth: 0.5,
                                backgroundColor,
                                borderColor,
                                pointBackgroundColor,
                                pointBorderColor,
                                pointRadius: 0,
                                fill: 'start'
                            }
                        ]
                    },
                    options
                })
            }
        }

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy()
            }
        }
    }, [stylesheets, token.details.locale, arr, color])

    return <div style={{ height: 90 }}><canvas className={'chart-bordered'}
        ref={canvasRef} /></div>
}

export default LineChart
