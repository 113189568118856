import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useGPACareplanStepIdDetailsMutation } from '@doc/api'
import { GPACareplanStepIdDetails } from '@doc/type'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { Modal, ModalBody, ModalHeader } from 'reactstrap'
// import _ from 'lodash'
// eslint-disable-next-line max-len
import ReadonlyCareplanStep from '@doc/components/patients/details/components/modal/ReadonlyCareplanStep/Main'
import _ from 'lodash'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

interface ComponentProps {
    contentModal: boolean,
    toggleContentModal: React.Dispatch<React.SetStateAction<boolean>>
    userId: string | undefined
    careplanStepId: string | undefined
}
const ContentDetails = (props: ComponentProps) => {
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const strings = useAppSelector(selectStrings)

    const [
        gPACareplanStepIdDetails,
        gPACareplanStepIdDetailsMutation
    ] = useGPACareplanStepIdDetailsMutation()

    const contentData = gPACareplanStepIdDetailsMutation.data as
     GPACareplanStepIdDetails['response']['content']

    const unsubscribeGPACareplanStepIdDetails = () => {
        const unsubscribeMutation = gPACareplanStepIdDetails({
            urlParams: {},
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGPACareplanStepIdDetails()

        const promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let gPACareplanStepIdDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.gPACareplanStepIdDetails.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        gPACareplanStepIdDetailsPromise = gPACareplanStepIdDetails({
                            authToken: newToken.value,
                            data: {
                                type: 'content',
                                userId: props.userId || ''
                            },
                            urlParams: {
                                careplanStepId: props.careplanStepId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            gPACareplanStepIdDetailsPromise && gPACareplanStepIdDetailsPromise.abort()
        }
    }

    useEffect(() => {
        // there can be a case where it can be called more than once.
        if (props.contentModal) {
            return fetchData(token)
        }

        return () => {}
    }, [props.contentModal])

    useEffect(() => {
        // console.log(contentData)
    }, [contentData])

    useEffect(() => {
        if (gPACareplanStepIdDetailsMutation.error) {
            const message = getErrorText(gPACareplanStepIdDetailsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [gPACareplanStepIdDetailsMutation.error])

    // ok so you want to display the careplan step the way it is BUT readonly version.
    // best to do it from scratch. start with how to render the data.

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    return <Modal
        unmountOnClose={false}
        // overflow-auto necessary for smaller screen sizes. this modal should not be responsive.
        className={'answer-summary-modal overflow-auto'}
        isOpen={props.contentModal} toggle={() => {
            props.toggleContentModal(false)
        }}>
        <ModalHeader className={'justify-content-start'} toggle={() => {
            props.toggleContentModal(false)
        // btn-close me-auto ms-0
        }} close={ <a className={'btn btn-round '}
            onClick={(e) => {
                e.preventDefault()
                props.toggleContentModal(false)
            }}>
            <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
            </i>
        </a>}
        >{}</ModalHeader>
        <ModalBody className={'p-0'}>
            {
                gPACareplanStepIdDetailsMutation.isLoading
                    ? LoadingContent
                    : gPACareplanStepIdDetailsMutation.isSuccess
                        ? <ReadonlyCareplanStep contentData={contentData}
                            userId={props.userId} careplanStepId={props.careplanStepId}
                        />
                        : contentData?.message
            }
        </ModalBody>
    </Modal>
}

export default ContentDetails
