import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import {
    DoGetTokenRequest,
    DoGetTokenResponse,
    DoLoginRequest,
    DoLoginResponse,
    GetMessagesRequest,
    GetMessagesResponse,
    GetModulesRequest,
    GetModulesResponse,
    GetWorkflowRequest,
    GetWorkflowResponse,
    ResetPasswordRequest,
    ResetPasswordResponse,
    TokenValidRequest,
    TokenValidResponse,
    UpdateSubscriberIdRequest,
    UpdateSubscriberIdResponse,
    UpdateWorkflowProgressRequest,
    UpdateWorkflowProgressResponse
} from '@login/type'
import {
    ChangePasswordRequest,
    ChangePasswordResponse,
    ValidateMFARequest,
    ValidateMFAResponse
} from '@profile/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'

export const loginV10Api = createApi({
    reducerPath: 'loginV10Api',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),

    endpoints: (builder) => ({
        // will not use build.query unless method type is get or undefined.
        changePassword: builder
            .mutation<ChangePasswordResponse, ChangePasswordRequest & TokenAuth>({
                query: (body) => {
                    const formData: ChangePasswordRequest = {
                        data: {
                            currentPassword: body.data.currentPassword,
                            newPassword: body.data.newPassword,
                            newPassword2: body.data.newPassword2
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths.changePassword.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        doLogin: builder.mutation<DoLoginResponse, DoLoginRequest & TokenAuth>({
            query: (body) => {
                const formData: DoLoginRequest = {
                    username: body.username,
                    password: body.password,
                    uuid: body.uuid,
                    id: body.id,
                    id_token: body.id_token
                }

                const formBody = body.username
                    ? `{"username":"${ formData.username }","password":"${ formData.password }"}`
                    : body.uuid
                        ? `{"uuid":"${ formData.uuid }","id":"${ formData.id }"}`.trim()
                        : body.id_token
                            ? `{"id_token":"${ formData.id_token }"}`.trim()
                            : ''

                return {
                    url: _.trimStart(
                        MODULE_TABLE.login.apiPaths.doLogin.path, '/'
                    ),
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formBody
                }
            }
        }),
        tokenValid: builder.mutation<TokenValidResponse, TokenValidRequest>({
            query: (body) => {
                return {
                    url: _.trimStart(
                        MODULE_TABLE.login.apiPaths.tokenValid.path, '/'
                    ),
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.token }`
                    },
                    body: '{}'
                }
            }
        }),
        getToken: builder
            .mutation<DoGetTokenResponse, DoGetTokenRequest>({
                query: (body) => {
                    const formData: DoGetTokenRequest = {
                        data: {
                            locale: body.data.locale
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths.doGetToken.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        // decided that it would be here instead of the login or token types
        getModules: builder
            .mutation<GetModulesResponse, GetModulesRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetModulesRequest = {
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths.getModules.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }

            }),
        getWorkflow: builder
            .mutation<GetWorkflowResponse, GetWorkflowRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetWorkflowRequest = {
                        data: {
                            ...(body.data?.moduleId && { moduleId: body.data?.moduleId }),
                            ...(body.data?.workflowId && { workflowId: body.data?.workflowId }),
                            ...(body.data?.route && { route: body.data.route })
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths.getWorkflow.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateWorkflowProgress: builder
            .mutation<UpdateWorkflowProgressResponse,
            UpdateWorkflowProgressRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdateWorkflowProgressRequest = {
                        data: {
                            stepId: body.data?.stepId,
                            workflowId: body.data?.workflowId,
                            percentComplete: body.data?.percentComplete
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths
                                .updateWorkflowProgress.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        resetPassword: builder
            .mutation<ResetPasswordResponse,
            ResetPasswordRequest & TokenAuth>({
                query: (body) => {
                    const formData: ResetPasswordRequest = {
                        data: {
                            username: body.data?.username
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths
                                .resetPassword.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateSubscriberId: builder
            .mutation<UpdateSubscriberIdResponse, UpdateSubscriberIdRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdateSubscriberIdRequest = {
                        data: {
                            subscriberId: body.data.subscriberId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths.updateSubscriberId.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        validateMFA: builder.mutation<ValidateMFAResponse,
            ValidateMFARequest & TokenAuth>({
                query: (body) => {
                    const formData: ValidateMFARequest = {
                        data: {
                            token: body.data.token
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths.validateMFA.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        getMessages: builder
            .mutation<GetMessagesResponse, GetMessagesRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetMessagesRequest = {
                        data: {}
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.login.apiPaths.getMessages.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useDoLoginMutation,
    useGetTokenMutation,
    useGetModulesMutation,
    useGetMessagesMutation,
    useGetWorkflowMutation,
    useUpdateWorkflowProgressMutation,
    useTokenValidMutation,
    useResetPasswordMutation,
    useUpdateSubscriberIdMutation,
    useValidateMFAMutation, useChangePasswordMutation
} = loginV10Api
