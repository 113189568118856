import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { DepartmentKeys, DepartmentValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    departmentEditFormik: FormikProps<DepartmentValues>
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: DepartmentKeys) => void
}

const City = ({ readOnly, departmentEditFormik, handleKeyDown }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: DepartmentKeys = 'departmentCity'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.DEPARTMENT.EDIT.CITY} className={'form-label'}>{
                    strings.fmt?.text.facility.menu.table.city
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly={readOnly}
                    type={'text'}
                    name={fieldName}
                    className={[
                        'form-control-sm',
                        readOnly ? 'form-control-plaintext' : 'form-control',
                        departmentEditFormik.errors.departmentCity && 'border-danger'
                    ].join(' ')}
                    placeholder={!readOnly
                        ? strings.fmt?.text.facility.menu.table.city
                        : ''}
                    value={departmentEditFormik.values.departmentCity}
                    id={IDS.DEPARTMENT.EDIT.CITY}
                    onBlur={departmentEditFormik.handleBlur}
                    onChange={!readOnly ? departmentEditFormik.handleChange : () => {}}
                    onKeyDown={handleKeyDown}
                    required
                />
                <div className={'form-text error'}>{
                    departmentEditFormik.errors.departmentCity
                        ? departmentEditFormik.errors.departmentCity
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default City
