import { END_USER_COLORS } from '@stylesheet/brands/ikh/enduser/Colors'
import { BootstrapConfig } from '@stylesheet/config/Bootstrap'

export const BOOTSTRAP_CONFIG: BootstrapConfig = {
    linkColor: END_USER_COLORS.blue_200,
    linkHoverColor: END_USER_COLORS.blue_100,
    bodyColor: END_USER_COLORS.white,
    borderColor: END_USER_COLORS.gray_900,
    offcanvas: {
        color: END_USER_COLORS.white,
        bg: END_USER_COLORS.darkGrey_2
    },
    shadow: {
        boxShadow: END_USER_COLORS.shadowColor1
    },
    formControl: {
        backgroundColor: END_USER_COLORS.gray_900,
        color: END_USER_COLORS.white,
        focus: {
            backgroundColor: END_USER_COLORS.gray_900,
            color: END_USER_COLORS.white
        },
        disabled: {
            backgroundColor: END_USER_COLORS.gray_800,
            color: END_USER_COLORS.darkGrey_2
        }
    },
    buttons: {
        bg: END_USER_COLORS.gray_900,
        color: END_USER_COLORS.black,
        hoverColor: END_USER_COLORS.black,
        hoverBg: END_USER_COLORS.white
    },
    btnWhite: {
        color: END_USER_COLORS.white,
        bg: END_USER_COLORS.gray_900,
        borderColor: END_USER_COLORS.gray_900,
        hoverColor: END_USER_COLORS.gray_900,
        hoverBg: END_USER_COLORS.gray_900,
        hoverBorderColor: END_USER_COLORS.gray_900,
        focusShadowRGB: '217, 226, 234',
        activeColor: END_USER_COLORS.darkBlue,
        activeBg: END_USER_COLORS.gray_900,
        activeBorderColor: END_USER_COLORS.gray_900,
        activeShadow: `inset 0 3px 5px ${ END_USER_COLORS.shadowColor1 }`,
        disabledColor: END_USER_COLORS.gray_400,
        disabledBg: END_USER_COLORS.gray_50,
        disabledBorderColor: END_USER_COLORS.gray_50,
        boxShadow: END_USER_COLORS.shadowColor2
    },
    btnDark: {
        color: END_USER_COLORS.white,
        bg: END_USER_COLORS.darkBlue,
        borderColor: END_USER_COLORS.darkBlue,
        hoverColor: END_USER_COLORS.darkBlue,
        hoverBg: END_USER_COLORS.darkBlue,
        hoverBorderColor: END_USER_COLORS.darkBlue,
        focusShadowRGB: '217, 226, 234',
        activeColor: END_USER_COLORS.darkBlue,
        activeBg: END_USER_COLORS.darkBlue,
        activeBorderColor: END_USER_COLORS.darkBlue,
        activeShadow: `inset 0 3px 5px ${ END_USER_COLORS.shadowColor1 }`,
        disabledColor: END_USER_COLORS.gray_400,
        disabledBg: END_USER_COLORS.gray_50,
        disabledBorderColor: END_USER_COLORS.gray_50
    },
    btnPrimary: {
        color: END_USER_COLORS.gray_900,
        bg: END_USER_COLORS.yellow1,
        borderColor: END_USER_COLORS.yellow1,
        hoverColor: END_USER_COLORS.gray_900,
        hoverBg: END_USER_COLORS.yellow2,
        hoverBorderColor: END_USER_COLORS.yellow3,
        focusShadowRGB: '49, 132, 253',
        activeColor: END_USER_COLORS.white,
        activeBg: END_USER_COLORS.yellow2,
        activeBorderColor: END_USER_COLORS.yellow3,
        activeShadow: `inset 0 3px 5px ${ END_USER_COLORS.shadowColor1 }`,
        disabledColor: END_USER_COLORS.gray_500,
        disabledBg: END_USER_COLORS.yellow1,
        disabledBorderColor: END_USER_COLORS.yellow1
    },
    btnSecondary: {
        borderColor: END_USER_COLORS.gray_200,
        color: END_USER_COLORS.white,
        bg: END_USER_COLORS.gray_900,
        hoverBorderColor: END_USER_COLORS.gray_900,
        hoverColor: END_USER_COLORS.gray_900,
        hoverBg: END_USER_COLORS.gray_900,
        focusShadowRgb: '49, 132, 253',
        activeBorderColor: END_USER_COLORS.gray_900,
        activeColor: END_USER_COLORS.white,
        activeBg: END_USER_COLORS.gray_900,
        activeShadow: `inset 0 3px 5px ${ END_USER_COLORS.shadowColor1 }`,
        disabledColor: END_USER_COLORS.gray_500,
        disabledBg: END_USER_COLORS.gray_100,
        disabledBorderColor: END_USER_COLORS.gray_200
    },
    form: {
        errorColor: END_USER_COLORS.red
    },
    navPills: {
        navPillsLinkActiveBg: END_USER_COLORS.gray_800,
        navPillsLinkActiveColor: END_USER_COLORS.white,
        background: END_USER_COLORS.gray_900,
        border: END_USER_COLORS.gray_900,
        boxShadow: END_USER_COLORS.shadowColor1,
        navLinkColor: END_USER_COLORS.white,
        navLinkActiveBoxShadow: END_USER_COLORS.shadowColor1
    },
    card: {
        boxShadow: END_USER_COLORS.darkGrey_2,
        backgroundColor: END_USER_COLORS.darkGrey_1,
        color: END_USER_COLORS.white
    },
    inputCheckbox: {
        unchecked: {
            backgroundColor: END_USER_COLORS.gray_50,
            borderColor: END_USER_COLORS.gray_300
        },
        checked: {
            backgroundColor: END_USER_COLORS.yellow1,
            borderColor: END_USER_COLORS.yellow4,
            color: END_USER_COLORS.darkBlue
        }
    },
    checkmark: {
        backgroundColor: END_USER_COLORS.gray_50,
        borderColor: END_USER_COLORS.gray_300
    },
    radioChecked: {
        backgroundColor: END_USER_COLORS.yellow1,
        borderColor: END_USER_COLORS.yellow4,
        color: END_USER_COLORS.darkBlue
    },
    labelHover: {
        color: END_USER_COLORS.blue_100 // Text color on hover
    },
    questionnaire: {
        gradientBackground: {
            color: END_USER_COLORS.white,
            // eslint-disable-next-line max-len
            backgroundColor: 'linear-gradient(180deg, rgba(29, 34, 58, 0) 37%, rgba(29, 34, 58, 0.192414) 54.72%, rgba(29, 34, 58, 0.385205) 70.14%, rgba(29, 34, 58, 0.8) 88.85%)'
        },
        checkedBackground: {
            backgroundColor: END_USER_COLORS.yellow1,
            opacity: 0.3
        }
    },
    questionRadio: {
        gradientBackground: {
            // eslint-disable-next-line max-len
            backgroundColor: 'linear-gradient(180deg, rgba(29, 34, 58, 0) 37%, rgba(29, 34, 58, 0.192414) 54.72%, rgba(29, 34, 58, 0.385205) 70.14%, rgba(29, 34, 58, 0.8) 88.85%)'
        },
        checkedBackground: {
            backgroundColor: END_USER_COLORS.yellow1,
            opacity: 0.3
        }
    },
    questionCheckbox: {
        gradientBackground: {
            // eslint-disable-next-line max-len
            backgroundColor: 'linear-gradient(180deg, rgba(29, 34, 58, 0) 37%, rgba(29, 34, 58, 0.192414) 54.72%, rgba(29, 34, 58, 0.385205) 70.14%, rgba(29, 34, 58, 0.8) 88.85%)'
        },
        checkedBackground: {
            backgroundColor: END_USER_COLORS.yellow1,
            opacity: 0.3
        },
        card: {
            setValue: {
                color: END_USER_COLORS.gray_400
            }
        }
    },
    questionCheckmark: {
        backgroundColor: END_USER_COLORS.yellow1,
        borderColor: END_USER_COLORS.gray_300,
        textColor: END_USER_COLORS.darkBlue,
        iconColor: END_USER_COLORS.white,
        uncheckedBackgroundColor: END_USER_COLORS.gray_100,
        uncheckedBorderColor: END_USER_COLORS.gray_300,
        uncheckedIconColor: END_USER_COLORS.darkBlue

    },
    questionPlusSign: {
        backgroundColor: END_USER_COLORS.yellow1,
        textColor: END_USER_COLORS.darkBlue,
        iconColor: END_USER_COLORS.darkBlue
    },
    questionUnchecked: {
        backgroundColor: END_USER_COLORS.gray_100,
        borderColor: END_USER_COLORS.gray_300,
        iconColor: END_USER_COLORS.darkBlue
    },
    questionIcon: {
        backgroundColor: END_USER_COLORS.darkGrey_2,
        iconColor: END_USER_COLORS.darkBlue
    },
    btnGroup: {
        btnDummy: {
            textColor: END_USER_COLORS.white,
            borderColor: END_USER_COLORS.yellow1,
            shadowColor: END_USER_COLORS.shadowColorYellow,
            backgroundColor: END_USER_COLORS.yellow1
        }
    },
    btnGroupCheckbox: {
        cardText: {
            background: END_USER_COLORS.shadowColor2,
            boxShadow: `0px 1px 8px 0px ${ END_USER_COLORS.shadowColor2 }`,
            color: END_USER_COLORS.white
        }
    }
}
