import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { TokenData } from '@app/types/type.token'
import { useEditTreatmentMutation, useGetTreatmentMutation } from '@fmt/api'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import TreatmentName from '@fmt/components/treatments/details/inputs/Name'
import { TREATMENT_FORMIK_INITIAL_VALUES, TREATMENT_VALIDATION_SCHEMA } from '@fmt/constants'
import { Treatment } from '@fmt/type'
import { push } from '@lagunovsky/redux-react-router'
import { useFormik } from 'formik'
import _ from 'lodash'

const AccordionComponent = (
    { mode, isOpen, obj, fetchData }:
    { mode: 'EDIT' | 'VIEW',
    isOpen?: boolean, obj: Treatment, fetchData: (token: TokenData) => () => void}) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const dispatch = useAppDispatch()

    const [getTreatment, getTreatmentMutation] = useGetTreatmentMutation()
    // accordion components are now editable.
    const [editTreatment, editTreatmentMutation] = useEditTreatmentMutation()

    const treatmentEditFormik = useFormik({
        initialValues: TREATMENT_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: TREATMENT_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.editTreatment.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        editTreatment({
                            authToken: newToken.value,
                            data: {
                                treatmentId: obj.treatmentId || '',
                                treatmentName: values.treatmentName || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = editTreatmentMutation.data

        if (data) {
            if (data.status === 'OK') {
                fetchData(token)
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editTreatmentMutation.data])

    useEffect(() => {
        if (editTreatmentMutation.error) {
            const message = getErrorText(editTreatmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editTreatmentMutation.error])

    /** create fetch data function */
    const fetchSingularData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                isOpen === true &&
                getTreatmentMutation.isSuccess === false
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getTreatmentId.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getTreatment({
                            authToken: newToken.value,
                            data: {
                                treatmentId: obj.treatmentId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // ONLY fetch it when obj.needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        return fetchSingularData(token)
    }, [token.id, token.valid, isOpen, getTreatmentMutation.isSuccess])

    useEffect(() => {
        if (getTreatmentMutation.data) {
            const data = getTreatmentMutation.data
            treatmentEditFormik.setValues({
                ...treatmentEditFormik.values,
                ...data.data
            })
        }
    }, [getTreatmentMutation.data])

    useEffect(() => {
        if (getTreatmentMutation.error) {
            const message = getErrorText(getTreatmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getTreatmentMutation.error])

    const ViewButton = useMemo(() => {
        return <button
            type={'button'}
            className={'btn btn-secondary w-100'}
            onClick={(e) => {
                e.stopPropagation()

                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.routes.viewTreatment,
                    true
                )

                dispatch(push(
                    _.replace(isValid.route,
                        ':treatmentId',
                        obj.treatmentId
                    )
                ))
            }}
        >
            {strings.fmt?.text.treatment.view_treatment}
        </button>
    }, undefined)

    const SubmitButton = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        )

        return <button type={'button'}
            disabled={
                // step 1 shouldn't be disabled here because of formik.
                false
                // step 2 can be skipped entirely.
            }
            className={[
                'btn btn-primary w-100 px-8'
            ].join(' ')}
            onClick={() => {
                treatmentEditFormik.handleSubmit()
            }}>
            { editTreatmentMutation.isLoading
                ? LoadingContent
                : strings.app?.text.save}
        </button>
    }, undefined)

    // const handleKeyDown = (
    //     e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
    //     fieldName?: TreatmentKeys
    // ) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault()
    //         const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
    //         if (nextInput) {
    //             nextInput.focus()
    //             nextInput.select()
    //         } else {
    //             treatmentEditFormik.handleSubmit()
    //         }
    //     }
    // }

    const NameInput = useMemo(() => {
        return <TreatmentName
            readOnly={mode === 'VIEW'}
            treatmentEditFormik={treatmentEditFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { treatmentEditFormik.handleSubmit() }
            }}
        />
    }, [
        mode,
        strings,
        treatmentEditFormik.values.treatmentName,
        treatmentEditFormik.errors.treatmentName
    ])

    return <>
        {
            getTreatmentMutation.isLoading && <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        }
        {getTreatmentMutation.isSuccess && <div className={'px-16'}>
            <h5 className={'col fw-semibold'}>{strings.fmt?.text.treatment.view_treatment}</h5>
            {NameInput}

            {mode === 'EDIT' && <div className={'container button-container'}>
                <div className={'row align-items-center mt-6'}>
                    <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                        {ViewButton}
                    </div>
                    <div className={'col-6 col-lg-4 col-md-6'}>
                        {SubmitButton}
                    </div>
                </div>
            </div>}
        </div>}
    </>
}

export default AccordionComponent
