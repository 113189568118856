
interface Colors {
    body: string,
    white: string,
    white_50: string,
    white_100: string,
    black: string,
    green: string,
    gray_50: string,
    gray_100: string,
    gray_200: string,
    gray_300: string,
    gray_400: string,
    gray_500: string,
    gray_600: string,
    gray_700: string,
    gray_800: string,
    gray_900: string,
    blue_100: string
    blue_200: string
    darkBlue: string,
    darkBlueSelected: string,
    red: string
    yellow1: string,
    yellow2: string,
    yellow3: string,
    yellow4: string,
    yellow5: string,
    yellow6: string,
    yellowShadow: string,
    darkGrey_1: string,
    darkGrey_2: string,
    shadowColor1: string,
    shadowColor2: string,
    shadowColor3: string,
    shadowColorYellow: string,
    shadowColorBlue: string,
    headerGradientColor: string,
    cardTypes: {
        notification: string,
        activity: string,
        content: string,
        lesson: string,
        reasoning: string
    }
    completion: {
        complete: string,
        locked: string,
        uncertain: string,
        incomplete: string
    },
    sso: {
        microsoft: string
    }
}

export const END_USER_COLORS: Colors = {
    body: '#fcfcfc',
    white: '#ffffff',
    white_50: '#f4f4f4',
    white_100: '#e5e5e5',
    black: '#000000',
    green: '#35B16E',
    gray_50: '#f7f7fd',
    gray_100: '#F8F8FC',
    gray_200: '#E7E8ED',
    gray_300: '#C6CCD2',
    gray_400: '#808080',
    gray_500: '#909090',
    gray_600: '#aaaaaa',
    gray_700: '#909090',
    gray_800: '#808080',
    gray_900: '#51585e',
    blue_100: '#457ae5',
    blue_200: '#0A7AFF',
    darkBlue: '#003870',
    darkBlueSelected: '#266299',
    red: '#f2320c',
    yellow1: '#F0B000',
    yellow2: '#fab700e1',
    yellow3: '#fab700c8',
    yellow4: '#F6BF00',
    yellow5: '#BF8C00',
    yellow6: '#fbdf80',
    yellowShadow: 'f6bf003f',
    darkGrey_1: '#474747',
    darkGrey_2: '#262626',
    shadowColor1: '#26262617',
    shadowColor2: '#3b3b3b36',
    shadowColor3: '#2626260A',
    shadowColorYellow: '#B2860014',
    shadowColorBlue: '#003eb213',
    headerGradientColor: '#DF8600',
    cardTypes: {
        notification: '#003870',
        activity: '#457AE5',
        content: '#E5448D',
        lesson: '#6CD8B7',
        reasoning: '#9840AE'
    },
    completion: {
        complete: '#35B16E',
        locked: '#C6CCD2;',
        uncertain: '#0c0d0d',
        incomplete: '#E78123'
    },
    sso: {
        microsoft: '#00a1f1'
    }
}
