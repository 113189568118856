
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { setAnswerValue } from '@reasonWithMe/slice'
import { QuestionInterfaceActions, ReasonWithMeState } from '@reasonWithMe/type'
import { fromUnixTime } from 'date-fns'
import getUnixTime from 'date-fns/getUnixTime'
import { useMemo } from 'react'
// for react-datepicker locale. requires date-fns.

import NewDatePicker from '@app/components/NewDatePicker'
import { selectStrings } from '@app/slices/slice.app'
import { IDS } from '@reasonWithMe/constants'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
}

const SingleDate = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    // useEffect(() => {
    //     console.log('newly rendered due to key change')
    // }, [])

    const DatePicker = useMemo(() => {
        const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        const data = questionInterface.currentReasonWithMeResponse.reasoningData

        // "date_time", "date"
        const dateType = data.question?.questionAnswers[0]?.answerValue
        const timeFormat = data.question?.questionAnswers[0]?.timeFormat

        // range lower and range upper.
        // const rangeLower = data.question?.questionAnswers[0]?.rangeLower || 0
        // const rangeUpper = data.question?.questionAnswers[0]?.rangeUpper || 0

        // if answerValue is not a number, set it to 0
        const singleDate = answerValue !== undefined && typeof answerValue === 'number'
            ? fromUnixTime(answerValue)
            : undefined

        return (<>
            <NewDatePicker
                popperPlacement={'bottom'}
                id={IDS.SINGLE_DATE.INPUT}
                isDate={(date) => {
                    if (componentDispatch !== undefined) {
                        componentDispatch({
                            type: 'SET_ANSWER_VALUE',
                            value: getUnixTime(date)
                        })
                    } else {
                        dispatch(
                            setAnswerValue(
                                getUnixTime(date)
                            )
                        )
                    }
                }}

                singleDate={singleDate}
                dateType={dateType}
                timeFormat={timeFormat}
            />

        </>

        )
    }, [strings, questionInterface])

    return <div className={'question-single-date'}>
        {DatePicker}
    </div>
}

export default SingleDate
