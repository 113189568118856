
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import {
    OpenListActions,
    OpenListValues,
    OpenMultiQuestionActions,
    QuestionAnswer
} from '@reasonWithMe/type'
import produce from 'immer'
import _ from 'lodash'
import React, { useEffect, useMemo, useReducer } from 'react'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidV4 } from 'uuid'

interface ComponentProps {
    enableValidation: boolean
    ids:{
        activeOptionId: string,
        questionId: string,
        answerIndex: number
    }
    obj:{
        question: QuestionAnswer & {
            id: string;
        };
    }
    answer: {
        hasAnswer: boolean;
        actualValue: any;
    }
    multiFormsDispatch: React.Dispatch<OpenMultiQuestionActions>,
    enableTruncation: boolean
    setEnableTruncation: React.Dispatch<React.SetStateAction<boolean>>
}

const OpenList = ({
    enableValidation,
    ids,
    obj,
    answer,
    multiFormsDispatch,
    enableTruncation,
    setEnableTruncation
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const [formsState, formsDispatch] = useReducer((
        state: Omit<OpenListValues, 'generalInfo'>[],
        action: OpenListActions
    ) => {
        switch (action.type) {
            case 'SET_INITIAL_VALUES':
                return produce(state, draft => {
                    draft.splice(0, draft.length, ...(action.arr || []))
                })
            case 'ADD_FORM':{
                return produce(state, draft => {
                    draft.push({
                        id: uuidV4(),
                        inputs: _.map(obj.question?.answerQuestions
                            ?.[ids.answerIndex].questionAnswers, (o) => {
                            const answerType = o.answerType as 'input' | 'radio'
                            | 'checkbox' | 'input-list' | 'input-list-split'

                            let userTypedAnswer: string | string[] | boolean = ''

                            if (answerType === 'input') {
                                userTypedAnswer = ''
                            }

                            if (answerType === 'radio') {
                                userTypedAnswer = false
                            }

                            if (answerType === 'checkbox') {
                                userTypedAnswer = false
                            }

                            if (answerType === 'input-list') {
                                userTypedAnswer = ['']
                            }

                            const answerValue = o.answerValue as number

                            return {
                                answerValue,
                                answerName: o.answerName || '',
                                answerType,
                                answerEditable: o.answerEditable || true,
                                userTypedAnswer
                            }
                        })
                    })
                })
            }
            case 'DELETE_FORM':{
                return produce(state, draft => {
                    if (action.index !== undefined && action.index > -1) {
                        _.pullAt(draft, [action.index])
                    }
                })
            }
            case 'UPDATE_FORM':{
                return produce(state, draft => {
                    const found = _.find(draft, (o) => {
                        return o.id === action.id
                    })

                    if (found) {
                        const inputToUpdate = _.find(found.inputs, (o) => {
                            return o.answerValue === action.columnOrder
                        })

                        if (inputToUpdate) {
                            inputToUpdate.userTypedAnswer = action.value
                        }
                    }
                })
            }
            default:
                return state
        }
    }, answer.actualValue || [
        {
            id: uuidV4(),
            inputs: _.map(obj.question?.answerQuestions
                ?.[ids.answerIndex].questionAnswers, (o) => {
                const answerType = o.answerType as 'input' | 'radio'
                | 'checkbox' | 'input-list' | 'input-list-split'

                let userTypedAnswer: string | string[] | boolean = ''

                if (answerType === 'input') {
                    userTypedAnswer = ''
                }

                if (answerType === 'radio') {
                    userTypedAnswer = false
                }

                if (answerType === 'checkbox') {
                    userTypedAnswer = false
                }

                if (answerType === 'input-list') {
                    userTypedAnswer = ['']
                }

                const answerValue = o.answerValue as number
                return {
                    answerValue,
                    answerName: o.answerName || '',
                    answerType,
                    answerEditable: o.answerEditable || true,
                    userTypedAnswer
                }
            })
        }
    ])

    useEffect(() => {
        // console.log('updating open list with: ', formsState)

        const isEveryAnswerValid = formsState.every(item =>
            item.inputs.every(o => {
                // check if userTypedAnswer is a string
                if (_.isString(o.userTypedAnswer)) {
                    // check if it's not empty. if true, it passes.
                    return _.isEmpty(o?.userTypedAnswer) === false
                } else if (_.isBoolean(o.userTypedAnswer)) {
                    // check if it's not undefined.
                    return o?.userTypedAnswer !== undefined
                } else if (_.isArray(o?.userTypedAnswer)) {
                    // check if there is at least one element in the array that is empty.
                    // check if there is AT LEAST one EMPTY user.
                    const someCheck = _.some(
                        o?.userTypedAnswer, (p) => {
                            return _.isEmpty(p)
                        }
                    )

                    if (someCheck === true) {
                        return false
                    } else {
                        // that means all questions in the array are answered.
                        return true
                    }
                }

                // return false if pre existing conditions were never met.
                return false
            })
        )

        console.log('is every answer valid state on open-list: ', isEveryAnswerValid)

        multiFormsDispatch({
            type: 'UPDATE_ANSWER_VALUE',
            ids,
            answer: {
                hasAnswer: isEveryAnswerValid,
                actualValue: formsState
            }
        })
    }, [formsState])

    const addForm = () => {
        formsDispatch({ type: 'ADD_FORM' })
    }

    useEffect(() => {
        if (enableTruncation) {
            // update the formState.

            // enableTruncation is true, then filter the formState before rendering.
            let filtered: OpenListValues[] = []

            if (enableTruncation) {
                _.forEach(formsState, (o) => {
                    // if there is at least one truthy userTypedAnswer, add to record.
                    const addToList = _.some(o.inputs, (p) => {
                        const userTypedAnswer = p.userTypedAnswer

                        if (typeof userTypedAnswer === 'string') {
                            // checks if the input is lengthy
                            return userTypedAnswer.length > 0
                        } else if (_.isArray(userTypedAnswer)) {
                        // checks if the input type list has more than one truthy record.
                            return _.some(
                                userTypedAnswer, (q) => {
                                    return q.length > 0
                                }
                            )
                        } else if (typeof userTypedAnswer === 'boolean') {
                        // this is included too because we'll have to treat it as
                        // not interacted with.
                            return userTypedAnswer === true
                        } else {
                            return false
                        }
                    })

                    if (addToList === true) {
                        filtered.push(
                            o
                        )
                    }
                })
            } else {
                filtered = formsState
            }

            formsDispatch({
                type: 'SET_INITIAL_VALUES',
                arr: filtered
            })

            // then set truncate to false again.
            setEnableTruncation(false)
        }
    }, [enableTruncation])

    const input = useMemo(() => {
        const desktopRender = formsState.map((form, outerIndex) => {
            const sorted = [...form.inputs]
                .sort((a, b) => {
                    const valueA = Number(a.answerValue || 0)
                    const valueB = Number(b.answerValue || 0)
                    return valueA - valueB
                })

            const rowHasInputList = _.includes(
                _.map(form.inputs, (o) => {
                    return o.answerType
                }), 'input-list'
            )

            const inputs = _.map(sorted,
                (formInput, innerIndex) => {
                    const answerType = formInput.answerType as 'input' | 'radio'
                        | 'checkbox' | 'input-list' | 'input-list-split'

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'input') {
                        input = <input
                            className={[
                                'form-control form-control-sm',
                                enableValidation
                                    ? _.isEmpty(formInput?.userTypedAnswer)
                                        ? 'border-danger'
                                        : ''
                                    : ''
                            ].join(' ')}
                            type={'text'}
                            name={`${ answerType }-${ outerIndex }`}
                            defaultValue={formInput.userTypedAnswer as string}
                            // if answerEditable is false OR if the findPreviousInput is truthy
                            disabled={ !formInput.answerEditable}
                            onChange={(e) => {
                                if (formInput.answerEditable) {
                                    formsDispatch({
                                        type: 'UPDATE_FORM',
                                        id: form.id,
                                        columnOrder: Number(formInput
                                            .answerValue || 0),
                                        value: e.target.value
                                    })
                                }
                            }}
                            onBlur={(e) => {
                                if (formInput.answerEditable) {
                                    formsDispatch({
                                        type: 'UPDATE_FORM',
                                        id: form.id,
                                        columnOrder: Number(formInput
                                            .answerValue || 0),
                                        value: e.target.value
                                    })
                                }
                            }}
                        />
                    }

                    if (answerType === 'radio') {
                        input = <label className={'custom-radio'}>
                            <input
                                type={'radio'}
                                name={`${ answerType }-${ outerIndex }`}
                                disabled={ !formInput.answerEditable}
                                checked={formInput.userTypedAnswer as boolean}
                                onChange={(e) => {
                                    if (formInput.answerEditable) {
                                        formsDispatch({
                                            type: 'UPDATE_FORM',
                                            id: form.id,
                                            columnOrder: Number(formInput
                                                .answerValue || 0),
                                            // radio/checkbox values are true/false
                                            value: !formInput.userTypedAnswer
                                        })
                                    }
                                }}
                            />
                            <span className={'checkmark'}>
                                <i className={'fa-solid fa-check mx-auto'}></i>
                            </span>
                        </label>
                    }

                    if (answerType === 'checkbox') {
                        input = <input
                            type={'checkbox'}
                            name={`${ answerType }-${ outerIndex }`}
                            disabled={!formInput.answerEditable}
                            checked={formInput.userTypedAnswer as boolean}
                            onChange={(e) => {
                                if (formInput.answerEditable) {
                                    formsDispatch({
                                        type: 'UPDATE_FORM',
                                        id: form.id,
                                        columnOrder: Number(formInput
                                            .answerValue || 0),
                                        value: !formInput.userTypedAnswer
                                    })
                                }
                            }}
                        />
                    }

                    if (answerType === 'input-list') {
                        const inputArr = formInput
                            .userTypedAnswer as string[]

                        const inputList = _.map(inputArr, (inputString,
                            inputIndex) => {
                            const showButtons = (<div>
                                {
                                    inputArr.length > 1 && (
                                        <div className={'col-auto'}>
                                            <div className={'question-plussign ms-auto'}
                                                onClick={() => {
                                                    const newArray = [...inputArr]

                                                    newArray.splice(inputIndex, 1)

                                                    formsDispatch({
                                                        type: 'UPDATE_FORM',
                                                        id: form.id,
                                                        columnOrder: Number(formInput
                                                            .answerValue || 0),
                                                        value: newArray
                                                    })
                                                }}>
                                                <i className={'fa-light fa-minus mx-auto'}></i>
                                            </div>

                                        </div>
                                    )
                                }

                                {
                                    inputIndex === inputArr.length - 1 && (
                                        <div className={'col-auto'}>
                                            <div className={'question-plussign ms-auto'}
                                                onClick={() => {
                                                    formsDispatch({
                                                        type: 'UPDATE_FORM',
                                                        id: form.id,
                                                        columnOrder: Number(formInput
                                                            .answerValue || 0),
                                                        value: [
                                                            ...inputArr,
                                                            ''
                                                        ]
                                                    })
                                                }}>
                                                <i
                                                    className={'fa-light fa-plus mx-auto'}></i>
                                            </div>

                                        </div>
                                    )
                                }
                            </div>)
                            return <li key={[
                                'input-cell', outerIndex, innerIndex, inputIndex
                            ].join('-')}
                            className={'container align-middle text-center mb-4'}>
                                <div className={'row align-items-center'}>
                                    <div className={'col'}>
                                        <input
                                            className={[
                                                'form-control form-control-sm',
                                                enableValidation
                                                    ? _.isEmpty(inputString)
                                                        ? 'border-danger'
                                                        : ''
                                                    : ''
                                            ].join(' ')}
                                            type={'text'}
                                            defaultValue={inputString}
                                            onChange={(e) => {
                                                const newArray = [...inputArr]
                                                newArray[inputIndex] = e.target.value
                                                formsDispatch({
                                                    type: 'UPDATE_FORM',
                                                    id: form.id,
                                                    columnOrder: Number(formInput
                                                        .answerValue || 0),
                                                    value: newArray
                                                })
                                            }}
                                            onBlur={(e) => {
                                                const newArray = [...inputArr]
                                                newArray[inputIndex] = e.target.value
                                                formsDispatch({
                                                    type: 'UPDATE_FORM',
                                                    id: form.id,
                                                    columnOrder: Number(formInput
                                                        .answerValue || 0),
                                                    value: newArray
                                                })
                                            }}
                                        />

                                    </div>

                                    {showButtons}

                                </div>

                            </li>
                        })

                        input = <ol type={'a'} className={'mb-0'}>
                            {
                                inputList
                            }
                        </ol>
                    }

                    return <td key={['table-cell', outerIndex, innerIndex].join('-')}
                        className={[
                            rowHasInputList ? '' : 'align-middle',
                            'text-center'
                        ].join(' ')}>
                        <div className={['answer-type', answerType].join(' ')}>{input}</div>
                    </td>
                })

            return <tr key={['table-row', outerIndex].join('-')}>
                {inputs}
                <td className={[
                    rowHasInputList ? 'pt-4' : 'align-middle',
                    'text-center ',
                    'cursor-pointer'
                ].join(' ')
                }
                >
                    <div className={'question-plussign ms-auto'}
                        onClick={() => {
                            formsDispatch({
                                type: 'DELETE_FORM',
                                index: outerIndex
                            })
                        }}>
                        <i className={`fa-light
                        ${ 'fa-trash px-1' }`}></i>
                    </div>

                </td>
            </tr>
        })

        const mobileRender = formsState.map((form, outerIndex) => {
            const sorted = [...form.inputs]
                .sort((a, b) => {
                    const valueA = Number(a.answerValue || 0)
                    const valueB = Number(b.answerValue || 0)
                    return valueA - valueB
                })

            const rowOneInputs = _.filter(sorted, (item) => {
                // Custom comparator to make 'John' appear first
                return item.answerType === 'input'
            })

            const rowTwoInputs = _.filter(sorted, (item) => {
                // Custom comparator to make 'John' appear first
                return item.answerType !== 'input'
            })

            const rowHasInputList = _.includes(
                _.map(rowTwoInputs, (o) => {
                    return o.answerType
                }), 'input-list'
            )

            // idea. split into two rows right away.
            // one row with inputs
            // one row with the rest.
            const rowOne = _.map(rowOneInputs,
                (formInput, innerIndex) => {
                    const answerType = formInput.answerType as 'input' | 'radio'
                        | 'checkbox' | 'input-list' | 'input-list-split'

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'input') {
                        input = <input
                            className={[
                                'form-control form-control-sm',
                                enableValidation
                                    ? _.isEmpty(formInput?.userTypedAnswer)
                                        ? 'border-danger'
                                        : ''
                                    : ''
                            ].join(' ')}
                            type={'text'}
                            name={`${ answerType }-${ outerIndex }`}
                            defaultValue={formInput.userTypedAnswer as string}
                            // if answerEditable is false OR if the findPreviousInput is truthy
                            disabled={ !formInput.answerEditable}
                            onChange={(e) => {
                                if (formInput.answerEditable) {
                                    formsDispatch({
                                        type: 'UPDATE_FORM',
                                        id: form.id,
                                        columnOrder: Number(formInput
                                            .answerValue || 0),
                                        value: e.target.value
                                    })
                                }
                            }}
                            onBlur={(e) => {
                                if (formInput.answerEditable) {
                                    formsDispatch({
                                        type: 'UPDATE_FORM',
                                        id: form.id,
                                        columnOrder: Number(formInput
                                            .answerValue || 0),
                                        value: e.target.value
                                    })
                                }
                            }}
                        />
                    }

                    return <tr key={['table-row-cell', outerIndex, innerIndex].join('-')}>
                        <td key={['table-cell', outerIndex, innerIndex].join('-')}
                            className={[
                                rowHasInputList ? '' : 'align-middle',
                                'text-center'
                            ].join(' ')}
                            colSpan={(obj.question?.answerQuestions
                                ?.[ids.answerIndex].questionAnswers.length || 0) + 1}
                        >
                            <div className={['answer-type', answerType].join(' ')}>{input}</div>
                        </td>
                    </tr>
                })

            const rowTwo = _.map(rowTwoInputs,
                (formInput, innerIndex) => {
                    const answerType = formInput.answerType as 'input' | 'radio'
                        | 'checkbox' | 'input-list' | 'input-list-split'

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'radio') {
                        input = <label className={'custom-radio'}>
                            <input
                                type={'radio'}
                                name={`${ answerType }-${ outerIndex }`}
                                disabled={ !formInput.answerEditable}
                                checked={formInput.userTypedAnswer as boolean}
                                onChange={(e) => {
                                    if (formInput.answerEditable) {
                                        formsDispatch({
                                            type: 'UPDATE_FORM',
                                            id: form.id,
                                            columnOrder: Number(formInput
                                                .answerValue || 0),
                                            // radio/checkbox values are true/false
                                            value: !formInput.userTypedAnswer
                                        })
                                    }
                                }}
                            />
                            <span className={'checkmark'}>
                                <i className={'fa-solid fa-check mx-auto'}></i>
                            </span>
                        </label>
                    }

                    if (answerType === 'checkbox') {
                        input = <input
                            type={'checkbox'}
                            name={`${ answerType }-${ outerIndex }`}
                            disabled={!formInput.answerEditable}
                            checked={formInput.userTypedAnswer as boolean}
                            onChange={(e) => {
                                if (formInput.answerEditable) {
                                    formsDispatch({
                                        type: 'UPDATE_FORM',
                                        id: form.id,
                                        columnOrder: Number(formInput
                                            .answerValue || 0),
                                        value: !formInput.userTypedAnswer
                                    })
                                }
                            }}
                        />
                    }

                    if (answerType === 'input-list') {
                        const inputArr = formInput
                            .userTypedAnswer as string[]

                        const inputList = _.map(inputArr, (inputString,
                            inputIndex) => {
                            const showButtons = (<div>
                                {
                                    inputArr.length > 1 && (
                                        <div className={'col-auto'}>
                                            <div className={'question-plussign ms-auto'}
                                                onClick={() => {
                                                    const newArray = [...inputArr]

                                                    newArray.splice(inputIndex, 1)

                                                    formsDispatch({
                                                        type: 'UPDATE_FORM',
                                                        id: form.id,
                                                        columnOrder: Number(formInput
                                                            .answerValue || 0),
                                                        value: newArray
                                                    })
                                                }}>
                                                <i className={'fa-light fa-minus mx-auto'}></i>
                                            </div>

                                        </div>
                                    )
                                }

                                {
                                    inputIndex === inputArr.length - 1 && (
                                        <div className={'col-auto'}>
                                            <div className={'question-plussign ms-auto'}
                                                onClick={() => {
                                                    formsDispatch({
                                                        type: 'UPDATE_FORM',
                                                        id: form.id,
                                                        columnOrder: Number(formInput
                                                            .answerValue || 0),
                                                        value: [
                                                            ...inputArr,
                                                            ''
                                                        ]
                                                    })
                                                }}>
                                                <i
                                                    className={'fa-light fa-plus mx-auto'}></i>
                                            </div>

                                        </div>
                                    )
                                }
                            </div>)
                            return <li key={[
                                'input-cell', outerIndex, innerIndex, inputIndex
                            ].join('-')}
                            className={'align-middle text-center mb-4'}>
                                <div className={'row align-items-center'}>
                                    <div className={'col'}>
                                        <input
                                            className={[
                                                'form-control form-control-sm',
                                                enableValidation
                                                    ? _.isEmpty(inputString)
                                                        ? 'border-danger'
                                                        : ''
                                                    : ''
                                            ].join(' ')}
                                            type={'text'}
                                            defaultValue={inputString}
                                            onChange={(e) => {
                                                const newArray = [...inputArr]
                                                newArray[inputIndex] = e.target.value
                                                formsDispatch({
                                                    type: 'UPDATE_FORM',
                                                    id: form.id,
                                                    columnOrder: Number(formInput
                                                        .answerValue || 0),
                                                    value: newArray
                                                })
                                            }}
                                            onBlur={(e) => {
                                                const newArray = [...inputArr]
                                                newArray[inputIndex] = e.target.value
                                                formsDispatch({
                                                    type: 'UPDATE_FORM',
                                                    id: form.id,
                                                    columnOrder: Number(formInput
                                                        .answerValue || 0),
                                                    value: newArray
                                                })
                                            }}
                                        />

                                    </div>

                                    {showButtons}

                                </div>

                            </li>
                        })

                        input = <ol type={'a'} className={'mb-0'}>
                            {
                                inputList
                            }
                        </ol>
                    }

                    return <td key={['table-cell', outerIndex, innerIndex].join('-')}
                        className={[
                            rowHasInputList ? '' : 'align-middle',
                            'text-center'
                        ].join(' ')}>
                        <div className={['answer-type', answerType].join(' ')}>{input}</div>
                    </td>
                })

            return <React.Fragment key={['table-row', outerIndex].join('-')}>
                {rowOne}
                <tr>
                    {/* then an extra td because the first one is taken */}
                    <td colSpan={rowOneInputs.length || 0}></td>
                    {rowTwo}
                    <td className={[
                        rowHasInputList ? 'pt-4' : 'align-middle',
                        'text-center ',
                        'cursor-pointer'
                    ].join(' ')
                    }
                    >
                        <div className={'question-plussign ms-auto'}
                            onClick={() => {
                                formsDispatch({
                                    type: 'DELETE_FORM',
                                    index: outerIndex
                                })
                            }}>
                            <i className={`fa-light
                        ${ 'fa-trash px-1' }`}></i>
                        </div>

                    </td>
                </tr>
            </React.Fragment>
        })

        return <div>

            <table className={'table table-borderless'}>
                <thead>
                    <tr>
                        {
                            _.map(obj.question?.answerQuestions
                                ?.[ids.answerIndex].questionAnswers, (obj, i) => {
                                return <th className={'align-middle text-center'}
                                    key={['th', i].join('-')
                                    }>
                                    {obj.answerName}
                                </th>
                            })
                        }
                        <th className={'align-middle text-center'}>{strings.reason_with_me
                            ?.text['open-list'].action}</th>
                    </tr>
                </thead>
                <tbody>
                    {isMobile ? mobileRender : desktopRender}
                    <tr>
                        <td className={[
                            'align-middle text-end'
                        ].join(' ') }
                        colSpan={2 + (obj.question?.answerQuestions
                            ?.[ids.answerIndex].questionAnswers.length || 0)}>
                            <button type={'button'} className={[
                                'px-5 btn btn-dark mx-auto'
                            ].join(' ')}
                            onClick={addForm}>
                                {strings.app?.text.add}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        // turning this into undefined cause the removeChild node crash
    }, [strings, formsState, isMobile, enableValidation])

    return <div className={'col px-4'}>
        <div className={'card multi-question-card'}>
            <div className={'card-body question-open-list'}>{
                obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                    ? <h5>
                        {
                            obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                        }
                    </h5>
                    : ''
            }

            {
                obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                    ? <span className={'mb-2'}>
                        {
                            obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                        }
                    </span>
                    : ''
            }
            {input}
            </div>
        </div>
    </div>
}

export default OpenList
