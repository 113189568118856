
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { smartSearch } from '@app/app.method'
import { selectStrings } from '@app/slices/slice.app'
import { setAnswerValue } from '@reasonWithMe/slice'
import { QuestionAnswer, QuestionInterfaceActions, ReasonWithMeState } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
}

const MultiTile = ({
    questionInterface,
    componentDispatch
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const dispatch = useAppDispatch()

    const [search] = useState<string>('')
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const input = useMemo(() => {
        /** how should the input be rendered */
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', data.question?.questionId
            ].join('')

            const isChecked = _.includes(
                answerValue,
                obj.acqValue
            )

            const isDisabled = _.isArray(answerValue)
                ? data.question?.questionAnswersAllowed
                    ? answerValue.length >= (data.question?.questionAnswersAllowed || 0)
                        ? data.question.questionAnswersAllowed > 1
                        : false
                    : false
                : false

            const selectionCellOnclick = () => {
                if (_.isArray(answerValue)) {
                    const found = _.find(answerValue, o => {
                        return (
                            o === obj.acqValue
                        )
                    })

                    if (found !== undefined) {
                        /** remove from list. */
                        const arr = _.filter(answerValue, o => {
                            return (
                                o !== found
                            )
                        })

                        /** if the arr has no elements, turn to undefined */
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: arr.length <= 0 ? undefined : arr
                            })
                        } else {
                            dispatch(setAnswerValue(arr.length <= 0 ? undefined : arr))
                        }
                    } else {
                        if (data.question?.questionAnswersAllowed
                            ? data.question.questionAnswersAllowed === 1
                            : false
                        ) {
                            if (componentDispatch !== undefined) {
                                componentDispatch({
                                    type: 'SET_ANSWER_VALUE',
                                    value: [obj.acqValue]
                                })
                            } else {
                                dispatch(
                                    setAnswerValue(
                                        [obj.acqValue]
                                    )
                                )
                            }
                        } else {
                            if (componentDispatch !== undefined) {
                                componentDispatch({
                                    type: 'SET_ANSWER_VALUE',
                                    value: _.concat(answerValue, obj.acqValue)
                                })
                            } else {
                                dispatch(
                                    setAnswerValue(
                                        _.concat(answerValue, obj.acqValue)
                                    )
                                )
                            }
                        }
                    }
                } else {
                    if (obj.acqValue === answerValue) {
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: undefined
                            })
                        } else {
                            dispatch(setAnswerValue(undefined))
                        }
                    } else {
                        if (componentDispatch !== undefined) {
                            componentDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: [obj.acqValue]
                            })
                        } else {
                            dispatch(setAnswerValue([obj.acqValue]))
                        }
                    }
                }
            }

            // if there are too many choices, use col-6
            return <div className={'col'} key={key}>
                <input type={'checkbox'}
                    className={[
                        'btn-check',
                        isDisabled && !isChecked ? 'disabled' : ''
                    ].join(' ')}
                    autoComplete={'off'}
                    id={key}
                    checked={isChecked}
                    // use class name instead.
                    // disabled={isDisabled && !isChecked}
                    onChange={() => {
                        if (data.question?.questionAnswersAllowed === 1) {
                            selectionCellOnclick()
                        } else {
                            if (!(isDisabled && !isChecked)) {
                                selectionCellOnclick()
                            }
                        }
                    }}
                />
                <label className={'btn btn-dummy'} htmlFor={key}>

                    <div className={'question-checkbox'}>
                        <div className={'card position-relative'}>
                            <div className={'img-wrapper '}>
                                <img
                                    src={obj.acqImage}
                                    className={'img-fluid'}
                                    alt={obj.acqName}
                                />
                                <div className={'gradient-background'}></div>
                                <div className={'checked-background'}></div>
                            </div>
                            <div
                                className={'card-header end-0 position-absolute text-end top-0'}
                            >
                                <div className={'question-checkmark'}>
                                    <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                </div>
                            </div>

                            <div className={
                                'bottom-0 card-footer position-absolute text-start'
                            }>
                                <span
                                    className={'card-text'}>
                                    {obj.acqName}
                                </span>
                            </div>
                        </div>
                    </div>

                </label>
            </div>
        }

        // filter by acqName.
        const filteredData = smartSearch(data.question?.questionAnswers || [], [], search)

        return <div className={'container-fluid'}>
            <div className={['row g-0 btn-group-checkbox-tile mt-3',
                isMobile ? ' row-cols-2' : ' row-cols-3'].join(' ')}
            role={'group'}>
                {
                    _.map(filteredData, renderInputs)
                }
            </div>
        </div>
    }, [questionInterface, search, isMobile])

    return <div className={'question-multi-tile'}>
        {/* place search text box here */}
        {/* <div className={'form-group mb-4'}>
            <div className={'form-control-wrapper'}>
                <input type={'search'}
                    className={'form-control form-search'}
                    placeholder={strings.app?.text.search.text}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    id={IDS.LIST.SEARCH} />
                <label
                    className={'form-floaticon'}
                    htmlFor={IDS.LIST.SEARCH}
                >
                    <div
                        className={'icon '}>
                        <i className={'fa-light fa-search'} aria-hidden={'true'}></i>
                    </div>
                </label>
            </div>
        </div> */}
        <p className={'text-center'}>{
            `${ strings.reason_with_me?.text.list.allowed || '' }: ${
                questionInterface.currentReasonWithMeResponse
                    .reasoningData.question
                    ?.questionAnswersAllowed
            }`.trim()
        }</p>
        {input}
    </div>
}

export default MultiTile
