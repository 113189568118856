import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    useCompleteCareplanProgressReasoningMutation,
    useGetReasoningMutation,
    useReasonWithMeMutation
} from '@careplan/api'
import ResultsPage from '@careplan/components/careplanStep/reasoningCard/ResultsPage'
import {
    BuildingBlockProgress,
    BuildingBlockProgressActions,
    CompleteCareplanProgressRequest,
    CompleteCareplanProgressResponse
} from '@careplan/type'
import { back } from '@lagunovsky/redux-react-router'
import Bodymap from '@reasonWithMe/components/types/Bodymap'
import Bool from '@reasonWithMe/components/types/Bool'
import FloatInput from '@reasonWithMe/components/types/FloatInput'
import List from '@reasonWithMe/components/types/List'
import OpenMultiQuestion from '@reasonWithMe/components/types/multi-question/MultiQuestion'
import MultiList from '@reasonWithMe/components/types/MultiList'
import MultiTile from '@reasonWithMe/components/types/MultiTile'
import NumberInput from '@reasonWithMe/components/types/NumberInput'
import Open from '@reasonWithMe/components/types/Open'
import OpenList, {
    OpenListPreviousQuestions
} from '@reasonWithMe/components/types/open-list/OpenList'
import Scale from '@reasonWithMe/components/types/Scale'
import SingleDate from '@reasonWithMe/components/types/SingleDate'
import Text from '@reasonWithMe/components/types/Text'
import {
    GetReasoningRequest,
    GetReasoningResponse,
    OpenListValues,
    OpenMultiQuestionOption,
    QuestionInterfaceActions,
    QuestionType,
    ReasonWithMeRequest,
    ReasonWithMeState
} from '@reasonWithMe/type'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { toast } from 'react-toastify'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import remarkDefinitionList from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'

import { TokenAuth } from '@app/types/type.app'
import Resume from '@reasonWithMe/components/types/Resume'
import Toggle from '@reasonWithMe/components/types/Toggle'
import View from '@reasonWithMe/components/types/view/Main'
import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    MutationDefinition
} from '@reduxjs/toolkit/dist/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { getUnixTime } from 'date-fns'
import { useMediaQuery } from 'react-responsive'
import { useDebouncedCallback } from 'use-debounce'

/** NOW THAT THIS IS A SEPARATE INSTANCE OF THE QUESTION INTERFACE
 * YOU CAN'T USE THE GLOBAL SLICE HERE ANYMORE. create a useReducer
 */

type CompleteCareplanProgressMutation = MutationTrigger<
  MutationDefinition<
    CompleteCareplanProgressRequest & TokenAuth,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    never,
    CompleteCareplanProgressResponse,
    'careplanV10ApiV10Api'
  >
>;

export type ComponentProps = {
    personalCareplanId: string
    // optional because the stepid shouldn't be present when rendered in the RwmModal.
    personalCareplanStepId?: string,
    progressDispatch: React.Dispatch<BuildingBlockProgressActions>,
    // became optional parameters
    buildingBlockProgress?: BuildingBlockProgress,
    updateProgress?: (requestData: {
        obj: BuildingBlockProgress, percentage: number, miscVar?: any
    }) => Promise<void>
    fromIncompleteOperation?: {
        setStartIncompleteCareplan: (value: React.SetStateAction<boolean>) => void,
        personalCareplanId: string,
        completeCareplanProgress: CompleteCareplanProgressMutation,
    }
    fromCompleteOperation?: {
        setStartCompleteCareplan: (value: React.SetStateAction<boolean>) => void,
        personalCareplanId: string,
        completeCareplanProgress: CompleteCareplanProgressMutation,
    }
    setQuestionType?: React.Dispatch<React.SetStateAction<QuestionType | undefined>>
    // prop created to change the styling of the next button.
    isModal: boolean
} & ReasonWithMeRequest

const QuestionInterface = (props: ComponentProps) => {
    const activeModules = useAppSelector(selectActiveModules)
    const [reasonWithMe, reasonWithMeMutation] = useReasonWithMeMutation()
    const [getReasoning, getReasoningMutation] = useGetReasoningMutation()
    const [completeCareplanProgressReasoning,
        completeCareplanProgressReasoningMutation] = useCompleteCareplanProgressReasoningMutation()

    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const dispatch = useAppDispatch()
    const validateRoute = useValidateRoute()

    const token = useAppSelector(selectToken)
    const strings = useAppSelector(selectStrings)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    // a useState to enable validation for the current question.
    const [enableValidation, setEnableValidation] = useState(false)
    // for general div to scroll up to (multi-question atm)
    const divRef = useRef<HTMLDivElement>(null)

    // Function to scroll the div to the top
    const scrollToTop = () => {
        // console.log('scroll multi-question to top')
        if (divRef.current) {
            // console.log('reference is found')
            divRef.current.scrollIntoView()
        }
    }

    // a useState to enable truncation on form submission
    const [enableTruncation, setEnableTruncation] = useState(false)

    const [
        questionInterfaceState,
        questionInterfaceDispatch
    ] = useReducer(
        (
            state: ReasonWithMeState['questionInterface'] & { showResultsPage: boolean },
            action: QuestionInterfaceActions
        ) => {
            switch (action.type) {
                case 'SET_REASONING_SET': {
                    return produce(state, draft => {
                        draft.firstReasoningSetId = action.value.data.reasoningSetId
                        draft.reasoningSetVersion = action.value.data.reasoningSetVersion
                    })
                } case 'SET_CURRENT_REASONING_DATA': {
                    return produce(state, draft => {
                        draft.currentReasonWithMeResponse.reasoningData = action.value
                    })
                } case 'SET_CURRENT_REASONING_RESULTS': {
                    return produce(state, draft => {
                        draft.currentReasonWithMeResponse.results = action.value
                    })
                } case 'SET_ANSWER_VALUE': {
                    return produce(state, draft => {
                        draft.currentReasonWithMeResponse.answerValue = action.value
                    })
                } case 'SET_RESULTS_PAGE': {
                    return produce(state, draft => {
                        draft.showResultsPage = action.value
                    })
                }
            }
        }, {
        /** retrieved from selecting a choice from the bodymap interface */
            firstReasoningSetId: props.data.reasoningSetId,
            /** only used once in reasonWithMe. */
            reasoningSetVersion: props.data.reasoningSetVersion,
            currentReasonWithMeResponse: {
                answerValue: undefined,
                reasoningData: {
                    stop_value: false,
                    reasoningSessionId: ''
                }
            },
            showResultsPage: false
        }
    )

    const unsubscribeReasonWithMe = () => {
        const unsubscribeMutation = reasonWithMe({ data: {} } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const fetchReasonWithMe = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeReasonWithMe()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.reasonWithMe.path,
                        true
                    )

                    if (isValid && newToken.value && questionInterfaceState.firstReasoningSetId &&
                        questionInterfaceState.reasoningSetVersion) {
                        promise = reasonWithMe({
                            authToken: newToken.value,
                            data: {
                                // will use test data for now. this is step 3.
                                // will use the dispatches from the slice
                                // at step 2.
                                reasoningSetId: questionInterfaceState.firstReasoningSetId,
                                reasoningSetVersion: questionInterfaceState.reasoningSetVersion
                            }
                        })
                    } else {
                        toast.error(strings.reason_with_me?.message.error.fetch_reason_with_me,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    useEffect(() => {
        fetchReasonWithMe(token)
    }, [activeModules.id])

    /** proceed to next question now. */
    const goToNextQuestion = async (
        token:TokenData
    ) => {
        if (token.valid) {
            const responseData = questionInterfaceState.currentReasonWithMeResponse.reasoningData
            const valueType = responseData.question?.valueType
            let answerValue = questionInterfaceState.currentReasonWithMeResponse.answerValue

            /** there will be cases where you want to present the answerValue a specific
             * way. For example, for lists, if the answer is one, turn it to a string.
             */

            if (
                responseData.question?.questionType === 'list' ||
                responseData.question?.questionType === 'multi-tile' ||
                responseData.question?.questionType === 'multi-list'
            ) {
                if (_.isArray(answerValue) && answerValue.length === 1) {
                    // answerValue = answerValue[0]
                    if (valueType === 'list') {
                        // answerValue = answerValue
                    } else if (valueType === 'tuple') {
                        // answerValue = answerValue
                    } else if (valueType === 'bool') {
                        answerValue = Boolean(answerValue[0])
                    } else if (valueType === 'int') {
                        answerValue = parseInt(answerValue[0], 10)
                    } else if (valueType === 'float') {
                        const floatValue = parseFloat(answerValue[0])

                        // Check if the value is an integer (no decimal point)
                        if (Number.isInteger(floatValue)) {
                            answerValue = parseFloat(floatValue + '.0')
                        } else {
                            answerValue = floatValue
                        }
                    } else if (valueType === 'str') {
                        answerValue = String(answerValue[0])
                    }
                } else if (_.isArray(answerValue && answerValue.length > 1)) {
                    answerValue = JSON.stringify(answerValue)
                }
            } else if (responseData.question?.questionType === 'scale') {
                if (_.isArray(answerValue) && answerValue.length === 1) {
                    answerValue = answerValue[0]
                } else if (_.isArray(answerValue && answerValue.length > 1)) {
                    answerValue = JSON.stringify(answerValue)
                }
            } else if (responseData.question?.questionType === 'int32') {
                /** if it's a string, convert to number */
                // console.log('answer value before: ', answerValue)
                // console.log('typeof answerValue: ', typeof answerValue)
                // if you type +15-, the answerValue is an empty stirng.
                if (typeof answerValue === 'string') {
                    if (answerValue.length <= 0) {
                        answerValue = undefined
                    } else {
                        answerValue = Number(answerValue)
                    }
                }
                // console.log('answer value after: ', answerValue)
            } else if (responseData.question?.questionType === 'float') {
                /** if it's a string, convert to number */
                if (typeof answerValue === 'string') {
                    if (answerValue.length <= 0) {
                        answerValue = undefined
                    } else {
                        answerValue = Number(answerValue)
                    }
                }
            } else if (responseData.question?.questionType === 'bool') {
                /** if it's a string, convert to number */
                // console.log('Type is open and getting acqValue')
                if (_.has(answerValue, 'acqName') && _.has(answerValue, 'acqValue')) {
                    answerValue = answerValue.acqValue
                }
            } else if (responseData.question?.questionType === 'open-list') {
                // answerValue = JSON.stringify(answerValue)
                // shouldn't be a stringified array according to tim.
            } else if (responseData.question?.questionType === 'multi-question') {
                // answerValue = JSON.stringify(answerValue)
                // shouldn't be a stringified array according to tim.
            } else if (responseData.question?.questionType === 'toggle') {
                if (_.isArray(answerValue) && answerValue.length === 1) {
                    answerValue = answerValue[0]
                } else if (_.isArray(answerValue && answerValue.length > 1)) {
                    answerValue = JSON.stringify(answerValue)
                }
            }

            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            if (responseData.question && responseData.reasoningSetId) {
                const foundApiPath = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.careplanPatient.moduleName,
                    MODULE_TABLE.careplanPatient.apiPaths.getReasoningId.path,
                    true
                )

                const completeCareplanProgressCallback = (data:
                    CompleteCareplanProgressResponse) => {
                    if (data.status === 'OK') {
                        // toast success message.
                        // be redirected to careplan overview with the steps.
                        if (props.fromCompleteOperation) {
                            toast.success(
                                data.message, { ...TOASTIFY_DEFAULT_OPTIONS }
                            )

                            props.fromCompleteOperation
                                .setStartCompleteCareplan(false)

                            // then navigate to careplans page with steps
                            const isValid = validateRoute(
                                activeModules.arr,
                                MODULE_TABLE.careplanPatient.moduleName,
                                MODULE_TABLE.careplanPatient
                                    .routes.careplan,
                                true
                            )

                            if (isValid) {
                                // now push but replace
                                // :personalCareplanId with something else
                                // dispatch(push(
                                //     _.replace(isValid.route,
                                //         ':personalCareplanId',
                                //         props.fromCompleteOperation.personalCareplanId
                                //     )
                                // ))
                                dispatch(back())
                            }
                        }
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                }

                const incompleteCareplanProgressCallback = (data:
                    CompleteCareplanProgressResponse) => {
                    if (data.status === 'OK') {
                        // toast success message.
                        // be redirected to careplan overview with the steps.
                        if (props.fromIncompleteOperation) {
                            toast.success(
                                data.message, { ...TOASTIFY_DEFAULT_OPTIONS }
                            )

                            props.fromIncompleteOperation
                                .setStartIncompleteCareplan(false)

                            // then navigate to careplans page with steps
                            const isValid = validateRoute(
                                activeModules.arr,
                                MODULE_TABLE.careplanPatient.moduleName,
                                MODULE_TABLE.careplanPatient
                                    .routes.careplan,
                                true
                            )

                            if (isValid) {
                                // now push but replace
                                // :personalCareplanId with something else
                                // dispatch(push(
                                //     _.replace(isValid.route,
                                //         ':personalCareplanId',
                                //         props.fromIncompleteOperation.personalCareplanId
                                //     )
                                // ))
                                dispatch(back())
                            }
                        }
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                }

                const getReasoningCallback = async (data: GetReasoningResponse) => {
                    /** if status is OK, set the data */
                    if (data.status === 'OK') {
                        /** adding new stuff now. Check if the stop_value is true.
                         * if so, navigate them to a new page instead of this component.
                         * This page will display results of answering the reasoning set.
                         */

                        if (data.data.stop_value === true) {
                            // don't forget to set the reasoning results.
                            questionInterfaceDispatch({
                                type: 'SET_CURRENT_REASONING_RESULTS',
                                value: data.data
                            })

                            // now you also need to create a component version
                            // of the results page.
                            // just add a boolean to show the results page
                            // and hide the rest.
                            questionInterfaceDispatch({
                                type: 'SET_RESULTS_PAGE',
                                value: true
                            })

                            // when done, update the progress.
                            props.updateProgress && props.buildingBlockProgress &&
                            props.updateProgress({
                                obj: props.buildingBlockProgress,
                                percentage: 100
                            })

                            // close the modal and direct the user to the
                            // careplans page.

                            const newToken = await revalidateToken({
                                value: token.value,
                                id: token.id
                            }, token.mode)

                            const isValid = validateAPIPath(
                                activeModules.arr,
                                MODULE_TABLE.careplanPatient.moduleName,
                                MODULE_TABLE.careplanPatient.apiPaths.completeCareplanProgress.path,
                                true
                            )

                            if (isValid) {
                                if (props.personalCareplanStepId && props.fromIncompleteOperation) {
                                    props.fromIncompleteOperation.completeCareplanProgress({
                                        authToken: newToken.value,
                                        personalCareplanStepId: props.personalCareplanStepId,
                                        data: {
                                            completeType: 'incomplete'
                                        }
                                    }).unwrap().then(incompleteCareplanProgressCallback)
                                }

                                if (props.personalCareplanStepId && props.fromCompleteOperation) {
                                    props.fromCompleteOperation.completeCareplanProgress({
                                        authToken: newToken.value,
                                        personalCareplanStepId: props.personalCareplanStepId,
                                        data: {
                                            completeType: 'complete'
                                        }
                                    }).unwrap().then(completeCareplanProgressCallback)
                                }
                            }

                            /** now reset the values */
                            questionInterfaceDispatch({
                                type: 'SET_ANSWER_VALUE',
                                value: undefined
                            })
                        } else {
                            questionInterfaceDispatch({
                                type: 'SET_CURRENT_REASONING_DATA',
                                value: data.data
                            })

                            /** now reset the values */

                            if (!(data.data.expectedType !== undefined && data
                                .data.retrievedType !== undefined)) {
                                // if it's a single date that's next, change it
                                // to new Date() instead.

                                if (data.data.question?.questionType === 'date') {
                                    questionInterfaceDispatch({
                                        type: 'SET_ANSWER_VALUE',
                                        value: getUnixTime(new Date())
                                    })
                                } else if (data.data.question?.questionType === 'toggle') {
                                    questionInterfaceDispatch({
                                        type: 'SET_ANSWER_VALUE',
                                        value: _.map(data.data.question.questionAnswers, (o) => {
                                            // should return what their default value is.
                                            return o.acqValue
                                        })
                                    })
                                } else {
                                    questionInterfaceDispatch({
                                        type: 'SET_ANSWER_VALUE',
                                        value: undefined
                                    })
                                }
                            }
                        }
                    } else {
                        if (data.validateValue === false) {
                            toast.error(data.data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        }
                    }
                }

                // NOTE: not all need to show a toast error.
                // only do this error toast method AFTER authentication.
                if (foundApiPath && newToken.value) {
                    const requestData: GetReasoningRequest['data'] = {
                        answerValue,
                        questionId: responseData.question?.questionId,
                        reasoningSetId: responseData.reasoningSetId,
                        reasoningSessionId: responseData.reasoningSessionId
                    }

                    // added by resume.

                    if (responseData.question?.questionType === 'resume') {
                        requestData.answerValue = true
                        requestData.reasoningSessionId = answerValue.reasoningId
                    }

                    getReasoning({
                        authToken: newToken.value,
                        data: requestData
                    }).unwrap()
                        .then(getReasoningCallback).catch((error) => {
                            if (error) {
                                const message = getErrorText(getReasoningMutation.error)
                                console.error(message)
                                toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                            }
                        })
                } else {
                    if (strings.login?.message.error.api_path) {
                        toast.error(
                                `${ MODULE_TABLE.careplanPatient.apiPaths
                                    .getReasoningId.path }: 
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                }
            } else {
                toast.error(
                    strings.reason_with_me?.message.error.missing_parameters || '',
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }

    useEffect(() => {
        if (reasonWithMeMutation.error) {
            const message = getErrorText(reasonWithMeMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [reasonWithMeMutation.error])

    useEffect(() => {
        // dispatch the questionInterfaceState to store the answers so
        // they can go back to it. Replace the questionInterfaceState
        // values once getReasoningID has invoked.
        const data = reasonWithMeMutation.data

        if (data?.data) {
            if (reasonWithMeMutation.data?.status === 'OK') {
                questionInterfaceDispatch({
                    type: 'SET_CURRENT_REASONING_DATA',
                    value: data?.data
                })

                // go here if there are any values besides undefined you want to assign.

                if (data.data.question?.questionType === 'date') {
                    questionInterfaceDispatch({
                        type: 'SET_ANSWER_VALUE',
                        value: getUnixTime(new Date())
                    })
                } else if (data.data.question?.questionType === 'toggle') {
                    questionInterfaceDispatch({
                        type: 'SET_ANSWER_VALUE',
                        value: _.map(data.data.question.questionAnswers, (o) => {
                            // should return what their default value is.
                            return o.acqValue
                        })
                    })
                } else {
                    questionInterfaceDispatch({
                        type: 'SET_ANSWER_VALUE',
                        value: undefined
                    })
                }

                // only do this if fromIncompleteOperation or
                // fromCompleteOperation is truthy.

                if (props.fromCompleteOperation || props.fromIncompleteOperation) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths
                            .completeCareplanProgressReasoning.path,
                        true
                    )

                    if (isValid && props.personalCareplanStepId) {
                        // AFTER THIS, make new call from IHD-214.
                        completeCareplanProgressReasoning({
                            authToken: token.value,
                            data: {
                                reasoningSessionId: data.data.reasoningSessionId,
                                reasoningSetId: data.data.reasoningSetId
                            },
                            personalCareplanStepId: props.personalCareplanStepId || ''
                        })
                    }
                }
            } else if (data.status === 'NOT_OK') {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }

        // currentWorkflowStep despite being an important dependency
        // is causing the call to be executed twice.
        // ideally, you don't want to remove this dependency
        // as it is involved in this useEffect. Using a debounce in lodash did
        // not work at all. No choice but to remove the dependency
    }, [reasonWithMeMutation.data])

    useEffect(() => {
        const data = completeCareplanProgressReasoningMutation.data

        if (data?.data) {
            if (completeCareplanProgressReasoningMutation.data?.status === 'OK') {
                //
            } else if (data.status === 'NOT_OK') {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [completeCareplanProgressReasoningMutation.data])

    // useEffect for setting the question type for RwmModal.tsx
    useEffect(() => {
        const data = questionInterfaceState.currentReasonWithMeResponse.reasoningData
        props.setQuestionType && props.setQuestionType(data.question?.questionType)
    }, [questionInterfaceState])

    const Question = useMemo(() => {
        /** how should the input be rendered */
        const data = questionInterfaceState.currentReasonWithMeResponse.reasoningData
        let input = <div></div>

        switch (data?.question?.questionType) {
            case 'multi-tile': input = <MultiTile questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'multi-list': input = <MultiList questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'scale': input = <Scale questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'list': input = <List questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'int32':
                input = <NumberInput
                    questionInterface={questionInterfaceState}
                    componentDispatch={questionInterfaceDispatch}
                    goToNextQuestion={goToNextQuestion}
                />
                break
            case 'bodymap': input = <Bodymap questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'bool': input = <Bool questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'toggle': input = <Toggle questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch} />
                break
            case 'open':
                input = <Open
                    questionInterface={questionInterfaceState}
                    componentDispatch={questionInterfaceDispatch}
                    goToNextQuestion={goToNextQuestion}
                />
                break
            case 'float':
                input = <FloatInput
                    questionInterface={questionInterfaceState}
                    componentDispatch={questionInterfaceDispatch}
                    goToNextQuestion={goToNextQuestion}
                />
                break
            case 'text': input = <Text questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'date': input = <SingleDate questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'open-list': input = <OpenList questionInterface={questionInterfaceState}
                enableTruncation={enableTruncation}
                setEnableTruncation={setEnableTruncation}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'multi-question': input = <OpenMultiQuestion
                questionInterface={questionInterfaceState}
                enableValidation={enableValidation}
                componentDispatch={questionInterfaceDispatch}
                divRef={divRef}
                enableTruncation={enableTruncation}
                setEnableTruncation={setEnableTruncation}
            />
                break
            case 'view': input = <View questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            case 'resume': input = <Resume questionInterface={questionInterfaceState}
                componentDispatch={questionInterfaceDispatch}
            />
                break
            default:
                break
        }

        const forMarkdownContent = _.isString(data?.question?.questionDescription)
            ? data?.question?.questionDescription
                .replace(/==([^=]+)==/g, '<mark>$1</mark>')
                .replace(/~(\d+)~/g, '<sub>$1</sub>')
                .replace(/~~([^~]+)~~/g, '<s>$1</s>')
            : ''

        const content = <div>
            {/* question header */}
            {
                props.isModal
                    ? <h5 className={'mt-3 mb-3 col text-center'}>
                        {data?.question?.questionHeader}
                    </h5>
                    : <h5 className={'mt-3 mb-3 col text-center'}>
                        {data?.question?.questionHeader}
                    </h5>
            }
            {
                data?.question?.questionType !== 'text'
                    ? <span className={'my-4 fw-light col text-center'}>
                        <div className={'markdown'}>
                            <ReactMarkdown
                                components={{
                                    // supersub replaces markdown with del tags
                                    // for somereason.
                                    del: (props) => <sub {...props} />,
                                    ul: (props) => {
                                        const modifiedProps = { ...props }
                                        // eslint-disable-next-line react/prop-types
                                        modifiedProps.ordered = props.ordered.toString() as any

                                        if (modifiedProps.className && modifiedProps.className
                                            .includes('contains-task-list')) {
                                            return <ul
                                                {...modifiedProps}
                                                className={[
                                                    'contains-task-list list-unstyled ps-4'
                                                ].join(' ')}
                                            />
                                        } else {
                                            return <ul
                                                {...modifiedProps}

                                            />
                                        }
                                    }
                                }}
                                linkTarget={'_blank'}
                                remarkPlugins={[
                                    remarkBreaks,
                                    remarkGfm,
                                    supersub,
                                    remarkEmoji,
                                    remarkDefinitionList,
                                    remarkHeadingId
                                ]} rehypePlugins={[
                                    rehypeRaw
                                ]}
                            >
                                {`${ forMarkdownContent }`}
                            </ReactMarkdown>
                        </div>
                    </span>
                    : ''
            }

            {
                getReasoningMutation.isLoading ? '' : input
            }
        </div>

        return (
            content
        )
    }, [strings, getReasoningMutation, questionInterfaceState, token,
        enableTruncation, enableValidation])

    const debounceGoToNextQuestion = useDebouncedCallback(goToNextQuestion, 300)

    const NextButton = useMemo(() => {
        /** how should the input be rendered */
        const data = questionInterfaceState.currentReasonWithMeResponse.reasoningData
        const answerValue = questionInterfaceState.currentReasonWithMeResponse.answerValue

        /** push functions in this array wouldn't work on child components. */
        const nextButtonDisabled: boolean[] = [
            answerValue === null,
            answerValue === undefined
        ]

        switch (data?.question?.questionType) {
            case 'multi-tile':
                break
            case 'multi-list':
                break
            case 'scale':{
                nextButtonDisabled.push(answerValue === undefined)
                break
            }
            case 'list':
                break
            case 'int32': {
                const ranges = data.question?.questionAnswers[0]
                const lower = ranges.rangeLower || 0
                const upper = ranges.rangeUpper || 0

                nextButtonDisabled.push(
                    (data?.question?.questionType === 'int32' &&
                        _.inRange(answerValue, lower, upper + 1) === false)
                )
            } break
            case 'bodymap':
                if (_.isArray(answerValue)) {
                    if (data.question.questionAnswersAllowed === 0) {
                    // should be disabled.
                        if (answerValue?.length === data.question.questionAnswersAllowed) {
                            nextButtonDisabled.push(true)
                        }
                    }
                } else {
                    console.log('bodymap answer is not an array: ', answerValue)
                    // the button should be disabled.
                    nextButtonDisabled.push(true)
                }
                break
            case 'bool':
                break
            case 'toggle':
                // if questionAnswersAllowed === 1, all answers must be true.

                if (data.question.questionAnswersAllowed === 1) {
                    nextButtonDisabled.push(
                        _.some(answerValue, (o) => {
                            return o === false
                        })
                    )
                }
                break
            case 'open':
                if (answerValue !== undefined) {
                    if (typeof answerValue === 'string') {
                        nextButtonDisabled.push((answerValue.trim().length <= 0))
                    } else {
                        nextButtonDisabled.push(false)
                    }
                } else {
                    nextButtonDisabled.push(true)
                }
                break
            case 'float': {
                const ranges = data.question?.questionAnswers[0]
                const lower = ranges.rangeLower || 0
                const upper = ranges.rangeUpper || 0

                nextButtonDisabled.push(
                    (data?.question?.questionType === 'float' &&
                        _.inRange(answerValue, lower, upper + 1) === false)
                )
            } break
            case 'text':
                break
            case 'date':
                break
            case 'open-list':{
                let missingValue = false

                // console.log(answerValue)
                // always starts with answerValue.answers
                if (_.isArray(answerValue?.answers)) {
                    const answers = answerValue?.answers as OpenListPreviousQuestions[]
                    // catch how many rows have NOT been answered.
                    let catchAnsweredRows = 0

                    _.forEach(answers, (o) => {
                        // if there is at least one truthy userTypedAnswer, add to record.
                        const addToList = _.some(o.inputs, (p) => {
                            const userTypedAnswer = p.answerValue

                            if (typeof userTypedAnswer === 'string') {
                                // checks if the input is lengthy
                                return userTypedAnswer.length > 0
                            } else if (_.isArray(userTypedAnswer)) {
                                // checks if the input type list has more than one truthy record.
                                return _.some(
                                    userTypedAnswer, (q) => {
                                        return q.length > 0
                                    }
                                )
                            } else if (typeof userTypedAnswer === 'boolean') {
                                // this is included too because we'll have to treat it as
                                // not interacted with.
                                return userTypedAnswer === true
                            } else {
                                return false
                            }
                        })

                        if (addToList === true) {
                            catchAnsweredRows++
                        }
                    })

                    if (data.question.questionAnswersAllowed === 0) {
                        if (catchAnsweredRows === 0) {
                            missingValue = true
                        }
                    } else {
                        if (catchAnsweredRows !== data.question.questionAnswersAllowed) {
                            missingValue = true
                        }
                    }
                }

                nextButtonDisabled.push(missingValue)
                break
            }
            case 'multi-question':{
                let missingValue = false

                if (_.isArray(answerValue?.answers)) {
                    let arr = answerValue?.answers as OpenMultiQuestionOption[]
                    // if questionAnswersAllowed is 0, everything must be answered.
                    // console.log('answer all?: ', data.question.questionAnswersAllowed)
                    if (data.question.questionAnswersAllowed === 0) {
                        // check everything
                    } else {
                        // disabled button will be true
                        // if you didn't meet the number of clicked buttons.
                        // apply validation after
                        arr = _.filter(arr, (o) => {
                            return o.isClickedOnce === true
                        })
                    }

                    if (!arr.length) missingValue = true
                    else {
                        arr.forEach((item) => {
                            const nestedAnswers = item.answers

                            if (nestedAnswers.length === 0) {
                                missingValue = true
                            }

                            nestedAnswers.forEach(outer => {
                                outer.answer.forEach(inner => {
                                    if (inner?.actualValue === undefined) {
                                        missingValue = true
                                        // check if it's a string. then check for trailing spaces.
                                    } else if (
                                        typeof inner?.actualValue === 'string' &&
                                    inner?.actualValue.trim().length <= 0
                                    ) {
                                        missingValue = true
                                    } else if (_.isArray(inner?.actualValue)) {
                                        // new case for input-list. Go to every element and find
                                        // userTypedAnswer if it's truthy or not

                                        const openListCheck = inner
                                            ?.actualValue[0] &&
                                            _.has(inner?.actualValue[0], 'inputs')

                                        if (openListCheck) {
                                            const answerValues = inner.actualValue as OpenListValues[]
                                            // catch how many rows have NOT been answered.
                                            let catchAnsweredRows = 0

                                            console.log(answerValues)

                                            _.forEach(answerValues, (o) => {
                                                // if there is at least one truthy userTypedAnswer, add to record.

                                                const addToList = _.some(o.inputs, (p) => {
                                                    // it's not p.answerValue here in multi-question -> userTypedAnswer
                                                    const userTypedAnswer = p.userTypedAnswer

                                                    if (typeof userTypedAnswer === 'string') {
                                                        // checks if the input is lengthy
                                                        return userTypedAnswer.length > 0
                                                    } else if (_.isArray(userTypedAnswer)) {
                                                        // checks if the input type list has more than one truthy record.
                                                        return _.some(
                                                            userTypedAnswer, (q) => {
                                                                return q.length > 0
                                                            }
                                                        )
                                                    } else if (typeof userTypedAnswer === 'boolean') {
                                                        // this is included too because we'll have to treat it as
                                                        // not interacted with.
                                                        return userTypedAnswer === true
                                                    } else {
                                                        return false
                                                    }
                                                })

                                                if (addToList === true) {
                                                    catchAnsweredRows++
                                                }
                                            })

                                            if (data.question?.questionAnswersAllowed === 0) {
                                                if (catchAnsweredRows === 0) {
                                                    missingValue = true
                                                }
                                            } else {
                                                if (catchAnsweredRows !== data.question?.questionAnswersAllowed) {
                                                    missingValue = true
                                                }
                                            }
                                        }
                                    }
                                })
                            })
                        })
                    }
                }

                nextButtonDisabled.push(missingValue)
                break
            }
            default:
                break
        }

        // disable next button with more conditions.

        const buttonName = data.question?.questionType === 'view'
            ? data.question.questionAnswers[0]?.acqName
            : (
                data.question?.nextButtonValue ||
                            strings.reason_with_me?.text.get_reasoning_id.submit_button || ''
            )

        const buttonContent = getReasoningMutation.isLoading
            ? (
                <div className={'spinner-container d-inline'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.reason_with_me?.text.get_reasoning_id.loading_button || ''
                    }</span>
                </div>
            )
            : buttonName

        const buttonClassName = [
            props.isModal ? ' w-100' : '',
            'mt-3  btn btn-primary btn-lg submit-button px-5',
            _.find(nextButtonDisabled, (o) => o === true) ||
                getReasoningMutation.isLoading
                ? 'disabled'
                : ''

        ].join(' ')

        const onClickEvent = () => {
            console.log('next button clicked: ', nextButtonDisabled, data.question?.questionType)
            // now make the next call.
            // bullet 10 item in IHD-332. for multi-questions, make sure that you validate
            // all inputs in there. don't forget to highlight the buttons that are invalid
            if (_.find(nextButtonDisabled, (o) => o === true)) {
                // do validation for question type.
                if (data?.question?.questionType === 'multi-question') {
                    console.log('set validation to multi-question')
                    setEnableValidation(true)
                    scrollToTop()
                }
            } else {
                // if you are on open-list, you'll have to do something else.
                if (
                    data.question?.questionType === 'open-list'
                ) {
                    // set a flag to open-list to truncate the values.
                    setEnableTruncation(true)
                    // now make the call below but debounced.
                    // goToNextQuestion(token)
                    debounceGoToNextQuestion(token)
                } else if (
                    data.question?.questionType === 'multi-question'
                ) {
                    setEnableTruncation(true)
                    debounceGoToNextQuestion(token)
                } else {
                    goToNextQuestion(token)
                }
            }
        }

        return <div onClick={onClickEvent}> <a
            className={buttonClassName}
        >
            <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {buttonContent}
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>
        </a></div>
    }, [strings, activeModules, getReasoningMutation,
        questionInterfaceState, token])

    const rwmQuestionnairePage =
        <div className={!isMobile
            ? props.isModal
                ? 'container px-5 px-sm-3'
                : 'container px-0'
            : ''}>

            <div className={'row'}>
                <div className={'col-12 mx-auto'}>
                    {/* was supposed to be removed per Tim's request */}
                    {/* {ProgressBar} */}
                    {/* show fetching message if reasonWithMeMutation
                            isLoading AND if data is empty. */}
                    {
                        reasonWithMeMutation.isLoading && !reasonWithMeMutation.data
                            ? <small className={'d-block text-center py-2'}>
                                <div className={'spinner-container'}>
                                    <span
                                        className={'spinner-border spinner-border-sm'}
                                    ></span>
                                    <span className={'ms-2'}>{
                                        strings.reason_with_me?.message
                                            .loading.question_interface || ''
                                    }</span>
                                </div>
                            </small>
                            : ''
                    }
                    {/* show helpmeout error message IF it crashes */}
                    {JSON.stringify(reasonWithMeMutation.error) && <small
                        className={'d-block text-center py-2'}>
                        {JSON.stringify(reasonWithMeMutation.error)}
                    </small>}
                    {/* the cause of subsequent inputs of the
                             same type not being a fresh render. */}
                    {Question}
                    <div className={'row justify-content-center'}>
                        <div className={'col-auto'}>
                            {NextButton}
                        </div>
                    </div>

                </div>
            </div>

        </div>

    return (
        <div>
            {questionInterfaceState.showResultsPage === false && (rwmQuestionnairePage)}
            {
                questionInterfaceState.showResultsPage === true && <ResultsPage
                    personalCareplanId={props.personalCareplanId}
                    firstReasoningSetId={questionInterfaceState.firstReasoningSetId}
                    reasoningSetVersion={questionInterfaceState.reasoningSetVersion}
                    currentReasonWithMeResponse={questionInterfaceState.currentReasonWithMeResponse}
                />
            }
        </div>
    )
}

export default QuestionInterface
