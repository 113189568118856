/* eslint-disable max-len */
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { css, useTheme } from '@emotion/react'
const GlobalStyle = () => {
    const theme = useTheme()

    return css`
        body {
            background: ${ theme.config.global.body.background };
            //to detected if a font-family isn't used properly
            // eslint-disable-next-line max-len
            font-family: ${ theme.config.global.body.fontFamily }; 
            
        }

        .version-text {
            color: ${ theme.config.global.versionControl.color }; 
        }

        .clickable {
            cursor: pointer;
        }

        .non-clickable {
            cursor: default;
        }

        .overlay {
            position: relative;

  
        }

        .fullscreen-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${ theme.config.global.fullscreenOverlay.background };
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000; /* Ensure it is on top of other content */
        }

        .fullscreen-overlay-text {
            color: ${ theme.config.global.fullscreenOverlay.color };

            .spinner-text {
                font-size: 1.4em;
            }

        }

        .overlay-text {
            position: absolute;
            display: flex;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: ${ theme.config.global.overlayText.background };;
            /* opacity: 0.8; */
            font-size: 1.4em;
            font-weight: 600;
            color: ${ theme.config.global.overlayText.color };;
            border-radius: 18px;


        }

        
        .markdown {
            /** added where images are width 100% */
            & img {
                margin: auto;
                max-height: 90%;
                max-width: 90%;
                cursor: pointer;

                display: block;
                width: auto;
                height: auto;
            }

        }

        .profile-img-placeholder{
            background: ${ theme.config.global.profileImgPlaceholder.background };;

            &.profile {
                width: 136px;
                height: 136px;
            }
            &.header {
                width: 40px;
                height: 40px;
            }
        }

        
        /** take from old globalStyle.tsx */

        .Toastify__toast--default {
            background: ${ theme.config.global.toastifyToastDefault.background };
            border: 0.1em solid;
        }

        .Toastify__toast--info {
            background: ${ theme.config.global.toastifyToastInfo.background };
            color: ${ theme.config.global.toastifyToastInfo.color };
        }

        .Toastify__toast--success {
            background: ${ theme.config.global.toastifyToastSuccess.background };
            color: ${ theme.config.global.toastifyToastSuccess.color };
        }

        .Toastify__toast--warning {
            background: ${ theme.config.global.toastifyToastWarning.background };
            color: ${ theme.config.global.toastifyToastWarning.color };
        }

        .Toastify__toast--error {
            background: ${ theme.config.global.toastifyToastError.background };
            color: ${ theme.config.global.toastifyToastError.color };
        }

        
        /** Taken from ikherstel.css modified form-floaticon with start and end.*/
        div.form-control-wrapper {
            position: relative;
            display: block;
            margin: 0;
        }

        div.form-control-wrapper .form-floaticon {
            position: absolute;
            left: 1.175rem;
            top: calc(calc(1.3125em + 2.125rem) / 2);
            margin: -1rem 0 0 0rem;
        }

        div.form-control-wrapper .form-floaticon.icon,
        div.form-control-wrapper .form-floaticon .icon {
            margin: 0;
        }

        div.form-control-wrapper div.nice-select .form-control,
        div.form-control-wrapper div.nice-select .form-control .option,
        div.form-control-wrapper .form-control,
        div.form-control-wrapper .form-control .option {
            padding-left: 2.95rem;
        }

        div.form-control-wrapper div.nice-select .form-control~.form-floaticon i,
        div.form-control-wrapper .form-control~.form-floaticon i {
            line-height: 32px;
        }

        div.form-control-wrapper div.nice-select .form-control-sm,
        div.form-control-wrapper div.nice-select .form-control-sm .option,
        div.form-control-wrapper .form-control-sm,
        div.form-control-wrapper .form-control-sm .option {
            padding-left: 2.95rem;
        }

        div.form-control-wrapper div.nice-select .form-control-sm~.form-floaticon,
        div.form-control-wrapper .form-control-sm~.form-floaticon {
            left: 1.175rem;
            top: calc(calc(1.3125em + 1.5rem) / 2);
        }

        div.form-control-wrapper div.nice-select .form-control-lg,
        div.form-control-wrapper div.nice-select .form-control-lg .option,
        div.form-control-wrapper .form-control-lg,
        div.form-control-wrapper .form-control-lg .option {
            padding-left: 2.95rem;
        }

        div.form-control-wrapper div.nice-select .form-control-lg~.form-floaticon,
        div.form-control-wrapper .form-control-lg~.form-floaticon {
            left: 1.175rem;
            top: calc(calc(1.3125em + 2.75rem) / 2);
        }

        div.form-control-wrapper.icon-end .form-control,
        div.form-control-wrapper.icon-end .form-control .option {
            padding-left: 1.175rem;
            padding-right: 2.95rem;
        }

        div.form-control-wrapper.icon-end .form-control~.form-floaticon {
            left: auto;
            right: 1.175rem;
        }

        div.form-control-wrapper.icon-end .form-control-sm,
        div.form-control-wrapper.icon-end .form-control-sm .option {
            padding-left: 1.175rem;
            padding-right: 2.95rem;
        }

        div.form-control-wrapper.icon-end .form-control-sm~.form-floaticon {
            left: auto;
            top: calc(calc(1.3125em + 1.5rem) / 2);
            right: 1.175rem;
        }

        div.form-control-wrapper.icon-end .form-control-lg,
        div.form-control-wrapper.icon-end .form-control-lg .option {
            padding-left: 1.175rem;
            padding-right: 2.95rem;
        }

        div.form-control-wrapper.icon-end .form-control-lg~.form-floaticon {
            left: auto;
            top: calc(calc(1.3125em + 2.75rem) / 2);
            right: 1.175rem;
        }

        /* Icon at the start */
        div.form-control-wrapper.icon-start-and-end div.nice-select .form-control,
        div.form-control-wrapper.icon-start-and-end div.nice-select .form-control .option,
        div.form-control-wrapper.icon-start-and-end .form-control,
        div.form-control-wrapper.icon-start-and-end .form-control .option {
            padding-left: 2.95rem;
            padding-right: 2.95rem;
        }
        
        div.form-control-wrapper.icon-start-and-end .form-control~.form-floaticon:nth-of-type(1) {
            left: 1.175rem;
        }

        /* Icon at the end */
        div.form-control-wrapper.icon-start-and-end .form-control~.form-floaticon:nth-of-type(2) {
            right: 1.175rem;
            left: unset;
        }

        div.form-control-wrapper.icon-start-and-end .form-control-sm~.form-floaticon:nth-of-type(1) {
            left: 1.175rem;
        }

        div.form-control-wrapper.icon-start-and-end .form-control-sm~.form-floaticon:nth-of-type(2) {
            right: 1.175rem;
            left: unset;
        }

        div.form-control-wrapper.icon-start-and-end .form-control-lg~.form-floaticon:nth-of-type(1) {
            left: 1.175rem;
            top: calc(calc(1.3125em + 2.75rem) / 2);
        }

        div.form-control-wrapper.icon-start-and-end .form-control-lg~.form-floaticon:nth-of-type(2) {
            right: 1.175rem;
            left: unset;
        }

        *::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
        *::-webkit-scrollbar-track {
            background: transparent;
        }
        *::-webkit-scrollbar-thumb {
            background-color: ${ theme.config.global.webkitScrollbarThumb.backgroundColor };
            border-radius: 20px;
        }
        *::-webkit-scrollbar-corner {
            background: transparent
        }

        @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
            /* Hide scrollbar for Chrome, Safari, and Opera */
            ::-webkit-scrollbar {
            display: none;
            }

            /* Hide scrollbar for IE, Edge, and Firefox */
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    
        .react-datepicker-popper {
            z-index: 2;
        }

        .react-datepicker-popper[data-placement^=bottom] {
        padding-top: 0;
        }

        .react-datepicker {
            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                font-size: 90%;
            }

            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                font-size: 1rem;
            }
            
            padding: 0.75rem;
            font-family: 'Ikh-EndUser';
            border: 1px solid ${ theme.config.global.reactDatepicker.borderColor };
            font-weight: 400;
            background-color: ${ theme.config.global.reactDatepicker.backgroundColor };
        }


        /** applied this property so elements next to this datepicker won't line break */
        .react-datepicker__tab-loop {
            display: inline-block;
        }

        .react-datepicker__header {
            background: transparent;
            border-bottom: none;
            padding: 0;
        }

        .react-datepicker__header {
            border-top-left-radius: 0rem;
        }

        .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
            border-top-right-radius: 0rem;
        }

        .react-datepicker__month{
            margin: 0 0.4rem 0.4rem 0.4rem;
            //makes sure that the months don't wrap.
            white-space: nowrap;
        }

        .react-datepicker-wrapper, .react-datepicker__input-container {
            /** frtom inline-block to inline so we can use the custom input property to make
            it a block property if chosen to. */
            display: inline;

            /** when the form-floaticon is hovered, change text to blue */
            & .form-floaticon:hover {
                color: ${ theme.config.global.formFloaticon.hoverColor };
            }
        }

        .react-datepicker,
        .react-datepicker__time-container .react-datepicker__time {
            color: ${ theme.config.global.reactDatepicker.textColor };
        }

        
        .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
        }

        .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        }

        .react-datepicker__navigation {
            top:0px;
        }

        .react-datepicker__navigation--previous {
            left: -6px
        }

        .react-datepicker__navigation--next {
            right: -6px
        }


        .react-datepicker__navigation-icon {
            /* top: -9px; */
        }
        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view--down-arrow,
        .react-datepicker__navigation-icon::before {
            border-color: ${ theme.config.global.datePickerArrows.borderColor };
            border-width: 2px 2px 0 0;
            height: 14px;
            width: 14px;
        }

        .react-datepicker__gridbox_icons {
            color: ${ theme.config.global.datePickerArrows.color };
            //make sure the box-shadows are removed
            box-shadow: none;
        }

        
        .react-datepicker__year-wrapper {
            justify-content: center;
            min-width: 180px;
        }

        .react-datepicker .title {
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
            color: ${ theme.config.global.datePickerText.color };
            margin-block-end: 10px; /* Hardcoded value */
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            color: ${ theme.config.global.datePickerDay.color };
            margin: 0rem 0.066rem; /* Hardcoded value */
            padding-block: 0.4rem; /* Hardcoded value */
            width: 2rem; /* Hardcoded value */
        }

        .react-datepicker__day--selected,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range,
        .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
            background: ${ theme.config.global.datePickerSelected.backgroundColor } !important;
            color: ${ theme.config.global.datePickerSelected.color };
            border-radius: 1em;
        }

        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range {
            background: ${ theme.config.global.datePickerSelectingRange.backgroundColor };
            color: ${ theme.config.global.datePickerSelectingRange.color };
        }

        .react-datepicker__day--range-start,
        .react-datepicker__day--selecting-range-start,
        .react-datepicker__day--range-end,
        .react-datepicker__day--selecting-range-end {
            background: ${ theme.config.global.datePickerRange.backgroundColor };
            color: ${ theme.config.global.datePickerRange.color };
        }

        .react-datepicker__day:hover,
        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover,
        .react-datepicker__year-text:hover {
            background: ${ theme.config.global.datePickerHover.backgroundColor };
        }

        .react-datepicker__month-select,
        .react-datepicker__year-select {
            border-radius: ${ theme.config.global.datePickerSelect.borderRadius };
            background: ${ theme.config.global.datePickerSelect.backgroundColor };
            color: ${ theme.config.global.datePickerSelect.color };
        }

        .react-datepicker__month-select option,
        .react-datepicker__year-select option {
            background: ${ theme.config.global.datePickerSelectOption.backgroundColor };
            color: ${ theme.config.global.datePickerSelectOption.color };
        }

        .react-datepicker__day--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ),
        .react-datepicker__month-text--in-selecting-range:not(
            .react-datepicker__day--in-range, 
            .react-datepicker__month-text--in-range, 
            .react-datepicker__quarter-text--in-range, 
            .react-datepicker__year-text--in-range
        ), 
        .react-datepicker__quarter-text--in-selecting-range:not(
            .react-datepicker__day--in-range, 
            .react-datepicker__month-text--in-range, 
            .react-datepicker__quarter-text--in-range, 
            .react-datepicker__year-text--in-range), 
            .react-datepicker__year-text--in-selecting-range:not(
            .react-datepicker__day--in-range, 
            .react-datepicker__month-text--in-range, 
            .react-datepicker__quarter-text--in-range, 
            .react-datepicker__year-text--in-range
        ),     
        .react-datepicker__time-container 
        .react-datepicker__time 
        .react-datepicker__time-box 
        ul.react-datepicker__time-list 
        li.react-datepicker__time-list-item:hover{
            background: ${ theme.config.global.timeListItemHover.backgroundColor };
        }

        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
            margin: 0.366rem 0.066rem;
        }

        
        .react-datepicker__day, 
        .react-datepicker__month .react-datepicker__month-text,
        .react-datepicker__year .react-datepicker__year-text {
            font-weight: 600;
        }

        .react-datepicker__day-name {
            color: ${ theme.config.global.dayName.color };
            font-size: 12px; /* Keeping the original font-size property */
        }

        @media (max-width:575.98px) {
            .react-datepicker {
                font-family: 'Ikh-EndUser';
                /* width: 100%; */
            }

            .react-datepicker__month-container {
                width: 100%;
            }

            .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                width: calc(100% / 7) !important;
            }

            .react-datepicker__month .react-datepicker__month-text, 
            .react-datepicker__month .react-datepicker__quarter-text {
                width: calc(100% / 4) !important;
            } 
        }

        .react-datepicker__month .react-datepicker__month-text, 
        .react-datepicker__month .react-datepicker__quarter-text {
            /* width: 6rem; */
            width: 5rem;
            padding-block: 1rem;
        }

        .react-datepicker__day, 
        .react-datepicker__month .react-datepicker__month-text,
        .react-datepicker__year .react-datepicker__year-text {
            font-weight: 600;
        }


        @media (max-width:575.98px) {
            .react-datepicker {
                font-family: 'Ikh-EndUser';
                /* width: 100%; */
            }

            .react-datepicker__month-container {
                width: 100%;
            }

            .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                width: calc(100% / 7) !important;
            }

            .react-datepicker__month .react-datepicker__month-text, 
            .react-datepicker__month .react-datepicker__quarter-text {
                width: calc(100% / 4) !important;
            } 
        }

        .react-datepicker__month .react-datepicker__month-text, 
        .react-datepicker__month .react-datepicker__quarter-text {
            /* width: 6rem; */
            width: 5rem;
            padding-block: 1rem;
        }

        input.react-datepicker-time__input {
            border: 0;
            color: ${ theme.config.global.timeInput.color };
        }
        
        input.react-datepicker-time__input:focus-visible {
            border: 0;
            outline: 0;
        }

        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
            width: 120px;
        }

        .react-datepicker__time-container {
            width: 120px;
        }

        .react-datepicker__month .react-datepicker__month-text,
        .react-datepicker__month .react-datepicker__quarter-text {
            margin-inline: 9px;
        }
            
        .rc-slider {
            padding: 0.4em 0 2.5em 0;
        }

        .rc-slider, .rc-slider-rail, .rc-slider-track, .rc-slider-step {
            height: 0.5em;
        }

        .rc-slider-handle {
            height: 1.5em;
            width: 1.5em;
            margin-top: -0.5em;
        }
        .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
            border-color: ${ theme.config.global.sliderHandleDragging.borderColor };
            box-shadow: ${ theme.config.global.sliderHandleDragging.boxShadow };
        }

        .rc-slider-handle:active, .rc-slider-handle:hover {
            border-color: ${ theme.config.global.sliderHandleHover.borderColor };
        }

        .rc-slider-track {
            background-color: ${ theme.config.global.sliderTrack.backgroundColor };
            display: none;
            opacity: 1;
        }

        .rc-slider-handle {
            border: ${ theme.config.global.sliderHandle.borderColor };
        }

        
        .rc-slider-mark {
            white-space: nowrap;
            top: 1.5em;
            font-size: 1em;
        }

        .rc-slider-mark-text {
            color: ${ theme.config.global.sliderMarkText.color };
        }

        .rc-slider-dot-active, .rc-slider-dot {
            border-color: ${ theme.config.global.sliderDot.borderColor };
            display: none; 
        }

        .rc-slider-disabled  {
            background-color: ${ theme.config.global.sliderDisabled.backgroundColor };
        }

        
        ul.tag-list {
            list-style: none;
            padding-top: 1rem;
            padding-right: 0px;
            padding-bottom: 1rem;
            padding-left: 0px;
            margin: 0px;
            overflow: hidden;
        }

        ul.tag-list li:not(:last-child) {
            margin-right: 0.5rem;
        }

        ul.tag-list li {
            float: left;
            margin-bottom: 0.3125rem;
        }

        .tag {
            display: flex;
            align-items: center;
            padding-top: 0.5rem;
            padding-right: 1rem;
            padding-bottom: 0.5rem;
            padding-left: 1rem;
            border-radius: 0.625rem;
            color: ${ theme.config.global.tag.color };
            background-color: ${ theme.config.global.tag.backgroundColor };
            box-shadow: ${ theme.config.global.tag.boxShadow };
        }

        .tag .icon {
            margin-left: 1rem;
        }

        .tag .icon:hover {
            cursor: pointer;
        }

            
        .form-switch.settings-switch {
            padding-left: 0;
        }

        .form-switch.settings-switch .form-check-input {
            width: 40px;
            height: 22px;
            margin-left: 0;
            background-color: ${ theme.config.global.formSwitch.backgroundColor };
            background-image: url("data:image/svg+xml,%3csvg width='40' height='22' viewBox='0 0 40 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='40' height='22' rx='11' fill='%23C6CCD2'/%3e%3ccircle cx='12' cy='11' r='8' fill='white'/%3e%3c/svg%3e");
            border: none;
            border-radius: 40px;
            transition: background-position 0.15s ease-in-out;
        }

        @media (prefers-reduced-motion: reduce) {
            .form-switch.settings-switch .form-check-input {
                transition: none;
            }
        }

        .form-switch.settings-switch .form-check-input:focus {
            background-image: url("data:image/svg+xml,%3csvg width='40' height='22' viewBox='0 0 40 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='40' height='22' rx='11' fill='%23C6CCD2'/%3e%3ccircle cx='12' cy='11' r='8' fill='white'/%3e%3c/svg%3e");
            border-color: transparent;
            box-shadow: none;
        }

        .form-switch.settings-switch .form-check-input:checked {
            border-color: transparent;
            background-image: url("data:image/svg+xml,%3csvg width='40' height='22' viewBox='0 0 40 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='40' height='22' rx='11' fill='url%28%23paint0_linear_2174_15032%29'/%3e%3ccircle cx='29' cy='11' r='8' fill='white'/%3e%3cdefs%3e%3clinearGradient id='paint0_linear_2174_15032' x1='2.98023e-07' y1='11' x2='40' y2='11' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23E89B06'/%3e%3cstop offset='1' stop-color='%23FFC600'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
        }


        .pagelink,
        .settings-switch-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 0.9375rem;
            padding-right: 0.25rem;
            padding-bottom: 0.9375rem;
            padding-left: 0.25rem;
            border-top: 1px solid ${ theme.config.global.pageLinkSettingsSwitchWrapper.borderTopColor };
            border-bottom: 1px solid ${ theme.config.global.pageLinkSettingsSwitchWrapper.borderBottomColor };
            margin-bottom: -1px;
        }

        .pagelink .title,
        .settings-switch-wrapper .title {
            font-size: 0.875rem;
            font-weight: 800;
            line-height: 1.125rem;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media (max-width: 575.98px) {

            .pagelink .title,
            .settings-switch-wrapper .title {
                font-size: 1rem;
                font-weight: 800;
            }
        }

        .pagelink .link-wrapper,
        .settings-switch-wrapper .link-wrapper {
            display: flex;
            align-items: center;
        }

        .password-strength-meter {
            display: flex;
            align-items: center;
            padding-top: 0rem;
            padding-right: 5rem;
            padding-bottom: 0rem;
        }

        .password-strength-meter .meter-item {
            flex-grow: 1;
            background-color: ${ theme.config.global.passwordStrengthMeter.backgroundColor };
            height: 0.4375rem;
            border-radius: 50rem;
        }

        .password-strength-meter .meter-item.active {
            background-color: ${ theme.config.global.passwordStrengthMeter.activeBackgroundColor };
        }

        .password-strength-meter .meter-item:not(:first-of-type) {
            margin-left: 0.375rem;
        }

        .password-strength-meter .meter-item:not(:last-child) {
            margin-right: 0.375rem;
        }

        @media (max-width: 575.98px) {
            .password-strength-meter {
                padding-right: 2.25rem;
                padding-left: 2.25em;
            }

            .password-strength-meter .meter-item:not(:first-of-type) {
                margin-left: 0.1875rem;
            }

            .password-strength-meter .meter-item:not(:last-child) {
                margin-right: 0.1875rem;
            }
        }
        
        .dropdown-list {
            border: 2px solid ${ theme.config.global.dropdownList.borderColor };
            border-radius: 18px;
            z-index: 2;
            background: ${ theme.config.global.dropdownList.backgroundColor };

            .option {
                margin-block-end: 1em;
                padding-block: 0.75em;
                padding-inline: 2.5em;
                border-radius: 18px;
                box-shadow: ${ theme.config.global.dropdownList.option.boxShadow };
                cursor: default;

                &.selected {
                    background: ${ theme.config.global.dropdownList.option.selected.background };
                }
            }

            .search-box:focus {
                border-color: ${ theme.config.global.dropdownList.searchBox.borderColor };
                outline: 0;
                box-shadow: ${ theme.config.global.dropdownList.searchBox.boxShadow };
            }

            .search-box {
                width: 100%;
                display: flex;
                align-items: center;
                background-color: ${ theme.config.global.dropdownList.searchBox.backgroundColor };
                border-radius: 16px;
                padding: 25px 20px;
                height: 40px;

                input {
                    padding: 0;
                    background: ${ theme.config.global.dropdownList.searchBox.input.background };
                    border: 0;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                        border: 0;
                    }
                }

                @media (max-width: 767px) {
                    input {
                        width: 100%;
                    }
                }

                i {
                    color: ${ theme.config.global.dropdownList.searchBox.icon.color };
                    margin-right: 15px;
                }
            }
        }

        /* for submit buttons in .modal class. found first in rwm complete/incomplete modal */
        .modal {
            & .submit-button {
                max-width: 350px;
                width: 100%;
            }

            .modal-content {
                background-color: ${ theme.config.global.modal.bg }; ;
                color: ${ theme.config.global.modal.color }; ;
            }
        }

        .footer.wave-background {
            height: 168px;
            background-image: url("/images_new/footer/wave-blue.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        @media (min-width: 992px) {

            .modal-xxl {
                --bs-modal-width: 992px;
            }
        }

        @media (min-width: 1200px) {
            .modal-xxl {
                --bs-modal-width: 992px;
            }
        }

        .image-stack {
            position: relative;
            width: 189px;
            height: 466px;
            cursor: pointer;

            & .image {
                position: absolute;
                mix-blend-mode: darken;
                z-index: 1;
                left: 0;
                top: 0;
            }

            & .image-stack-hover {
                position: absolute;
                mix-blend-mode: darken;
                z-index: 10;
                left: 0;
                top: 0;
            }
        }
    `
}

export default GlobalStyle
