import { GPACareplanStepIdDetails } from '@doc/type'
import _ from 'lodash'
import { useMemo } from 'react'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const Bool = ({
    reasoningObj
}: ComponentProps) => {
    const questionData = reasoningObj.questionData

    // you are only getting the boolean value from this.
    // you need to do a find intead from questionAnswers
    const answerValue = reasoningObj.data.data.answerValue

    const input = useMemo(() => {
        const questionsAnswers = questionData?.questionAnswers

        const found = _.find(questionsAnswers, (o) => {
            return o.acqValue === answerValue
        })

        const acq = {
            acqName: found?.acqName === undefined ? '' : found?.acqName,
            acqValue: found?.acqValue === undefined ? '' : found?.acqValue
        }

        return <div className={'question-bool row row-cols-1 row-cols-md-2 btn-group-checkbox-list'}
            role={'group'}>
            {
                _.map(questionsAnswers, (obj, i) => {
                    const key = [
                        'question-choice', '-', i
                    ].join('')

                    return <div className={'col'} key={key}>
                        <input
                            type={'checkbox'}
                            className={'btn-check'}
                            id={key}
                            autoComplete={'off'}
                            checked={ obj.acqValue === acq.acqValue &&
                                obj.acqName === acq.acqName}
                            onChange={() => {
                            }}
                        />
                        <label className={'btn btn-dummy'} htmlFor={key} >

                            <div className={'question-checkbox'}>
                                <div className={'card justify-content-center px-3 py-2'}>
                                    <div className={'d-flex flex-column'}>
                                        <div className={'d-flex align-items-center'}>
                                            <div className={'p text-start'}><span style={{
                                                verticalAlign: 'inherit'
                                            }}>
                                                <span style={{ verticalAlign: 'inherit' }}>
                                                    {obj.acqName}
                                                </span>
                                            </span></div>
                                            <div
                                                className={'question-checkmark ms-auto'}>
                                                <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                            </div>
                                            <div
                                                className={'question-plussign ms-auto'}>
                                                <i className={'fa-light fa-plus mx-auto'}></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </label>
                    </div>
                })
            }
        </div>
    }, [])

    return input
}

export default Bool
