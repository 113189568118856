import { useAppSelector } from '@app/app.hook'
import { selectDateFormats, selectStrings } from '@app/slices/slice.app'
import {
    CareplanValues,
    DepartmentCareFacilitiesAction,
    DepartmentCareFacilitiesState,
    PatientValues
} from '@doc/type'
import { format, fromUnixTime } from 'date-fns'
import { FormikProps } from 'formik'
import React from 'react'

/** summary is to iterate the list of selected treatements
 * and then select its carepath paths
 */
interface ComponentProps {
    departmentCareFacilitiesState: DepartmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction: React.Dispatch<DepartmentCareFacilitiesAction>
    patientAddStepOneFormik: FormikProps<Pick<PatientValues,
    'firstName' | 'lastName' | 'email' | 'patientNumber'>>,
    patientAddStepTwoFormik: FormikProps<{
        careFacilityId: string;
        departmentId: string;
    }>,
    makeCareplanFormik: FormikProps<CareplanValues>,
}

const Main = ({
    departmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction, patientAddStepOneFormik,
    patientAddStepTwoFormik,
    makeCareplanFormik
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const dateFormats = useAppSelector(selectDateFormats)

    const FirstNameInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.doc?.text.patient
                        .add_interface.steps['1'].fields.first_name
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={patientAddStepOneFormik.values.firstName}
                />
            </div>

        </div>

    </div>

    const LastNameInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.doc?.text
                        .patient.add_interface.steps['1'].fields.last_name
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={patientAddStepOneFormik.values.lastName}
                />
            </div>

        </div>

    </div>

    const PatientNumberInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.doc?.text.patient
                        .add_interface.steps['1'].fields.patient_number
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        patientAddStepOneFormik.values.patientNumber
                    ].join(' ')}
                />
            </div>

        </div>

    </div>

    const EmailInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.doc?.text.patient.add_interface
                        .steps['1'].fields.email_address
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        patientAddStepOneFormik.values.email
                    ].join(' ')}
                />
            </div>

        </div>

    </div>

    const DepartmentInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.doc
                        ?.text.patient.add_interface.stepIndicators['2']
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        departmentCareFacilitiesState.main.selectedOptions
                            ?.department.departmentName
                    ].join(' ')}
                />
            </div>
        </div>
    </div>

    const CareInstitutionInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.doc?.text
                        .patient.add_interface.steps['4'].fields.facility
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        departmentCareFacilitiesState.main.selectedOptions
                            ?.careInstitution.facilityName
                    ].join(' ')}
                />
            </div>
        </div>
    </div>

    const CarepathInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.doc?.text.patient.add_interface.stepIndicators['3']
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        departmentCareFacilitiesState.main.selectedOptions
                            ?.carepath.carepathName,
                        ['(', departmentCareFacilitiesState.main.selectedOptions
                            ?.carepath.treatmentName, ')'].join('')
                    ].join(' ')}
                />
            </div>
        </div>
    </div>

    const OperationDateInput = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label className={'form-label'}>{
                    strings.doc?.text.careprofessionals.treated_patients.operation_date
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly
                    type={'text'}
                    className={[
                        'form-control-plaintext form-control-sm'
                    ].join(' ')}
                    value={[
                        departmentCareFacilitiesState.main
                            .selectedOptions.operationDate.isChecked
                            ? departmentCareFacilitiesState.main
                                .selectedOptions.operationDate.date
                                ? format(fromUnixTime(
                                    departmentCareFacilitiesState.main
                                        .selectedOptions.operationDate.date
                                ), dateFormats.format1)
                                : undefined
                            : strings.doc?.text.careprofessionals.abnormalities_table.unknown
                    ].join(' ')}
                />
            </div>
        </div>
    </div>

    return <>
        <h2 className={'mb-5 fw-semibold '} >{strings.doc?.text
            .patient.add_interface.stepIndicators['4']}</h2>

        {/* facility data BUT readonly and no change events. */}

        {/* you could copy the input from step one BUT there are some fields
        that are displayed different */}
        <h4 className={'mb-0'}>{strings.doc?.text.patient
            .add_interface.steps['4'].patient_data}</h4>
        {FirstNameInput}
        {LastNameInput}
        {EmailInput}
        {PatientNumberInput}
        <h4 className={'mb-0 mt-3'}>{strings.doc?.text
            .patient.add_interface.stepIndicators['2']}</h4>
        {DepartmentInput}
        {CareInstitutionInput}
        <h4 className={'mb-0 mt-3'}>{strings.doc?.text
            .patient.add_interface.stepIndicators['3']}</h4>
        {CarepathInput}
        {OperationDateInput}

    </>
}

export default Main
