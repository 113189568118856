
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import NewDatePicker from '@app/components/NewDatePicker'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useGetOverallProgressMutation, useGetPersonalCareplanMutation } from '@careplan/api'
import { IDS, MODULE_VERSION } from '@careplan/constants'
import { LibrarySearchActions, LibrarySearchState, PersonalCareplanData } from '@careplan/type'
import { push } from '@lagunovsky/redux-react-router'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/recoveryPlan/Components'
import { fromUnixTime, getUnixTime } from 'date-fns'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'

const ContentMenu = () => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const rootRef = useRef<HTMLDivElement | null>(null)
    const dateFormats = useAppSelector(selectDateFormats)

    const [getPersonalCareplan, getPersonalCareplanMutation] = useGetPersonalCareplanMutation()
    const [showOffcanvas, setShowOffcanvas] = useState(false)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node) &&
                !(event.target as HTMLElement).classList.contains('offcanvas-trigger')
            ) {
                // Clicked outside the container, handle the event here
                console.log('Clicked outside the container')
                setShowOffcanvas(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [containerRef])

    const [librarySearchState, librarySearchDispatch] = useReducer(
        (state: LibrarySearchState, action: LibrarySearchActions) => {
            switch (action.type) {
                case 'UPDATE_SEARCH': {
                    return produce(state, draft => {
                        draft.search = action.value
                    })
                } case 'UPDATE_FILTERS': {
                    return produce(state, draft => {
                        draft.filters[action.value.key] = action.value.filterValue
                    })
                } case 'UPDATE_DATE': {
                    return produce(state, draft => {
                        draft.dateRange[action.value.key] = action.value.date
                    })
                } case 'REMOVE_FILTERS': {
                    return produce(state, draft => {
                        draft.search = ''
                        draft.dateRange = {
                            start: getUnixTime(new Date()),
                            end: getUnixTime(new Date())
                        }
                    })
                }
            }
        }, {
            search: '',
            filters: {
                activity: false,
                content: false,
                lesson: false,
                reasoningSet: false
            },
            dateRange: {
                start: getUnixTime(new Date()),
                end: getUnixTime(new Date())
            }
        }
    )

    const unsubscribeGetPersonalCareplan = () => {
        const unsubscribeMutation = getPersonalCareplan({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPersonalCareplan()
        unsubscribeGetOverallProgress()

        let getPersonalCareplanPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getPersonalCareplan.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getPersonalCareplanPromise = getPersonalCareplan({
                            authToken: newToken.value,
                            data: {}
                        })
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPersonalCareplanPromise && getPersonalCareplanPromise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        if (getPersonalCareplanMutation.error) {
            const message = getErrorText(getPersonalCareplanMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPersonalCareplanMutation.error])

    /**
     * IHD-60 request.
     */
    useEffect(() => {
        const arr = _.isArray(getPersonalCareplanMutation.data?.data)
            ? getPersonalCareplanMutation.data?.data
            : []

        const result: PersonalCareplanData | undefined = arr && arr[0]
        if (result?.myCareplanId) {
            console.log('going to that one careplan already.')

            // now dispatch to another page.
            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.careplanPatient.moduleName,
                MODULE_TABLE.careplanPatient.routes.careplan,
                true
            )
            if (isValid) {
                // now push but replace :personalCareplanId with something else
                dispatch(push(
                    _.replace(isValid.route, ':personalCareplanId', result.myCareplanId)
                ))
            }
        }
    }, [getPersonalCareplanMutation.data, activeModules.id])

    const menu = useMemo(() => {
        // Sort the activities by their order.
        const arr = _.isArray(getPersonalCareplanMutation.data?.data)
            ? getPersonalCareplanMutation.data?.data
            : []

        const primarySort: PersonalCareplanData[] = _.sortBy(
            arr, [(obj) => {
                const matchNumber : RegExpMatchArray | null | undefined =
                obj.careplanName?.match(/\d+/)

                if (matchNumber) {
                    return parseInt(matchNumber[0])
                } else {
                    return '0'
                }
            }]
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        )

        const content =
                _.map(primarySort, (obj, index) => {
                    // Generate a unique key for each group.
                    const key = `careplan-${ index }`

                    const imageThumbnail = <img
                        src={obj.imageThumbnail || '/images_new/grey-bg.png'}
                        style={{ backgroundColor: 'gray' }}
                        className={''}
                        alt={'iRestore'}
                    />

                    let stepTypeString = ''

                    if (obj.stepType === 'activity') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.activity || ''
                    } else if (obj.stepType === 'content') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.content || ''
                    } else if (obj.stepType === 'lesson') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.lesson || ''
                    } else if (obj.stepType === 'uncertain') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.uncertain || ''
                    } else if (obj.stepType === 'reasoning') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.reasoning || ''
                    }

                    const contentMetaType = <div className={'content-meta-info-container'}>
                        <div
                            className={'content-meta-type me-1'}>
                            <i className={'fa-light fa-book'} aria-hidden={'true'}>
                            </i>
                        </div>
                        <div className={''}>
                            <small className={[
                                'fw-bold content-type',
                                obj.stepType
                            ].join(' ')}>
                                {stepTypeString}
                            </small>
                        </div>
                    </div>

                    const contentMetaInfo = <div className={'content-meta-info-container'}>
                        <div className={'me-1'}>
                            <i className={'fa-light fa-clock'} aria-hidden={'true'}></i>
                        </div>
                        <span className={''}>
                            <span style={{ verticalAlign: 'inherit' }}>
                                <span style={{ verticalAlign: 'inherit' }}>
                                    {obj.doneWhen}
                                </span>
                            </span>
                        </span>
                    </div>

                    return (<div className={'col'} key={key} onClick={() => {
                        if (obj.myCareplanId) {
                            // now dispatch to another page.
                            const isValid = validateRoute(
                                activeModules.arr,
                                MODULE_TABLE.careplanPatient.moduleName,
                                MODULE_TABLE.careplanPatient.routes.careplan,
                                true
                            )

                            console.log('going to one careplan path')
                            if (isValid) {
                                // now push but replace :personalCareplanId with something else
                                dispatch(push(
                                    _.replace(isValid.route,
                                        ':personalCareplanId', obj.myCareplanId)
                                ))
                            }
                        } else {
                            //
                        }
                    }}>
                        <div className={'card card-article library'}>
                            <div className={'card-overlay'}></div>
                            <div className={'img-wrapper '}>
                                {imageThumbnail}
                            </div>
                            <div className={'d-flex flex-column'}>
                                <div className={'content-meta-info mb-2'}>
                                    <div className={'row align-items-center g-0'}>
                                        <div className={'col-4'}>
                                            {contentMetaType}
                                        </div>
                                        <div className={'col-4'}>
                                            {contentMetaInfo}
                                        </div>
                                    </div>

                                </div>

                                <div className={'card-title'}>
                                    <span style={{ verticalAlign: 'inherit' }}>
                                        <span style={{ verticalAlign: 'inherit' }}>
                                            {obj.careplanName}
                                        </span>
                                    </span>
                                </div>

                            </div>

                            <div className={'link-wrapper'}>
                                <a onClick={(e) => {
                                    e.preventDefault()
                                }} className={'nav-link stretched-link me-1 clickable'}>
                                    <i
                                        className={'fa-light fa-arrow-right float-end'}
                                        aria-hidden={'true'}
                                    >
                                    </i>
                                </a>
                            </div>
                        </div>
                    </div>
                    )
                })

        // Render the grouped activities with their corresponding order number.
        return (
            getPersonalCareplanMutation.isLoading
                ? LoadingContent
                : getPersonalCareplanMutation.isSuccess
                    ? content
                    : JSON.stringify(getPersonalCareplanMutation.error)
        )
    }, [getPersonalCareplanMutation, strings])

    // you need the show className.
    // you need the show className.
    const offCanvas = useMemo(() => {
        const availableStepTypes = _.compact(
            _.uniq(
                _.map(
                    _.isArray(getPersonalCareplanMutation.data?.data)
                        ? getPersonalCareplanMutation.data?.data || []
                        : [], (o) => o.stepType
                )
            )
        )

        let checkboxes: {
            id: string
            isChecked: boolean
            name: keyof LibrarySearchState['filters']
            label: string | undefined
        }[] = []

        checkboxes = _.map(availableStepTypes, (str) => {
            let stepTypeString = ''

            if (str === 'activity') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.activity || ''
            } else if (str === 'content') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.content || ''
            } else if (str === 'lesson') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.lesson || ''
            } else if (str === 'uncertain') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.uncertain || ''
            } else if (str === 'reasoning') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.reasoning || ''
            }

            return {
                id: _.upperCase('CAREPLAN__CHECK-' + str),
                isChecked: librarySearchState.filters[str],
                name: str,
                label: stepTypeString
            }
        })

        const renderedCheckboxes = _.map(checkboxes, (obj, index) => {
            const key = `checkbox-${ index }`

            return <div className={'mb-2 form-check'} key={key}>
                <input type={'checkbox'}
                    className={'form-check-input'}
                    id={obj.id}
                    checked={obj.isChecked}
                    onChange={() => {
                        librarySearchDispatch({
                            type: 'UPDATE_FILTERS',
                            value: {
                                filterValue: !obj.isChecked,
                                key: obj.name
                            }
                        })
                    }}
                />
                <label htmlFor={obj.id}>
                    <div className={''}>
                        {obj.label}
                    </div>
                </label>
            </div>
        })

        const StartDate = <div className={'form-group'}>
            <label className={'form-label'} htmlFor={IDS.LIBRARY.START}>
                <span style={{ verticalAlign: 'inherit' }}>
                    <span style={{ verticalAlign: 'inherit' }}>
                        {strings.careplanPatient?.text.careplanMenu.date_range.from}
                    </span>
                </span>
            </label>
            <NewDatePicker
                id={IDS.LIBRARY.START}
                isDate={(date) => {
                    librarySearchDispatch({
                        type: 'UPDATE_DATE',
                        value: {
                            date: getUnixTime(date),
                            key: 'start'
                        }
                    })
                }}
                singleDate={fromUnixTime(librarySearchState.dateRange.start)}
                dateType={''}
                timeFormat={dateFormats.format5}
            />
        </div>

        const EndDate = <div className={'form-group'}>
            <label className={'form-label'} htmlFor={IDS.LIBRARY.END}>
                <span style={{ verticalAlign: 'inherit' }}>
                    <span style={{ verticalAlign: 'inherit' }}>
                        {strings.careplanPatient?.text.careplanMenu.date_range.to}
                    </span>
                </span>
            </label>
            <div className={'form-control-wrapper'} id={'date-wrapper'}>
                <NewDatePicker
                    id={IDS.LIBRARY.END}
                    isDate={(date) => {
                        librarySearchDispatch({
                            type: 'UPDATE_DATE',
                            value: {
                                date: getUnixTime(date),
                                key: 'end'
                            }
                        })
                    }}
                    singleDate={fromUnixTime(librarySearchState.dateRange.end)}
                    dateType={''}
                    timeFormat={dateFormats.format5}
                />
            </div>
        </div>

        return <Offcanvas
            fade={true}
            isOpen={showOffcanvas}
            direction={'end'}
            toggle={() => {
                setShowOffcanvas(!showOffcanvas)
            }}
        >
            <OffcanvasHeader
                tag={'div'}
                close={ <button
                    type={'button'}
                    className={'btn btn-round btn-rounded btn-lg offcanvas-trigger'}
                    onClick={() => {
                        setShowOffcanvas(!showOffcanvas)
                    }}
                >
                    <i className={'fa-light fa-xmark'} aria-hidden={'true'}></i>
                </button>}
                toggle={() => {
                    setShowOffcanvas(!showOffcanvas)
                }}
            >

                <h2 id={'offcanvas-header-label'}>
                    {strings.careplanPatient?.text.careplanMenu.filters.offcanvas
                        .filters_recovery_plan}
                </h2>

            </OffcanvasHeader>
            <OffcanvasBody className={'d-flex flex-column'}>

                <div className={'mb-4'} role={'group'}>

                    <h4 className={'mb-4'}>
                        <span style={{ verticalAlign: 'inherit' }}>
                            <span style={{ verticalAlign: 'inherit' }}>
                                {strings.careplanPatient?.text.careplanMenu.filters.offcanvas.type}

                            </span>
                        </span>
                    </h4>

                    {renderedCheckboxes}

                </div>

                <div className={'mb-4'} role={'group'}>

                    <h4 className={'mb-4'}><span style={{ verticalAlign: 'inherit' }}>
                        <span style={{ verticalAlign: 'inherit' }}>
                            {strings.careplanPatient?.text.careplanMenu.filters.offcanvas.period}
                        </span>
                    </span>
                    </h4>

                    {StartDate}
                    {EndDate}

                </div>

                <div className={'ih-vert-button-container my-auto'}>

                    <button type={'button'} className={[
                        'btn btn-lg btn-secondary ih-filter-button mx-auto'
                    ].join(' ')}
                    onClick={() => {
                        setShowOffcanvas(!showOffcanvas)
                    }}>
                        {strings.careplanPatient?.text.careplanMenu
                            .filters.offcanvas.apply_filters}
                    </button>

                    <button type={'button'} className={[
                        'btn btn-secondary btn-lg', 'ih-filter-button mx-auto'
                    ].join(' ')}
                    onClick={() => {
                        librarySearchDispatch({
                            type: 'REMOVE_FILTERS'
                        })
                    }}>
                        {strings.careplanPatient?.text.careplanMenu
                            .filters.offcanvas.remove_filters}
                    </button>

                </div>
            </OffcanvasBody>
        </Offcanvas>
    }, undefined)

    const LibraryContent = useMemo(() => {
        const formGroup = <div className={'form-group mt-4'}>
            <div className={'d-flex align-items-center'}>
                <div className={'form-control-wrapper flex-grow-1'}>
                    <input type={'search'}
                        className={'form-control form-search search-white'}
                        placeholder={strings.careplanPatient?.text.careplanMenu.search.placeholder}
                        id={IDS.LIBRARY.SEARCH}
                        value={librarySearchState.search}
                        onChange={(e) => {
                            librarySearchDispatch({
                                type: 'UPDATE_SEARCH',
                                value: e.target.value
                            })
                        }}
                    />
                    <label
                        className={'form-floaticon'}
                        htmlFor={IDS.LIBRARY.SEARCH}
                    >
                        <div
                            className={'icon '}>
                            <i
                                className={'fa-light fa-search'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </div>
                    </label>
                </div>
                <div className={'ms-3'}>
                    <button
                        type={'button'}
                        className={'btn btn-round btn-rounded btn-lg offcanvas-trigger'}
                        onClick={() => {
                            setShowOffcanvas(!showOffcanvas)
                        }}
                    >
                        <i
                            className={'fa-light fa-filter'}
                            aria-hidden={'true'}>
                        </i>
                    </button>
                </div>
            </div>
        </div>

        const pageLinks = <div
            className={'row row-cols-1 row-cols-md-2 pagelinks mb-4'}
            role={'group'}
        >
            <div className={'col'}>
                <div className={'pagelink'}>
                    <i className={'fa-light fa-book me-5'} aria-hidden={'true'}></i>
                    <div className={'title'}>
                        <span style={{ verticalAlign: 'inherit' }}>
                            <span style={{ verticalAlign: 'inherit' }}>
                                {strings.careplanPatient?.text.selection_for_you}
                            </span>
                        </span>
                    </div>
                    <div className={'link-wrapper ms-auto'}>
                        <a className={'nav-link stretched-link clickable'} onClick={(e) => {
                            e.preventDefault()
                        }}>
                            <i className={'fa-light fa-chevron-right'} aria-hidden={'true'}></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className={'col'}>
                <div className={'pagelink'}>
                    <i className={'fa-light fa-bookmark me-5'} aria-hidden={'true'}></i>
                    <div className={'title'}>
                        <span style={{ verticalAlign: 'inherit' }}>
                            <span style={{ verticalAlign: 'inherit' }}>
                                {strings.careplanPatient?.text.saved_articles_long}
                            </span>
                        </span>
                    </div>
                    <div className={'link-wrapper ms-auto'}>
                        <a className={'nav-link stretched-link clickable'} onClick={(e) => {
                            e.preventDefault()
                        }}>
                            <i className={'fa-light fa-chevron-right'} aria-hidden={'true'}></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        const content = <div className={'col-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8 mx-auto'}>
            <hr className={'mb-0'} />
            {pageLinks}
            <div className={'row row-cols-1 row-cols-md-2 g-4'}>
                {menu}
            </div>
        </div>

        return <div className={'library-content-container'}>
            <section className={'library-content'}>
                <div className={'library-content-wrapper'}>
                    <div className={'container px-5 px-sm-3'}>
                        <div className={'row'}>
                            <div className={'col-12 col-md-10 col-lg-8 mx-auto'}>
                                {formGroup}
                            </div>
                        </div>
                        <div className={'row'}>
                            {content}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }, undefined)

    // as requested, don't render the page at all IF the response data is just one.
    const columnOne = <div>
        <h1 className={'my-3'}>
            {strings.careplanPatient?.text.careplanMenu.recovery_plan}
        </h1>

        <div className={'row justify-content-between mb-5'}>
            <div className={'col-auto mb-md-0 mb-3'}>
                {/* group filter */}
                {LibraryContent}
            </div>
            <div className={'col-auto'}>
                {/* collapse all and expand all button */}
                {/* offcanvas button */}
                <button
                    type={'button'}
                    className={'btn btn-round btn-rounded'}
                    onClick={() => {
                        setShowOffcanvas(!showOffcanvas)
                    }}
                >
                    <i
                        className={'fa-light fa-filter'}
                        aria-hidden={'true'}>
                    </i>
                </button>
            </div>

        </div>

        {offCanvas}

        <div className={'container-fluid'}>
            <div className={'row'}>
                <div className={[
                    'col-12 mx-auto'
                ].join(' ')}>
                    <hr className={'d-lg-none'} />
                    {menu}
                </div>
            </div>
        </div>
    </div>

    const result = (<div className={'recovery-plan-page position-relative'} ref={rootRef}>

        {/* header image works differenty both themes will use the doctor image but with
    different gradients. if no image is provided, the blue background without the
    gradient will be used in it's place. */}
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>

            {/* container with a col width and then set to center */}
            <div className={'container mx-auto'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-auto careplan-cards-container mb-5'}>
                        {columnOne}
                    </div>
                </div>

                <div
                    className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
                >
                    {MODULE_VERSION}
                </div>
            </div>

        </div>

    </div>)

    return getPersonalCareplanMutation.data?.data &&
     _.isArray(getPersonalCareplanMutation.data.data) &&
    (getPersonalCareplanMutation.data.data.length || 0) > 1
        ? result
        : <></>
}

export default ContentMenu
