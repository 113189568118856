/* eslint-disable no-unused-vars */

import { history, selectRouter, store } from '@app/app.store'
import Sidebar from '@sidebar/components/Sidebar'

import { selectToken } from '@app/slices/slice.token'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import RouteGenerator from '@app/app.route.generator'
import {
    selectActiveModules,
    selectReturnToLink,
    setDateFormats,
    setModuleOwner,
    setReturnToLink
} from '@app/slices/slice.app'
// import GlobalStyle from '@app/styles/globalStyle'
// import Overlay from '@app/styles/styledOverlay'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import LanguageHandler from '@login/LanguageHandler'
import TokenHandler from '@login/TokenHandler'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { ToastContainer } from 'react-toastify'

import IdleComponent from '@app/components/IdleComponent'
import CommonWorkflow from '@login/CommonWorkflow'

/** IMPORTANT. */
import '@app/app.chartjs.plugin'
import StylesheetManager from '@app/stylesheetManager/Main'

import { DATE_FORMAT } from '@app/app.constants'
import { checkRoute, getAllRoutes, returnToLinkRouteCheck, suppressError } from '@app/app.method'
import FirebaseController from '@app/components/FirebaseController/App'
import FullscreenOverlay from '@app/components/FullscreenOverlay'
import MessagesModal from '@app/components/MessagesModal'
import useLinkInterceptor from '@app/hooks/useLinkInterceptor'
import HeadContents from '@app/stylesheetManager/HeadContents'
import { AppState } from '@app/types/type.app'
import { selectIsFullscreenOverlay } from '@login/slices/slice.login'
import { setDefaultOptions } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'
import _ from 'lodash'

/** Moving naivgation page to root because the header has to show up everywhere. */

// YOU STOPPED HERE. just test pure functions with JEST and go for automated E2E testing
// with Playwright or Cypress or Katalon.

export const Root = () => {
    // const isRevalidating = useAppSelector(selectIsRevalidating)
    // const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const router = useAppSelector(selectRouter)
    const returnToLink = useAppSelector(selectReturnToLink)
    const dispatch = useAppDispatch()
    const activeModules = useAppSelector(selectActiveModules)
    const isFullscreenOverlay = useAppSelector(selectIsFullscreenOverlay)

    useEffect(() => {
        suppressError()
    }, [])

    useEffect(() => {
        const str = checkRoute(activeModules.arr,
            router.location.pathname) as AppState['moduleOwner']
        dispatch(setModuleOwner(str))
    }, [
        activeModules.id,
        router.location.pathname
    ])

    // useEffect(() => {
    //     console.log('module owner: ', moduleOwner)
    // }, [moduleOwner])

    // const dateFormats = useAppSelector(selectDateFormats)

    // on mount, get the link you wanted. (DEPRECATED)
    // changed, from now on, everytime it changes, you want this to be updated at all
    // times. just trust me.
    // also change when the activeModules.id changes.
    useEffect(() => {
        const routePasses = returnToLinkRouteCheck(router.location.pathname)

        if (routePasses) {
            // make sure you can't set returnToLink those that don't exist.
            // check if the route even exists first from MODULE_TABLE since it covers all versions.
            const allRoutes: string[] = getAllRoutes()

            if (allRoutes.includes(router.location.pathname)) {
                console.log('setting return link to:', router.location.pathname)
                dispatch(setReturnToLink(router.location.pathname))
                // setting it in localStorage to retain the lifespan.
                localStorage.setItem('recentRoute', router.location.pathname)
            } else {
                console.log("not setting a link that doesn't exist.")
            }
        } else {
            console.log('not setting return to link at the end of the new function.')
        }
    }, [router.location.pathname])

    // edge to check if the url has the following params:
    useEffect(() => {
        if (token.valid === true && returnToLink.includes(':')) {
            console.error('return to link has colon. reset to home url')
            dispatch(setReturnToLink(''))
            localStorage.setItem('recentRoute', '')
        }
    }, [token.id, returnToLink])

    /** change userLocale based on token language */
    useEffect(() => {
        // console.log('locale is: ', token.details.locale)
        if (_.lowerCase(token.details.locale).replace(' ', '-') === 'nl-nl') {
            // console.log('setting to dutch')
            setDefaultOptions({ locale: nl })
            dispatch(setDateFormats(DATE_FORMAT['nl-nl']))
        } else if (_.lowerCase(token.details.locale).replace(' ', '-') === 'en-us') {
            // console.log('setting to english')
            setDefaultOptions({ locale: enUS })
            dispatch(setDateFormats(DATE_FORMAT['en-us']))
        } else {
            // console.log('logging to nothing')
            setDefaultOptions({ locale: enUS })
            dispatch(setDateFormats(DATE_FORMAT['en-us']))
        }
    }, [token.details.locale])

    // useEffect(() => {
    //     console.log('date format in use: ', dateFormats)
    // }, [dateFormats])

    // this won't work in react-markdown components.
    // in that case, use the linkTarget prop
    useLinkInterceptor()

    // doesn't confirm here.
    // useEffect(() => {
    //     console.log('running on undefined')
    // }, undefined)
    return (
        <>
            {/* <Overlay visible={isRevalidating} >
                    <span>{strings.app?.text.revalidating_token || ''}</span>
                </Overlay> */}
            {/* <GlobalStyle /> */}

            <ToastContainer />
            {/* You can only render the workflow logic IF you are not a guest
                and if you retrieved your activeModules data */}
            <MessagesModal />
            <FirebaseController/>
            <CommonWorkflow/>
            <HeadContents />
            <Sidebar/>
            <IdleComponent/>
            <TokenHandler />
            <LanguageHandler />
            <StylesheetManager>
                {isFullscreenOverlay.show &&
                <FullscreenOverlay text={isFullscreenOverlay.text}/> }
                <ReduxRouter history={history}>
                    <RouteGenerator />
                </ReduxRouter >
            </StylesheetManager>
        </>
    )
}

/**
 Warning: createRoot(): Creating roots directly with document.body is discouraged,
 since its children are often manipulated by third-party scripts and browser extensions.
 This may lead to subtle reconciliation issues. Try using a container element created for your app.
*/
const domNode = document.getElementById('root') as (Element | DocumentFragment)
const root = createRoot(domNode)
root.render(<Provider store={store}>
    <Root />
</Provider>)

// will be kept in reserve until i can see a use for service workers.
// if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//         navigator.serviceWorker.register('/service-worker.js')
//     })
// }
