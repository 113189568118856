import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import {
    AddCareFacilityRequest,
    AddCareFacilityResponse,
    AddCarepathRequest,
    AddCarepathResponse,
    AddCareProfessionalRequest,
    AddCareProfessionalResponse,
    AddDepartmentCareFacilityRequest,
    AddDepartmentCareFacilityResponse,
    AddDepartmentCareProfessionalRequest,
    AddDepartmentCareProfessionalResponse,
    AddDepartmentRequest,
    AddDepartmentResponse,
    AddDepartmentTreatmentRequest,
    AddDepartmentTreatmentResponse,
    AddTreatmentCarepathRequest,
    AddTreatmentCarepathResponse,
    AddTreatmentRequest,
    AddTreatmentResponse,
    DeleteDepartmentCareFacilityRequest,
    DeleteDepartmentCareFacilityResponse,
    DeleteDepartmentCareProfessionalRequest,
    DeleteDepartmentCareProfessionalResponse,
    DeleteDepartmentTreatmentRequest,
    DeleteDepartmentTreatmentResponse,
    DeleteTreatmentCarepathRequest,
    DeleteTreatmentCarepathResponse,
    EditCareFacilityRequest,
    EditCareFacilityResponse,
    EditCarepathRequest,
    EditCarepathResponse,
    EditCareProfessionalRequest,
    EditCareProfessionalResponse,
    EditDepartmentRequest,
    EditDepartmentResponse,
    EditTreatmentRequest,
    EditTreatmentResponse,
    GetCareFacilitiesRequest,
    GetCareFacilitiesResponse,
    GetCareFacilityRequest,
    GetCareFacilityResponse,
    GetCarepathRequest,
    GetCarepathResponse,
    GetCarepathsRequest,
    GetCarepathsResponse,
    GetCareProfessionalRequest,
    GetCareProfessionalResponse,
    GetCareProfessionalsRequest,
    GetCareProfessionalsResponse,
    GetDepartmentRequest,
    GetDepartmentResponse,
    GetDepartmentsRequest,
    GetDepartmentsResponse,
    GetTreatmentCarepathsRequest,
    GetTreatmentCarepathsResponse,
    GetTreatmentRequest,
    GetTreatmentResponse,
    GetTreatmentsRequest,
    GetTreatmentsResponse,
    UpdateProfilePictureCarepathRequest,
    UpdateProfilePictureCarepathResponse,
    UpdateProfilePictureCareProfessionalRequest,
    UpdateProfilePictureCareProfessionalResponse,
    UpdateProfilePictureDepartmentRequest,
    UpdateProfilePictureDepartmentResponse,
    UpdateProfilePictureFacilityRequest,
    UpdateProfilePictureFacilityResponse,
    UpdateProfilePictureTreatmentRequest,
    UpdateProfilePictureTreatmentResponse
} from '@fmt/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'
export const fmtV10Api = createApi({
    reducerPath: 'fmtV10Api',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        addCareFacility: builder
            .mutation<AddCareFacilityResponse, AddCareFacilityRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data:AddCareFacilityRequest['data']
                    } =
                    {
                        data: {
                            facilityName: body.data.facilityName,
                            facilityAddressStreet: body.data.facilityAddressStreet,
                            facilityAddressNumber: body.data.facilityAddressNumber,
                            facilityAddressSuffix: body.data.facilityAddressSuffix,
                            facilityPostalcode: body.data.facilityPostalcode,
                            facilityCity: body.data.facilityCity,
                            facilityPhonenumber: body.data.facilityPhonenumber,
                            facilityEmailAddress: body.data.facilityEmailAddress,
                            facilityWebsite: body.data.facilityWebsite
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.addCareFacility.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        editCareFacility: builder
            .mutation<EditCareFacilityResponse, EditCareFacilityRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: EditCareFacilityRequest['data']
                        } =
                        {
                            data: {
                                facilityId: body.data.facilityId,
                                facilityName: body.data.facilityName,
                                facilityAddressStreet: body.data.facilityAddressStreet,
                                facilityAddressNumber: body.data.facilityAddressNumber,
                                facilityAddressSuffix: body.data.facilityAddressSuffix,
                                facilityPostalcode: body.data.facilityPostalcode,
                                facilityCity: body.data.facilityCity,
                                facilityPhonenumber: body.data.facilityPhonenumber,
                                facilityEmailAddress: body.data.facilityEmailAddress,
                                facilityWebsite: body.data.facilityWebsite
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.editCareFacility.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getCareFacility: builder
            .mutation<GetCareFacilityResponse, GetCareFacilityRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                                data: GetCareFacilityRequest['data']
                            } =
                            {
                                data: {
                                    facilityId: body.data.facilityId
                                }
                            }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getCareFacility.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getCareFacilities: builder
            .mutation<GetCareFacilitiesResponse, GetCareFacilitiesRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                                data: GetCareFacilitiesRequest['data']
                            } =
                            {
                                data: {
                                    skip: body.data.skip,
                                    limit: body.data.limit
                                }
                            }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getCareFacilities.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }

            }),
        getDepartments: builder
            .mutation<GetDepartmentsResponse, GetDepartmentsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                                    data: GetDepartmentsRequest['data']
                                } =
                                {
                                    data: {
                                        ...(body.data.facilityId !== undefined && {
                                            facilityId: body.data.facilityId
                                        }),
                                        ...(body.data.isLinked !== undefined && {
                                            isLinked: body.data.isLinked
                                        }),
                                        skip: body.data.skip,
                                        limit: body.data.limit
                                    }
                                }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getDepartments.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getCareProfessionals: builder
            .mutation<GetCareProfessionalsResponse, GetCareProfessionalsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetCareProfessionalsRequest['data']
                        } =
                        {
                            data: {
                                ...(body.data.departmentId !== undefined && {
                                    departmentId: body.data.departmentId
                                }),
                                ...(body.data.isLinked !== undefined && {
                                    isLinked: body.data.isLinked
                                }),
                                ...(body.data.skip !== undefined && {
                                    skip: body.data.skip
                                }),
                                ...(body.data.limit !== undefined && {
                                    limit: body.data.limit
                                }),
                                ...(body.data.search !== undefined && {
                                    search: body.data.search
                                })
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getCareProfessionals.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        // getDepartmentCareProfessionals: builder
        //     .mutation<GetDepartmentCareProfessionalsResponse,
        //      GetDepartmentCareProfessionalsRequest & TokenAuth>({
        //          query: (body) => {
        //              const formData: {
        //                     data: GetDepartmentCareProfessionalsRequest['data']
        //                 } =
        //                 {
        //                     data: {
        //                         ...(body.data.departmentId !== undefined && {
        //                             departmentId: body.data.departmentId
        //                         })
        //                     }
        //                 }

        //              const url = [
        //                  _.trimStart(
        //                      MODULE_TABLE.fmt
        //                          .apiPaths.getDepartmentCareProfessionals.path, '/'
        //                  )
        //              ].join('')

        //              return {
        //                  url,
        //                  method: 'POST',
        //                  headers: {
        //                      Authorization: `Bearer ${ body.authToken }`,
        //                      'Content-Type': 'application/json'
        //                  },
        //                  body: formData
        //              }
        //          }
        //      }),
        getCareProfessional: builder
            .mutation<GetCareProfessionalResponse, GetCareProfessionalRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: GetCareProfessionalRequest['data']
                    } =
                    {
                        data: {
                            careProfessionalId: body.data.careProfessionalId
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getCareProfessional.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        addCareProfessional: builder
            .mutation<AddCareProfessionalResponse, AddCareProfessionalRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: AddCareProfessionalRequest['data']
                        } =
                        {
                            data: {
                                careProfessionalName: body.data.careProfessionalName,
                                careProfessionalProfession: body.data.careProfessionalProfession,
                                careProfessionalLocation: body.data.careProfessionalLocation,
                                careProfessionalPhoneNumber: body.data.careProfessionalPhoneNumber,
                                careProfessionalEmail: body.data.careProfessionalEmail,
                                addSystemUser: body.data.addSystemUser
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.addCareProfessional.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        editCareProfessional: builder
            .mutation<EditCareProfessionalResponse, EditCareProfessionalRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                                data: EditCareProfessionalRequest['data']
                            } =
                            {
                                data: {
                                    careProfessionalId: body.data.careProfessionalId,
                                    careProfessionalName: body.data.careProfessionalName,
                                    careProfessionalProfession: body.data
                                        .careProfessionalProfession,
                                    careProfessionalLocation: body.data.careProfessionalLocation,
                                    careProfessionalPhoneNumber: body.data
                                        .careProfessionalPhoneNumber,
                                    careProfessionalEmail: body.data.careProfessionalEmail
                                }
                            }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.editCareProfessional.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getTreatments: builder
            .mutation<GetTreatmentsResponse, GetTreatmentsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: GetTreatmentsRequest['data']
                    } =
                    {
                        data: {
                            ...(body.data.departmentId !== undefined && {
                                departmentId: body.data.departmentId
                            }),
                            ...(body.data.isLinked !== undefined && {
                                isLinked: body.data.isLinked
                            }),
                            skip: body.data.skip,
                            limit: body.data.limit
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getTreatments.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getTreatment: builder
            .mutation<GetTreatmentResponse, GetTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: GetTreatmentRequest['data']
                    } =
                    {
                        data: {
                            treatmentId: body.data.treatmentId
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getTreatmentId.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getTreatmentCarepaths: builder
            .mutation<GetTreatmentCarepathsResponse, GetTreatmentCarepathsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetTreatmentCarepathsRequest['data']
                        } =
                        {
                            data: {
                                treatmentId: body.data.treatmentId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getTreatmentCarepaths.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getCarepaths: builder
            .mutation<GetCarepathsResponse, GetCarepathsRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetCarepathsRequest['data']
                        } =
                        {
                            data: {
                                skip: body.data.skip,
                                limit: body.data.limit
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getCarepaths.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getCarepath: builder
            .mutation<GetCarepathResponse, GetCarepathRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: GetCarepathRequest['data']
                        } =
                        {
                            data: {
                                carepathId: body.data.carepathId
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getCarepathId.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getDepartment: builder
            .mutation<GetDepartmentResponse, GetDepartmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: GetDepartmentRequest['data']
                    } =
                    {
                        data: {
                            departmentId: body.data.departmentId
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.getDepartment.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        addDepartment: builder
            .mutation<AddDepartmentResponse, AddDepartmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                            data: AddDepartmentRequest['data']
                        } =
                        {
                            data: {
                                departmentName: body.data.departmentName,
                                departmentAddressStreet: body.data.departmentAddressStreet,
                                departmentAddressNumber: body.data.departmentAddressNumber,
                                departmentAddressSuffix: body.data.departmentAddressSuffix,
                                departmentPostalcode: body.data.departmentPostalcode,
                                departmentCity: body.data.departmentCity,
                                departmentPhonenumber: body.data.departmentPhonenumber,
                                departmentEmailAddress: body.data.departmentEmailAddress,
                                departmentWebsite: body.data.departmentWebsite
                            }
                        }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.addDepartment.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        editDepartment: builder
            .mutation<EditDepartmentResponse, EditDepartmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                                data: EditDepartmentRequest['data']
                            } =
                            {
                                data: {
                                    departmentId: body.data.departmentId,
                                    departmentName: body.data.departmentName,
                                    departmentAddressStreet: body.data.departmentAddressStreet,
                                    departmentAddressNumber: body.data.departmentAddressNumber,
                                    departmentAddressSuffix: body.data.departmentAddressSuffix,
                                    departmentPostalcode: body.data.departmentPostalcode,
                                    departmentCity: body.data.departmentCity,
                                    departmentPhonenumber: body.data.departmentPhonenumber,
                                    departmentEmailAddress: body.data.departmentEmailAddress,
                                    departmentWebsite: body.data.departmentWebsite
                                }
                            }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.editDepartment.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateProfilePictureFacility: builder
            .mutation<
            UpdateProfilePictureFacilityResponse,
            UpdateProfilePictureFacilityRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<UpdateProfilePictureFacilityRequest, 'data'> = {
                        data: body.data
                    }

                    return {
                        url: [
                            _.trimStart(
                                MODULE_TABLE.fmt.apiPaths
                                    .updateProfilePictureFacility.path, '/'
                            ),
                            body.facilityId ? '/' + body.facilityId : ''
                        ].join(''),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData.data
                    }
                }
            }),
        updateProfilePictureDepartment: builder
            .mutation<
                UpdateProfilePictureDepartmentResponse,
                UpdateProfilePictureDepartmentRequest & TokenAuth>({
                    query: (body) => {
                        const formData: Pick<UpdateProfilePictureDepartmentRequest, 'data'> = {
                            data: body.data
                        }

                        return {
                            url: [
                                _.trimStart(
                                    MODULE_TABLE.fmt.apiPaths
                                        .updateProfilePictureDepartment.path, '/'
                                ),
                                body.departmentId ? '/' + body.departmentId : ''
                            ].join(''),
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`
                            },
                            body: formData.data
                        }
                    }
                }),
        updateProfilePictureCareProfessional: builder
            .mutation<
                    UpdateProfilePictureCareProfessionalResponse,
                    UpdateProfilePictureCareProfessionalRequest & TokenAuth>({
                        query: (body) => {
                            const formData: Pick<
                            UpdateProfilePictureCareProfessionalRequest, 'data'> = {
                                data: body.data
                            }

                            return {
                                url: [
                                    _.trimStart(
                                        MODULE_TABLE.fmt.apiPaths
                                            .updateProfilePictureCareProfessional.path, '/'
                                    ),
                                    body.careprofessionalId ? '/' + body.careprofessionalId : ''
                                ].join(''),
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${ body.authToken }`
                                },
                                body: formData.data
                            }
                        }
                    }),
        addCarepath: builder
            .mutation<AddCarepathResponse, AddCarepathRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: AddCarepathRequest['data']
                    } =
                    {
                        data: {
                            carepathName: body.data.carepathName,
                            dateName: body.data.dateName
                            // treatmentName: body.data.treatmentName
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.addCarepath.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        editCarepath: builder
            .mutation<EditCarepathResponse, EditCarepathRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: EditCarepathRequest['data']
                    } =
                    {
                        data: {
                            treatmentId: body.data.treatmentId,
                            carepathId: body.data.carepathId,
                            carepathName: body.data.carepathName,
                            dateName: body.data.dateName
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.editCarepath.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateProfilePictureCarepath: builder
            .mutation<
                    UpdateProfilePictureCarepathResponse,
                    UpdateProfilePictureCarepathRequest & TokenAuth>({
                        query: (body) => {
                            const formData: Pick<
                            UpdateProfilePictureCarepathRequest, 'data'> = {
                                data: body.data
                            }

                            return {
                                url: [
                                    _.trimStart(
                                        MODULE_TABLE.fmt.apiPaths
                                            .updateProfilePictureCarepath.path, '/'
                                    ),
                                    body.carepathId ? '/' + body.carepathId : ''
                                ].join(''),
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${ body.authToken }`
                                },
                                body: formData.data
                            }
                        }
                    }),
        addTreatment: builder
            .mutation<AddTreatmentResponse, AddTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                                data: AddTreatmentRequest['data']
                            } =
                            {
                                data: {
                                    treatmentName: body.data.treatmentName
                                }
                            }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.addTreatment.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        editTreatment: builder
            .mutation<EditTreatmentResponse, EditTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                                data: EditTreatmentRequest['data']
                            } =
                            {
                                data: {
                                    treatmentId: body.data.treatmentId,
                                    treatmentName: body.data.treatmentName
                                }
                            }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.fmt
                                .apiPaths.editTreatment.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        // getDepartmentTreatments: builder
        //     .mutation<GetDepartmentTreatmentsResponse,
        //      GetDepartmentTreatmentsRequest & TokenAuth>({
        //          query: (body) => {
        //              const formData: {
        //                     data: GetDepartmentTreatmentsRequest['data']
        //                 } =
        //                 {
        //                     data: {
        //                         ...(body.data.departmentId !== undefined && {
        //                             departmentId: body.data.departmentId
        //                         })
        //                     }
        //                 }

        //              const url = [
        //                  _.trimStart(
        //                      MODULE_TABLE.fmt
        //                          .apiPaths.getDepartmentTreatments.path, '/'
        //                  )
        //              ].join('')

        //              return {
        //                  url,
        //                  method: 'POST',
        //                  headers: {
        //                      Authorization: `Bearer ${ body.authToken }`,
        //                      'Content-Type': 'application/json'
        //                  },
        //                  body: formData
        //              }
        //          }
        //      }),
        updateProfilePictureTreatment: builder
            .mutation<
                        UpdateProfilePictureTreatmentResponse,
                        UpdateProfilePictureTreatmentRequest & TokenAuth>({
                            query: (body) => {
                                const formData: Pick<
                                UpdateProfilePictureTreatmentRequest, 'data'> = {
                                    data: body.data
                                }

                                return {
                                    url: [
                                        _.trimStart(
                                            MODULE_TABLE.fmt.apiPaths
                                                .updateProfilePictureTreatment.path, '/'
                                        ),
                                        body.treatmentId ? '/' + body.treatmentId : ''
                                    ].join(''),
                                    method: 'POST',
                                    headers: {
                                        Authorization: `Bearer ${ body.authToken }`
                                    },
                                    body: formData.data
                                }
                            }
                        }),
        addDepartmentCareFacility: builder
            .mutation<
            AddDepartmentCareFacilityResponse,
            AddDepartmentCareFacilityRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<
                    AddDepartmentCareFacilityRequest, 'data'> = {
                        data: body.data
                    }

                    return {
                        url: [
                            _.trimStart(
                                MODULE_TABLE.fmt.apiPaths
                                    .addDepartmentCareFacility.path, '/'
                            )
                        ].join(''),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        deleteDepartmentCareFacility: builder
            .mutation<
            DeleteDepartmentCareFacilityResponse,
            DeleteDepartmentCareFacilityRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<
                    DeleteDepartmentCareFacilityRequest, 'data'> = {
                        data: body.data
                    }

                    return {
                        url: [
                            _.trimStart(
                                MODULE_TABLE.fmt.apiPaths
                                    .deleteDepartmentCareFacility.path, '/'
                            )
                        ].join(''),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        addDepartmentCareProfessional: builder
            .mutation<
            AddDepartmentCareProfessionalResponse,
                    AddDepartmentCareProfessionalRequest & TokenAuth>({
                        query: (body) => {
                            const formData: Pick<
                            AddDepartmentCareProfessionalRequest, 'data'> = {
                                data: body.data
                            }

                            return {
                                url: [
                                    _.trimStart(
                                        MODULE_TABLE.fmt.apiPaths
                                            .addDepartmentCareProfessional.path, '/'
                                    )
                                ].join(''),
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${ body.authToken }`
                                },
                                body: formData
                            }
                        }
                    }),
        deleteDepartmentCareProfessional: builder
            .mutation<
            DeleteDepartmentCareProfessionalResponse,
            DeleteDepartmentCareProfessionalRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<
                    DeleteDepartmentCareProfessionalRequest, 'data'> = {
                        data: body.data
                    }

                    return {
                        url: [
                            _.trimStart(
                                MODULE_TABLE.fmt.apiPaths
                                    .deleteDepartmentCareProfessional.path, '/'
                            )
                        ].join(''),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        addDepartmentTreatment: builder
            .mutation<
            AddDepartmentTreatmentResponse,
            AddDepartmentTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<
                    AddDepartmentTreatmentRequest, 'data'> = {
                        data: body.data
                    }

                    return {
                        url: [
                            _.trimStart(
                                MODULE_TABLE.fmt.apiPaths
                                    .addDepartmentTreatment.path, '/'
                            )
                        ].join(''),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        deleteDepartmentTreatment: builder
            .mutation<
            DeleteDepartmentTreatmentResponse,
            DeleteDepartmentTreatmentRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<
                    DeleteDepartmentTreatmentRequest, 'data'> = {
                        data: body.data
                    }

                    return {
                        url: [
                            _.trimStart(
                                MODULE_TABLE.fmt.apiPaths
                                    .deleteDepartmentTreatment.path, '/'
                            )
                        ].join(''),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            }),
        addTreatmentCarepath: builder
            .mutation<
                AddTreatmentCarepathResponse,
                AddTreatmentCarepathRequest & TokenAuth>({
                    query: (body) => {
                        const formData: Pick<
                        AddTreatmentCarepathRequest, 'data'> = {
                            data: body.data
                        }

                        return {
                            url: [
                                _.trimStart(
                                    MODULE_TABLE.fmt.apiPaths
                                        .addTreatmentCarepath.path, '/'
                                )
                            ].join(''),
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`
                            },
                            body: formData
                        }
                    }
                }),
        deleteTreatmentCarepath: builder
            .mutation<
            DeleteTreatmentCarepathResponse,
            DeleteTreatmentCarepathRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<
                    DeleteTreatmentCarepathRequest, 'data'> = {
                        data: body.data
                    }

                    return {
                        url: [
                            _.trimStart(
                                MODULE_TABLE.fmt.apiPaths
                                    .deleteTreatmentCarepath.path, '/'
                            )
                        ].join(''),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useAddCareFacilityMutation,
    useEditCareFacilityMutation,
    useGetCareFacilitiesMutation,
    useGetCareFacilityMutation,
    useGetDepartmentsMutation,
    useGetCareProfessionalsMutation,
    useGetTreatmentsMutation,
    useGetTreatmentMutation,
    useGetTreatmentCarepathsMutation,
    useGetCarepathsMutation,
    useGetCarepathMutation,
    useAddDepartmentMutation,
    useEditDepartmentMutation,
    useGetDepartmentMutation,
    useAddCareProfessionalMutation,
    useEditCareProfessionalMutation,
    useGetCareProfessionalMutation,
    useUpdateProfilePictureFacilityMutation,
    useUpdateProfilePictureDepartmentMutation,
    useUpdateProfilePictureCareProfessionalMutation,
    useAddCarepathMutation,
    useEditCarepathMutation,
    useUpdateProfilePictureCarepathMutation,
    useAddTreatmentMutation,
    useEditTreatmentMutation,
    useUpdateProfilePictureTreatmentMutation,
    useAddDepartmentCareFacilityMutation,
    useDeleteDepartmentCareFacilityMutation,
    useAddDepartmentCareProfessionalMutation,
    useAddDepartmentTreatmentMutation,
    useAddTreatmentCarepathMutation,
    // useGetDepartmentCareProfessionalsMutation,
    useDeleteDepartmentCareProfessionalMutation,
    // useGetDepartmentTreatmentsMutation,
    useDeleteDepartmentTreatmentMutation,
    useDeleteTreatmentCarepathMutation
} = fmtV10Api
