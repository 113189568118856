
import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { GPACareplanStepIdDetails } from '@doc/type'
import { OpenListPreviousQuestions } from '@reasonWithMe/components/types/open-list/OpenList'
import { QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useAppSelector } from '@app/app.hook'
import { selectActiveModules } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useGPACareplanStepIdDetailsMutation } from '@doc/api'

import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

// import _ from 'lodash'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
    reasoningModal: {
        reasoningSessionId: string;
        reasoningSetId: string;
        addedOn: number;
    } | undefined,
    userId: string | undefined
    careplanStepId: string | undefined
}

const MultiList = ({
    reasoningObj, reasoningModal, userId, careplanStepId
}: ComponentProps) => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()

    const [
        gPACareplanStepIdDetails,
        gPACareplanStepIdDetailsMutation
    ] = useGPACareplanStepIdDetailsMutation()

    const reasoningData = gPACareplanStepIdDetailsMutation.data as
     GPACareplanStepIdDetails['response']['reasoningPrevious']

    const unsubscribeGPACareplanStepIdDetails = () => {
        const unsubscribeMutation = gPACareplanStepIdDetails({
            urlParams: {},
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGPACareplanStepIdDetails()

        const promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let gPACareplanStepIdDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid && reasoningObj.questionData
                .previousQuestionData?.questionUseAnswerFrom) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.gPACareplanStepIdDetails.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        gPACareplanStepIdDetailsPromise = gPACareplanStepIdDetails({
                            authToken: newToken.value,
                            data: {
                                type: 'reasoningPrevious',
                                userId: userId || '',
                                reasoningSessionId: reasoningModal?.reasoningSessionId,
                                questionId: reasoningObj.questionData
                                    .previousQuestionData?.questionUseAnswerFrom
                            },
                            urlParams: {
                                careplanStepId: careplanStepId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            gPACareplanStepIdDetailsPromise && gPACareplanStepIdDetailsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [])

    const input = useMemo(() => {
        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', reasoningObj.id
            ].join('')

            const isChecked = _.includes(
                answerValue,
                obj.acqValue
            )

            const isDisabled = _.isArray(answerValue)
                ? questionData.questionAnswersAllowed
                    ? answerValue.length >= (questionData.questionAnswersAllowed || 0)
                        ? questionData.questionAnswersAllowed >= 1
                        : false
                    : false
                : false

            // if there are too many choices, use col-6
            return <div className={'col'} key={key}>
                <input type={'checkbox'}
                    className={[
                        'btn-check',
                        isDisabled && !isChecked ? 'disabled' : ''
                    ].join(' ')}
                    autoComplete={'off'}
                    id={key}
                    checked={isChecked}
                    // use class name instead.
                    // disabled={isDisabled && !isChecked}
                    onChange={(e) => {
                        e.preventDefault()
                    }}
                />
                <label className={'btn btn-dummy'} htmlFor={key}>

                    <div className={'question-checkbox'}>
                        <div className={'card justify-content-center px-3 py-2'}>

                            <div className={'d-flex flex-column'}>
                                <div className={'d-flex align-items-center'}>
                                    <div className={'p text-start'}>
                                        {obj.acqName}
                                    </div>
                                    {
                                        isChecked
                                            ? <div className={'question-checkmark ms-auto'}>
                                                <i className={'fa-light fa-check'}></i>
                                            </div>
                                            : <div className={'question-plussign ms-auto'}>
                                                <i className={'fa-light fa-plus'}></i>
                                            </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>

                </label>
            </div>
        }

        let filteredData: QuestionAnswer[] = questionData.questionAnswers

        // added in 4/12/2024. if there is previous question data AND it's from open list.
        // use this instead.
        const previousQuestionData1 = _.cloneDeep(reasoningData?.data.previousQuestionData)
        const previousQuestionData2 = _.cloneDeep(questionData.previousQuestionData)

        if (previousQuestionData2?.questionType === 'open-list') {
            const useField = previousQuestionData2.useField
            const onlyIf = previousQuestionData2.onlyIf

            // console.log('only if', onlyIf)
            // console.log('found indicator: ', foundIndicator)

            // console.log('useField: ', useField)

            // time to create the arr by the questionAnswer structure.
            const arr: QuestionAnswer[] = []
            let filteredItems : OpenListPreviousQuestions[] = previousQuestionData1
                ?.questionAnswers?.answers || []

            const firstElement: OpenListPreviousQuestions = previousQuestionData1
                ?.questionAnswers
                ?.answers?.[0]

            const foundIndicator = _.find(
                firstElement.inputs,
                (o) => {
                    const options = o.options
                    return options.answerValue === onlyIf?.answerValue
                }
            )

            if (foundIndicator) {
                filteredItems = filteredItems.filter((outer: OpenListPreviousQuestions) => {
                    // get inputs field.
                    const foundAgain = _.find(
                        outer.inputs,
                        (inner) => {
                            const options = inner.options
                            return options.answerValue === onlyIf?.answerValue
                        }
                    )

                    // console.log('id: ', outer.id)
                    console.log('foundAgain: ', foundAgain)

                    if (foundAgain) {
                        // check answertype through if statments.
                        if (foundAgain.options.answerType === 'radio') {
                            return foundAgain.answerValue === onlyIf?.answerIs
                        } else if (foundAgain.options.answerType === 'checkbox') {
                            return foundAgain.answerValue === onlyIf?.answerIs
                        }
                    }

                    return true
                })
            }

            // and then loop over the previous questionData.
            _.forEach(filteredItems, (answer: OpenListPreviousQuestions) => {
                // find input.
                // applicable if useField is defined.
                if (useField) {
                    const foundInput = answer.inputs.find((input) => {
                        return input.options.answerValue === useField?.answerValue
                    })

                    // console.log('foundInput: ', foundInput)

                    if (foundInput) {
                        if (_.isArray(foundInput.answerValue)) {
                            // console.log('pushing array')

                            _.forEach(foundInput.answerValue, value => {
                                arr.push({
                                    acqName: value,
                                    acqValue: value
                                })
                            })
                        } else if (_.isString(foundInput.answerValue)) {
                            // console.log('pushing string')
                            arr.push({
                                acqName: foundInput.answerValue,
                                acqValue: foundInput.answerValue
                            })
                        } else if (_.isBoolean(foundInput.answerValue)) {
                            console.log('cannot add boolean value because of type.')
                        }
                    }
                } else {
                    // if useField is not defined, use all inputs and then inputList in that order.
                    const restInputs = answer.inputs.filter(input => {
                        return input.options.answerType === 'input'
                    })

                    const restInputList = answer.inputs.filter(input => {
                        return input.options.answerType === 'input-list'
                    })

                    // creating empty obj BUT make it an array of strings.
                    // just use join in the end,
                    const acq: {
                        acqName: string[]
                        acqValue: string[]
                    } = {
                        acqName: [],
                        acqValue: []
                    }

                    // use join instead of concat please.

                    // go over the input boxes first.
                    _.forEach(restInputs, (foundInput) => {
                        if (_.isArray(foundInput.answerValue)) {
                            // console.log('pushing array')

                            _.forEach(foundInput.answerValue, value => {
                                acq.acqName.push(value)
                                acq.acqValue.push(value)
                            })
                        } else if (_.isString(foundInput.answerValue)) {
                            acq.acqName.push(foundInput.answerValue)
                            acq.acqValue.push(foundInput.answerValue)
                        } else if (_.isBoolean(foundInput.answerValue)) {
                            console.log('cannot add boolean value because of type.')
                        }
                    })

                    // then go over the input-lists.
                    _.forEach(restInputList, (foundInput) => {
                        if (_.isArray(foundInput.answerValue)) {
                            // console.log('pushing array')

                            _.forEach(foundInput.answerValue, value => {
                                acq.acqName.push(value)
                                acq.acqValue.push(value)
                            })
                        }
                    })

                    arr.push({
                        acqName: acq.acqName.join(' '),
                        acqValue: acq.acqValue.join(' ')
                    })
                }
            })

            filteredData = arr as QuestionAnswer[]
        } else {
        // filter by acqName.
            filteredData = (questionData?.questionAnswers || []) as QuestionAnswer[]
        }

        return <div className={'container-fluid'}>
            <div className={'row row-cols-1 btn-group-checkbox-list mt-3'}
                role={'group'}>
                {
                    _.map(filteredData, renderInputs)
                }
            </div>
        </div>
    }, [reasoningData])

    const filters = useMemo(() => {
        return _.isArray(answerValue)
            ? <ul className={'tag-list pb-0'}>{
                _.map(answerValue, (o, i) => {
                    const key = [
                        'answer-choice-tag', '-', i, '-', reasoningObj.id
                    ].join('')
                    return <li className={''} key={key} >
                        <div className={'tag'}>
                            <span style={{ verticalAlign: 'inherit' }}>
                                <span style={{ verticalAlign: 'inherit' }}>
                                    {o}
                                </span>
                            </span>
                            <div className={'icon'}onClick={(e) => {
                                e.preventDefault()
                            }}>
                                <i className={'fa-light fa-xmark'} aria-hidden={'true'}>

                                </i>
                            </div>
                        </div>
                    </li>
                })}
            </ul>
            : ''
    }, [])

    return <div className={['question-multi-list', isMobile ? 'px-0' : 'px-4'].join(' ')}>
        {/* filters */}
        {filters}
        {input}
    </div>
}

export default MultiList
