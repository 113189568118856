/* eslint-disable max-len */
import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useGPACareplanStepIdMutation } from '@doc/api'
import { PatientParams } from '@doc/type'
// import _ from 'lodash'
import CareplanStepDetails from '@doc/components/patients/details/components/modal/CareplanStepDetails'
import YesAndNoReasoningDetails from '@doc/components/patients/details/components/modal/YesAndNoReasoningDetails'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ComponentProps {
    careplanStepId: string
}

const AnswerSummary = ({ careplanStepId }: ComponentProps) => {
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const dateFormats = useAppSelector(selectDateFormats)

    const [gPACareplanStepId, gPACareplanStepIdMutation] =
    useGPACareplanStepIdMutation()
    const strings = useAppSelector(selectStrings)
    const { userId } = useParams<PatientParams>()

    const patientActivityCareplanStepIdData = gPACareplanStepIdMutation.data
        ?.data

    const [contentModal, toggleContentModal] = useState(false)
    const [reasoningModal, toggleReasoningModal] = useState<{
        reasoningSessionId: string;
        reasoningSetId: string;
        addedOn: number;
    } | undefined>(undefined)

    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                    userId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)

                if (isMounted) {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .gPACareplanStepId.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        gPACareplanStepId({
                            authToken: newToken.value,
                            urlParams: {
                                careplanStepId
                            },
                            data: {
                                userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        if (gPACareplanStepIdMutation.error) {
            const message = getErrorText(gPACareplanStepIdMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [gPACareplanStepIdMutation.error])

    useEffect(() => {
        if (userId) {
            return fetchData(token)
        } else {
            return () => {}
        }
    }, [token.id, token.valid, userId])

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    const result = <div className={'container-fluid answer-summary py-2'}>
        {/* title */}
        <div className={'row g-2 align-items-center'}>
            <div className={'col-auto'}>
                <h5 className={'fw-semibold'}>{
                    patientActivityCareplanStepIdData?.activityData.stepName
                }</h5>
            </div>
        </div>
        <div className={'row g-2 align-items-center mb-4'}>
            <div className={'col-auto'}>
                <p className={'mb-0'}>{
                    strings.doc
                        ?.text.patient.actions_in_app.answer_summary?.second_step.completed_on
                }</p>
            </div>
            <div className={'col-auto'}>
                <span>{
                    format(fromUnixTime(
                        patientActivityCareplanStepIdData?.activityData
                            .carePlanStep?.doneWhen || 0
                    ), dateFormats.format9)
                }</span>
            </div>
        </div>
        {/* then two buttons named content and reasoning at the center */}
        <div className={'row justify-content-end'}>
            <div className={'col-auto'}>
                <span className={'fw-bold mb-2 d-inline-block'}>
                    {[
                        strings.doc
                            ?.text.patient.actions_in_app.answer_summary
                            ?.second_step.reasoning,
                        strings.doc?.text.patient.actions_in_app
                            .answer_summary.second_step?.added_on,
                        ': '
                    ].join(' ')}
                </span>
            </div>
        </div>
        <div className={'row justify-content-around'}>
            <div className={'col-auto'}>
                <button type={'button'}
                    className={[
                        'btn btn-primary popup-button w-100 py-2'
                    ].join(' ')}
                    onClick={() => {
                        toggleContentModal(true)
                        // open the modal.
                    }}>
                    { strings.doc
                        ?.text.patient.actions_in_app.answer_summary?.second_step.content}
                </button>
            </div>
            {
                patientActivityCareplanStepIdData?.activityData.reasoningData.length
                    ? <div className={'col-auto'}>
                        {/* reasoning data can have multiple buttons per addedOn. */}
                        {
                            _.map(patientActivityCareplanStepIdData
                                ?.activityData.reasoningData, (o) => {
                                const key = `reasoning-button-${ o.reasoningSessionId }`

                                return <div key={key} className={'mb-2'}>
                                    <button type={'button'}
                                        className={[
                                            'btn btn-primary popup-button w-100 py-2'
                                        ].join(' ')}
                                        onClick={() => {
                                            toggleReasoningModal(o)
                                        }}>

                                        <span>
                                            {format(fromUnixTime(
                                                o.addedOn || 0
                                            ), dateFormats.format9) || ''}
                                        </span>
                                    </button>
                                </div>
                            })
                        }

                    </div>
                    : ''
            }

        </div>

    </div>

    return (<div >
        {gPACareplanStepIdMutation.isLoading
            ? LoadingContent
            : gPACareplanStepIdMutation.isSuccess
                ? result
                : JSON.stringify(gPACareplanStepIdMutation.error)}

        {/* problem will be that both calls will be made.
         you have to prevent fetchData from trigerring */}
        <CareplanStepDetails
            contentModal={contentModal}
            toggleContentModal={toggleContentModal}
            userId={userId}
            careplanStepId={careplanStepId}
        />

        <YesAndNoReasoningDetails
            reasoningModal={reasoningModal}
            toggleReasoningModal={toggleReasoningModal}
            userId={userId}
            careplanStepId={careplanStepId}
        />
    </div>)
}

export default AnswerSummary
