import { useEffect, useState } from 'react'

import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, PAGE_COUNT, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import _ from 'lodash'
import { toast } from 'react-toastify'

import { selectRouter } from '@app/app.store'
import { useGetCareFacilitiesMutation } from '@fmt/api'
import DashboardResponsiveHeader from '@fmt/components/DashboardResponsiveHeader'
import DataTable from '@fmt/components/facilities/DataTable'
import AddInterface from '@fmt/components/facilities/add/AddInterface'
import { MODULE_VERSION } from '@fmt/constants'
import { selectFacilityMenu, setFacilityMenu } from '@fmt/slice'
import { GetCareFacilitiesResponse } from '@fmt/type'
import { back, push } from '@lagunovsky/redux-react-router'

const FacilitiesMain = () => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const facilityMenu = useAppSelector(selectFacilityMenu)

    const validateRoute = useValidateRoute()
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const router = useAppSelector(selectRouter)

    const fixedCacheKey = 'shared-getCareFacilities-key'
    const [getCareFacilities, getCareFacilitiesMutation] = useGetCareFacilitiesMutation({
        fixedCacheKey
    })

    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount, setPageCount] = useState(PAGE_COUNT[1].value)

    // a workaround to prevent tables from flickering. simple but it works.
    const [
        getCareFacilitiesResponse,
        setGetCareFacilitiesResponse
    ] = useState<GetCareFacilitiesResponse>()

    useEffect(() => {
        if (getCareFacilitiesMutation.data) {
            setGetCareFacilitiesResponse(getCareFacilitiesMutation.data)
        }
    }, [getCareFacilitiesMutation.data])

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    useEffect(() => {
        setCurrentPage(0)
    }, [pageCount])

    const unsubscribeGetCareFacilities = () => {
        const unsubscribeMutation = getCareFacilities({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetCareFacilities()

        let getCareFacilitiesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCareFacilities.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        const skip = currentPage * (getCareFacilitiesResponse
                            ?.data.limit || 1)

                        const totalRecords = (getCareFacilitiesResponse
                            ?.data.totalRecords || 0) - 1

                        getCareFacilitiesPromise = getCareFacilities({
                            authToken: newToken.value,
                            data: {
                                skip: skip > (getCareFacilitiesResponse?.data
                                    .totalRecords || 0)
                                    ? totalRecords
                                    : skip,
                                limit: pageCount
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCareFacilitiesPromise && getCareFacilitiesPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid, currentPage, pageCount])

    useEffect(() => {
        if (getCareFacilitiesMutation.error) {
            const message = getErrorText(getCareFacilitiesMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCareFacilitiesMutation.error])

    // a useEffect where the addModal is open when the route is /add.
    useEffect(() => {
        if ((router.location.pathname === MODULE_TABLE.fmt
            .routes.createCareFacility)) {
            dispatch(setFacilityMenu({
                ...facilityMenu,
                showAddModal: true
            }))
        }
        // do not set to false automatically.let it close through
        // another useEffect
    }, [router.location.pathname])

    useEffect(() => {
        if (!facilityMenu.showAddModal) {
            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.routes.careFacilities,
                true
            )

            if (isValid) {
                dispatch(push(isValid.route))
            }
        }
    }, [facilityMenu.showAddModal])

    return <div className={'facility-page'}>

        {/* dashboard responsive header */}
        <DashboardResponsiveHeader />

        <div className={'main-content'}>

            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-between pt-16 pb-0 px-5'}>
                    <div className={'col-auto'}>
                        <a onClick={(e) => {
                            e.preventDefault()
                            dispatch(back())
                        }} className={'btn btn-round btn-sm-md'}>
                            <i
                                className={'fa-light fa-arrow-left'}
                                aria-hidden={'true'}>
                            </i>
                        </a>
                    </div>
                    <div className={'col-auto'}>
                        <h1 className={'my-0'}>{strings.fmt
                            ?.text.facility.menu.title}</h1>
                    </div>
                    <div className={'col-auto'}>
                        <a href={'#'}
                            onClick={(e) => {
                                e.preventDefault()
                            }} className={[
                                'btn btn-round btn-sm-md invisible'
                            ].join(' ')}>
                            <i
                                className={'fa-light fa-arrow-right'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </a>
                    </div>
                </div>
                <div className={'row justify-content-between mt-5'}>
                    <div className={'col'}>
                        <div className={
                            'container-fluid px-5 px-sm-3 facility-body list-interface'
                        }>
                            <div className={'h-100 row'}>
                                <div className={' col-12 col-md-11 mx-auto'}>
                                    <DataTable
                                        mode={'EDIT'}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        responseData={getCareFacilitiesResponse}
                                        fixedCacheKey={fixedCacheKey}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*, make step 3 content
                ALSO, including skip logic in step 2 and disable the next button IF
                there is no initial departments added.  */}
                <AddInterface fetchData={fetchData}/>
            </div>

        </div>

        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </div>
}

export default FacilitiesMain
