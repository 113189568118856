/* eslint-disable max-len */
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import styled from '@emotion/styled'
import Color from 'color'

export const HeaderImage = styled.div<{url:string}>`
    background-image: ${ (props) => {
    const urlToUse = props.url || '/images_new/header/header_blue.svg'
    return `url("${ urlToUse }")`
} };
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;



    @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        height: 113px;
    }

    @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        height: 260px;

    }

    width: 100%;
    position: absolute;
    z-index: -2;
`

export const HeaderGradient = styled.div`
    background-image: ${ (props) => {
    let colorStopOne = ''
    let colorStopTwo = ''

    colorStopOne = Color(props.theme.config.endUser?.login.headerGradientColor).alpha(0.5).rgb().string()
    colorStopTwo = Color(props.theme.config.endUser?.login.headerGradientColor).alpha(0.4).rgb().string()

    // eslint-disable-next-line max-len
    const gradientPart = `linear-gradient(90deg, ${ colorStopOne } 0%, ${ colorStopTwo } 22.63%, rgba(255, 255, 255, 0) 57.06%)`

    return `${ gradientPart }`
} };

    
    @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        height: 113px;
    }

    @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
        height: 260px;

    }
    width: 100%;
    position: absolute;
    z-index: -1;
`
