
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { GPACareplanStepIdDetails } from '@doc/type'
import { OpenMultiQuestionOption, QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidV4 } from 'uuid'
// eslint-disable-next-line max-len
import Scale from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/multi-question/Scale'
// eslint-disable-next-line max-len
import Open from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/multi-question/Open'
// eslint-disable-next-line max-len
import OpenList from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/types/multi-question/OpenList'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const MultiQuestion = ({
    reasoningObj
}: ComponentProps) => {
    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue as {
        answers: {
            answers: {
                answer: {
                    id: string;
                    actualValue: any;
                }[];
                question: QuestionAnswer & {
                    id: string;
                };
            }[];
            id: string;
            label: string;
            referenceObj?: any;
        }[];
        userQuestionIdentifier?: string | undefined;
        questionUseAnswerFrom?: string | undefined;
    }

    const [questionAnswers, setQuestionAnswers] = useState<(QuestionAnswer & {id: string})[]>([])
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const [formsState] = useReducer((
        state: {
            options: Pick<OpenMultiQuestionOption, 'id' | 'referenceObj' | 'answers' | 'label'>[]
        },
        action: | {type: ''}
    ) => {
        switch (action.type) {
            default:
                return state
        }
    }, {
        options: _.map(answerValue.answers, (o) => {
            return {
                ...o,
                answers: _.map(o.answers, o => {
                    return {
                        question: o.question,
                        answer: _.map(o.answer, p => {
                            return {
                                ...p,
                                hasAnswer: true
                            }
                        })
                    }
                })
            }
        })
    })

    useEffect(() => {
        // check if previous question is open-list.
        if (questionData?.previousQuestionData?.questionType === 'open-list') {
            const conditionFound = questionData?.questionAnswers.filter((o) => {
                return o.answerType !== 'multi-list'
            })

            setQuestionAnswers(_.map(conditionFound, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        } else if (questionData?.previousQuestionData?.questionType === 'multi-question') {
            const conditionFound = questionData?.questionAnswers.filter((o) => {
                return o.answerType !== 'multi-list'
            })

            setQuestionAnswers(_.map(conditionFound, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        } else if (!questionData?.previousQuestionData ||
            _.keys(questionData?.previousQuestionData).length === 0) {
            setQuestionAnswers(_.map(questionData?.questionAnswers, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        } else if (questionData?.previousQuestionData?.questionType === 'bodymap') {
            const conditionFound = questionData?.questionAnswers.filter((o) => {
                return o.answerType !== 'multi-list'
            })

            setQuestionAnswers(_.map(conditionFound, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        }
    }, [])

    // you want to display the rendered interface AFTER each label/button.

    const selectableButtons = useMemo(() => {
        console.log('form state in readonly options: ', formsState.options)
        console.log('question answers for denominator: ', questionAnswers)

        return formsState.options.map((obj) => {
            const key = [obj.id].join('')

            const numerator = obj.answers.map((outer) => {
                return _.filter(
                    outer.answer,
                    (inner) => inner?.hasAnswer
                ).length
            }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)

            const denominator = questionAnswers.map((o) => {
                return o?.answerQuestions?.length || 0
            }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)

            // console.log(obj)

            const label = <div className={'card shadow'}>
                <div className={[
                    'align-items-center justify-content-between',
                    ' d-flex flex-row h-100 card-body px-3'
                ].join(' ')}
                >
                    <div className={'p col'}>
                        {obj.label}
                    </div>
                    {/* show fraction when clicked once. */}
                    {<div className={'p col-auto offset-3'}>
                        {

                            numerator === denominator
                                ? <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                : [
                                    numerator, denominator
                                ].join(' / ')
                        }
                    </div>}
                </div>

            </div>
            let renderedGroups:(JSX.Element|undefined)[] = []

            renderedGroups = _.map(obj.answers, (outer, index) => {
                let arr: JSX.Element[] = []

                if (outer.question.answerType === 'scale') {
                    arr = _.map(outer.answer, (inner, innerIndex) => {
                        const key2 = [
                            obj.id,
                            outer.question.id,
                            inner.id
                        ].join('-')

                        // console.log('key2: ', key2)

                        return <Scale key={key2}
                            ids={{
                                activeOptionId: obj.id,
                                questionId: outer.question.id,
                                answerIndex: innerIndex
                            }}
                            obj={outer}
                            answer={inner}
                        />
                    })
                } else if (outer.question.answerType === 'open') {
                    arr = _.map(outer.answer, (inner, innerIndex) => {
                        const key2 = [
                            obj.id,
                            outer.question.id,
                            inner.id
                        ].join('-')

                        // console.log('key2: ', key2)

                        return <Open key={key2}
                            ids={{
                                activeOptionId: obj.id,
                                questionId: outer.question.id,
                                answerIndex: innerIndex
                            }}
                            obj={outer}
                            answer={inner}
                        />
                    })
                } else if (outer.question.answerType === 'open-list') {
                    arr = _.map(outer.answer, (inner, innerIndex) => {
                        const key2 = [
                            obj.id,
                            outer.question.id,
                            inner.id
                        ].join('-')

                        // console.log('key2: ', key2)

                        return <OpenList key={key2}
                            ids={{
                                activeOptionId: obj.id,
                                questionId: outer.question.id,
                                answerIndex: innerIndex
                            }}
                            obj={outer}
                            answer={inner}
                        />
                    })
                }

                return <div key={outer.question.id} className={'col-12'}>
                    <div className={'g-4 row row-cols-1'}>
                        {arr}
                    </div>
                </div >
            })

            return (
                <div key={key} className={'mb-6 multi-question-item gy-4 row'}>
                    {
                        obj.label !== 'Invisible Button' && <div className={'col-12'} >

                            {label}
                        </div>
                    }
                    {
                        renderedGroups
                    }
                </div>

            )
        })
    }, [formsState, questionAnswers])
    return (
        <div className={['question-multi-question'].join(' ')}>
            {/* //render an array of buttons */}
            <div className={['container-fluid', isMobile ? 'px-0' : ''].join(' ')}>

                <div className={[
                    'mt-4 mb-3'
                ].join(' ')}>
                    {selectableButtons}
                </div>
            </div>
        </div>
    )
}

export default MultiQuestion
