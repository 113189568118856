import { TIMER } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import {
    selectShowExpire,
    selectShowIdle,
    selectToken,
    setShowExpire,
    setShowIdle
} from '@app/slices/slice.token'

import { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import { MODULE_TABLE } from '@app/app.config'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { replace } from '@lagunovsky/redux-react-router'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import { useIdleTimer } from 'react-idle-timer'
const IdleComponent = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const showIdle = useAppSelector(selectShowIdle)
    const showExpire = useAppSelector(selectShowExpire)
    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    // const router = useAppSelector(selectRouter)
    const revalidateToken = useRevalidateToken()

    /** do not enable idle sessions on guest tokens. */

    const idleSession = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: TIMER.IDLE,
        // timeout: 3000,
        onIdle: () => {
            dispatch(setShowIdle(true))
            dispatch(setShowExpire(false))
        },
        debounce: 5000,
        onAction: async (event, idleTimer) => {
            // causes css styles to reset on debug.
            await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)
        }
    })

    const logout = () => {
        // simulate logout path.
        const isValid = validateRoute(
            activeModules.arr,
            MODULE_TABLE.login.moduleName,
            MODULE_TABLE.login.routes.logout,
            true
        )

        // will use replace instead of push so any redirects to login or logout
        if (isValid) {
            dispatch(replace(isValid.route))
        }
    }

    const expireSession = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: TIMER.EXPIRED,
        // timeout: 6000,
        onIdle: () => {
            console.log('expiry window shown')
            dispatch(setShowIdle(false))
            dispatch(setShowExpire(true))
            logout()
        }
    })

    /** reset timers on token revalidation */
    useEffect(() => {
        // reset the timers when the token id changes.
        // this excludes guest tokens.
        if (token.id && token.mode !== 'guest') {
            // start the idle and expiration timers.
            console.log('user is logged in. starting timers.')
            idleSession?.start()
            expireSession?.start()
        }
    }, [token.id, token.valid])

    // IF YOU ARE THE GUEST TOKEN, stop and then pause.
    useEffect(() => {
        // reset the timers when the token id changes.
        if (token.mode === 'guest') {
            console.log('user is a guest. resetting and pausing timers.')
            // start the idle and expiration timers.
            idleSession?.start()
            expireSession?.start()

            idleSession?.pause()
            expireSession?.pause()
        }
    }, [token.mode])

    const closeIdleModal = async () => {
        // assumes that you also want to revalidate.
        dispatch(setShowIdle(false))
        // if you close the modal this way,
        // we'll safely assume that you want to continue.
        // your session.
        await revalidateToken({
            value: token.value,
            id: token.id
        }, token.mode)
    }

    const endSession = async () => {
        dispatch(setShowIdle(false))
        logout()
    }

    const closeExpireModal = async () => {
        dispatch(setShowExpire(false))
    }

    return (
        <>
            <Modal onClosed={() => {
                // what should happen if the modal is closed.
            }} centered={true} isOpen={showIdle} toggle={() => {
                dispatch(setShowIdle(!showIdle))
            }}>
                <ModalHeader className={'justify-content-between'} toggle={() => {
                    dispatch(setShowIdle(!showIdle))
                }} close={ <a className={'btn btn-round '}
                    onClick={(e) => {
                        e.preventDefault()
                        closeIdleModal()
                    }}>
                    <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                    </i>
                </a>}
                >
                    <span className={'h5'}>{strings.app?.text.idle?.title || ''}</span>
                </ModalHeader>
                <ModalBody>
                    {/* content changes if idle / expire sessions expired. */}
                    <small className={'d-block mb-4'}>
                        {strings.app?.text.idle?.message || ''}
                    </small>
                    <div className={'row justify-content-end'}>
                        <div className={'col-auto mb-2 mb-md-0'}>
                            <button
                                type={'button'}
                                className={'btn btn-primary w-100'}
                                onClick={ () => {
                                    closeIdleModal()
                                }}>
                                {strings.app?.text.idle?.continue || ''}
                            </button>
                        </div>
                        <div className={'col-auto'}>
                            <button type={'button'}
                                className={'btn btn-secondary w-100'}
                                onClick={() => {
                                    endSession()
                                }}>{strings.app?.text.authenticate?.log_out || ''}</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal centered={true} isOpen={showExpire} toggle={() => {
                dispatch(setShowExpire(!showExpire))
            }}>
                <ModalHeader className={'justify-content-between'} toggle={() => {
                    dispatch(setShowExpire(!showExpire))
                }} close={ <a className={'btn btn-round '}
                    onClick={(e) => {
                        e.preventDefault()
                        closeExpireModal()
                    }}>
                    <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                    </i>
                </a>}
                >
                    <span className={'h5'}>{strings.app?.text.session?.title || ''}</span>
                </ModalHeader>
                <ModalBody>
                    {/* content changes if idle / expire sessions expired. */}
                    <small className={'d-block mb-4'}>
                        {strings.app?.text.session?.message || ''}
                    </small>
                    <div className={'row justify-content-end'}>
                        <div className={'col-auto'}>
                            <button type={'button'}
                                className={'btn btn-secondary w-100'}
                                onClick={() => {
                                    closeExpireModal()
                                }}>{strings.app?.text.close || ''}</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </>

    )
}

export default IdleComponent
