import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import {
    GetLibraryArticleRequest,
    GetLibraryArticleResponse,
    GetLibraryRequest,
    GetLibraryResponse,
    RemoveFromLibraryRequest,
    RemoveFromLibraryResponse,
    SaveToLibraryRequest,
    SaveToLibraryResponse
} from '@library/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'

export const libraryV10Api = createApi({
    reducerPath: 'libraryV10Api',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getLibrary: builder
            .mutation<GetLibraryResponse, GetLibraryRequest
                      & TokenAuth>({
                          query: (body) => {
                              const formData: GetLibraryRequest = { }

                              return {
                                  url: _.trimStart(
                                      MODULE_TABLE.library.apiPaths.getLibrary.path, '/'
                                  ),
                                  method: 'POST',
                                  headers: {
                                      Authorization: `Bearer ${ body.authToken }`,
                                      'Content-Type': 'application/json'
                                  },
                                  body: formData
                              }
                          }
                      }),
        saveToLibrary: builder
            .mutation<SaveToLibraryResponse, SaveToLibraryRequest
            & TokenAuth>({
                query: (body) => {
                    const formData: SaveToLibraryRequest = {
                        data: {
                            ...(body.data.personalCareplanStepId && {
                                personalCareplanStepId: body.data.personalCareplanStepId
                            }),
                            ...(body.data.articleId && {
                                articleId: body.data.articleId
                            })
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.library.apiPaths
                                .saveToLibrary.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        removeFromLibrary: builder
            .mutation<RemoveFromLibraryResponse, RemoveFromLibraryRequest
                & TokenAuth>({
                    query: (body) => {
                        const formData: RemoveFromLibraryRequest = {
                            data: {
                                articleId: body.data.articleId
                            }
                        }

                        return {
                            url: _.trimStart(
                                MODULE_TABLE.library.apiPaths
                                    .removeFromLibrary.path, '/'
                            ),
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`,
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        }
                    }
                }),
        getLibraryArticle: builder
            .mutation<GetLibraryArticleResponse, GetLibraryArticleRequest
            & TokenAuth>({
                query: (body) => {
                    const formData: GetLibraryArticleRequest = {
                        data: {
                            articleId: body.data.articleId
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.library.apiPaths
                                .getLibraryArticle.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })

    })
})

export const {
    useGetLibraryMutation,
    useRemoveFromLibraryMutation,
    useSaveToLibraryMutation,
    useGetLibraryArticleMutation
} = libraryV10Api
