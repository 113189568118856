import { MODULE_TABLE } from '@app/app.config'

/** This is the name of the module. */
export const MODULE_NAME = {
    LONG: MODULE_TABLE.reasonWithMe.moduleName,
    SHORT: MODULE_TABLE.reasonWithMe.moduleShortName
}

/** This is the major version number of the module. */
export const MODULE_MAJOR_VERSION = 1

/** This is the minor version number of the module. */
export const MODULE_MINOR_VERSION = 2

/** This is the hotfix version number of the module. */
export const MODULE_HOTFIX_VERSION = 7

/** This is the full version string of the module,
 * based on the major, minor, and hotfix versions. */
export const MODULE_VERSION = [
    MODULE_NAME.SHORT, ' ',
    `v.${ MODULE_MAJOR_VERSION }.${ MODULE_MINOR_VERSION }.${ MODULE_HOTFIX_VERSION }`
].join('')
export const IDS = {
    BODYMAP: {
        SWITCH: 'BODYMAP__SWTICH'
    },
    LIST: {
        // would be the case if the interface only has ONE search text in the page
        // being rendered.
        SEARCH: 'LIST__SEARCH'
    },
    SINGLE_DATE: {
        INPUT: 'INPUT__SINGLE_DATE'
    }
}
