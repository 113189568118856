import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { back, push } from '@lagunovsky/redux-react-router'
import { useGetOverallProgressMutation, useGetSettingsListMutation } from '@profile/api'
import { MODULE_VERSION } from '@profile/constants'
import { useEffect, useMemo } from 'react'
// import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { toast } from 'react-toastify'

import { TokenData } from '@app/types/type.token'
import {
    HeaderContent,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/profilePage/Components'
import _ from 'lodash'

import { useMediaQuery } from 'react-responsive'

const AppSettings = () => {
    // this has no backend functionality yet.
    // THIS IS NOT FINISHED.
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const validateRoute = useValidateRoute()
    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const [getSettingsList, getSettingsListMutation] = useGetSettingsListMutation()
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    // const [showModals, setShowModals] = useState({
    //     download: false,
    //     delete: false
    // })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const unsubscribeGetSettingsList = () => {
        const unsubscribeMutation = getSettingsList({ data: {} } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        if (getSettingsListMutation.error) {
            const message = getErrorText(getSettingsListMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSettingsListMutation.error])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetSettingsList()
        unsubscribeGetOverallProgress()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getSettingsList.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (foundApiPath && newToken.value) {
                        promise = getSettingsList({
                            authToken: newToken.value,
                            data: {}
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.profile
                                    .apiPaths.getSettingsList.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [activeModules.id])

    // const LargeTextSwitch = <div className={'settings-switch-wrapper'}>

    //     <i className={['fa-light fa-bell',
    //         isMobile ? 'me-3' : 'me-5'].join(' ')} aria-hidden={'true'}> </i>
    //     <div className={'title'}>{
    //         strings.profile?.text.large_text
    //     }</div>
    //     <div className={'form-check form-switch settings-switch ms-auto'}>
    //         <input className={'form-check-input'}
    //             type={'checkbox'} role={'switch'}
    //             id={IDS.APP_SETTINGS.SWITCHES.LARGE_TEXT} />
    //     </div>

    // </div>

    // const DarkModeSwitch = <div className={'settings-switch-wrapper'}>

    //     <i className={['fa-light fa-bell',
    //         isMobile ? 'me-3' : 'me-5'].join(' ')} aria-hidden={'true'}> </i>
    //     <div className={'title'}>{
    //         strings.profile?.text.dark_mode
    //     }</div>
    //     <div className={'form-check form-switch settings-switch ms-auto'}>
    //         <input className={'form-check-input'}
    //             type={'checkbox'} role={'switch'}
    //             id={IDS.APP_SETTINGS.SWITCHES.DARK_MODE} />
    //     </div>
    // </div>

    const PageLinks = useMemo(() => {
        return <div className={'card'}>

            <div className={'card-body px-4'}>
                <p className={isMobile ? 'h6 mb-3 mt-2' : 'h4 mb-4 mt-3'}>
                    {strings.profile?.text.app_settings.other }</p>
                <div className={!isMobile ? 'd-flex flex-column px-4' : 'd-flex flex-column'}>
                    {
                        _.map(getSettingsListMutation.data?.data.listData || [], (o, index) => {
                            const key = index
                            return <div className={'pagelink'} key={key}>

                                <i className={[`fa-light ${ o.pageIcon || 'fa-blinds-open' }`,
                                    isMobile ? 'me-3' : 'me-5'].join(' ')}
                                aria-hidden={'true'}
                                >
                                </i>
                                <div className={'title'}>
                                    {o.name}
                                </div>
                                <div className={'link-wrapper ms-auto'}>
                                    <a href={'#'} onClick={(e) => {
                                        e.preventDefault()
                                        const isValid = validateRoute(
                                            activeModules.arr,
                                            MODULE_TABLE.profile.moduleName,
                                            MODULE_TABLE.profile.routes.content,
                                            true
                                        )

                                        if (isValid) {
                                            dispatch(push(
                                                _.replace(isValid.route,
                                                    ':listId',
                                                    o.listId
                                                )
                                            ))
                                        }
                                    }} className={'nav-link stretched-link'}>
                                        <i className={'fa-light fa-chevron-right'}
                                            aria-hidden={'true'}></i>
                                    </a>
                                </div>

                            </div>
                        })
                    }
                </div>
            </div>

        </div>
    }, undefined)

    // const DownloadMyDataModal = <Modal centered={true} isOpen={showModals.download}
    // toggle={() => {
    //     setShowModals({
    //         ...showModals,
    //         download: !showModals.download
    //     })
    // }}>
    //     <ModalHeader className={'justify-content-between'} toggle={() => {
    //         setShowModals({
    //             ...showModals,
    //             download: !showModals.download
    //         })
    //     }} close={ <a className={'btn btn-round '}
    //         onClick={(e) => {
    //             e.preventDefault()
    //             setShowModals({
    //                 ...showModals,
    //                 download: false
    //             })
    //         }}>
    //         <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
    //         </i>
    //     </a>}
    //     >
    //     </ModalHeader>
    //     <ModalBody>
    //         <p className={'ff-medium mb-2'}>{
    //             strings.profile?.text.download_data?.confirmation_message?.[1]
    //         }</p>

    //         <p className={'mb-2'}>{strings.profile?.text
    //             .download_data?.confirmation_message?.[2]}</p>
    //     </ModalBody>
    //     <ModalFooter>
    //         <div className={'container px-0'}>

    //             <div className={'row align-items-center'}>

    //                 <div className={'col-6 col-md-4 ms-auto'}>

    //                     <button type={'button'} onClick={() => {
    //                         // do api call.
    //                     }} className={'btn btn-primary btn-sm w-100'}>
    //                         {strings.app?.text.yes}
    //                     </button>

    //                 </div>

    //                 <div className={'col-6 col-md-4 me-auto'}>

    //                     <button type={'button'} onClick={() => {
    //                         setShowModals({
    //                             ...showModals,
    //                             download: false
    //                         })
    //                     }} className={'btn btn-secondary btn-sm w-100'}>
    //                         {strings.app?.text.no}
    //                     </button>

    //                 </div>

    //             </div>

    //         </div>
    //     </ModalFooter>
    // </Modal>

    // const DeleteMyDataModal = <Modal centered={true} isOpen={showModals.delete} toggle={() => {
    //     setShowModals({
    //         ...showModals,
    //         delete: !showModals.delete
    //     })
    // }}>
    //     <ModalHeader className={'justify-content-between'} toggle={() => {
    //         setShowModals({
    //             ...showModals,
    //             delete: !showModals.delete
    //         })
    //     }} close={ <a className={'btn btn-round '}
    //         onClick={(e) => {
    //             e.preventDefault()
    //             setShowModals({
    //                 ...showModals,
    //                 delete: false
    //             })
    //         }}>
    //         <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
    //         </i>
    //     </a>}
    //     >
    //     </ModalHeader>
    //     <ModalBody>
    //         <p className={'ff-medium mb-2'}>{
    //             strings.profile?.text.delete_data?.confirmation_message?.[1]
    //         }</p>

    //         <p className={'mb-2'}>{strings.profile?.text
    //             .delete_data?.confirmation_message?.[2]}</p>
    //     </ModalBody>
    //     <ModalFooter>
    //         <div className={'container px-0'}>

    //             <div className={'row align-items-center'}>

    //                 <div className={'col-6 col-md-4 ms-auto'}>

    //                     <button type={'button'} onClick={() => {
    //                     // do api call.
    //                     }} className={'btn btn-primary btn-sm w-100'}>
    //                         {strings.app?.text.yes}
    //                     </button>

    //                 </div>

    //                 <div className={'col-6 col-md-4 me-auto'}>

    //                     <button type={'button'} onClick={() => {
    //                         setShowModals({
    //                             ...showModals,
    //                             delete: false
    //                         })
    //                     }} className={'btn btn-secondary btn-sm w-100'}>
    //                         {strings.app?.text.no}
    //                     </button>

    //                 </div>

    //             </div>

    //         </div>
    //     </ModalFooter>
    // </Modal>

    // const DownloadMyData = <div className={'d-flex flex-row align-items-center'}>

    //     <button className={'btn btn-round btn-rounded btn-lg'}
    //         id={IDS.APP_SETTINGS.SWITCHES.DOWNLOAD_DATA}
    //         type={'button'}onClick={(e) => {
    //             e.preventDefault()
    //             // show the modal which should be a confirmation message.
    //         }}
    //     >
    //         <i className={'fa-light fa-sharp fa-arrow-down-to-bracket'}
    //             aria-hidden={'true'}></i>
    //     </button>

    //     <label htmlFor={IDS.APP_SETTINGS.SWITCHES.DOWNLOAD_DATA}
    //         className={'fw-bold mx-3 my-0 cursor-pointer'}>
    //         {strings.profile?.text?.download_data?.label}
    //     </label>

    // </div>

    // const DeleteMyData = <div className={'d-flex flex-row align-items-center'}>

    //     <button className={'btn btn-round btn-rounded btn-lg'}
    //         id={IDS.APP_SETTINGS.SWITCHES.DELETE_DATA}
    //         type={'button'} onClick={(e) => {
    //             e.preventDefault()
    //             // show the modal which should be a confirmation message.
    //         }}
    //     >
    //         <i className={'fa-light fa-trash-can'} aria-hidden={'true'}></i>
    //     </button>

    //     <label htmlFor={IDS.APP_SETTINGS.SWITCHES.DELETE_DATA}
    //         className={'fw-bold mx-3 my-0 cursor-pointer'}>
    //         {strings.profile?.text?.delete_data?.label}
    //     </label>

    // </div>

    // const AccessibilitySection = useMemo(() => {
    //     return <div className={'card'}>

    //         <div className={'card-body px-4'}>
    //             <p className={isMobile ? 'h6 mb-3 mt-2' : 'h4 mb-4 mt-3'}>
    //                 {strings.profile?.text.accessibility }</p>
    //             <div className={!isMobile ? 'd-flex flex-column px-4' : 'd-flex flex-column'}>
    //                 {LargeTextSwitch}
    //                 {DarkModeSwitch}
    //             </div>
    //         </div>

    //     </div>
    // }, undefined)

    // const OtherButtonsSection = <div className={'row row-cols-1 g-3'}>
    //     <div className={'col'}>
    //         {DownloadMyData}
    //     </div>
    //     <div className={'col'}>
    //         {DeleteMyData}
    //     </div>
    // </div>

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        <HeaderImage
            className={'p-4'}
            url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} >
        </HeaderImage>
        <HeaderGradient />
        <HeaderContent
            className={'p-4'}
        >
            <button
                type={'button'}
                className={[
                    'btn btn-round btn-rounded'
                ].join('')}
                onClick={(e) => {
                    e.preventDefault()
                    dispatch(back())
                }}
            >
                <i
                    className={[
                        'fa-light',
                        'fa-arrow-left'
                    ].join(' ')}
                    aria-hidden={'true'}>
                </i>
            </button>
        </HeaderContent>
        <div className={'main-content'}>
            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-center mb-5'}>
                    <div className={'col-10 col-md-8'}>
                        <h1 className={'mt-4  mb-5'}>{strings.profile?.text
                            .app_settings.settings}</h1>
                        {/* two columns. */}
                        {/* {DownloadMyDataModal}
                        {DeleteMyDataModal} */}

                        {/* two columns. */}

                        <div className={'row g-4'}>
                            <div className={'col-12 col-xl-6'}>
                                <div className={'row row-cols-1 g-3'}>
                                    {/* <div className={'col'}>
                                        {NotificationSection}
                                    </div> */}
                                    <div className={'col'}>
                                        {PageLinks}
                                    </div>
                                </div>
                            </div>
                            {/* <div className={'col-12 col-xl-6'}>
                                <div className={'row row-cols-1 g-3'}>
                                    <div className={'col'}>
                                        {AccessibilitySection}
                                    </div>
                                    <div className={'col'}>
                                        {OtherButtonsSection}
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <div className={'main-content'}>
            <div className={'container-fluid px-4'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-12 cards-container mb-5'}>
                        <div className={'row align-items-center g-3 mb-3'}>
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'btn btn-round btn-rounded'
                                    ].join('')}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(back())
                                    }}
                                >
                                    <i
                                        className={[
                                            'fa-light',
                                            'fa-arrow-left'
                                        ].join(' ')}
                                        aria-hidden={'true'}>
                                    </i>
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <h2 className={'mb-0'}>{strings.profile?.text
                                    .app_settings.settings}</h2>
                            </div>
                        </div>

                        {/* two columns. */}
                        {/* {DownloadMyDataModal} */}
                        {/* {DeleteMyDataModal} */}

                        {/* two columns. */}

                        <div className={'row g-4'}>
                            <div className={'col-12 col-xl-6'}>
                                <div className={'row row-cols-1 g-3'}>
                                    {/* <div className={'col'}>
                                        {NotificationSection}
                                    </div> */}
                                    <div className={'col'}>
                                        {PageLinks}
                                    </div>
                                </div>
                            </div>
                            {/* <div className={'col-12 col-xl-6'}>
                                <div className={'row row-cols-1 g-3'}>
                                    <div className={'col'}>
                                        {AccessibilitySection}
                                    </div>
                                    <div className={'col'}>
                                        {OtherButtonsSection}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>

    return <div className={'account-settings-page position-relative'}>

        {/* the container */}

        {
            isMobile ? mobileResult : desktopResult
        }
    </div>
}

export default AppSettings
