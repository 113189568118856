import { MODULE_TABLE } from '@app/app.config'
import { MOBILE_RESPONSIVE_LIMIT, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useChangePasswordMutation } from '@login/api'
import { MODULE_VERSION } from '@login/constants'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { ChangePasswordKeys } from '@profile/type'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/login/Components'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import FixedImage from '@app/components/FixedImage'
import { replace } from '@lagunovsky/redux-react-router'
import { selectTokenForForcePasswordChange } from '@login/slices/slice.login'
import {
    CHANGE_PASSWORD_FORMIK_INITIAL_VALUES,
    CHANGE_PASSWORD_VALIDATION_SCHEMA,
    IDS as PROFILE_IDS
} from '@profile/constants'
import { PASSWORD_STRENGTH_LIMIT } from '@registration/constants'
import { useMediaQuery } from 'react-responsive'

const ForcePasswordChangePage = () => {
    const [changePassword, changePasswordMutation] = useChangePasswordMutation({
        fixedCacheKey: 'shared-force-password-change-key'
    })

    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)
    const token = useAppSelector(selectToken)
    const tokenForForcePasswordChange = useAppSelector(selectTokenForForcePasswordChange)

    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const validateRoute = useValidateRoute()

    const [togglePassword, setTogglePassword] = useState({
        currentPassword: false,
        newPassword: false,
        retypeNewPassword: false
    })

    const [weakPasswordMessage, setWeakPasswordMessage] = useState<string>('')

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const [strength, setStrength] = useState(0)

    const calculateStrength = (value: string) => {
        let score = 0
        if (value.length >= 8) {
            score += 1
        }
        if (/[a-z]/.test(value)) {
            score += 1
        }
        if (/[A-Z]/.test(value)) {
            score += 1
        }
        if (/[0-9]/.test(value)) {
            score += 1
        }
        if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(value)) {
            score += 1
        }

        setStrength(
            Math.min(
                Math.round(
                    score / (PASSWORD_STRENGTH_LIMIT / 10)),
                PASSWORD_STRENGTH_LIMIT
            )
        )
    }

    const changePasswordFormik = useFormik({
        initialValues: CHANGE_PASSWORD_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CHANGE_PASSWORD_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.password_match || ''
        ),
        onSubmit: async (values) => {
            if (tokenForForcePasswordChange) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)

                const isValid = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.login.moduleName,
                    MODULE_TABLE.login.apiPaths.changePassword.path,
                    true
                )

                if (isValid && newToken.value) {
                    changePassword({
                        authToken: tokenForForcePasswordChange,
                        data: {
                            currentPassword: values.currentPassword,
                            newPassword: values.newPassword,
                            newPassword2: values.retypeNewPassword
                        }
                    })
                }
            }
        }
    })

    useEffect(() => {
        calculateStrength(changePasswordFormik.values.newPassword)
    }, [changePasswordFormik.values.newPassword])

    const CurrentPasswordInput = useMemo(() => {
        const fieldName: ChangePasswordKeys = 'currentPassword'

        return <div className={'form-group mb-4'}>
            <label htmlFor={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.CURRENT_PASSWORD}
                className={'form-label'}>{
                    strings.profile?.text.account_settings.change_password.current_password
                }</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={togglePassword.currentPassword ? 'text' : 'password'}
                    value={changePasswordFormik.values.currentPassword}
                    className={`form-control ${
                        changePasswordFormik.errors.currentPassword && 'border-danger' }`}
                    placeholder={strings.profile?.text.account_settings
                        .change_password.current_password || ''}
                    id={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.CURRENT_PASSWORD}
                    name={fieldName}
                    onChange={changePasswordFormik.handleChange}
                    data-parsley-trigger={'blur'}
                    required />
                <label className={'form-floaticon'}
                    htmlFor={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.CURRENT_PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        setTogglePassword({
                            ...togglePassword,
                            currentPassword: !togglePassword.currentPassword
                        })
                    }}>
                        {!togglePassword.currentPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                changePasswordFormik.errors.currentPassword
                    ? changePasswordFormik.errors.currentPassword
                    : null
            }</div>
        </div>
    }, [
        strings,
        changePasswordFormik.values.currentPassword,
        changePasswordFormik.errors.currentPassword,
        togglePassword
    ])

    const NewPasswordInput = useMemo(() => {
        const fieldName: ChangePasswordKeys = 'newPassword'

        return <div className={'form-group mb-4'}>
            <label htmlFor={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.NEW_PASSWORD}
                className={'form-label'}>{
                    strings.profile?.text.account_settings.change_password.new_password
                }</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={togglePassword.newPassword ? 'text' : 'password'}
                    value={changePasswordFormik.values.newPassword}
                    className={`form-control ${
                        changePasswordFormik.errors.newPassword && 'border-danger' }`}
                    placeholder={strings.profile?.text.account_settings
                        .change_password.new_password || ''}
                    id={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.NEW_PASSWORD}
                    name={fieldName}
                    onChange={changePasswordFormik.handleChange}
                    data-parsley-trigger={'blur'}
                    required />
                <label className={'form-floaticon'}
                    htmlFor={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.NEW_PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        setTogglePassword({
                            ...togglePassword,
                            newPassword: !togglePassword.newPassword
                        })
                    }}>
                        {!togglePassword.newPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                changePasswordFormik.errors.newPassword
                    ? changePasswordFormik.errors.newPassword
                    : weakPasswordMessage
            }</div>
        </div>
    }, [
        weakPasswordMessage,
        strings,
        changePasswordFormik.values.newPassword,
        changePasswordFormik.errors.newPassword,
        togglePassword
    ])

    const RetypeNewPasswordInput = useMemo(() => {
        const fieldName: ChangePasswordKeys = 'retypeNewPassword'

        return <div className={'form-group mb-4'}>
            <label htmlFor={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.RETYPE_NEW_PASSWORD}
                className={'form-label'}>{
                    strings.profile?.text.account_settings.change_password.retype_new_password
                }</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={togglePassword.retypeNewPassword ? 'text' : 'password'}
                    value={changePasswordFormik.values.retypeNewPassword}
                    className={`form-control ${
                        changePasswordFormik.errors.retypeNewPassword && 'border-danger' }`}
                    placeholder={strings.profile?.text.account_settings
                        .change_password.retype_new_password || ''}
                    id={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.RETYPE_NEW_PASSWORD}
                    name={fieldName}
                    onChange={changePasswordFormik.handleChange}
                    data-parsley-trigger={'blur'}
                    required />
                <label className={'form-floaticon'}
                    htmlFor={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.RETYPE_NEW_PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        setTogglePassword({
                            ...togglePassword,
                            retypeNewPassword: !togglePassword.retypeNewPassword
                        })
                    }}>
                        {!togglePassword.retypeNewPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                changePasswordFormik.errors.retypeNewPassword
                    ? changePasswordFormik.errors.retypeNewPassword
                    : null
            }</div>
        </div>
    }, [
        strings,
        changePasswordFormik.values.retypeNewPassword,
        changePasswordFormik.errors.retypeNewPassword,
        togglePassword
    ])

    const passwordStrengthCells = useMemo(() => {
        const items = []

        for (let i = 0; i < PASSWORD_STRENGTH_LIMIT; i++) {
            items.push(
                <div key={i} className={`meter-item ${
                    i < strength ? 'active' : ''
                }`}></div>
            )
        }

        return (
            <div className={'password-strength-meter mb-4'}>
                {items}
            </div>
        )
    }, [strength])

    useEffect(() => {
        if (changePasswordMutation.data) {
            const data = changePasswordMutation.data
            if (data.status === 'OK') {
                //
            } else if (data.status === 'NOT_OK') {
                if (data.message === 'Signature has expired') {
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.routes.login,
                        true
                    )
                    if (isValid) {
                        dispatch(replace(isValid.route))
                    }
                } else {
                    toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    setWeakPasswordMessage(data.message)
                }
            }
        }
    }, [changePasswordMutation.data, activeModules.id])

    useEffect(() => {
        if (changePasswordMutation.error) {
            const message = getErrorText(changePasswordMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [changePasswordMutation.error])

    const ChangePasswordSubmitButton = useMemo(() => {
        const buttonContent = changePasswordMutation.isLoading
            ? (
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{strings.login?.text?.form.loading_button}</span>
                </div>
            )
            : strings.profile?.text.account_settings.change_password.change_password

        return (<button type={'submit'}
            disabled={changePasswordMutation.isLoading}
            form={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.FORM}
            className={'btn btn-primary btn-lg w-100'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    {buttonContent}
                </div>
            </div>
        </button>)
    }, [changePasswordMutation.isLoading, isMobile])

    const desktopResult = <>
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        {/* put header image here */}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-5'}>

                <div className={'justify-content-center row'}>
                    <div className={'col-10 px-0'}>
                        {/* status card */}
                        <div className={'status-card card border-0'}>
                            <h4 className={'mb-0'}>
                                {strings?.login?.text
                                    ?.title.reset_password}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                        <form id={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.FORM}
                            onSubmit={changePasswordFormik.handleSubmit}
                        >
                            {CurrentPasswordInput}
                            {NewPasswordInput}
                            {passwordStrengthCells}
                            {RetypeNewPasswordInput}
                        </form>
                    </div>
                </div>
                <div
                    className={[
                        'row align-items-center justify-content-center'
                    ].join(' ')}
                >
                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                        {ChangePasswordSubmitButton}
                    </div>
                </div>

            </div>
        </div>

        <div className={'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'}>
            {MODULE_VERSION}
        </div>
    </>

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>
            <div className={'justify-content-center row'}>
                <div className={'col-12'}>
                    {/* status card */}
                    <div className={'status-card card border-0'}>
                        <span className={'fw-semibold'}>
                            {strings?.login?.text
                                ?.title.reset_password}
                        </span>
                    </div>
                </div>
            </div>

            <div className={'form-container'}>
                <div>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                            <form id={PROFILE_IDS.ACCOUNT_SETTINGS.PASSWORD.FORM}
                                onSubmit={changePasswordFormik.handleSubmit}>
                                {CurrentPasswordInput}
                                {NewPasswordInput}
                                {passwordStrengthCells}
                                {RetypeNewPasswordInput}
                            </form>

                        </div>
                    </div>
                </div>
                <div>

                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                            {ChangePasswordSubmitButton}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>

    return <div>
        <div className={'forget-password-page'}>
            {
                isMobile ? mobileResult : desktopResult
            }

        </div>
    </div>
}

export default ForcePasswordChangePage
