/* eslint-disable max-len */
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import Color from 'color'
import { css, useTheme } from '@emotion/react'

const GlobalStyle = () => {
    const theme = useTheme()

    const CSS_CONTENT = css`

        :root {
            --bs-link-color: ${ theme.config.bootstrap.linkColor };
            --bs-link-hover-color: ${ theme.config.bootstrap.linkHoverColor };
            --bs-body-color: ${ theme.config.bootstrap.bodyColor }; 
        }

        .offcanvas {
            --bs-offcanvas-color: ${ theme.config.bootstrap.offcanvas.color };
            --bs-offcanvas-bg: ${ theme.config.bootstrap.offcanvas.bg };
        }

        /* Shadow */
        .shadow {
            box-shadow: 0px 4px 50px 0px ${ theme.config.bootstrap.shadow.boxShadow };
        }

        /* Form Control */
        .form-control {
            padding: 0.95rem 1.5rem;
            border-radius: 12px;
            background-color: ${ theme.config.bootstrap.formControl.backgroundColor };
            color: ${ theme.config.bootstrap.formControl.color }; 

            &:focus{
                background-color: ${ theme.config.bootstrap.formControl.focus.backgroundColor };
                color: ${ theme.config.bootstrap.formControl.focus.color }; 
            }

            &:disabled {
                background-color: ${ theme.config.bootstrap.formControl.disabled.backgroundColor };
                color: ${ theme.config.bootstrap.formControl.disabled.color }; 

                //same thing
                &::placeholder {
                    color: ${ theme.config.bootstrap.formControl.disabled.color }; 
                }
            }
        }

        .form-control-plaintext {
            padding: 0.95rem 1.5rem;
            border-radius: 12px;
            color: ${ theme.config.bootstrap.formControl.color }; 

            &:focus{
                color: ${ theme.config.bootstrap.formControl.focus.color }; 
            }

            &:disabled {
                color: ${ theme.config.bootstrap.formControl.disabled.color }; 

                //same thing
                &::placeholder {
                    color: ${ theme.config.bootstrap.formControl.disabled.color }; 
                }
            }
        }

        /* Buttons */
        .btn {
            --bs-btn-bg: ${ theme.config.bootstrap.buttons.bg };
            --bs-btn-color: ${ theme.config.bootstrap.buttons.color };
            --bs-btn-hover-color: ${ theme.config.bootstrap.buttons.hoverColor };
            --bs-btn-hover-bg: ${ theme.config.bootstrap.buttons.hoverBg };
            /*when toggling the button, the border width kinda makes the button move.*/
            --bs-btn-border-width: 0px;
            /** all buttons will have a shadow */
            --bs-btn-focus-shadow-rgb: ${ theme.config.bootstrap.btnWhite.focusShadowRGB };
            box-shadow: 0px 2px 4px 0px ${ theme.config.bootstrap.btnWhite.boxShadow };
        }

        .btn-white {
            --bs-btn-color: ${ theme.config.bootstrap.btnWhite.color };
            --bs-btn-bg: ${ theme.config.bootstrap.btnWhite.bg };
            --bs-btn-border-color: ${ theme.config.bootstrap.btnWhite.borderColor };
            --bs-btn-hover-color: ${ theme.config.bootstrap.btnWhite.hoverColor };
            --bs-btn-hover-bg: ${ theme.config.bootstrap.btnWhite.hoverBg };
            --bs-btn-hover-border-color: ${ theme.config.bootstrap.btnWhite.hoverBorderColor };
            --bs-btn-focus-shadow-rgb: ${ theme.config.bootstrap.btnWhite.focusShadowRGB };
            --bs-btn-active-color: ${ theme.config.bootstrap.btnWhite.activeColor };
            --bs-btn-active-bg: ${ theme.config.bootstrap.btnWhite.activeBg };
            --bs-btn-active-border-color: ${ theme.config.bootstrap.btnWhite.activeBorderColor };
            --bs-btn-active-shadow: ${ theme.config.bootstrap.btnWhite.activeShadow };
            --bs-btn-disabled-color: ${ theme.config.bootstrap.btnWhite.disabledColor };
            --bs-btn-disabled-bg: ${ theme.config.bootstrap.btnWhite.disabledBg };
            --bs-btn-disabled-border-color: ${ theme.config.bootstrap.btnWhite.disabledBorderColor };
        }

        .btn-dark {
            --bs-btn-color: ${ theme.config.bootstrap.btnDark.color };
            --bs-btn-bg: ${ theme.config.bootstrap.btnDark.bg };
            --bs-btn-border-color: ${ theme.config.bootstrap.btnDark.borderColor };
            --bs-btn-hover-color: ${ theme.config.bootstrap.btnDark.hoverColor };
            --bs-btn-hover-bg: ${ theme.config.bootstrap.btnDark.hoverBg };
            --bs-btn-hover-border-color: ${ theme.config.bootstrap.btnDark.hoverBorderColor };
            --bs-btn-focus-shadow-rgb: ${ theme.config.bootstrap.btnDark.focusShadowRGB };
            --bs-btn-active-color: ${ theme.config.bootstrap.btnDark.activeColor };
            --bs-btn-active-bg: ${ theme.config.bootstrap.btnDark.activeBg };
            --bs-btn-active-border-color: ${ theme.config.bootstrap.btnDark.activeBorderColor };
            --bs-btn-active-shadow: ${ theme.config.bootstrap.btnDark.activeShadow };
            --bs-btn-disabled-color: ${ theme.config.bootstrap.btnDark.disabledColor };
            --bs-btn-disabled-bg: ${ theme.config.bootstrap.btnDark.disabledBg };
            --bs-btn-disabled-border-color: ${ theme.config.bootstrap.btnDark.disabledBorderColor };
        }

        .btn-primary {
            --bs-btn-color: ${ theme.config.bootstrap.btnPrimary.color };
            --bs-btn-bg: ${ theme.config.bootstrap.btnPrimary.bg };
            --bs-btn-border-color: ${ theme.config.bootstrap.btnPrimary.borderColor };
            --bs-btn-hover-color: ${ theme.config.bootstrap.btnPrimary.hoverColor };
            --bs-btn-hover-bg: ${ theme.config.bootstrap.btnPrimary.hoverBg };
            --bs-btn-hover-border-color: ${ theme.config.bootstrap.btnPrimary.hoverBorderColor };
            --bs-btn-focus-shadow-rgb: ${ theme.config.bootstrap.btnPrimary.focusShadowRGB };
            --bs-btn-active-color: ${ theme.config.bootstrap.btnPrimary.activeColor };
            --bs-btn-active-bg: ${ theme.config.bootstrap.btnPrimary.activeBg };
            --bs-btn-active-border-color: ${ theme.config.bootstrap.btnPrimary.activeBorderColor };
            --bs-btn-active-shadow: ${ theme.config.bootstrap.btnPrimary.activeShadow };
            --bs-btn-disabled-color: ${ theme.config.bootstrap.btnPrimary.disabledColor };
            --bs-btn-disabled-bg: ${ theme.config.bootstrap.btnPrimary.disabledBg };
            --bs-btn-disabled-border-color: ${ theme.config.bootstrap.btnPrimary.disabledBorderColor };
            
            &.disabled {
                border-width: 0px;
            }
            border-radius: 24px;

        }

        .btn-secondary {
            --bs-btn-border-width: 1px;
            --bs-btn-color: ${ theme.config.bootstrap.btnSecondary.color };
            --bs-btn-bg: ${ theme.config.bootstrap.btnSecondary.bg };
            --bs-btn-border-color: ${ theme.config.bootstrap.btnSecondary.borderColor };
            --bs-btn-hover-color: ${ theme.config.bootstrap.btnSecondary.hoverColor };
            --bs-btn-hover-bg: ${ theme.config.bootstrap.btnSecondary.hoverBg };
            --bs-btn-hover-border-color: ${ theme.config.bootstrap.btnSecondary.hoverBorderColor };
            --bs-btn-focus-shadow-rgb: ${ theme.config.bootstrap.btnSecondary.focusShadowRgb };
            --bs-btn-active-color: ${ theme.config.bootstrap.btnSecondary.activeColor };
            --bs-btn-active-bg: ${ theme.config.bootstrap.btnSecondary.activeBg };
            --bs-btn-active-border-color: ${ theme.config.bootstrap.btnSecondary.activeBorderColor };
            --bs-btn-active-shadow: ${ theme.config.bootstrap.btnSecondary.activeShadow };
            --bs-btn-disabled-color: ${ theme.config.bootstrap.btnSecondary.disabledColor };
            --bs-btn-disabled-bg: ${ theme.config.bootstrap.btnSecondary.disabledBg };
            --bs-btn-disabled-border-color: ${ theme.config.bootstrap.btnSecondary.disabledBorderColor };

            &.disabled {
                border-width: 0px;
            }
            border-radius: 24px;
        }

            
        .btn-lg {
            font-weight: 600;
            font-size: 14px;
        }

        .btn-lg,
        .btn-group-lg > .btn {
            --bs-btn-padding-y: 1.0rem;
            --bs-btn-padding-x: 1.5rem;
        }

        /* Form Styles */
        form {
            .form-group .form-label {
                padding-left: 0rem;
                font-weight: 700;
            }

            .form-text.error {
                color: ${ theme.config.bootstrap.form.errorColor };
            }
        }

        /* Headings */
        h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
            font-weight: 700;
            /* font-weight: 500; */
            /* because 500 had nothing. */
        }

        /* Navigation Pills */
        & .nav-pills {
            --bs-nav-pills-link-active-bg: ${ theme.config.bootstrap.navPills.navPillsLinkActiveBg };
            --bs-nav-pills-link-active-color: ${ theme.config.bootstrap.navPills.navPillsLinkActiveColor };


            background: ${ theme.config.bootstrap.navPills.background };
            border: 1px solid ${ theme.config.bootstrap.navPills.border };
            border-radius: 1em;
            box-shadow: 0px 2px 8px 0px ${ theme.config.bootstrap.navPills.boxShadow } inset;


            .nav-item {
                padding: 0.2em;
            }

            .nav-link {
                padding-inline: 0.75em;
                padding-block: 0.65em;
                color: ${ theme.config.bootstrap.navPills.navLinkColor };
            }

            .nav-link.active, .nav-pills .show > .nav-link {
                border-radius: 1em;
                font-weight: 700;
                box-shadow: 0px 2px 5px 0px ${ theme.config.bootstrap.navPills.navLinkActiveBoxShadow };
            }
        }

        /* Round Buttons */
        .btn-round {
            --bs-btn-padding-x-round: 0.625rem;
            --bs-btn-padding-y-round: 0.625rem;
            height: 2.5rem;
            width: 2.5rem;
            --bs-btn-padding-y: 0.625rem;
            --bs-btn-padding-x: 0.625rem;
            --bs-btn-font-size: 0.9375rem;
            --bs-btn-border-radius: 35rem;
        }


        .btn-round.btn-lg,
        .btn-group-lg>.btn-round.btn {
            height: 3.125rem;
            width: 3.125rem;
        }

        .btn-round.btn-sm,
        .btn-group-sm>.btn-round.btn {
            height: 1.875rem;
            width: 1.875rem;
            --bs-btn-padding-y: 0.2125rem;
            --bs-btn-padding-x: 0.2125rem;
            --bs-btn-font-size: 0.9375rem;
            --bs-btn-border-radius: 50rem;
        }

        .btn-round.btn-sm-lg {
            height: 3.125rem;
            width: 3.125rem;
        }

        .btn-round.btn-sm-lg i.fa-light {
            font-size: 1.25rem;
        }

        @media (max-width: 575.98px) {
            .btn-round.btn-sm-lg {
                height: 1.875rem;
                width: 1.875rem;
            }

            .btn-round.btn-sm-lg i.fa-light {
                font-size: 0.9375rem;
            }
        }

        .btn-round.btn-sm-md {
            height: 2.5rem;
            width: 2.5rem;
        }

        @media (max-width: 575.98px) {
            .btn-round.btn-sm-md {
                height: 1.875rem;
                width: 1.875rem;
            }
        }

        /* Rounded Buttons */
        .btn-rounded {
            border-radius: 0.9rem;
        }

        /* .btn-white {
            box-shadow: 0px 2px 4px 0px ${ theme.config.bootstrap.btnWhite.boxShadow };
        } */

        /* Card Styles */
        .card {
            box-shadow: 0px 2px 10px 0px ${ theme.config.bootstrap.card.boxShadow };
            border-width: 0;

            .card-text {
                font-size: 1rem;
                line-height: 1.125rem;
                max-height: 3.375rem;
                overflow: hidden;   
                text-overflow: ellipsis;
                padding-right: 1rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            background-color: ${ theme.config.bootstrap.card.backgroundColor };
        }

        @media (min-width: 992px) {
            .card .card-text {
                font-size: 0.875rem;
            }
        }

        /* Form Check Input Styles */
        input[type=checkbox] {
            background-color: ${ theme.config.bootstrap.inputCheckbox.unchecked.backgroundColor };
            border-color: ${ theme.config.bootstrap.inputCheckbox.unchecked.borderColor };
        }

        input[type=checkbox]:checked {
            background-color: ${ theme.config.bootstrap.inputCheckbox.checked.backgroundColor };
            border-color: ${ theme.config.bootstrap.inputCheckbox.checked.borderColor };
            color: ${ theme.config.bootstrap.inputCheckbox.checked.color };
        }

        input[type=radio] {
            display: none;
        }

        .custom-radio {
            position: relative;
            cursor: pointer;
            padding-left: 20px;
            margin-bottom: 15px;
            display: inline-flex;
            line-height: 1.5;
        }

        /* Style the custom checkmark */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px; /* Adjust as needed */
            width: 20px; /* Adjust as needed */
            background-color: ${ theme.config.bootstrap.checkmark.backgroundColor };
            border-radius: 50%;
            border: 2px solid ${ theme.config.bootstrap.checkmark.borderColor };
        }

        .checkmark > i {
            display: none;
            font-size: 0.7em;
            vertical-align: middle;
            margin-top: -3px;
        }

        /* Style the checkmark when the radio is checked */
        input[type="radio"]:checked + .checkmark {
            background-color: ${ theme.config.bootstrap.radioChecked.backgroundColor };
            border-color: ${ theme.config.bootstrap.radioChecked.borderColor };
            color: ${ theme.config.bootstrap.radioChecked.color };

            & > i {
                display: inline-block;
            }
        }

        .custom-radio:hover {
            color: ${ theme.config.bootstrap.labelHover.color };
        }

        /* Gradient Background */
        .questionnaire .img-wrapper .gradient-background,
        .question-radio .img-wrapper .gradient-background,
        .question-checkbox .img-wrapper .gradient-background {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background-image: ${ theme.config.bootstrap.questionnaire.gradientBackground.backgroundColor };
            color: ${ theme.config.bootstrap.questionnaire.gradientBackground.color };
        }

        .questionnaire .img-wrapper .checked-background,
        .question-radio .img-wrapper .checked-background,
        .question-checkbox .img-wrapper .checked-background {
            visibility: hidden;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: ${ theme.config.bootstrap.questionnaire.checkedBackground.backgroundColor };
            opacity: ${ theme.config.bootstrap.questionnaire.checkedBackground.opacity };
        }

        /* Card Styling for Checkbox */
        .question-checkbox .card {
            background-color: transparent;
        }

        /* Checkmark, Plus Sign, Unchecked Styling */
        .question-checkmark,
        .question-plussign,
        .question-unchecked {
            visibility: hidden;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-height: 2.5rem;
            min-width: 2.5rem;
            max-height: 2.5rem;
            max-width: 2.5rem;
            border-radius: 6px;
            background-color: ${ theme.config.bootstrap.questionCheckmark.backgroundColor };
            color: ${ theme.config.bootstrap.questionCheckmark.textColor };
            font-size: 0.8rem;
        }

        .question-unchecked {
            background-color: ${ theme.config.bootstrap.questionCheckmark.uncheckedBackgroundColor };
            border: 2px solid ${ theme.config.bootstrap.questionCheckmark.uncheckedBorderColor };
        }

        .question-plussign,
        .question-unchecked {
            visibility: visible;
            height: 1.5rem;
            width: 1.5rem;
        }

        .question-icon {
            border-radius: 50rem;
            background-color: ${ theme.config.bootstrap.questionIcon.backgroundColor };
            color: ${ theme.config.bootstrap.questionIcon.iconColor };
            font-size: 1.5rem;
        }


        .btn-group-radio .btn-dummy,
        .btn-group-checkbox-list .btn-dummy,
        .btn-group-checkbox-tile .btn-dummy,
        .btn-group-checkbox-set .btn-dummy {
            width: 100%;
            padding: 0.125rem;
            color: ${ theme.config.bootstrap.btnGroup.btnDummy.textColor };
            border: 4px solid transparent;
            background-color: transparent;
            margin-bottom: 0.75rem;
        }

        .btn-group-radio .btn-dummy,
        .btn-group-checkbox-list .btn-dummy,
        .btn-group-checkbox-set .btn-dummy {
            border-radius: 1.375rem;
        }

        .btn-group-checkbox-tile .btn-dummy {
            border-radius: 0.8rem;
        }

        .btn-group-checkbox-tile .btn-dummy .card-header {
            border-bottom-width: 0px;
            padding-inline: 0.5rem;
            background-color: transparent;
        }

        .btn-group-checkbox-tile .btn-dummy .card-footer {
            border-top-width: 0px;
            padding-inline: 0.5rem;
        }

        .btn-group-checkbox-tile .btn-dummy .card-footer .card-text {
            padding: 2px 6px 2px 6px;
            border-radius: 8px;
            background: ${ theme.config.bootstrap.btnGroupCheckbox.cardText.background };
            box-shadow: ${ theme.config.bootstrap.btnGroupCheckbox.cardText.boxShadow };
            font-weight: 800;
            color: ${ theme.config.bootstrap.btnGroupCheckbox.cardText.color };
        }

        .btn-group-checkbox-tile .btn-dummy .question-checkbox .img-wrapper img {
            border-radius: 12px;

        }

        .btn-group-radio .btn-dummy,
        .btn-group-checkbox-list .btn-dummy,
        .btn-group-checkbox-tile .btn-dummy,
        .btn-group-checkbox-set .btn-dummy {
            width: 100%;
            padding: 0.125rem;
            border-radius: 1.375rem;
            color: ${ theme.config.bootstrap.btnGroup.btnDummy.textColor };
            border: 4px solid transparent;
            background-color: transparent;
            margin-bottom: 0.75rem;
        }

        .btn-group-radio .btn-check:checked+.btn.btn-dummy,
        .btn-group-checkbox-list .btn-check:checked+.btn.btn-dummy,
        .btn-group-checkbox-set .btn-check:checked+.btn.btn-dummy {
            border-color: ${ theme.config.bootstrap.btnGroup.btnDummy.borderColor };
        }

        .btn-group-checkbox-tile .btn-check:checked+.btn.btn-dummy {
            border-color: transparent;
        }

        .btn-group-radio .btn-check:checked+.btn.btn-dummy .question-checkmark,
        .btn-group-checkbox-list .btn-check:checked+.btn.btn-dummy .question-checkmark,
        .btn-group-checkbox-tile .btn-check:checked+.btn.btn-dummy .question-checkmark,
        .btn-group-checkbox-set .btn-check:checked+.btn.btn-dummy .question-checkmark {
            visibility: visible;
        }

        .btn-group-checkbox-tile .btn-check:checked+.btn.btn-dummy .question-checkmark {
            min-height: 1.875rem;
            min-width: 1.875rem;
            max-height: 1.875rem;
            max-width: 1.875rem;
        }

        .btn-group-radio .btn-check:checked+.btn.btn-dummy .question-unchecked,
        .btn-group-radio .btn-check:checked+.btn.btn-dummy .question-plussign,
        .btn-group-checkbox-list .btn-check:checked+.btn.btn-dummy .question-unchecked,
        .btn-group-checkbox-list .btn-check:checked+.btn.btn-dummy .question-plussign,
        .btn-group-checkbox-tile .btn-check:checked+.btn.btn-dummy .question-unchecked,
        .btn-group-checkbox-tile .btn-check:checked+.btn.btn-dummy .question-plussign,
        .btn-group-checkbox-set .btn-check:checked+.btn.btn-dummy .question-unchecked,
        .btn-group-checkbox-set .btn-check:checked+.btn.btn-dummy .question-plussign {
            display: none;
        }

        .btn-group-radio .btn-check:checked+.btn.btn-dummy .checked-background,
        .btn-group-checkbox-list .btn-check:checked+.btn.btn-dummy .checked-background,
        .btn-group-checkbox-tile .btn-check:checked+.btn.btn-dummy .checked-background,
        .btn-group-checkbox-set .btn-check:checked+.btn.btn-dummy .checked-background {
            visibility: visible;
        }

        .btn-group-checkbox-tile .btn-check:checked+.btn.btn-dummy .checked-background {
            border-radius: 12px;
        }

        .btn-group-radio .btn-check:disabled+.btn.btn-dummy .card,
        .btn-group-radio .btn-check.disabled+.btn.btn-dummy .card,
        .btn-group-checkbox-list .btn-check:disabled+.btn.btn-dummy .card,
        .btn-group-checkbox-list .btn-check.disabled+.btn.btn-dummy .card,
        .btn-group-checkbox-tile .btn-check:disabled+.btn.btn-dummy .card,
        .btn-group-checkbox-tile .btn-check.disabled+.btn.btn-dummy .card,
        .btn-group-checkbox-set .btn-check:disabled+.btn.btn-dummy .card,
        .btn-group-checkbox-set .btn-check.disabled+.btn.btn-dummy .card {
            opacity: 0.4;
        }

        .btn-group-radio .btn-check:disabled+.btn.btn-dummy .card-text,
        .btn-group-radio .btn-check.disabled+.btn.btn-dummy .card-text,
        .btn-group-checkbox-list .btn-check:disabled+.btn.btn-dummy .card-text,
        .btn-group-checkbox-list .btn-check.disabled+.btn.btn-dummy .card-text,
        .btn-group-checkbox-tile .btn-check:disabled+.btn.btn-dummy .card-text,
        .btn-group-checkbox-tile .btn-check.disabled+.btn.btn-dummy .card-text,
        .btn-group-checkbox-set .btn-check:disabled+.btn.btn-dummy .card-text,
        .btn-group-checkbox-set .btn-check.disabled+.btn.btn-dummy .card-text {
            opacity: 0.4;
        }

        /** added inset box shadow on checked */
        .btn-group-radio .btn-check:checked+.btn.btn-dummy .card,
        .btn-group-radio .btn-check.checked+.btn.btn-dummy .card,
        .btn-group-checkbox-list .btn-check:checked+.btn.btn-dummy .card,
        .btn-group-checkbox-list .btn-check.checked+.btn.btn-dummy .card,
        .btn-group-checkbox-tile .btn-check:checked+.btn.btn-dummy .card,
        .btn-group-checkbox-tile .btn-check.checked+.btn.btn-dummy .card,
        .btn-group-checkbox-set .btn-check:checked+.btn.btn-dummy .card,
        .btn-group-checkbox-set .btn-check.checked+.btn.btn-dummy .card {
            box-shadow: 4px 4px 10px 0px ${ theme.config.bootstrap.btnGroup.btnDummy.shadowColor } inset;
        }

        .btn-group-radio .btn-check:disabled+.btn.btn-dummy .question-icon,
        .btn-group-radio .btn-check.disabled+.btn.btn-dummy .question-icon,
        .btn-group-checkbox-list .btn-check:disabled+.btn.btn-dummy .question-icon,
        .btn-group-checkbox-list .btn-check.disabled+.btn.btn-dummy .question-icon,
        .btn-group-checkbox-tile .btn-check:disabled+.btn.btn-dummy .question-icon,
        .btn-group-checkbox-tile .btn-check.disabled+.btn.btn-dummy .question-icon,
        .btn-group-checkbox-set .btn-check:disabled+.btn.btn-dummy .question-icon,
        .btn-group-checkbox-set .btn-check.disabled+.btn.btn-dummy .question-icon {
            opacity: 0.4;
        }

        .btn-group-checkbox-list .btn-dummy,
        .btn-group-checkbox-set .btn-dummy {
            border: none;
            border-radius: 0.625rem;
            margin-bottom: 0.25rem;
        }

        .btn-group-checkbox-list .btn-check:checked+.btn.btn-dummy,
        .btn-group-checkbox-set .btn-check:checked+.btn.btn-dummy {
            background-color: ${
                (() => {
                    const color = Color(theme.config.bootstrap.btnGroup.btnDummy.backgroundColor)

                    return 'rgba(' + [color.rgb().array()[0],
                        color.rgb().array()[1],
                        color.rgb().array()[2],
                        '0.2'
                    ].join(', ') + ')'
                })()
            };
        }

        .btn-group-checkbox-list .question-checkbox .card,
        .btn-group-checkbox-set .question-checkbox .card {
            color: ${ theme.config.bootstrap.card.color };
            border-radius: 0.625rem;
            box-shadow: 0px 2px 10px 0px ${ theme.config.bootstrap.card.boxShadow };
        }

        .btn-group-checkbox-tile .question-checkbox .card {
            border-radius: 12px;
        }

        .btn-group-checkbox-list .question-checkbox .card .card-body,
        .btn-group-checkbox-set .question-checkbox .card .card-body {
            padding-top: 0.625rem;
            padding-right: 0.25rem;
            padding-bottom: 0.625rem;
            padding-left: 0.25rem;

            & .set-value {
                color: ${ theme.config.bootstrap.questionCheckbox.card.setValue.color };
            }
        }

        .btn-group-checkbox-list .question-checkmark,
        .btn-group-checkbox-set .question-checkmark {
            background-color: ${ theme.config.bootstrap.questionCheckmark.backgroundColor };
            color: ${ theme.config.bootstrap.questionCheckmark.textColor };
        }


        .btn-group-checkbox-set .btn-dummy {
            margin-bottom: 0.5rem;
        }

        .btn-group-checkbox-set .btn-dummy .question-checkmark {
            display: none;
        }

        .btn-group-checkbox-set .btn-check:checked+.btn.btn-dummy .question-checkmark {
            display: inline-flex;
        }

        .btn-group-checkbox-set .question-checkbox {
        }

        .btn-group-checkbox-set .question-checkbox .card {
            padding-top: 0.35rem;
            padding-bottom: 0.35rem;
        }

        .btn-group-checkbox-set .question-checkmark,
        .btn-group-checkbox-set .question-unchecked {
        }

        .progress-bar {
            -webkit-transition: var(--bs-progress-bar-transition);
            transition: var(--bs-progress-bar-transition);
        }

        .transition-none {
            -webkit-transition: none;
            transition: none;
        }

        @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
            //for smaller screens.
            .modal-dialog {
                margin: 0;
            }

            .modal-body {
                & .form-group {
                    & div.form-control-wrapper {
                        & .form-control.form-search {  
                            padding: 0.55rem 1rem 0.55em 2.75rem;
                        }

                        & .form-floaticon {
                            top: calc(calc(1.3125em + 2.125rem) / 2.3);
                        }
                    }
                }
            }
        }





        .modal-footer {
            border-radius: 16px 16px 0px 0px;
        }


        .modal-footer {
            border-radius: 0px 0px 16px 16px;
        }


        .modal-footer > * {
            margin: 0;
        }

        .text-truncate-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        }



    `

    return CSS_CONTENT
}

export default GlobalStyle
