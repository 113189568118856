import { useEffect, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { MODULE_VERSION, PATIENT_EDIT_VALIDATION_SCHEMA } from '@doc/constants'
import { PatientParams, PatientValues } from '@doc/type'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { FormikProps, useFormik } from 'formik'
import { toast } from 'react-toastify'

import { selectRouter } from '@app/app.store'
import { TokenData } from '@app/types/type.token'
import {
    useEditPatientMutation,
    useGetPatientActivityMutation,
    useGetPatientAdvisoryMutation,
    useGetPatientChartsMutation,
    useGetPatientDeviationsMutation,
    useGetPatientMutation,
    useResendInviteMutation,
    useUpdateProfilePicturePatientMutation
} from '@doc/api'
import DashboardResponsiveHeader from '@fmt/components/DashboardResponsiveHeader'
import { back } from '@lagunovsky/redux-react-router'

import IkherstelRecords from '@doc/components/patients/details/ikherstelRecords/Main'
import PatientNavigation from '@doc/components/patients/details/navigation/PatientNavigation'
import PatientRecords from '@doc/components/patients/details/navigation/PatientRecords'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

const PatientDetailsInterface = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const router = useAppSelector(selectRouter)
    // upload profile picture logic.
    const [file, setFile] = useState<File | null>(null)
    const [getPatient, getPatientMutation] = useGetPatientMutation()
    const [getPatientActivity, getPatientActivityMutation] = useGetPatientActivityMutation()
    const [getPatientCharts, getPatientChartsMutation] = useGetPatientChartsMutation()
    const [getPatientAdvisory, getPatientAdvisoryMutation] = useGetPatientAdvisoryMutation()
    const [getPatientDeviations, getPatientDeviationsMutation] = useGetPatientDeviationsMutation()
    const [resendInvite, resendInviteMutation] = useResendInviteMutation()
    const [showRecentlyUploaded, setShowRecentlyUploaded] = useState<boolean>(false)
    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false)
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const { userId } = useParams<PatientParams>()

    const [editPatient, editPatientMutation] = useEditPatientMutation()

    const [mode, setMode] = useState<'EDIT' | 'VIEW'>(
        _.lowerCase(router.location.pathname).includes('edit') ? 'EDIT' : 'VIEW'
    )

    // a fix that does the ff things:
    // before this existed, the getPatients gets called twice from two separate useEffects
    // the first hook should only be done once you clicked on either buttons that
    // changes the url. ideally, a quick solution. should be set to FALSE.
    const [modeButtonClicked, setModeButtonClicked] = useState(false)

    const [activeTab, setActiveTab] = useState<{
        name: string | undefined;
        link: string;
    } | undefined>()

    const patientData = getPatientMutation.data
        ?.data.patientPersonalData.patientData[0]?.userData[0] || undefined

    const patientActivityData = getPatientActivityMutation.data
        ?.data.activityData

    const patientChartData = getPatientChartsMutation.data
        ?.data

    const patientAdvisoryData = getPatientAdvisoryMutation.data
        ?.data

    const patientDeviationsData = getPatientDeviationsMutation.data
        ?.data

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    type PatientFormikProps = Pick<PatientValues,
    'firstName' | 'lastName' | 'email' | 'patientNumber'>
    & {active: boolean}

    const patientEditFormik: FormikProps<PatientFormikProps> = useFormik({
        initialValues: {
            firstName: patientData?.firstName || '',
            lastName: patientData?.lastName || '',
            email: patientData?.email || '',
            patientNumber: patientData?.patientNumber || '',
            active: patientData?.active || false
        } || {
            firstName: '',
            lastName: '',
            email: '',
            patientNumber: '',
            active: false
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PATIENT_EDIT_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.editPatient.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        editPatient({
                            authToken: newToken.value,
                            data: {
                                userId: userId || '',
                                firstName: values.firstName || '',
                                lastName: values.lastName || '',
                                email: values.email || '',
                                patientNumber: values.patientNumber || '',
                                active: values.active || false
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    const resendInviteToUser = async () => {
        if (token.valid) {
            const newToken = await revalidateToken(
                {
                    value: token.value,
                    id: token.id
                },
                token.mode
            )
            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.doc.moduleName,
                MODULE_TABLE.doc.apiPaths.resendInvite.path,
                true
            )

            if (foundApiPath && newToken.value) {
                resendInvite({
                    authToken: newToken.value,
                    data: {
                        userId: userId || ''
                    }
                })
            }
        }
    }

    useEffect(() => {
        const data = resendInviteMutation.data

        if (data) {
            if (data.status === 'OK') {
                // close component by clearing the edit formik.
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [resendInviteMutation.data])

    /** create fetch data function */
    const fetchPatientDetailsData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                userId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientId.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getPatient({
                            authToken: newToken.value,
                            data: {
                                userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    const fetchIkherstelRecordsData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                userId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientActivity.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getPatientActivity({
                            authToken: newToken.value,
                            data: {
                                userId
                            }
                        })
                    }

                    const isValid3 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientCharts.path,
                        true
                    )

                    if (isValid3 && newToken.value) {
                        getPatientCharts({
                            authToken: newToken.value,
                            data: {
                                userId
                            }
                        })
                    }

                    const isValid4 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientAdvisory.path,
                        true
                    )

                    if (isValid4 && newToken.value) {
                        getPatientAdvisory({
                            authToken: newToken.value,
                            data: {
                                userId
                            }
                        })
                    }

                    const isValid5 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientDeviations.path,
                        true
                    )

                    if (isValid5 && newToken.value) {
                        getPatientDeviations({
                            authToken: newToken.value,
                            data: {
                                userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // THIS FETCH SHOULD NOT HAPPEN ON MOUNT.
    // solution change dependency to a useState. a button where you clicked on it
    // and then unset it.
    useEffect(() => {
        // should only be performed IF the button is clicked.
        if (modeButtonClicked) {
            setMode(
                _.lowerCase(router.location.pathname).includes('edit') ? 'EDIT' : 'VIEW'
            )

            // you go here ideally after submitting the values from the edit mode.
            if (_.lowerCase(router.location.pathname).includes('view')) {
                patientEditFormik.resetForm()
                // and then fetch the data again just to double check.
                // you only want to do this IF you came from clicking the button.
                fetchPatientDetailsData(token)
            }

            // don't forget to set it false after.
            setModeButtonClicked(false)
        }
    }, [router.location.pathname, modeButtonClicked])

    // ONLY fetch it when obj.needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        if (userId) {
            setShowRecentlyUploaded(false)
            return fetchPatientDetailsData(token)
        } else {
            return () => {}
        }
    }, [token.id, token.valid, userId])

    useEffect(() => {
        if (userId) {
            setShowRecentlyUploaded(false)
            return fetchIkherstelRecordsData(token)
        } else {
            return () => {}
        }
    }, [token.id, token.valid, userId])

    useEffect(() => {
        if (getPatientMutation.data) {
            const data = getPatientMutation.data
            patientEditFormik.setValues({
                firstName: data.data.patientPersonalData.patientData[0]?.userData[0]
                    ?.firstName || '',
                lastName: data.data.patientPersonalData.patientData[0]?.userData[0]?.lastName || '',
                email: data.data.patientPersonalData.patientData[0]?.userData[0]?.email || '',
                patientNumber: data.data.patientPersonalData.patientData[0]
                    ?.userData[0]?.patientNumber || '',
                active: data.data.patientPersonalData.patientData[0]
                    ?.userData[0]?.active || false
            })
        }
    }, [getPatientMutation.data])

    useEffect(() => {
        if (getPatientMutation.error) {
            const message = getErrorText(getPatientMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientMutation.error])

    useEffect(() => {
        if (getPatientActivityMutation.error) {
            const message = getErrorText(getPatientActivityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientActivityMutation.error])

    useEffect(() => {
        if (getPatientChartsMutation.error) {
            const message = getErrorText(getPatientChartsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientChartsMutation.error])

    useEffect(() => {
        if (getPatientAdvisoryMutation.error) {
            const message = getErrorText(getPatientAdvisoryMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientAdvisoryMutation.error])

    useEffect(() => {
        if (getPatientDeviationsMutation.error) {
            const message = getErrorText(getPatientDeviationsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientDeviationsMutation.error])

    useEffect(() => {
        const data = editPatientMutation.data

        if (data) {
            if (data.status === 'OK') {
                // close component by clearing the edit formik.
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // patientEditFormik.resetForm()
                fetchPatientDetailsData(token)
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editPatientMutation.data])

    useEffect(() => {
        if (editPatientMutation.error) {
            const message = getErrorText(editPatientMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editPatientMutation.error])

    const [updateProfilePicturePatient, updateProfilePicturePatientMutation] =
    useUpdateProfilePicturePatientMutation()
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleUpload = async () => {
        if (file) {
            const formData = new FormData()
            formData.append('profilePicture', file)

            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            const isValid = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.doc.moduleName,
                MODULE_TABLE.doc.apiPaths
                    .updateProfilePicturePatient.path,
                true
            )

            if (isValid) {
                updateProfilePicturePatient({
                    userId: userId || '',
                    data: formData,
                    authToken: newToken.value
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        // toast success message.
                        toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        // we need to use this token now so set it.
                        setShowRecentlyUploaded(true)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        }
    }

    useEffect(() => {
        handleUpload()
    }, [file])

    useEffect(() => {
        if (updateProfilePicturePatientMutation.error) {
            const message = getErrorText(updateProfilePicturePatientMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updateProfilePicturePatientMutation.error])
    const [floatingUiBoundary, setBoundary] = useState<HTMLDivElement | null>(null)

    const navigationContent = <div className={'container-fluid navigation-content pt-12 px-16'} >
        <div className={'h-100 row'}>
            <div className={' col-12 mx-auto'}>
                {
                    activeTab?.link === MODULE_TABLE.doc.routes.editPatient &&
                     <PatientRecords
                         resendInviteToUser={resendInviteToUser}
                         mode={mode}
                         patientEditFormik={patientEditFormik}
                         showCancelWarning={showCancelWarning}
                         setShowCancelWarning={setShowCancelWarning}
                         userId={userId}
                         editPatientMutation={editPatientMutation}
                         resendInviteMutation={resendInviteMutation}
                         updateProfilePicturePatientMutation={
                             {
                                 data: updateProfilePicturePatientMutation
                                     .data
                             }}
                         showRecentlyUploaded={showRecentlyUploaded}
                         getPatientMutation={{
                             data: getPatientMutation.data
                         }}
                         fileInputRef={fileInputRef}
                         setFile={setFile}
                         modeButtonClicked={modeButtonClicked}
                         setMode={setMode}
                         setModeButtonClicked={setModeButtonClicked}
                     />
                }
                {
                    activeTab?.link === MODULE_TABLE.doc.routes.viewPatient &&
                    <IkherstelRecords
                        floatingUiBoundary={floatingUiBoundary}
                        getPatientActivityMutation={{
                            isLoading: getPatientActivityMutation.isLoading,
                            isSuccess: getPatientActivityMutation.isSuccess
                        }}
                        getPatientDeviationsMutation={{
                            status: getPatientDeviationsMutation.data?.status || 'NOT_OK',
                            message: getPatientDeviationsMutation.data?.message || '',
                            isLoading: getPatientDeviationsMutation.isLoading,
                            isSuccess: getPatientDeviationsMutation.isSuccess
                        }}
                        patientActivityData={patientActivityData || []}
                        patientChartData={patientChartData || []}
                        patientAdvisoryData={patientAdvisoryData}
                        patientDeviationsData={patientDeviationsData}
                    />
                }
            </div>
        </div>
    </div>

    return <div className={'patient-page'}>

        {/* dashboard responsive header */}
        <DashboardResponsiveHeader />

        <div className={'main-content'} ref={(e) => {
            setBoundary(e)
        }}>

            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-between pt-16 pb-0 px-5'}>
                    <div className={'col-auto'}>
                        <a onClick={(e) => {
                            e.preventDefault()
                            dispatch(back())
                        }} className={'btn btn-round btn-sm-md'}>
                            <i
                                className={'fa-light fa-arrow-left'}
                                aria-hidden={'true'}>
                            </i>
                        </a>
                    </div>
                    <div className={'col-auto'}>
                        <h1 className={'my-0'}>{
                            [patientData?.firstName, patientData?.lastName]
                                .join(' ')
                        }</h1>
                    </div>
                    <div className={'col-auto'}>
                        <a href={'#'}
                            onClick={(e) => {
                                e.preventDefault()
                            }} className={[
                                'btn btn-round btn-sm-md invisible'
                            ].join(' ')}>
                            <i
                                className={'fa-light fa-arrow-right'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </a>
                    </div>
                </div>
                <div className={'row justify-content-between mt-5'}>
                    <div className={'col'}>
                        <div className={
                            'container-fluid px-5 px-sm-3 patient-body'
                        }>
                            <div className={'h-100 row'}>
                                <div className={' col-12 mx-auto'}>
                                    {<PatientNavigation
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />}

                                    {navigationContent}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </div>
}

export default PatientDetailsInterface
