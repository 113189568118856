import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import {
    selectCurrentWorkflowStep,
    selectWorkflowCount,
    selectWorkflowId
} from '@login/slices/slice.workflow'
import UpdateWorkflowProgressConsumer from '@login/workflow/UpdateWorkflowConsumer'
import { useUpdateWorkflowProgressMutation } from '@registration/api'
import { MODULE_VERSION } from '@registration/constants'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/registration/Components'
import { useEffect, useMemo } from 'react'

import { MODULE_TABLE } from '@app/app.config'
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import FixedImage from '@app/components/FixedImage'
import { useMediaQuery } from 'react-responsive'

function SuccessPage () {
    const strings = useAppSelector(selectStrings)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const workflowCount = useAppSelector(selectWorkflowCount)
    const token = useAppSelector(selectToken)
    const workflowId = useAppSelector(selectWorkflowId)
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const [
        updateWorkflowProgress,
        updateWorkflowProgressMutation
    ] = useUpdateWorkflowProgressMutation()

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const SubmitButton = useMemo(() => {
        const buttonContent = updateWorkflowProgressMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
        // if it's not the last step, use "next", else "complete"
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            currentWorkflowStep && currentWorkflowStep.stepSequence < workflowCount
                                ? strings.app?.text.next
                                : strings.app?.text.complete
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        return (<button
            onClick={async () => {
                const call = async () => {
                    if (currentWorkflowStep) {
                        if (token.valid) {
                            const newToken = await revalidateToken({
                                value: token.value,
                                id: token.id
                            }, token.mode)
                            const percentComplete = 100
                            const isValid = validateAPIPath(
                                activeModules.arr,
                                MODULE_TABLE.registration.moduleName,
                                MODULE_TABLE.registration.apiPaths.updateWorkflowProgress.path,
                                true
                            )

                            if (isValid && newToken.value) {
                                updateWorkflowProgress({
                                    authToken: newToken.value,
                                    data: {
                                        stepId: currentWorkflowStep.stepId,
                                        workflowId,
                                        percentComplete
                                    }
                                })
                            }
                        }
                    }
                }
                call()
            }}
            disabled={updateWorkflowProgressMutation.isLoading}
            className={'btn btn-primary btn-lg w-100'}>
            {buttonContent}
        </button>)
    }, [strings,
        currentWorkflowStep, workflowCount, updateWorkflowProgressMutation])

    const stepSequenceCells = useMemo(() => {
        const items = []

        for (let i = 0; i < workflowCount; i++) {
            items.push(
                <li key={i} className={'page-item'}>
                    <a className={`page-link clickable ${
                        i === ((currentWorkflowStep?.stepSequence || 0) - 1) ? 'active' : ''
                    }`} onClick={(e) => {
                        e.preventDefault()
                    }}></a>
                </li>
            )
        }

        return (
            <ul className={'pagination justify-content-center'}>
                {items}
            </ul>
        )
    }, [currentWorkflowStep, workflowCount])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        {/* put header image here */}
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-20'}>

                <div className={'justify-content-center row'}>
                    <div className={'col-10 px-0'}>
                        {/* status card */}
                        <div className={'status-card card border-0'}>
                            <h4>
                                <p className={'mt-2'}>
                                    {strings.registration?.message.great}
                                </p>
                            </h4>
                            <span>
                                {strings.registration?.message.data_changed}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center mb-5'}>
                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                        {SubmitButton}
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center'}>
                    <div className={'col-auto'}>
                        {stepSequenceCells}
                    </div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>
            <div className={'justify-content-center row'}>
                <div className={'col-12'}>
                    {/* status card */}
                    <div className={'status-card card border-0'}>
                        <span className={'fw-semibold mb-2'}>{
                            strings.registration?.message.great
                        }</span>
                        <span>
                            {strings.registration?.message.data_changed}
                        </span>
                    </div>
                </div>
            </div>

            <div className={'form-container'}>
                <div ></div>
                <div >

                    <div className={'row align-items-center justify-content-center mb-5'}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                            {SubmitButton}
                        </div>
                    </div>
                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-auto'}>
                            {stepSequenceCells}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    return (<div>
        <div className={'registration-page'}>

            <UpdateWorkflowProgressConsumer
                data={updateWorkflowProgressMutation.data}
                originalArgs={updateWorkflowProgressMutation.originalArgs}
            />

            {
                isMobile ? mobileResult : desktopResult
            }

        </div>

    </div>
    )
}

export default SuccessPage
