/* eslint-disable max-len */
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { CSS_QUESTION_TYPE } from '@stylesheet/globalStyles/group/endUser/reasonWithMe/globalStyles'
import { css, useTheme } from '@emotion/react'

export const CAREPLAN_STEP_CONTENT = () => {
    const theme = useTheme()

    return css`
    /* make everything smaller in all texts */
    font-size: 88.5%;

    background: ${ theme.config.endUser?.careplanStep.mainContent.background };
    border-radius: 12px;
    padding-block: 45px;
    margin-inline: 10vw;

    @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
        margin-block: 45px;
        padding-inline: 30px;
        padding-block-start: 15px;
        padding-block-end: 140px;
    }

    @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
        padding-inline: 100px;
    }

    .question-checkmark,
    .question-plussign,
    .question-unchecked {
        min-height: 1.5rem;
        min-width: 1.5rem;
        max-height: 1.5rem;
        max-width: 1.5rem;
    }

    .meta-info {
        small.content-type{
            &.activity {
                color: ${ theme.config.endUser?.careplanStep.mainContent.metaInfo.activityColor };
            }
            &.content {
                color: ${ theme.config.endUser?.careplanStep.mainContent.metaInfo.contentColor };
            }
            &.lesson {
                color: ${ theme.config.endUser?.careplanStep.mainContent.metaInfo.lessonColor };
            }
            &.reasoning {
                color: ${ theme.config.endUser?.careplanStep.mainContent.metaInfo.reasoningColor };
            }
        }
    }



    .questionnaire {
        .card {
            color: ${ theme.config.endUser?.careplanStep.mainContent.questionnaire.card.color };
            background-color: ${ theme.config.endUser?.careplanStep.mainContent.questionnaire.card.backgroundColor };

            .card-body {
                padding-top: 1.5rem;
                padding-right: 2rem;
                padding-bottom: 1.125rem;
                padding-left: 2rem;
            }
        }
    }

    .media-wrapper {
        position: relative;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

        & > .react-player {
            position: absolute;
            top: 0;
            left: 0;

            & video:first-of-type, & iframe:first-of-type {
                border-radius: 1.4em 1.4em 0 0;
            }
        }

        &.audio{
            border-radius: 1em;
        }

        &.video{
            border-radius:0 0 1.4em 1.4em;
        }

        /** used when controls is set to true. now will be used for player */
        & > .player {

            /** for icons */
            & .play-button i{
                color: ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.playButtonColor };
            background: ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.playButtonBackground };
                border: 1px solid transparent;
                border-radius: 8px;
                width: 40px;
                height: 40px;
                text-align: center;
                cursor: pointer;
                line-height: 40px;
            }

            & .volume-button  i{

                font-size: 1em;
                padding: 1em;
                cursor: pointer;
            }

            & .fullscreen-button i{

                border: 1px solid transparent;
                border-radius: 8px;
                width: 40px;
                height: 40px;
                text-align: center;
                cursor: pointer;
                line-height: 40px;
                box-shadow: ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.fullscreenButtonBoxShadow };

            }

            width: 100%;
            padding-block: 0.5em;
            padding-inline: 2em;
            border: 0.1em solid transparent;

            //make sure padding-inline is zero.
            input[type='range'] {
                -webkit-appearance: none; /* Override default CSS styles */
                appearance: none;
                width: 100%;

                &.volume-control {
                    @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
                        width: 65px;
                    }
                    @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
                        width: 150px;
                    }
                }

                padding-block: 0em;
                padding-inline: 0em;
                height: 0.3em;
                margin-block: 1em;
                //bar color
                background: ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeBarColor };
                //progress color
                background-image: linear-gradient(
                    180deg,
                    ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeProgressColor } 9.04%,
                    ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeProgressColor } 91.12%
                );
                background-repeat: no-repeat;
                outline: none;
                border-radius: 10em;
                border-width: 0px;
                background-size: 0% 100%;
                cursor: pointer;
            }

            input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0.8em;
                height: 0.8em;
                border-radius: 50%;
                background: linear-gradient(
                    180deg,
                    ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 9.04%,
                    ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 91.12%
                );
                cursor: pointer;
            }

            input[type=range]::-webkit-slider-runnable-track  {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }

            /* Moz */

        input[type='range']::-moz-range-thumb {
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                background: linear-gradient(
                    180deg,
                    ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 9.04%,
                    ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 91.12%
                );

                cursor: pointer;
            }

            input[type='range']::-moz-range-track {
                background: ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeTrackColor };
                border-radius: 50%;
                height: 1em;
            }

            /* IE */

            input[type='range']::-ms-thumb {
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                background: linear-gradient(
                    180deg,
                    ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 9.04%,
                    ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 91.12%
                );
                cursor: pointer;
            }

            input[type='range']::-ms-track {
                background: ${ theme.config.endUser?.careplanStep.mainContent.mediaWrapper.player.rangeTrackColor };
                border-radius: 50%;
                height: 1em;
            }


            & div.icon {
                cursor: pointer;
                font-size: 0.8em;

                & i {
                    padding: 0.1em;
                    box-sizing: content-box;
                    border-radius: 1em;
                }

                &.active i {
                    outline: 2px solid black;
                }
            }
        }
    }
`
}

const GlobalStyle = () => {
    return css`

        .careplan-step-page {

            .main-content {

                /* for both screen szies */
                padding-block: 200px;

                ${ CSS_QUESTION_TYPE() }

            }

            .content-container{
                ${ CAREPLAN_STEP_CONTENT() }

            }
            
            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    .card-grid {
                        margin-bottom: 5rem;
                    }

                }
            }

            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                & .header {
                    height: 58px;

                    & img {
                        width: 98px;
                        margin-block-start: -5px;
                    }
                }
                
                & .main-content {
                    width: 100%;

                    & .content-container {
                        margin-inline: 0vw;
                    }

                    padding-top: 113px;
                    position: absolute;
                    //100% minus the height of the previous divs. 100% means height of viewport
                    //in this case
                                    /* height: calc(100% - 113px - 58px); */
                    height: calc(100% - 113px);


                }


            }
        
        }


    `
}

export default GlobalStyle
