import { FavIcon } from '@stylesheet/config/ThemeConfig'

export const APP_ICON_AND_TITLE: {
    END_USER: {
        APP_NAME: string,
        FAVICON: FavIcon,
    },
    ADMIN: {
        APP_NAME: string,
        FAVICON: FavIcon,
    }
} = {
    END_USER: {
        APP_NAME: 'ikHerstel',
        FAVICON: {
            16: '/favicons/ikh/endUser/16/favicon.png',
            24: '/favicons/ikh/endUser/24/favicon.png',
            32: '/favicons/ikh/endUser/32/favicon.png',
            48: '/favicons/ikh/endUser/48/favicon.png',
            57: '/favicons/ikh/endUser/57/favicon.png',
            64: '/favicons/ikh/endUser/64/favicon.png',
            72: '/favicons/ikh/endUser/72/favicon.png',
            76: '/favicons/ikh/endUser/76/favicon.png',
            114: '/favicons/ikh/endUser/114/favicon.png',
            120: '/favicons/ikh/endUser/120/favicon.png',
            144: '/favicons/ikh/endUser/144/favicon.png',
            152: '/favicons/ikh/endUser/152/favicon.png',
            180: '/favicons/ikh/endUser/180/favicon.png',
            192: '/favicons/ikh/endUser/192/favicon.png',
            512: '/favicons/ikh/endUser/512/favicon.png'
        }
    },
    ADMIN: {
        APP_NAME: 'Amino',
        FAVICON: {
            16: '/favicons/ikh/admin/16/favicon.png',
            24: '/favicons/ikh/admin/24/favicon.png',
            32: '/favicons/ikh/admin/32/favicon.png',
            48: '/favicons/ikh/admin/48/favicon.png',
            57: '/favicons/ikh/admin/57/favicon.png',
            64: '/favicons/ikh/admin/64/favicon.png',
            72: '/favicons/ikh/admin/72/favicon.png',
            76: '/favicons/ikh/admin/76/favicon.png',
            114: '/favicons/ikh/admin/114/favicon.png',
            120: '/favicons/ikh/admin/120/favicon.png',
            144: '/favicons/ikh/admin/144/favicon.png',
            152: '/favicons/ikh/admin/152/favicon.png',
            180: '/favicons/ikh/admin/180/favicon.png',
            192: '/favicons/ikh/admin/192/favicon.png',
            512: '/favicons/ikh/admin/512/favicon.png'
        }
    }
}
