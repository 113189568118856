import { BOOTSTRAP_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/light/BootstrapConfig'
import {
    CAREPLAN_STEP_CONFIG
} from '@stylesheet/brands/ikh/enduser/themes/light/CareplanStepConfig'
import { GLOBAL_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/light/GlobalConfig'
import { LOGIN_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/light/LoginConfig'
import { PROFILE_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/light/ProfileConfig'
import {
    REGISTRATION_CONFIG
} from '@stylesheet/brands/ikh/enduser/themes/light/RegistrationConfig'
import {
    RECOVERY_PLAN_CONFIG
} from '@stylesheet/brands/ikh/enduser/themes/light/RecoveryPlanConfig'
import { ThemeConfig } from '@stylesheet/config/ThemeConfig'
import {
    REASON_WITH_ME_CONFIG
} from '@stylesheet/brands/ikh/enduser/themes/light/ReasonWithMeConfig'

export const THEME_CONFIG: ThemeConfig = {
    global: GLOBAL_CONFIG,
    bootstrap: BOOTSTRAP_CONFIG,
    endUser: {
        careplanStep: CAREPLAN_STEP_CONFIG,
        login: LOGIN_CONFIG,
        profile: PROFILE_CONFIG,
        registration: REGISTRATION_CONFIG,
        recoveryPlan: RECOVERY_PLAN_CONFIG,
        reasonWithMe: REASON_WITH_ME_CONFIG
    }
}
