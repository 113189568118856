import { ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { BootstrapConfig } from '@stylesheet/config/Bootstrap'

export const BOOTSTRAP_CONFIG: BootstrapConfig = {
    linkColor: ADMIN_COLORS.yellow,
    linkHoverColor: ADMIN_COLORS.yellow3,
    bodyColor: ADMIN_COLORS.black,
    borderColor: ADMIN_COLORS.white,
    offcanvas: {
        color: ADMIN_COLORS.white,
        bg: ADMIN_COLORS.gray_900
    },
    shadow: {
        boxShadow: ADMIN_COLORS.shadowColor
    },
    formControl: {
        backgroundColor: ADMIN_COLORS.gray_900,
        color: ADMIN_COLORS.white,
        focus: {
            backgroundColor: ADMIN_COLORS.gray_900,
            color: ADMIN_COLORS.white
        },
        disabled: {
            backgroundColor: ADMIN_COLORS.gray_800,
            color: ADMIN_COLORS.gray_900
        }
    },
    buttons: {
        bg: ADMIN_COLORS.gray_700,
        color: ADMIN_COLORS.white,
        hoverColor: ADMIN_COLORS.black,
        hoverBg: ADMIN_COLORS.white
    },
    btnWhite: {
        color: ADMIN_COLORS.white,
        bg: ADMIN_COLORS.gray_900,
        borderColor: ADMIN_COLORS.gray_900,
        hoverColor: ADMIN_COLORS.gray_900,
        hoverBg: ADMIN_COLORS.gray_900,
        hoverBorderColor: ADMIN_COLORS.gray_900,
        focusShadowRGB: '217, 226, 234',
        activeColor: ADMIN_COLORS.dark,
        activeBg: ADMIN_COLORS.gray_900,
        activeBorderColor: ADMIN_COLORS.gray_900,
        activeShadow: `inset 0 3px 5px ${ ADMIN_COLORS.shadowColor }`,
        disabledColor: ADMIN_COLORS.gray_400,
        disabledBg: ADMIN_COLORS.gray_50,
        disabledBorderColor: ADMIN_COLORS.gray_50,
        boxShadow: ADMIN_COLORS.shadowColor2
    },
    btnDark: {
        color: ADMIN_COLORS.white,
        bg: ADMIN_COLORS.dark,
        borderColor: ADMIN_COLORS.dark,
        hoverColor: ADMIN_COLORS.dark,
        hoverBg: ADMIN_COLORS.dark,
        hoverBorderColor: ADMIN_COLORS.dark,
        focusShadowRGB: '217, 226, 234',
        activeColor: ADMIN_COLORS.dark,
        activeBg: ADMIN_COLORS.dark,
        activeBorderColor: ADMIN_COLORS.dark,
        activeShadow: `inset 0 3px 5px ${ ADMIN_COLORS.shadowColor }`,
        disabledColor: ADMIN_COLORS.gray_400,
        disabledBg: ADMIN_COLORS.gray_50,
        disabledBorderColor: ADMIN_COLORS.gray_50
    },
    btnPrimary: {
        color: ADMIN_COLORS.gray_900,
        bg: ADMIN_COLORS.yellow1,
        borderColor: ADMIN_COLORS.yellow1,
        hoverColor: ADMIN_COLORS.gray_900,
        hoverBg: ADMIN_COLORS.yellow2,
        hoverBorderColor: ADMIN_COLORS.yellow3,
        focusShadowRGB: '49, 132, 253',
        activeColor: ADMIN_COLORS.white,
        activeBg: ADMIN_COLORS.yellow2,
        activeBorderColor: ADMIN_COLORS.yellow3,
        activeShadow: `inset 0 3px 5px ${ ADMIN_COLORS.shadowColor }`,
        disabledColor: ADMIN_COLORS.gray_500,
        disabledBg: ADMIN_COLORS.yellow1,
        disabledBorderColor: ADMIN_COLORS.yellow1
    },
    btnSecondary: {
        borderColor: ADMIN_COLORS.gray_200,
        color: ADMIN_COLORS.white,
        bg: ADMIN_COLORS.gray_900,
        hoverBorderColor: ADMIN_COLORS.white_100,
        hoverColor: ADMIN_COLORS.white,
        hoverBg: ADMIN_COLORS.gray_900,
        focusShadowRgb: '49, 132, 253',
        activeBorderColor: ADMIN_COLORS.white_100,
        activeColor: ADMIN_COLORS.white,
        activeBg: ADMIN_COLORS.gray_900,
        activeShadow: `inset 0 3px 5px ${ ADMIN_COLORS.shadowColor }`,
        disabledColor: ADMIN_COLORS.gray_500,
        disabledBg: ADMIN_COLORS.gray_100,
        disabledBorderColor: ADMIN_COLORS.gray_200
    },
    form: {
        errorColor: ADMIN_COLORS.red
    },
    navPills: {
        navPillsLinkActiveBg: ADMIN_COLORS.gray_800,
        navPillsLinkActiveColor: ADMIN_COLORS.white,
        background: ADMIN_COLORS.gray_900,
        border: ADMIN_COLORS.gray_900,
        boxShadow: ADMIN_COLORS.shadowColor,
        navLinkColor: ADMIN_COLORS.white,
        navLinkActiveBoxShadow: ADMIN_COLORS.shadowColor
    },
    card: {
        boxShadow: ADMIN_COLORS.gray_900,
        backgroundColor: ADMIN_COLORS.gray_800,
        color: ADMIN_COLORS.white
    },
    inputCheckbox: {
        unchecked: {
            backgroundColor: ADMIN_COLORS.gray_50,
            borderColor: ADMIN_COLORS.gray_300
        },
        checked: {
            backgroundColor: ADMIN_COLORS.yellow1,
            borderColor: ADMIN_COLORS.yellow4,
            color: ADMIN_COLORS.dark
        }
    },
    checkmark: {
        backgroundColor: ADMIN_COLORS.gray_50,
        borderColor: ADMIN_COLORS.gray_300
    },
    radioChecked: {
        backgroundColor: ADMIN_COLORS.yellow1,
        borderColor: ADMIN_COLORS.yellow4,
        color: ADMIN_COLORS.dark
    },
    labelHover: {
        color: ADMIN_COLORS.blue_100 // Text color on hover
    },
    questionnaire: {
        gradientBackground: {
            color: ADMIN_COLORS.white,
            // eslint-disable-next-line max-len
            backgroundColor: 'linear-gradient(180deg, rgba(29, 34, 58, 0) 37%, rgba(29, 34, 58, 0.192414) 54.72%, rgba(29, 34, 58, 0.385205) 70.14%, rgba(29, 34, 58, 0.8) 88.85%)'
        },
        checkedBackground: {
            backgroundColor: ADMIN_COLORS.yellow1,
            opacity: 0.3
        }
    },
    questionRadio: {
        gradientBackground: {
            // eslint-disable-next-line max-len
            backgroundColor: 'linear-gradient(180deg, rgba(29, 34, 58, 0) 37%, rgba(29, 34, 58, 0.192414) 54.72%, rgba(29, 34, 58, 0.385205) 70.14%, rgba(29, 34, 58, 0.8) 88.85%)'
        },
        checkedBackground: {
            backgroundColor: ADMIN_COLORS.yellow1,
            opacity: 0.3
        }
    },
    questionCheckbox: {
        gradientBackground: {
            // eslint-disable-next-line max-len
            backgroundColor: 'linear-gradient(180deg, rgba(29, 34, 58, 0) 37%, rgba(29, 34, 58, 0.192414) 54.72%, rgba(29, 34, 58, 0.385205) 70.14%, rgba(29, 34, 58, 0.8) 88.85%)'
        },
        checkedBackground: {
            backgroundColor: ADMIN_COLORS.yellow1,
            opacity: 0.3
        },
        card: {
            setValue: {
                color: ADMIN_COLORS.gray_400
            }
        }
    },
    questionCheckmark: {
        backgroundColor: ADMIN_COLORS.yellow1,
        borderColor: ADMIN_COLORS.gray_300,
        textColor: ADMIN_COLORS.dark,
        iconColor: ADMIN_COLORS.white,
        uncheckedBackgroundColor: ADMIN_COLORS.gray_100,
        uncheckedBorderColor: ADMIN_COLORS.gray_300,
        uncheckedIconColor: ADMIN_COLORS.dark

    },
    questionPlusSign: {
        backgroundColor: ADMIN_COLORS.yellow1,
        textColor: ADMIN_COLORS.dark,
        iconColor: ADMIN_COLORS.dark
    },
    questionUnchecked: {
        backgroundColor: ADMIN_COLORS.gray_100,
        borderColor: ADMIN_COLORS.gray_300,
        iconColor: ADMIN_COLORS.dark
    },
    questionIcon: {
        backgroundColor: ADMIN_COLORS.gray_900,
        iconColor: ADMIN_COLORS.dark
    },
    btnGroup: {
        btnDummy: {
            textColor: ADMIN_COLORS.white,
            borderColor: ADMIN_COLORS.yellow1,
            shadowColor: ADMIN_COLORS.shadowColorYellow,
            backgroundColor: ADMIN_COLORS.yellow1
        }
    },
    btnGroupCheckbox: {
        cardText: {
            background: ADMIN_COLORS.shadowColor2,
            boxShadow: `0px 1px 8px 0px ${ ADMIN_COLORS.shadowColor2 }`,
            color: ADMIN_COLORS.white
        }
    }
}
