import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useGetCareProfessionalsMutation, useGetTreatmentsMutation } from '@fmt/api'
import {
    CareProfessional,
    Department,
    GetCareProfessionalsResponse,
    GetTreatmentsResponse
} from '@fmt/type'
import _ from 'lodash'
import { useEffect, useState } from 'react'

/** summary is to iterate the list of selected treatements
 * and then select its carepath paths
 */
interface ComponentProps {
    department: Department
}

const DepartmentData = ({
    department
}: ComponentProps) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()

    const [getLinkedTreatments,
        getLinkedTreatmentsMutation
    ] = useGetTreatmentsMutation()
    const [
        getLinkedTreatmentsResponse,
        setGetLinkedTreatmentsResponse
    ] = useState<GetTreatmentsResponse>()

    const [getLinkedCareprofessionals,
        getLinkedCareprofessionalsMutation
    ] = useGetCareProfessionalsMutation()

    const [
        getLinkedCareprofessionalsResponse,
        setGetLinkedCareprofessionalsResponse
    ] = useState<GetCareProfessionalsResponse>()

    useEffect(() => {
        if (getLinkedTreatmentsMutation.data) {
            setGetLinkedTreatmentsResponse(getLinkedTreatmentsMutation.data)
        }
    }, [getLinkedTreatmentsMutation.data])

    useEffect(() => {
        if (getLinkedCareprofessionalsMutation.data) {
            setGetLinkedCareprofessionalsResponse(getLinkedCareprofessionalsMutation.data)
        }
    }, [getLinkedCareprofessionalsMutation.data])

    const unsubscribeGetLinkedTreatments = () => {
        const unsubscribeMutation = getLinkedTreatments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetLinkedCareprofessionals = () => {
        const unsubscribeMutation = getLinkedCareprofessionals({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = (token: TokenData) => {
        unsubscribeGetLinkedTreatments()
        unsubscribeGetLinkedCareprofessionals()

        let getLinkedTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getLinkedCareprofessionalsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getTreatments.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getLinkedTreatmentsPromise = getLinkedTreatments({
                            authToken: newToken.value,
                            data: {
                                departmentId: department.departmentId,
                                isLinked: true,
                                limit: 0,
                                skip: 0
                            }
                        })
                    }

                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCareProfessionals.path,
                        true
                    )
                    if (isValid && newToken.value) {
                        getLinkedCareprofessionalsPromise = getLinkedCareprofessionals({
                            authToken: newToken.value,
                            data: {
                                departmentId: department.departmentId,
                                isLinked: true,
                                limit: 0,
                                skip: 0
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getLinkedTreatmentsPromise && getLinkedTreatmentsPromise.abort()
            getLinkedCareprofessionalsPromise && getLinkedCareprofessionalsPromise.abort()
        }
    }

    useEffect(() => {
        // if the values of linkedDepartments is updated, then update the queries.
        return fetchData(token)
    }, [token.id, token.valid])

    const addressTruthy = _.some([
        'departmentAddressStreet',
        'departmentAddressNumber',
        'departmentAddressSuffix'
    ], prop => _.get(department, prop))

    const addressStreet = department.departmentAddressStreet
    const addressNumber = department.departmentAddressNumber
    const addressSuffix = department.departmentAddressSuffix
    const city = department.departmentCity

    const nonEmptyValues = _.compact([
        addressStreet, ' ', addressNumber, addressSuffix
    ])
    const formattedString =
' (' + nonEmptyValues.join('') + ', ' + (
    city || '') + ')'

    const careprofessionals = getLinkedCareprofessionalsResponse?.data.careprofessionals || []

    const treatments = getLinkedTreatmentsResponse?.data.treatments || []

    const careprofessionalsRow = <div className={'row mb-3'}>
        <div className={'col-12 col-md-3 mb-2 mb-md-0'}>
            <span>
                {strings.fmt?.text.facility.add.careprofessionals}
            </span>
        </div>
        <div className={'col-12 col-md-9'}>
            {/* tag list */}
            <ul className={'tag-list pt-0'}>
                {
                    _.map(careprofessionals, (o) => {
                        const data: CareProfessional = {
                            careProfessionalId: o.careProfessionalId,
                            careProfessionalName: o.careProfessionalName || '',
                            careProfessionalProfession: o.careProfessionalProfession || '',
                            careProfessionalEmail: '',
                            careProfessionalLocation: '',
                            careProfessionalPhoneNumber: '',
                            profilePicture: ''
                        }

                        const addressTruthy = _.some([
                            'careProfessionalProfession'
                        ], prop => _.get(data, prop))

                        const profession = data.careProfessionalProfession

                        const nonEmptyValues = _.compact([
                            profession
                        ])
                        const formattedString = ' ( ' + nonEmptyValues.join('') + ' ) '

                        return <li key={o.careProfessionalId}>
                            <div className={'tag review-tag d-block'}>
                                <span>{data.careProfessionalName}</span>
                                {

                                    addressTruthy
                                        ? <span className={'fw-light'}>
                                            {formattedString}
                                        </span>
                                        : ''
                                }
                            </div>
                        </li>
                    })
                }
            </ul>
        </div>
    </div>

    const treatmentsRow = <div className={'row mb-3'}>
        <div className={'col-12 col-md-3 mb-2 mb-md-0'}>
            <span>
                {strings.fmt?.text.facility.add.treatment}
            </span>
        </div>
        <div className={'col-12 col-md-9'}>
            {/* tag list */}
            <ul className={'tag-list pt-0'}>
                {
                    _.map(treatments, (o) => {
                        return <li key={o.treatmentId}>
                            <div className={'tag review-tag'}>
                                {o.treatmentName || ''}
                            </div>
                        </li>
                    })
                }
            </ul>
        </div>
    </div>
    return <>
        <h5 className={'mb-5'}>
            <span>{department.departmentName}</span>
            {
                addressTruthy
                    ? <span className={'fw-light'}>
                        {formattedString}
                    </span>
                    : ''
            }
        </h5>

        {
            careprofessionals.length ? careprofessionalsRow : ''
        }

        {
            treatments.length ? treatmentsRow : ''
        }

    </>
}
export default DepartmentData
