import { BOOTSTRAP_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/dark/BootstrapConfig'
import {
    CAREPLAN_STEP_CONFIG
} from '@stylesheet/brands/ikh/enduser/themes/dark/CareplanStepConfig'
import { GLOBAL_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/dark/GlobalConfig'
import { LOGIN_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/dark/LoginConfig'
import { PROFILE_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/dark/ProfileConfig'
import { REGISTRATION_CONFIG } from '@stylesheet/brands/ikh/enduser/themes/dark/RegistrationConfig'
import {
    RECOVERY_PLAN_CONFIG
} from '@stylesheet/brands/ikh/enduser/themes/dark/RecoveryPlanConfig'
import { ThemeConfig } from '@stylesheet/config/ThemeConfig'
import {
    REASON_WITH_ME_CONFIG
} from '@stylesheet/brands/ikh/enduser/themes/dark/ReasonWithMeConfig'

export const THEME_CONFIG: ThemeConfig = {
    global: GLOBAL_CONFIG,
    bootstrap: BOOTSTRAP_CONFIG,
    endUser: {
        careplanStep: CAREPLAN_STEP_CONFIG,
        login: LOGIN_CONFIG,
        profile: PROFILE_CONFIG,
        registration: REGISTRATION_CONFIG,
        recoveryPlan: RECOVERY_PLAN_CONFIG,
        reasonWithMe: REASON_WITH_ME_CONFIG
    }
}
