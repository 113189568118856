import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { TokenData } from '@app/types/type.token'
import {
    useEditDepartmentMutation,
    useGetDepartmentMutation,
    useGetDepartmentsMutation
} from '@fmt/api'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import Address from '@fmt/components/departments/details/inputs/Address'
import City from '@fmt/components/departments/details/inputs/City'
import DepartmentName from '@fmt/components/departments/details/inputs/DepartmentName'
import Email from '@fmt/components/departments/details/inputs/Email'
import GeneralPhoneNumber from '@fmt/components/departments/details/inputs/GeneralPhoneNumber'
import PostalCode from '@fmt/components/departments/details/inputs/PostalCode'
import StreetName from '@fmt/components/departments/details/inputs/StreetName'
import Website from '@fmt/components/departments/details/inputs/Website'
import { DEPARTMENT_FORMIK_INITIAL_VALUES, DEPARTMENT_VALIDATION_SCHEMA } from '@fmt/constants'
import { Department, DepartmentKeys } from '@fmt/type'
import { push } from '@lagunovsky/redux-react-router'
import { useFormik } from 'formik'
import _ from 'lodash'

const AccordionComponent = (
    { mode, isOpen, obj, fixedCacheKey }:
    { mode: 'EDIT' | 'VIEW', isOpen?: boolean, obj: Department, fixedCacheKey:string}
) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const dispatch = useAppDispatch()

    const [getDepartment, getDepartmentMutation] = useGetDepartmentMutation()
    // accordion components are now editable.
    const [editDepartment, editDepartmentMutation] = useEditDepartmentMutation()

    const [getDepartments] = useGetDepartmentsMutation({
        fixedCacheKey
    })

    const departmentEditFormik = useFormik({
        initialValues: DEPARTMENT_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DEPARTMENT_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.editDepartment.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        editDepartment({
                            authToken: newToken.value,
                            data: {
                                departmentId: obj.departmentId,
                                departmentName: values.departmentName || '',
                                departmentAddressStreet: values.departmentAddressStreet,
                                departmentAddressNumber: values.departmentAddressNumber,
                                departmentAddressSuffix: values.departmentAddressSuffix || '',
                                departmentPostalcode: values.departmentPostalcode,
                                departmentCity: values.departmentCity,
                                departmentPhonenumber: values.departmentPhonenumber || '',
                                departmentEmailAddress: values.departmentEmailAddress || '',
                                departmentWebsite: values.departmentWebsite || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = editDepartmentMutation.data

        if (data) {
            if (data.status === 'OK') {
                // don't do anything.
                const isValid2 = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.apiPaths
                        .getDepartments.path,
                    true
                )

                if (isValid2) {
                    getDepartments({
                        authToken: token.value,
                        data: {
                        }
                    })
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editDepartmentMutation.data])

    useEffect(() => {
        if (editDepartmentMutation.error) {
            const message = getErrorText(editDepartmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editDepartmentMutation.error])

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                isOpen === true &&
                getDepartmentMutation.isSuccess === false
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getDepartment.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getDepartment({
                            authToken: newToken.value,
                            data: {
                                departmentId: obj.departmentId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // ONLY fetch it when obj.needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid, isOpen, getDepartmentMutation.isSuccess])

    useEffect(() => {
        if (getDepartmentMutation.data) {
            const data = getDepartmentMutation.data
            departmentEditFormik.setValues({
                ...departmentEditFormik.values,
                ...data.data
            })
        }
    }, [getDepartmentMutation.data])

    useEffect(() => {
        if (getDepartmentMutation.error) {
            const message = getErrorText(getDepartmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDepartmentMutation.error])

    const ViewButton = useMemo(() => {
        return <button
            type={'button'}
            className={'btn btn-secondary w-100'}
            onClick={(e) => {
                e.stopPropagation()

                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.routes.viewDepartment,
                    true
                )

                dispatch(push(
                    _.replace(isValid.route,
                        ':departmentId',
                        obj.departmentId
                    )
                ))
            }}
        >
            {strings.fmt?.text.department.menu.view_department}
        </button>
    }, undefined)

    const SubmitButton = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        )

        return <button type={'button'}
            disabled={
                // step 1 shouldn't be disabled here because of formik.
                false
                // step 2 can be skipped entirely.
            }
            className={[
                'btn btn-primary w-100 px-8'
            ].join(' ')}
            onClick={() => {
                departmentEditFormik.handleSubmit()
            }}>
            { editDepartmentMutation.isLoading
                ? LoadingContent
                : strings.app?.text.save}
        </button>
    }, undefined)

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: DepartmentKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                departmentEditFormik.handleSubmit()
            }
        }
    }

    const DepartmentNameInput = useMemo(() => {
        return <DepartmentName
            readOnly={mode === 'VIEW'}
            departmentEditFormik={departmentEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentPostalcode')
            }}
        />
    }, [
        mode,
        strings,
        departmentEditFormik.values.departmentName,
        departmentEditFormik.errors.departmentName
    ])

    const PostalCodeInput = useMemo(() => {
        return <PostalCode
            readOnly={mode === 'VIEW'}
            departmentEditFormik={departmentEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentAddressNumber')
            }}
        />
    }, [
        mode,
        strings,
        departmentEditFormik.values.departmentPostalcode,
        departmentEditFormik.errors.departmentPostalcode
    ])

    const AddressInput = useMemo(() => {
        return <Address
            readOnly={mode === 'VIEW'}
            departmentEditFormik={departmentEditFormik}

            handleKeyDown={handleKeyDown}

        />
    }, [
        mode,
        strings,
        departmentEditFormik.values.departmentAddressNumber,
        departmentEditFormik.errors.departmentAddressNumber,
        departmentEditFormik.values.departmentAddressSuffix,
        departmentEditFormik.errors.departmentAddressSuffix
    ])

    const StreetNameInput = useMemo(() => {
        return <StreetName
            readOnly={mode === 'VIEW'}
            departmentEditFormik={departmentEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentCity')
            }}
        />
    }, [
        mode,
        strings,
        departmentEditFormik.values.departmentAddressStreet,
        departmentEditFormik.errors.departmentAddressStreet
    ])

    const CityInput = useMemo(() => {
        return <City
            readOnly={mode === 'VIEW'}
            departmentEditFormik={departmentEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentPhonenumber')
            }}
        />
    }, [
        mode,
        strings,
        departmentEditFormik.values.departmentCity,
        departmentEditFormik.errors.departmentCity
    ])

    /** GROUP 2 */

    const GeneralPhoneNumberInput = useMemo(() => {
        return <GeneralPhoneNumber
            readOnly={mode === 'VIEW'}
            departmentEditFormik={departmentEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentEmailAddress')
            }}
        />
    }, [
        mode,
        strings,
        departmentEditFormik.values.departmentPhonenumber,
        departmentEditFormik.errors.departmentPhonenumber
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={mode === 'VIEW'}
            departmentEditFormik={departmentEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentWebsite')
            }}
        />
    }, [
        mode,
        strings,
        departmentEditFormik.values.departmentEmailAddress,
        departmentEditFormik.errors.departmentEmailAddress
    ])

    const WebsiteInput = useMemo(() => {
        return <Website
            readOnly={mode === 'VIEW'}
            departmentEditFormik={departmentEditFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { departmentEditFormik.handleSubmit() }
            }}
        />
    }, [
        mode,
        strings,
        departmentEditFormik.values.departmentWebsite,
        departmentEditFormik.errors.departmentWebsite
    ])
    return <>
        {
            getDepartmentMutation.isLoading && <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        }
        {getDepartmentMutation.isSuccess && <div className={'px-16'}>
            <h5 className={'col fw-semibold'}>{strings.fmt?.text
                .facility.add.steps['2'].department_data}</h5>
            {/* group one */}
            <div className={'mb-6'}>
                <h6 className={'mb-6'}>{strings.fmt
                    ?.text.facility.menu.name_and_address_details}</h6>
                {DepartmentNameInput}
                {PostalCodeInput}
                {AddressInput}
                {StreetNameInput}
                {CityInput}
            </div>
            {/* group two */}

            <div>
                <h6 className={'mb-6'}>{strings.fmt
                    ?.text.facility.menu.contact_details}</h6>
                {GeneralPhoneNumberInput}
                {EmailInput}
                {WebsiteInput}
            </div>

            {mode === 'EDIT' && <div className={'container button-container'}>
                <div className={'row align-items-center mt-6'}>
                    <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                        {ViewButton}
                    </div>
                    <div className={'col-6 col-lg-4 col-md-6'}>
                        {SubmitButton}
                    </div>
                </div>
            </div>}
        </div>}
    </>
}

export default AccordionComponent
