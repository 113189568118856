import { END_USER_COLORS } from '@stylesheet/brands/rtw/enduser/Colors'
import { LoginConfig } from '@stylesheet/config/endUser/Login'

export const LOGIN_CONFIG: LoginConfig = {
    headerGradientColor: END_USER_COLORS.headerGradientColor,
    mainContent: {
        statusCard: {
            color: END_USER_COLORS.white,
            background: END_USER_COLORS.darkBlue
        },
        btnPrimary: {
            sso: {
                microsoft: {
                    color: END_USER_COLORS.white,
                    bg: END_USER_COLORS.sso.microsoft,
                    borderColor: END_USER_COLORS.sso.microsoft,
                    hoverColor: END_USER_COLORS.white,
                    hoverBg: END_USER_COLORS.sso.microsoft,
                    hoverBorderColor: END_USER_COLORS.sso.microsoft,
                    activeColor: END_USER_COLORS.white,
                    activeBg: END_USER_COLORS.sso.microsoft,
                    activeBorderColor: END_USER_COLORS.sso.microsoft,
                    disabledBg: END_USER_COLORS.sso.microsoft,
                    disabledBorderColor: END_USER_COLORS.sso.microsoft
                }
            }
        }
    }
}
