import {
    OpenListActions,
    OpenListInputs,
    OpenListValues,
    ReasonWithMeState
} from '@reasonWithMe/type'
import _ from 'lodash'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    formsDispatch: (value: OpenListActions) => void,
    form: OpenListValues
}

const MultiList = ({
    questionInterface, formsDispatch, form
}: ComponentProps) => {
    const data = questionInterface.currentReasonWithMeResponse.reasoningData

    // console.log('answers allowed, ', data.question?.questionAnswersAllowed)
    // console.log('form inputs: ', form.inputs)

    const renderInputs = (obj: OpenListInputs) => {
        const key = [
            obj.answerName
        ].join('')

        const isChecked = obj.userTypedAnswer === true

        const isDisabled = form.inputs.filter((o) => {
            return o.userTypedAnswer === true
        }).length >= (data.question?.questionAnswersAllowed || 0)

        const selectionCellOnclick = () => {
            const arr = _.cloneDeep(form.inputs)
            const found = _.find(arr, o => {
                return (
                    o.answerName === obj.answerName
                )
            })

            if (found) {
                found.userTypedAnswer = !found.userTypedAnswer

                // console.log('arr to update: ', arr)

                formsDispatch({
                    type: 'UPDATE_ELEMENT',
                    id: form.id,
                    value: arr
                })
            }
        }

        // if there are too many choices, use col-6
        return <div className={'col'} key={key}>
            <input type={'checkbox'}
                className={'btn-check'}
                autoComplete={'off'}
                id={key}
                checked={isChecked}
                disabled={isDisabled && !isChecked}
                onChange={() => {
                    if (!(isDisabled && !isChecked)) {
                        selectionCellOnclick()
                    }
                }}
            />
            <label className={'btn btn-dummy'} htmlFor={key}>

                <div className={'question-checkbox'}>
                    <div className={'card justify-content-center px-3 py-2'}>

                        <div className={'d-flex flex-column'}>
                            <div className={'d-flex align-items-center'}>
                                <div className={'p text-start'}>
                                    {obj.answerName}
                                </div>
                                {
                                    isChecked
                                        ? <div className={'question-checkmark ms-auto'}>
                                            <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                        </div>
                                        : <div className={'question-plussign ms-auto'}>
                                            <i className={'fa-light fa-plus mx-auto'}></i>
                                        </div>
                                }

                            </div>
                        </div>

                    </div>
                </div>

            </label>
        </div>
    }

    return <>
        <div className={'container-fluid'}>
            <div className={'row row-cols-1 row-cols-md-2 btn-group-checkbox-list mt-3'}
                role={'group'}>
                {
                    _.map(form.inputs, renderInputs)
                }
            </div>
        </div>
    </>
}

export default MultiList
