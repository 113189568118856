import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { ImageDisplay } from '@profile/styles/ImageDisplay'

import Email from '@doc/components/patients/details/inputs/Email'
import FirstName from '@doc/components/patients/details/inputs/FirstName'
import LastName from '@doc/components/patients/details/inputs/LastName'
import PatientNumber from '@doc/components/patients/details/inputs/PatientNumber'

import { push } from '@lagunovsky/redux-react-router'

import { ACTION_MUTATION_PROMISE } from '@app/app.constants'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useGetPatientTherapeuticsMutation } from '@doc/api'
import Active from '@doc/components/patients/details/inputs/Active'
import AddPatientTreatment from '@doc/components/patients/details/makeCareplan/Main'
import PatientTreatmentsInterface from '@doc/components/patients/details/PatientTreatmentsInterface'
import {
    GetPatientResponse,
    PatientKeys,
    PatientValues,
    UpdateProfilePicturePatientResponse
} from '@doc/type'
import { FormikProps } from 'formik'
import _ from 'lodash'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

interface ComponentProps {
    resendInviteToUser: () => void
    mode: 'VIEW' | 'EDIT',
    patientEditFormik: FormikProps<Pick<
    PatientValues, 'firstName' | 'lastName' | 'email' | 'patientNumber'> & {active: boolean}>,
    showCancelWarning: boolean
    setShowCancelWarning: React.Dispatch<React.SetStateAction<boolean>>
    showRecentlyUploaded: boolean
    userId: string | undefined
    editPatientMutation: {
        isLoading: boolean
    }
    resendInviteMutation: {
        isLoading: boolean
    }
    getPatientMutation: {
        data: GetPatientResponse | undefined
    }
    updateProfilePicturePatientMutation: {
        data: UpdateProfilePicturePatientResponse | undefined
    }
    fileInputRef: React.RefObject<HTMLInputElement>
    setFile: React.Dispatch<React.SetStateAction<File | null>>
    modeButtonClicked: boolean,
    setMode: React.Dispatch<React.SetStateAction<'VIEW' | 'EDIT'>>
    setModeButtonClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const PatientRecords = ({
    resendInviteToUser, mode, patientEditFormik, showCancelWarning, setShowCancelWarning, userId,
    editPatientMutation, updateProfilePicturePatientMutation,
    showRecentlyUploaded, getPatientMutation, resendInviteMutation,
    fileInputRef, setFile, modeButtonClicked,
    setMode, setModeButtonClicked
}:ComponentProps) => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)

    const validateRoute = useValidateRoute()
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const [getPatientTherapeutics, getPatientTherapeuticsMutation] =
    useGetPatientTherapeuticsMutation()
    const [showAddModal, toggleShowAddModal] = useState<boolean>(false)

    const unsubscribegetPatientTherapeutics = () => {
        const unsubscribeMutation = getPatientTherapeutics({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const patientData = getPatientMutation.data
        ?.data.patientPersonalData.patientData[0]?.userData[0] || undefined

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribegetPatientTherapeutics()

        let getPatientTherapeuticsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientTherapeutics.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getPatientTherapeuticsPromise = getPatientTherapeutics({
                            authToken: newToken.value,
                            data: {
                                userId: userId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPatientTherapeuticsPromise && getPatientTherapeuticsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0]
        if (selectedFile) {
            setFile(selectedFile)
        }
    }

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: PatientKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                patientEditFormik.handleSubmit()
            }
        }
    }

    const FirstNameInput = useMemo(() => {
        return <FirstName
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'firstName')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.firstName,
        patientEditFormik.errors.firstName
    ])

    const LastNameInput = useMemo(() => {
        return <LastName
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'lastName')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.lastName,
        patientEditFormik.errors.lastName
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'email')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.email,
        patientEditFormik.errors.email
    ])

    const PatientNumberInput = useMemo(() => {
        return <PatientNumber
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'patientNumber')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.patientNumber,
        patientEditFormik.errors.patientNumber
    ])

    const ActiveSwitch = useMemo(() => {
        return <Active
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'patientNumber')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.active,
        patientEditFormik.errors.active
    ])

    const CancelButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            // if you made changes to the formik, show a warning first via popup
            const obj = patientEditFormik.touched

            interface FormikTouched {
                [key: string]: boolean;
              }

            function hasTrueValue (obj: FormikTouched) {
                for (const key in obj) {
                    if (obj[key] === true) {
                        return true
                    }
                }
                return false
            }

            const hasTrue = hasTrueValue(obj)
            if (hasTrue) {
                // show warning pls
                setShowCancelWarning(true)
            } else {
                // go back to view
                // WRONG. only change the mode.
                setMode('VIEW')
                // const isValid = validateRoute(
                //     activeModules.arr,
                //     MODULE_TABLE.doc.moduleName,
                //     MODULE_TABLE.doc.routes.viewPatient,
                //     true
                // )
                // setModeButtonClicked(true)

                // dispatch(push(
                //     _.replace(isValid.route,
                //         ':userId',
                //         userId || ''
                //     )
                // ))
            }
        }}>
        {strings.app?.text.cancel}
    </button>

    const SubmitButton = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        )

        return <button type={'button'}
            disabled={
                // step 1 shouldn't be disabled here because of formik.
                false
                // step 2 can be skipped entirely.
            }
            className={[
                'btn btn-primary w-100 fw-semibold py-2'
            ].join(' ')}
            onClick={() => {
                patientEditFormik.handleSubmit()
            }}>
            { editPatientMutation.isLoading
                ? LoadingContent
                : strings.app?.text.save}
        </button>
    }, undefined)

    const EditButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            setMode('EDIT')
        }}>
        {strings.doc?.text.patient.patient_records.edit}
    </button>

    const ResendButton = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        )

        return <button type={'button'}
            disabled={
                // step 1 shouldn't be disabled here because of formik.
                false
                // step 2 can be skipped entirely.
            }
            className={[
                'btn btn-primary w-100 fw-semibold py-2 text-nowrap'
            ].join(' ')}
            onClick={() => {
                resendInviteToUser()
            }}>
            { resendInviteMutation.isLoading
                ? LoadingContent
                : strings.doc?.text.patient.patient_records.resend_invite}
        </button>
    }, undefined)

    const ProfilePic = useMemo(() => {
        const url = showRecentlyUploaded
            ? updateProfilePicturePatientMutation.data?.data.url
            : patientData?.profilePicture
        const imageThumbnail = url
            ? <ImageDisplay
                url={url}
                className={'rounded-square profile'}
            />
            : <div
                className={[
                    'img-placeholder rounded-square profile'
                ].join(' ')}
            >
            </div>

        return imageThumbnail
    }, undefined)

    const ChangeProfilePictureButton = useMemo(() => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        )

        return <>
            <input
                type={'file'}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <button type={'button'}
                className={[
                    'btn btn-secondary w-100 mt-5'
                ].join(' ')}
                onClick={() => {
                    if (fileInputRef.current) {
                        fileInputRef.current.click()
                    }
                }}>
                { editPatientMutation.isLoading
                    ? LoadingContent
                    : strings.doc?.text.patient.patient_records.change}
            </button>
        </>
    }, undefined)

    /** display list of patients here with the data table template. */

    // startIncompleteCareplan modal goes here.
    const AddTreatmentForm = useMemo(() => {
        return patientData && <AddPatientTreatment
            showAddModal={showAddModal}
            toggleShowAddModal={toggleShowAddModal}
            fetchData={fetchData}
        />
    }, [
        showAddModal,
        patientData
    ])

    const patientRecords = <div>
        <div className={'row'}>
            <div className={'col-12 col-lg'}>
                <div className={'edit-interface'}>
                    <h2 className={'mb-5 fw-semibold '} >{strings.doc?.text.patient
                        .patient_records.patient}</h2>
                    {/* group one */}
                    <div className={'mb-6'}>
                        <h4 className={'mb-6'}>{strings.doc?.text.patient
                            .patient_records.details}</h4>
                        {FirstNameInput}
                        {LastNameInput}
                        {EmailInput}
                        {PatientNumberInput}
                        {ActiveSwitch}
                    </div>

                    {mode === 'EDIT' && <div className={'container button-container'}>
                        <div className={'row align-items-center mt-6'}>
                            <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                                {CancelButton}
                            </div>
                            <div className={'col-6 col-lg-4 col-md-6'}>
                                {SubmitButton}
                            </div>
                        </div>
                    </div>}

                    {mode === 'VIEW' && <div className={'container button-container'}>
                        <div className={'row align-items-center mt-6'}>
                            <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                                {ResendButton}
                            </div>
                            <div className={'col-6 col-lg-4 col-md-6'}>
                                {EditButton}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className={'col-12 col-lg-auto mt-6 mt-lg-0'}>
                <div className={'picture-interface'}>
                    <h2 className={'mb-5 fw-semibold '} >{strings.doc?.text.patient
                        .patient_records.image}</h2>
                    <div className={'text-center'}>

                        <div className={'img-wrapper mx-auto mb-2'}>

                            {ProfilePic}

                        </div>
                        {
                            mode === 'EDIT' && ChangeProfilePictureButton
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className={'row mt-4 mb-8'}>
            <div className={'col-12 col-lg-6'}>
                <div className={'course-interface'}>
                    <h2 className={'mb-5 fw-semibold '} >
                        {strings.doc?.text.patient
                            .patient_records.active_diagnosis}</h2>
                </div>

            </div>
            <div className={'col-12 col-lg-6'}>
                <div className={'diagnosis-interface'}>
                    <div className={'row justify-content-between'}>
                        <div className={'col-auto'}>
                            <h2 className={'mb-5 fw-semibold '} >
                                {strings.doc?.text.patient
                                    .patient_records.course}
                            </h2>
                        </div>
                        <div className={'col-auto'}>
                            <div className={'align-items-center g-3 row'}>
                                <div className={'col-auto'}>
                                    <div className={
                                        'btn rounded-square d-flex ms-3 shadow-sm ' +
                                'justify-content-center align-items-center'
                                    } onClick={() => {
                                        toggleShowAddModal(true)
                                    }}>
                                        <i className={'fa-light fa-plus'}></i>
                                    </div>
                                </div>
                                <div className={'col-auto'}>
                                    <h6 className={'mb-0 fw-semibold'}>{
                                        strings.app?.text.add
                                    }</h6>
                                </div>

                            </div>

                        </div>
                    </div>
                    <PatientTreatmentsInterface
                        mode={mode}
                        responseData={
                            getPatientTherapeuticsMutation.data
                        }
                        fetchData={fetchData}
                        isLoading={getPatientTherapeuticsMutation.isLoading}
                    />
                </div>
            </div>
        </div>
    </div>

    return <>
        {patientRecords}
        {AddTreatmentForm}
        <Modal centered={true} isOpen={showCancelWarning} toggle={() => {
            setShowCancelWarning(!showCancelWarning)
        }}>
            <ModalHeader className={'justify-content-between'} toggle={() => {
                setShowCancelWarning(!showCancelWarning)
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    setShowCancelWarning(false)
                }}>
                <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                </i>
            </a>}
            >
                <span className={'h5'}>{
                    strings.doc?.text.patient
                        .patient_records?.data_edit_warning?.title || ''
                }</span>
            </ModalHeader>
            <ModalBody>
                {/* content changes if idle / expire sessions expired. */}
                <small className={'d-block my-4'}>
                    {strings.doc?.text.patient
                        .patient_records?.data_edit_warning?.content || ''}
                </small>
                <div className={'row justify-content-end'}>
                    <div className={'col-auto mb-2 mb-md-0'}>
                        <button
                            type={'button'}
                            className={'btn btn-primary w-100'}
                            onClick={ () => {
                                setShowCancelWarning(false)
                            }}>
                            {strings.app?.text?.no || ''}
                        </button>
                    </div>
                    <div className={'col-auto'}>
                        <button type={'button'} onClick={() => {
                            const isValid = validateRoute(
                                activeModules.arr,
                                MODULE_TABLE.doc.moduleName,
                                MODULE_TABLE.doc.routes.viewPatient,
                                true
                            )

                            setModeButtonClicked(true)

                            dispatch(push(
                                _.replace(isValid.route,
                                    ':userId',
                                    userId || ''
                                )
                            ))

                            setShowCancelWarning(false)
                        }} className={'btn btn-primary w-100'}>
                            {strings.app?.text.yes}
                        </button>

                    </div>
                </div>
            </ModalBody>
        </Modal></>
}

export default PatientRecords
