import { useEffect, useReducer } from 'react'

import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { MODULE_TABLE } from '@app/app.config'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { selectRouter } from '@app/app.store'
import { TokenData } from '@app/types/type.token'
import { useAddPatientMutation, useMakeCareplanMutation } from '@doc/api'
import PopupModal from '@doc/components/patients/add/PopupModal'
import {
    CAREPLAN_FORMIK_INITIAL_VALUES,
    CAREPLAN_VALIDATION_SCHEMA,
    PATIENT_FORMIK_STEP_ONE_INITIAL_VALUES,
    PATIENT_FORMIK_STEP_TWO_INITIAL_VALUES,
    PATIENT_STEP_ONE_VALIDATION_SCHEMA,
    PATIENT_STEP_TWO_VALIDATION_SCHEMA
} from '@doc/constants'
import { resetDoctor, selectPatientMenu, setPatientMenu } from '@doc/slice'
import {
    DepartmentCareFacilitiesAction,
    DepartmentCareFacilitiesSelection,
    DepartmentCareFacilitiesState,
    DoctorState
} from '@doc/type'
import { getUnixTime } from 'date-fns'
import { useFormik } from 'formik'
import produce from 'immer'

export const defaultDepartmentCareFaciitiesSelection: DepartmentCareFacilitiesSelection = {
    search: '',
    selectedOptions: {
        department: {
            departmentId: '',
            departmentName: ''
        },
        careInstitution: {
            careInstitutionId: '',
            facilityName: ''
        },
        carepath: {
            treatmentName: '',
            treatmentId: '',
            carepathId: '',
            carepathName: '',
            dateName: ''
        },
        operationDate: {
            isChecked: false,
            date: getUnixTime(new Date())
        }
    }
}

interface ComponentProps {
    fetchData: (token: TokenData) => () => void,
}

const AddInterface = ({ fetchData } : ComponentProps) => {
    const dispatch = useAppDispatch()

    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const router = useAppSelector(selectRouter)

    const patientMenu = useAppSelector(selectPatientMenu)

    const [addPatient, addPatientMutation] = useAddPatientMutation({})
    const [makeCareplan, makeCareplanMutation] = useMakeCareplanMutation({})

    // const [backupPatientData, setBackupPatientData] = useState<
    // Pick<PatientValues, 'firstName' | 'lastName' | 'email' | 'patientNumber'>>()

    // note: since the add interface is used on both the patient menu dashboard
    // and the careprofessional dashboard, we want to make sure that the
    // patient menu is reset back to normal when this is unmounted.
    useEffect(() => {
        return () => {
            dispatch(resetDoctor())
        }
    }, [])

    const patientAddStepOneFormik = useFormik({
        initialValues: PATIENT_FORMIK_STEP_ONE_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PATIENT_STEP_ONE_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            dispatch(setPatientMenu({
                ...patientMenu,
                currentStep: patientMenu.currentStep + 1
            }))
        }
    })

    const patientAddStepTwoFormik = useFormik({
        initialValues: PATIENT_FORMIK_STEP_TWO_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PATIENT_STEP_TWO_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.addPatient.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        addPatient({
                            authToken: newToken.value,
                            data: {
                                firstName: patientAddStepOneFormik.values.firstName || '',
                                lastName: patientAddStepOneFormik.values.lastName || '',
                                email: patientAddStepOneFormik.values.email || '',
                                patientNumber: patientAddStepOneFormik.values.patientNumber || '',
                                careFacilities: [{
                                    careFacilityId: values.careFacilityId,
                                    departmentId: values.departmentId
                                }]
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    const makeCareplanFormik = useFormik({
        initialValues: CAREPLAN_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CAREPLAN_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.makeCareplan.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        makeCareplan({
                            authToken: newToken.value,
                            data: {
                                userId: values.userId || '',
                                treatmentId: values.treatmentId || '',
                                carePlanId: values.carePlanId || '',
                                startDate: values.startDate || 0
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    // useEffect(() => {
    //     if (patientAddStepOneFormik.isSubmitting) {
    //         // if the data already exists, check if the data has changed.
    //         if (
    //             patientAddStepOneFormik.values.firstName !== backupPatientData?.firstName ||
    //             patientAddStepOneFormik.values.lastName !== backupPatientData?.lastName
    //         ) {
    //             // if a change was detected, reset the other two formiks.
    //             console.log('change detected. resetting other formiks.')
    //             patientAddStepTwoFormik.resetForm()
    //             makeCareplanFormik.resetForm()
    //         } else {
    //             console.log('no change detected')
    //         }

    //         // if the data here is new. you have to save it.
    //         setBackupPatientData(patientAddStepOneFormik.values)
    //     }
    //     return () => {

    //     }
    // }, [patientAddStepOneFormik.isSubmitting])

    useEffect(() => {
        const steps: DoctorState['patientMenu']['steps'] = [
            {
                number: 1,
                name: strings.doc?.text.patient.add_interface.stepIndicators['1'] || ''
            },
            {
                number: 2,
                name: strings.doc?.text.patient.add_interface.stepIndicators['2'] || ''
            },
            {
                number: 3,
                name: strings.doc?.text.patient.add_interface.stepIndicators['3'] || ''
            },
            {
                number: 4,
                name: strings.doc?.text.patient.add_interface.stepIndicators['4'] || ''
            }
        ]

        dispatch(setPatientMenu({
            ...patientMenu,
            steps
        }))
    }, [router.location.pathname])

    useEffect(() => {
        const data = addPatientMutation.data

        if (data) {
            if (data.status === 'OK') {
                // toast success message.
                // toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // go to next step
                if (patientMenu.currentStep < patientMenu.steps.length) {
                    dispatch(setPatientMenu({
                        ...patientMenu,
                        currentStep: patientMenu.currentStep + 1,
                        addInterfaceProps: {
                            userId: data.data.userId
                        }
                    }))
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [addPatientMutation.data])

    useEffect(() => {
        const data = makeCareplanMutation.data

        if (data) {
            if (data.status === 'OK') {
                // toast success message.
                // toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // go to next step
                if (patientMenu.currentStep < patientMenu.steps.length) {
                    dispatch(setPatientMenu({
                        ...patientMenu,
                        currentStep: patientMenu.currentStep + 1
                    }))
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [makeCareplanMutation.data])

    useEffect(() => {
        if (addPatientMutation.error) {
            const message = getErrorText(addPatientMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addPatientMutation.error])

    useEffect(() => {
        if (makeCareplanMutation.error) {
            const message = getErrorText(makeCareplanMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [makeCareplanMutation.error])

    /** for 2nd modal reducer */
    const defaultDepartmentCareFaciitiesSelection: DepartmentCareFacilitiesSelection = {
        search: '',
        selectedOptions: {
            department: {
                departmentId: '',
                departmentName: ''
            },
            careInstitution: {
                careInstitutionId: '',
                facilityName: ''
            },
            carepath: {
                treatmentName: '',
                treatmentId: '',
                carepathId: '',
                carepathName: '',
                dateName: ''
            },
            operationDate: {
                isChecked: false,
                date: getUnixTime(new Date())
            }
        }
    }

    // now we can clear the entire state when the form is submitted.
    const [
        departmentCareFacilitiesState,
        dispatchDepartmentCareFacilitiesAction
    ] = useReducer(
        (
            state: DepartmentCareFacilitiesState,
            action: DepartmentCareFacilitiesAction
        ) => {
            switch (action.type) {
                case 'UPDATE_MAIN':
                    return produce(state, (draftState) => {
                        draftState.main = {
                            ...draftState.main,
                            ...action.payload
                        }
                    })

                default:
                    return state
            }
        },
        {
            main: _.cloneDeep(defaultDepartmentCareFaciitiesSelection)
        }
    )

    useEffect(() => {
        patientAddStepTwoFormik.setFieldValue('careFacilityId',
            departmentCareFacilitiesState.main
                .selectedOptions.careInstitution.careInstitutionId)

        patientAddStepTwoFormik.setFieldValue('departmentId',
            departmentCareFacilitiesState.main
                .selectedOptions.department.departmentId)

        makeCareplanFormik.setFieldValue('treatmentId', departmentCareFacilitiesState.main
            .selectedOptions.carepath.treatmentId)

        makeCareplanFormik.setFieldValue('carePlanId', departmentCareFacilitiesState.main
            .selectedOptions.carepath.carepathId)

        makeCareplanFormik.setFieldValue('startDate',
            departmentCareFacilitiesState.main
                .selectedOptions.operationDate.isChecked
                ? departmentCareFacilitiesState.main
                    .selectedOptions.operationDate.date
                : 0
        )
    }, [departmentCareFacilitiesState])

    useEffect(() => {
        makeCareplanFormik.setFieldValue('userId', patientMenu.addInterfaceProps.userId)
    }, [patientMenu.addInterfaceProps.userId])
    return <>
        <PopupModal
            fetchData={fetchData}
            patientAddStepOneFormik={patientAddStepOneFormik}
            patientAddStepTwoFormik={patientAddStepTwoFormik}
            makeCareplanFormik={makeCareplanFormik}
            addPatientData={{
                data: addPatientMutation.data,
                isLoading: addPatientMutation.isLoading
            }}
            makeCareplanData={{
                data: makeCareplanMutation.data,
                isLoading: makeCareplanMutation.isLoading
            }}
            departmentCareFacilitiesState={
                departmentCareFacilitiesState
            }
            dispatchDepartmentCareFacilitiesAction={
                dispatchDepartmentCareFacilitiesAction
            }
        />
    </>
}

export default AddInterface
