
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import { GPACareplanStepIdDetails } from '@doc/type'
import { QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    reasoningObj: GPACareplanStepIdDetails[
        'reasoningData']['reasoningData']['rootObject']
}

const MultiTile = ({
    reasoningObj
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const questionData = reasoningObj.questionData
    const answerValue = reasoningObj.data.data.answerValue

    const input = useMemo(() => {
        /** how should the input be rendered */

        const renderInputs = (obj: QuestionAnswer, i: number, arr: QuestionAnswer[]) => {
            const key = [
                'answer-choice', '-', i, '-', reasoningObj.id
            ].join('')

            const isChecked = _.includes(
                answerValue,
                obj.acqValue
            )

            const isDisabled = _.isArray(answerValue)
                ? questionData.questionAnswersAllowed
                    ? answerValue.length >= (questionData.questionAnswersAllowed || 0)
                        ? questionData.questionAnswersAllowed > 1
                        : false
                    : false
                : false

            // if there are too many choices, use col-6
            return <div className={'col'} key={key}>
                <input type={'checkbox'}
                    className={[
                        'btn-check',
                        isDisabled && !isChecked ? 'disabled' : ''
                    ].join(' ')}
                    autoComplete={'off'}
                    id={key}
                    checked={isChecked}
                    // use class name instead.
                    // disabled={isDisabled && !isChecked}
                    onChange={() => {}}
                />
                <label className={'btn btn-dummy'} htmlFor={key}>

                    <div className={'question-checkbox'}>
                        <div className={'card position-relative'}>
                            <div className={'img-wrapper '}>
                                <img
                                    src={obj.acqImage}
                                    className={'img-fluid'}
                                    alt={obj.acqName}
                                />
                                <div className={'gradient-background'}></div>
                                <div className={'checked-background'}></div>
                            </div>
                            <div
                                className={'card-header end-0 position-absolute text-end top-0'}
                            >
                                <div className={'question-checkmark'}>
                                    <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                </div>
                            </div>

                            <div className={
                                'bottom-0 card-footer position-absolute text-start'
                            }>
                                <span
                                    className={'card-text'}>
                                    {obj.acqName}
                                </span>
                            </div>
                        </div>
                    </div>

                </label>
            </div>
        }

        return <div className={'container-fluid'}>
            <div className={['row g-0 btn-group-checkbox-tile mt-3',
                isMobile ? ' row-cols-2' : ' row-cols-3'].join(' ')}
            role={'group'}>
                {
                    _.map(questionData.questionAnswers || [], renderInputs)
                }
            </div>
        </div>
    }, [isMobile])

    return <div>
        <p className={'text-center'}>{
            `${ strings.reason_with_me?.text.list.allowed || '' }: ${
                questionData?.questionAnswersAllowed
            }`.trim()
        }</p>
        {input}
    </div>
}

export default MultiTile
