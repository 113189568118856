import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import {
    BuildingBlock,
    BuildingBlockProgress,
    ListRoutineActions,
    ListRoutineState
} from '@careplan/type'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useMemo, useReducer } from 'react'
// import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid'
const ListRoutine = ({
    buildingBlock,
    buildingBlockProgress,
    updateBuildingBlockProgress
}: {
    buildingBlock: BuildingBlock,
    buildingBlockProgress?:BuildingBlockProgress
    updateBuildingBlockProgress?: (requestData: {
        obj: BuildingBlockProgress, percentage: number, miscVar?: any
    }) => Promise<void>
}) => {
    const strings = useAppSelector(selectStrings)

    const [routineState, routineDispatch] = useReducer(
        (state: ListRoutineState, action: ListRoutineActions) => {
            switch (action.type) {
                case 'SET_DATA': {
                    return produce(state, draft => {
                        draft.arr = action.value
                    })
                } case 'UPDATE_IS_CHECKED': {
                    return produce(state, draft => {
                        const found = _.find(draft.arr, o => {
                            return (
                                o.setId === action.value.setId
                            )
                        })

                        if (found) {
                            const foundRepetition = _.find(
                                found.repetitions, o => {
                                    return (
                                        o.repetitionId === action.value.repetitionId
                                    )
                                })
                            if (foundRepetition) {
                                foundRepetition.isChecked = action.value.isChecked
                            }
                        }
                    })
                }
            }
        }, {
            arr: []
        }
    )

    useEffect(() => {
        console.log('list routine should be called once.')
    }, [])

    /**
     * bug found: you update the progress and this triggers
     * again after the call is complete. the data is
     * now reset back to zero. Change the dependency to buildingBlock
     * so that this call is only called once.
     */
    useEffect(() => {
        let arr: ListRoutineState['arr'] = []

        if (_.isArray(buildingBlock.buildingBlockValue)) {
            // check if the progress has miscVar.
            // if it does, get that instead of making a new one.

            // should be the ListRoutineState['arr']
            arr = _.map(buildingBlock.buildingBlockValue, (exercise) => {
                const repetitions = exercise.setRepetition

                const findSet = _.find(buildingBlockProgress
                    ?.miscVar, (o) => {
                    return o.setId === exercise.setId
                }) as ListRoutineState['arr'][0] | undefined

                if (findSet) {
                    return findSet
                } else {
                    return {
                        ...exercise,
                        repetitions: _.times(repetitions, (i) => {
                            return {
                                isChecked: false,
                                repetitionId: uuidv4()
                            }
                        })
                    }
                }
            })
        }

        console.log(arr)

        // should be a 2d array.
        routineDispatch({
            type: 'SET_DATA',
            value: arr
        })
    }, [buildingBlock])

    /** only update the building block EXCEPT on mount.  */
    useEffect(() => {
        // get all checked values from each repetition.
        let checkedCount = 0
        let allInputs = 0

        _.forEach(routineState.arr, (o) => {
            _.forEach(o.repetitions, (p) => {
                p.isChecked && checkedCount++
                allInputs++
            })
        })

        // don't update if the value to submit is a zero.
        const percentage = _.round((checkedCount / (allInputs || 1)) * 100, 2)
        if (routineState.arr.length && percentage <= 0) {
            console.log('don\'t update if the percentage is less than zero.')
        } else if (percentage > 0) {
            // don't update the progress if equal.
            if (_.isEqual(routineState.arr, buildingBlockProgress?.miscVar)) {
                console.log('don\'t update if buildingBlockProgress and initial is same.')
            } else {
                console.log('percentage is: ', percentage)
                buildingBlockProgress && updateBuildingBlockProgress && updateBuildingBlockProgress(
                    {
                        obj: buildingBlockProgress,
                        percentage,
                        miscVar: routineState.arr
                    }
                )
            }
        }
    }, [routineState.arr])

    const choices = useMemo(() => {
        // should be 2d
        return _.map(routineState.arr, (set, outerIndex) => {
            return _.map(set.repetitions, (repetition, innerIndex) => {
                const key = `btncheckset-${ outerIndex }-${ innerIndex }`

                const checkbox = <input type={'checkbox'}
                    checked={repetition.isChecked} className={'btn-check'} id={key}
                    onChange={(e) => {
                        routineDispatch({
                            type: 'UPDATE_IS_CHECKED',
                            value: {
                                setId: set.setId,
                                repetitionId: repetition.repetitionId,
                                isChecked: !repetition.isChecked
                            }
                        })
                    }}/>

                const cardBody = <div className={'card-body'}>
                    <div className={'d-flex align-items-center'}>
                        <div className={'question-unchecked'}></div>
                        <div className={'question-checkmark'}>
                            <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                        </div>
                        <div className={'p fw-bold mx-3'}>
                            {set.setName}
                        </div>
                        <small className={'set-value'}>{
                            [
                                set.setValue,
                                strings.careplanPatient?.text.careplanStep.routine.times
                            ].join(' ')
                        }
                        </small>

                    </div>
                </div>
                return <div className={'col'} key={key}>
                    {checkbox}
                    <label className={'btn btn-dummy'} htmlFor={key}>
                        <div className={'question-checkbox'}>
                            <div className={'card justify-content-center px-3'}>
                                {cardBody}
                            </div>
                        </div>
                    </label>
                </div>
            })
        })
    }, [routineState])

    return <div>
        <div className={'col-12 col-md-8 col-lg-6 me-auto mb-4'}>
            <div className={'row row-cols-1 btn-group-checkbox-set'} role={'group'}>
                {choices}
            </div>
        </div>
    </div>
}

export default ListRoutine
