// PageCountDropdown.js
import { PAGE_COUNT } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import React from 'react'

interface ComponentProps {
    pageCount: number
    setPageCount: React.Dispatch<React.SetStateAction<number>>
}
const PageCountDropdown = ({ pageCount, setPageCount }: ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    return (
        <div className={'align-items-center row'}>
            <div className={'col-auto'}>
                <label htmlFor={'pageCount'}>{strings.app?.text.page_count}</label>
            </div>
            <div className={'col-auto'}>
                <select
                    className={[
                        'form-control-sm',
                        'form-control form-select'
                    ].join(' ')}
                    id={'pageCount'}
                    value={pageCount}
                    onChange={(e) => {
                        setPageCount(Number(e.target.value))
                    }}
                >
                    {PAGE_COUNT.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

        </div>
    )
}

export default PageCountDropdown
