import { MODULE_TABLE } from '@app/app.config'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { IDS } from '@registration/constants'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
// for react-datepicker locale. requires date-fns.

import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useGetUserTreatmentsMutation, useMakeCareplanMutation } from '@doc/api'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { fromUnixTime, getUnixTime } from 'date-fns'
// for react-datepicker locale. requires date-fns.
import NewDatePicker from '@app/components/NewDatePicker'
import { TokenData } from '@app/types/type.token'
import { useMediaQuery } from 'react-responsive'

import MakeCareplanInterface from '@doc/components/patients/details/makeCareplan/MakeCareplanInterface'
import {
    MAKE_CAREPLAN_FORMIK_INITIAL_VALUES,
    MAKE_CAREPLAN_VALIDATION_SCHEMA
} from '@doc/constants'
import { CareplanKeys, PatientParams } from '@doc/type'
import { useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

interface ComponentProps {
    showAddModal: boolean
    toggleShowAddModal: React.Dispatch<React.SetStateAction<boolean>>
    fetchData: (token: TokenData) => () => void
}

function AddPatientTreatment (props: ComponentProps) {
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)

    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const [isOutsideClick, setIsOutsideClick] = useState(false)
    const dateFormats = useAppSelector(selectDateFormats)

    const [getUserTreatments, getUserTreatmentsMutation] = useGetUserTreatmentsMutation()
    const [makeCareplan, makeCareplanMutation] = useMakeCareplanMutation({})

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const { userId } = useParams<PatientParams>()

    const makeCareplanFormik = useFormik({
        initialValues: {
            ...MAKE_CAREPLAN_FORMIK_INITIAL_VALUES,
            userId
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MAKE_CAREPLAN_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.invalid_date || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.makeCareplan.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        makeCareplan({
                            authToken: newToken.value,
                            data: {
                                userId: values.userId || '',
                                treatmentId: values.treatmentId || '',
                                carePlanId: values.careplanId || '',
                                startDate: values.isUnknownChecked ? 0 : values.startDate || 0
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    const unsubscribeGetUserTreatments = () => {
        const unsubscribeMutation = getUserTreatments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    useEffect(() => {
        if (getUserTreatmentsMutation.error) {
            const message = getErrorText(getUserTreatmentsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getUserTreatmentsMutation.error])

    useEffect(() => {
        if (makeCareplanMutation.error) {
            const message = getErrorText(makeCareplanMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [makeCareplanMutation.error])

    // useEffect(() => {
    //     console.log(makeCareplanFormik.values)
    // }, [makeCareplanFormik.values])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */

        unsubscribeGetUserTreatments()

        let getUserTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const apiPath2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.getUserTreatments.path,
                        true
                    )

                    if (apiPath2 && newToken.value) {
                        getUserTreatmentsPromise = getUserTreatments({
                            authToken: newToken.value,
                            data: {
                                userId: userId || ''
                            }
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.doc
                                    .apiPaths.getUserTreatments.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false

            getUserTreatmentsPromise && getUserTreatmentsPromise.abort()
        }
    }

    useEffect(() => {
        const data = makeCareplanMutation.data

        const call = async () => {
            if (data?.status === 'OK') {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // close the modal and fetch the list of therapeutics again.
                props.fetchData(token)
                props.toggleShowAddModal(false)
            }
        }
        call()
    }, [activeModules.id, makeCareplanMutation.data])

    useEffect(() => {
        // empty formik via reset.
        makeCareplanFormik.resetForm()
        return fetchData(token)
    }, [activeModules.id])

    /** components from ReviewAgainForm */
    const ReviewAgainSubmitButton = useMemo(() => {
        const buttonContent = makeCareplanMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            strings.app?.text.next
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        return (<button type={'submit'}
            disabled={(
                getUserTreatmentsMutation.isSuccess === false) ||
                (makeCareplanMutation.isLoading)
            }
            form={IDS.MAKE_CAREPLAN.FORM} className={'btn btn-primary btn-lg w-100'}>
            {buttonContent}
        </button>)
    }, [strings, makeCareplanMutation, getUserTreatmentsMutation])

    const TreatmentIdSelection = useMemo(() => {
        const displayed = <MakeCareplanInterface
            makeCareplanFormik={makeCareplanFormik}
            isOutsideClick={isOutsideClick}
            setIsOutsideClick={setIsOutsideClick}
            getUserTreatmentsResponse={getUserTreatmentsMutation.data}
        />

        return displayed
    }, [strings,
        isOutsideClick,
        setIsOutsideClick,
        makeCareplanFormik.values.treatmentId,
        makeCareplanFormik.errors.treatmentId,
        getUserTreatmentsMutation.data
    ])

    const DatePickerInput = useMemo(() => {
        const fieldName: CareplanKeys = 'startDate'

        const label = strings.doc?.text.careprofessionals.treated_patients.operation_date

        return (<div className={'form-group  mb-4'}>
            <label htmlFor={IDS.MAKE_CAREPLAN.OK_DATE}
                className={'form-label'}>{`${ label } `}</label>
            <NewDatePicker
                disabled={makeCareplanFormik.values.isUnknownChecked}
                id={IDS.MAKE_CAREPLAN.OK_DATE}
                errors={makeCareplanFormik.errors.startDate}
                isDate={(date) => {
                    makeCareplanFormik.setFieldValue(fieldName, getUnixTime(date))
                }}
                singleDate={makeCareplanFormik.values.startDate !== undefined
                    ? fromUnixTime(makeCareplanFormik.values.startDate)
                    : undefined
                }
                dateType={''}
                timeFormat={dateFormats.format5}
            />
            <div className={'form-text error'}>{
                makeCareplanFormik.errors.startDate
                    ? makeCareplanFormik.errors.startDate
                    : null
            }</div>
            <div>
                <input type={'checkbox'}
                    id={`${ IDS.MAKE_CAREPLAN.UNKNOWN_DATE }`}
                    checked={makeCareplanFormik.values.isUnknownChecked}
                    className={'form-check-input'}
                    onChange={(e) => {
                        makeCareplanFormik.setFieldValue('isUnknownChecked', e.target.checked)
                    }}/>
                <label className={'form-check-label ps-3'}
                    htmlFor={`${ IDS.MAKE_CAREPLAN.UNKNOWN_DATE }`}>{
                        strings.doc?.text.careprofessionals.abnormalities_table.unknown}</label>
            </div>
        </div>)
    }, [strings, makeCareplanFormik.values.startDate,
        makeCareplanFormik.errors.startDate, makeCareplanFormik.values.isUnknownChecked,
        makeCareplanFormik.errors.isUnknownChecked])

    return (<>
        <Modal
            onClosed={() => {
                if (makeCareplanMutation.isSuccess === true) {
                    makeCareplanFormik.resetForm()
                    // then reset the mutation since this modal component doesn't unmount
                    makeCareplanMutation.reset()
                }
            }}
            unmountOnClose={false}
            size={'lg'}
            isOpen={props.showAddModal} toggle={() => {
                props.toggleShowAddModal(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                props.toggleShowAddModal(false)
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    props.toggleShowAddModal(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <div className={isMobile ? 'container' : 'container px-5' }>
                    <div className={'row'}>
                        <div className={'col-12 mx-auto'}>
                            <form id={IDS.MAKE_CAREPLAN.FORM}
                                onSubmit={makeCareplanFormik.handleSubmit}>
                                {TreatmentIdSelection}
                                {DatePickerInput}
                                <div className={'justify-content-center row'}>
                                    <div className={'col-auto'}>
                                        {ReviewAgainSubmitButton}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>)
}

export default AddPatientTreatment
