import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { back } from '@lagunovsky/redux-react-router'
import { useGetCareprofessionalsMutation, useGetOverallProgressMutation } from '@profile/api'
import { MODULE_VERSION } from '@profile/constants'
import {
    CardImageWrapper,
    HeaderContent,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/profilePage/Components'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'

import { useMediaQuery } from 'react-responsive'

const MyCareTeam = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)

    const [getCareprofessionals, getCareprofessionalsMutation] = useGetCareprofessionalsMutation()
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const unsubscribeGetCareprofessionals = () => {
        const unsubscribeMutation = getCareprofessionals({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGetCareprofessionals()
        unsubscribeGetOverallProgress()

        let getCareprofessionalsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getUserCareProfessionals.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getCareprofessionalsPromise = getCareprofessionals({
                            authToken: newToken.value
                        })
                    }
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCareprofessionalsPromise && getCareprofessionalsPromise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    const cards = useMemo(() => {
        const data = getCareprofessionalsMutation.data?.data.myCareprofessionals

        return <div className={'row row-cols-1 row-cols-md-2 g-3 mt-lg-8 mt-2'}>
            {_.map(data, (o, index) => {
                const cardBody = <div className={'card-body position-relative'}>
                    <div className={'row'}>
                        <div className={'col-auto '}>
                            <CardImageWrapper className={''}

                                url={o.profilePicture || ''}
                            >
                            </CardImageWrapper>
                        </div>
                        <div className={[
                            'col',
                            !isMobile ? 'my-3' : ''
                        ].join(' ')}>
                            <div className={'row'}>
                                <div className={'col-auto'}>
                                    <span className={isMobile ? 'h6 mb-1' : 'h5 mb-2'}>
                                        {o.name}
                                    </span>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-auto'}>
                                    <small>
                                        {o.profession}
                                    </small>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-auto'}>
                                    <small>
                                        {o.location}
                                    </small>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                return <div className={'col'} key={`card-${ index }`}>
                    <div className={'card'}>
                        {cardBody}
                    </div>
                </div>
            })}
        </div>
    }, undefined)

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        <HeaderImage
            className={'p-4'}
            url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} >
        </HeaderImage>
        <HeaderGradient />
        <HeaderContent
            className={'p-4'}
        >
            <button
                type={'button'}
                className={[
                    'btn btn-round btn-rounded'
                ].join('')}
                onClick={(e) => {
                    e.preventDefault()
                    dispatch(back())
                }}
            >
                <i
                    className={[
                        'fa-light',
                        'fa-arrow-left'
                    ].join(' ')}
                    aria-hidden={'true'}>
                </i>
            </button>
        </HeaderContent>
        <div className={'main-content'}>
            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-10 col-md-8 cards-container mb-5'}>
                        <h1 className={'mt-4 mb-5'}>{strings.profile?.text.my_careteam}</h1>

                        {cards}

                    </div>
                </div>

            </div>
        </div>
        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <div className={'main-content'}>
            <div className={'container-fluid px-4'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-12 cards-container mb-5'}>
                        <div className={'row align-items-center g-3'}>
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'btn btn-round btn-rounded'
                                    ].join('')}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(back())
                                    }}
                                >
                                    <i
                                        className={[
                                            'fa-light',
                                            'fa-arrow-left'
                                        ].join(' ')}
                                        aria-hidden={'true'}>
                                    </i>
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <h2 className={'mb-0'}>{strings.profile?.text.my_careteam}</h2>
                            </div>
                        </div>

                        {cards}

                    </div>
                </div>

            </div>
        </div>
    </>

    return <div className={'my-careteam-page position-relative'}>
        {
            isMobile ? mobileResult : desktopResult
        }
    </div>
}

export default MyCareTeam
