import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import Color from 'color'
import styled from '@emotion/styled'
// import _ from 'lodash'

export const HeaderImage = styled.div<{url:string}>`
    background-image: ${ (props) => {
    const urlToUse = props.url || '/images_new/header/header_blue.svg'
    return `url("${ urlToUse }")`
} };
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;


    height: 260px;
    width: 100%;
    position: absolute;
    z-index: -2;
`

export const HeaderGradient = styled.div`
    background-image: ${ (props) => {

    let colorStopOne =''
    let colorStopTwo = ''

    colorStopOne = Color(props.theme.config.endUser?.profile.headerGradientColor).alpha(0.5).rgb().string()
    colorStopTwo = Color(props.theme.config.endUser?.profile.headerGradientColor).alpha(0.4).rgb().string()




    // eslint-disable-next-line max-len
    const gradientPart = `linear-gradient(90deg, ${ colorStopOne } 0%, ${ colorStopTwo } 22.63%, rgba(255, 255, 255, 0) 57.06%)`

    return `${ gradientPart }`
    } };
    height: 260px;
    width: 100%;
    position: absolute;
    z-index: -1;
`

export const HeaderContent = styled.div`
    background: transparent;
    height: 260px;
    width: 100%;
    position: absolute;
    z-index: 1;
`

export const CardImageWrapper = styled.div<{url:string, }>`
    background-image: url(${ (props) => '"' + props.url + '"' });
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (max-width: ${MOBILE_RESPONSIVE_LIMIT})  {
        width: 80px;
        height: 80px;
    }

    @media (min-width: ${MOBILE_RESPONSIVE_LIMIT})  {
        width: 96px;
        height: 96px;
    }


    background-color: ${ (props) => {
        let bgColor =''

        bgColor = props.theme.config.endUser?.profile.cardImageWrapper.background || ''

        return bgColor
    }};

    border-radius: 18px;
`
