import { useMemo } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { CareFacilityKeys, CareFacilityValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    facilityStepOneAddFormik: FormikProps<CareFacilityValues>,
}
// won't put formik here because users can unclick and the data would be
// deinitialized.

const StepOne = ({
    facilityStepOneAddFormik
}:ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareFacilityKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                facilityStepOneAddFormik.handleSubmit()
            }
        }
    }

    const FacilityNameInput = useMemo(() => {
        const fieldName: CareFacilityKeys = 'facilityName'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.FACILITY.ADD.FACILITY_NAME} className={'form-label'}>{
                        strings.fmt?.text.facility.add.steps['1'].facility_name
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            facilityStepOneAddFormik.errors.facilityName && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.fmt
                            ?.text.facility.add.steps['1'].facility_name}
                        value={facilityStepOneAddFormik.values.facilityName}
                        id={IDS.FACILITY.ADD.FACILITY_NAME}
                        onChange={facilityStepOneAddFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'facilityPostalcode')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        facilityStepOneAddFormik.errors.facilityName
                            ? facilityStepOneAddFormik.errors.facilityName
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        facilityStepOneAddFormik.values.facilityName,
        facilityStepOneAddFormik.errors.facilityName
    ])

    const PostalCodeInput = useMemo(() => {
        const fieldName: CareFacilityKeys = 'facilityPostalcode'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.FACILITY.ADD.POSTAL_CODE} className={'form-label'}>{
                        strings.fmt?.text.facility.menu.table.postal_code
                    }</label>
                </div>
                <div className={'col-sm-4 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            facilityStepOneAddFormik.errors.facilityPostalcode && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.fmt?.text.facility.menu.table.postal_code}
                        value={facilityStepOneAddFormik.values.facilityPostalcode}
                        id={IDS.FACILITY.ADD.POSTAL_CODE}
                        onChange={facilityStepOneAddFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'facilityAddressNumber')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        facilityStepOneAddFormik.errors.facilityPostalcode
                            ? facilityStepOneAddFormik.errors.facilityPostalcode
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        facilityStepOneAddFormik.values.facilityPostalcode,
        facilityStepOneAddFormik.errors.facilityPostalcode
    ])

    const AddressInput = useMemo(() => {
        const fieldName1: CareFacilityKeys = 'facilityAddressNumber'
        const fieldName2: CareFacilityKeys = 'facilityAddressSuffix'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.FACILITY.ADD.HOUSE_NUMBER} className={'form-label'}>{
                        strings.fmt?.text.facility.add.steps['1'].house_number
                    }</label>
                    <span className={'mx-2'}>{'+'}</span>
                    <label htmlFor={IDS.FACILITY.ADD.SUFFIX} className={'form-label'}>{
                        strings.fmt?.text.facility.add.steps['1'].suffix
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <div className={'row'}>
                        <div className={'col-12 col-lg-4 mb-4 mb-lg-0'}>
                            <input
                                type={'text'}
                                name={fieldName1}
                                className={[
                                    'form-control-sm',
                                    'form-control',
                                    facilityStepOneAddFormik.errors.facilityAddressNumber &&
                                    'border-danger'
                                ].join(' ')}
                                placeholder={strings.fmt?.text.facility.add.steps['1'].house_number}
                                value={facilityStepOneAddFormik.values.facilityAddressNumber}
                                id={IDS.FACILITY.ADD.HOUSE_NUMBER}
                                onChange={facilityStepOneAddFormik.handleChange}
                                onKeyDown={(e) => {
                                    handleKeyDown(e, 'facilityAddressSuffix')
                                }}
                                required
                            />
                            <div className={'form-text error'}>{
                                facilityStepOneAddFormik.errors.facilityAddressNumber
                                    ? facilityStepOneAddFormik.errors.facilityAddressNumber
                                    : null
                            }</div>
                        </div>
                        <div className={'col-lg-3 col-12'}>
                            <input
                                type={'text'}
                                name={fieldName2}
                                className={[
                                    'form-control-sm',
                                    'form-control',
                                    facilityStepOneAddFormik.errors.facilityAddressSuffix &&
                                     'border-danger'
                                ].join(' ')}
                                placeholder={strings.fmt?.text.facility.add.steps['1'].suffix}
                                value={facilityStepOneAddFormik.values.facilityAddressSuffix}
                                id={IDS.FACILITY.ADD.SUFFIX}
                                onChange={facilityStepOneAddFormik.handleChange}
                                onKeyDown={(e) => {
                                    handleKeyDown(e, 'facilityAddressStreet')
                                }}
                                required
                            />
                            <div className={'form-text error'}>{
                                facilityStepOneAddFormik.errors.facilityAddressSuffix
                                    ? facilityStepOneAddFormik.errors.facilityAddressSuffix
                                    : null
                            }</div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        facilityStepOneAddFormik.values.facilityAddressNumber,
        facilityStepOneAddFormik.errors.facilityAddressNumber,
        facilityStepOneAddFormik.values.facilityAddressSuffix,
        facilityStepOneAddFormik.errors.facilityAddressSuffix
    ])

    const StreetNameInput = useMemo(() => {
        const fieldName: CareFacilityKeys = 'facilityAddressStreet'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.FACILITY.ADD.STREET} className={'form-label'}>{
                        strings.fmt?.text.facility.add.steps['1'].street_name
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            facilityStepOneAddFormik.errors.facilityAddressStreet && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.fmt?.text.facility.add.steps['1'].street_name}
                        value={facilityStepOneAddFormik.values.facilityAddressStreet}
                        id={IDS.FACILITY.ADD.STREET}
                        onChange={facilityStepOneAddFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'facilityCity')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        facilityStepOneAddFormik.errors.facilityAddressStreet
                            ? facilityStepOneAddFormik.errors.facilityAddressStreet
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        facilityStepOneAddFormik.values.facilityAddressStreet,
        facilityStepOneAddFormik.errors.facilityAddressStreet
    ])

    const CityInput = useMemo(() => {
        const fieldName: CareFacilityKeys = 'facilityCity'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.FACILITY.ADD.CITY} className={'form-label'}>{
                        strings.fmt?.text.facility.menu.table.city
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            facilityStepOneAddFormik.errors.facilityCity && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.fmt?.text.facility.menu.table.city}
                        value={facilityStepOneAddFormik.values.facilityCity}
                        id={IDS.FACILITY.ADD.CITY}
                        onChange={facilityStepOneAddFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'facilityPhonenumber')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        facilityStepOneAddFormik.errors.facilityCity
                            ? facilityStepOneAddFormik.errors.facilityCity
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        facilityStepOneAddFormik.values.facilityCity,
        facilityStepOneAddFormik.errors.facilityCity
    ])

    /** GROUP 2 */

    const GeneralPhoneNumberInput = useMemo(() => {
        const fieldName: CareFacilityKeys = 'facilityPhonenumber'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.FACILITY.ADD.GENERAL_NUMBER} className={'form-label'}>{
                        strings.fmt?.text.facility.add.steps['1'].general_telephone_number
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            facilityStepOneAddFormik.errors.facilityPhonenumber && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.fmt
                            ?.text.facility.add.steps['1'].general_telephone_number}
                        value={facilityStepOneAddFormik.values.facilityPhonenumber}
                        id={IDS.FACILITY.ADD.GENERAL_NUMBER}
                        onChange={facilityStepOneAddFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'facilityEmailAddress')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        facilityStepOneAddFormik.errors.facilityPhonenumber
                            ? facilityStepOneAddFormik.errors.facilityPhonenumber
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        facilityStepOneAddFormik.values.facilityPhonenumber,
        facilityStepOneAddFormik.errors.facilityPhonenumber
    ])

    const EmailInput = useMemo(() => {
        const fieldName: CareFacilityKeys = 'facilityEmailAddress'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.FACILITY.ADD.EMAIL} className={'form-label'}>{
                        strings.fmt?.text.facility.add.steps['1'].email_address
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            facilityStepOneAddFormik.errors.facilityEmailAddress && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.fmt?.text.facility.add.steps['1'].email_address}
                        value={facilityStepOneAddFormik.values.facilityEmailAddress}
                        id={IDS.FACILITY.ADD.EMAIL}
                        onChange={facilityStepOneAddFormik.handleChange}
                        onKeyDown={(e) => {
                            handleKeyDown(e, 'facilityWebsite')
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        facilityStepOneAddFormik.errors.facilityEmailAddress
                            ? facilityStepOneAddFormik.errors.facilityEmailAddress
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        facilityStepOneAddFormik.values.facilityEmailAddress,
        facilityStepOneAddFormik.errors.facilityEmailAddress
    ])

    const WebsiteInput = useMemo(() => {
        const fieldName: CareFacilityKeys = 'facilityWebsite'

        const result = <div className={'form-group'}>
            <div className={'row mt-3 align-items-center'}>
                <div className={'col-3 d-none d-sm-block'}>
                    <label htmlFor={IDS.FACILITY.ADD.WEBSITE} className={'form-label'}>{
                        strings.fmt?.text.facility.add.steps['1'].website
                    }</label>
                </div>
                <div className={'col-sm-9 col-12'}>
                    <input
                        type={'text'}
                        name={fieldName}
                        className={[
                            'form-control-sm',
                            'form-control',
                            facilityStepOneAddFormik.errors.facilityWebsite && 'border-danger'
                        ].join(' ')}
                        placeholder={strings.fmt?.text.facility.add.steps['1'].website}
                        value={facilityStepOneAddFormik.values.facilityWebsite}
                        id={IDS.FACILITY.ADD.WEBSITE}
                        onChange={facilityStepOneAddFormik.handleChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') { facilityStepOneAddFormik.handleSubmit() }
                        }}
                        required
                    />
                    <div className={'form-text error'}>{
                        facilityStepOneAddFormik.errors.facilityWebsite
                            ? facilityStepOneAddFormik.errors.facilityWebsite
                            : null
                    }</div>
                </div>

            </div>

        </div>

        return result
    }, [

        strings,
        facilityStepOneAddFormik.values.facilityWebsite,
        facilityStepOneAddFormik.errors.facilityWebsite
    ])

    const StepOneContent = <div id={'step-one-content'}>
        <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text.facility.menu.facility_data}</h2>
        {/* group one */}
        <div className={'mb-6'}>
            <h4 className={'mb-6'}>{strings.fmt?.text.facility.menu.name_and_address_details}</h4>
            {FacilityNameInput}
            {PostalCodeInput}
            {AddressInput}
            {StreetNameInput}
            {CityInput}
        </div>
        {/* group two */}

        <div>
            <h4 className={'mb-6'}>{strings.fmt?.text.facility.menu.contact_details}</h4>
            {GeneralPhoneNumberInput}
            {EmailInput}
            {WebsiteInput}
        </div>
    </div>

    const result = <div>
        {StepOneContent}
    </div>
    return <>{result}</>
}

export default StepOne
