import { useMemo, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import ActionsInApp from '@doc/components/patients/details/navigation/ActionsInApp'
import ComparisonChartInterface from
    '@doc/components/patients/details/components/patientProgress/Main'
import Abnormalities from '@doc/components/patients/details/ikherstelRecords/Abnormalities'
import FullAdviceModal from '@doc/components/patients/details/modals/FullAdviceModal'
import {
    GetPatientAdvisoryResponse,
    GetPatientDeviationsResponse,
    GraphInfo,
    PatientActivityData
} from '@doc/type'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Overlay from '@app/components/Overlay'

interface ComponentProps {
    floatingUiBoundary: HTMLDivElement | null,
    getPatientActivityMutation: {
        isLoading: boolean
        isSuccess: boolean
    }
    getPatientDeviationsMutation: {
        status: 'OK' | 'NOT_OK' | 'NOT_ACTIVE';
        message: string;
        isLoading: boolean
        isSuccess: boolean
    }
    patientActivityData: PatientActivityData[],
    patientChartData: GraphInfo[]
    patientAdvisoryData: GetPatientAdvisoryResponse['data'] | undefined
    patientDeviationsData: GetPatientDeviationsResponse['data'] | undefined
}
const IkherstelRecords = ({
    floatingUiBoundary,
    getPatientActivityMutation, getPatientDeviationsMutation,
    patientActivityData, patientChartData,
    patientAdvisoryData, patientDeviationsData
} : ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    /** display list of patients here with the data table template. */
    const [showAdviceModal, setShowAdviceModal] = useState(false)

    const abnormalities = <Abnormalities
        isLoading={getPatientDeviationsMutation.isLoading}
        isSuccess={getPatientDeviationsMutation.isSuccess}
        patientDeviationsData={patientDeviationsData}
    />

    const advice = <div className={'course-interface h-100'}>

        <div className={'container'}>
            <div className={'row  align-items-center advice-container'}>
                <div className={'col-auto'}>
                    <i className={'fas fa-stethoscope'}></i>
                </div>
                <div className={'col flex-column'}>
                    <div className={'title'}>{
                        patientAdvisoryData?.advisoryData.header
                    }</div>
                    <div className={'subtitle'}>{
                    }</div>
                </div>
            </div>
        </div>

        <div className={'mb-2'}>
            <span className={'d-inline-block fw-lighter mb-1 mt-3'}>
                { patientAdvisoryData?.advisoryData.body}
            </span>
        </div>

        <button
            type={'button'}
            disabled={true}
            className={'btn btn-primary w-100'}
            onClick={ () => {
                // show modal for advice.
                setShowAdviceModal(true)
            }}>
            {strings.doc?.text.patient
                .ikherstel_records.advice.view_full_advice}
        </button>

    </div>

    const ikherstelRecords = <div>
        <div className={'row mt-5 g-3'}>
            <div className={'col-12 col-lg-8'}>
                {
                    getPatientDeviationsMutation.status === 'NOT_ACTIVE'
                        ? <Overlay
                            component={abnormalities}
                            text={getPatientDeviationsMutation.message || ''}
                        />
                        : abnormalities
                }
            </div>
            <div className={'col-12 col-lg-4'}>
                {advice}
            </div>
        </div>
        <div className={'row mt-5'}>
            <div className={'col'}>
                {<ComparisonChartInterface
                    floatingUiBoundary={floatingUiBoundary}
                />}
            </div>
        </div>
        <div className={'row mt-5'}>
            <div className={'col'}>
                {<ActionsInApp
                    floatingUiBoundary={floatingUiBoundary}
                    getPatientActivityMutation={getPatientActivityMutation}
                    patientActivityData={patientActivityData}
                    patientChartData={patientChartData || []}
                />}
            </div>
        </div>
    </div>

    const detailedModal = useMemo(() => {
        return showAdviceModal && <Modal
            size={'lg'}
            unmountOnClose={false}
            isOpen={showAdviceModal !== null} toggle={() => {
                setShowAdviceModal(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                setShowAdviceModal(false)
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    setShowAdviceModal(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <FullAdviceModal/>
            </ModalBody>
        </Modal>
    }, undefined)

    return <>
        {ikherstelRecords}
        {detailedModal}
    </>
}

export default IkherstelRecords
