import { getToken, isSupported, Messaging, onMessage } from 'firebase/messaging'

export const getTokenForReal: (messaging: Messaging, userAgent: string)
=> Promise<string | false | undefined> = async (messaging, userAgent) => {
    if (await isSupported() !== true) {
        console.error("Browser doesn't have all required APIs for push notifs.")
        return undefined
    } else {
        console.info('Browser HAS all required APIs for push notifs.')
    }

    // edg not edge.
    const isEdge = /Edg\//.test(userAgent)

    if (isEdge) {
        console.log('Running in Microsoft Edge browser. return false because getToken' +
        ' is not called due to score system from edge browsers allowing notification prompts.')
        return false
    } else {
        console.log('Not running in Microsoft Edge browser. continue getting token')
    }

    // Alternatively, you can provide an existing service worker to the SDK
    // through getToken(): Promise<string>.
    // gets a service worker by default this way.still have to provide it somewhere.
    return getToken(messaging, {
        vapidKey:
         'BLmTLM7oBnbH3lztaHFHBmpIreHlUhhL9FM0TGS1YlpU-wMwSw3oF0-Ryut_1WPVQsdE6vMk3K8cLvy7MkwNsGo'
    }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken)
            return currentToken
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.')
            return false
        // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
        // catch error while creating client token
        return undefined
    })
}

export const onMessageListener = (messaging: Messaging) =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
