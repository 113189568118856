import { MODULE_TABLE } from '@app/app.config'
import {
    RegisterValues,
    RegisterKeys,
    ReviewAgainValues,
    ReviewAgainKeys,
    UpdateTreatmentValues,
    UpdateTreatmentKeys,
    MfaValues,
    MfaKeys
} from '@registration/type'
import { ObjectSchema, AnyObject } from 'yup'
import * as Yup from 'yup'
/** This is the name of the module. */
export const MODULE_NAME = {
    LONG: MODULE_TABLE.registration.moduleName,
    SHORT: MODULE_TABLE.registration.moduleShortName
}

/** This is the major version number of the module. */
export const MODULE_MAJOR_VERSION = 1

/** This is the minor version number of the module. */
export const MODULE_MINOR_VERSION = 1

/** This is the hotfix version number of the module. */
export const MODULE_HOTFIX_VERSION = 3

/** This is the full version string of the module,
 * based on the major, minor, and hotfix versions. */
export const MODULE_VERSION = [
    MODULE_NAME.SHORT, ' ',
    `v.${ MODULE_MAJOR_VERSION }.${ MODULE_MINOR_VERSION }.${ MODULE_HOTFIX_VERSION }`
].join('')
export const REGISTER_FORMIK_INITIAL_VALUES: RegisterValues = {
    username: '',
    password: '',
    repeatPassword: ''
}

export const REGISTER_VALIDATION_SCHEMA: (
    usernameValidationMessage: string,
    emptyMessage: string,
    passwordMatchMessage: string,
) => ObjectSchema<
RegisterValues,
AnyObject,
Record<RegisterKeys, undefined>
> = (usernameValidationMessage, emptyMessage, passwordMatchMessage) => Yup.object({
    username: Yup.string()
        .required(emptyMessage)
        // .matches(/^[a-zA-Z0-9]+$/, usernameValidationMessage),
        .matches(/[^'"`]+/, usernameValidationMessage),
    password: Yup.string().required(emptyMessage),
    repeatPassword: Yup.string()
        .required(emptyMessage)
        .oneOf([Yup.ref('password'), ''], passwordMatchMessage)
})

export const IDS = {
    REGISTER: {
        FORM: 'REGISTER__FORM',
        USERNAME: 'REGISTER__USERNAME',
        PASSWORD: 'REGISTER__PASSWORD',
        REPEAT_PASSWORD: 'REGISTER__REPEAT_PASSWORD'
    },
    REVIEW_AGAIN: {
        FORM: 'REVIEW_AGAIN__FORM',
        FIRST_NAME: 'REGISTER__FIRST_NAME',
        LAST_NAME: 'REGISTER__LAST_NAME',
        EMAIL: 'REGISTER__EMAIL',
        DATE_OF_BIRTH: 'REVIEW_AGAIN__DATE_OF_BIRTH'
    },
    MAKE_CAREPLAN: {
        FORM: 'MAKE_CAREPLAN__FORM',
        OK_DATE: 'MAKE_CAREPLAN__OK_DATE',
        UNKNOWN_DATE: 'MAKE_CAREPLAN__UNKNOWN_DATE'
    },
    UPDATE_TREATMENT: {
        FORM: 'UPDATE_TREATMENT__FORM',
        TREATMENT_ID: 'UPDATE_TREATMENT__TREATMENT_ID',
        OK_DATE: 'UPDATE_TREATMENT__OK_DATE',
        UNKNOWN_DATE: 'UPDATE_TREATMENT__UNKNOWN_DATE'
    },
    MFA: {
        FORM: 'MFA__FORM',
        PIN_CODE: 'MFA__PIN_CODE'
    }
}

export const PASSWORD_STRENGTH_LIMIT = 7

export const REVIEW_AGAIN_FORMIK_INITIAL_VALUES: ReviewAgainValues = {
    firstName: '',
    lastName: '',
    email: ''
    // dateOfBirth: getUnixTime(new Date())
}

export const REVIEW_AGAIN_VALIDATION_SCHEMA: (
    emailMessage: string,
    emptyMessage: string,
) => ObjectSchema<
ReviewAgainValues,
AnyObject,
Record<ReviewAgainKeys, undefined>
> = (emailMessage, emptyMessage) => Yup.object({
    firstName: Yup.string().required(emptyMessage),
    lastName: Yup.string().required(emptyMessage),
    email: Yup.string()
        .email(emailMessage)
        .required(emptyMessage)
    // dateOfBirth: Yup.number()
    //     .required(emptyMessage)
})

export const UPDATE_TREATMENT_FORMIK_INITIAL_VALUES: UpdateTreatmentValues = {
    treatmentId: '',
    careplanId: '',
    startDate: undefined,
    isUnknownChecked: false
}

export const UPDATE_TREATMENT_VALIDATION_SCHEMA: (
    emptyMessage: string,
    invalidMessage: string,
) => ObjectSchema<
UpdateTreatmentValues,
AnyObject,
Record<UpdateTreatmentKeys, undefined>
> = (emptyMessage, invalidMessage) => Yup.object({
    treatmentId: Yup.string().required(emptyMessage),
    careplanId: Yup.string().required(emptyMessage),
    startDate: Yup.number()
        .test('isValidDate', invalidMessage, (value) => {
            //  we have to allow undefined and numerical values.
            if (value === undefined || typeof value === 'number') {
                return true
            }

            return false
        }),
    isUnknownChecked: Yup.boolean().required()
})

export const MFA_FORMIK_INITIAL_VALUES: MfaValues = {
    pinCode: ''
}
export const MFA_VALIDATION_SCHEMA: (
    emptyMessage: string,
) => ObjectSchema<
MfaValues,
AnyObject,
Record<MfaKeys, undefined>
> = (emptyMessage) => Yup.object({
    pinCode: Yup.string().required(emptyMessage)
})
