import { END_USER_COLORS } from '@stylesheet/brands/rtw/enduser/Colors'
import { RecoveryPlanConfig } from '@stylesheet/config/endUser/RecoveryPlan'

export const RECOVERY_PLAN_CONFIG: RecoveryPlanConfig = {
    floatingUiProgress: {
        background: END_USER_COLORS.darkBlue,
        arrowColor: END_USER_COLORS.darkBlue,
        textColor: END_USER_COLORS.white
    },
    bodymapFloatingUi: {
        background: END_USER_COLORS.gray_700,
        arrowColor: END_USER_COLORS.gray_700,
        textColor: END_USER_COLORS.white
    },
    headerGradientColor: END_USER_COLORS.headerGradientColor,
    cardImageWrapper: {
        background: END_USER_COLORS.gray_100
    },
    selected: {
        background: END_USER_COLORS.yellow1
    },
    offcanvas: {
        color: END_USER_COLORS.darkBlue,
        formControl: {
            borderColor: END_USER_COLORS.gray_200
        },
        formCheck: {
            input: {
                borderColor: END_USER_COLORS.gray_200
            }
        },
        reactDatepickerWrapper: {
            input: {
                color: END_USER_COLORS.darkBlue
            }
        },
        btnPrimary: {
            btnColor: END_USER_COLORS.white,
            btnBg: END_USER_COLORS.darkBlue,
            btnBorderColor: END_USER_COLORS.darkBlue,
            btnHoverColor: END_USER_COLORS.darkGrey_2,
            btnHoverBg: END_USER_COLORS.darkBlueSelected,
            btnHoverBorderColor: END_USER_COLORS.darkBlueSelected,
            btnActiveColor: END_USER_COLORS.white,
            btnActiveBg: END_USER_COLORS.darkBlueSelected,
            btnActiveBorderColor: END_USER_COLORS.darkBlueSelected,
            btnActiveShadow: END_USER_COLORS.shadowColor1,
            btnDisabledColor: END_USER_COLORS.white,
            btnDisabledBg: END_USER_COLORS.darkBlue,
            btnDisabledBorderColor: END_USER_COLORS.darkBlue
        },
        btnSecondary: {
            btnBorderColor: END_USER_COLORS.completion.locked,
            mainBorderColor: END_USER_COLORS.gray_200
        }
    },
    mainContent: {
        greyText: {
            color: END_USER_COLORS.gray_300
        },
        groupCount: {
            background: END_USER_COLORS.gray_200
        },
        card: {
            bsCardBg: END_USER_COLORS.white,
            careplanCard: {
                cardBody: {
                    progressBarBg: END_USER_COLORS.yellow1
                },
                completionStatusFlag: {
                    color: END_USER_COLORS.white,
                    background: END_USER_COLORS.black,
                    complete: {
                        background: END_USER_COLORS.completion.complete
                    },
                    locked: {
                        background: END_USER_COLORS.completion.locked,
                        color: END_USER_COLORS.darkGrey_2
                    },
                    incomplete: {
                        background: END_USER_COLORS.completion.incomplete
                    }
                }
            },
            statusCard: {
                color: END_USER_COLORS.white,
                background: END_USER_COLORS.darkBlue
            },
            progressCard: {
                recoveryBarBg: END_USER_COLORS.gray_200,
                actualRecoveryBarBg: END_USER_COLORS.yellow1,
                expectedRecoveryBarBg: END_USER_COLORS.cardTypes.notification
            },
            todayCard: {
                mainRecoveryBarBg: END_USER_COLORS.yellow1,
                progressDivider: {
                    borderRightColor: END_USER_COLORS.gray_200,
                    borderLgEnd: {
                        borderRightColor: END_USER_COLORS.gray_200
                    }
                },
                stepTypeLabel: {
                    background: END_USER_COLORS.gray_50
                },
                stepTypeRecoveryBarBg: END_USER_COLORS.yellow1
            }
        },
        contentType: {
            activity: {
                color: END_USER_COLORS.cardTypes.activity
            },
            content: {
                color: END_USER_COLORS.cardTypes.content
            },
            lesson: {
                color: END_USER_COLORS.cardTypes.lesson
            },
            reasoning: {
                color: END_USER_COLORS.cardTypes.reasoning
            }
        }
    },
    libraryPage: {
        mainContent: {
            greyText: {
                color: END_USER_COLORS.gray_300
            }
        }
    }

}
