import React, { ReactNode, useEffect, useMemo } from 'react'

// import bootstrap.css

// then import the other npm module css.
import '@stylesheet/other-css-imports'
import { Global, ThemeProvider } from '@emotion/react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectToken } from '@app/slices/slice.token'

import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { selectModuleOwner, selectStrings } from '@app/slices/slice.app'
import { selectStylesheets, setStylesheetModule } from '@stylesheet/slice'
import { Brands, ModuleName, StylesheetState, ThemeName } from '@stylesheet/type'
import { toast } from 'react-toastify'
import {
    THEME_CONFIG as IKH_ENDUSER_THEME_CONFIG_LIGHT
} from '@stylesheet/brands/ikh/enduser/themes/light/MasterConfig'
import {
    THEME_CONFIG as IKH_ADMIN_THEME_CONFIG_LIGHT
} from '@stylesheet/brands/ikh/admin/themes/light/MasterConfig'
import {
    THEME_CONFIG as IKH_ADMIN_THEME_CONFIG_DARK
} from '@stylesheet/brands/ikh/admin/themes/dark/MasterConfig'
import {
    THEME_CONFIG as IKH_ENDUSER_THEME_CONFIG_DARK
} from '@stylesheet/brands/ikh/enduser/themes/dark/MasterConfig'
import {
    THEME_CONFIG as RTW_ENDUSER_THEME_CONFIG_LIGHT,
    THEME_CONFIG as RTW_ENDUSER_THEME_CONFIG_DARK
} from '@stylesheet/brands/rtw/enduser/themes/light/MasterConfig'
import {
    THEME_CONFIG as RTW_ADMIN_THEME_CONFIG_LIGHT,
    THEME_CONFIG as RTW_ADMIN_THEME_CONFIG_DARK
} from '@stylesheet/brands/rtw/admin/themes/light/MasterConfig'

import CareProfessionalDashboard from
    '@stylesheet/globalStyles/group/admin/CareProfessionalDashboard'
import FacilityManagementAdmin from '@stylesheet/globalStyles/group/admin/FacilityManagement'
import AnswerSummaryAdmin from '@stylesheet/globalStyles/group/admin/AnswerSummary'
import Sidebar from '@stylesheet/globalStyles/Sidebar'
import BootstrapOverrides from '@stylesheet/globalStyles/bootstrap-overrides'
import GlobalStyles from '@stylesheet/globalStyles/global-styles'
import CareplanStepGlobalStyles from
    '@stylesheet/globalStyles/group/endUser/careplanStep/globalStyles'
import LoginGlobalStyles from '@stylesheet/globalStyles/group/endUser/login/globalStyles'
import ProfilePageGlobalStyles from
    '@stylesheet/globalStyles/group/endUser/profilePage/globalStyles'
import ReasonWithMeGlobalStyles from
    '@stylesheet/globalStyles/group/endUser/reasonWithMe/globalStyles'
import RegistrationGlobalStyles from
    '@stylesheet/globalStyles/group/endUser/registration/globalStyles'
import RecoveryPlanGlobalStyles from
    '@stylesheet/globalStyles/group/endUser/recoveryPlan/globalStyles'

const StylesheetManager: React.FC<{
    children: ReactNode;
  }> = (props) => {
      // now you want the token data here.
      const dispatch = useAppDispatch()
      const token = useAppSelector(selectToken)
      const strings = useAppSelector(selectStrings)
      const stylesheets = useAppSelector(selectStylesheets)
      const moduleOwner = useAppSelector(selectModuleOwner)

      //   useEffect(() => {
      //       console.log('is using stylesheet: ', isUsingStylesheet)
      //   }, [isUsingStylesheet])

      useEffect(() => {
          // show warning toast message.
          if (token.details.ss && Boolean(token.details.ss?.endUser) === false) {
              const message = strings.app?.message.error.stylesheet.missing_stylesheet.end_user
              console.warn(message)
              toast.warn(message, { ...TOASTIFY_DEFAULT_OPTIONS })
          }

          if (token.mode === 'auth') {
              if (token.details.ss && Boolean(token.details.ss?.admin) === false) {
                  const message = strings.app?.message.error.stylesheet.missing_stylesheet.admin
                  console.warn(message)
                  toast.warn(message, { ...TOASTIFY_DEFAULT_OPTIONS })
              }
          }
      }, [token.details.ss?.endUser])

      const parseStylesheetState = (input: string) => {
          const [brandTheme, moduleName, themeName] = input.split('-')

          if (!brandTheme || !moduleName || !themeName) {
              // do nothing.
              console.error('ss string format is incorrect')
          }

          const moduleNameTyped = moduleName as ModuleName
          const brandLowerTyped = brandTheme as Brands
          const themeLowerTyped = themeName as ThemeName
          //   const themeLowerTyped = 'dark' as ThemeName

          dispatch(setStylesheetModule({
              module: moduleNameTyped,
              brand: brandLowerTyped,
              theme: themeLowerTyped
          }))
      }

      // we do things new now.
      // based on token.details.ss.endUser AND token.details.ss.admin.
      useEffect(() => {
          // assign a default string cause for testing, you want something nice.
          parseStylesheetState(token.details.ss?.endUser || 'ikh-enduser-light')
          parseStylesheetState(token.details.ss?.admin || 'ikh-admin-light')
      }, [
          token.details.ss?.endUser,
          token.details.ss?.admin
      ])

      useEffect(() => {
          console.log('stylesheets used: ', stylesheets)
      }, [stylesheets])

      // now create the theme object for the theme provider
      // first thing to do is check on the brand.
      // then check on the theme. no need to check on the module name
      // that's for something else. we just want to pass the colors in.

      const getThemeConfig = useMemo(() => {
          console.log('setting up config')
          // IF NOTHING IS PROVIDED. ikh resources will be used by default (light).
          const defaults = {
              global: IKH_ENDUSER_THEME_CONFIG_LIGHT.global,
              bootstrap: IKH_ENDUSER_THEME_CONFIG_LIGHT.bootstrap,
              endUser: IKH_ENDUSER_THEME_CONFIG_LIGHT.endUser,
              admin: IKH_ADMIN_THEME_CONFIG_LIGHT.admin
          }

          const adminTheme: StylesheetState['admin'] = stylesheets.admin
          const endUserTheme: StylesheetState['enduser'] = stylesheets.enduser
          if (moduleOwner === 'enduser') {
              if (endUserTheme?.brand === 'ikh') {
                  if (endUserTheme.theme === 'light') {
                      defaults.global = IKH_ENDUSER_THEME_CONFIG_LIGHT.global
                      defaults.bootstrap = IKH_ENDUSER_THEME_CONFIG_LIGHT.bootstrap
                  }

                  if (endUserTheme.theme === 'dark') {
                      defaults.global = IKH_ENDUSER_THEME_CONFIG_DARK.global
                      defaults.bootstrap = IKH_ENDUSER_THEME_CONFIG_DARK.bootstrap
                  }
              } else if (endUserTheme?.brand === 'rtw') {
                  if (endUserTheme.theme === 'light') {
                      defaults.global = RTW_ENDUSER_THEME_CONFIG_LIGHT.global
                      defaults.bootstrap = RTW_ENDUSER_THEME_CONFIG_LIGHT.bootstrap
                  }
                  if (endUserTheme.theme === 'dark') {
                      defaults.global = RTW_ENDUSER_THEME_CONFIG_DARK.global
                      defaults.bootstrap = RTW_ENDUSER_THEME_CONFIG_DARK.bootstrap
                  }
              }
          } else if (moduleOwner === 'admin') {
              if (adminTheme?.brand === 'ikh') {
                  if (adminTheme.theme === 'light') {
                      defaults.global = IKH_ADMIN_THEME_CONFIG_LIGHT.global
                      defaults.bootstrap = IKH_ADMIN_THEME_CONFIG_LIGHT.bootstrap
                  }
                  if (adminTheme.theme === 'dark') {
                      defaults.global = IKH_ADMIN_THEME_CONFIG_DARK.global
                      defaults.bootstrap = IKH_ADMIN_THEME_CONFIG_DARK.bootstrap
                  }
              } else if (adminTheme?.brand === 'rtw') {
                  if (adminTheme.theme === 'light') {
                      defaults.global = RTW_ADMIN_THEME_CONFIG_LIGHT.global
                      defaults.bootstrap = RTW_ADMIN_THEME_CONFIG_LIGHT.bootstrap
                  }
                  if (adminTheme.theme === 'dark') {
                      defaults.global = RTW_ADMIN_THEME_CONFIG_DARK.global
                      defaults.bootstrap = RTW_ADMIN_THEME_CONFIG_DARK.bootstrap
                  }
              }
          }

          // then set admin and endUser config independently.
          if (endUserTheme?.brand === 'ikh') {
              if (endUserTheme.theme === 'light') {
                  defaults.endUser = IKH_ENDUSER_THEME_CONFIG_LIGHT.endUser
              }
              if (endUserTheme.theme === 'dark') {
                  defaults.endUser = IKH_ENDUSER_THEME_CONFIG_DARK.endUser
              }
          } else if (endUserTheme?.brand === 'rtw') {
              if (endUserTheme.theme === 'light') {
                  defaults.endUser = RTW_ENDUSER_THEME_CONFIG_LIGHT.endUser
              }
              if (endUserTheme.theme === 'dark') {
                  defaults.endUser = RTW_ENDUSER_THEME_CONFIG_DARK.endUser
              }
          }

          if (adminTheme?.brand === 'ikh') {
              if (adminTheme.theme === 'light') {
                  defaults.admin = IKH_ADMIN_THEME_CONFIG_LIGHT.admin
              }
              if (adminTheme.theme === 'dark') {
                  defaults.admin = IKH_ADMIN_THEME_CONFIG_DARK.admin
              }
          } else if (adminTheme?.brand === 'rtw') {
              if (adminTheme.theme === 'light') {
                  defaults.admin = RTW_ADMIN_THEME_CONFIG_LIGHT.admin
              }
              if (adminTheme.theme === 'dark') {
                  defaults.admin = RTW_ADMIN_THEME_CONFIG_DARK.admin
              }
          }

          return defaults
      }, [
          stylesheets.admin,
          stylesheets.enduser,
          moduleOwner
      ])

      // memoize global styles.
      const memoizedGlobalStyles = useMemo(() => {
          console.log('rendering global styles on mount')
          // load based on what stylesheet you are in atm.
          return <>

              {
                  moduleOwner === 'enduser' && <>
                      <Global styles={CareplanStepGlobalStyles} />
                      <Global styles={LoginGlobalStyles} />
                      <Global styles={ProfilePageGlobalStyles} />
                      <Global styles={ReasonWithMeGlobalStyles} />
                      <Global styles={RegistrationGlobalStyles} />
                      <Global styles={RecoveryPlanGlobalStyles} />
                  </>
              }

              {
                  moduleOwner === 'admin' && <>
                      <Global styles={CareProfessionalDashboard} />
                      <Global styles={FacilityManagementAdmin} />
                      <Global styles={AnswerSummaryAdmin} />
                  </>
              }

              <Global styles={Sidebar} />
              <Global styles={BootstrapOverrides} />
              <Global styles={GlobalStyles} />

          </>
      }, [
          getThemeConfig,
          moduleOwner
      ])

      return <ThemeProvider theme={{
          config: getThemeConfig
      }}>
          {/* WARNING: for performance purposes, RENDER ALL THE SHEETS AT THE START BUT:
            *  make sure that, :root has unique values in all global styles since they will
            * merge into one <style> class /</style> */}
          {memoizedGlobalStyles}
          {props.children}

      </ThemeProvider>
  }
export default StylesheetManager
