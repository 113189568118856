
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { ReasonWithMeState, QuestionInterfaceActions } from '@reasonWithMe/type'
import { setAnswerValue } from '@reasonWithMe/slice'
import { useMemo } from 'react'
import { selectStrings } from '@app/slices/slice.app'
import _ from 'lodash'
import { TokenData } from '@app/types/type.token'
import { selectToken } from '@app/slices/slice.token'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>,
    goToNextQuestion: (token: TokenData) => Promise<void>
}

const FloatInput = ({
    questionInterface,
    componentDispatch,
    goToNextQuestion
}: ComponentProps) => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            // now make the next call.
            goToNextQuestion(token)
        }
    }

    const input = useMemo(() => {
        // render an input box within a range
        const data = questionInterface.currentReasonWithMeResponse.reasoningData

        const ranges = data.question?.questionAnswers[0]
        const lower = ranges?.rangeLower || 0
        const upper = ranges?.rangeUpper || 0

        return <div className={'question-float-input'}>
            <input
                className={'form-control form-control-sm'}
                // errors={_.inRange(answerValue, lower, upper) === false}
                type={'number'}
                placeholder={_.has(ranges, 'answerInner')
                    ? ranges?.answerInner
                    : strings.reason_with_me?.text.float_input.placeholder}
                // defaultValue={0}
                // value={answerValue}
                onChange={(e) => {
                    if (componentDispatch !== undefined) {
                        componentDispatch({
                            type: 'SET_ANSWER_VALUE',
                            value: e.target.value
                        })
                    } else {
                        dispatch(setAnswerValue(e.target.value))
                    }
                }}
                onKeyDown={handleKeyDown}
            />
            <small>
                {`${ strings.reason_with_me
                    ?.text.float_input.range || '' } ${ lower } - ${ upper }`}
            </small>
            <small className={'ps-2'}>{strings.reason_with_me
                ?.text.float_input.disclaimer || ''}</small>
        </div>
    }, [strings, questionInterface])

    return input
}

export default FloatInput
