import { MODULE_TABLE } from '@app/app.config'
import { FIREBASE_CONFIG, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getTokenForReal, onMessageListener } from '@app/components/FirebaseController/method'
import { selectActiveModules, selectFirebaseToken, setFirebaseToken } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { MessagePayload, getMessaging } from 'firebase/messaging'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useUpdateSubscriberIdMutation } from '@login/api'
import _ from 'lodash'

import { initializeApp } from 'firebase/app'

// Initialize Firebase
// console.log('this is called.')
const app = initializeApp(FIREBASE_CONFIG)

// Initialize Firebase Cloud Messaging and get a reference to the service
// this is asynchronous i think.
const firebaseMessaging = getMessaging(app)

export const App = () => {
    const dispatch = useAppDispatch()
    const [notification, setNotification] = useState({
        title: '',
        body: ''
    })
    const firebaseToken = useAppSelector(selectFirebaseToken)
    const [isTokenFound, setTokenFound] = useState<boolean | undefined>()
    const token = useAppSelector(selectToken)

    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const activeModules = useAppSelector(selectActiveModules)

    const [
        updateSubscriberId,
        updateSubscriberIdMutation
    ] = useUpdateSubscriberIdMutation()

    useEffect(() => {
        if (firebaseToken === null || firebaseToken === undefined) {
            console.log('no firebase token found in localStorage cause you emptied the storage')
        } else {
            setTokenFound(true)
        }
    }, [firebaseToken])

    onMessageListener(firebaseMessaging).then((payload) => {
        const messagePayload = payload as MessagePayload
        console.log('received message foreground')
        setNotification({
            title: messagePayload.notification?.title || '',
            body: messagePayload.notification?.body || ''
        })
        console.log(payload)
    }).catch((err: any) => console.log('failed: ', err))

    const showTestMessage = () => {
        toast.success(<div>
            <strong className={'mr-auto'}>{notification.title}</strong>
            <small>{notification.body}</small>
        </div>, { ...TOASTIFY_DEFAULT_OPTIONS })
    }

    // works on mount.
    useEffect(() => {
        // Create a new Broadcast Channel with the same name as in the service worker
        const channel = new BroadcastChannel('background-message')

        // Listen for messages from the service worker
        channel.onmessage = (event) => {
            const payload = event.data
            console.log('Received background message in app:', payload)

            // Handle the background message payload here
        }

        // Clean up the channel on component unmount
        return () => {
            channel.close()
        }
    }, [])

    // ideally should be performed after authentication AND if the user has retrieved
    // their getModules. use activeModules.id as the dependency.
    useEffect(() => {
        if (token.mode === 'auth' && activeModules.arr.length) {
            console.log('for registration token for user: ', token.details.sub)

            const call = async () => {
                const subscriberId = await getTokenForReal(firebaseMessaging, navigator.userAgent)

                if (_.isString(subscriberId)) {
                    if (token.valid) {
                        const newToken = await revalidateToken({
                            value: token.value,
                            id: token.id
                        }, token.mode)

                        const foundApiPath = validateAPIPath(
                            activeModules.arr,
                            MODULE_TABLE.login.moduleName,
                            MODULE_TABLE.login.apiPaths.updateSubscriberId.path,
                            true
                        )

                        if (foundApiPath && newToken.value) {
                            updateSubscriberId({
                                authToken: newToken.value,
                                data: {
                                    subscriberId: subscriberId || ''
                                }
                            }).unwrap().then((o) => {
                                // if ok. set the value in dispatch
                                if (o.status === 'OK') {
                                    dispatch(setFirebaseToken(subscriberId))
                                }
                            })
                        }
                    }
                }
            }
            call()
        }
    }, [activeModules.id])

    useEffect(() => {
        if (updateSubscriberIdMutation.data?.status === 'OK') {
            console.log('subscription to user is done')
        } else if (updateSubscriberIdMutation.data?.status === 'NOT_OK') {
            console.log('subscription to user is NOT_OK')
        }
    }, [updateSubscriberIdMutation.data])

    useEffect(() => {
        // if (notification.title && token.mode === 'auth') {
        if (notification.title) {
            showTestMessage()
        }
    }, [notification])

    useEffect(() => {
        if (isTokenFound === true) {
            console.log(' firebase token found in storage ')
        } else if (isTokenFound === false) {
            console.error(' NO firebase token found in storage ')
        }
    }, [isTokenFound])

    // inside the jsx being returned:
    return <></>
}

export default App
