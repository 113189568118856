import { RootState } from '@app/app.store'
import { ContentState, ReactPlayerControlState } from '@careplan/type'
import { DoctorState } from '@doc/type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: DoctorState = {
    patientMenu: {
        addInterfaceProps: {
            userId: ''
            // userId: '654e3adc2090176bdbb020dc'
            // uuid: 'fd18a57a-f513-4ff5-ae45-c29fca9d089d'
        },
        currentStep: 1,
        showAddModal: false,
        steps: []
    },
    mediaPlayers: []
}

export const slice = createSlice({
    name: 'docv10',
    initialState,
    reducers: {
        setPatientMenu: (state: DoctorState, action: PayloadAction<
            DoctorState['patientMenu']
        >) => {
            state.patientMenu = action.payload
        },
        setMediaPlayer: (state: ContentState, action: PayloadAction<{
            key: string,
            item: ReactPlayerControlState
        }>) => {
            let found = _.find(state.mediaPlayers, (obj) => {
                return obj.key === action.payload.key
            })

            if (!found) {
                // console.log('Data with specified key does not exists. Will push.')
                state.mediaPlayers.push({
                    key: action.payload.key,
                    data: action.payload.item
                })
            } else {
                // this case SHOULD only be met when invoked. not ideally done in a ref.
                // console.log('Data with specified key already exists. Will overwrite.')
                found = {
                    key: action.payload.key,
                    data: action.payload.item
                }
            }
        },
        resetDoctor: (state: DoctorState) => {
            state = initialState
        }
    }
})

export const {
    resetDoctor,
    setPatientMenu,
    setMediaPlayer
} = slice.actions

export const selectPatientMenu = (state: RootState) => state.docV10.patientMenu
export const selectMediaPlayers = (state: RootState) => state.docV10.mediaPlayers

export default slice.reducer
