import { OpenListActions, OpenListInputs, OpenListValues } from '@reasonWithMe/type'
import _ from 'lodash'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
    findPreviousInput: any,
    formsDispatch: (value: OpenListActions) => void
    form: OpenListValues,
    inputs: OpenListInputs[]
}

const Radio = ({
    answerType, outerIndex, innerIndex,
    formInput, findPreviousInput, formsDispatch, form,
    inputs
}: ComponentProps) => {
    return <label className={'custom-radio'}>
        <input
            type={'radio'}
            name={`${ answerType }-${ outerIndex }-${ innerIndex }`}
            disabled={(findPreviousInput && !findPreviousInput?.answerEditable) ||
                         !formInput.answerEditable}
            checked={formInput.userTypedAnswer as boolean}
            onChange={(e) => {
                if (formInput.answerEditable) {
                    formsDispatch({
                        type: 'UPDATE_FORM',
                        id: form.id,
                        columnOrder: Number(formInput
                            .answerValue || 0),
                        // radio/checkbox values are true/false
                        value: !formInput.userTypedAnswer
                    })

                    // also uncheck other radio boxes in the same row.
                    _.forEach(inputs, (o) => {
                        if (o.answerType === 'radio') {
                            // then check if the answerValue is not the same
                            // as the answerValue in o.
                            if (o.answerValue !== Number(formInput
                                .answerValue || 0)) {
                                formsDispatch({
                                    type: 'UPDATE_FORM',
                                    id: form.id,
                                    columnOrder: Number(o.answerValue || 0),
                                    // set to false here.
                                    value: false
                                })
                            }
                        }
                    })
                }
            }}
        />
        <span className={'checkmark'}>
            <i className={'fa-solid fa-check mx-auto'}></i>
        </span>
    </label>
}

export default Radio
