import { MODULE_TABLE } from '@app/app.config'
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import useOnScreen from '@app/hooks/useOnScreen'
import { selectActiveModules } from '@app/slices/slice.app'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    BuildingBlock,
    BuildingBlockLinkArticle,
    BuildingBlockProgress,
    BuildingBlockProgressState
} from '@careplan/type'
import { CardImageWrapper } from '@stylesheet/globalStyles/group/endUser/recoveryPlan/Components'
import _ from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    buildingBlock: BuildingBlock
    progressState: BuildingBlockProgressState
    updateBuildingBlockProgress: (requestData: {
        obj: BuildingBlockProgress;
        percentage: number;
        miscVar?: any;
    }) => Promise<void>
}

const LinkArticle = ({
    progressState, updateBuildingBlockProgress, buildingBlock
}:ComponentProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useOnScreen(ref)
    const activeModules = useAppSelector(selectActiveModules)

    const validateRoute = useValidateRoute()

    const progress = _.find(progressState.progress, (obj) => {
        return obj.buildingBlockId === buildingBlock.buildingBlockId
    })

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        if (isVisible) {
            const progress = _.find(progressState.progress, (obj) => {
                return obj.buildingBlockId === buildingBlock.buildingBlockId
            })

            if (progress) {
                // console.log('setting item to 100.')

                updateBuildingBlockProgress({
                    obj: progress,
                    percentage: 100
                })
            }
        }
    }, [isVisible, progressState.progress])

    const colImageClassName = 'col'
    const colContentClassName = 'col meta-info'
    const imageWrapperWidth = '100%'
    const imageWrapperHeight = 81

    const blockData: BuildingBlockLinkArticle = buildingBlock
        .buildingBlockValue as BuildingBlockLinkArticle

    const cardBody = <div className={'card-body position-relative'}>
        <div className={[
            'row row-cols-1', isMobile ? 'g-3' : ''
        ].join(' ')}>
            <div className={colImageClassName}>
                <CardImageWrapper className={''}
                    url={blockData?.articleThumbnail || ''}
                    style={{
                        width: imageWrapperWidth,
                        height: imageWrapperHeight
                    }}
                >
                </CardImageWrapper>
            </div>
            <div className={[
                colContentClassName,
                !isMobile ? 'mb-3 mt-3' : ''
            ].join(' ')}>
                <h6 className={'mb-0'}>
                    {blockData.articleTitle}
                </h6>
                <span>
                    {blockData.articleDescription}
                </span>
            </div>
        </div>
    </div>

    const articleRoute = useMemo(() => {
        const isValid = validateRoute(
            activeModules.arr,
            MODULE_TABLE.library.moduleName,
            MODULE_TABLE.library.routes.article,
            true
        )
        if (isValid) {
            return _.replace(isValid.route,
                ':articleId', blockData.articleId)
        }

        return ''
    }, [])

    const result = (<div
        className={'align-items-center justify-content-start row'}
        ref={ref}
    >
        <a className={'col-6 text-decoration-none text-reset link-article-card'}
            // generate route dynamically
            href={articleRoute}
            onMouseOver={() => {
                // console.log('article is now set to 100 from hover')
                if (progress) {
                    updateBuildingBlockProgress({
                        obj: progress,
                        percentage: 100
                    })
                }
            }}
            onClick={(e) => {
                // now dispatch to another page.
                e.preventDefault()
                const href = e.currentTarget.href // Get the href value
                window.open(href, '_blank') // Open the href in a new tab
                // const isValid = validateRoute(
                //     activeModules.arr,
                //     MODULE_TABLE.library.moduleName,
                //     href,
                //     true
                // )
                // if (isValid) {
                // now push but replace
                // :personalCareplanId with something else
                // dispatch(push(
                //     _.replace(isValid.route,
                //         ':articleId', blockData.articleId)
                // ))

                // }
            }}>
            {/* card-activity will be something else soon */}
            <div className={'card careplan-card'}>
                {cardBody}
            </div>

        </a>
    </div>
    )

    return result
}

export default LinkArticle
