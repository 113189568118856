import { MODULE_TABLE } from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectReturnToLink } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenAuth } from '@app/types/type.app'
import { replace } from '@lagunovsky/redux-react-router'
import { useUpdateWorkflowProgressMutation } from '@login/api'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateModule } from '@login/MutationProvider/validateModule'
import { useValidateModuleAll } from '@login/MutationProvider/validateModuleAll'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import { useValidateRouteAll } from '@login/MutationProvider/validateRouteAll'

import { } from '@login/slices/slice.login'
import {
    resetWorkflow,
    selectCurrentWorkflowStep,
    setCurrentWorkflowStep,
    setCurrentWorkflowStepProgress,
    setModuleId,
    setWorkflowCount,
    setWorkflowId
} from '@login/slices/slice.workflow'
import {
    GetWorkflowRequest,
    GetWorkflowResponse,
    UpdateWorkflowProgressRequest,
    UpdateWorkflowProgressResponse
} from '@login/type'
import {
    setAnswerValue,
    setCurrentReasoningData,
    setCurrentReasoningResults,
    setReasoningSet
} from '@reasonWithMe/slice'
import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    MutationDefinition
} from '@reduxjs/toolkit/dist/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import _ from 'lodash'
import { useEffect } from 'react'

// Define the prop type for getWorkflow mutation
type GetWorkflowMutation = MutationTrigger<
  MutationDefinition<
    GetWorkflowRequest & TokenAuth,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    never,
    GetWorkflowResponse,
    'loginV10Api'
  >
>;

type UpdateWorkflowProgressMutation = MutationTrigger<
    MutationDefinition<
    UpdateWorkflowProgressRequest & TokenAuth,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    never, UpdateWorkflowProgressResponse,
    'loginV10Api'>
>;

const GetWorkflowConsumer = ({ updateWorkflowProgress, getWorkflow, data, originalArgs }: {
    updateWorkflowProgress: UpdateWorkflowProgressMutation,
    getWorkflow: GetWorkflowMutation,
    data: GetWorkflowResponse | undefined,
    originalArgs: (GetWorkflowRequest & TokenAuth) | undefined
}) => {
    const dispatch = useAppDispatch()
    /** unfortunately, it's best to put updateWorkflowProgress in the components
     * that need it.
     */

    const activeModules = useAppSelector(selectActiveModules)

    const validateRoute = useValidateRoute()
    const validateRouteAll = useValidateRouteAll()
    const validateModule = useValidateModule()
    const validateModuleAll = useValidateModuleAll()
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const token = useAppSelector(selectToken)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const returnToLink = useAppSelector(selectReturnToLink)

    // make updateWorkflowProgress but name it fixWorkflowStep instead.
    const [
        fixWorkflowStep
    ] = useUpdateWorkflowProgressMutation()

    const fixSteps = async (
        data: GetWorkflowResponse,
        originalArgs: (GetWorkflowRequest & TokenAuth) | undefined
    ) => {
        // new loophole found. has workflow steps but the data is complete
        // check if THE LAST STEP is 100.

        // for debugging purposes only
        // if the last workflow step is done but if for some reasons,
        // previous steps are not, we'll  need to be strict this time around.
        if (data.data?.workflowSteps?.length && originalArgs) {
            const lastStep = _.last(data.data.workflowSteps)

            const allAreComplete = _.every(data.data.workflowSteps, (o) => {
                return o?.stepProgress.percentComplete === 100
            })

            if (lastStep?.stepProgress.percentComplete === 100 && allAreComplete &&
                    originalArgs?.data?.moduleId) {
                console.log(['call has moduleId, and last step are complete.',
                    'get the last step and mark as complete again'].join(' '))

                await Promise.all(
                    data.data.workflowSteps.map(o => {
                        const promise = fixWorkflowStep({
                            authToken: token.value,
                            data: {
                                stepId: o.stepId,
                                workflowId: data?.data?.workflowId || '',
                                percentComplete: 100
                            }
                        }).unwrap()

                        return promise
                    })
                )

                // set to 100%
                dispatch(setCurrentWorkflowStepProgress(100))
            }
        }
    }

    useEffect(() => {
        // ONLY WORKS on Login V1.0
        if (data && activeModules.arr.length) {
            // check if there is a workflow step to set first.

            const redirectAction = () => {
                // redirect them to the route.
                const foundRoute = validateRouteAll(
                    activeModules.arr, token.details.roid, false
                )

                // reset workflow data as you end up navigating elsewhere.
                dispatch(resetWorkflow())

                if (foundRoute) {
                    console.log('defaultRoute after validation: ', foundRoute)
                    dispatch(replace(token.details.roid))
                } else {
                    // if all else fails, go to the login route.
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.routes.login,
                        true
                    )
                    if (isValid) {
                        dispatch(replace(token.details.roid))
                    }
                }
            }

            const redirectBackAction = () => {
                // if not defined, use default path instead.,
                let routeToUse = validateRouteAll(
                    activeModules.arr,
                    returnToLink,
                    false
                )

                if (!routeToUse.route) {
                    console.log('no recent route? go to default route instead')
                    routeToUse = validateRouteAll(
                        activeModules.arr,
                        token.details.roid,
                        false
                    )
                    if (routeToUse.route) {
                        console.log('designated route after validation: ', token.details.roid)
                        dispatch(replace(token.details.roid))
                    } else {
                        // if all else fails, go to the login route.
                        const isValid = validateRoute(
                            activeModules.arr,
                            MODULE_TABLE.login.moduleName,
                            MODULE_TABLE.login.routes.login,
                            true
                        )
                        if (isValid) {
                            dispatch(replace(isValid.route))
                        }
                    }
                } else {
                    console.log('designated route after validation: ', returnToLink)
                    dispatch(replace(returnToLink))
                }

                // reset workflow data as you end up navigating elsewhere.
                dispatch(resetWorkflow())
            }

            if (!data.data?.workflowSteps?.length && originalArgs) {
                // console.log(originalArgs?.data?.moduleId)
                if (originalArgs?.data?.moduleId) {
                    // make the getWorkflow call again without a moduleId this time.
                    console.log('at end. no workflow steps but you have moduleId originalArgs')

                    if (data.data?.workflowResult === 'redirect') {
                        console.log('redirecting the user to token,details.roid')
                        // redirect them to the route.
                        redirectAction()
                    } else if (data.data?.workflowResult === 'redirect_back') {
                        console.log('will be taken back to route from storage.')
                        redirectBackAction()
                    } else {
                        console.log('getting workflow without moduleId')
                        getWorkflow({
                            authToken: token.value,
                            data: {}
                        })
                    }
                } else {
                    console.log('response data after getWorkflow on empty workflow steps', data)
                    if (data.data?.workflowResult === 'do_nothing') {
                        console.log('do_nothing action after getWorkflow call')
                    } else if (data.data?.workflowResult === 'redirect') {
                        console.log('redirect action after getWorkflow call.')

                        // redirect them to the route.
                        redirectAction()
                    } else if (data.data?.workflowResult === 'redirect_back') {
                        console.log('will be taken back to route from storage.')
                        redirectBackAction()
                    } else {
                        console.log('action unidentifed in workflow result')
                    }
                }
            } else if (data.data?.workflowSteps?.length && originalArgs) {
                fixSteps(
                    data, originalArgs
                )
            }
        }
    // we're getting the old data for some reason. remove all
    // dependencies except for data. While it is important to include
    // originalArgs, it might trigger this lifecycle  twice when data is enough
    }, [data, activeModules.id])

    useEffect(() => {
        if (data && activeModules.arr.length) {
            // check if there is a workflow step to set first.
            console.log('workflowSteps: ', data.data?.workflowSteps)

            if (data.data?.workflowSteps?.length && data.data?.workflowId && data.data?.moduleId) {
                const isSet = _.sortBy(data.data.workflowSteps, 'stepSequence')
                    .find((step) => step.stepProgress.percentComplete < 100 &&
                    step.stepProgress.percentComplete >= 0
                    )

                // time to store. except for moduleId as there
                // will be cases mostly when the workflow
                // desired in the specific component requires it.
                dispatch(setWorkflowId(data.data.workflowId))
                dispatch(setModuleId(data.data.moduleId))
                dispatch(setWorkflowCount(data.data.workflowSteps.length))

                const foundModule = validateModuleAll(
                    activeModules.arr, data.data.moduleId, false
                )

                // however, we need to check on an extra condition. if the workflow step
                // is a reasoning set AND if the moduleId ISN'T the reasonwithme, then
                // it is also marked as a fail.
                const foundModuleReasoningSet = validateModule(
                    activeModules.arr,
                    MODULE_TABLE.reasonWithMe.moduleName,
                    true
                )

                const call = async (workflowId: string) => {
                    if (token.valid && isSet) {
                        const newToken = await revalidateToken({
                            value: token.value,
                            id: token.id
                        }, token.mode)
                        const percentComplete = -1

                        const isValid = validateAPIPath(
                            activeModules.arr,
                            MODULE_TABLE.login.moduleName,
                            MODULE_TABLE.login.apiPaths.updateWorkflowProgress.path,
                            true
                        )

                        if (isValid && newToken.value) {
                            updateWorkflowProgress({
                                authToken: newToken.value,
                                data: {
                                    stepId: isSet.stepId,
                                    workflowId,
                                    percentComplete
                                }
                            })
                        }
                    }
                }

                if (isSet?.stepType === 'reasoningset') {
                    if (foundModuleReasoningSet?.moduleId === foundModule?.moduleId) {
                        console.log('setting workflow step after reasoning set check.')

                        dispatch(setCurrentWorkflowStep(isSet))
                    } else {
                        console.log('this workflow is marked as failed from reasoning set check.')
                        call(data.data.workflowId)
                    }
                } else {
                    if (foundModule) {
                        // no need to store the workflow steps in a state.
                        // since we'll be using the getWorkflow call on every page refresh.
                        // just to be sure, sort by stepSequence.
                        console.log('setting workflow step: ', isSet)

                        dispatch(setCurrentWorkflowStep(isSet))
                    } else {
                        /**  This scenario can lead to a false "failed" state if you have
                         *  a guest token and later authenticate as a user. At that point,
                         * you receive an auth token, but without the associated configuration
                         *  for that specific lifecycle. Consequently, the configuration
                         *  of the guest token is mistakenly utilized, causing
                         * the incorrect state. */
                        console.log('this workflow is marked as failed.')
                        // this has to mark the step as failed because the moduleId is wrong.
                        call(data.data.workflowId)
                    }
                }
            }
        }
    }, [data, activeModules.id])

    // workflow route redirect logic. Once we receive our getWorkflow data,
    // after validating the moduleId, we can due a switch case and redirect
    // the user to a route of our choosing. Also moved reasonWithMe logic
    // to here as we classify that as another workflow
    useEffect(() => {
        if (data && activeModules.arr.length && data.data?.moduleId && currentWorkflowStep) {
            const foundModule = validateModuleAll(
                activeModules.arr, data.data.moduleId, false
            )
            console.log('found module to redirect during workflow: ', foundModule)
            switch (foundModule?.moduleName) {
                case 'Registration':{
                    const foundRoute = validateRoute(
                        activeModules.arr,
                        foundModule.moduleName,
                        MODULE_TABLE.registration.routes.registration,
                        true
                    )
                    if (foundRoute) {
                        console.log('going to reg path without need of params anymore')
                        dispatch(replace(foundRoute.route))
                    }
                    break
                }

                case 'reasonWithMe': {
                    if (
                        currentWorkflowStep?.stepType === 'reasoningset' &&
                        typeof currentWorkflowStep.stepContent !== 'string'
                    ) {
                        console.log('should be in reasoning set. resetting data.')
                        // you should be in a useEffect where you will be
                        // redirected to the reasonWithMe interface.
                        // remember to set the reasoningset and the version before
                        // dispatching a push to QuestionInterface.tsx

                        // in the event that you receive a moduleId that isn't RWM,
                        // go to default route instead.
                        dispatch(setReasoningSet({
                            data: {
                                reasoningSetId: currentWorkflowStep.stepContent.reasoningSetId,
                                reasoningSetVersion: currentWorkflowStep.stepContent
                                    .dataAcquisitionProtocolVersion
                            }
                        }))

                        // unset the reasoning set data.
                        dispatch(setCurrentReasoningResults({
                            stop_value: false,
                            reasoningSessionId: ''
                        }))
                        dispatch(setCurrentReasoningData({
                            stop_value: false,
                            reasoningSessionId: ''
                        }))
                        dispatch(setAnswerValue(undefined))

                        // first instance of getting dynamic data and checking
                        // if the route exists and then doing a push.
                        const foundRoute = validateRoute(
                            activeModules.arr,
                            foundModule.moduleName,
                            MODULE_TABLE.reasonWithMe.routes.questionInterface,
                            false
                        )

                        console.log('is route found: ', foundRoute)

                        if (foundRoute) {
                            dispatch(replace(foundRoute.route))
                        }
                    }

                    break
                }

                default:
                    // do not redirect the user to anything.
                    break
            }
        }
    }, [currentWorkflowStep])

    return <></> // Render nothing
}

export default GetWorkflowConsumer
