/* eslint-disable max-len */
import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { css, useTheme } from '@emotion/react'
import Color from 'color'

export const CSS_QUESTION_TYPE = () => {
    const theme = useTheme()

    return css`
        & .question-scale {
            & .list-allowed {
                /* @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                    margin-block-end: 25px;
                }

                @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                    margin-block-end: 80px;
                } */
                margin-block-end: 80px;
            }

            & .card.scale-card, & .card.checkbox-card {
                box-shadow: 0px 2px 10px 0px ${
                    (() => {
                        const color = Color(theme.config.admin?.answerSummary?.reasonWithMe.boxShadowColor || '')
                        return 'rgba(' + [
                            color.rgb().array()[0],
                            color.rgb().array()[1],
                            color.rgb().array()[2],
                            '0.05'
                        ].join(', ') + ')'
                    })()
                };

                & > .card-body {
                    padding: 0;

                    & > div {
                        padding-inline: 50px;
                        padding-block: 10px;
                    }
                }
            }

            & .card.scale-card {
                margin-block-end: 20px;
            }

            & .btn-group-checkbox-list {
                margin-block-end: 60px;
            }
        }

        & .question-multi-list, & .question-list {
            margin-block-end: 80px;

            & .form-control-wrapper input[type=search] {
                background-color: ${ theme.config.admin?.answerSummary
                    ?.reasonWithMe.formControlInputBackgroundColor || '' };
            }
        }

        & .question-single-date, & .question-open, & .question-number-input,
        & .question-float-input, & .question-bool, & .question-text {
            margin-block-end: 80px;
        }

        & .question-multi-question {
            & .multi-question-card {
                box-shadow: 0px 2px 10px 0px ${
                    (() => {
                        const color = Color(theme.config.admin?.answerSummary?.reasonWithMe.boxShadowColor || '')
                        return 'rgba(' + [
                            color.rgb().array()[0],
                            color.rgb().array()[1],
                            color.rgb().array()[2],
                            '0.05'
                        ].join(', ') + ')'
                    })()
                };

                & > .card-body {
                    padding: 1.2em;
                }
            }
        }

        & .submit-button {
            /** to assist with responsiveness */
            max-width: 350px;
            width: 100%;
        }

    `
}

export const CAREPLAN_STEP_CONTENT = () => {
    const theme = useTheme()

    return css`
    /* make everything smaller in all texts */
    font-size: 88.5%;

    background: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.background };
    border-radius: 12px;
    padding-block: 20px;
    margin-inline: 5vw;

    /* @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
        margin-block: 45px;
        padding-inline: 30px;
        padding-block-start: 15px;
        padding-block-end: 140px;
    } */

    /* @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
        padding-inline: 50px;
    } */
    /* padding-inline: 50px; */

    .question-checkmark,
    .question-plussign,
    .question-unchecked {
        min-height: 1.5rem;
        min-width: 1.5rem;
        max-height: 1.5rem;
        max-width: 1.5rem;
    }

    .meta-info {
        small.content-type{
            &.activity {
                color: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.metaInfo.activityColor };
            }
            &.content {
                color: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.metaInfo.contentColor };
            }
            &.lesson {
                color: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.metaInfo.lessonColor };
            }
            &.reasoning {
                color: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.metaInfo.reasoningColor };
            }
        }
    }



    .questionnaire {
        .card {
            color: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.questionnaire.card.color };
            background-color: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.questionnaire.card.backgroundColor };

            .card-body {
                padding-top: 1.5rem;
                padding-right: 2rem;
                padding-bottom: 1.125rem;
                padding-left: 2rem;
            }
        }
    }

    .media-wrapper {
        position: relative;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

        & > .react-player {
            position: absolute;
            top: 0;
            left: 0;

            & video:first-of-type, & iframe:first-of-type {
                border-radius: 1.4em 1.4em 0 0;
            }
        }

        &.audio{
            border-radius: 1em;
        }

        &.video{
            border-radius:0 0 1.4em 1.4em;
        }

        /** used when controls is set to true. now will be used for player */
        & > .player {

            /** for icons */
            & .play-button i{
                color: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.playButtonColor };
            background: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.playButtonBackground };
                border: 1px solid transparent;
                border-radius: 8px;
                width: 40px;
                height: 40px;
                text-align: center;
                cursor: pointer;
                line-height: 40px;
            }

            & .volume-button  i{

                font-size: 1em;
                padding: 1em;
                cursor: pointer;
            }

            & .fullscreen-button i{

                border: 1px solid transparent;
                border-radius: 8px;
                width: 40px;
                height: 40px;
                text-align: center;
                cursor: pointer;
                line-height: 40px;
                box-shadow: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.fullscreenButtonBoxShadow };

            }

            width: 100%;
            padding-block: 0.5em;
            padding-inline: 2em;
            border: 0.1em solid transparent;

            //make sure padding-inline is zero.
            input[type='range'] {
                -webkit-appearance: none; /* Override default CSS styles */
                appearance: none;
                width: 100%;

                &.volume-control {
                    /* @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
                        width: 65px;
                    }
                    @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
                        width: 150px;
                    } */

                    width: 150px;

                }

                padding-block: 0em;
                padding-inline: 0em;
                height: 0.3em;
                margin-block: 1em;
                //bar color
                background: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeBarColor };
                //progress color
                background-image: linear-gradient(
                    180deg,
                    ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeProgressColor } 9.04%,
                    ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeProgressColor } 91.12%
                );
                background-repeat: no-repeat;
                outline: none;
                border-radius: 10em;
                border-width: 0px;
                background-size: 0% 100%;
                cursor: pointer;
            }

            input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0.8em;
                height: 0.8em;
                border-radius: 50%;
                background: linear-gradient(
                    180deg,
                    ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 9.04%,
                    ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 91.12%
                );
                cursor: pointer;
            }

            input[type=range]::-webkit-slider-runnable-track  {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }

            /* Moz */

        input[type='range']::-moz-range-thumb {
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                background: linear-gradient(
                    180deg,
                    ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 9.04%,
                    ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 91.12%
                );

                cursor: pointer;
            }

            input[type='range']::-moz-range-track {
                background: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeTrackColor };
                border-radius: 50%;
                height: 1em;
            }

            /* IE */

            input[type='range']::-ms-thumb {
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                background: linear-gradient(
                    180deg,
                    ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 9.04%,
                    ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeThumbColor } 91.12%
                );
                cursor: pointer;
            }

            input[type='range']::-ms-track {
                background: ${ theme.config.admin?.answerSummary.careplanStep.mainContent.mediaWrapper.player.rangeTrackColor };
                border-radius: 50%;
                height: 1em;
            }


            & div.icon {
                cursor: pointer;
                font-size: 0.8em;

                & i {
                    padding: 0.1em;
                    box-sizing: content-box;
                    border-radius: 1em;
                }

                &.active i {
                    outline: 2px solid black;
                }
            }
        }
    }
`
}

const GlobalStyle = () => {
    const theme = useTheme()

    return css`

        .answer-summary-modal {

            //only applies to min-width of 800px;
            /* @media (min-width: 800px)  { */
            min-width: 800px;
            max-width: 1440px;
            width: fit-content;
            /* } */



            color: ${ theme.config.admin?.answerSummary.careplanStep.modalGeneralColor };

            //for second column sticky scroll.
            /* max-height: 80vh; */

            .main-content {

                ${ CSS_QUESTION_TYPE() }

            }

            .content-container{
                ${ CAREPLAN_STEP_CONTENT() }

            }
            
            /* @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    .card-grid {
                        margin-bottom: 5rem;
                    }

                }
            } */
/* 
            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                & .header {
                    height: 58px;

                    & img {
                        width: 98px;
                        margin-block-start: -5px;
                    }
                }
                
                & .main-content {
                    width: 100%;

                    & .content-container {
                        margin-inline: 0vw;
                    }

                    padding-top: 113px;
                    position: absolute;
                    height: calc(100% - 113px);


                }


            } */

            .careplan-progress-links {
                position: sticky;
                /* position: relative; */
                top: 10px;
                //content should have a fixed width at all times.
                width: 300px;
                padding: 1em;

                .progress-block {
                    margin: 0.5rem;
                    border-width: 1px;
                    border-style: solid;
                    border-color: ${ theme.config.admin?.answerSummary.careplanStep.progressBlock.borderColor };
                    border-radius: 0.6em;
                    padding: 0.5em;
                }
            }

            .reasoning-answer-card {
                margin: 0rem 2rem 2rem 2rem;
                border-width: 1px;
                border-style: solid;
                border-color: ${ theme.config.admin?.answerSummary.careplanStep.progressBlock.borderColor };
                border-radius: 0.6em;
                padding: 0.5em;
            }
        
        }


    `
}

export default GlobalStyle
