import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { findAPIPath } from '@app/app.method'
import { selectStrings } from '@app/slices/slice.app'
import { SystemModule } from '@login/type'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

export const useValidateAPIPath = () => {
    const strings = useAppSelector(selectStrings)
    const callback = useCallback((arr: SystemModule[],
        module: string,
        apiPath: string,
        showToast: boolean) => {
        if (arr.length) {
            const foundApiPath = findAPIPath(
                arr, module, apiPath
            )

            // NOTE: not all need to show a toast error.
            // only do this error toast method AFTER authentication.
            if (foundApiPath) {
                return true
            } else {
                if (strings.login?.message.error.api_path && showToast) {
                    const message = [
                        apiPath,
                        ':',
                        strings.login?.message.error.api_path
                    ].join(' ')
                    toast.error(
                        message,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
                    console.error(message)
                }
            }
        }

        return false
    }, [strings])

    return callback
}
