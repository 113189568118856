import { useEffect, useState } from 'react'

import { MODULE_TABLE } from '@app/app.config'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import {
    useAddDepartmentCareFacilityMutation,
    useDeleteDepartmentCareFacilityMutation,
    useGetDepartmentsMutation
} from '@fmt/api'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import DepartmentsInterface from '@fmt/components/facilities/add/steps/StepTwo/DepartmentsInterface'
import QuickAddDepartment from '@fmt/components/facilities/add/steps/StepTwo/QuickAddDepartment'
import { selectFacilityMenu } from '@fmt/slice'
import { Department, GetDepartmentsResponse } from '@fmt/type'
import { FormikProps } from 'formik'
import _ from 'lodash'
import { toast } from 'react-toastify'

interface ComponentProps {
    facilityStepTwoAddFormik: FormikProps<{
        linkedDepartments: Department[]
    }>
}

const StepTwo = ({ facilityStepTwoAddFormik }:ComponentProps) => {
    // what you need from top to bottom,
    // one, a dropdown that displays a list of listed departments by the facilityId
    // and a list of UNLINKED departments.
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    // const validateRoute = useValidateRoute()
    // const dispatch = useAppDispatch()
    const facilityMenu = useAppSelector(selectFacilityMenu)
    const [isOutsideClick, setIsOutsideClick] = useState(false)

    const [showAddDepartmentInterface, setShowAddDepartmentInterface] = useState(false)

    const [
        addDepartmentCareFacility,
        addDepartmentCareFacilityMutation
    ] = useAddDepartmentCareFacilityMutation({})

    const [
        deleteDepartmentCareFacility,
        deleteDepartmentCareFacilityMutation
    ] = useDeleteDepartmentCareFacilityMutation({})

    const [getUnlinkedDepartments, getUnlinkedDepartmentsMutation] = useGetDepartmentsMutation()
    const [getLinkedDepartments, getLinkedDepartmentsMutation] = useGetDepartmentsMutation()

    // a workaround to prevent tables from flickering. simple but it works.
    const [
        getUnlinkedDepartmentsResponse,
        setGetUnlinkedDepartmentsResponse
    ] = useState<GetDepartmentsResponse>()

    const [
        getLinkedDepartmentsResponse,
        setGetLinkedDepartmentsResponse
    ] = useState<GetDepartmentsResponse>()

    useEffect(() => {
        if (getLinkedDepartmentsResponse) {
            facilityStepTwoAddFormik.setFieldValue('linkedDepartments',
                getLinkedDepartmentsResponse.data.departments
            )
        }
    }, [getLinkedDepartmentsResponse])

    useEffect(() => {
        if (getUnlinkedDepartmentsMutation.data) {
            setGetUnlinkedDepartmentsResponse(getUnlinkedDepartmentsMutation.data)
        }
    }, [getUnlinkedDepartmentsMutation.data])

    useEffect(() => {
        if (getLinkedDepartmentsMutation.data) {
            setGetLinkedDepartmentsResponse(getLinkedDepartmentsMutation.data)
        }
    }, [getLinkedDepartmentsMutation.data])

    const unsubscribeGetUnlinkedDepartments = () => {
        const unsubscribeMutation = getUnlinkedDepartments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetLinkedDepartments = () => {
        const unsubscribeMutation = getLinkedDepartments({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        if (addDepartmentCareFacilityMutation.error) {
            const message = getErrorText(addDepartmentCareFacilityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addDepartmentCareFacilityMutation.error])

    useEffect(() => {
        if (deleteDepartmentCareFacilityMutation.error) {
            const message = getErrorText(deleteDepartmentCareFacilityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [deleteDepartmentCareFacilityMutation.error])

    const fetchData = (token: TokenData) => {
        unsubscribeGetUnlinkedDepartments()
        unsubscribeGetLinkedDepartments()

        let getUnlinkedDepartmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getLinkedDepartmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getDepartments.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getUnlinkedDepartmentsPromise = getLinkedDepartments({
                            authToken: newToken.value,
                            data: {
                                isLinked: true,
                                facilityId: facilityMenu.addInterfaceProps.facilityId,
                                limit: 0,
                                skip: 0
                            }
                        })

                        getLinkedDepartmentsPromise = getUnlinkedDepartments({
                            authToken: newToken.value,
                            data: {
                                isLinked: false,
                                limit: 0,
                                skip: 0
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getUnlinkedDepartmentsPromise && getUnlinkedDepartmentsPromise.abort()
            getLinkedDepartmentsPromise && getLinkedDepartmentsPromise.abort()
        }
    }

    useEffect(() => {
        // if the values of linkedDepartments is updated, then update the queries.
        return fetchData(token)
    }, [token.id, token.valid])

    const changeDepartmentStatus = async (obj: Department, linked: boolean) => {
        const newToken = await revalidateToken(
            {
                value: token.value,
                id: token.id
            },
            token.mode
        )

        if (linked) {
            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.apiPaths.addDepartmentCareFacility.path,
                true
            )
            if (foundApiPath && newToken.value) {
                addDepartmentCareFacility({
                    authToken: newToken.value,
                    data: {
                        departmentId: obj?.departmentId || '',
                        facilityId: facilityMenu.addInterfaceProps.facilityId
                    }
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        //
                        fetchData(token)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        } else {
            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.apiPaths.deleteDepartmentCareFacility.path,
                true
            )
            if (foundApiPath && newToken.value) {
                deleteDepartmentCareFacility({
                    authToken: newToken.value,
                    data: {
                        departmentId: obj?.departmentId || '',
                        facilityId: facilityMenu.addInterfaceProps.facilityId
                    }
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        //
                        fetchData(token)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        }
    }

    return <div id={'step-two-content'}>
        <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text.facility.add.departments}</h2>
        {/* dropdown goes here */}
        <DepartmentsInterface
            isFromEditPage={false}
            isOutsideClick={isOutsideClick}
            setIsOutsideClick={setIsOutsideClick}
            changeDepartmentStatus={changeDepartmentStatus}
            getLinkedDepartmentsResponse={getLinkedDepartmentsResponse}
            getUnlinkedDepartmentsResponse={getUnlinkedDepartmentsResponse}
        />
        {/* add department interface goes here */}
        <div className={'row mt-6'}>
            <div className={
                'col-12 d-flex justify-content-end align-items-center'
            }>
                <div className={
                    'rounded-square d-flex me-5 btn ' +
                'justify-content-center align-items-center'
                } onClick={() => {
                    // show department interface
                    setShowAddDepartmentInterface(!showAddDepartmentInterface)
                }}>
                    <i className={'fa-light fa-plus'}></i>
                </div>
                <h6 className={'mb-0 fw-semibold'}>{
                    strings.fmt?.text.facility.add.steps['2'].add_new_department
                }</h6>

            </div>
        </div>
        <QuickAddDepartment
            fetchData={fetchData}
            showAddDepartmentInterface={showAddDepartmentInterface}
            setShowAddDepartmentInterface={setShowAddDepartmentInterface}
        />
    </div>
}

export default StepTwo
