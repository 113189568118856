import React, { ReactNode } from 'react'

interface OverlayProps {
  component: ReactNode;
  text: string;
}

const Overlay: React.FC<OverlayProps> = ({ component, text }) => {
    return (
        <div className={'overlay'}>
            <div className={'overlay-text'}>{text}</div>
            {component}
        </div>
    )
}

export default Overlay
