import { CAREPLANS_CUSTOM_MATCH, CAREPLAN_STEP_CUSTOM_MATCH, MODULE_TABLE } from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectRouter } from '@app/app.store'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { CareplanParams } from '@careplan/type'
import { push, replace } from '@lagunovsky/redux-react-router'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import { useValidateRouteAll } from '@login/MutationProvider/validateRouteAll'

import _ from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'

import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { useGetMenuMutation } from '@sidebar/api'
import {
    selectActiveTab,
    selectMenuList,
    selectShowMenuBar,
    setActiveTab,
    setMenuList,
    toggleExpand,
    toggleMenuTopSidebarMobileBar
} from '@sidebar/slice'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'
import { v4 as uuidV4 } from 'uuid'

import { TokenData } from '@app/types/type.token'
import { MenuList } from '@sidebar/type'

const Sidebar = () => {
    const dispatch = useAppDispatch()

    const revalidateToken = useRevalidateToken()
    const validateRoute = useValidateRoute()
    const router = useAppSelector(selectRouter)
    const validateRouteAll = useValidateRouteAll()
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const activeTab = useAppSelector(selectActiveTab)
    const menuList = useAppSelector(selectMenuList)
    const showMenuBar = useAppSelector(selectShowMenuBar)

    const [getMenu, getMenuMutation] = useGetMenuMutation()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const mobileSidebarRef = useRef <HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (mobileSidebarRef.current) {
                // weird behavior but works anyways
                if (mobileSidebarRef.current &&
                    !mobileSidebarRef.current.contains(event.target as Node)) {
                    // if you are at the admin page, don't do this because of
                    // the sidebar not within bounds as the admin dashboard header
                    // is on top of it.

                    if (isMobile && showMenuBar.mobile.dashboard.top) {
                        //
                        // console.log('not closing bar in specific case.')
                    } else {
                        // console.log('closing bar by handle click outside event')
                        dispatch(toggleMenuTopSidebarMobileBar(
                            false
                        ))
                    }
                }
            } else {
                // console.log('component is not rendered')
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [mobileSidebarRef, showMenuBar])

    useEffect(() => {
        if (getMenuMutation.data?.data) {
            dispatch(setMenuList(
                _.sortBy(getMenuMutation.data?.data || [], (o) => o.pageOrder).map((item) => {
                    // Check if the 'id' property exists
                    if (!item.id) {
                        // If 'id' doesn't exist, add it using the UUID v4 generator
                        return {
                            ...item,
                            id: uuidV4()
                        }
                    }
                    // If 'id' already exists, simply return the item as is
                    return item
                })
            ))
        }
    }, [getMenuMutation.data?.data])

    const unsubscribeGetMenu = () => {
        const unsubscribeMutation = getMenu({ } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    useEffect(() => {
        if (getMenuMutation.error) {
            const message = getErrorText(getMenuMutation.error)
            if (message.includes('Aborted') === false) {
                console.error(message)
                toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [getMenuMutation.error])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetMenu()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid && token.mode === 'auth') {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.apiPaths.getMenu.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (foundApiPath && newToken.value) {
                        promise = getMenu({
                            authToken: newToken.value
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            // toast.error(
                            //     `${ MODULE_TABLE.login
                            //         .apiPaths.getMenu.path }:
                            //     ${ strings.login?.message.error.api_path }`.trim(),
                            //     { ...TOASTIFY_DEFAULT_OPTIONS }
                            // )
                        }
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [activeModules.id])

    useEffect(() => {
        console.log('activeTab atm: ', activeTab)
    }, [activeTab])

    const { personalCareplanId } = useParams<CareplanParams>()

    useEffect(() => {
        // on mount, select the group where the link matches with the
        // router.location.pathname

        // console.log('link:', router.location.pathname)

        _.forEach(menuList, (mainPage) => {
            if (
                mainPage.pageLink === MODULE_TABLE.careplanPatient.routes.careplans
            ) {
                if (CAREPLANS_CUSTOM_MATCH.test(router.location.pathname) ||
                CAREPLAN_STEP_CUSTOM_MATCH.test(router.location.pathname)) {
                    dispatch(setActiveTab(mainPage))
                }
            } else {
                if (router.location.pathname === mainPage.pageLink) {
                    dispatch(setActiveTab(mainPage))
                }

                mainPage.subPages?.forEach((subPage) => {
                    if (
                        subPage.pageLink === MODULE_TABLE.careplanPatient.routes.careplans
                    ) {
                        if (CAREPLANS_CUSTOM_MATCH.test(router.location.pathname) ||
                        CAREPLAN_STEP_CUSTOM_MATCH.test(router.location.pathname)) {
                            dispatch(setActiveTab(subPage))
                        }
                    } else {
                        if (router.location.pathname === subPage.pageLink) {
                            dispatch(setActiveTab(subPage))
                        }
                    }
                })
            }
        })
    }, [router.location.pathname, menuList])

    const linkNavigation = (link: string) => {
        // now dispatch to another page.
        const foundRoute = validateRouteAll(
            activeModules.arr, link, false
        )
        if (foundRoute) {
            // if the link clicked is MODULE_TABLE.careplanPatient.routes.careplan
            // then you need to use the link with the personalCareplanId
            if (link === MODULE_TABLE.careplanPatient.routes.careplan) {
                dispatch(push(
                    _.replace(
                        foundRoute.route, ':personalCareplanId',
                        personalCareplanId || ''
                    )
                ))
            } else {
                dispatch(push(foundRoute.route))
            }
        } else {
            console.error(strings.login?.message.error.route_denied)
        }
    }

    const renderDesktopMenuList = (menuList: MenuList[], isNested: boolean): JSX.Element[] => {
        return _.map(menuList, (obj, index) => {
            const key = `sidebar-item-${ index }`
            const isLink = activeTab.pageName === obj.pageName

            const icon = `${ isLink ? 'fa-solid' : 'fa-light' } ${ obj.pageIcon }`

            const classNames = ['nav-link']

            if (isLink) {
                classNames.push('active')
            }

            const combinedClassName = classNames.join(' ')

            const genericRow = (
                <li className={'nav-item'} key={key}>
                    {/* render this if there are no subpages */}
                    <a
                        className={combinedClassName}

                        type={'button'}
                        role={'tab'}
                    >
                        <div className={'row justify-content-between'}>
                            <div className={'col'} onClick={(e) => {
                                e.preventDefault()

                                console.log(obj.pageLink)

                                if (obj.pageLink) {
                                    linkNavigation(obj.pageLink)
                                }
                            }}>
                                <i className={icon} aria-hidden={'true'}></i>
                                <span className={'ms-3'}>
                                    {obj.pageName}
                                </span>
                            </div>
                            {obj.subPages?.length
                                ? <div className={'col-auto ps-0'} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    if (obj.subPages?.length) {
                                        // expand the row
                                        dispatch(toggleExpand({
                                            id: obj.id,
                                            expand: !obj.expand
                                        }))
                                    }
                                }}>
                                    <i
                                        className={`fa-solid ${
                                    obj.expand ? 'fa-chevron-up' : 'fa-chevron-down'
                                }`}
                                        aria-hidden={'true'}>
                                    </i>
                                </div>
                                : ''}

                        </div>
                    </a>

                    {/* render subpages recursively */}
                    {(obj.subPages?.length || 0) > 0 && obj.expand === true
                        ? (
                            <ul className={`nav nav-pills flex-column 
                        ${ obj.subPages?.length ? 'ps-6 pe-0' : '' }`}>
                                {renderDesktopMenuList(obj.subPages || [], true)}
                            </ul>
                        )
                        : ''}
                </li>
            )

            return genericRow
        })
    }
    const groupedData = _.groupBy(menuList, 'sectionNumber')

    const desktopNavigation = <div
        // can be shown if showMenuBar.desktop is true
        className={['sidebar desktop', showMenuBar.desktop ? 'd-block' : 'd-none'].join(' ')}>
        <div className={'header py-4 text-center'}>
            {/* image of logo goes here. fixed width but height can change whatever */}
            <img src={'/images_new/header/logo.svg'} />
        </div>
        <div className={' navigation'}>
            <ul className={'nav nav-pills flex-column'}>

                {
                    Object.entries(groupedData).map(([sectionNumber, items], index, arr) => (
                        <div key={sectionNumber}>
                            {/* Render your component content based on the grouped items */}
                            {renderDesktopMenuList(items, true)}
                            {index !== arr.length - 1 && <hr />}
                        </div>
                    ))
                }
            </ul>
            <div className={'gap'}></div>
            <div className={'footer text-center w-100'}>
                <hr />
                <button className={'btn btn-lg btn-secondary'} onClick={() => {
                    // simulate logout path.
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.routes.logout,
                        true
                    )

                    if (isValid) {
                        dispatch(replace(isValid.route))
                    }
                }}>
                    <span className={'ps-3'}>{strings.app?.text.authenticate.log_out}</span>
                    <i className={[
                        'fa-arrow-right-from-bracket fa-light float-end pt-1 '
                    ].join(' ')}></i>
                </button>
            </div>
        </div>
    </div>

    const renderMobileMenuList = (menuList: MenuList[], isNested: boolean): JSX.Element[] => {
        return _.map(menuList, (obj, index) => {
            const key = `sidebar-item-${ index }`
            const isLink = activeTab.pageName === obj.pageName

            const icon = `${ isLink ? 'fa-solid' : 'fa-light' } ${ obj.pageIcon } d-block`

            const classNames = ['nav-link']

            if (isLink) {
                classNames.push('active')
            }

            const combinedClassName = classNames.join(' ')

            const genericRow = (
                <li className={'nav-item'} key={key}>
                    {/* render this if there are no subpages */}
                    <a
                        className={combinedClassName}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()

                            console.log(obj.pageLink)

                            if (obj.subPages?.length) {
                                // expand the row
                                dispatch(toggleExpand({
                                    id: obj.id,
                                    expand: !obj.expand
                                }))

                                // BUT also navigate IF obj.pageLink is valid.
                                if (obj.pageLink) {
                                    linkNavigation(obj.pageLink)
                                }
                            } else {
                                // navigate user to somewhere.
                                linkNavigation(obj.pageLink)
                            }
                        }}
                        type={'button'}
                        role={'tab'}
                    >
                        <div className={'text-center'}>
                            <i className={icon} aria-hidden={'true'}></i>
                            <span className={'d-block mt-2'}>
                                {obj.pageName}
                            </span>
                        </div>
                    </a>
                </li>
            )

            return genericRow
        })
    }

    // const mobileTopNavigation = useMemo(() => {
    //     // top menu bar.
    //     return <div
    //         className={['container-fluid sidebar mobile top',
    //             showMenuBar.mobile.top.menu ? 'd-block' : 'd-none'].join(' ')}>
    //         <div className={'row'}>
    //             <div className={'col-auto'}>
    //                 <h4 className={'my-1'} onClick={() => {
    //                     dispatch(toggleMenuTopSidebarMobileBar(
    //                         !showMenuBar.mobile.top.sidebar
    //                     ))
    //                 }}>
    //                     <i
    //                         className={ [
    //                             'fa-light',
    //                             showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
    //                         ].join(' ')}
    //                     >
    //                     </i>
    //                 </h4>
    //             </div>
    //         </div>
    //     </div>
    // }, undefined)

    const mobileBottomNavigation = useMemo(() => {
        // bottom menu bar is always shown for mobile because it has to.
        // no conditionals.

        return <div
            className={['sidebar mobile bottom',
                showMenuBar.mobile.bottom ? 'd-block' : 'd-none'].join(' ')}>
            <div className={'navigation mobile'}>
                <ul className={'nav nav-pills justify-content-around'}>
                    {/* show priamry records */}
                    {renderMobileMenuList(
                        _.filter(menuList, 'primaryFlag')
                        , true
                    )}
                </ul>
            </div>
        </div>
    }, undefined)

    /** would be present in other components */
    const mobileSidebarHeader = <div className={'py-3 header text-center'}>
        <div className={'container-fluid align-items-baseline h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4>
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const mobileSidebarNavigation = <div ref={mobileSidebarRef}
        // can be shown if showMenuBar.desktop is true
        className={['sidebar desktop', showMenuBar.mobile.top.sidebar === true
            ? 'd-block'
            : 'd-none'].join(' ')}>
        {mobileSidebarHeader}
        <div className={' navigation'}>
            <ul className={'nav nav-pills flex-column'}>

                {
                    Object.entries(groupedData).map(([sectionNumber, items], index, arr) => (
                        <div key={sectionNumber}>
                            {/* Render your component content based on the grouped items */}
                            {renderDesktopMenuList(items, true)}
                            {index !== arr.length - 1 && <hr />}
                        </div>
                    ))
                }
            </ul>
            <div className={'gap'}></div>
            <div className={'footer text-center w-100'}>
                <hr />
                <button className={'btn btn-lg btn-secondary'} onClick={() => {
                    // simulate logout path.
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.routes.logout,
                        true
                    )

                    if (isValid) {
                        dispatch(replace(isValid.route))
                    }
                }}>
                    <span className={'ps-3'}>{strings.app?.text.authenticate.log_out}</span>
                    <i className={[
                        'fa-arrow-right-from-bracket fa-light float-end pt-1 '
                    ].join(' ')}></i>
                </button>
            </div>
        </div>
    </div>

    return <>
        {/* for endUsers */}
        {isMobile
            ? <>
                {/* {mobileTopNavigation} */}
                {mobileSidebarNavigation}
                {mobileBottomNavigation}
            </>
            : desktopNavigation}
        {/* for admin */}
    </>
}

export default Sidebar
