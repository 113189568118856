import { MODULE_TABLE } from '@app/app.config'
import { URL } from '@app/app.constants'
import { TokenAuth } from '@app/types/type.app'
import {
    CheckCareplanStatusRequest,
    CheckCareplanStatusResponse,
    CompleteCareplanProgressReasoningRequest,
    CompleteCareplanProgressReasoningResponse,
    CompleteCareplanProgressRequest,
    CompleteCareplanProgressResponse,
    FinishPersonalCareplanRequest,
    FinishPersonalCareplanResponse,
    GetMotivationalMessageRequest,
    GetMotivationalMessageResponse,
    GetOverallProgressRequest,
    GetOverallProgressResponse,
    GetPersonalCareplanRequest,
    GetPersonalCareplanResponse,
    GetTodayRequest,
    GetTodayResponse,
    MakePersonalCareplanRequest,
    MakePersonalCareplanResponse,
    UpdateCareplanProgressRequest,
    UpdateCareplanProgressResponse
} from '@careplan/type'
import {
    RemoveFromLibraryRequest,
    RemoveFromLibraryResponse,
    SaveToLibraryRequest,
    SaveToLibraryResponse
} from '@library/type'
import {
    GetReasoningRequest,
    GetReasoningResponse,
    ReasonWithMeRequest,
    ReasonWithMeResponse
} from '@reasonWithMe/type'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'

export const careplanV10Api = createApi({
    reducerPath: 'careplanV10Api',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getPersonalCareplan: builder
            .mutation<GetPersonalCareplanResponse, GetPersonalCareplanRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetPersonalCareplanRequest = {
                        data: {
                            ...(body.data.personalCareplanStepId && {
                                personalCareplanStepId: body.data.personalCareplanStepId
                            }),
                            ...(body.data.personalCarePlanId && {
                                personalCarePlanId: body.data.personalCarePlanId
                            }),
                            ...(body.data.activityId && {
                                activityId: body.data.activityId
                            }),
                            ...(body.data.contentId && {
                                contentId: body.data.contentId
                            }),
                            ...(body.data.reasoningSetId && {
                                reasoningSetId: body.data.reasoningSetId
                            }),
                            ...(body.data.uniqueLessonId && {
                                uniqueLessonId: body.data.uniqueLessonId
                            }),
                            ...(body.data.uniqueTherapeuticId && {
                                uniqueTherapeuticId: body.data.uniqueTherapeuticId
                            })
                        }
                    }

                    const removeBrackets = (str: string) => {
                        const pattern = /<[^>]+>/g
                        return _.replace(str, pattern, '')
                    }

                    const url = [
                        _.trim(
                            removeBrackets(
                                MODULE_TABLE.careplanPatient
                                    .apiPaths.getPersonalCareplan.path
                            ), '/'
                        ),
                        body.data.personalCarePlanId ? '/' + body.data.personalCarePlanId : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        makePersonalCareplan: builder
            .mutation<MakePersonalCareplanResponse, MakePersonalCareplanRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: Omit<MakePersonalCareplanRequest['data'], 'personalCarePlanId'>
                    } =
                    {
                        data: {
                            reasoningSessionId: body.data.reasoningSessionId,
                            ...(body.data.therapeuticsId && {
                                therapeuticsId: body.data.therapeuticsId
                            })
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.careplanPatient
                                .apiPaths.makePersonalCareplan.path, '/'
                        ),
                        body.data.personalCarePlanId ? '/' + body.data.personalCarePlanId : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        finishPersonalCareplan: builder
            .mutation<FinishPersonalCareplanResponse, FinishPersonalCareplanRequest & TokenAuth>({
                query: (body) => {
                    const formData = {
                        data: {
                            reasoningSessionId: body.data.reasoningSessionId
                        }
                    }

                    const removeBrackets = (str: string) => {
                        const pattern = /<[^>]+>/g
                        return _.replace(str, pattern, '')
                    }

                    const url = [
                        _.trim(
                            removeBrackets(
                                MODULE_TABLE.careplanPatient
                                    .apiPaths.finishPersonalCareplan.path
                            ), '/'
                        ),
                        body.personalCareplanId ? '/' + body.personalCareplanId : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        checkCareplanStatus: builder
            .mutation<CheckCareplanStatusResponse, CheckCareplanStatusRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: Omit<CheckCareplanStatusRequest['data'], 'personalCarePlanId'>
                    } = {
                        data: {
                        }
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.careplanPatient
                                .apiPaths.checkCareplanStatus.path, '/'
                        ),
                        body.personalCarePlanId ? '/' + body.personalCarePlanId : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateCareplanProgress: builder
            .mutation<UpdateCareplanProgressResponse, UpdateCareplanProgressRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<UpdateCareplanProgressRequest, 'data'> = {
                        data: {
                            buildingBlockId: body.data.buildingBlockId,
                            percentComplete: body.data.percentComplete,
                            ...(body.data.miscVar && {
                                miscVar: body.data.miscVar
                            })
                        }
                    }

                    return {
                        url: [
                            _.trimStart(
                                MODULE_TABLE.careplanPatient
                                    .apiPaths.updateCareplanProgress.path, '/'
                            ),
                            body.personalCareplanStepId ? '/' + body.personalCareplanStepId : ''
                        ].join(''),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        completeCareplanProgress: builder
            .mutation<CompleteCareplanProgressResponse,
            CompleteCareplanProgressRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<CompleteCareplanProgressRequest, 'data'> = {
                        data: {
                            completeType: body.data.completeType
                        }
                    }

                    const removeBrackets = (str: string) => {
                        const pattern = /<[^>]+>/g
                        return _.replace(str, pattern, '')
                    }

                    const url = [
                        _.trim(
                            removeBrackets(
                                MODULE_TABLE.careplanPatient
                                    .apiPaths.completeCareplanProgress.path
                            ), '/'
                        ),
                        body.personalCareplanStepId
                            ? '/' +
                         body.personalCareplanStepId
                            : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getToday: builder
            .mutation<GetTodayResponse, GetTodayRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetTodayRequest = {
                        data: {}
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.careplanPatient
                                .apiPaths.getToday.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        completeCareplanProgressReasoning: builder
            .mutation<CompleteCareplanProgressReasoningResponse,
            CompleteCareplanProgressReasoningRequest & TokenAuth>({
                query: (body) => {
                    const formData: Pick<CompleteCareplanProgressReasoningRequest, 'data'> = {
                        data: {
                            reasoningSessionId: body.data.reasoningSessionId,
                            reasoningSetId: body.data.reasoningSetId
                        }
                    }

                    const removeBrackets = (str: string) => {
                        const pattern = /<[^>]+>/g
                        return _.replace(str, pattern, '')
                    }

                    const url = [
                        _.trim(
                            removeBrackets(
                                MODULE_TABLE.careplanPatient
                                    .apiPaths.completeCareplanProgressReasoning.path
                            ), '/'
                        ),
                        body.personalCareplanStepId
                            ? '/' +
                         body.personalCareplanStepId
                            : ''
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getOverallProgress: builder
            .mutation<GetOverallProgressResponse, GetOverallProgressRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetOverallProgressRequest = {
                        data: {}
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.careplanPatient
                                .apiPaths.getOverallProgress.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getMotivationalMessage: builder
            .mutation<GetMotivationalMessageResponse, GetMotivationalMessageRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetMotivationalMessageRequest = {
                        data: {}
                    }

                    const url = [
                        _.trimStart(
                            MODULE_TABLE.careplanPatient
                                .apiPaths.getMotivationalMessage.path, '/'
                        )
                    ].join('')

                    return {
                        url,
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        saveToLibrary: builder
            .mutation<SaveToLibraryResponse, SaveToLibraryRequest
            & TokenAuth>({
                query: (body) => {
                    const formData: SaveToLibraryRequest = {
                        data: {
                            ...(body.data.personalCareplanStepId && {
                                personalCareplanStepId: body.data.personalCareplanStepId
                            }),
                            ...(body.data.articleId && {
                                articleId: body.data.articleId
                            })
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.careplanPatient.apiPaths
                                .saveToLibrary.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        removeFromLibrary: builder
            .mutation<RemoveFromLibraryResponse, RemoveFromLibraryRequest
                & TokenAuth>({
                    query: (body) => {
                        const formData: RemoveFromLibraryRequest = {
                            data: {
                                articleId: body.data.articleId
                            }
                        }

                        return {
                            url: _.trimStart(
                                MODULE_TABLE.careplanPatient.apiPaths
                                    .removeFromLibrary.path, '/'
                            ),
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`,
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        }
                    }
                }),
        reasonWithMe: builder
            .mutation<ReasonWithMeResponse, ReasonWithMeRequest & TokenAuth>({
                query: (body) => {
                    const formData: ReasonWithMeRequest = {
                        data: {
                            reasoningSetId: body.data.reasoningSetId,
                            reasoningSetVersion: body.data.reasoningSetVersion
                        }
                    }

                    return {
                        url: _.trimStart(
                            MODULE_TABLE.careplanPatient.apiPaths.reasonWithMe.path, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getReasoning: builder
            .mutation<GetReasoningResponse, GetReasoningRequest & TokenAuth>({
                query: (body) => {
                    const formData: {
                        data: Omit<GetReasoningRequest['data'], 'reasoningSessionId'>
                    } = {
                        data: {
                            answerValue: body.data.answerValue,
                            questionId: body.data.questionId,
                            reasoningSetId: body.data.reasoningSetId
                        }
                    }

                    return {
                        url: _.trimStart(
                             `${ MODULE_TABLE.reasonWithMe
                                 .apiPaths.reasonWithMe.path }/${
                                    body.data.reasoningSessionId }`, '/'
                        ),
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetPersonalCareplanMutation,
    // useMakePersonalCareplanMutation,
    useGetTodayMutation,
    useGetOverallProgressMutation,
    useCheckCareplanStatusMutation,
    useUpdateCareplanProgressMutation,
    useCompleteCareplanProgressMutation,
    useFinishPersonalCareplanMutation,
    useCompleteCareplanProgressReasoningMutation,
    useGetMotivationalMessageMutation,
    useRemoveFromLibraryMutation,
    useSaveToLibraryMutation,
    useGetReasoningMutation,
    useReasonWithMeMutation
} = careplanV10Api
