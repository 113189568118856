import { useEffect, useState } from 'react'

import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, PAGE_COUNT, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import _ from 'lodash'
import { toast } from 'react-toastify'

import { selectRouter } from '@app/app.store'
import { useGetCarepathsMutation } from '@fmt/api'
import DashboardResponsiveHeader from '@fmt/components/DashboardResponsiveHeader'
import DataTable from '@fmt/components/carepaths/DataTable'
import { MODULE_VERSION } from '@fmt/constants'
import { selectCarepathMenu, setCarepathMenu } from '@fmt/slice'
import { GetCarepathsResponse } from '@fmt/type'
import { back, push } from '@lagunovsky/redux-react-router'

const CarepathsMain = () => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const carepathMenu = useAppSelector(selectCarepathMenu)

    const validateRoute = useValidateRoute()
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const router = useAppSelector(selectRouter)

    const fixedCacheKey = 'shared-getCarepaths-key'
    const [getCarepaths, getCarepathsMutation] = useGetCarepathsMutation({
        fixedCacheKey
    })

    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount, setPageCount] = useState(PAGE_COUNT[1].value)

    // a workaround to prevent tables from flickering. simple but it works.
    const [
        getCarepathsResponse,
        setGetCarepathsResponse
    ] = useState<GetCarepathsResponse>()

    useEffect(() => {
        if (getCarepathsMutation.data) {
            setGetCarepathsResponse(getCarepathsMutation.data)
        }
    }, [getCarepathsMutation.data])

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    useEffect(() => {
        setCurrentPage(0)
    }, [pageCount])

    const unsubscribeGetCarepaths = () => {
        const unsubscribeMutation = getCarepaths({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGetCarepaths()

        let getCarepathsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCarepaths.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        const skip = currentPage * (getCarepathsResponse
                            ?.data.limit || 1)

                        const totalRecords = (getCarepathsResponse
                            ?.data.totalRecords || 0) - 1

                        getCarepathsPromise = getCarepaths({
                            authToken: newToken.value,
                            data: {
                                skip: skip > (getCarepathsResponse?.data
                                    .totalRecords || 0)
                                    ? totalRecords
                                    : skip,
                                limit: pageCount
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCarepathsPromise && getCarepathsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid, currentPage, pageCount])

    useEffect(() => {
        if (getCarepathsMutation.error) {
            const message = getErrorText(getCarepathsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCarepathsMutation.error])

    // a useEffect where the addModal is open when the route is /add.
    useEffect(() => {
        if ((router.location.pathname === MODULE_TABLE.fmt
            .routes.createCarepath)) {
            dispatch(setCarepathMenu({
                ...carepathMenu,
                showAddModal: true
            }))
        }
        // do not set to false automatically.let it close through
        // another useEffect
    }, [router.location.pathname])

    useEffect(() => {
        if (!carepathMenu.showAddModal) {
            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.routes.carepaths,
                true
            )

            if (isValid) {
                dispatch(push(isValid.route))
            }
        }
    }, [carepathMenu.showAddModal])

    return <div className={'carepath-page'}>

        {/* dashboard responsive header */}
        <DashboardResponsiveHeader />

        <div className={'main-content'}>

            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-between pt-16 pb-0 px-5'}>
                    <div className={'col-auto'}>
                        <a onClick={(e) => {
                            e.preventDefault()
                            dispatch(back())
                        }} className={'btn btn-round btn-sm-md'}>
                            <i
                                className={'fa-light fa-arrow-left'}
                                aria-hidden={'true'}>
                            </i>
                        </a>
                    </div>
                    <div className={'col-auto'}>
                        <h1 className={'my-0'}>{strings.fmt
                            ?.text.carepath.carepath}</h1>
                    </div>
                    <div className={'col-auto'}>
                        <a href={'#'}
                            onClick={(e) => {
                                e.preventDefault()
                            }} className={[
                                'btn btn-round btn-sm-md invisible'
                            ].join(' ')}>
                            <i
                                className={'fa-light fa-arrow-right'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </a>
                    </div>
                </div>
                <div className={'row justify-content-between mt-5'}>
                    <div className={'col'}>
                        <div className={
                            'container-fluid px-5 px-sm-3 carepath-body list-interface'
                        }>
                            <div className={'h-100 row'}>
                                <div className={' col-12 col-md-11 mx-auto'}>
                                    <DataTable
                                        mode={'EDIT'}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        responseData={getCarepathsResponse}
                                        fixedCacheKey={fixedCacheKey}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </div>
}

export default CarepathsMain
