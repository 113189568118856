import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { TreatmentKeys, TreatmentValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    treatmentEditFormik: FormikProps<TreatmentValues>
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>
        , fieldName?: TreatmentKeys) => void
}

const TreatmentName = ({ readOnly, treatmentEditFormik, handleKeyDown }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName: TreatmentKeys = 'treatmentName'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.TREATMENT.EDIT.NAME}
                    className={'form-label'}>{
                        strings.fmt?.text.department.menu.name
                    }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <input
                    readOnly={readOnly}
                    type={'text'}
                    name={fieldName}
                    className={[
                        'form-control-sm',
                        readOnly ? 'form-control-plaintext' : 'form-control',
                        treatmentEditFormik.errors.treatmentName &&
                         'border-danger'
                    ].join(' ')}
                    placeholder={!readOnly ? strings.fmt?.text.department.menu.name : ''}
                    value={treatmentEditFormik.values.treatmentName}
                    id={IDS.TREATMENT.EDIT.NAME}
                    onBlur={treatmentEditFormik.handleBlur}
                    onChange={!readOnly ? treatmentEditFormik.handleChange : () => {}}
                    onKeyDown={handleKeyDown}
                    required
                />
                <div className={'form-text error'}>{
                    treatmentEditFormik.errors.treatmentName
                        ? treatmentEditFormik.errors.treatmentName
                        : null
                }</div>
            </div>

        </div>

    </div>

    return result
}

export default TreatmentName
