
import { selectToken, setToken } from '@app/slices/slice.token'

import {
    EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE,
    EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE,
    MODULE_TABLE,
    REGISTRATION_CUSTOM_MATCH
} from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectRouter } from '@app/app.store'
import { selectActiveModules, selectStrings, setReturnToLink } from '@app/slices/slice.app'
import { replace } from '@lagunovsky/redux-react-router'
import {
    useChangePasswordMutation,
    useDoLoginMutation,
    useGetTokenMutation,
    useValidateMFAMutation
} from '@login/api'

import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import {
    selectTokenForForcePasswordChange,
    setIsFullscreenOverlay,
    setTokenForForcePasswordChange,
    setTokenForMFA
} from '@login/slices/slice.login'
import { fromBase64 } from 'js-base64'
import _ from 'lodash'
import { v4 as uuidV4 } from 'uuid'

/** Moving naivgation page to root because the header has to show up everywhere. */

export const TokenHandler = () => {
    const activeModules = useAppSelector(selectActiveModules)
    const token = useAppSelector(selectToken)
    const router = useAppSelector(selectRouter)
    const validateRoute = useValidateRoute()
    const revalidateToken = useRevalidateToken()

    const strings = useAppSelector(selectStrings)
    const tokenForPasswordChange = useAppSelector(selectTokenForForcePasswordChange)

    const dispatch = useAppDispatch()

    const [doGetToken, doGetTokenMutation] = useGetTokenMutation({
        fixedCacheKey: 'shared-getToken-key'
    })

    const [doLogin, doLoginMutation] = useDoLoginMutation({
        fixedCacheKey: 'shared-login-key'
    })

    const [, validateMFAMutation] = useValidateMFAMutation({
        fixedCacheKey: 'shared-validate-mfa-key'
    })

    const [, changePasswordMutation] = useChangePasswordMutation({
        fixedCacheKey: 'shared-force-password-change-key'
    })

    useEffect(() => {
        if (doGetTokenMutation.data) {
            // a new id is assigned.
            dispatch(setToken({
                id: uuidV4(),
                value: doGetTokenMutation.data.token,
                valid: true,
                mode: 'guest'

            }))
            // but it would also make sense that you are immediately redirected to the login
            // page because that's what the guest token is for.

            // also, get rid of your workflow data. you are a guest. do it at CommonWorkflow.tsx
        }
    }, [doGetTokenMutation.data])

    useEffect(() => {
        // this would also happen if you are from the reg link.
        if (activeModules.arr.length) {
            if ((
                !(REGISTRATION_CUSTOM_MATCH
                    .test(router.location.pathname)) && token.mode === 'guest'
            ) && (
                !(EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE
                    .test(router.location.pathname)) && token.mode === 'guest'
            ) && (
                !(EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE
                    .test(router.location.pathname)) && token.mode === 'guest'
            )) {
                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.login.moduleName,
                    MODULE_TABLE.login.routes.login,
                    true
                )

                console.log('going to login path becasue token mode is guest now')
                if (isValid) {
                    dispatch(replace(isValid.route))
                }
            } else if (
                (REGISTRATION_CUSTOM_MATCH
                    .test(router.location.pathname))
            ) {
                console.log('you are at the registration module so do a login with uuid and id.')

                // will need to use router.location.pathname because we can't use useParams because
                // we are out of scope. no choice really.
                const [, , uuid, id] = _.split(router.location.pathname, '/')

                console.log('UUID:', uuid)
                console.log('ID:', id)

                // do a login regardless if the recent user is the same because
                // of personal detail updates such as role privileges.
                // if ((uuid && id) && (id !== token.details.sub)) {
                if (!doLoginMutation.isSuccess) {
                    doLogin({
                        authToken: token.value,
                        uuid,
                        id
                    })
                    // enable fullscreen
                    dispatch(setIsFullscreenOverlay({
                        show: true,
                        text: strings.login?.text?.form.loading_button || ''
                    }))
                }
                // }
            } else if (
                (
                    EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE
                        .test(router.location.pathname) ||
                    EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE
                        .test(router.location.pathname)
                )
            ) {
                console.log('you are at the login module so do a login with uuid, id, and route.')

                // will need to use router.location.pathname because we can't use useParams because
                // we are out of scope. no choice really.
                const [, , uuid, id, route] = _.split(router.location.pathname, '/')

                const routeToUse = route ? fromBase64(route) : ''

                console.log('UUID:', uuid)
                console.log('ID:', id)
                console.log('Route:', routeToUse)

                if (
                    REGISTRATION_CUSTOM_MATCH.test(routeToUse) ||
                    Object.values(MODULE_TABLE.registration.routes)
                        .includes(routeToUse)
                ) {
                    console.log("not setting returnToLink because it's a reg link")
                } else if (
                    EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE.test(routeToUse) ||
                    EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE.test(routeToUse)
                ) {
                    console.log("not setting returnToLink because it's a login quick link")
                } else if (
                    Object.values(MODULE_TABLE.login.routes).includes(routeToUse)
                ) {
                    console.log('not setting login module routes')
                } else if (
                // new else if detected. don't set returnToLink the rwm paths beacuse if you do,
                // you'll back to it again after making
                // a careplan which means you are stuck in a loop.
                    Object.values(MODULE_TABLE.reasonWithMe.routes)
                        .includes(routeToUse)
                ) {
                    console.log('not setting rwm module routes')
                } else {
                    console.log('setting return link FROM log url to:', routeToUse)
                    // then set returnToLink.
                    dispatch(setReturnToLink(routeToUse))
                    // setting it in localStorage to retain the lifespan.
                    localStorage.setItem('recentRoute', routeToUse)
                }

                // do a login regardless if the recent user is the same because
                // of personal detail updates such as role privileges.
                // if ((uuid && id) && (id !== token.details.sub)) {
                if (!doLoginMutation.isSuccess) {
                    doLogin({
                        authToken: token.value,
                        uuid,
                        id
                    })
                    // enable fullscreen
                    dispatch(setIsFullscreenOverlay({
                        show: true,
                        text: strings.login?.text?.form.loading_button || ''
                    }))
                }
                // }
            }
        }
    }, [token.id, token.valid, activeModules.id])

    useEffect(() => {
        if (doGetTokenMutation.error) {
            const message = getErrorText(doGetTokenMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doGetTokenMutation.error])

    /**
     * when to revalidate a token: sorted by priority.
     * 1.) if you are at the registration workflow, revalidate if the id
     * is the same from the sub of the token currently active.
     * 2.) if you just logged in regardless of what token you have.
     * 3.) onMount AND if you have a token.
     *
     *
     * when to generate a guest token:
     * 1.) when you don't have a token
     * 2.) when you logout
     */

    useEffect(() => {
        // priority 2.

        const call = async () => {
            const data = doLoginMutation.data
            let moduleName = ''
            let route = ''

            if (doLoginMutation.data?.status === 'OK') {
                console.log('setting token after doLogin')

                // check if it has mfa.
                if (data?.data.mfa === true) {
                    console.log("has mfa so we can't set the token just yet.")
                    // save this token for mfa code authentication.
                    dispatch(setTokenForMFA(data.token))
                    // navigate to the mfa page.
                    moduleName = MODULE_TABLE.login.moduleName
                    route = MODULE_TABLE.login.routes.mfaPass

                    const isValid = validateRoute(
                        activeModules.arr, moduleName, route, false
                    )

                    if (isValid) {
                        dispatch(replace(isValid.route))
                    }
                } else if (data?.data.changePassword === true) {
                    console.log("has force password change so we can't set the token just yet.")
                    // save this token for mfa code authentication.
                    dispatch(setTokenForForcePasswordChange(data.token))
                    // navigate to the mfa page.
                    moduleName = MODULE_TABLE.login.moduleName
                    route = MODULE_TABLE.login.routes.forcePassword

                    const isValid = validateRoute(
                        activeModules.arr, moduleName, route, false
                    )

                    if (isValid) {
                        dispatch(replace(isValid.route))
                    }
                } else {
                    console.log('no mfa so we can set the token right away')
                    if (data?.data.firstTime === true) {
                        moduleName = MODULE_TABLE.onboarding.moduleName
                        route = MODULE_TABLE.onboarding.routes.gettingStarted

                        const isValid = validateRoute(
                            activeModules.arr, moduleName, route, false
                        )

                        if (isValid) {
                            dispatch(replace(isValid.route))
                        }
                    } else {
                        const id = uuidV4()
                        dispatch(setToken({
                            id,
                            value: doLoginMutation.data.token,
                            valid: true,
                            mode: 'auth'
                        }))
                    }
                }
            } else if (data?.status === 'NOT_OK') {
                // go to the login page especially when you are in the reg workflow
                if (
                    (REGISTRATION_CUSTOM_MATCH
                        .test(router.location.pathname))
                ) {
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.routes.login,
                        true
                    )

                    console.log('reg: going to login path becasue authentication is incorrect')
                    if (isValid) {
                        dispatch(replace(isValid.route))
                    }
                } else if (
                    EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE.test(router.location.pathname) ||
                    EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE.test(router.location.pathname)
                ) {
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.login.moduleName,
                        MODULE_TABLE.login.routes.login,
                        true
                    )

                    console.log('log: going to login path becasue authentication is incorrect')
                    if (isValid) {
                        dispatch(replace(isValid.route))
                    }
                } else {
                    toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                }
            }
        }
        call()
    }, [doLoginMutation.data])

    useEffect(() => {
        // priority 2.

        const call = async () => {
            if (validateMFAMutation.data?.status === 'OK') {
                console.log('setting token after validateMFA')

                const id = uuidV4()
                dispatch(setToken({
                    id,
                    value: validateMFAMutation.data.data.token,
                    valid: true,
                    mode: 'auth'
                }))
            }
        }
        call()
    }, [validateMFAMutation.data])

    useEffect(() => {
        // priority 2.

        const call = async () => {
            if (changePasswordMutation.data?.status === 'OK') {
                console.log('setting token after changePassword')

                const id = uuidV4()
                dispatch(setToken({
                    id,
                    value: tokenForPasswordChange,
                    valid: true,
                    mode: 'auth'
                }))
            }
        }
        call()
    }, [changePasswordMutation.data])

    // only do this IF there is a token that already exists. running onmount regardless
    // on who the user is.
    useEffect(() => {
        // let's start from here. call this right away as normal. BUT if the path is a
        // /log OR a /reg, and regardless of token validity or type, override it
        // by making the call to guest token immediately.

        if (
            (REGISTRATION_CUSTOM_MATCH
                .test(router.location.pathname)) &&
                doLoginMutation.isSuccess === false
        ) {
            console.log('reg path detected on mount, get guest token.')
            doGetToken({
                data: { locale: navigator.language }
            })
        } else if (
            (EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE
                .test(router.location.pathname) || EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE
                .test(router.location.pathname)) &&
                doLoginMutation.isSuccess === false
        ) {
            console.log('log path detected on mount, get guest token.')
            doGetToken({
                data: { locale: navigator.language }
            })
        } else {
            revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)
        }
        // enable fullscreen
        dispatch(setIsFullscreenOverlay({
            show: true,
            text: strings.app?.text.loading || ''
        }))
    }, [])

    /** move doGetToken execution to token revalidation instead of checking if token.valid
     * is false.
     */

    return <></>
}

export default TokenHandler
