import React from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { IDS } from '@fmt/constants'
import { CareFacilityKeys, CareFacilityValues } from '@fmt/type'
import { FormikProps } from 'formik'

interface ComponentProps {
    readOnly: boolean
    facilityEditFormik: FormikProps<CareFacilityValues>,
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>
        , fieldName?: CareFacilityKeys) => void
}

const Address = ({ readOnly, facilityEditFormik, handleKeyDown }:ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const fieldName1: CareFacilityKeys = 'facilityAddressNumber'
    const fieldName2: CareFacilityKeys = 'facilityAddressSuffix'

    const result = <div className={'form-group'}>
        <div className={'row mt-3 align-items-center'}>
            <div className={'col-3 d-none d-sm-block'}>
                <label htmlFor={IDS.FACILITY.EDIT.HOUSE_NUMBER} className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].house_number
                }</label>
                <span className={'mx-2'}>{'+'}</span>
                <label htmlFor={IDS.FACILITY.EDIT.SUFFIX} className={'form-label'}>{
                    strings.fmt?.text.facility.add.steps['1'].suffix
                }</label>
            </div>
            <div className={'col-sm-9 col-12'}>
                <div className={'row'}>
                    <div className={'col-12 col-lg-7 mb-4 mb-lg-0'}>
                        <input
                            readOnly={readOnly}
                            type={'text'}
                            name={fieldName1}
                            className={[
                                'form-control-sm',
                                readOnly ? 'form-control-plaintext' : 'form-control',
                                facilityEditFormik.errors.facilityAddressNumber &&
                                'border-danger'
                            ].join(' ')}
                            placeholder={!readOnly
                                ? strings.fmt
                                    ?.text.facility.add.steps['1'].house_number
                                : ''}
                            value={facilityEditFormik.values.facilityAddressNumber}
                            id={IDS.FACILITY.EDIT.HOUSE_NUMBER}
                            onBlur={facilityEditFormik.handleBlur}
                            onChange={!readOnly ? facilityEditFormik.handleChange : () => {}}
                            onKeyDown={(e) => {
                                handleKeyDown(e, 'facilityAddressSuffix')
                            }}
                            required
                        />
                        <div className={'form-text error'}>{
                            facilityEditFormik.errors.facilityAddressNumber
                                ? facilityEditFormik.errors.facilityAddressNumber
                                : null
                        }</div>
                    </div>
                    <div className={'col-lg-5 col-12'}>
                        <input
                            readOnly={readOnly}
                            type={'text'}
                            name={fieldName2}
                            className={[
                                'form-control-sm',
                                readOnly ? 'form-control-plaintext' : 'form-control',
                                facilityEditFormik.errors.facilityAddressSuffix &&
                                 'border-danger'
                            ].join(' ')}
                            placeholder={!readOnly
                                ? strings.fmt
                                    ?.text.facility.add.steps['1'].suffix
                                : ''}
                            value={facilityEditFormik.values.facilityAddressSuffix}
                            id={IDS.FACILITY.EDIT.SUFFIX}
                            onBlur={facilityEditFormik.handleBlur}
                            onChange={!readOnly ? facilityEditFormik.handleChange : () => {}}
                            onKeyDown={(e) => {
                                handleKeyDown(e, 'facilityAddressStreet')
                            }}
                            required
                        />
                        <div className={'form-text error'}>{
                            facilityEditFormik.errors.facilityAddressSuffix
                                ? facilityEditFormik.errors.facilityAddressSuffix
                                : null
                        }</div>
                    </div>
                </div>

            </div>

        </div>

    </div>

    return result
}

export default Address
