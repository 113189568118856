import { tryParseJSON } from '@app/app.method'
import { RootState } from '@app/app.store'
import { EMPTY_TOKEN_DATA } from '@app/app.constants'
import { DecodedJWT, Roletype, TokenData, TokenDetails, TokenState } from '@app/types/type.token'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'

/** TOKEN SLICE REDO: DON'T STORE THE VALID STATUS OF THE TOKEN. IT'S MISLEADING. */

const getFromLocalStorage: () => TokenData = () => {
    /** Coming from print pdf operations using sessionStorage instead of localStorage.
     * If localStorage token is undefined, get it from sessionStorage otherwise
     * initialize an empty string.
     */
    const tokenObj = tryParseJSON(
        localStorage.getItem('token') || sessionStorage.getItem('token') || ''
    ) as unknown as TokenData

    const goSignal = !_.isEmpty(tokenObj) &&
        _.has(tokenObj, 'id') &&
        _.has(tokenObj, 'value') &&
        _.has(tokenObj, 'mode') &&
        _.has(tokenObj, 'details')

    if (goSignal) {
        return {
            id: tokenObj.id,
            value: tokenObj.value,
            mode: tokenObj.mode,
            // LET THE VALIDATION BE THE FIRST THING TO OCCUR.
            valid: false,
            details: tokenObj.details
        }
    } else {
        return {
            id: EMPTY_TOKEN_DATA.id,
            value: EMPTY_TOKEN_DATA.value,
            valid: EMPTY_TOKEN_DATA.valid,
            mode: EMPTY_TOKEN_DATA.mode,
            details: EMPTY_TOKEN_DATA.details
        }
    }
}

export const INITIAL_STATE: TokenState = {
    data: getFromLocalStorage(),
    showIdle: false,
    showExpire: false,
    isRevalidating: false
}

export const slice = createSlice({
    name: 'token',
    initialState: INITIAL_STATE,
    reducers: {
        /**
         * setter for token property. also sets data in
         * localStorage.
         *  */
        setToken: (state: TokenState, action: PayloadAction<Omit<TokenData, 'details'>>) => {
            // now how to deal with ids.
            // just make sure you are getting the original id of the token when doing this.
            state.data.id = action.payload.id

            // proceed as normal.
            state.data.value = action.payload.value
            state.data.valid = action.payload.valid
            state.data.mode = action.payload.mode
            let details = EMPTY_TOKEN_DATA.details as TokenDetails

            if (action.payload.value) {
                /** before setting the aid property, we should decode the string
             * and parse the aid string as an array of objects. we still need to
             * check.
            */
                const tokenDetails = jwtDecode(action.payload.value) as DecodedJWT

                // const roleTypes = tryParseJSON(tokenDetails.aid)
                const roleTypes = tryParseJSON(tokenDetails.rid)
                const ssDetails = tryParseJSON(tokenDetails.ss)

                details = {
                    sub: tokenDetails.sub,
                    /** make sure you use these expiration numbers as part of the idle timer
                 * IF the idle window doesn't have values to retrieve.
                 */
                    iat: tokenDetails.iat,
                    exp: tokenDetails.exp,
                    name: tokenDetails.name,
                    locale: tokenDetails.locale,
                    fullName: tokenDetails.fullName,
                    age: tokenDetails.age,
                    rid: roleTypes as unknown as TokenDetails['rid'],
                    aid: tokenDetails.aid,
                    roid: tokenDetails.roid,
                    pic: tokenDetails.pic,
                    ss: ssDetails as unknown as TokenDetails['ss']
                } as TokenDetails

                /** checking if it's an empty object from tryParseJSON as a falsy return
             * is an array.
             */

                if (_.isArray(roleTypes)) {
                    details.rid = roleTypes as Roletype[]
                }
                state.data.details = details
            }

            localStorage.setItem('token', JSON.stringify({
                id: action.payload.id,
                value: action.payload.value,
                mode: action.payload.mode,
                details
            }))
        },

        /** individual setter for idle and expire booleans */
        setShowIdle: (state: TokenState, action: PayloadAction<boolean>) => {
            state.showIdle = action.payload
        },
        setShowExpire: (state: TokenState, action: PayloadAction<boolean>) => {
            state.showExpire = action.payload
        },
        setIsRevalidating: (state: TokenState, action: PayloadAction<boolean>) => {
            state.isRevalidating = action.payload
        },
        /**
         * reset token state and empties localStorage token property.
         *  */
        resetToken: (state: TokenState) => {
            state.data.id = EMPTY_TOKEN_DATA.id
            state.data.value = EMPTY_TOKEN_DATA.value
            state.data.valid = EMPTY_TOKEN_DATA.valid
            state.data.mode = EMPTY_TOKEN_DATA.mode
            // state.showIdle = false
            // state.showExpire = false
            state.data.details = EMPTY_TOKEN_DATA.details
            localStorage.removeItem('token')

            /** setting token data is separate from idle and expire properties */
        }
    }
})

export const {
    setToken,
    setShowIdle,
    setShowExpire, setIsRevalidating
    // resetToken
} = slice.actions

export const selectToken = (state: RootState) => state.token.data
export const selectShowIdle = (state: RootState) => state.token.showIdle
export const selectShowExpire = (state: RootState) => state.token.showExpire
export const selectIsRevalidating = (state: RootState) => state.token.isRevalidating

export default slice.reducer
