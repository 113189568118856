import { SIDEBAR_WIDTH } from '@app/app.constants'
import { css, useTheme } from '@emotion/react'

const GlobalStyle = () => {
    const theme = useTheme()

    return css`
        .sidebar.desktop {
            font-family: 'Ikh-EndUser';
            /* fixed width */
            min-width: ${ [SIDEBAR_WIDTH, 'px'].join('') };
            max-width: ${ [SIDEBAR_WIDTH, 'px'].join('') };
            white-space: pre-wrap;
            position: fixed;
            background: ${ theme.config.global.sidebar.main.background };
            z-index: 2;
            height: 100%;
            /* so that the sidebar stays on top all the time. */
            top: 0;

            hr {
                margin-block-start: 2.5rem;
                margin-inline: 1.5rem;
                color:${ theme.config.global.sidebar.main.separator };
            }

            & .header {
                height: 74.5px;
            }

            & .navigation {
                display: flex;
                flex-flow: column;
                height: calc(100% - 74.5px);
                padding-inline: 0rem;

                & .nav-pills {
                    flex: 0 1 auto;
                    background: transparent;
                    box-shadow: none;
                    border: none;

                    & .nav-item {
                        margin-block: 12px;
                        padding: 0;

                        /* margin-block: 0.7rem; */

                        & .nav-link {
                            color: ${ theme.config.global.sidebar.main.primary };
                            font-size: 14px;
                            margin-inline: 1rem;
                            padding-inline-start: 1rem;
                            border-radius: 10px;
                            padding-block: 0.75rem;
                            &.active {
                                font-weight: normal;
                                box-shadow: none;
                                background: ${ theme.config.global.sidebar.main
        .navLink.active.background };
                            }
                        }
                    }
                }

                & .gap {
                    flex: 1 1 auto;
                }

                & .footer {
                    flex: 0 1 40px;

                    & button {
                        border-radius: 24px;
                        box-shadow: 0px 2px 4px 0px ${ theme.config.global.sidebar.main.shadow };
                        margin-block: 1rem;
                        width: 80%;
                    }
                }
            }
        }

        .sidebar.mobile {
            font-family: 'Ikh-EndUser';

            &.top {
                min-height: 40px;
                max-height: 40px;
                width: 100%;
                background: ${ theme.config.global.sidebar.top.background };
                /* position: fixed;
                top: 0;
                z-index: 1; */
            }

            &.bottom {
                min-height: 60px;
                max-height: 60px;
                width: 100%;
                background: ${ theme.config.global.sidebar.bottom.primary };
                position: fixed;
                bottom: 0;
                z-index: 1;

                & .navigation {
                    & .nav-pills {
                        background: transparent;
                        box-shadow: none;
                        border: none;
                        & .nav-item {
                            padding: 0;

                            & .nav-link {
                                color:${ theme.config.global.sidebar.bottom.text };

                                &.active {
                                    border-radius: 0px;
                                    background: ${ theme.config
            .global.sidebar.bottom.primarySelected };
                                }

                                & span {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    `
}

export default GlobalStyle
