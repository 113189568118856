import React, { MutableRefObject, useReducer, useRef } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import AccordionComponent from '@doc/components/patients/AccordionComponent'

import { GetPatientsResponse } from '@doc/type'

import { MODULE_TABLE } from '@app/app.config'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import PageCountDropdown from '@app/components/PageCountDropdown'
import Pagination from '@app/components/Pagination'
import { push } from '@lagunovsky/redux-react-router'

import produce from 'immer'
import { useDraggable } from 'react-use-draggable-scroll'

interface ComponentProps {
    search: string,
    setSearch: React.Dispatch<React.SetStateAction<string>>,
    mode: 'EDIT' | 'VIEW',
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    fixedCacheKey: 'shared-getPatients-key'
    getPatientsMutation: {
        isLoading: boolean;
        isSuccess: boolean;
        responseData: GetPatientsResponse | undefined;
    },
    pageCount: number
    setPageCount: React.Dispatch<React.SetStateAction<number>>
}

const DataTable = ({
    search,
    setSearch, mode,
    currentPage, setCurrentPage, fixedCacheKey, getPatientsMutation,
    pageCount, setPageCount
}: ComponentProps) => {
    const dispatch = useAppDispatch()

    const validateRoute = useValidateRoute()

    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    /** for data table */
    // Initialize state using useReducer
    const [accordions, accordionsDispatch] = useReducer((state: {
        id: string;
        isOpen: boolean;
      }[], action: { type: 'PUSH_ID'; payload: { id: string } }
      | { type: 'TOGGLE_ISOPEN'; payload: { id: string, isOpen: boolean } }) => {
        switch (action.type) {
            case 'PUSH_ID':
                return produce(state, draft => {
                    const existingAccordion = draft.find((item) => item.id === action.payload.id)
                    if (!existingAccordion) {
                        draft.push({
                            id: action.payload.id,
                            isOpen: true
                        })
                    }
                })

            case 'TOGGLE_ISOPEN':
                return produce(state, draft => {
                    const existingAccordion = draft.find((item) => item.id === action.payload.id)
                    if (existingAccordion) {
                        existingAccordion.isOpen = action.payload.isOpen
                    }
                })

            default:
                return state
        }
    },
    [])

    // no longer using smart search
    const filteredData = getPatientsMutation.responseData?.data.patientData || []

    const recordMenuRef = useRef<HTMLDivElement | null>()
    const { events } = useDraggable(
        recordMenuRef as unknown as MutableRefObject<HTMLElement>
    )

    const isLoadingContent = <small className={'d-block text-center py-2'}>
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    </small>

    const isTableEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const tableContent = <tbody>
        {
            filteredData.length
                ? _.map(filteredData, (obj) => {
                    const key = obj.userId

                    const found = _.find(accordions, (o) => {
                        return o.id === obj.userId
                    })

                    // console.log(obj)

                    return <React.Fragment key={key}>
                        <tr onClick={() => {
                            if (found) {
                                accordionsDispatch({
                                    type: 'TOGGLE_ISOPEN',
                                    payload: {
                                        id: obj.userId,
                                        isOpen: !found.isOpen
                                    }
                                })
                            }

                            accordionsDispatch({
                                type: 'PUSH_ID',
                                payload: {
                                    id: obj.userId
                                }
                            })
                        }}>
                            <td>
                                <input type={'checkbox'} className={'form-check-input'}/>
                            </td>
                            <td className={'accordion'}>
                                <u className={'me-2'}>{
                                    obj.userData?.[0]?.firstName || ''
                                }</u>
                                <i className={
                                    [
                                        'fa-light',
                                        found?.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                                    ].join(' ')
                                }></i>
                            </td>
                            <td>{obj.userData?.[0]?.lastName || ''}</td>
                            <td>{obj.userData?.[0]?.active || ''}</td>
                            <td>{obj.userData?.[0]?.email || ''}</td>
                            <td>{obj.userData?.[0]?.patientNumber || ''}</td>
                            <td className={'cursor-pointer'} onClick={(e) => {
                                e.stopPropagation()

                                const isValid = validateRoute(
                                    activeModules.arr,
                                    MODULE_TABLE.doc.moduleName,
                                    MODULE_TABLE.doc.routes.viewPatient,
                                    true
                                )

                                dispatch(push(
                                    _.replace(isValid.route,
                                        ':userId',
                                        obj.userId
                                    )
                                ))
                            }}><i className={'fa-light fa-eye'}></i></td>
                            <td className={'cursor-pointer'} onClick={(e) => {
                                e.stopPropagation()

                                const isValid = validateRoute(
                                    activeModules.arr,
                                    MODULE_TABLE.doc.moduleName,
                                    MODULE_TABLE.doc.routes.editPatient,
                                    true
                                )

                                dispatch(push(
                                    _.replace(isValid.route,
                                        ':userId',
                                        obj.userId
                                    )
                                ))
                            }}><i className={'fa-light fa-edit'}></i></td>
                            <td className={'cursor-pointer'} >
                                <i className={'fa-light fa-trash'}></i>
                            </td>
                        </tr>
                        {<tr className={`accordion ${ !found?.isOpen ? 'd-none' : '' }`}>
                            <td colSpan={9}>
                                <AccordionComponent
                                    search={search}
                                    currentPage={currentPage}
                                    getPatientsMutation={getPatientsMutation}
                                    mode={mode}
                                    isOpen={found?.isOpen}
                                    obj={obj}
                                    fixedCacheKey={fixedCacheKey}
                                />
                            </td>
                        </tr>}
                    </React.Fragment>
                })
                : getPatientsMutation.isSuccess && <tr><td colSpan={9}>{isTableEmpty}</td></tr>
        }
    </tbody>

    const table = <div
        className={'col-12 record-menu mt-5'}
        ref={(e) => {
            recordMenuRef.current = e
        } }
        {...events}
    >
        <table>
            <thead>
                <tr>
                    <th>
                        <input type={'checkbox'} className={'form-check-input'}/>
                    </th>
                    {/* <i class="fa-solid fa-angles-up-down fa-rotate-180"></i> */}
                    <th>
                        <span className={'me-2'}>{
                            strings.doc?.text.patient.make_careplan.first_name
                        }</span>
                        <i className={'fa-solid fa-angles-up-down'}></i>
                    </th>
                    <th>
                        <span className={'me-2'}>{
                            strings.doc?.text.patient.make_careplan.last_name
                        }</span>
                        <i className={'fa-solid fa-angles-up-down'}></i>
                    </th>
                    <th>
                        <span className={'me-2'}>{strings.doc?.text
                            .patient.patient_menu.active}</span>
                        <i className={'fa-solid fa-angles-up-down'}></i>
                    </th>
                    <th>
                        <span className={'me-2'}>{
                            strings.doc?.text.patient.make_careplan.email
                        }</span>
                        <i className={'fa-solid fa-angles-up-down'}></i>
                    </th>
                    <th>
                        <span className={'me-2'}>{strings.doc?.text
                            .patient.add_interface.steps['1'].fields.patient_number}</span>
                        <i className={'fa-solid fa-angles-up-down'}></i>
                    </th>
                    <th colSpan={3}>{strings.doc?.text.patient.patient_menu.action}</th>
                </tr>
            </thead>
            {
                getPatientsMutation.isLoading
                    ? <tbody><tr><td colSpan={9}>{isLoadingContent}</td></tr></tbody>
                    : tableContent
            }

        </table>
    </div>

    const debounceSetSearch = useDebouncedCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value)
            // but also change the skip value to zero as well.
            setCurrentPage(0)
        },
        1000
    )

    return <>
        <div className={'row'}>
            {/* <div className={'row h-100 flex-column'}> */}
            <div className={
                'col-12 d-flex justify-content-end align-items-center'
            }>
                <h6 className={'mb-0 fw-semibold'}>{
                    strings.doc?.text.patient.patient_menu.add_patient
                }</h6>
                <div className={
                    'rounded-square yellow d-flex ms-3 ' +
                    'justify-content-center align-items-center'
                } onClick={() => {
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.routes.createPatient,
                        true
                    )

                    if (isValid) {
                        dispatch(push(isValid.route))
                    }
                }}>
                    <i className={'fa-light fa-plus'}></i>
                </div>
            </div>
            <div className={'col-12'}>
                <div className={['row justify-content-between ',
                    'align-items-center mt-3'].join(' ')}>
                    <div className={'col'}>
                        <div className={'search-box'}>
                            <i className={'fa-light fa-search'}></i>
                            <input type={'text'}
                                className={'form-control'}
                                placeholder={strings.app?.text.search.text
                                }
                                defaultValue={search}
                                onChange={(e) => {
                                    debounceSetSearch(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className={'col-auto pe-0'}>
                        <PageCountDropdown pageCount={pageCount}
                            setPageCount={setPageCount}
                        />
                    </div>
                    <div className={'col-auto pe-0'}>
                        <button
                            className={['btn ',
                                'rounded-square me-2'].join(' ')}
                        >
                            <i className={'fa-light fa-filter'}></i>
                        </button>
                        <button className={['btn ',
                            'rounded-square'].join(' ')}>
                            <i className={'fa-light fa-download'}></i>
                        </button>
                    </div>
                </div>
            </div>
            {table}
            <div className={'container-fluid pb-4 pt-6 px-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-auto'}>
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPageState={setCurrentPage}
                            limit={getPatientsMutation.responseData?.data.limit ||
                                 getPatientsMutation.responseData
                                     ?.data.totalRecords || 1}
                            skip={getPatientsMutation.responseData?.data.skip || 1}
                            totalRecords={(getPatientsMutation.responseData
                                ?.data.totalRecords || 1)}
                        />
                    </div>
                </div>
            </div>
        </div>

    </>
}

export default DataTable
